

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class scrollBtn extends Vue {
    private scrollBtn: boolean = false;
    private goDown: boolean = true;

    public onScroll(e: any) {
        if (typeof window === 'undefined') return;
        const top = window.pageYOffset || e.target.scrollTop || 0;
        const pageHeight = document.body.scrollHeight - window.innerHeight - 1;

        this.scrollBtn = top > 20 ? true : false;

        if (top >= pageHeight) {
            this.goDown = false;
        } else {
            this.goDown = true;
        }
    }
    public scrollTo() {
        const pageHeight = document.body.scrollHeight - window.innerHeight;

        if (this.goDown) {
            this.$vuetify.goTo(pageHeight);
        } else this.$vuetify.goTo(0);

        this.goDown = !this.goDown;
    }
}
