













































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IMaskComponent } from 'vue-imask';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';

const env = process.env.VUE_APP_ENV;
@Component({
    components: {
        'imask-input': IMaskComponent,
    },
})
export default class RemoteCampaignOpenQuestionItem extends Vue {
    private mdcTextFields!: any;

    @Prop()
    index!: number;

    @Prop()
    question!: string;

    @Prop()
    options!: string[];

    private addOption() {
        this.options_.push('');
    }

    private deleteOption(index: number) {
        if (this.options_.length <= 1) {
            return;
        }

        this.options_.splice(index, 1);
    }

    get question_() {
        return this.question;
    }

    set question_(v: any) {
        this.$emit('update:question', v);
    }

    get options_() {
        return this.options;
    }

    set options_(v: any) {
        this.$emit('update:options', v);
    }

    get isTestingMode() {
        return env !== 'production' ? true : false;
    }

    public autofill(isAutoFill?: boolean) {
        this.question_ = 'How are you';
        var question1 = ['good','bad'];
        this.options_ = question1;
    }

    private deleteQuestion() {
        this.$emit('delete');
    }

    private initMDCTextFields() {
        this.mdcTextFields = Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
