export const receipt = {
    number: '領収書番号',
    copy: '領収書のコピー',
    refundConfirmation: '返金確認',
    cancelRecurrentFailedMsg: '月払いをキャンセル失敗',
    refundFailed: '返金失敗',
    refundSucceeded: '返金成功',
    refundOnceOnlyMsg: '返金が一度だけ行えます。確認後、キャンセルできません。',
    refundAndCancelRecurrentButton: '返金して月払いをキャンセル',
    notEnoughBalanceMsg: '残高が足りません。JARVIX PAYに連絡して下さい。',
    printer: {
        printingReceipt: '領収書をプリント中',
        failedToPrint: 'プリント失敗',
        checkPrint: 'プリンターとの接続を確認して下さい。',
    },
};
