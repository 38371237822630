const win = typeof (window as any) !== 'undefined' ? window : ({} as Window);

// console.log(process.env);
import devtools from '@vue/devtools';
if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_VUE_DEVTOOLS__HOST) {
    devtools.connect(
        process.env.VUE_APP_VUE_DEVTOOLS__HOST,
        process.env.VUE_APP_VUE_DEVTOOLS__PORT || null
    );
}

import { setupPlatforms } from '@/utils/platform';
setupPlatforms(win);

import init_ios from '@/utils/init/init_ios';
try {
    init_ios(win, document);
} catch (e) {}

// #region init Vue
import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
// import BootstrapVue from 'bootstrap-vue';
import VueMDCAdapter from 'vue-mdc-adapter';
import Vue2Filters from 'vue2-filters';
import VueNumeric from 'vue-numeric';
import VueRx from 'vue-rx';
import { i18n } from './plugins/i18n';
import vClickOutside from 'v-click-outside';

import './plugins/vuetify'; // path to vuetify export
// import './stylus/main.styl';

// import '@/registerServiceWorker';

// Vue.use(BootstrapVue);
Vue.use(VueMDCAdapter);
Vue.use(Vue2Filters);
Vue.use(VueNumeric);
Vue.use(VueRx);
Vue.use(vClickOutside);

Vue.config.productionTip = false;

// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'normalize.css/normalize.css';

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
// #endregion init Vue
