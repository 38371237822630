var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "activity-settings-capture app-page-activity mdc-layout-grid app-page-activity--active",
      attrs: { id: "capture-setting" }
    },
    [
      _c("div", { staticClass: "mdc-layout-grid__inner" }, [
        !_vm.clearing
          ? _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
              },
              [
                _c("mdc-switch", {
                  staticClass:
                    "mdc-typography--headline6 title-font-size switch-button",
                  attrs: {
                    "align-end": true,
                    label: _vm.$t("settings.capture.enableAuthorization")
                  },
                  model: {
                    value: _vm.clearing,
                    callback: function($$v) {
                      _vm.clearing = $$v
                    },
                    expression: "clearing"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.clearing
          ? _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet capture-mode-box"
              },
              [
                _c("p", { staticClass: "p-title" }, [
                  _vm._v(_vm._s(_vm.$t("settings.capture.mode.label")))
                ]),
                _c("mdc-radio", {
                  staticClass: "capture-mode",
                  attrs: {
                    label: _vm.$t("settings.capture.mode.auto"),
                    name: "captureMode",
                    value: "auto",
                    disabled: !_vm.clearing
                  },
                  model: {
                    value: _vm.captureMode,
                    callback: function($$v) {
                      _vm.captureMode = $$v
                    },
                    expression: "captureMode"
                  }
                }),
                _c("mdc-radio", {
                  staticClass: "capture-mode",
                  attrs: {
                    label: _vm.$t("settings.capture.mode.manual"),
                    name: "captureMode",
                    value: "manual",
                    disabled: !_vm.clearing
                  },
                  model: {
                    value: _vm.captureMode,
                    callback: function($$v) {
                      _vm.captureMode = $$v
                    },
                    expression: "captureMode"
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c("mdc-dialog", {
        ref: "captureMode",
        attrs: {
          title: _vm.$t("settings.capture.confirm"),
          accept: _vm.$t("actions.confirm"),
          cancel: _vm.$t("actions.cancel")
        },
        on: { cancel: _vm.clearingChangeCanceled, accept: _vm.accept },
        model: {
          value: _vm.clearingDialogOpen,
          callback: function($$v) {
            _vm.clearingDialogOpen = $$v
          },
          expression: "clearingDialogOpen"
        }
      }),
      _c("mdc-dialog", {
        ref: "captureMode",
        attrs: {
          title: _vm.$t("settings.capture.confirm"),
          accept: _vm.$t("actions.confirm"),
          cancel: _vm.$t("actions.cancel")
        },
        on: { cancel: _vm.captureModeChangeCanceled, accept: _vm.accept },
        model: {
          value: _vm.captureModeDialogOpen,
          callback: function($$v) {
            _vm.captureModeDialogOpen = $$v
          },
          expression: "captureModeDialogOpen"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }