var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "activity-recurrents-list",
      attrs: { id: "remote-history" }
    },
    [
      _c(
        "div",
        { staticClass: "mdc-layout-grid__inner" },
        [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.remoteStatus,
                      expression: "remoteStatus"
                    }
                  ],
                  staticClass: "mdc-text-field__input",
                  attrs: { options: _vm.statusOptions },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.remoteStatus = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { disabled: "" }, domProps: { value: null } },
                    [_vm._v(_vm._s(_vm.$t("status.title")))]
                  ),
                  _vm._l(_vm.statusOptions, function(status) {
                    return _c(
                      "option",
                      { key: status.id, domProps: { value: status.value } },
                      [_vm._v(_vm._s(_vm.$t(status.text)))]
                    )
                  })
                ],
                2
              ),
              _c("label", { staticClass: "mdc-floating-label" }, [
                _vm._v(_vm._s(_vm.$t("status.title")))
              ]),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ]
          ),
          _vm._l(_vm.items, function(item) {
            return _c("RemoteHistoryItem", {
              key: item.id,
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet",
              attrs: { remote: item },
              on: {
                click: function($event) {
                  _vm.$router.push({
                    name: "remote.details",
                    params: { id: item.id, remoteProp: item }
                  })
                }
              }
            })
          }),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav",
              staticStyle: { "justify-content": "center" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "mdc-button",
                  attrs: { disabled: !_vm.moreButton },
                  on: { click: _vm.moreItems }
                },
                [
                  _c("span", { staticClass: "mdc-fab__label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.moreButton
                          ? _vm.$t("actions.loading")
                          : _vm.$t("actions.noMore")
                      )
                    )
                  ])
                ]
              )
            ]
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }