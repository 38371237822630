export default {
    INVALID_EMAIL_FORAMT: 'AUTH.INVALID_EMAIL_FORMAT',
    INVALID_STATEMENTDESCRIPTOR_FORMAT_TOO_SHORT: 'AUTH.INVALID_STATEMENTDESCRIPTOR_FORMAT_TOO_SHORT',
    INVALID_STATEMENTDESCRIPTOR_FORMAT_TOO_LONG: 'AUTH.INVALID_STATEMENTDESCRIPTOR_FORMAT_TOO_LONG',
    INVALID_NAME_FORMAT: 'AUTH.INVALID_NAME_FORMAT',
    INVALID_COMPANY_NAME_FORMAT: 'AUTH.INVALID_COMPANY_NAME_FORMAT',
    INVALID_PASSWORD_FORMAT: 'AUTH.INVALID_PASSWORD_FORMAT',
    CONFIRM_FAILURE: 'AUTH.CONFIRM_FAILURE',
    INVALID_MOBILE_FORMAT: 'AUTH.INVALID_MOBILE_FORMAT',
    INVALID_VERIFICATION_CODE: 'AUTH.INVALID_VERIFICATION_CODE',
    INVALID_HKID: 'AUTH.INVALID_HKID',
    INVALID_SUPPORTING_DOCUMENT: 'AUTH.INVALID_SUPPORTING_DOCUMENT',
    ADDRESS_TOO_LONG: 'AUTH.ADDRESS_TOO_LONG',
    EMPTY_FIELD: 'AUTH.EMPTY_FIELD',
    NO_SELECTED_ACCOUNT: 'AUTH.NO_SELECTED_ACCOUNT',
    NO_SELECTED_USER: 'AUTH.NO_SELECTED_USER',
    INCORRECT_CONFIRMED_VALUE: 'AUTH.INCORRECT_CONFIRMED_VALUE',
    DUPLICATED_OLD_VALUE: 'AUTH.DUPLICATED_OLD_VALUE',
    DUPLICATED_MERCHANT_NAME: 'AUTH.DUPLICATED_MERCHANT_NAME',

    EMAIL_USED: 'AUTH.EMAIL_USED',
    SIGNUP_SUCCESS: 'AUTH.SIGNUP_SUCCESS',
    LOGIN_SUCCESS: 'AUTH.LOGIN_SUCCESS',
    ACTIVATE_USER_SUCCESS: 'AUTH.ACTIVATE_USER_SUCCESS',
    INCORRECT_CREDENTIALS: 'AUTH.INCORRECT_CREDENTIALS',

    HOLDER_NAME_TOO_LONG: 'AUTH.HOLDER_NAME_TOO_LONG',
    INVALID_HOLDER_NAME: 'AUTH.INVALID_HOLDER_NAME',
    NOT_ACCEPTING_TOS: 'AUTH.NOT_ACCEPTING_TOS',

    DOCUMENT_ERROR: 'AUTH.DOCUMENT_ERROR',
    LOGIN_AGAIN: 'AUTH.LOGIN_AGAIN',
};
