export default class Loop {
    private started = false;
    private _starting = false;
    private _stop = false;
    private _stopping = false;
    private stopped = true;
    private loopFunction?: any;
    private context?: any;
    private loop?: any;
    private stopPromise?: any;
    constructor(context: any, loopFunction: any) {
        this.context = context;
        this.loopFunction = loopFunction;
        this.start;
    }
    public start = async (...args: Array<any>) => {
        if (this.started || this._starting) return;
        this._starting = true;
        this._stopping && (await this.stopPromise);
        let resolve;
        this.stopPromise = new Promise(res => {
            resolve = res;
        });
        this.stopPromise.resolve = resolve;
        this.loop = (async () => {
            this.started = true;
            this._starting = false;
            this.stopped = false;
            this._stop = false;
            do {
                try {
                    await this.loopFunction.call(this.context, ...args);
                } catch (e) {}
            } while (!this._stop);
            this.started = false;
            this.stopped = true;
            this.stopPromise.resolve();
        })().catch(e => {
            this.started = false;
            this.stopped = true;
            this.stopPromise.resolve();
        });
    };
    public stop = async () => {
        if (this.stopped || this._stopping) return;
        this._stop = true;
        this._stopping = true;
        await this.stopPromise;
        this._stopping = false;
    };
}
