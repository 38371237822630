export const dashboard = {
    transaction: '支払い',
    refund: '返金',
    volume: '金額',
    count: '件数',
    daily: '今日',
    weekly: '7日',
    monthly: '今月',
    payout: {
        history: '入金履歴 (クレジットカード)',
        arrivalDate: '支払い日',
        expectedArrivalDate: '推定支払い日',
        total: '総額',
        net: '純額',
        type: {
            title: '入金タイプ',
            charge: '支払い',
            refund: '返金',
            adjustment: '調整額',
        },
        payment: {
            title: '支払いタイプ',
        },
    },
};
