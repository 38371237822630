var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "activity-payment-remote-link-item" }, [
    _c(
      "div",
      { staticClass: "mdc-layout-grid__inner" },
      _vm._l(_vm.items, function(item) {
        return _c("CampaignSummaryItem", {
          key: item.id,
          staticClass:
            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet",
          attrs: { campaign: item },
          on: {
            preview: function($event) {
              _vm.$router.push({
                name: "campaign-history-preview",
                params: {
                  id: item.id,
                  displayNameProp: _vm.displayName,
                  urlNameProp: _vm.urlName
                }
              })
            },
            orders: function($event) {
              _vm.$router.push({
                name: "campaign-order-list",
                params: { campaignId: item.id }
              })
            }
          }
        })
      })
    ),
    _c(
      "div",
      {
        staticClass:
          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav",
        staticStyle: { "justify-content": "center" }
      },
      [
        _c(
          "button",
          {
            staticClass: "mdc-button",
            attrs: { disabled: !_vm.moreButton },
            on: { click: _vm.moreItems }
          },
          [
            _c("span", { staticClass: "mdc-fab__label" }, [
              _vm._v(
                _vm._s(
                  _vm.moreButton
                    ? _vm.$t("actions.loadMore")
                    : _vm.$t("actions.noMore")
                )
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }