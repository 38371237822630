

































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IMaskComponent } from 'vue-imask';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import { isPhoneNumber } from '../../utils/validators';
import AUTH_MSG from '../../constants/AUTH';

const env = process.env.VUE_APP_ENV;
@Component({
    components: {
        'imask-input': IMaskComponent,
    },
})
export default class RemoteCampaignContactItem extends Vue {
    private mdcTextFields!: any;
    private countryCodeList = this.$store.state.countryCodeList;

    @Prop() index!: number;

    @Prop() contact!: any;

    @Prop() editingContactList!: boolean;

    private contactEditingInfo = { ...this.contact };
    private editing = true;

    public mounted() {
        // sort the country code due to language
        if (this.$i18n.locale == 'en') {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.en > b.en ? 1 : -1));
        } else {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.phone_code >= b.phone_code ? 1 : -1));
        }
        this.contactEditingInfo.countryCode = {
            phone_code: '+852',
            en: 'Hong Kong (SAR)',
            zh: '香港',
            cn: '香港',
            alphanumeric_sender: 1,
        };
        this.initMDCTextFields();
    }

    private edit() {
        if (this.editingContactList) {
            this.$root.$emit('error', { message: this.$t('erros.GENERAL.editing') });
            return;
        }

        this.contactEditingInfo = { ...this.contact };
        this.editing = true;
        this.$emit('edit', true);
    }

    private cancel() {
        this.editing = false;
        this.$emit('edit', false);
    }

    private save() {
        if (!this.contactEditingInfo.shopName || !this.contactEditingInfo.countryCode.phone_code || !this.contactEditingInfo.number) {
            this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.empty_contact') });
            return;
        }
        if (!isPhoneNumber(`${this.contactEditingInfo.countryCode.phone_code}${this.contactEditingInfo.number}`)) {
            this.$root.$emit('error', { message: AUTH_MSG.INVALID_MOBILE_FORMAT });
            return;
        }
        this.editing = false;
        this.$emit('edit', false);
        this.$emit('save', this.contactEditingInfo);
    }

    private deleteContact() {
        this.$emit('delete');
    }

    get hasData() {
        return this.contact.shopName && this.contact.number ? true : false;
    }

    get isTestingMode() {
        return env !== 'production' ? true : false;
    }

    private initMDCTextFields() {
        this.mdcTextFields = Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }

    public autofill(isAutoFill?: boolean) {
        let c = this.contactEditingInfo;
        c.shopName = 'beauty shop';
        c.address = 'happy street';
        c.countryCode = {
            phone_code: '+852',
            en: 'Hong Kong (SAR)',
            zh: '香港',
            cn: '香港',
            alphanumeric_sender: 1,
        };
        c.number = '65912791';
    }
}
