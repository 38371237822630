var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "activity-dashboard-transfer-expanded app-page-activity mdc-layout-grid app-page-activity--active bottom-nav--hidden"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "dashboard-card dashboard-card--expanded transfer-history"
        },
        [
          _c(
            "a",
            {
              staticClass: "close-icon",
              on: {
                click: function($event) {
                  _vm.$router.go(-1)
                }
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "close-icon__icon",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 24 24"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                    }
                  })
                ]
              )
            ]
          ),
          _c("div", { staticClass: "dashboard-card__title" }, [
            _vm._v(_vm._s(_vm.$t("general.history.title")))
          ]),
          _c(
            "div",
            {
              staticClass:
                "dashboard-card__content dashboard-card__content--extended"
            },
            [
              _c(
                "div",
                { staticClass: "mdc-list--wrapper" },
                [
                  _c("PayoutList", {
                    attrs: { payouts: _vm.payouts, dividers: "" },
                    on: {
                      click: function($event) {
                        _vm.showPayout($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "dashboard-card__action" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.gettingPayouts,
                        expression: "!gettingPayouts"
                      }
                    ],
                    staticClass: "dashboard-card__action--wrapper"
                  },
                  [
                    _c(
                      "mdc-button",
                      {
                        attrs: { disabled: !_vm.moreButton, dense: "" },
                        on: { click: _vm.moreItems }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.moreButton
                              ? _vm.$t("actions.loadMore")
                              : _vm.$t("actions.noMore")
                          )
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.gettingPayouts,
                        expression: "gettingPayouts"
                      }
                    ],
                    staticClass: "dashboard-card__action--wrapper"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "spinner-wrapper" },
                      [
                        _c("Spinner1", {
                          staticClass: "loading-spinner",
                          attrs: { active: _vm.gettingPayouts }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }