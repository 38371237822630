import { render, staticRenderFns } from "./Review.vue?vue&type=template&id=66a16cef&scoped=true"
import script from "./Review.vue?vue&type=script&lang=ts"
export * from "./Review.vue?vue&type=script&lang=ts"
import style0 from "./Review.vue?vue&type=style&index=0&id=66a16cef&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66a16cef",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VCardText,VExpansionPanel,VExpansionPanelContent,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/home/leonlai257/jarvixpay/front-end/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('66a16cef', component.options)
    } else {
      api.reload('66a16cef', component.options)
    }
    module.hot.accept("./Review.vue?vue&type=template&id=66a16cef&scoped=true", function () {
      api.rerender('66a16cef', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/signup/Review.vue"
export default component.exports