var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "confirm-dialog" },
    [
      _c(
        "mdc-dialog",
        {
          attrs: { title: _vm.title, accept: _vm.$t("actions.accept") },
          on: { accept: _vm.onAccept },
          model: {
            value: _vm.open,
            callback: function($$v) {
              _vm.open = $$v
            },
            expression: "open"
          }
        },
        [_c("div", [_vm._v(_vm._s(_vm.message))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }