import MButton from 'material-components-vue/dist/button';
import MCard from 'material-components-vue/dist/card';
import MCheckbox from 'material-components-vue/dist/checkbox';
import MChips from 'material-components-vue/dist/chips';
import MDialog from 'material-components-vue/dist/dialog';
import MDrawer from 'material-components-vue/dist/drawer';
import MElevation from 'material-components-vue/dist/elevation';
import MFab from 'material-components-vue/dist/fab';
import MFloatingLabel from 'material-components-vue/dist/floating-label';
import MFormField from 'material-components-vue/dist/form-field';
import MGridList from 'material-components-vue/dist/grid-list';
import MIconButton from 'material-components-vue/dist/icon-button';
import MIcon from 'material-components-vue/dist/icon';
import MImageList from 'material-components-vue/dist/image-list';
import MLayoutGrid from 'material-components-vue/dist/layout-grid';
import MLineRipple from 'material-components-vue/dist/line-ripple';
import MLinearProgress from 'material-components-vue/dist/linear-progress';
import MList from 'material-components-vue/dist/list';
import MMenu from 'material-components-vue/dist/menu';
import MNotchedOutline from 'material-components-vue/dist/notched-outline';
import MRadio from 'material-components-vue/dist/radio';
import MRipple from 'material-components-vue/dist/ripple';
import MSelect from 'material-components-vue/dist/select';
import MShape from 'material-components-vue/dist/shape';
import MSlider from 'material-components-vue/dist/slider';
import MSnackbar from 'material-components-vue/dist/snackbar';
import MSwitch from 'material-components-vue/dist/switch';
import MTabs from 'material-components-vue/dist/tabs';
import MTextfield from 'material-components-vue/dist/textfield';
import MTheme from 'material-components-vue/dist/theme';
import MToolbar from 'material-components-vue/dist/toolbar';
import MTopAppBar from 'material-components-vue/dist/top-app-bar';
import MTypography from 'material-components-vue/dist/typography';

export {
    MButton,
    MCard,
    MCheckbox,
    MChips,
    MDialog,
    MDrawer,
    MElevation,
    MFab,
    MFloatingLabel,
    MFormField,
    MGridList,
    MIconButton,
    MIcon,
    MImageList,
    MLayoutGrid,
    MLineRipple,
    MLinearProgress,
    MList,
    MMenu,
    MNotchedOutline,
    MRadio,
    MRipple,
    MSelect,
    MShape,
    MSlider,
    MSnackbar,
    MSwitch,
    MTabs,
    MTextfield,
    MTheme,
    MToolbar,
    MTopAppBar,
    MTypography,
};

// x = x.split('\n').map(_=>_.slice(45).slice(0, -2))
// console.log(x.map(_=>`import M${camelSentence(_)} from 'material-components-vue/dist/${_}';`).join('\n'))
// console.log(`export { ${x.map(_=>'M'+camelSentence(_)).join(', ')} };`)
