






import { Component, Prop, Vue } from 'vue-property-decorator';
import { MDCDialog } from '@material/dialog';
import TERMS_HTML from '@/constants/TERMS_HTML';

@Component
export default class Terms extends Vue {
    private termsHTML = TERMS_HTML;
    private modalOpen: boolean = false;
    public dialog!: any;

    public show() {
        //this.dialog.show();
        this.modalOpen = true;
    }
}
