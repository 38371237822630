var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "client-receipt" }, [
    _c(
      "div",
      { staticClass: "app-page app-page-payment-history app-page--active" },
      [
        _c("div", { staticClass: "app-page-container" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid app-page-activity app-page-activity--active activity-payment-history-expanded"
            },
            [
              _vm.charge
                ? _c("div", { staticClass: "payment-history-expanded-item" }, [
                    _c("div", {
                      staticClass: "payment-history-expanded-item__background"
                    }),
                    _vm.charge
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "payment-history-expanded-item__content"
                          },
                          [
                            _c("div", { staticClass: "section" }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mdc-typography--headline6",
                                    staticStyle: {
                                      margin: "0",
                                      "font-size": "1em",
                                      opacity: "0.75"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    #" +
                                        _vm._s(_vm.charge.receipt_no) +
                                        "\n                                "
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "section" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row row--more-margin row--space-between"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "payment-history-expanded-item__amount mdc-typography--headline4"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm._f("currencySymbolDisplay")(
                                              _vm.charge.currency
                                            )
                                          ) +
                                          _vm._s(_vm.charge.amount) +
                                          "\n                                "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "payment-history-expanded-item__amount mdc-typography--body2"
                                    },
                                    [_vm._v(_vm._s(_vm.hkdConvent))]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row row--more-margin row--space-between"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "payment-history-expanded-item__date mdc-typography--caption"
                                    },
                                    [_vm._v(_vm._s(_vm.formatCreatedDate))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "payment-history-expanded-item__time mdc-typography--caption"
                                    },
                                    [_vm._v(_vm._s(_vm.formatCreatedTime))]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row row--more-margin row--space-between"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tags__group" },
                                    [
                                      _c("ChargeStatusTag", {
                                        attrs: { status: _vm.charge }
                                      }),
                                      _vm.charge.connect_recurrence_id
                                        ? _c("RecurrentStatusTag")
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "buttons__group" }, [
                                    _vm.charge.status === "failed"
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "mdc-button mdc-button--raised",
                                            on: {
                                              click: function($event) {
                                                _vm.$emit("retry")
                                              }
                                            }
                                          },
                                          [_vm._m(0)]
                                        )
                                      : _vm._e()
                                  ])
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "section" }, [
                              _vm._m(1),
                              _c("div", { staticClass: "row" }, [
                                _vm.statementDescriptor
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "payment-history-expanded-item__desc mdc-typography--body1"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(_vm.statementDescriptor) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "payment-history-expanded-item__desc mdc-typography--body1"
                                      },
                                      [_vm._v("—")]
                                    )
                              ])
                            ]),
                            _c("div", { staticClass: "section" }, [
                              _vm._m(2),
                              _c("div", { staticClass: "row" }, [
                                _vm.charge.description
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "payment-history-expanded-item__desc mdc-typography--body1"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(_vm.charge.description) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "payment-history-expanded-item__desc mdc-typography--body1"
                                      },
                                      [_vm._v("—")]
                                    )
                              ])
                            ]),
                            _c("div", { staticClass: "section" }, [
                              _vm._m(3),
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c("VirtualCard", {
                                    attrs: { card: _vm.charge.creditcard }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c("div", { staticClass: "section" }, [
                              _vm._m(4),
                              _vm.charge.status
                                ? _c(
                                    "div",
                                    { staticClass: "row row--space-between" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "payment-history-expanded-item__desc mdc-typography--body2"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                {
                                                  succeeded: "成功付款",
                                                  failed: "付款失敗",
                                                  pending: "處理中"
                                                }[_vm.charge.status]
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "payment-history-expanded-item__datetime mdc-typography--caption"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.formatFullCreatedDate
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "mdc-button__text" }, [
      _c("span", [_vm._v("請再次付款")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "mdc-typography--headline6" }, [_vm._v("商戶")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "mdc-typography--headline6" }, [
        _vm._v("商品詳情")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "mdc-typography--headline6" }, [
        _vm._v("信用卡資料")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "mdc-typography--headline6" }, [_vm._v("記錄")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }