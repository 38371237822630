






import { Component, Vue, Prop } from 'vue-property-decorator';

interface TAndC {
    [c: string]: string;
}

interface statusList {
    [index: string]: TAndC;
}

@Component
export default class PayoutStatus extends Vue {
    // prettier-ignore
    @Prop(String) status!: String;
    // prettier-ignore
    @Prop(Boolean) small?: Boolean;

    private statusProps_: statusList = {
        paid: {
            class: 'tags--succeeded',
            text: 'status.arrived',
        },
        pending: {
            class: 'tags--pending',
            text: 'status.pending',
        },
        in_transit: {
            class: 'tags--pending',
            text: 'status.inTransit',
        },
        canceled: {
            class: 'tags--refunded',
            text: 'status.canceled',
        },
        failed: {
            class: 'tags--failed',
            text: 'status.failed',
        },
    };

    get statusProps() {
        for (let key in this.statusProps_) {
            // console.log(key);
            if (this.statusProps_.hasOwnProperty(key)) {
                this.statusProps_[key].text = this.$t(this.statusProps_[key].text) as string;
            }
        }
        return this.statusProps_;
    }
}
