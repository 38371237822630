var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "activity-settings-export app-page-activity app-page-activity--active mdc-layout-grid"
    },
    [
      _c("div", { staticClass: "layout-grid__inner" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card__section" }, [
            _c("div", { staticClass: "mdc-typography--headline6" }, [
              _vm._v(_vm._s(_vm.$t("settings.export.label")))
            ]),
            _c("div", { staticClass: "mdc-typography--body2" }, [
              _vm._v(_vm._s(_vm.$t("settings.export.reminder.type")))
            ])
          ]),
          _c("div", { staticClass: "card__section" }, [
            _c(
              "div",
              { staticClass: "flex-column select-group" },
              _vm._l(_vm.exportTypes, function(v, k, i) {
                return _c("mdc-radio", {
                  key: _vm.selectionsName_ + i,
                  attrs: {
                    value: k,
                    label: _vm.$t(v.label.zhHK),
                    name: _vm.selectionsName_
                  },
                  model: {
                    value: _vm.exportType,
                    callback: function($$v) {
                      _vm.exportType = $$v
                    },
                    expression: "exportType"
                  }
                })
              })
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "card", class: { disabled: !_vm.exportType } },
          [
            _c("div", { staticClass: "card__section" }, [
              _c("div", { staticClass: "mdc-typography--headline6" }, [
                _vm._v(_vm._s(_vm.$t("settings.export.period")))
              ]),
              _c("div", { staticClass: "mdc-typography--body2" }, [
                _vm._v(_vm._s(_vm.$t("settings.export.reminder.period")))
              ])
            ]),
            _c(
              "div",
              { staticClass: "card__section date-group" },
              [
                _c(
                  "m-textfield",
                  {
                    ref: "dateRangeFrom",
                    class: { "mdc-text-field--empty": !_vm.from },
                    attrs: {
                      id: _vm.dateRange.from.id,
                      type: "date",
                      autocorrect: "off",
                      spellcheck: "false",
                      autocomplete: "off",
                      outlined: ""
                    },
                    model: {
                      value: _vm.from,
                      callback: function($$v) {
                        _vm.from = $$v
                      },
                      expression: "from"
                    }
                  },
                  [
                    _c(
                      "m-floating-label",
                      {
                        attrs: {
                          for: _vm.dateRange.from.id,
                          floatAbove: !!_vm.from
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t(_vm.dateRange.from.label.zhHK)))]
                    ),
                    _c("m-notched-outline", { attrs: { notched: !!_vm.from } })
                  ],
                  1
                ),
                _c(
                  "m-textfield",
                  {
                    ref: "dateRangeTo",
                    class: { "mdc-text-field--empty": !_vm.to },
                    attrs: {
                      id: _vm.dateRange.to.id,
                      type: "date",
                      autocorrect: "off",
                      spellcheck: "false",
                      autocomplete: "off",
                      outlined: ""
                    },
                    model: {
                      value: _vm.to,
                      callback: function($$v) {
                        _vm.to = $$v
                      },
                      expression: "to"
                    }
                  },
                  [
                    _c(
                      "m-floating-label",
                      {
                        attrs: {
                          for: _vm.dateRange.to.id,
                          floatAbove: !!_vm.to
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t(_vm.dateRange.to.label.zhHK)))]
                    ),
                    _c("m-notched-outline", { attrs: { notched: !!_vm.to } })
                  ],
                  1
                )
              ],
              1
            )
          ]
        ),
        _vm.master
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.exportType != "payouts" &&
                      _vm.exportType != "onlinePayments",
                    expression:
                      "exportType != 'payouts' && exportType != 'onlinePayments'"
                  }
                ],
                staticClass: "card",
                class: { disabled: !_vm.from || !_vm.to }
              },
              [
                _c("div", { staticClass: "card__section" }, [
                  _c("div", { staticClass: "mdc-typography--headline6" }, [
                    _vm._v(_vm._s(_vm.$t("settings.branch.label")))
                  ]),
                  _c("div", { staticClass: "mdc-typography--body2" }, [
                    _vm._v(_vm._s(_vm.$t("settings.export.reminder.branch")))
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "card__section" },
                  [
                    !_vm.shopsSelection.length
                      ? _c("Spinner1", {
                          staticClass: "loading",
                          staticStyle: { "font-size": "1.5rem" },
                          attrs: { active: true }
                        })
                      : _c(
                          "div",
                          { staticClass: "flex-column checkbox-group" },
                          [
                            _c(
                              "m-form-field",
                              [
                                _c("m-checkbox", {
                                  attrs: {
                                    id: "shopsSelectionAll_",
                                    indeterminate:
                                      !_vm.shopsSelectionAll &&
                                      _vm.shopsSelectionSome
                                  },
                                  model: {
                                    value: _vm.shopsSelectionAll,
                                    callback: function($$v) {
                                      _vm.shopsSelectionAll = $$v
                                    },
                                    expression: "shopsSelectionAll"
                                  }
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "shopsSelectionAll_" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("settings.branch.label")) +
                                        _vm._s(
                                          _vm.shopsSelectionCount
                                            ? " (" +
                                              _vm.shopsSelectionCount +
                                              ")"
                                            : ""
                                        )
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "mdc-typography--caption" },
                                  [_vm._v(_vm._s(_vm.$t("status.all")))]
                                )
                              ],
                              1
                            ),
                            _c("hr"),
                            _vm._l(_vm.shopsSelection, function(s) {
                              return _c(
                                "m-form-field",
                                { key: s.uid },
                                [
                                  _c("m-checkbox", {
                                    attrs: { id: s.uid },
                                    model: {
                                      value: s.checked,
                                      callback: function($$v) {
                                        _vm.$set(s, "checked", $$v)
                                      },
                                      expression: "s.checked"
                                    }
                                  }),
                                  _c("label", { attrs: { for: s.uid } }, [
                                    _vm._v(_vm._s(s.store_name))
                                  ])
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.exportType == "statement",
                expression: "exportType == 'statement'"
              }
            ],
            staticClass: "card",
            class: {
              disabled:
                !_vm.from ||
                !_vm.to ||
                (_vm.master &&
                  _vm.exportType != "payouts" &&
                  _vm.exportType != "onlinePayments" &&
                  !_vm.shopsSelectionSome)
            }
          },
          [
            _c("div", { staticClass: "card__section" }, [
              _c("div", { staticClass: "mdc-typography--headline6" }, [
                _vm._v(_vm._s(_vm.$t("settings.export.bybranch.title")))
              ]),
              _c("div", { staticClass: "mdc-typography--body2" }, [
                _vm._v(_vm._s(_vm.$t("settings.export.reminder.bybranch")))
              ])
            ]),
            _c("div", { staticClass: "card__section" }, [
              _c(
                "div",
                { staticClass: "flex-column select-group" },
                [
                  _c(
                    "m-form-field",
                    [
                      _c("mdc-radio", {
                        attrs: {
                          name: "radioBranch",
                          id: "brBranchTrue_",
                          value: "true"
                        },
                        model: {
                          value: _vm.byBranchToggle,
                          callback: function($$v) {
                            _vm.byBranchToggle = $$v
                          },
                          expression: "byBranchToggle"
                        }
                      }),
                      _c("label", { attrs: { for: "brBranchTrue_" } }, [
                        _vm._v(_vm._s(_vm.$t("settings.export.bybranch.true")))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "m-form-field",
                    [
                      _c("mdc-radio", {
                        attrs: {
                          name: "radioBranch",
                          id: "brBranchFalse_",
                          value: "false",
                          checked: ""
                        },
                        model: {
                          value: _vm.byBranchToggle,
                          callback: function($$v) {
                            _vm.byBranchToggle = $$v
                          },
                          expression: "byBranchToggle"
                        }
                      }),
                      _c("label", { attrs: { for: "brBranchFalse_" } }, [
                        _vm._v(_vm._s(_vm.$t("settings.export.bybranch.false")))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "steps-nav",
            class: {
              disabled:
                !_vm.from ||
                !_vm.to ||
                (_vm.master &&
                  _vm.exportType != "payouts" &&
                  _vm.exportType != "onlinePayments" &&
                  !_vm.shopsSelectionSome)
            }
          },
          [
            _c("br"),
            _c(
              "m-fab",
              {
                staticClass: "mdc-fab--icon-reversed",
                attrs: { extended: "" },
                on: { click: _vm.generateExportDownloadLink }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "mdc-fab__icon",
                    attrs: {
                      slot: "icon",
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 24 24"
                    },
                    slot: "icon"
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                      }
                    })
                  ]
                ),
                _c("span", [_vm._v(_vm._s(_vm.$t("settings.export.title")))])
              ]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }