var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bottom-nav__container",
      style: { height: _vm.height && _vm.height + "px" }
    },
    [
      _c(
        "div",
        { staticClass: "bottom-nav bottom-nav__inner" },
        _vm._l(_vm.items, function(item) {
          return _c("NavbarItem", {
            key: item.to.name,
            ref: "navbarItems",
            refInFor: true,
            attrs: { params: item },
            nativeOn: {
              click: function($event) {
                _vm.$emit("navbar-item-clicked", item.to.name)
              }
            }
          })
        })
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }