





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CampaignSummaryItem from '@/components/campaign-history/CampaignSummaryItem.vue';

import { moment_HK as moment } from '@/store';
import { scrollTop } from '@/utils/scrollTop';

@Component({
    components: {
        CampaignSummaryItem,
    },
})
export default class CampaignHistoryList extends Vue {
    private displayName = '';
    private urlName = '';
    private items = [];
    private timestamp?: number;
    private moreButton = true;
    public gettingCampaigns = false;

    public async created() {
        const data = await this.$store.dispatch('retreiveMerchantCampaignNames');
        this.displayName = data.displayName;
        this.urlName = data.urlName;

        this.fetchItems();
    }

    public async fetchItems() {
        if (!this.gettingCampaigns) {
            this.moreButton = true;
            this.timestamp = moment().unix();
            this.items = await this.getCampaigns();
            scrollTop();
        }
    }

    public async moreItems() {
        const newItem = await this.getCampaigns();

        if (newItem.length) {
            this.items = this.items.concat(newItem);
        }
    }

    public async getCampaigns() {
        let newItems: any;

        try {
            newItems = await this.$store.dispatch('getCampaignsSummary', {
                timestamp: this.timestamp,
                limit: 10,
            });

            if (newItems.length < 10) {
                this.moreButton = false;
            } else {
                this.timestamp = moment((newItems[newItems.length - 1] as any).created_at).unix();
            }
        } catch (error) {
            this.$root.$emit('error', error);
        } finally {
            this.gettingCampaigns = false;
        }

        if (newItems) {
            return newItems;
        } else {
            return [];
        }
    }
}
