export const customer = {
    title: '客户',
    name: '姓名',
    countryCode: '国家代码',
    phone: '电话',
    email: '电邮',
    address: '地址',
    remarks: '备注',
    refNo: '参考编号',
    optional: ' (选填)',
    required: ' (必填)',
    new: '新增客户',
    newClient: '新客户',
    currentClient: '現有客戶',
    gender: '性别',
    male: '男',
    female: '女',
    age: '年龄',
    orderDate: '预订日期',
    orderTime: '预订时间',
    specialRequest: '客户咨询内容',
};
