export const settings = {
    captureNow: '立即清机 (直碌)',
    authorized: {
        transaction: '已授权笔数',
        volume: '已授权金额',
    },
    user: {
        label: '用户',
        create: '创建用户',
        type: '用户类型',
        superuser: '管理员',
        normaluser: '普通用户',
        email: '电邮',
        display: '显示名称',
        phone: '电话 (选填)',
        info: '用户资料',
    },
    branch: {
        label: '分店',
        create: '创建分店',
        modify: '更改分店',
        receipt: {
            name: '分店名称 (英文) - 收据',
            address: '分店地址 (英文) - 收据',
        },
        campaign: {
            name: '分店名称 (英文) - 互联网',
            address: '分店名称 (英文) - 互联网',
        },
    },
    merchant: {
        setup: '初次设定',
        setupMessage: '如果需要再次更改，请前往设定页面。',
        modify: '更改商户资料',
        name: {
            receipt: '商户名称 (收据)',
            campaign: '商户名称 (互联网)',
        },
        logo: {
            campaign: '商户Logo (互联网)',
        },
        current: '目前名称',
        new: '新名称',
        confirm: '确认新名称',
        display: '显示名称',
        url: 'URL名称 (English Only)',
        uploadLogoWarning: '如有需要，请上传贵商户的Logo。',
        modifyCampaignWarning: '注意！如果更改了域名名称，过往的连结将会失效，并立即更新您的连结。',
    },
    export: {
        title: '汇出',
        label: '汇出报表',
        type: {
            transaction: '交易记录',
            journal: '流水记录',
            online: '网上通道记录',
            statement: '月结单',
            payout: '转帐记录',
        },
        reminder: {
            type: '请选择汇出的报表。',
            period: '请选择汇出的日期',
            branch: '请选择汇出的分店。',
            bybranch: '请选择汇出的月结单选项',
        },
        bybranch: {
            title: '月结单选项',
            true: '分开每个分店',
            false: '整合所有分店',
        },
        period: '范围',
        downloadData: '数据下载',
        downloadAndOpen: '下载 (开启新视窗)',
        copyLinkAndPassword: '复制连结及密码',
        longPressOrCopy: '长按分享/复制',
        expire: '连结将于{0}失效。',
    },
    printer: '打印机',
    connectPrinter: '连接打印机',
    connectPrinterFailed: '连接打印机失败',
    capture: {
        title: '清机 (直碌)',
        enableAuthorization: '开启清机模式',
        mode: {
            label: '模式',
            auto: '自动清机 (每天)',
            manual: '手动',
        },
        summary: {
            succeededTrx: '{0}个款项成功收取',
            failedTrx: '{0}个款项<b>不成功</b>收取',
            contact: '如有问题，请与Jarvix Pay联络',
        },
        confirm: '确认更改清机模式',
    },
    permissions: {
        title: '权限',
        account: {
            addAccount: '新增分店',
            addUser: '新增用户',
            editPermission: '修改权限',
            modifyInformation: '更新商户资料',
        },
        functional: {
            campaignAgent: '代客推广',
            dashboard: '总覧',
            export: '汇出',
            payout: '银行转帐',
            printer: '打印机',
            transactionsHistory: '交易记录',
        },
        payment: {
            cancelRecurrence: '取消月付',
            capture: '清机',
            changeRecurrenceCreditCard: '转换月付信用卡',
            editChargeDescription: '更改交易详情',
            refund: '退款',
            enable_recurrence: '开启月付功能',
            enable_remote: '开启遥距收款功能',
            enable_campaign: '开启宣传功能',
        },
        notification: {
            payment: '收款通知',
            campaign: '宣传通知',
            summary: '总覧通知',
        },
    },
    language: '语言',
    password: {
        label: '密码',
        change: '更改密码',
        reminder: '建议使用与其他服务不同的高强度密码。',
        current: '目前密码',
        new: '新密码',
        confirm: '确认新密码',
    },
    about: '关于',
    logout: {
        title: '登出',
    },
};
