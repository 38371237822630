export const dashboard = {
    transaction: '交易',
    refund: '退款',
    volume: '金額',
    count: '筆數',
    daily: '今日',
    weekly: '7日',
    monthly: '本月',

    payout: {
        history: '轉帳記錄 (信用卡)',
        arrivalDate: '到帳日',
        expectedArrivalDate: '預計到帳日',
        total: '總額',
        net: '淨額',
        type: {
            title: '轉帳類別',
            charge: '付款',
            refund: '退款',
            adjustment: '其他調整',
        },
        payment: {
            title: '付款途徑',
        },
    },
};
