



































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { isEmail, isMobileNumber } from '../../utils/validators';
import AUTH_MSG from '@/constants/AUTH';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import { IMaskComponent } from 'vue-imask';

@Component({
    components: {
        'imask-input': IMaskComponent,
    },
})
export default class NewStore extends Vue {
    private mdcTextField: any;
    private receiptStoreName = '';
    private receiptStoreAddress = '';
    private campaignStoreName = '';
    private campaignStoreAddress = '';
    private permissions = {
        account: {
            add_user: 1,
            edit_permission: 1,
        },
        payment: {
            refund: 1,
            cancel_recurrence: 1,
            change_recurrence_credit_card: 1,
            edit_charge_description: 1,
            capture: 1,
            enable_recurrence: 1,
            enable_remote: 1,
            enable_campaign: 1,
        },
        functional: {
            dashboard: 1,
            printer: 1,
            export: 1,
            transactions_history: 1,
        },
        // set 0 for disable notification
        notification: {
            payment: 0,
            campaign: 0,
            summary: 0,
        },
    };
    private printer: boolean = false;

    private errors: string[] = [];

    private engCharMask = /^[a-zA-Z0-9!@#$%^&()~`_\-+=,.?/:;{}\[\]| ]*$/;

    public mounted() {
        this.initMDCTextFields();
    }

    protected submit() {
        this.errors = [];

        if (!this.receiptStoreName || !this.receiptStoreAddress || !this.campaignStoreName || !this.campaignStoreAddress) {
            this.errors.push(AUTH_MSG.EMPTY_FIELD);
        }

        if (this.errors.length) {
            return;
        }

        return this.$store
            .dispatch('addStore', {
                receiptStoreName: this.receiptStoreName.trim(),
                receiptStoreAddress: this.receiptStoreAddress.trim(),
                campaignStoreName: this.campaignStoreName.trim(),
                campaignStoreAddress: this.campaignStoreAddress.trim(),
                permissions: this.permissions,
            })
            .then((response: any) => {
                return this.$root.$emit('confirm', {
                    path: '/payment',
                    title: this.$t('status.success'),
                });
            })
            .catch((error: any) => {
                return this.$root.$emit('error', error);
            });
    }

    // animation function
    private initMDCTextFields() {
        this.mdcTextField = Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
