var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.status && _vm.status in _vm.statusProps
    ? _c(
        "div",
        {
          staticClass: "tags filled",
          class: [
            _vm.statusProps[_vm.status].class,
            { "tags--small": _vm.small }
          ]
        },
        [_vm._v("\n    " + _vm._s(_vm.statusProps[_vm.status].text) + "\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }