var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-card transfer-history" }, [
    _c(
      "div",
      { staticClass: "dashboard-card__title dashboard-card__title--small" },
      [_vm._v(_vm._s(_vm.$t("dashboard.payout.history")))]
    ),
    _c(
      "div",
      {
        staticClass: "dashboard-card__content dashboard-card__content--extended"
      },
      [
        _vm.gettingPayouts
          ? _c("Spinner1", { staticClass: "loading-spinner" })
          : _c(
              "div",
              { staticClass: "mdc-list--wrapper" },
              [
                _c("PayoutList", {
                  attrs: { payouts: _vm.payouts },
                  on: {
                    click: function($event) {
                      _vm.showPayout($event)
                    }
                  }
                })
              ],
              1
            )
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "dashboard-card__action dashboard-card__action--extended"
      },
      [
        _c("hr", { staticClass: "dashboard-card__divider" }),
        _c(
          "mdc-button",
          {
            attrs: { dense: "", disabled: !_vm.moreButton },
            on: { click: _vm.showAll }
          },
          [_vm._v(_vm._s(_vm.$t("actions.loadMore")))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }