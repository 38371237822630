var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-page app-page-payment app-page--active" },
    [
      _c("div", { staticClass: "app-page-container" }, [
        _c("div", { staticClass: "external-account" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid app-page-activity app-page-activity--active bottom-nav--hidden"
            },
            [
              _c(
                "form",
                {
                  staticClass: "mdc-layout-grid__inner",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.update($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6"
                    },
                    [_vm._v(_vm._s(_vm.$t("signup.externalAccountInfo")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.holderName,
                            expression: "holderName"
                          }
                        ],
                        staticClass: "mdc-text-field__input",
                        attrs: {
                          type: "text",
                          autocomplete: "off",
                          autocorrect: "off",
                          spellcheck: "false"
                        },
                        domProps: { value: _vm.holderName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.holderName = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label",
                          class:
                            _vm.holderName != ""
                              ? "mdc-floating-label--float-above"
                              : "",
                          attrs: { for: "holderName" }
                        },
                        [_vm._v(_vm._s(_vm.$t("signup.holderName")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.holderType,
                              expression: "holderType"
                            }
                          ],
                          staticClass: "mdc-text-field__input",
                          class: { "select--hidden": !_vm.holderType },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.holderType = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { disabled: "" },
                              domProps: { value: null }
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.holderType")))]
                          ),
                          _c("option", { attrs: { value: "company" } }, [
                            _vm._v(_vm._s(_vm.$t("signup.companyAccount")))
                          ]),
                          _c("option", { attrs: { value: "individual" } }, [
                            _vm._v(_vm._s(_vm.$t("signup.individualAccount")))
                          ])
                        ]
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label",
                          class:
                            _vm.holderType != ""
                              ? "mdc-floating-label--float-above"
                              : ""
                        },
                        [_vm._v(_vm._s(_vm.$t("signup.holderType")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.bank,
                              expression: "bank"
                            }
                          ],
                          staticClass: "mdc-text-field__input",
                          class: { "select--hidden": !_vm.bank },
                          attrs: { options: _vm.bankOptions },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.bank = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { disabled: "" },
                              domProps: { value: null }
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.bank")))]
                          ),
                          _vm._l(_vm.bankOptions, function(bank) {
                            return _c(
                              "option",
                              { key: bank.id, domProps: { value: bank.value } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.locale != "zh_HK"
                                      ? bank.bank_name
                                      : bank.chinese_name
                                  )
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label",
                          class:
                            _vm.bank != null
                              ? "mdc-floating-label--float-above"
                              : ""
                        },
                        [_vm._v(_vm._s(_vm.$t("signup.bank")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.branchCode,
                            expression: "branchCode"
                          }
                        ],
                        staticClass: "mdc-text-field__input",
                        attrs: {
                          type: "text",
                          autocomplete: "off",
                          autocorrect: "off",
                          spellcheck: "false"
                        },
                        domProps: { value: _vm.branchCode },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.branchCode = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label ",
                          class:
                            _vm.branchCode != ""
                              ? "mdc-floating-label--float-above"
                              : "",
                          attrs: { for: "branchCode" }
                        },
                        [_vm._v(_vm._s(_vm.$t("signup.branchCode")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.accountNumber,
                            expression: "accountNumber"
                          }
                        ],
                        staticClass: "mdc-text-field__input",
                        attrs: {
                          type: "text",
                          autocomplete: "off",
                          autocorrect: "off",
                          spellcheck: "false"
                        },
                        domProps: { value: _vm.accountNumber },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.accountNumber = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label ",
                          class:
                            _vm.accountNumber != ""
                              ? "mdc-floating-label--float-above"
                              : "",
                          attrs: { for: "accountNumber" }
                        },
                        [_vm._v(_vm._s(_vm.$t("signup.accountNumber")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.country,
                              expression: "country"
                            }
                          ],
                          staticClass: "mdc-text-field__input",
                          class: { "select--hidden": !_vm.country },
                          attrs: { options: _vm.countryOptions },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.country = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { disabled: "" },
                              domProps: { value: null }
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.state")))]
                          ),
                          _vm._l(_vm.countryOptions, function(country) {
                            return _c(
                              "option",
                              {
                                key: country.id,
                                domProps: { value: country.value }
                              },
                              [_vm._v(_vm._s(country.text))]
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label",
                          class:
                            _vm.country != ""
                              ? "mdc-floating-label--float-above"
                              : ""
                        },
                        [_vm._v(_vm._s(_vm.$t("signup.state")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currency,
                              expression: "currency"
                            }
                          ],
                          staticClass: "mdc-text-field__input",
                          class: { "select--hidden": !_vm.currency },
                          attrs: { options: _vm.currencyOptions },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.currency = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { disabled: "" },
                              domProps: { value: null }
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.currency")))]
                          ),
                          _c("option", { attrs: { value: "hkd" } }, [
                            _vm._v(_vm._s(_vm.$t("currency.hkd")))
                          ])
                        ]
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label",
                          class:
                            _vm.currency != ""
                              ? "mdc-floating-label--float-above"
                              : ""
                        },
                        [_vm._v(_vm._s(_vm.$t("signup.currency")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _vm.errors.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                        },
                        [
                          _c("p", { staticClass: "error-prompt" }, [
                            _c(
                              "ul",
                              _vm._l(_vm.errors, function(error) {
                                return _c("li", { key: error }, [
                                  _vm._v(_vm._s(error))
                                ])
                              })
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "mdc-fab mdc-fab--extended",
                          attrs: {
                            type: "button",
                            "aria-label": _vm.$t("actions.back")
                          },
                          on: { click: _vm.goPrevious }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "mdc-fab__icon",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewbox: "0 0 24 24"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M15.3,17.3c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0L11.4,12l3.9-3.9c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-4.6,4.6c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0l4.6,4.6C14.3,17.7,14.9,17.7,15.3,17.3z"
                                }
                              })
                            ]
                          ),
                          _c("span", { staticClass: "mdc-fab__label" }, [
                            _vm._v(_vm._s(_vm.$t("actions.back")))
                          ])
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "mdc-fab mdc-fab--extended",
                          attrs: {
                            type: "submit",
                            "aria-label": _vm.$t("actions.next")
                          }
                        },
                        [
                          _c("span", { staticClass: "mdc-fab__label" }, [
                            _vm._v(_vm._s(_vm.$t("actions.next")))
                          ]),
                          _c(
                            "svg",
                            {
                              staticClass: "mdc-fab__icon",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewbox: "0 0 24 24"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }