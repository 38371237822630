var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-page app-page-payment app-page--active" },
    [
      _c("div", { staticClass: "app-page-container" }, [
        _c("div", { staticClass: "document-upload" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid app-page-activity app-page-activity--active bottom-nav--hidden"
            },
            [
              _c(
                "form",
                {
                  staticClass: "mdc-layout-grid__inner",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.update($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6"
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("signup.uploadDocument")) +
                          "\n                        "
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mdc-typography--subtitle1",
                          staticStyle: { "margin-top": "15px" }
                        },
                        [_vm._v(_vm._s(_vm.$t("signup.remind")))]
                      ),
                      _c("ol", { staticClass: "mdc-typography--subtitle1" }, [
                        _c("li", { staticClass: "reminder-item" }, [
                          _vm._v(_vm._s(_vm.$t("signup.remindItem")))
                        ]),
                        _c("li", { staticClass: "reminder-item" }, [
                          _vm._v(_vm._s(_vm.$t("signup.remindDocs")))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet",
                      attrs: { flat: "" }
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        {
                          attrs: { expand: "", dark: "" },
                          model: {
                            value: _vm.panel,
                            callback: function($$v) {
                              _vm.panel = $$v
                            },
                            expression: "panel"
                          }
                        },
                        [
                          _c(
                            "v-expansion-panel-content",
                            {
                              staticStyle: {
                                "background-color": "#673ab7",
                                color: "white",
                                "border-radius": "10px",
                                "min-height": "60px",
                                border: "0px"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mdc-typography--headline6",
                                  staticStyle: { "font-weight": "300" },
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [
                                  _vm._v(
                                    "\n                                    * " +
                                      _vm._s(_vm.$t("signup.br")) +
                                      "\n                                    "
                                  ),
                                  JSON.stringify(_vm.brFile) != "{}"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "#69cb59" } },
                                        [_vm._v("check_circle")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                {
                                  staticStyle: {
                                    "background-color": "#46287d",
                                    color: "white",
                                    "border-radius": "0px 0px 10px 10px"
                                  },
                                  attrs: { light: "" }
                                },
                                [
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c("input", {
                                        staticStyle: { display: "none" },
                                        attrs: {
                                          type: "file",
                                          accept:
                                            "image/jpeg, image/png, application/pdf",
                                          id: "selectedBRFile",
                                          autocomplete: "off",
                                          autocorrect: "off",
                                          spellcheck: "false"
                                        },
                                        on: { change: _vm.selectedBRFile }
                                      }),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "mdc-fab mdc-fab--extended upload-btn",
                                          attrs: {
                                            type: "button",
                                            onclick:
                                              "document.getElementById('selectedBRFile').click();"
                                          }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "mdc-fab__icon",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                height: "20",
                                                width: "20",
                                                viewBox: "0 0 24 24"
                                              }
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d:
                                                    "M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "mdc-fab__label" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("signup.selectDocs")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c("v-spacer")
                                    ],
                                    1
                                  ),
                                  JSON.stringify(_vm.brFile) != "{}"
                                    ? _c("v-card-text", { staticStyle: {} }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mdc-card mdc-card--outlined",
                                            staticStyle: { color: "black" }
                                          },
                                          [
                                            _c("br"),
                                            _c(
                                              "div",
                                              { staticClass: "file-preview" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "upload-file-img"
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.brFile.url,
                                                        width: "100px",
                                                        height: "100px"
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "upload-file-label mdc-typography--subtitle1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.brFile.file.name
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.ciRequired
                            ? _c(
                                "v-expansion-panel-content",
                                {
                                  staticStyle: {
                                    "background-color": "#673ab7",
                                    color: "white",
                                    "border-radius": "10px",
                                    "min-height": "60px",
                                    border: "0px"
                                  },
                                  attrs: { dark: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mdc-typography--headline6",
                                      staticStyle: { "font-weight": "300" },
                                      attrs: { slot: "header" },
                                      slot: "header"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    * " +
                                          _vm._s(_vm.$t("signup.businessCI")) +
                                          "\n                                    "
                                      ),
                                      JSON.stringify(_vm.ciFile) != "{}"
                                        ? _c(
                                            "v-icon",
                                            { attrs: { color: "#69cb59" } },
                                            [_vm._v("check_circle")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card",
                                    {
                                      staticStyle: {
                                        "background-color": "#46287d",
                                        color: "white",
                                        "border-radius": "0px 0px 10px 10px"
                                      },
                                      attrs: { light: "" }
                                    },
                                    [
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c("input", {
                                            staticStyle: { display: "none" },
                                            attrs: {
                                              type: "file",
                                              accept:
                                                "image/jpeg, image/png, application/pdf",
                                              id: "selectedCIFile",
                                              autocomplete: "off",
                                              autocorrect: "off",
                                              spellcheck: "false"
                                            },
                                            on: { change: _vm.selectedCIFile }
                                          }),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "mdc-fab mdc-fab--extended upload-btn",
                                              attrs: {
                                                type: "button",
                                                onclick:
                                                  "document.getElementById('selectedCIFile').click();"
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "mdc-fab__icon",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    height: "20",
                                                    width: "20",
                                                    viewBox: "0 0 24 24"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d:
                                                        "M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "mdc-fab__label"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "signup.selectDocs"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c("v-spacer")
                                        ],
                                        1
                                      ),
                                      JSON.stringify(_vm.ciFile) != "{}"
                                        ? _c(
                                            "v-card-text",
                                            { staticStyle: {} },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mdc-card mdc-card--outlined",
                                                  staticStyle: {
                                                    color: "black"
                                                  }
                                                },
                                                [
                                                  _c("br"),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "file-preview"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upload-file-img"
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src:
                                                                _vm.ciFile.url,
                                                              width: "100px",
                                                              height: "100px"
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "upload-file-label mdc-typography--subtitle1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.ciFile.file
                                                                .name
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(_vm.ownerNo, function(i) {
                            return [
                              _c(
                                "v-expansion-panel-content",
                                {
                                  key: i,
                                  staticStyle: {
                                    "background-color": "#673ab7",
                                    color: "white",
                                    "border-radius": "10px",
                                    "min-height": "60px",
                                    border: "0px"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mdc-typography--headline6",
                                      staticStyle: { "font-weight": "300" },
                                      attrs: { slot: "header" },
                                      slot: "header"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        * " +
                                          _vm._s(
                                            _vm.$t("signup.companyOwner")
                                          ) +
                                          " " +
                                          _vm._s(i) +
                                          _vm._s(_vm.$t("signup.proof")) +
                                          " -  "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "nowrap"
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.ownerName[i - 1]))]
                                      ),
                                      _vm.owners.identityFront[i - 1] !=
                                        undefined &&
                                      _vm.owners.identityBack[i - 1] !=
                                        undefined &&
                                      _vm.owners.address[i - 1] != undefined
                                        ? _c(
                                            "v-icon",
                                            { attrs: { color: "#69cb59" } },
                                            [_vm._v("check_circle")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card",
                                    {
                                      staticStyle: {
                                        "background-color": "#46287d",
                                        color: "white",
                                        "border-radius": "0px 0px 10px 10px"
                                      },
                                      attrs: { light: "" }
                                    },
                                    [
                                      _c("v-card-text", { staticStyle: {} }, [
                                        _c("div", [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(_vm.$t("signup.idProof")) +
                                              "\n                                            "
                                          )
                                        ])
                                      ]),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c("input", {
                                            staticStyle: { display: "none" },
                                            attrs: {
                                              type: "file",
                                              accept: "image/jpeg, image/png",
                                              id:
                                                "selectedOwnerIdentityFrontFile" +
                                                (i - 1),
                                              autocomplete: "off",
                                              autocorrect: "off",
                                              spellcheck: "false"
                                            },
                                            on: {
                                              change: function($event) {
                                                _vm.selectedOwnerIdentityFrontFile(
                                                  $event,
                                                  i - 1
                                                )
                                              }
                                            }
                                          }),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "mdc-fab mdc-fab--extended upload-btn",
                                              attrs: {
                                                type: "button",
                                                onclick:
                                                  "document.getElementById('selectedOwnerIdentityFrontFile" +
                                                  (i - 1) +
                                                  "').click();"
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "mdc-fab__icon",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    height: "20",
                                                    width: "20",
                                                    viewBox: "0 0 24 24"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d:
                                                        "M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "mdc-fab__label"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    " +
                                                      _vm._s(
                                                        _vm.$t("signup.idFront")
                                                      ) +
                                                      "\n                                                "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c("v-spacer")
                                        ],
                                        1
                                      ),
                                      _vm.owners.identityFront[i - 1] !=
                                      undefined
                                        ? _c("v-card-text", [
                                            _vm.owners.identityFront[i - 1] !=
                                            undefined
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mdc-card mdc-card--outlined",
                                                    staticStyle: {
                                                      color: "black"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "file-preview"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "upload-file-img"
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src:
                                                                  _vm.owners
                                                                    .identityFront[
                                                                    i - 1
                                                                  ].url,
                                                                width: "100px",
                                                                height: "100px"
                                                              }
                                                            })
                                                          ]
                                                        ),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "upload-file-label mdc-typography--subtitle1"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.owners
                                                                  .identityFront[
                                                                  i - 1
                                                                ].file.name
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c("input", {
                                            staticStyle: { display: "none" },
                                            attrs: {
                                              type: "file",
                                              accept: "image/jpeg, image/png",
                                              id:
                                                "selectedOwnerIdentityBackFile" +
                                                (i - 1),
                                              autocomplete: "off",
                                              autocorrect: "off",
                                              spellcheck: "false"
                                            },
                                            on: {
                                              change: function($event) {
                                                _vm.selectedOwnerIdentityBackFile(
                                                  $event,
                                                  i - 1
                                                )
                                              }
                                            }
                                          }),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "mdc-fab mdc-fab--extended upload-btn",
                                              attrs: {
                                                type: "button",
                                                onclick:
                                                  "document.getElementById('selectedOwnerIdentityBackFile" +
                                                  (i - 1) +
                                                  "').click();"
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "mdc-fab__icon",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    height: "20",
                                                    width: "20",
                                                    viewBox: "0 0 24 24"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d:
                                                        "M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "mdc-fab__label"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("signup.idBack")
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c("v-spacer")
                                        ],
                                        1
                                      ),
                                      _vm.owners.identityBack[i - 1] !=
                                      undefined
                                        ? _c(
                                            "v-card-text",
                                            { staticStyle: {} },
                                            [
                                              _vm.owners.identityBack[i - 1] !=
                                              undefined
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mdc-card mdc-card--outlined",
                                                      staticStyle: {
                                                        color: "black"
                                                      }
                                                    },
                                                    [
                                                      _c("br"),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "file-preview"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upload-file-img"
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src:
                                                                    _vm.owners
                                                                      .identityBack[
                                                                      i - 1
                                                                    ].url,
                                                                  width:
                                                                    "100px",
                                                                  height:
                                                                    "100px"
                                                                }
                                                              })
                                                            ]
                                                          ),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "upload-file-label mdc-typography--subtitle1"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.owners
                                                                    .identityBack[
                                                                    i - 1
                                                                  ].file.name
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c("input", {
                                            staticStyle: { display: "none" },
                                            attrs: {
                                              type: "file",
                                              accept:
                                                "image/jpeg, image/png, application/pdf",
                                              id:
                                                "selectedOwnerAddressFile" +
                                                (i - 1),
                                              autocomplete: "off",
                                              autocorrect: "off",
                                              spellcheck: "false"
                                            },
                                            on: {
                                              change: function($event) {
                                                _vm.selectedOwnerAddressFile(
                                                  $event,
                                                  i - 1
                                                )
                                              }
                                            }
                                          }),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "mdc-fab mdc-fab--extended upload-btn",
                                              attrs: {
                                                type: "button",
                                                onclick:
                                                  "document.getElementById('selectedOwnerAddressFile" +
                                                  (i - 1) +
                                                  "').click();"
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "mdc-fab__icon",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    height: "20",
                                                    width: "20",
                                                    viewBox: "0 0 24 24"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d:
                                                        "M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "mdc-fab__label"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "signup.addressProof"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c("v-spacer")
                                        ],
                                        1
                                      ),
                                      _vm.owners.address[i - 1] != undefined
                                        ? _c(
                                            "v-card-text",
                                            { staticStyle: {} },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mdc-card mdc-card--outlined",
                                                  staticStyle: {
                                                    color: "black"
                                                  }
                                                },
                                                [
                                                  _c("br"),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "file-preview"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upload-file-img"
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src:
                                                                _vm.owners
                                                                  .address[
                                                                  i - 1
                                                                ].url,
                                                              width: "100px",
                                                              height: "100px"
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "upload-file-label mdc-typography--subtitle1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.owners
                                                                .address[i - 1]
                                                                .file.name
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }),
                          _c(
                            "v-expansion-panel-content",
                            {
                              staticStyle: {
                                "background-color": "#673ab7",
                                color: "white",
                                "border-radius": "10px",
                                "min-height": "60px",
                                border: "0px"
                              },
                              attrs: { dark: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mdc-typography--headline6",
                                  staticStyle: { "font-weight": "300" },
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [
                                  _vm._v(
                                    "\n                                    * " +
                                      _vm._s(_vm.$t("signup.bankProof")) +
                                      "\n                                    "
                                  ),
                                  JSON.stringify(_vm.bank) != "{}"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "#69cb59" } },
                                        [_vm._v("check_circle")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                {
                                  staticStyle: {
                                    "background-color": "#46287d",
                                    color: "white",
                                    "border-radius": "0px 0px 10px 10px"
                                  },
                                  attrs: { light: "" }
                                },
                                [
                                  _c("v-card-text", { staticStyle: {} }, [
                                    _c("div", [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            _vm.$t("signup.bankProofExample")
                                          ) +
                                          "\n                                        "
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c("input", {
                                        staticStyle: { display: "none" },
                                        attrs: {
                                          type: "file",
                                          accept:
                                            "image/jpeg, image/png, application/pdf",
                                          id: "selectedBankFile",
                                          autocomplete: "off",
                                          autocorrect: "off",
                                          spellcheck: "false"
                                        },
                                        on: { change: _vm.selectedBankFile }
                                      }),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "mdc-fab mdc-fab--extended upload-btn",
                                          attrs: {
                                            type: "button",
                                            onclick:
                                              "document.getElementById('selectedBankFile').click();"
                                          }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "mdc-fab__icon",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                height: "20",
                                                width: "20",
                                                viewBox: "0 0 24 24"
                                              }
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d:
                                                    "M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "mdc-fab__label" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("signup.selectDocs")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c("v-spacer")
                                    ],
                                    1
                                  ),
                                  JSON.stringify(_vm.bank) != "{}"
                                    ? _c("v-card-text", [
                                        JSON.stringify(_vm.bank) != "{}"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mdc-card mdc-card--outlined",
                                                staticStyle: { color: "black" }
                                              },
                                              [
                                                _c("br"),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "file-preview"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "upload-file-img"
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: _vm.bank.url,
                                                            width: "100px",
                                                            height: "100px"
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "upload-file-label mdc-typography--subtitle1"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.bank.file.name
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-expansion-panel-content",
                            {
                              staticStyle: {
                                "background-color": "#673ab7",
                                color: "white",
                                "border-radius": "10px",
                                "min-height": "60px",
                                border: "0px"
                              },
                              attrs: { dark: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mdc-typography--headline6",
                                  staticStyle: { "font-weight": "300" },
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [_vm._v(_vm._s(_vm.$t("signup.otherInfo")))]
                              ),
                              _c(
                                "v-card",
                                {
                                  staticStyle: {
                                    "background-color": "#46287d",
                                    color: "white",
                                    "border-radius": "0px 0px 10px 10px"
                                  },
                                  attrs: { light: "" }
                                },
                                [
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c("input", {
                                        staticStyle: { display: "none" },
                                        attrs: {
                                          type: "file",
                                          accept:
                                            "image/jpeg, image/png, application/pdf",
                                          id: "selectedOthersFile",
                                          autocomplete: "off",
                                          autocorrect: "off",
                                          spellcheck: "false"
                                        },
                                        on: { change: _vm.selectedOthersFile }
                                      }),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "mdc-fab mdc-fab--extended upload-btn",
                                          attrs: {
                                            type: "button",
                                            onclick:
                                              "document.getElementById('selectedOthersFile').click();"
                                          }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "mdc-fab__icon",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                height: "20",
                                                width: "20",
                                                viewBox: "0 0 24 24"
                                              }
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d:
                                                    "M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "mdc-fab__label" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("signup.selectDocs")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c("v-spacer")
                                    ],
                                    1
                                  ),
                                  JSON.stringify(_vm.others) != "[]"
                                    ? _c(
                                        "v-card-text",
                                        { staticStyle: {} },
                                        [
                                          _vm._l(_vm.others, function(file, i) {
                                            return [
                                              _c("br", { key: "br" + i }),
                                              _c(
                                                "div",
                                                {
                                                  key: "otherfile" + i,
                                                  staticClass:
                                                    "mdc-card mdc-card--outlined",
                                                  staticStyle: {
                                                    color: "black"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "85%",
                                                        "margin-top": "2%"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ma-0",
                                                          attrs: {
                                                            small: "",
                                                            icon: "",
                                                            flat: ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.removeOther(
                                                                    i
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [_vm._v("close")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "file-preview"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upload-file-img"
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: file.url,
                                                              width: "100px",
                                                              height: "100px"
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "upload-file-label mdc-typography--subtitle1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              file.file.name
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm.errors.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                        },
                        [
                          _c("p", { staticClass: "error-prompt" }, [
                            _c(
                              "ul",
                              _vm._l(_vm.errors, function(error) {
                                return _c(
                                  "li",
                                  { key: error, staticClass: "error" },
                                  [_vm._v(_vm._s(error))]
                                )
                              })
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "mdc-fab mdc-fab--extended",
                          attrs: {
                            type: "button",
                            "aria-label": _vm.$t("actions.back")
                          },
                          on: { click: _vm.goPrevious }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "mdc-fab__icon",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M15.3,17.3c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0L11.4,12l3.9-3.9c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-4.6,4.6c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0l4.6,4.6C14.3,17.7,14.9,17.7,15.3,17.3z"
                                }
                              })
                            ]
                          ),
                          _c("span", { staticClass: "mdc-fab__label" }, [
                            _vm._v(_vm._s(_vm.$t("actions.back")))
                          ])
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "mdc-fab mdc-fab--extended",
                          attrs: {
                            type: "submit",
                            "aria-label": _vm.$t("actions.next")
                          }
                        },
                        [
                          _c("span", { staticClass: "mdc-fab__label" }, [
                            _vm._v(_vm._s(_vm.$t("actions.next")))
                          ]),
                          _c(
                            "svg",
                            {
                              staticClass: "mdc-fab__icon",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }