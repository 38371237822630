export const status = {
    title: 'Status',
    all: 'All',
    succeeded: 'Succeeded',
    authorized: 'Authorized',
    unauthorized: 'Unauthorized',
    pending: 'Pending',
    paying: 'Paying',
    failed: 'Failed',
    refunded: 'Refunded',
    canceled: 'Canceled',
    arrived: 'Arrived',
    inTransit: 'In Transit',
    active: 'Active',
    inactive: 'Inactive',
    notYetStarted: 'Not Yet Started',
    completed: 'Completed',
    paused: 'Paused',
    agentPromotion: 'Agent Promotion',
    free: 'Free',
    success: 'Success',
    failure: 'Fail',
};
