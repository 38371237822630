export const receipt = {
    number: '收据编号',
    copy: '收据副本',
    refundConfirmation: '确认退款',
    cancelRecurrentFailedMsg: '取消月付失败',
    refundFailed: '退款失败。',
    refundSucceeded: '成功退款。',
    refundOnceOnlyMsg: '您只能进行一次退款，确认后将不能取消。',
    refundAndCancelRecurrentButton: '退款并取消月付',
    notEnoughBalanceMsg: '余额不足，请联络JARVIX PAY。',
    printer: {
        printingReceipt: '正在列印收据',
        failedToPrint: '印单失败',
        checkPrint: '请检查打印机连接或稍候再试。',
    },
};
