export const settings = {
    captureNow: 'Capture Now (Direct)',
    authorized: {
        transaction: 'Authorized Transaction(s)',
        volume: 'Authorized Volume',
    },
    user: {
        label: 'User',
        create: 'Create User',
        type: 'User Type',
        superuser: 'Super user',
        normaluser: 'Normal user',
        email: 'Email',
        display: 'Display Name',
        phone: 'Phone Number (Optional)',
        info: 'User information',
    },
    branch: {
        label: 'Branch',
        create: 'Create Branch',
        modify: 'Modify Branch',
        receipt: {
            name: 'Branch Name (English only) - Receipt',
            address: 'Branch Address (English only) - Receipt',
        },
        campaign: {
            name: 'Branch Name (English only) - Campaign',
            address: 'Branch Address (English only) - Campaign',
        },
    },
    merchant: {
        setup: 'Initial Setup',
        setupMessage: 'If you want to modify thm again, please go to settings.',
        modify: 'Modify Merchant',
        name: {
            receipt: 'Merchant Name (Receipt)',
            campaign: 'Merchant Name (Campaign)',
        },
        logo: {
            campaign: 'Merchant Logo (Campaign)',
        },
        current: 'Current Name',
        new: 'New Name',
        confirm: 'Confirm New Name',
        display: 'Display Name',
        url: 'URL Name (English Only)',
        uploadLogoWarning: 'Please upload merchant logo if needed.',
        modifyCampaignWarning: 'Warning! If campaign name is changed, the previous links will be disabled. Please update your links.',
    },
    export: {
        title: 'Export',
        label: 'Export Report',
        type: {
            transaction: 'Transaction',
            journal: 'Journal',
            online: 'Online Payment',
            statement: 'Monthly Statement',
            payout: 'Payout',
        },
        reminder: {
            type: 'Please select the report type',
            period: 'Please fill in the period.',
            branch: 'Please select the branch(es).',
            bybranch: 'Please select the statement option',
        },
        bybranch: {
            title: 'Statement Option',
            true: 'Seperated statement by branch',
            false: 'Combined statement',
        },
        period: 'Period',
        downloadData: 'Data Downlaod',
        downloadAndOpen: 'Download (Open in new window)',
        copyLinkAndPassword: 'Copy Link and Password',
        longPressOrCopy: 'Long Press To Share / Copy Link',
        expire: 'Link will be expired on {0}',
    },
    printer: 'Printer',
    connectPrinter: 'Connect Printer',
    connectPrinterFailed: 'Connecting Printer Failed',
    capture: {
        title: 'Capture',
        enableAuthorization: 'Enable Authorization',
        mode: {
            label: 'Mode',
            auto: 'Automatic (Daily)',
            manual: 'Manual',
        },
        summary: {
            succeededTrx: 'Charged {0} Transaction(s) Successfully',
            failedTrx: 'Charged {0} Transaction(s) <b>Unscuccessdfully</b>',
            contact: 'If you have any problem, please contact Jarvix Pay',
        },
        confirm: 'Please confirm to change capture mode.',
    },
    permissions: {
        title: 'Permissions',
        account: {
            addAccount: 'Add Branch',
            addUser: 'Add User',
            editPermission: 'Edit Permission',
            modifyInformation: 'Modify Information',
        },
        functional: {
            campaignAgent: 'Campaign Agent',
            dashboard: 'Dashboard',
            export: 'Report',
            payout: 'Payout',
            printer: 'Printer',
            transactionsHistory: 'History',
        },
        payment: {
            cancelRecurrence: 'Cancel Recurrent',
            capture: 'Capture Mode',
            changeRecurrenceCreditCard: 'Change Recurrent Credit Card',
            editChargeDescription: 'Edit Charge',
            refund: 'Refund',
            enable_recurrence: 'Enable Recurrence',
            enable_remote: 'Enable Remote',
            enable_campaign: 'Enable Promotion',
        },
        notification: {
            payment: 'Payment notification',
            campaign: 'Promotion notification',
            summary: 'Account summary notification',
        },
    },
    language: 'Language',
    password: {
        label: 'Password',
        change: 'Change Password',
        reminder: 'It is recommedned to use a strong password that is different from other services',
        current: 'Current Password',
        new: 'New Passowrd',
        confirm: 'Confirm Password',
    },
    about: 'About',
    logout: {
        title: 'Logout',
    },
};
