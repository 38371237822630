























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { moment_HK as moment, moment_HK } from '@/store';
import ChargeStatusTag from '@/components/charges/ChargeStatusTag.vue';

@Component({
    components: {
        ChargeStatusTag,
    },
})
export default class FreeOrderReceipt extends Vue {
    @Prop() displayName!: string;
    @Prop() productList: any;

    private created_at = moment_HK();

    get formatFullCreatedDate() {
        return `${moment(this.created_at).format('LL')} ${moment(this.created_at).format('LTS')}`;
    }

    get formatCreatedDate() {
        return moment(this.created_at).format('LL');
    }

    get formatCreatedTime() {
        return moment(this.created_at).format('LT');
    }
}
