






















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Verification from '@/components/Verification.vue';
import Terms from '@/components/Terms.vue';
import Privacy from '@/components/Privacy.vue';
import { isEmail, isMobileNumber } from '../../utils/validators';
import { MDCTextField } from '@material/textfield';
import { MDCFormField } from '@material/form-field';
import { MDCCheckbox } from '@material/checkbox';
import { MDCNotchedOutline } from '@material/notched-outline';
import { IMaskComponent } from 'vue-imask';

const langs = [
    {
        name: 'English',
        nativeName: 'English',
        value: 'en',
    },
    {
        name: 'Traditional_Chinese',
        nativeName: '中文(香港)',
        value: 'zh_HK',
    },
    {
        name: 'Simplified_Chinese',
        nativeName: '中文(简体)',
        value: 'zh_CN',
    },
    {
        name: 'Japanese',
        nativeName: '日本語',
        value: 'jp',
    },
];
@Component({
    components: {
        Verification,
        Terms,
        Privacy,
        'imask-input': IMaskComponent,
    },
})
export default class Registration extends Vue {
    private companyName: string = '';
    private email: string = '';
    private password: string = '';
    private confirmPassword: string = '';
    private phone: number | null = null;
    private checkbox: any;
    private hidden = false;

    private verificationDialog?: any;

    private errors: string[] = [];

    private selectedLanguage = 'en';
    private langs = langs;

    private selectedCountryCode: any = { phone_code: '+852', en: 'Hong Kong (SAR)', zh: '香港', cn: '香港', alphanumeric_sender: 1 };
    private countryCodeList = this.$store.state.countryCodeList;

    @Watch('selectedLanguage')
    onLangSelectChange(val: string, oldVal: string) {
        this.$i18n.locale = val;

        // sort the country code due to language
        if (val == 'en') {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.en > b.en ? 1 : -1));
        } else {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.phone_code >= b.phone_code ? 1 : -1));
        }
    }
    public mounted() {
        this.verificationDialog = this.$refs.verificationDialog;

        if (this.$store.state.user && this.$store.state.user.resume_page === '/signup') {
            this.hidden = true;
            this.verificationDialog.show();
        }

        this.initMDCTextFields();
    }

    protected register() {
        this.errors = [];

        if (!isEmail(this.email)) {
            this.errors.push(this.$t('AUTH.INVALID_EMAIL_FORAMT') as any);
            // this.errors.push('電子郵件地址無效。');
        }

        if (!this.companyName) {
            this.errors.push(this.$t(`AUTH.INVALID_COMPANY_NAME_FORMAT`) as any);
            // this.errors.push('公司名稱格式無效。');
        }

        if (this.password.length < 8) {
            this.errors.push(this.$t(`AUTH.INVALID_PASSWORD_FORMAT`) as any);
            // this.errors.push('密碼格式無效, 密碼必須需要至少8個字元。');
        }

        if (this.confirmPassword !== this.password) {
            this.errors.push(this.$t(`AUTH.CONFIRM_FAILURE`) as any);
            // this.errors.push('確認密碼無效。');
        }

        if (!isMobileNumber(`${this.selectedCountryCode.phone_code}${this.phone}`)) {
            this.errors.push(this.$t(`AUTH.INVALID_MOBILE_FORMAT`) as any);
            // this.errors.push('電話號碼無效。');
        }

        if (!this.checkbox.checked) {
            this.errors.push(this.$t(`AUTH.NOT_ACCEPTING_TOS`) as any);
            // this.errors.push('閣下必須瞭解並同意 Jarvix Pay 服務條款。');
        }

        if (this.errors.length) {
            return;
        } else {
            // console.log(this.selectedCountryCode.en);
        }
        this.$store
            .dispatch('register', {
                lang: this.selectedLanguage,
                companyName: this.companyName.trim(),
                email: this.email.trim(),
                password: this.password,
                country: this.selectedCountryCode.en,
                countryCode: this.selectedCountryCode.phone_code,
                phone: this.phone,
            })
            .then((response: any) => {
                window.localStorage.setItem('loggedIn', new Date().getTime().toString());
                return this.verificationDialog.show();
            })
            .catch((error: any) => {
                return this.$root.$emit('error', error);
            });
    }

    private showTOS() {
        (this.$refs.terms as any).show();
    }

    private showPrivacy() {
        (this.$refs.privacy as any).show();
    }

    private engCharMask = /^[a-zA-Z0-9!@#$%^&()~`_\-+=,.?/:;{}\[\]| ]*$/;

    // animation function
    private initMDCTextFields() {
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
        this.checkbox = new MDCCheckbox(this.$el.querySelector('.mdc-checkbox'));

        const formField = new MDCFormField(this.$el.querySelector('.mdc-form-field'));
        formField.input = this.checkbox;
    }
}
