var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-item" }, [
    _c("div", { staticClass: "customer-list-item" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "row-left-section" }, [
          _c("div", [
            _vm._v(
              _vm._s(_vm.$t("customer.name")) + ": " + _vm._s(_vm.customer.name)
            )
          ]),
          _c("div", [
            _vm._v(
              _vm._s(_vm.$t("customer.phone")) +
                ": " +
                _vm._s(
                  "" + _vm.customer.countryCode.phone_code + _vm.customer.phone
                )
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "row-right-section" },
          [
            _c(
              "mdc-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customer.remoteLinkId,
                    expression: "customer.remoteLinkId"
                  }
                ],
                ref: "copyBtn",
                staticClass: "button"
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("actions.copy")) +
                    "\n                    "
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.copyMessage,
                        expression: "copyMessage"
                      }
                    ],
                    staticClass: "copy-button--message"
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.copyMessage))])]
                )
              ]
            ),
            _vm.customer.remoteLinkId && !_vm.sent
              ? _c(
                  "mdc-button",
                  { staticClass: "button", on: { click: _vm.send } },
                  [_vm._v(_vm._s(_vm.$t("actions.send")))]
                )
              : _vm._e(),
            _vm.customer.remoteLinkId && _vm.sent
              ? _c(
                  "mdc-button",
                  {
                    staticClass: "button button--red-color",
                    attrs: { disabled: "" }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("actions.sent")) +
                        "\n                "
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }