




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { moment_HK as moment } from '@/store';
import ClipboardJS from 'clipboard';

const exportURI = process.env.VUE_APP_EXPORT_URI;
const copyMessages = {
    success: {
        zhHK: '複製成功!',
    },
    failed: {
        zhHK: '複製失敗!',
    },
    pass: {
        zhHK: '\n用戶名稱: /\n密碼: ',
    },
};
const copyMessageDuration = 1e3;
let copyMessageTimeout = 0;

@Component
export default class ExportDownload extends Vue {
    private clipboard!: any;
    private copyMessage = '';

    get id() {
        return this.$route.params.id;
    }

    get code() {
        return this.$route.params.code;
    }

    get expire_at() {
        return this.$route.params.expire_at;
    }

    get type() {
        return this.$route.params.type;
    }

    get downloadLink() {
        return exportURI + this.id;
    }

    set downloadLink(_: any) {}

    get copyText() {
        return this.downloadLink + copyMessages.pass.zhHK + this.code;
    }

    public downloadButtonClicked() {
        window.open(this.downloadLink, '_blank');
    }

    public copyButtonClicked() {}

    public mounted() {
        this.initClipboard();
    }

    public initClipboard() {
        this.clipboard = new ClipboardJS((this.$refs.copyButton as Vue).$el, { text: () => this.copyText });

        let showCopyMessage = (status: 'success' | 'failed') => {
            window.clearTimeout(copyMessageTimeout);
            this.copyMessage = copyMessages[status].zhHK;
            copyMessageTimeout = window.setTimeout(() => {
                this.copyMessage = '';
            }, copyMessageDuration);
        };
        this.clipboard.on('success', (e: any) => {
            this.$emit('copy-success', e);
            showCopyMessage('success');
        });
        this.clipboard.on('error', (e: any) => {
            this.$emit('copy-failed', e);
            showCopyMessage('failed');
        });
    }

    public beforeDestroy() {
        this.clipboard && this.clipboard.destroy();
        window.clearTimeout(copyMessageTimeout);
    }

    get moment() {
        return moment;
    }
}
