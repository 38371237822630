var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "campaign-history-preview" } }, [
    _vm.campaignData
      ? _c(
          "div",
          { staticClass: "mdc-layout-grid__inner campaign-summary-preview" },
          [
            _vm.campaignData.status === "canceled"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet flex"
                  },
                  [
                    _c("button", { staticClass: "mdc-button red-color" }, [
                      _vm._v(_vm._s(_vm.$t("status.canceled")))
                    ])
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size flex"
              },
              [
                _c("div", [_vm._v(_vm._s(_vm.$t("campaign.title")))]),
                _c(
                  "a",
                  {
                    staticClass: "close-icon ml-auto",
                    on: {
                      click: function($event) {
                        _vm.$router.go(-1)
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "close-icon__icon",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 24 24"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
              },
              [_vm._v(_vm._s(_vm.campaignData.internalCampaignName))]
            ),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
              },
              [_vm._v(_vm._s(_vm.$t("campaign.period")))]
            ),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
              },
              [_vm._v(_vm._s(_vm.date.from) + " - " + _vm._s(_vm.date.to))]
            ),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
              },
              [_vm._v(_vm._s(_vm.$t("campaign.link")))]
            ),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
              },
              _vm._l(_vm.campaignData.linkList, function(link) {
                return _c("RemoteCampaignLinkItem", {
                  key: link.url,
                  attrs: {
                    displayCount: true,
                    link: _vm.baseUrl + "/" + link.url,
                    count: link.count
                  }
                })
              })
            ),
            _vm.campaignData.clientDetail.name &&
            _vm.campaignData.clientDetail.email
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
                  },
                  [_vm._v(_vm._s(_vm.$t("status.agentPromotion")))]
                )
              : _vm._e(),
            _vm.campaignData.clientDetail.name
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet title-font-size"
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("customer.name")) +
                        ": " +
                        _vm._s(_vm.campaignData.clientDetail.name)
                    )
                  ]
                )
              : _vm._e(),
            _vm.campaignData.clientDetail.email
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet title-font-size"
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("customer.email")) +
                        ": " +
                        _vm._s(_vm.campaignData.clientDetail.email)
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
              },
              [_vm._v(_vm._s(_vm.$t("campaign.copywriting")))]
            ),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size",
                attrs: { align: "center" }
              },
              [_vm._v(_vm._s(_vm.campaignData.externalCampaignName))]
            ),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet no-line-height"
              },
              [
                _vm._l(_vm.campaignData.bannerSrc, function(src, index) {
                  return [
                    _c("img", {
                      key: index,
                      staticStyle: { width: "100%" },
                      attrs: { src: src }
                    })
                  ]
                })
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
              },
              [
                _vm._l(_vm.campaignData.advertisement.split("\n"), function(
                  line,
                  index
                ) {
                  return [
                    _c(
                      "div",
                      { key: index, staticStyle: { "padding-bottom": "8px" } },
                      [_vm._v(_vm._s(line))]
                    )
                  ]
                })
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
              },
              [_vm._v(_vm._s(_vm.$t("campaign.product.title")))]
            ),
            _vm._l(_vm.campaignData.productList, function(product) {
              return _c(
                "div",
                {
                  key: product.id,
                  staticClass:
                    "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet product"
                },
                [
                  _c(
                    "mdc-checkbox",
                    {
                      attrs: { disabled: product.quantity - product.sold <= 0 }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(product.name) +
                            " - " +
                            _vm._s(_vm._f("currency")(product.price))
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          "\n                    (" +
                            _vm._s(_vm.$t("campaign.product.remaining")) +
                            " :\n                    " +
                            _vm._s(
                              product.quantity - product.sold > 0
                                ? product.quantity - product.sold
                                : 0
                            ) +
                            " )\n                "
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          "[" +
                            _vm._s(_vm.$t("campaign.product.quantity")) +
                            " : " +
                            _vm._s(product.quantity) +
                            " ]"
                        )
                      ])
                    ]
                  ),
                  _c(
                    "mdc-select",
                    {
                      staticClass: "mdc-select-style",
                      attrs: {
                        label: _vm.$t("general.paymentDetails.quantity"),
                        disabled: product.quantity - product.sold <= 0,
                        value: "1"
                      }
                    },
                    _vm._l(product.quantityPerOrder, function(n) {
                      return _c(
                        "option",
                        {
                          key: n,
                          attrs: {
                            disabled:
                              n > product.quantity - product.sold ? true : false
                          },
                          domProps: { value: n }
                        },
                        [_vm._v(_vm._s(n))]
                      )
                    })
                  )
                ],
                1
              )
            }),
            _vm.campaignData.branchOption
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("campaign.additionalOptions.branchSelection")
                      )
                    )
                  ]
                )
              : _vm._e(),
            _vm.campaignData.branchOption
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                  },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.branchId,
                            expression: "branchId"
                          }
                        ],
                        staticClass: "mdc-text-field__input",
                        class: { "select--hidden": !_vm.branchId },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.branchId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { disabled: "" },
                            domProps: { value: null }
                          },
                          [_vm._v(_vm._s(_vm.$t("settings.branch.label")))]
                        ),
                        _vm._l(_vm.campaignData.accountList, function(branch) {
                          return [
                            _c(
                              "option",
                              {
                                key: branch.id,
                                domProps: { value: branch.id }
                              },
                              [
                                _vm._v(
                                  _vm._s(branch.campaign_store_name) +
                                    " - " +
                                    _vm._s(branch.campaign_store_address)
                                )
                              ]
                            )
                          ]
                        })
                      ],
                      2
                    ),
                    _c("label", { staticClass: "mdc-floating-label" }, [
                      _vm._v(_vm._s(_vm.$t("settings.branch.label")))
                    ]),
                    _c("div", { staticClass: "mdc-notched-outline" }, [
                      _c("svg", [
                        _c("path", { staticClass: "mdc-notched-outline__path" })
                      ])
                    ]),
                    _c("div", { staticClass: "mdc-notched-outline__idle" })
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
              },
              [_vm._v(_vm._s(_vm.$t("customer.title")))]
            ),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
              },
              [
                _c("input", {
                  staticClass: "mdc-text-field__input",
                  attrs: {
                    type: "text",
                    id: "name",
                    autocomplete: "off",
                    autocorrect: "off",
                    spellcheck: "false",
                    disabled: ""
                  }
                }),
                _c(
                  "label",
                  { staticClass: "mdc-floating-label", attrs: { for: "name" } },
                  [_vm._v(_vm._s(_vm.$t("customer.name")))]
                ),
                _c("div", { staticClass: "mdc-notched-outline" }, [
                  _c("svg", [
                    _c("path", { staticClass: "mdc-notched-outline__path" })
                  ])
                ]),
                _c("div", { staticClass: "mdc-notched-outline__idle" })
              ]
            ),
            _vm.campaignData.genderOption
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                  },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.gender,
                            expression: "gender"
                          }
                        ],
                        staticClass: "mdc-text-field__input",
                        class: { "select--hidden": !_vm.gender },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.gender = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { disabled: "" },
                            domProps: { value: null }
                          },
                          [_vm._v(_vm._s(_vm.$t("customer.gender")))]
                        ),
                        _c("option", { attrs: { value: "male" } }, [
                          _vm._v(_vm._s(_vm.$t("customer.male")))
                        ]),
                        _c("option", { attrs: { value: "female" } }, [
                          _vm._v(_vm._s(_vm.$t("customer.female")))
                        ])
                      ]
                    ),
                    _c("label", { staticClass: "mdc-floating-label" }, [
                      _vm._v(_vm._s(_vm.$t("customer.gender")))
                    ]),
                    _c("div", { staticClass: "mdc-notched-outline" }, [
                      _c("svg", [
                        _c("path", { staticClass: "mdc-notched-outline__path" })
                      ])
                    ]),
                    _c("div", { staticClass: "mdc-notched-outline__idle" })
                  ]
                )
              : _vm._e(),
            _vm.campaignData.ageOption
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                  },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.age,
                            expression: "age"
                          }
                        ],
                        staticClass: "mdc-text-field__input",
                        class: { "select--hidden": !_vm.age },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.age = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { disabled: "" },
                            domProps: { value: null }
                          },
                          [_vm._v(_vm._s(_vm.$t("customer.age")))]
                        ),
                        _vm._l(80, function(n) {
                          return [
                            n >= 16
                              ? _c(
                                  "option",
                                  { key: n, domProps: { value: n } },
                                  [_vm._v(_vm._s(n))]
                                )
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    ),
                    _c("label", { staticClass: "mdc-floating-label" }, [
                      _vm._v(_vm._s(_vm.$t("customer.age")))
                    ]),
                    _c("div", { staticClass: "mdc-notched-outline" }, [
                      _c("svg", [
                        _c("path", { staticClass: "mdc-notched-outline__path" })
                      ])
                    ]),
                    _c("div", { staticClass: "mdc-notched-outline__idle" })
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedCountryCode,
                        expression: "selectedCountryCode"
                      }
                    ],
                    staticClass: "mdc-text-field__input",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedCountryCode = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c(
                      "option",
                      { attrs: { disabled: "" }, domProps: { value: null } },
                      [_vm._v(_vm._s(_vm.$t("customer.countryCode")))]
                    ),
                    _vm._l(_vm.countryCodeList, function(code) {
                      return _c(
                        "option",
                        { key: code.en, domProps: { value: code } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$i18n.locale == "zh_HK"
                                ? code.zh
                                : _vm.$i18n.locale == "zh_CN"
                                  ? code.cn
                                  : code.en
                            ) +
                              " " +
                              _vm._s("(" + code.phone_code + ")")
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _c("label", { staticClass: "mdc-floating-label" }, [
                  _vm._v(_vm._s(_vm.$t("customer.countryCode")))
                ]),
                _c("div", { staticClass: "mdc-notched-outline" }, [
                  _c("svg", [
                    _c("path", { staticClass: "mdc-notched-outline__path" })
                  ])
                ]),
                _c("div", { staticClass: "mdc-notched-outline__idle" })
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
              },
              [
                _c("input", {
                  staticClass: "mdc-text-field__input",
                  attrs: {
                    type: "tel",
                    id: "contact",
                    autocomplete: "off",
                    autocorrect: "off",
                    spellcheck: "false",
                    disabled: ""
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "mdc-floating-label",
                    attrs: { for: "contact" }
                  },
                  [_vm._v(_vm._s(_vm.$t("customer.phone")))]
                ),
                _c("div", { staticClass: "mdc-notched-outline" }, [
                  _c("svg", [
                    _c("path", { staticClass: "mdc-notched-outline__path" })
                  ])
                ]),
                _c("div", { staticClass: "mdc-notched-outline__idle" })
              ]
            ),
            _vm.campaignData.emailOption
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                  },
                  [
                    _c("input", {
                      staticClass: "mdc-text-field__input",
                      attrs: {
                        type: "email",
                        id: "email",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false",
                        disabled: ""
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "mdc-floating-label",
                        attrs: { for: "email" }
                      },
                      [_vm._v(_vm._s(_vm.$t("customer.email")))]
                    ),
                    _c("div", { staticClass: "mdc-notched-outline" }, [
                      _c("svg", [
                        _c("path", { staticClass: "mdc-notched-outline__path" })
                      ])
                    ]),
                    _c("div", { staticClass: "mdc-notched-outline__idle" })
                  ]
                )
              : _vm._e(),
            _vm.campaignData.addressOption
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                  },
                  [
                    _c("input", {
                      staticClass: "mdc-text-field__input",
                      attrs: {
                        type: "text",
                        id: "address",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false",
                        disabled: ""
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "mdc-floating-label",
                        attrs: { for: "address" }
                      },
                      [_vm._v(_vm._s(_vm.$t("customer.address")))]
                    ),
                    _c("div", { staticClass: "mdc-notched-outline" }, [
                      _c("svg", [
                        _c("path", { staticClass: "mdc-notched-outline__path" })
                      ])
                    ]),
                    _c("div", { staticClass: "mdc-notched-outline__idle" })
                  ]
                )
              : _vm._e(),
            _vm.campaignData.dateOption
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                  },
                  [
                    _c(
                      "m-textfield",
                      {
                        ref: "reservedDate",
                        class: { "mdc-text-field--empty": !_vm.reservedDate },
                        attrs: {
                          type: "date",
                          min: _vm.campaignData.from,
                          max: _vm.campaignData.to,
                          autocorrect: "off",
                          spellcheck: "false",
                          autocomplete: "off",
                          outlined: ""
                        },
                        model: {
                          value: _vm.reservedDate,
                          callback: function($$v) {
                            _vm.reservedDate = $$v
                          },
                          expression: "reservedDate"
                        }
                      },
                      [
                        _c(
                          "m-floating-label",
                          { attrs: { floatAbove: !!_vm.reservedDate } },
                          [_vm._v(_vm._s(_vm.$t("customer.orderDate")))]
                        ),
                        _c("m-notched-outline")
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.campaignData.timeOption
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                  },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.reservedTime,
                            expression: "reservedTime"
                          }
                        ],
                        staticClass: "mdc-text-field__input",
                        class: { "select--hidden": !_vm.reservedTime },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.reservedTime = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { disabled: "" },
                            domProps: { value: null }
                          },
                          [_vm._v(_vm._s(_vm.$t("customer.orderTime")))]
                        ),
                        _c("option", { attrs: { value: "10:00" } }, [
                          _vm._v("10:00 AM")
                        ]),
                        _c("option", { attrs: { value: "10:30" } }, [
                          _vm._v("10:30 AM")
                        ]),
                        _c("option", { attrs: { value: "11:00" } }, [
                          _vm._v("11:00 AM")
                        ]),
                        _c("option", { attrs: { value: "11:30" } }, [
                          _vm._v("11:30 AM")
                        ]),
                        _c("option", { attrs: { value: "12:00" } }, [
                          _vm._v("12:00 PM")
                        ]),
                        _c("option", { attrs: { value: "12:30" } }, [
                          _vm._v("12:30 PM")
                        ]),
                        _c("option", { attrs: { value: "13:00" } }, [
                          _vm._v("13:00 PM")
                        ]),
                        _c("option", { attrs: { value: "13:30" } }, [
                          _vm._v("13:30 PM")
                        ]),
                        _c("option", { attrs: { value: "14:00" } }, [
                          _vm._v("14:00 PM")
                        ]),
                        _c("option", { attrs: { value: "14:30" } }, [
                          _vm._v("14:30 PM")
                        ]),
                        _c("option", { attrs: { value: "15:00" } }, [
                          _vm._v("15:00 PM")
                        ]),
                        _c("option", { attrs: { value: "15:30" } }, [
                          _vm._v("15:30 PM")
                        ]),
                        _c("option", { attrs: { value: "16:00" } }, [
                          _vm._v("16:00 PM")
                        ]),
                        _c("option", { attrs: { value: "16:30" } }, [
                          _vm._v("16:30 PM")
                        ]),
                        _c("option", { attrs: { value: "17:00" } }, [
                          _vm._v("17:00 PM")
                        ]),
                        _c("option", { attrs: { value: "17:30" } }, [
                          _vm._v("17:30 PM")
                        ]),
                        _c("option", { attrs: { value: "18:00" } }, [
                          _vm._v("18:00 PM")
                        ]),
                        _c("option", { attrs: { value: "18:30" } }, [
                          _vm._v("18:30 PM")
                        ]),
                        _c("option", { attrs: { value: "19:00" } }, [
                          _vm._v("19:00 PM")
                        ]),
                        _c("option", { attrs: { value: "19:30" } }, [
                          _vm._v("19:30 PM")
                        ]),
                        _c("option", { attrs: { value: "20:00" } }, [
                          _vm._v("20:00 PM")
                        ]),
                        _c("option", { attrs: { value: "20:30" } }, [
                          _vm._v("20:30 PM")
                        ]),
                        _c("option", { attrs: { value: "21:00" } }, [
                          _vm._v("21:00 PM")
                        ]),
                        _c("option", { attrs: { value: "21:30" } }, [
                          _vm._v("21:30 PM")
                        ])
                      ]
                    ),
                    _c("label", { staticClass: "mdc-floating-label" }, [
                      _vm._v(_vm._s(_vm.$t("customer.orderTime")))
                    ]),
                    _c("div", { staticClass: "mdc-notched-outline" }, [
                      _c("svg", [
                        _c("path", { staticClass: "mdc-notched-outline__path" })
                      ])
                    ]),
                    _c("div", { staticClass: "mdc-notched-outline__idle" })
                  ]
                )
              : _vm._e(),
            _vm.campaignData.specialRequestOption
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched mdc-text-field--textarea"
                  },
                  [
                    _c("textarea", {
                      staticClass: "mdc-text-field__input",
                      attrs: { rows: "2", cols: "100" }
                    }),
                    _c("label", { staticClass: "mdc-floating-label" }, [
                      _vm._v(_vm._s(_vm.$t("customer.specialRequest")))
                    ]),
                    _c("div", { staticClass: "mdc-notched-outline" }, [
                      _c("svg", [
                        _c("path", { staticClass: "mdc-notched-outline__path" })
                      ])
                    ]),
                    _c("div", { staticClass: "mdc-notched-outline__idle" })
                  ]
                )
              : _vm._e(),
            _vm.campaignData.mcOption
              ? [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
                    },
                    [_vm._v(_vm._s(_vm.$t("campaign.survey")))]
                  ),
                  _vm._l(_vm.campaignData.questionList, function(question, i) {
                    return _c(
                      "div",
                      {
                        key: question.id,
                        staticClass:
                          "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                      },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("campaign.question.label")) +
                              " " +
                              _vm._s(i + 1) +
                              " - " +
                              _vm._s(question.name)
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "margin-top" },
                          _vm._l(question.options, function(option, j) {
                            return _c(
                              "mdc-radio",
                              {
                                key: j,
                                staticClass: "custom-radio",
                                attrs: { name: "radios_" + i }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(option.name) +
                                    "\n                    "
                                )
                              ]
                            )
                          })
                        )
                      ]
                    )
                  })
                ]
              : _vm._e(),
            _vm.campaignData.cta
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet center"
                  },
                  [
                    _c("button", { staticClass: "mdc-fab mdc-fab--extended" }, [
                      _c("span", { staticClass: "mdc-fab__label" }, [
                        _vm._v(_vm._s(_vm.campaignData.cta))
                      ])
                    ])
                  ]
                )
              : _vm._e(),
            _vm._m(0),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet contact"
              },
              [
                _c("div", { staticStyle: { "padding-bottom": "16px" } }, [
                  _vm._v(_vm._s(_vm.$t("campaign.contact.title")))
                ]),
                _vm._l(_vm.campaignData.contactList, function(contact, index) {
                  return [
                    _c(
                      "div",
                      { key: index, staticStyle: { "padding-bottom": "16px" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { "padding-bottom": "8px" } },
                          [_vm._v(_vm._s(contact.shopName))]
                        ),
                        contact.address
                          ? _c(
                              "div",
                              { staticStyle: { "padding-bottom": "8px" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("customer.address") +
                                      " : " +
                                      contact.address
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticStyle: { "padding-bottom": "8px" } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t("customer.phone")) +
                                " :\n                        "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "tel:" +
                                    contact.countryCode +
                                    contact.number
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(contact.countryCode) +
                                    _vm._s(contact.number)
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                })
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet tnc"
              },
              [
                _c("div", { staticStyle: { "padding-bottom": "8px" } }, [
                  _vm._v(_vm._s(_vm.$t("campaign.tnc")))
                ]),
                _vm._l(_vm.campaignData.tnc.split("\n"), function(line, index) {
                  return [
                    _c(
                      "div",
                      { key: index, staticStyle: { "padding-bottom": "8px" } },
                      [_vm._v(_vm._s(line))]
                    )
                  ]
                })
              ],
              2
            ),
            _vm.campaignData.status === "active"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet flex"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "mdc-button red-color mx-auto",
                        on: { click: _vm.toggleCancelDialog }
                      },
                      [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                    )
                  ]
                )
              : _vm._e()
          ],
          2
        )
      : _vm._e(),
    _c(
      "div",
      [
        _c(
          "mdc-dialog",
          {
            attrs: {
              title: _vm.$t("actions.cancel") + "?",
              accept: _vm.$t("actions.accept"),
              cancel: _vm.$t("actions.back")
            },
            on: { accept: _vm.cancel },
            model: {
              value: _vm.cancelDialogOpen,
              callback: function($$v) {
                _vm.cancelDialogOpen = $$v
              },
              expression: "cancelDialogOpen"
            }
          },
          [_c("div", [_vm._v(_vm._s(_vm.$t("campaign.cancelMsg")))])]
        ),
        _c("mdc-dialog", {
          attrs: {
            title: _vm.$t("status.success"),
            accept: _vm.$t("actions.accept")
          },
          on: {
            accept: function($event) {
              _vm.$router.push({ name: "campaign-history-list" })
            }
          },
          model: {
            value: _vm.confirmDialogOpen,
            callback: function($$v) {
              _vm.confirmDialogOpen = $$v
            },
            expression: "confirmDialogOpen"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet line"
      },
      [_c("hr")]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }