






import { Vue, Component, Prop } from 'vue-property-decorator';
// import { Vue, Prop } from 'vue-property-decorator';
@Component({
    props: {
        propMessage: String,
    },
})
export default class Spinner2 extends Vue {
    // prettier-ignore
    @Prop({ default: true }) active?: string;
    // active = false;
}
