














import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ClipboardJS from 'clipboard';

@Component({})
export default class RemoteCampaignLinkItem extends Vue {
    @Prop()
    displayCount!: boolean;

    @Prop()
    link!: string;

    @Prop()
    count!: number;

    private clipboard!: any;

    get copyText() {
        return this.link;
    }

    public mounted() {
        this.initClipboard();
    }

    private initClipboard() {
        this.clipboard = new ClipboardJS((this.$refs.copyBtn as Vue).$el, { text: () => this.copyText });

        this.clipboard.on('success', (e: any) => {
            this.$emit('copy-success', e);
        });
        this.clipboard.on('error', (e: any) => {
            this.$emit('copy-failed', e);
        });
    }

    public beforeDestroy() {
        this.clipboard && this.clipboard.destroy();
    }
}
