var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "mdc-layout-grid app-page-activity app-page-activity--active",
      attrs: { id: "new-user-setting" }
    },
    [
      _c(
        "form",
        {
          staticClass: "mdc-layout-grid__inner",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _vm.master
            ? _c(
                "div",
                {
                  staticClass:
                    "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.accountId,
                          expression: "accountId"
                        }
                      ],
                      staticClass: "mdc-text-field__input",
                      class: { "select--hidden": !_vm.accountId },
                      attrs: { options: _vm.accountOptions },
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.accountId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.changeStore
                        ]
                      }
                    },
                    [
                      _c(
                        "option",
                        { attrs: { disabled: "" }, domProps: { value: null } },
                        [_vm._v(_vm._s(_vm.$t("settings.branch.label")))]
                      ),
                      _vm._l(_vm.accountOptions, function(account) {
                        return _c(
                          "option",
                          { key: account.id, domProps: { value: account.id } },
                          [_vm._v(_vm._s(account.store_name))]
                        )
                      })
                    ],
                    2
                  ),
                  _c("label", { staticClass: "mdc-floating-label" }, [
                    _vm._v(_vm._s(_vm.$t("settings.branch.label")))
                  ]),
                  _c("div", { staticClass: "mdc-notched-outline" }, [
                    _c("svg", [
                      _c("path", { staticClass: "mdc-notched-outline__path" })
                    ])
                  ]),
                  _c("div", { staticClass: "mdc-notched-outline__idle" })
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.userType,
                      expression: "userType"
                    }
                  ],
                  staticClass: "mdc-text-field__input",
                  class: { "select--hidden": !_vm.userType },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.userType = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.changeStore
                    ]
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { disabled: "" }, domProps: { value: null } },
                    [_vm._v(_vm._s(_vm.$t("settings.user.type")))]
                  ),
                  _c(
                    "option",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.isMasterAccount && _vm.currentUserType == 0,
                          expression: "isMasterAccount && currentUserType == 0"
                        }
                      ],
                      attrs: { value: "superuser" }
                    },
                    [_vm._v(_vm._s(_vm.$t("settings.user.superuser")))]
                  ),
                  _c("option", { attrs: { value: "user" } }, [
                    _vm._v(_vm._s(_vm.$t("settings.user.normaluser")))
                  ])
                ]
              ),
              _c("label", { staticClass: "mdc-floating-label" }, [
                _vm._v(_vm._s(_vm.$t("settings.user.type")))
              ]),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                staticClass: "mdc-text-field__input",
                attrs: {
                  type: "email",
                  autocomplete: "off",
                  autocorrect: "off",
                  spellcheck: "false"
                },
                domProps: { value: _vm.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              }),
              _c(
                "label",
                { staticClass: "mdc-floating-label ", attrs: { for: "email" } },
                [_vm._v(_vm._s(_vm.$t("settings.user.email")))]
              ),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.username,
                    expression: "username"
                  }
                ],
                staticClass: "mdc-text-field__input",
                attrs: {
                  type: "text",
                  autocomplete: "off",
                  autocorrect: "off",
                  spellcheck: "false"
                },
                domProps: { value: _vm.username },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.username = $event.target.value
                  }
                }
              }),
              _c(
                "label",
                {
                  staticClass: "mdc-floating-label ",
                  attrs: { for: "username" }
                },
                [_vm._v(_vm._s(_vm.$t("settings.user.display")))]
              ),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password"
                  }
                ],
                staticClass: "mdc-text-field__input",
                attrs: {
                  type: "password",
                  autocomplete: "off",
                  autocorrect: "off",
                  spellcheck: "false"
                },
                domProps: { value: _vm.password },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password = $event.target.value
                  }
                }
              }),
              _c(
                "label",
                {
                  staticClass: "mdc-floating-label ",
                  attrs: { for: "password" }
                },
                [_vm._v(_vm._s(_vm.$t("settings.password.label")))]
              ),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.confirmPassword,
                    expression: "confirmPassword"
                  }
                ],
                staticClass: "mdc-text-field__input",
                attrs: {
                  type: "password",
                  autocomplete: "off",
                  autocorrect: "off",
                  spellcheck: "false"
                },
                domProps: { value: _vm.confirmPassword },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.confirmPassword = $event.target.value
                  }
                }
              }),
              _c(
                "label",
                {
                  staticClass: "mdc-floating-label ",
                  attrs: { for: "confirmPwd" }
                },
                [_vm._v(_vm._s(_vm.$t("settings.password.confirm")))]
              ),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedCountryCode,
                      expression: "selectedCountryCode"
                    }
                  ],
                  staticClass: "mdc-text-field__input",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedCountryCode = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { disabled: "" }, domProps: { value: undefined } },
                    [_vm._v(_vm._s(_vm.$t("signup.countryCode")))]
                  ),
                  _vm._l(_vm.countryCodeList, function(code) {
                    return _c(
                      "option",
                      { key: code.en, domProps: { value: code } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$i18n.locale == "zh_HK"
                              ? code.zh
                              : _vm.$i18n.locale == "zh_CN"
                                ? code.cn
                                : code.en
                          ) +
                            " " +
                            _vm._s("(" + code.phone_code + ")")
                        )
                      ]
                    )
                  })
                ],
                2
              ),
              _c("label", { staticClass: "mdc-floating-label" }, [
                _vm._v(_vm._s(_vm.$t("signup.countryCode")))
              ]),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.phone,
                    expression: "phone"
                  }
                ],
                staticClass: "mdc-text-field__input",
                attrs: {
                  type: "number",
                  autocomplete: "off",
                  autocorrect: "off",
                  spellcheck: "false"
                },
                domProps: { value: _vm.phone },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.phone = $event.target.value
                  }
                }
              }),
              _c(
                "label",
                { staticClass: "mdc-floating-label ", attrs: { for: "phone" } },
                [_vm._v(_vm._s(_vm.$t("settings.user.phone")))]
              ),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ]
          ),
          _vm.options.length
            ? _c(
                "div",
                {
                  staticClass:
                    "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                },
                [
                  _c("div", { staticClass: "mdc-typography--headline6" }, [
                    _vm._v(_vm._s(_vm.$t("settings.permissions.title")))
                  ]),
                  _vm._l(_vm.options, function(option) {
                    return _c(
                      "div",
                      {
                        key: option["name"],
                        staticClass: "mdc-form-field",
                        staticStyle: { "margin-bottom": "-11px" }
                      },
                      [
                        _c("div", { staticClass: "mdc-checkbox" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: option["value"],
                                expression: "option['value']"
                              }
                            ],
                            staticClass: "mdc-checkbox__native-control",
                            attrs: {
                              type: "checkbox",
                              autocomplete: "off",
                              autocorrect: "off",
                              spellcheck: "false",
                              disabled: _vm.userType == "superuser"
                            },
                            domProps: {
                              checked: Array.isArray(option["value"])
                                ? _vm._i(option["value"], null) > -1
                                : option["value"]
                            },
                            on: {
                              change: function($event) {
                                var $$a = option["value"],
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        option,
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        option,
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(option, "value", $$c)
                                }
                              }
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "mdc-checkbox__background" },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "mdc-checkbox__checkmark",
                                  attrs: { viewBox: "0 0 24 24" }
                                },
                                [
                                  _c("path", {
                                    staticClass: "mdc-checkbox__checkmark-path",
                                    attrs: {
                                      fill: "none",
                                      d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                                    }
                                  })
                                ]
                              ),
                              _c("div", {
                                staticClass: "mdc-checkbox__mixedmark"
                              })
                            ]
                          )
                        ]),
                        _c("label", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.permissionTranslate[option["name"]])
                              )
                            )
                          ])
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm.errors.length
            ? _c(
                "div",
                {
                  staticClass:
                    "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                },
                [
                  _c("p", { staticClass: "error-prompt" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.errors, function(error) {
                        return _c("li", { key: error }, [_vm._v(_vm._s(error))])
                      })
                    )
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
            },
            [
              _c(
                "div",
                {
                  staticClass: "mdc-button",
                  on: {
                    click: function($event) {
                      _vm.$router.go(-1)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.back")))]
              ),
              _c(
                "button",
                {
                  staticClass: "mdc-fab mdc-fab--extended",
                  class: { disabled: !_vm.okToSubmit },
                  attrs: {
                    type: "submit",
                    "aria-label": _vm.$t("actions.create")
                  }
                },
                [
                  _c("span", { staticClass: "mdc-fab__label" }, [
                    _vm._v(_vm._s(_vm.$t("actions.create")))
                  ]),
                  _c(
                    "svg",
                    {
                      staticClass: "mdc-fab__icon",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewbox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }