var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "spinner1" }, [
    _c(
      "div",
      { staticClass: "preloader-wrapper", class: { active: _vm.active } },
      [
        _c("div", { staticClass: "spinner-layer" }, [
          _c("div", { staticClass: "circle-clipper left" }, [
            _c("div", {
              staticClass: "circle",
              class: { border2: _vm.border2_ }
            })
          ]),
          _c("div", { staticClass: "circle-clipper right" }, [
            _c("div", {
              staticClass: "circle",
              class: { border2: _vm.border2_ }
            })
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }