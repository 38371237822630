<template>
    <div class="app-page app-page-payment app-page--active">
        <div class="app-page-container">
            <div class="mdc-layout-grid app-page-activity app-page-activity--active bottom-nav--hidden">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet">
                        <div class="mdc-typography--headline5">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="80px" height="80px"><path d="M0 0h24v24H0V0z" fill="none"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-6c-2.33 0-4.32 1.45-5.12 3.5h1.67c.69-1.19 1.97-2 3.45-2s2.75.81 3.45 2h1.67c-.8-2.05-2.79-3.5-5.12-3.5z"/></svg>
                        </div>
                    </div>
                    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet">
                        <div class="mdc-typography--headline5">
                            {{$t('signup.unfortunately')}}<br/>
                            {{$t('signup.unsuccessful')}}
                        </div>
                    </div>
                    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet">
                        <div class="mdc-typography--headline6">
                            {{$t('signup.contact_us')}}<br/>
                            (3618 7750/6922 5068)<br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '../src/assets/scss/global-variables.scss';
svg {
    // fill: $mdc-theme-secondary;
    fill: $mdc-theme-on-surface;
}
.mdc-typography--headline5,
.mdc-typography--headline6 {
    text-align: center;
    // color: $mdc-theme-secondary;
    font-weight: 400;
}
.mdc-layout-grid {
    position: fixed;
    top: -6%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
