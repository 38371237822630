export const general = {
    payment: '收款',
    direct: '直碌',
    recurrent: '月付',
    remote: '遥距',
    campaign: '宣传',
    online: '网上通道',
    dashboard: '总览',
    payout: '转帐记录',
    transactions: '交易记录',
    settings: '设定',
    notifications: '通知',
    planning: '策划',
    summary: '总结',
    keyword: '关键字',
    searchCategory: {
        title: '类别',
        all: '全部',
        description: '详情',
        receiptNumber: '单据编号',
        status: '状态',
        type: '种类',
        cashflow: '应收月付',
    },
    paymentMethods: {
        label: '付款方式',
        qrPayment: '二维码支付',
        card: '信用卡',
        wechat: '微信支付',
        alipay: '支付宝',
    },
    card: {
        label: '信用卡',
        number: '信用卡号码',
        holderName: '持卡人姓名',
        expiryDate: '到期日',
        info: '信用卡资料',
        error: {
            missingCardNumber: '请输入信用卡号码。',
            missingExpiryDate: '请输入信用卡到期日。',
            missingCvc: '请输入信用卡验证码。',
            missingCardHolderName: '请输入持卡人姓名。',
            invalidExpiryDate: '信用卡在支付所有期数前到期。',
        },
    },
    paymentDetails: {
        quantity: '數量',
        amount: '金额',
        description: '详情',
        remoteMessage: '客户讯息',
        totalAmount: '总金额',
    },
    date: {
        start: '开始日期',
        end: '结束日期',
    },
    history: {
        title: '记录',
        payment: '付款记录',
        expectedIncome: '预期收入',
        expectedCount: '预期笔数',
        chargeCount: '期数',
    },
    login: {
        agreementHeader: '登入即代表您已了解并同意我们的',
        privacy: '私隐政策',
        tnc: '服务条款',
    },
    thisStore: '本分店',
    warningMsg: '请仔细检查所输入的资料，一经送出将不能更改！',
    confirmInfo: '确认资料',
    confirmMsg: '您确定输入资料无误?',
    wechatPayDisable: '微信支付尚未开通，请联络相关客户经理。(3618 7750 / 6010 5244)',
    qrPaymentDisable: '微信支付/支付宝尚未开通，请联络相关客户经理。(3618 7750 / 6010 5244)',
};
