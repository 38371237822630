export const status = {
    title: '状态',
    all: '全部',
    succeeded: '成功付款',
    authorized: '已授权',
    unauthorized: '取消授权',
    pending: '处理中',
    paying: '付款中',
    failed: '付款失败',
    refunded: '已退款',
    canceled: '已取消',
    arrived: '已到帐',
    inTransit: '转帐中',
    active: '使用中',
    inactive: '未能正常付款',
    completed: '已完结',
    paused: '已暂停',
    agentPromotion: '代客推广',
    free: '免费',
    success: '成功',
    failure: '失败',
};
