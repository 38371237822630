














































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BANKS from '../../constants/BANKS';
import { dynamicSort } from '../../utils/dynamicSort';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';

const ENV = (process.env.VUE_APP_ENV || 'development') as 'development' | 'staging' | 'production';

@Component
export default class ExternalAccount extends Vue {
    private holderName = '';
    private holderType = '';
    private bank: string | null = null;
    private bankOptions = (BANKS.bankOptions[ENV] as any)
        .sort(dynamicSort('bank_name'))
        .map((bank: { bank_code: string; bank_name:string, chinese_name: string }) => {
            return {
                value: bank.bank_code,
                bank_name: bank.bank_name,
                chinese_name: bank.chinese_name
            };
        });
    private branchCode = '';
    private accountNumber = '';
    private country: string = '';
    private countryOptions = BANKS.countryOptions.sort(dynamicSort('text'));
    private currency: string = '';
    private currencyOptions = BANKS.currencyOptions.sort(dynamicSort('text'));
    private errors: string[] = [];

    public async mounted() {
        await this.initMDCTextFields();
        this.holderName = !this.$store.state.signUp.externalAccount.holderName ? '' : this.$store.state.signUp.externalAccount.holderName;
        this.holderType = !this.$store.state.signUp.externalAccount.holderType ? '' : this.$store.state.signUp.externalAccount.holderType;
        this.bank = this.$store.state.signUp.externalAccount.bank;
        this.branchCode = !this.$store.state.signUp.externalAccount.branchCode ? '' : this.$store.state.signUp.externalAccount.branchCode;
        this.accountNumber = !this.$store.state.signUp.externalAccount.accountNumber
            ? ''
            : this.$store.state.signUp.externalAccount.accountNumber;
        this.country = !this.$store.state.signUp.externalAccount.country ? '' : this.$store.state.signUp.externalAccount.country;
        this.currency = !this.$store.state.signUp.externalAccount.currency ? '' : this.$store.state.signUp.externalAccount.currency;
        
        if (
            this.holderName == '' ||
            this.holderType == '' ||
            this.bank == null ||
            this.branchCode == '' ||
            this.accountNumber == '' ||
            this.accountNumber == '' ||
            this.country == '' ||
            this.currency == ''
        ) {
            await this.$store.dispatch('retrieveSignUpData');
            this.holderName = !!this.$store.state.signUp.externalAccount.holderName
                ? this.$store.state.signUp.externalAccount.holderName
                : '';
            this.holderType = !!this.$store.state.signUp.externalAccount.holderType
                ? this.$store.state.signUp.externalAccount.holderType
                : '';
            this.bank = this.$store.state.signUp.externalAccount.bank;
            this.branchCode = !!this.$store.state.signUp.externalAccount.branchCode
                ? this.$store.state.signUp.externalAccount.branchCode
                : '';
            this.accountNumber = !!this.$store.state.signUp.externalAccount.accountNumber
                ? this.$store.state.signUp.externalAccount.accountNumber
                : '';
            this.country = !!this.$store.state.signUp.externalAccount.country ? this.$store.state.signUp.externalAccount.country : '';
            this.currency = !!this.$store.state.signUp.externalAccount.currency ? this.$store.state.signUp.externalAccount.currency : '';
        }

    }

    protected update() {
        this.errors = [];

        if (
            this.holderName == '' ||
            this.holderType == '' ||
            this.bank == null ||
            this.branchCode == '' ||
            this.accountNumber == '' ||
            this.accountNumber == '' ||
            this.country == '' ||
            this.currency == ''
        ) {
            this.errors.push(this.$t('AUTH.EMPTY_FIELD') as any);
            // this.errors.push('請輸入所有資料。');
        }

        if (this.errors.length) {
            return;
        } else {
            this.$store.dispatch('saveExternalAccountData', {
                nextPage: 'document_upload',
                legal_entity: {
                    holderName: this.holderName,
                    holderType: this.holderType,
                    bank: this.bank,
                    branchCode: this.branchCode,
                    accountNumber: this.accountNumber,
                    country: this.country,
                    currency: this.currency,
                },
            });
            this.$router.push({ name: 'document_upload' });
        }
    }

    // animation function
    private initMDCTextFields() {
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }

    protected goPrevious() {
        this.$store.dispatch('saveExternalAccountData', {
            nextPage: 'owner',
            legal_entity: {
                holderName: this.holderName,
                holderType: this.holderType,
                bank: this.bank,
                branchCode: this.branchCode,
                accountNumber: this.accountNumber,
                country: this.country,
                currency: this.currency,
            },
        });
        this.$router.push({ name: 'owner' });
    }
}
