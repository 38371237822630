












































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isEmail, isMobileNumber, isUUID4 } from '../../utils/validators';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import { IMaskComponent } from 'vue-imask';

@Component({
    components: {
        'imask-input': IMaskComponent,
    },
})
export default class EditPermission extends Vue {
    private mdcTextField: any;
    private accountId = '';
    private accountOptions: object[] = [];
    private user = {};
    private isMasterAccount = '';
    private selectedUserType: number = 2;
    private permissionTranslate = {
        add_user: 'settings.permissions.account.addUser',
        add_account: 'settings.permissions.account.addAccount',
        edit_permission: 'settings.permissions.account.editPermission',
        modify_information: 'settings.permissions.account.modifyInformation',

        refund: 'settings.permissions.payment.refund',
        cancel_recurrence: 'settings.permissions.payment.cancelRecurrence',
        change_recurrence_credit_card: 'settings.permissions.payment.changeRecurrenceCreditCard',
        edit_charge_description: 'settings.permissions.payment.editChargeDescription',
        capture: 'settings.permissions.payment.capture',
        // enable_direct: 'settings.permissions.payment.enable_direct',
        enable_recurrence: 'settings.permissions.payment.enable_recurrence',
        enable_remote: 'settings.permissions.payment.enable_remote',
        enable_campaign: 'settings.permissions.payment.enable_campaign',

        dashboard: 'settings.permissions.functional.dashboard',
        payout: 'settings.permissions.functional.payout',
        export: 'settings.permissions.functional.export',
        printer: 'settings.permissions.functional.printer',
        // wechat: '微訊支付',
        campaign_agent: 'settings.permissions.functional.campaignAgent',
        transactions_history: 'settings.permissions.functional.transactionsHistory',

        // notification
        // payment: 'settings.permissions.notification.payment',
        // campaign: 'settings.permissions.notification.campaign',
        // summary: 'settings.permissions.notification.summary',
    };

    private permissions = {
        account: {
            add_user: false,
            edit_permission: false,
            add_account: false,
            modify_information: false,
        },
        payment: {
            refund: false,
            cancel_recurrence: false,
            change_recurrence_credit_card: false,
            edit_charge_description: false,
            capture: false,
            // enable_direct: false,
            enable_recurrence: false,
            enable_remote: false,
            enable_campaign: false,
        },
        functional: {
            dashboard: false,
            printer: false,
            export: false,
            payout: false,
            campaign_agent: false,
            transactions_history: false,
        },
        notification: {
            payment: false,
            campaign: false,
            summary: false,
        },
    };

    private storesAndUsersPermission: any = '';
    private allowedPermission: any = '';
    private engCharMask = /^[a-zA-Z0-9!@#$%^&()~`_\-+=,.?/:;{}\[\]| ]*$/;
    private errors: string[] = [];

    get userOptions() {
        let users = this.accountOptions.filter((account: any) => account.id == this.accountId).map((account: any) => account.users)[0];
        return users;
    }

    get userEditable() {
        if (this.$store.state.user.permissions_.userType == 0) {
            return this.selectedUserType == 1 || this.selectedUserType == 2;
        } else if (this.$store.state.user.permissions_.userType == 1) {
            return this.selectedUserType == 2;
        } else if (this.$store.state.user.permissions_.userType == 2) {
            return this.selectedUserType == 2;
        }
    }

    get disabled() {
        return this.user ? ((this.user as any).id ? false : true) : true;
    }

    get master() {
        return this.$store.state.user ? this.$store.state.user.permissions_.master : false;
    }

    get options() {
        let o: any = [];
        for (let key in this.allowedPermission) {
            if (key !== 'accountId') {
                for (let subkey in this.allowedPermission[key]) {
                    if (this.allowedPermission[key][subkey] != 0 && this.permissionTranslate.hasOwnProperty(subkey)) {
                        //default printer permission is true
                        if (subkey != 'printer') {
                            o.push({ name: subkey, value: false });
                        } else {
                            o.push({ name: subkey, value: true });
                        }
                    }
                }
            }
        }
        return o;
    }

    protected changeStore() {
        // reset account
        this.user = {};
        if (this.accountId) {
            // which permissions are allowed in this
            for (let permission of this.storesAndUsersPermission) {
                if (permission.account_id == this.accountId) {
                    this.allowedPermission = {
                        account: permission['account_permission'],
                        payment: permission['payment_permission'],
                        functional: permission['functional_permission'],
                        // notification: permission['notification_permission'],
                    };
                    this.user = {};
                    break;
                }
            }
        }
        this.isMasterAccount = this.accountOptions
            .filter((account: any) => account.id == this.accountId)
            .map((account: any) => account.master)[0];
    }

    protected changeUser() {
        let currentUserPermission = {};
        let found = false;
        // console.log(this.user);
        if (this.user) {
            //Find the permission config in all store and user permission config
            for (let permission of this.storesAndUsersPermission) {
                if (permission.account_id == this.accountId) {
                    for (let userPermission of permission['users']) {
                        if (userPermission.user_id == this.user) {
                            currentUserPermission = {
                                account: userPermission['account_permission'],
                                payment: userPermission['payment_permission'],
                                functional: userPermission['functional_permission'],
                                // notification: userPermission['notification_permission'],
                            };
                            found = true;
                            break;
                        }
                    }
                }
                if (found) {
                    break;
                }
            }

            //Set the value of the options
            for (let key in currentUserPermission) {
                for (let subkey in (currentUserPermission as any)[key]) {
                    for (let option of this.options) {
                        if (option['name'] === subkey) {
                            // console.log(`[${key}][${subkey}]: ${(currentUserPermission as any)[key][subkey]}`);
                            option['value'] = (currentUserPermission as any)[key][subkey];
                        }
                    }
                }
            }
        }

        this.userOptions.forEach((userOption: any) => {
            if (userOption.id == this.user) {
                this.selectedUserType = userOption.type;
            }
        });

        // console.log(this.selectedUserType);
    }

    public async created() {
        this.accountOptions = await this.$store.dispatch('retreiveStoresAndUsers');
        // this.accountPermissionList = await this.$store.dispatch('retreiveStorePermission');
        this.storesAndUsersPermission = await this.$store.dispatch('retreiveStoresAndUsersPermission');
        // init the allowed permission if this is not a master account
        if (!this.master && this.accountOptions[0]) {
            // this.accountId = (this.accountOptions[0] as any).id;
            this.accountId = this.$store.state.user.accountId;
            for (let permission of this.storesAndUsersPermission) {
                if (permission.account_id == this.accountId) {
                    this.allowedPermission = {
                        account: permission['account_permission'],
                        payment: permission['payment_permission'],
                        functional: permission['functional_permission'],
                        // notification: permission['notification_permission'],
                    };
                    break;
                }
            }
        }
    }

    public mounted() {
        this.initMDCTextFields();
    }

    private permissionFormation() {
        for (let option of this.options) {
            for (let key in this.permissions) {
                for (let subkey in (this.permissions as any)[key]) {
                    if (option['name'] == subkey) {
                        (this.permissions as any)[key][subkey] = option['value'];
                        break;
                    }
                }
            }
        }
    }

    protected submit() {
        this.permissionFormation();
        return this.$store
            .dispatch('editPermissions', {
                userId: this.user,
                permissions: this.permissions,
            })
            .then((response: any) => {
                return this.$root.$emit('confirm', {
                    path: '/settings',
                    title: this.$t('status.success'),
                });
            })
            .catch((error: any) => {
                return this.$root.$emit('error', error);
            });
    }

    // animation function
    private initMDCTextFields() {
        this.mdcTextField = Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
