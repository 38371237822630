var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mdc-layout-grid activity-payment-amount",
      class: _vm.langClass,
      attrs: { id: "amount" }
    },
    [
      _c(
        "div",
        { staticClass: "mdc-layout-grid__inner" },
        [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--large mdc-text-field--outlined mdc-text-field--not-notched"
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.currency,
                      expression: "currency"
                    }
                  ],
                  staticClass: "mdc-text-field__input",
                  attrs: { options: _vm.currencyList },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.currency = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { disabled: "" }, domProps: { value: null } },
                    [_vm._v(_vm._s(_vm.$t("currency.name")))]
                  ),
                  _vm._l(_vm.currencyList, function(c) {
                    return _c(
                      "option",
                      { key: c.text, domProps: { value: c.value } },
                      [_vm._v(_vm._s(_vm.$t(c.text)))]
                    )
                  })
                ],
                2
              ),
              _c("label", { staticClass: "mdc-floating-label" }, [
                _vm._v(_vm._s(_vm.$t("currency.name")))
              ]),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--xlarge mdc-text-field--outlined mdc-text-field--not-notched"
            },
            [
              _c("imask-input", {
                ref: "paymentInputUser",
                staticClass: "mdc-text-field__input",
                staticStyle: { "text-align": "right", color: "transparent" },
                attrs: {
                  mask: Number,
                  prepare: _vm.convertPlusToDot,
                  radix: ".",
                  thousandsSeparator: ",",
                  scale: _vm.currency !== "jpy" ? 2 : 0,
                  unmask: "typed",
                  min: 0,
                  max: _vm.chargeLimit,
                  placeholder: _vm.placeHolderForCurrency(_vm.currency),
                  type: "tel",
                  autocorrect: "off",
                  spellcheck: "false",
                  autocomplete: "off"
                },
                on: { complete: _vm.onCompleteHandler },
                model: {
                  value: _vm.amount,
                  callback: function($$v) {
                    _vm.amount = $$v
                  },
                  expression: "amount"
                }
              }),
              _c(
                "div",
                {
                  staticClass: "mdc-text-field__input",
                  staticStyle: {
                    "text-align": "right",
                    position: "absolute",
                    top: "1px",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    "pointer-events": "none"
                  },
                  attrs: { readonly: "" }
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "line-height": "1",
                        margin: "auto 0 auto auto"
                      }
                    },
                    [_vm._v(_vm._s(_vm.amountShown))]
                  )
                ]
              ),
              _c("div", { staticClass: "mdc-text-field-outline" })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.description,
                    expression: "description"
                  }
                ],
                staticClass: "mdc-text-field__input",
                attrs: {
                  type: "text ",
                  id: "payment-description ",
                  autocomplete: "off",
                  autocorrect: "off",
                  spellcheck: "false"
                },
                domProps: { value: _vm.description },
                on: {
                  keyup: _vm.onCompleteHandler_Description,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.description = $event.target.value
                  }
                }
              }),
              _c(
                "label",
                {
                  staticClass: "mdc-floating-label",
                  class: [
                    {
                      "mdc-floating-label--float-above": _vm.description
                        ? true
                        : false
                    }
                  ],
                  attrs: { for: "payment-description" }
                },
                [_vm._v(_vm._s(_vm.$t("general.paymentDetails.description")))]
              ),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet recurrent-box",
              class: { active: _vm.isRecurrent__ === true }
            },
            [
              _c("mdc-switch", {
                staticClass: "recurrent-switch",
                class: { checked: _vm.isRecurrent__ === true },
                style: {
                  visibility: _vm.enableRecurrence ? "visible" : "hidden"
                },
                attrs: {
                  "align-end": true,
                  label: _vm.$t("general.recurrent"),
                  disabled: !_vm.enableRecurrence
                },
                model: {
                  value: _vm.isRecurrent__,
                  callback: function($$v) {
                    _vm.isRecurrent__ = $$v
                  },
                  expression: "isRecurrent__"
                }
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isRecurrent__,
                      expression: "isRecurrent__"
                    }
                  ],
                  staticClass: "mdc-text-field mdc-text-field--outlined"
                },
                [
                  _c("imask-input", {
                    ref: "recurrentDuration",
                    staticClass: "mdc-text-field__input",
                    attrs: {
                      id: "payment-recurrent-duration",
                      mask: Number,
                      scale: 0,
                      unmask: "typed",
                      max: 99,
                      type: "tel",
                      autocorrect: "off",
                      spellcheck: "false",
                      autocomplete: "off"
                    },
                    on: { complete: _vm.onCompleteHandler_Duration },
                    model: {
                      value: _vm.recurrentDuration_,
                      callback: function($$v) {
                        _vm.recurrentDuration_ = $$v
                      },
                      expression: "recurrentDuration_"
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "mdc-floating-label",
                      attrs: { for: "payment-recurrent-duration" }
                    },
                    [_vm._v(_vm._s(_vm.$t("recurrent.duration")))]
                  ),
                  _c("div", { staticClass: "mdc-notched-outline" }, [
                    _c("svg", [
                      _c("path", { staticClass: "mdc-notched-outline__path" })
                    ])
                  ]),
                  _c("div", { staticClass: "mdc-notched-outline__idle" })
                ],
                1
              ),
              _c("mdc-checkbox", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isRecurrent__,
                    expression: "isRecurrent__"
                  }
                ],
                staticClass: "auto-extend",
                attrs: {
                  label: _vm.recurrentDuration
                    ? _vm.$t("recurrent.autoRenewalMessage", [
                        _vm.recurrentDuration
                      ])
                    : _vm.$t("recurrent.autoRenewal")
                },
                model: {
                  value: _vm.recurrentAutoExtend,
                  callback: function($$v) {
                    _vm.recurrentAutoExtend = $$v
                  },
                  expression: "recurrentAutoExtend"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
            },
            [
              _vm.check
                ? _c("p", { staticClass: "error-prompt" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("errors.PAYMENT_DETAILS.invalid_transaction", [
                          _vm.chargeLimitDisplay
                        ])
                      )
                    )
                  ])
                : _vm._e(),
              _vm.isRecurrent_ && _vm.checkDescription
                ? _c("p", { staticClass: "error-prompt" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("errors.PAYMENT_DETAILS.missing_description")
                      )
                    )
                  ])
                : _vm._e(),
              _vm.isRecurrent_ && _vm.checkDuration
                ? _c("p", { staticClass: "error-prompt" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$t(
                            "errors.PAYMENT_DETAILS.invalid_recurrent_duration"
                          )
                        ) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
            },
            [
              _c(
                "button",
                {
                  staticClass: "mdc-button",
                  on: {
                    click: function($event) {
                      _vm.reset(true)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.reset")))]
              ),
              _vm.isTestingMode
                ? _c(
                    "button",
                    {
                      staticClass: "mdc-button",
                      on: {
                        click: function($event) {
                          _vm.autofill(true)
                        }
                      }
                    },
                    [_vm._v("Auto fill")]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "mdc-fab mdc-fab--extended",
                  attrs: { "aria-label": _vm.$t("actions.next") },
                  on: { click: _vm.nextPage }
                },
                [
                  _c("span", { staticClass: "mdc-fab__label" }, [
                    _vm._v(_vm._s(_vm.$t("actions.next")))
                  ]),
                  _c(
                    "svg",
                    {
                      staticClass: "mdc-fab__icon",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewbox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          ),
          _c(
            "mdc-dialog",
            {
              ref: "paymentConfirm",
              attrs: {
                title: _vm.$t("recurrent.summary"),
                accept: _vm.$t("actions.accept"),
                cancel: _vm.$t("actions.cancel")
              },
              on: { accept: _vm.payDialogConfirm_accept },
              model: {
                value: _vm.paymentConfirm_open,
                callback: function($$v) {
                  _vm.paymentConfirm_open = $$v
                },
                expression: "paymentConfirm_open"
              }
            },
            [
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("recurrent.amount")) +
                    ": " +
                    _vm._s(_vm._f("currencySymbolDisplay")(_vm.currency)) +
                    _vm._s(_vm.maskAmount)
                )
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("recurrent.duration")) +
                    ": " +
                    _vm._s(_vm.recurrentDuration_)
                )
              ]),
              _c("p", [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$t("recurrent.totalAmount", [
                        _vm.symbolDisplay(_vm.currency),
                        _vm.maskAmount,
                        _vm.recurrentDuration_,
                        _vm.symbolDisplay(_vm.currency),
                        _vm.maskTotalAmount
                      ])
                    ) +
                    "\n            "
                )
              ]),
              _c(
                "p",
                { staticStyle: { color: "black" } },
                [
                  _c("font", {
                    attrs: { size: "3" },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("recurrent.warningMessage", [
                          _vm.earliestPossibleExpiryData
                        ])
                      )
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }