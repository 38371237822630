














import { Component, Vue, Prop } from 'vue-property-decorator';
import * as moment from 'moment';
import PayoutStatus from '@/components/payouts/PayoutStatus.vue';

@Component({
    components: {
        PayoutStatus,
    },
})
export default class PayoutList extends Vue {
    @Prop() payouts!: { [_: string]: any }[];
    @Prop(Boolean) dividers!: boolean;

    get payoutsRender() {
        moment.locale(this.$i18n.locale);
        return this.payouts.map(p => ({
            ...p,
            ...{
                arrival_date__formatted: moment(p.arrival_date).format('LL'),
                // .slice(5),
            },
        }));
    }
}
