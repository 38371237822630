import { isPlatform } from '@/utils/platform';
import { scrollIntoViewSmoothly } from '@/utils';
import { Capacitor } from '@capacitor/core/dist/esm/definitions';
import { KeyboardInfo } from '@capacitor/core/dist/esm/core-plugin-definitions';
const privateStripeElementClass = '__PrivateStripeElement';

export default function(win: Window, doc: Document) {
    // #region ios prevent double-tap
    if (!isPlatform(win, 'hybrid') && isPlatform(win, 'ios')) {
        let multiTouch: any, movingTouch: any, targetIsInput: any, lastTouchEnd: any, touchX: any, touchY: any;
        doc.addEventListener(
            'touchstart',
            function(event) {
                movingTouch = 0;
                multiTouch = event.touches.length > 1;
                let t = (event.target as any).tagName.toUpperCase();
                targetIsInput = t == 'INPUT' || t == 'TEXTAREA' || t == 'SELECT' || t == 'A' || t == 'BUTTON' || t == 'LABEL';
                // console.log('targetIsInput: ' + (targetIsInput ? 'true' : 'false'));
                // console.log('multiTouch: ' + (multiTouch ? 'true' : 'false'));
            },
            {
                passive: true,
            }
        );

        doc.addEventListener(
            'touchmove',
            function() {
                movingTouch = 1;
            },
            {
                passive: true,
            }
        );

        doc.addEventListener(
            'touchend',
            function(event) {
                if (multiTouch || movingTouch || targetIsInput) return;
                // console.log('single touch end');
                // let dp = false;
                if (typeof event.cancelable !== 'boolean' || event.cancelable) {
                    let now = Date.now(),
                        x = event.changedTouches[0].clientX,
                        y = event.changedTouches[0].clientY;
                    if (now - lastTouchEnd <= 500 && Math.abs(x - touchX) + Math.abs(y - touchY) < 60) {
                        // console.log('prevent');
                        event.preventDefault();
                        // dp = true;
                    }
                    lastTouchEnd = now;
                    touchX = x;
                    touchY = y;
                    // console.log(dp ? 'double tap' : 'normal tap');
                }
                //  else {
                //     lastTouchEnd = 0;
                // }
            },
            {
                passive: false,
            }
        );
    }
    // #endregion ios prevent double-tap

    // run for native ios only
    if (isPlatform(win, 'capacitor') && isPlatform(win, 'ios')) {
        iosNativeKeyboardFix(win, doc);
    }
}

function iosNativeKeyboardFix(win: Window, doc: Document) {
    // check for window.Capacitor
    const Capacitor: Capacitor | undefined = (win as any).Capacitor;
    if (!(Capacitor && Capacitor.Plugins)) {
        return;
    }

    // import Keyboard Plugin
    const { Keyboard } = Capacitor.Plugins;

    Keyboard.setAccessoryBarVisible({ isVisible: true });

    Keyboard.addListener('keyboardWillShow', updateBodyPadding);

    Keyboard.addListener('keyboardWillHide', updateBodyPadding);

    function updateBodyPadding(info?: KeyboardInfo) {
        const keyboardHeight = info && info.keyboardHeight;
        if (keyboardHeight) {
            let activeActivity = document.querySelector('.app-page-activity--active');
            let botomNavHeight = activeActivity ? parseInt(window.getComputedStyle(activeActivity).marginBottom as string) : 0;
            doc.body.style.paddingBottom = keyboardHeight - botomNavHeight + 'px';
            let target = document.activeElement;
            target && scrollIntoViewSmoothly(target, { behavior: 'smooth', boundary: document.documentElement } as any);
        } else {
            doc.body.style.paddingBottom = '';
        }
    }
}
