export const customer = {
    title: '客戶',
    name: '姓名',
    countryCode: '國家代碼',
    phone: '電話',
    email: '電郵',
    address: '地址',
    remarks: '備註',
    refNo: '參考編號',
    optional: ' (選填)',
    required: ' (必填)',
    new: '新增客戶',
    newClient: '新客戶',
    currentClient: '現有客戶',
    gender: '性別',
    male: '男',
    female: '女',
    age: '年齡',
    orderDate: '預訂日期',
    orderTime: '預訂時間',
    specialRequest: '客戶諮詢內容',
};
