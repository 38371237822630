var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "remote-campaign" } },
    [
      _vm.displayName && !_vm.modalShow
        ? [
            _c("div", { staticClass: "mdc-layout-grid__inner" }, [
              _c(
                "div",
                {
                  staticClass:
                    "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size merhcant-heading"
                },
                [
                  _c("img", {
                    staticClass: "logo",
                    attrs: { src: _vm.merchantLogo }
                  }),
                  _c("div", { staticClass: "merchant-name" }, [
                    _vm._v(_vm._s(_vm.displayName))
                  ])
                ]
              )
            ]),
            _c("br"),
            _c("EditingRemoteCampaign", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editing && !_vm.submitted,
                  expression: "editing && !submitted"
                }
              ],
              attrs: {
                merchantDisplayName: _vm.displayName,
                merchantLogo: _vm.merchantLogo,
                branchOptions: _vm.branchOptions
              },
              on: { preview: _vm.preview }
            }),
            _c("RemoteCampaignPreview", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.editing && !_vm.submitted,
                  expression: "!editing && !submitted"
                }
              ],
              attrs: {
                previewData: _vm.campaignData,
                bannerSrc: _vm.bannerSrc,
                merchantDisplayName: _vm.displayName,
                merchantLogo: _vm.merchantLogo,
                baseUrl: _vm.baseUrl
              },
              on: { back: _vm.back, save: _vm.save }
            }),
            _vm.submitted
              ? _c(
                  "div",
                  _vm._l(_vm.campaignData.linkList, function(link) {
                    return _c("RemoteCampaignLinkItem", {
                      key: link,
                      attrs: {
                        link: _vm.baseUrl + "/" + link,
                        displayCount: false
                      }
                    })
                  })
                )
              : _vm._e()
          ]
        : _vm._e(),
      _c(
        "mdc-dialog",
        {
          staticClass: "mdc-dialog",
          attrs: {
            title: _vm.$t("settings.merchant.setup"),
            accept: _vm.$t("actions.accept"),
            cancel: _vm.$t("actions.cancel")
          },
          on: {
            validate: _vm.onValidate,
            accept: function($event) {
              _vm.accept()
            },
            cancel: function($event) {
              _vm.quit()
            }
          },
          model: {
            value: _vm.modalShow,
            callback: function($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow"
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "mdc-text-field mdc-text-field--outlined mdc-text-field--not-notched",
              staticStyle: { width: "100%" }
            },
            [
              _c("imask-input", {
                staticClass: "mdc-text-field__input small-font",
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("settings.merchant.display"),
                  autocorrect: "off",
                  spellcheck: "false",
                  autocomplete: "off"
                },
                model: {
                  value: _vm.displayName,
                  callback: function($$v) {
                    _vm.displayName = $$v
                  },
                  expression: "displayName"
                }
              }),
              _c("div", { staticClass: "mdc-text-field-outline" })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-text-field mdc-text-field--outlined mdc-text-field--not-notched",
              staticStyle: { width: "100%" }
            },
            [
              _c("imask-input", {
                staticClass: "mdc-text-field__input small-font",
                attrs: {
                  mask: _vm.mask,
                  prepare: _vm.prepare,
                  type: "text",
                  placeholder: _vm.$t("settings.merchant.url"),
                  autocorrect: "off",
                  spellcheck: "false",
                  autocomplete: "off"
                },
                model: {
                  value: _vm.urlName,
                  callback: function($$v) {
                    _vm.urlName = $$v
                  },
                  expression: "urlName"
                }
              }),
              _c("div", { staticClass: "mdc-text-field-outline" })
            ],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }