import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { messages } from '../i18n/index';

Vue.use(VueI18n);

let supportedLang = ['en', 'zh_HK'];

let englishLangRegex = new RegExp('^en.{0,3}$');

let chineseLangRegex = new RegExp('^zh.{0,3}$');

let userLang = window.navigator.language;

//default language is english
let defaultLang = 'en';

//if userlang is not english language families, then search for our supported languages
if (!englishLangRegex.test(userLang)) {
    if (chineseLangRegex.test(userLang)) {
        defaultLang = 'zh_HK';
    } else {
        defaultLang = supportedLang.includes(userLang) ? window.navigator.language : 'en';
    }
} else {
    defaultLang = 'en';
}

// console.log(defaultLang);

export const i18n = new VueI18n({
    locale: defaultLang,
    fallbackLocale: defaultLang,
    messages,
});
