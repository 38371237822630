

























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import RecurrentItem from '@/components/recurrents/RecurrentItem.vue';
// import { moment_HK as moment } from '@/store';
import * as moment from 'moment';
import { scrollTop } from '@/utils/scrollTop';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
@Component({
    components: {
        RecurrentItem,
    },
})
export default class Recurrents extends Vue {
    private type = 'all';

    // by status
    private items = [];
    private timestamp?: number;
    private moreButton = true;
    public gettingCharges = false;
    private recurrenceStatus = 'all';
    private statusOptions = [
        {
            value: 'all',
            text: 'all',
        },
        {
            value: 'pending',
            text: 'status.pending',
        },
        {
            value: 'active',
            text: 'status.active',
        },
        {
            value: 'inactive',
            text: 'status.inactive',
        },
        {
            value: 'completed',
            text: 'status.completed',
        },
        {
            value: 'failed',
            text: 'status.failed',
        },
    ];

    // cashflow
    private cashflowSummary = {
        counts: 0,
        amount: 0,
    };
    private cashflowItems = [];
    private startDate = moment().format('YYYY-MM-DD');
    private endDate = moment().format('YYYY-MM-DD');
    get maxDate() {
        return moment(this.startDate).endOf('month').format('YYYY-MM-DD');
    }

    @Watch('type')
    async onTypeChanged(val: string) {
        if (val === 'cashflow' || this.cashflowItems.length) {
            this.cashflowItems = await this.getCashflow();
            this.cashflowSummary = await this.getCashflowSummary();
        }
    }

    @Watch('startDate')
    async onStartDateChanged() {
        moment.locale(this.$i18n.locale);
        if (
            this.endDate < this.startDate ||
            moment(this.endDate).month() != moment(this.startDate).month() ||
            moment(this.endDate).year() != moment(this.startDate).year()
        ) {
            this.endDate = this.startDate;
        }

        this.cashflowItems = await this.getCashflow();
        this.cashflowSummary = await this.getCashflowSummary();
    }

    @Watch('endDate')
    async onEndDateChanged() {
        moment.locale(this.$i18n.locale);
        if (this.endDate < this.startDate) {
            this.endDate = this.startDate;
        }

        if (this.endDate > this.maxDate) {
            this.endDate = this.maxDate;
        }

        this.cashflowItems = await this.getCashflow();
        this.cashflowSummary = await this.getCashflowSummary();
    }

    @Watch('recurrenceStatus')
    onStatusChanged(val: string) {
        this.recurrenceStatus = val;
        this.fetchItems();
    }

    public mounted() {
        this.initMDCTextFields();
    }

    public async created() {
        this.fetchItems();
    }

    public triggerReceipt(chargeId: string) {
        this.$router.push({ name: 'receipt', params: { chargeId } });
    }

    public async fetchItems() {
        if (!this.gettingCharges) {
            this.moreButton = true;
            this.timestamp = moment().unix();
            this.items = await this.getCharges();
            scrollTop();
        }
    }

    public async moreItems() {
        const newItem = await this.getCharges();

        if (newItem.length) {
            this.items = this.items.concat(newItem);
        }
    }

    public async getCharges() {
        let newItems: any;
        this.gettingCharges = true;
        try {
            newItems = await this.$store.dispatch('getRecurringCharges', {
                timestamp: this.timestamp,
                limit: 10,
                status: this.recurrenceStatus,
            });

            if (newItems.length < 10) {
                this.moreButton = false;
            } else {
                this.timestamp = moment((newItems[newItems.length - 1] as any).created_at).unix();
            }
        } catch (error) {
            this.$root.$emit('error', error);
        } finally {
            this.gettingCharges = false;
        }

        if (newItems) {
            return newItems;
        } else {
            return [];
        }
    }

    public async getCashflow() {
        try {
            return await this.$store.dispatch('getRecurringCashflow', {
                startDate: this.startDate,
                endDate: this.endDate,
            });
        } catch (error) {
            this.$root.$emit('error', error);
        }
    }

    public async getCashflowSummary() {
        try {
            return await this.$store.dispatch('getRecurringCashflowSummary', {
                startDate: this.startDate,
                endDate: this.endDate,
            });
        } catch (error) {
            this.$root.$emit('error', error);
        }
    }

    // animation function
    private initMDCTextFields() {
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
