






import { Component, Vue } from 'vue-property-decorator';
import Registration from '@/components/signup/Registration.vue';

@Component({
    components: {
        Registration,
    },
})
export default class Signup extends Vue {}
