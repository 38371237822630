











































import { Component, Vue, Prop } from 'vue-property-decorator';
import { compressImageFile } from '@/utils/compressImageFile';
import AUTH_MSG from '../../../constants/AUTH';
import * as Exif from 'exif-js';

@Component({})
export default class MerchantLogo extends Vue {
    private hasLogo = false;
    private changed = false;
    private srcFile = '';
    private src = '';
    private prevSrc = '';

    public created() {
        return this.$store.dispatch('getMerchantLogo').then((res: any) => {
            this.prevSrc = res.src;
            this.src = this.prevSrc;
        });
    }

    protected async previewFile(event: any) {
        const file = event.target.files[0];

        if (!file) {
            return;
        }

        if (!file.type.includes('image')) {
            return this.$root.$emit('error', {
                message: AUTH_MSG.DOCUMENT_ERROR,
            });
        }

        const orientation = await this.checkOrientation(file);
        const quality = 0.95;

        await compressImageFile(file, quality, orientation)
            .then((res: any) => {
                this.changed = true;
                this.srcFile = res.thumbnail;
                this.src = URL.createObjectURL(this.srcFile);
            })
            .catch((err: any) => {
                return this.$root.$emit('error', {
                    message: this.$t('erros.GENERAL.retry'),
                });
            });
    }

    protected async upload() {
        return this.$store
            .dispatch('updateMerchantLogo', {
                file: this.srcFile,
            })
            .then((res: any) => {
                this.changed = false;
                this.prevSrc = this.src;
            })
            .catch((error: any) => this.$root.$emit('error', error));
    }

    protected cancel() {
        this.changed = false;
        this.src = this.prevSrc;
    }

    protected checkOrientation = async (file: any) => {
        return new Promise(function(resolve, reject) {
            Exif.getData(file, function(this: any) {
                const orientation = Exif.getTag(this, 'Orientation');
                resolve(orientation);
            });
        });
    };
}
