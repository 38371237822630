export const signup = {
    // Registration
    accountRegistration: 'Account Registration',
    companyName: 'Company Name',
    email: 'Email Address',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    countryCode: 'Country Code',
    phone: 'Phone Number',
    iAccept: 'I have understood and agreed',
    tos: ' Terms of Service',
    and: ' and',
    privacy: ' Privacy Policy of Jarvix Pay',
    fullStop: '.',
    signup: 'Sign Up',

    // Verification
    accountVerification: 'Account Verification',
    verificationInputText: 'Please Input Your Verification Code.',

    // Company
    companyInfo: 'Company Information',
    businessType: 'Business Type',
    limited: 'Limited',
    unlimited: 'Unlimited',
    businessBR: 'Business Registration Number (BR)',
    businessCI: 'Company Incorporation Number (CI)',
    statementDescriptor: 'Statement Display Name',
    companyPhone: 'Company Phone Number',
    CompanyAddress: 'Company Address',
    city: 'City',
    state: 'Country / Region',
    businessDescription: 'Business Description',
    businessWeb: 'Business Website',

    // Owner
    companyOwner: 'Company Owner',
    companyRemind: 'Reminder: You must disclose information of all company owners who hold more than 25% of the company',
    companyImportantOwner: 'Company Significant Controller',
    owner: 'Owner',
    lastName: 'Last Name',
    firstName: 'First Name',
    identityType: 'Identity Document Type',
    identityNumber: 'Identity Number',
    hkid: 'Hong Kong Identity Card',
    passport: 'Passport',
    dob: 'Date Of Birth',
    ownerPhone: 'Mobile Phone Number',
    address: 'Address',
    addOwner: '+ Add Owner',

    // ExternalAccount
    externalAccountInfo: 'Bank Account Information',
    holderName: 'Account Holder Name',
    holderType: 'Account Holder Type',
    companyAccount: 'Company Account',
    individualAccount: 'Individual Account',
    bank: 'Bank',
    branchCode: 'Branch Number',
    accountNumber: 'Account Number',
    currency: 'Currency',

    // DocumentUpload
    uploadDocument: 'Upload Document',
    remind: 'Reminder:',
    remindItem: '* are required fields.',
    remindDocs: 'File format should be either pdf, png or jpg.',
    selectDocs: 'Select Document',
    br: 'Business Registration Certificate (BR)',
    proof: ' Proof Document',
    idProof: 'File format of the identity proof document should be either png or jpg.',
    idFront: 'Select Identity Proof Document (Front)',
    idBack: 'Select Identity Proof Document (Back)',
    addressProof: 'Select Address Proof Document',
    bankProof: ' Bank Account Proof Document',
    bankProofExample: 'Example: Bank Card, Monthly Statement, Cheque Book',
    otherInfo: 'Other Information (May Upload More Than 1 Item)',
    errorBR: 'The file format of the uploaded "Business Registration Certifcate (BR)" document is not png, jpg or pdf',
    errorCI: 'The file format of the uploaded "Certificate of Incorporation (CI)" document is not png, jpg or pdf',
    errorBank: 'The file format of the uploaded "Bank Account Proof document" is not png, jpg or pdf',
    errorIdentityFront:
        'The file format of the uploaded "Identity Proof Document (Front)" of the company owner {ownerNo} is not png or jpg',
    errorIdentityBack: 'The file format of the uploaded "Identity Proof Document (Back)" of the company owner {ownerNo} is not png or jpg',
    errorAddress: 'The file format of the uploaded "Address Proof" document of the company owner {ownerNo} is not png, jpg or pdf',
    errorOther: 'The file format of the uploaded document of other information {otherNo} is not png, jpg or pdf',

    // Review
    preview: 'Preview',
    confirm: 'Please confirm all filled information is accurate. You cannot edit any information after submitting the application.',
    company: 'Company',
    name: 'Name',
    type: 'Type',
    clientName: 'Statement Display Name',
    document: 'Proof document',
    changeInfo: 'Edit Info',

    // Signature
    sign: 'Sign',
    remindTerms: 'You must fill in all information and sign all terms.',
    remindEmail: 'You will receive soft copy of the signed documents through your contact email after submitting the signatures.',
    contactEmail: 'Contact Email',
    peopleName: 'Full Name',
    peopleTitle: 'Title',
    gender: 'Gender',
    male: 'Mr.',
    female: 'Ms.',
    applicationForm: 'Jarvix Pay Application Form',
    partA: 'Part A: Company Information',
    companySignAddress: 'Company Registered Address',
    brNumber: 'Business Registration Number',
    companyEmail: 'Company Email Address',
    partB: 'Part B: Applicant Information',
    idNumber: 'HKID Number',
    personalAddress: 'Personal Address',
    partC: 'Part C: Bank Account Information',
    bankName: 'Name of Bank',
    bankHolderName: 'Account Holder Name',
    partD: 'Part D: Jarvix Pay Transaction Fee',
    visaMaster: 'Visa/MasterCard',
    cardIssuer: '(Issued in Hong Kong)',
    rateFee: 'Transaction Fee',
    tradeAmount: 'Transaction Amount',
    eachTrade: 'HK$ per transaction',
    remark: 'Remarks',
    cashRebate: 'Cash Rebate Of Transaction Amount',
    ae: 'American Express',
    unionpay: 'UnionPay',
    foreign: 'Visa/MasterCard/American Express/UnionPay',
    noneHKIssuer: '(Issued outside Hong Kong)',
    arrivalDay: 'Arrival Date',
    requiredDocs: 'Required Documents:',
    brCopy: 'Copy of Business Registration Certificate',
    submitted: 'Submitted',
    ciCopy: 'Copy of Certificate of Incorporation',
    limitedProvided: 'Limited Companies Are Required To Provide',
    bankDocs: 'Bank Account Documents',
    remindBank: 'Should be able to display account holder name and bank account number',
    ownerIdCopy: 'HKID Copy Of The Company’s Significant Controller',
    ownerAddressCopy: 'Address Proof Copy Of The Company’s Signification Controller',
    remindLegal:
        'We confirm the above information is accurate, legal and valid. If the above information is not accurate or illegal, we will be responsible for all consequences and legal liabilities.',
    contract: 'Contract',
    submitContract: 'Submit Application',
    thanks: 'Thank you for your application',
    pending: 'Your account is being processed. ',
    reSign: 'Re-sign',
    remindReSign:
        'The previous contracts will be immediately terminated after re-signing the contracts, and the terms will be according to the new contracts.',

    //Signing
    signHereLabel: 'Click Here To Sign',
    signHere: 'Sign Here',

    // Rejected
    unfortunately: 'Unfortunately',
    unsuccessful: 'Your application was not successful',
    contact_us: 'Please contact us if you have any questions',
};
