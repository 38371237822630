
















































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MDCRipple } from '@material/ripple';

@Component({})
export default class Review extends Vue {
    private panel: Array<boolean> = [];
    private dbData: object = {
        company: {
            businessName: '',
            businessType: '',
            businessBR: '',
            businessCI: '',
            statementDescriptor: '',
            address: '',
            phone: '',
            city: '',
            state: '',
            businessDescription: '',
            businessWeb: '',
        },
        owners: [
            {
                id: 1,
                firstName: '',
                lastName: '',
                identityType: '',
                identityNumber: '',
                dob: '',
                phone: '',
                address: '',
                city: '',
                state: '',
            },
        ],
        externalAccount: {
            holderName: '',
            holderType: '',
            bank: null,
            branchCode: '',
            accountNumber: '',
            country: '',
            currency: '',
        },
        documentUpload: {
            brFile: {},
            ciFile: {},
            owners: {
                identity: new Array(),
                address: new Array(),
            },
            bank: {},
            others: [],
        },
    };
    private brFile: object = {};
    private ciRequired: boolean = false;
    private ciFile: object = {};
    private ownerNo: number = 3;
    private ownerName: Array<string> = [];
    private owners = {
        identityFront: new Array(),
        identityBack: new Array(),
        address: new Array(),
    };
    private bank: object = {};
    private others = new Array();

    private confirmDialogOpen = false;
    private dialogTitle: string = '';
    private dialogMessage: string = '';

    public async mounted() {
        this.initMDC();
        this.dbData = await this.$store.dispatch('retrieveSignUpData');
        this.brFile = this.$store.state.signUp.documentUpload.brFile;
        this.ciRequired = this.$store.state.signUp.company.businessCI != '' ? true : false;
        this.ciFile = this.$store.state.signUp.documentUpload.ciFile;
        this.ownerNo = this.$store.state.signUp.owners.length;
        this.ownerName = this.$store.state.signUp.owners.map((owner: any) => {
            return owner.lastName + ' ' + owner.firstName;
        });
        this.owners = this.$store.state.signUp.documentUpload.owners;
        this.bank = this.$store.state.signUp.documentUpload.bank;
        this.others = this.$store.state.signUp.documentUpload.others;
    }

    // animation function
    private initMDC() {
        const selector = '.mdc-button, .mdc-icon-button, .mdc-card__primary-action';
        const ripples = [].map.call(document.querySelectorAll(selector), function(el: any) {
            return new MDCRipple(el);
        });
    }

    protected update() {
        this.confirmDialogOpen = true;
        this.dialogTitle = `${this.$t('general.confirmInfo')}`;
        this.dialogMessage = `${this.$t('signup.confirm')}`;
    }
    protected goPrevious() {
        this.$router.push({ name: 'company' });
    }

    protected async onAccept() {
        try {
            await this.$store.dispatch('uploadSignUpDocument', this.$store.state.signUp.documentUpload);
        } catch (error) {
            // console.log(error);
            return this.$root.$emit('error', new Error(`${this.$t('errors.GENERAL.retry')}`));
        }
        this.$router.push({ name: 'signature' });
    }
}
