export const customer = {
    title: 'お客様',
    name: 'お名前',
    countryCode: '国番号',
    phone: '電話番号',
    email: 'メールアドレス',
    address: '住所',
    remarks: '備考',
    refNo: '参照番号',
    optional: '(任意項目)',
    required: '(必須項目)',
    new: 'お客様を追加',
    newClient: '新しいお客様',
    currentClient: '既存顧客',
    gender: '性別',
    male: '男性',
    female: '女性',
    age: '年齢',
    orderDate: '予約日付',
    orderTime: '予約時間',
    specialRequest: 'お問い合わせ内容',
};
