









































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    filters: {
        currencyDisplay: (currency: string) => {
            if (currency.toLowerCase() == 'usd') {
                return currency.toUpperCase() + '$';
            } else if (currency.toLowerCase() == 'hkd') {
                return currency.toUpperCase() + '$';
            } else if (currency.toLowerCase() == 'cny') {
                return currency.toUpperCase() + '¥';
            } else if (currency.toLowerCase() == 'mop') {
                return currency.toUpperCase() + '$';
            } else if (currency.toLowerCase() == 'jpy') {
                return currency.toUpperCase() + '¥';
            } else if (currency.toLowerCase() == 'gbp') {
                return currency.toUpperCase() + '£';
            }
        },
    },
})
export default class RemoteLinkItem extends Vue {
    @Prop()
    item: any;
}
