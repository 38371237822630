var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "remote-link-expired" } }, [
    _c(
      "div",
      {
        staticClass:
          "mdc-layout-grid app-page-activity app-page-activity--active activity-payment-summary"
      },
      [
        _c("div", { staticClass: "mdc-layout-grid__inner" }, [
          _vm._m(0),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
            },
            [
              _c("button", { staticClass: "mdc-button" }),
              _c(
                "button",
                { staticClass: "mdc-button", on: { click: _vm.close } },
                [_vm._v("關閉頁面")]
              )
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet payment-summary",
        staticStyle: { flex: "1 1 auto" }
      },
      [
        _c("div", { staticClass: "payment-summary__wrapper-inner" }, [
          _c("div", { staticClass: "payment-summary__inner" }, [
            _c("div", [_vm._v("對不起，相關連結已經逾時或不存在。")]),
            _c("div", [_vm._v("如有問題，請與商戶聯絡。")])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }