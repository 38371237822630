var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-page app-page-payment app-page--active" },
    [
      _c("div", { staticClass: "app-page-container" }, [
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid app-page-activity app-page-activity--active bottom-nav--hidden"
          },
          [
            _c("div", { staticClass: "mdc-layout-grid__inner" }, [
              _c(
                "div",
                {
                  staticClass:
                    "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                },
                [
                  _c("div", { staticClass: "mdc-typography--headline5" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 24 24",
                          fill: "black",
                          width: "80px",
                          height: "80px"
                        }
                      },
                      [
                        _c("path", {
                          attrs: { d: "M0 0h24v24H0V0z", fill: "none" }
                        }),
                        _c("circle", {
                          attrs: { cx: "15.5", cy: "9.5", r: "1.5" }
                        }),
                        _c("circle", {
                          attrs: { cx: "8.5", cy: "9.5", r: "1.5" }
                        }),
                        _c("path", {
                          attrs: {
                            d:
                              "M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-6c-2.33 0-4.32 1.45-5.12 3.5h1.67c.69-1.19 1.97-2 3.45-2s2.75.81 3.45 2h1.67c-.8-2.05-2.79-3.5-5.12-3.5z"
                          }
                        })
                      ]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                },
                [
                  _c("div", { staticClass: "mdc-typography--headline5" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("signup.unfortunately"))
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("signup.unsuccessful")) +
                        "\n                    "
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                },
                [
                  _c("div", { staticClass: "mdc-typography--headline6" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("signup.contact_us"))
                    ),
                    _c("br"),
                    _vm._v("\n                        (3618 7750/6922 5068)"),
                    _c("br")
                  ])
                ]
              )
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }