



























































import { Component, Vue, Watch } from 'vue-property-decorator';
import VueNumeric from 'vue-numeric';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import { IMaskComponent } from 'vue-imask';
import bankOptions from '@/constants/BANKS';
import { placeHolderForCurrency as placeHolderForCurrency_, symbolDisplay } from '@/utils/helpers/currencyHelper';

const currencyList = bankOptions.currencyOptions;
const env = process.env.VUE_APP_ENV;
@Component({
    components: {
        VueNumeric,
        'imask-input': IMaskComponent,
    },
})
export default class ProductEditInfo extends Vue {
    public amount = 0;
    public currency = 'hkd';
    private amountShown = '';
    public description = '';
    public message = '';
    private descriptionError = false;
    private amountError = false;
    private messageError = false;

    private input_user_el: any = { value: '' };
    private mdcTextFields!: any;
    get chargeLimit() {
        return this.$store.state.user.charge_limit_for_each_currency && this.currency
            ? this.$store.state.user.charge_limit_for_each_currency[this.currency]
            : this.$store.state.user.charge_limit
                ? this.$store.state.user.charge_limit
                : 100000;
    }

    get chargeLimitDisplay() {
        return this.chargeLimit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    get currencyList() {
        return currencyList.filter(({ text, value: currency }) => {
            return this.$store.state.user.allowedCurrencies['remote'].includes(currency);
        });
    }

    private convertPlusToDot = (amount: any) => amount.replace('+', '.');

    @Watch('description')
    onDescriptionChanged() {
        this.descriptionError = this.description ? false : true;
        this.$emit('update', { description: this.description });
    }

    @Watch('message')
    onMessageChanged() {
        this.messageError = this.message ? false : true;
        this.$emit('update', { message: this.message });
    }

    @Watch('amount')
    onAmountChanged() {
        this.amountError = this.amount < 10 || this.amount > this.chargeLimit ? true : false;
        this.$emit('update', { amount: this.amount });
    }

    @Watch('currency')
    oncurrencyChanged() {
        // this.amountError = this.amount < 10 || this.amount > this.chargeLimit ? true : false;
        this.amountShown =
            this.input_user_el.value.length > 0 ? ((symbolDisplay(this.currency) + '' + this.input_user_el.value) as string) : '';
        this.$emit('update', { currency: this.currency });
    }

    get isTestingMode() {
        return env !== 'production' ? true : false;
    }

    private async onCompleteHandler() {
        this.amountShown =
            // this.input_user_el.value.length > 0 ? (this.currencySymbolDisplay_(this.currency, this.input_user_el.value) as string) : '';
            this.input_user_el.value.length > 0 ? ((symbolDisplay(this.currency) + '' + this.input_user_el.value) as string) : '';
    }

    public currencySymbolDisplay_(currency: string, amount: number) {
        if (currency.toLowerCase() == 'usd') {
            return '$ ' + amount;
        } else if (currency.toLowerCase() == 'hkd') {
            return '$ ' + amount;
        } else if (currency.toLowerCase() == 'cny') {
            return '¥ ' + amount;
        } else if (currency.toLowerCase() == 'mop') {
            return '$ ' + amount;
        } else if (currency.toLowerCase() == 'jpy') {
            return '¥ ' + amount;
        } else if (currency.toLowerCase() == 'gbp') {
            return '£ ' + amount;
        }
    }

    public placeHolderForCurrency(currency: string) {
        return placeHolderForCurrency_(currency);
    }

    public mounted() {
        this.input_user_el = (this.$refs.paymentInputUser as any).$el;
        this.initMDCTextFields();
    }

    public autofill(isAutoFill?: boolean) {
        this.amount = 100;
        this.description = 'testing description';
        this.message = 'testing message';
    }

    private initMDCTextFields() {
        this.mdcTextFields = Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
