var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mdc-layout-grid__inner",
      attrs: { id: "product-edit-info" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--large mdc-text-field--outlined mdc-text-field--not-notched"
        },
        [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currency,
                  expression: "currency"
                }
              ],
              staticClass: "mdc-text-field__input",
              attrs: { options: _vm.currencyList },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.currency = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { disabled: "" }, domProps: { value: null } },
                [_vm._v(_vm._s(_vm.$t("currency.name")))]
              ),
              _vm._l(_vm.currencyList, function(c) {
                return _c(
                  "option",
                  { key: c.text, domProps: { value: c.value } },
                  [_vm._v(_vm._s(_vm.$t(c.text)))]
                )
              })
            ],
            2
          ),
          _c("label", { staticClass: "mdc-floating-label" }, [
            _vm._v(_vm._s(_vm.$t("currency.name")))
          ]),
          _c("div", { staticClass: "mdc-notched-outline" }, [
            _c("svg", [
              _c("path", { staticClass: "mdc-notched-outline__path" })
            ])
          ]),
          _c("div", { staticClass: "mdc-notched-outline__idle" })
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--xlarge mdc-text-field--outlined mdc-text-field--not-notched"
            },
            [
              _c("imask-input", {
                ref: "paymentInputUser",
                staticClass: "mdc-text-field__input",
                staticStyle: { "text-align": "right", color: "transparent" },
                attrs: {
                  mask: Number,
                  prepare: _vm.convertPlusToDot,
                  radix: ".",
                  thousandsSeparator: ",",
                  scale: _vm.currency !== "jpy" ? 2 : 0,
                  unmask: "typed",
                  min: 0,
                  max: _vm.chargeLimit,
                  placeholder: _vm.placeHolderForCurrency(_vm.currency),
                  type: "tel",
                  autocorrect: "off",
                  spellcheck: "false",
                  autocomplete: "off"
                },
                on: { complete: _vm.onCompleteHandler },
                model: {
                  value: _vm.amount,
                  callback: function($$v) {
                    _vm.amount = $$v
                  },
                  expression: "amount"
                }
              }),
              _c(
                "div",
                {
                  staticClass: "mdc-text-field__input",
                  staticStyle: {
                    "text-align": "right",
                    position: "absolute",
                    top: "1px",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    "pointer-events": "none"
                  },
                  attrs: { readonly: "" }
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "line-height": "1",
                        margin: "auto 0 auto auto"
                      }
                    },
                    [_vm._v(_vm._s(_vm.amountShown))]
                  )
                ]
              ),
              _c("div", { staticClass: "mdc-text-field-outline" })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.description,
                expression: "description"
              }
            ],
            staticClass: "mdc-text-field__input",
            attrs: {
              type: "text ",
              id: "payment-product-description ",
              autocomplete: "off",
              autocorrect: "off",
              spellcheck: "false"
            },
            domProps: { value: _vm.description },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.description = $event.target.value
              }
            }
          }),
          _c(
            "label",
            {
              staticClass: "mdc-floating-label",
              attrs: { for: "payment-description" }
            },
            [_vm._v(_vm._s(_vm.$t("general.paymentDetails.description")))]
          ),
          _c("div", { staticClass: "mdc-notched-outline" }, [
            _c("svg", [
              _c("path", { staticClass: "mdc-notched-outline__path" })
            ])
          ]),
          _c("div", { staticClass: "mdc-notched-outline__idle" })
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched mdc-text-field--textarea"
        },
        [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.message,
                expression: "message"
              }
            ],
            staticClass: "mdc-text-field__input",
            attrs: { rows: "5", cols: "100" },
            domProps: { value: _vm.message },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.message = $event.target.value
              }
            }
          }),
          _c("label", { staticClass: "mdc-floating-label" }, [
            _vm._v(_vm._s(_vm.$t("general.paymentDetails.remoteMessage")))
          ]),
          _c("div", { staticClass: "mdc-notched-outline" }, [
            _c("svg", [
              _c("path", { staticClass: "mdc-notched-outline__path" })
            ])
          ]),
          _c("div", { staticClass: "mdc-notched-outline__idle" })
        ]
      ),
      _vm.amountError || _vm.descriptionError || _vm.messageError
        ? _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
            },
            [
              _vm.amountError
                ? _c("p", { staticClass: "error-prompt" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("errors.PAYMENT_DETAILS.invalid_transaction", [
                          _vm.chargeLimitDisplay
                        ])
                      )
                    )
                  ])
                : _vm._e(),
              _vm.descriptionError
                ? _c("p", { staticClass: "error-prompt" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("errors.PAYMENT_DETAILS.missing_description")
                      )
                    )
                  ])
                : _vm._e(),
              _vm.messageError
                ? _c("p", { staticClass: "error-prompt" }, [
                    _vm._v(
                      _vm._s(_vm.$t("errors.PAYMENT_DETAILS.missing_message"))
                    )
                  ])
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm.isTestingMode
        ? _c(
            "button",
            {
              staticClass: "mdc-button",
              on: {
                click: function($event) {
                  _vm.autofill(true)
                }
              }
            },
            [_vm._v("Auto fill")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }