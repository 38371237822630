var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "campaign-order-information" } }, [
    _c("div", { staticClass: "activity-payment-campaign-item" }, [
      _c("div", { staticClass: "mdc-layout-grid__inner" }, [
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet campaign-purchase-information",
            staticStyle: { flex: "1 1 auto" }
          },
          [
            _c(
              "div",
              { staticClass: "campaign-purchase-information__wrapper-inner" },
              [
                _c(
                  "div",
                  { staticClass: "campaign-purchase-information__inner" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "mdc-typography--headline5" }, [
                        _vm._v(_vm._s(_vm.$t("general.summary")))
                      ]),
                      _c(
                        "a",
                        {
                          staticClass: "close-icon ml-auto",
                          on: {
                            click: function($event) {
                              _vm.$router.go(-1)
                            }
                          }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "close-icon__icon",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "mdc-typography--headline6" }, [
                      _vm._v(_vm._s(_vm.$t("general.history.payment")))
                    ]),
                    _c(
                      "mdc-list",
                      { attrs: { interactive: "" } },
                      _vm._l(_vm.charges, function(item) {
                        return _c(
                          "mdc-list-item",
                          {
                            key: item.charge_id,
                            on: {
                              click: function($event) {
                                _vm.$router.push({
                                  name: "receipt",
                                  params: { chargeId: item.charge_id }
                                })
                              }
                            }
                          },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm._f("currency")(item.amount)))
                            ]),
                            _c("ChargeStatusTag", {
                              attrs: { status: item, small: "" }
                            }),
                            _c(
                              "div",
                              { staticClass: "mdc-typography--caption" },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm
                                        .moment(item.created_at)
                                        .format("LL")
                                        .substring(5)
                                    ) +
                                    "\n                                "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      })
                    ),
                    _c("div", { staticClass: "big-card" }, [
                      _c(
                        "div",
                        { staticClass: "big-card__section" },
                        [
                          _c(
                            "mdc-button",
                            { attrs: { dense: "", disabled: !false } },
                            [_vm._v(_vm._s(_vm.$t("actions.noMore")))]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm.order.campaign_branch_option_enabled
                      ? _c(
                          "div",
                          { staticClass: "mdc-typography--subtitle1 enlarged" },
                          [_vm._v(_vm._s(_vm.$t("settings.branch.label")))]
                        )
                      : _vm._e(),
                    _vm.order.campaign_branch_option_enabled
                      ? _c(
                          "div",
                          { staticClass: "mdc-typography--subtitle1" },
                          [
                            _c(
                              "div",
                              { staticClass: "mdc-typography--body1" },
                              [
                                _vm._v(
                                  _vm._s(_vm.order.store_name) +
                                    " - " +
                                    _vm._s(_vm.order.store_address)
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mdc-typography--subtitle1 enlarged" },
                      [_vm._v(_vm._s(_vm.$t("customer.title")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "mdc-typography--subtitle1 customer" },
                      [
                        _c("div", { staticClass: "mdc-typography--body1" }, [
                          _vm._v(
                            _vm._s(_vm.$t("customer.name")) +
                              ": " +
                              _vm._s(_vm.order.customer_name)
                          )
                        ]),
                        _vm.order.customer_gender
                          ? _c(
                              "div",
                              { staticClass: "mdc-typography--body1" },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.$t("customer.gender")) +
                                    ":\n                                " +
                                    _vm._s(
                                      _vm.order.customer_gender === "male"
                                        ? "" + _vm.$t(_vm.$t("customer.male"))
                                        : "" + _vm.$t(_vm.$t("customer.female"))
                                    ) +
                                    "\n                            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.order.customer_age
                          ? _c(
                              "div",
                              { staticClass: "mdc-typography--body1" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("customer.age")) +
                                    ": " +
                                    _vm._s(_vm.order.customer_age)
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "mdc-typography--body1" }, [
                          _vm._v(
                            _vm._s(_vm.$t("customer.countryCode")) +
                              ": " +
                              _vm._s(_vm.order.customer_country_code)
                          )
                        ]),
                        _c("div", { staticClass: "mdc-typography--body1" }, [
                          _vm._v(
                            _vm._s(_vm.$t("customer.phone")) +
                              ": " +
                              _vm._s(_vm.order.customer_phone)
                          )
                        ]),
                        _vm.order.customer_email
                          ? _c(
                              "div",
                              { staticClass: "mdc-typography--body1" },
                              [
                                _c("div", [
                                  _vm._v(_vm._s(_vm.$t("customer.email")) + ":")
                                ]),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.order.customer_email))
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.order.customer_address
                          ? _c(
                              "div",
                              { staticClass: "mdc-typography--body1" },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.$t("customer.address")) + ":"
                                  )
                                ]),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.order.customer_address))
                                ])
                              ]
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "mdc-typography--subtitle1 enlarged" },
                      [_vm._v(_vm._s(_vm.$t("campaign.product.title")))]
                    ),
                    _c("div", { staticClass: "product" }, [
                      _c(
                        "div",
                        {
                          staticClass: "mdc-typography--subtitle1 product--name"
                        },
                        [_vm._v(_vm._s(_vm.$t("campaign.product.label")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-typography--subtitle1 product--amount"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("general.paymentDetails.amount"))
                          )
                        ]
                      )
                    ]),
                    _vm._l(_vm.order.productList, function(product) {
                      return _c(
                        "div",
                        { key: product.id, staticClass: "product" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mdc-typography--body1 product--name"
                            },
                            [
                              _vm._v(
                                _vm._s(product.name) +
                                  " x " +
                                  _vm._s(product.quantity)
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mdc-typography--body1 product--amount"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(
                                    product.quantity * product.price
                                  )
                                )
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    _c("hr"),
                    _c("div", { staticClass: "product" }, [
                      _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                        _vm._v(
                          _vm._s(_vm.$t("general.paymentDetails.totalAmount")) +
                            "："
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "mdc-typography--subtitle1 ml-auto" },
                        [_vm._v(_vm._s(_vm._f("currency")(_vm.order.amount)))]
                      )
                    ]),
                    _vm.order.customer_reserved_date ||
                    _vm.order.customer_reserved_time ||
                    _vm.order.customer_special_request
                      ? _c(
                          "div",
                          { staticClass: "mdc-typography--subtitle1 enlarged" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("campaign.additionalOptions.title"))
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mdc-typography--subtitle1 others" },
                      [
                        _vm.order.customer_reserved_date
                          ? _c(
                              "div",
                              { staticClass: "mdc-typography--body1" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("customer.orderDate")) +
                                    ": " +
                                    _vm._s(
                                      _vm
                                        .moment(
                                          _vm.order.customer_reserved_date
                                        )
                                        .format("LL")
                                    )
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.order.customer_reserved_time
                          ? _c(
                              "div",
                              { staticClass: "mdc-typography--body1" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("customer.orderTime")) +
                                    ": " +
                                    _vm._s(_vm.order.customer_reserved_time)
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.order.customer_special_request
                          ? _c(
                              "div",
                              { staticClass: "mdc-typography--body1" },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "padding-bottom": "8px" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("customer.specialRequest")
                                      ) + ":"
                                    )
                                  ]
                                ),
                                _vm._l(
                                  _vm.order.customer_special_request.split(
                                    "\n"
                                  ),
                                  function(line, index) {
                                    return [
                                      _c("div", { key: index }, [
                                        _vm._v(_vm._s(line))
                                      ])
                                    ]
                                  }
                                )
                              ],
                              2
                            )
                          : _vm._e()
                      ]
                    ),
                    _vm.order.questionList && _vm.order.questionList.length
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "mdc-typography--subtitle1 enlarged"
                            },
                            [_vm._v(_vm._s(_vm.$t("campaign.question.title")))]
                          ),
                          _vm._l(_vm.order.questionList, function(item, index) {
                            return _c(
                              "div",
                              { key: item.id, staticClass: "others" },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(index + 1) +
                                      ". " +
                                      _vm._s(item.question)
                                  )
                                ]),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.$t("campaign.question.answer")) +
                                      "：" +
                                      _vm._s(item.answer)
                                  )
                                ])
                              ]
                            )
                          })
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }