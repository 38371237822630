var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "campaign-product-item" }, [
    _c("div", { staticClass: "list-item", class: { editing: _vm.editing } }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { ref: "title", staticClass: "mdc-typography--headline6" }, [
          _vm._v(
            _vm._s(_vm.$t("campaign.product.label")) + " - " + _vm._s(_vm.index)
          )
        ]),
        _c(
          "div",
          { staticClass: "row-right-section" },
          [
            !_vm.editing
              ? _c(
                  "mdc-button",
                  {
                    staticClass: "edit-button",
                    attrs: { dense: "", type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.edit($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("actions.edit")))]
                )
              : _vm._e(),
            !_vm.editing
              ? _c(
                  "mdc-button",
                  {
                    staticClass:
                      "edit-button edit-button--edit edit-button--red-color",
                    attrs: { dense: "", type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.deleteCustomer($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("actions.delete")))]
                )
              : _vm._e(),
            _vm.editing
              ? _c(
                  "mdc-button",
                  {
                    staticClass: "edit-button edit-button--editing",
                    attrs: { dense: "", disabled: "", type: "button" }
                  },
                  [_vm._v(_vm._s(_vm.$t("actions.editing")))]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm.hasData
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.editing,
                  expression: "!editing"
                }
              ],
              staticClass: "row"
            },
            [
              _vm._v(
                _vm._s(_vm.$t("campaign.product.name")) +
                  ": " +
                  _vm._s(_vm.product.name)
              )
            ]
          )
        : _vm._e(),
      _vm.hasData && _vm.product.originalPrice
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.editing,
                  expression: "!editing"
                }
              ],
              staticClass: "row"
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("campaign.product.originalPrice")) +
                  ": " +
                  _vm._s(_vm._f("currency")(_vm.product.originalPrice)) +
                  "\n        "
              )
            ]
          )
        : _vm._e(),
      _vm.hasData
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.editing,
                  expression: "!editing"
                }
              ],
              staticClass: "row"
            },
            [
              _vm._v(
                _vm._s(_vm.$t("campaign.product.price")) +
                  ": " +
                  _vm._s(_vm._f("currency")(_vm.product.price))
              )
            ]
          )
        : _vm._e(),
      _vm.hasData && _vm.product.discountMessage
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.editing,
                  expression: "!editing"
                }
              ],
              staticClass: "row"
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("campaign.product.customDiscountMessage")) +
                  ": " +
                  _vm._s(_vm.product.discountMessage) +
                  "\n        "
              )
            ]
          )
        : _vm._e(),
      _vm.hasData
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.editing,
                  expression: "!editing"
                }
              ],
              staticClass: "row"
            },
            [
              _vm._v(
                _vm._s(_vm.$t("campaign.product.quantity")) +
                  ": " +
                  _vm._s(_vm.product.quantity)
              )
            ]
          )
        : _vm._e(),
      _vm.hasData
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.editing,
                  expression: "!editing"
                }
              ],
              staticClass: "row"
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("campaign.product.quantityPerOrder")) +
                  ": " +
                  _vm._s(_vm.product.quantityPerOrder) +
                  "\n        "
              )
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.editing,
              expression: "editing"
            }
          ],
          staticClass: "fields"
        },
        [
          _c(
            "mdc-checkbox",
            {
              staticClass: "extra-option",
              model: {
                value: _vm.discount,
                callback: function($$v) {
                  _vm.discount = $$v
                },
                expression: "discount"
              }
            },
            [_vm._v(_vm._s(_vm.$t("campaign.product.discount")))]
          ),
          _c("mdc-textfield", {
            attrs: {
              label: _vm.$t("campaign.product.name"),
              fulliwdth: "",
              outline: "",
              autocorrect: "off",
              spellcheck: "false",
              autocomplete: "off"
            },
            model: {
              value: _vm.productEditingInfo.name,
              callback: function($$v) {
                _vm.$set(_vm.productEditingInfo, "name", $$v)
              },
              expression: "productEditingInfo.name"
            }
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.discount,
                  expression: "discount"
                }
              ],
              staticClass:
                "mdc-text-field mdc-text-field--outlined mdc-text-field--notched",
              staticStyle: { width: "65%" }
            },
            [
              _c("imask-input", {
                staticClass: "mdc-text-field__input",
                attrs: {
                  mask: Number,
                  scale: 2,
                  unmask: "typed",
                  type: "tel",
                  autocorrect: "off",
                  spellcheck: "false",
                  autocomplete: "off",
                  radix: ".",
                  thousandsSeparator: ",",
                  max: 99999
                },
                model: {
                  value: _vm.productEditingInfo.originalPrice,
                  callback: function($$v) {
                    _vm.$set(_vm.productEditingInfo, "originalPrice", $$v)
                  },
                  expression: "productEditingInfo.originalPrice"
                }
              }),
              _c(
                "label",
                {
                  staticClass: "mdc-floating-label",
                  class: {
                    "mdc-floating-label--float-above":
                      _vm.productEditingInfo.originalPrice
                  },
                  attrs: { for: "originalPrice" }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("campaign.product.originalPrice")) +
                      "(" +
                      _vm._s(_vm.currency) +
                      ")"
                  )
                ]
              ),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-text-field mdc-text-field--outlined mdc-text-field--notched",
              staticStyle: { width: "65%" }
            },
            [
              _c("imask-input", {
                staticClass: "mdc-text-field__input",
                attrs: {
                  mask: Number,
                  scale: 2,
                  unmask: "typed",
                  type: "tel",
                  autocorrect: "off",
                  spellcheck: "false",
                  autocomplete: "off",
                  radix: ".",
                  thousandsSeparator: ",",
                  max: 99999
                },
                model: {
                  value: _vm.productEditingInfo.price,
                  callback: function($$v) {
                    _vm.$set(_vm.productEditingInfo, "price", $$v)
                  },
                  expression: "productEditingInfo.price"
                }
              }),
              _c(
                "label",
                { staticClass: "mdc-floating-label", attrs: { for: "price" } },
                [
                  _vm._v(
                    _vm._s(_vm.$t("campaign.product.price")) +
                      "(" +
                      _vm._s(_vm.currency) +
                      ")"
                  )
                ]
              ),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-text-field mdc-text-field--outlined mdc-text-field--notched",
              staticStyle: { width: "65%" }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.productEditingInfo.discountMessage,
                    expression: "productEditingInfo.discountMessage"
                  }
                ],
                staticClass: "mdc-text-field__input",
                attrs: { type: "text" },
                domProps: { value: _vm.productEditingInfo.discountMessage },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.productEditingInfo,
                      "discountMessage",
                      $event.target.value
                    )
                  }
                }
              }),
              _c(
                "label",
                {
                  staticClass: "mdc-floating-label",
                  class: {
                    "mdc-floating-label--float-above":
                      _vm.productEditingInfo.discountMessage
                  },
                  attrs: { for: "discountMessage" }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("campaign.product.customDiscountMessage"))
                  )
                ]
              ),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-text-field mdc-text-field--outlined mdc-text-field--notched",
              staticStyle: { width: "65%" }
            },
            [
              _c("imask-input", {
                ref: "quantity",
                staticClass: "mdc-text-field__input",
                attrs: {
                  id: "quantity",
                  mask: Number,
                  scale: 0,
                  unmask: "typed",
                  type: "tel",
                  autocorrect: "off",
                  spellcheck: "false",
                  autocomplete: "off",
                  thousandsSeparator: ",",
                  max: 999
                },
                model: {
                  value: _vm.productEditingInfo.quantity,
                  callback: function($$v) {
                    _vm.$set(_vm.productEditingInfo, "quantity", $$v)
                  },
                  expression: "productEditingInfo.quantity"
                }
              }),
              _c(
                "label",
                {
                  staticClass: "mdc-floating-label",
                  class: {
                    "mdc-floating-label--float-above":
                      _vm.productEditingInfo.quantity
                  },
                  attrs: { for: "quantity" }
                },
                [_vm._v(_vm._s(_vm.$t("campaign.product.quantity")))]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-notched-outline",
                  class: {
                    "mdc-notched-outline--notched":
                      _vm.productEditingInfo.quantity
                  }
                },
                [
                  _c("svg", [
                    _c("path", { staticClass: "mdc-notched-outline__path" })
                  ])
                ]
              ),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-text-field mdc-text-field--outlined mdc-text-field--notched",
              staticStyle: { width: "65%" }
            },
            [
              _c("imask-input", {
                ref: "quantityPerOrder",
                staticClass: "mdc-text-field__input",
                attrs: {
                  id: "quantityPerOrder",
                  mask: Number,
                  scale: 0,
                  unmask: "typed",
                  type: "tel",
                  autocorrect: "off",
                  spellcheck: "false",
                  autocomplete: "off",
                  thousandsSeparator: ",",
                  max: 999
                },
                model: {
                  value: _vm.productEditingInfo.quantityPerOrder,
                  callback: function($$v) {
                    _vm.$set(_vm.productEditingInfo, "quantityPerOrder", $$v)
                  },
                  expression: "productEditingInfo.quantityPerOrder"
                }
              }),
              _c(
                "label",
                {
                  staticClass: "mdc-floating-label",
                  class: {
                    "mdc-floating-label--float-above":
                      _vm.productEditingInfo.quantityPerOrder
                  },
                  attrs: { for: "quantityPerOrder" }
                },
                [_vm._v(_vm._s(_vm.$t("campaign.product.quantityPerOrder")))]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-notched-outline",
                  class: {
                    "mdc-notched-outline--notched":
                      _vm.productEditingInfo.quantityPerOrder
                  }
                },
                [
                  _c("svg", [
                    _c("path", { staticClass: "mdc-notched-outline__path" })
                  ])
                ]
              ),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.editing,
              expression: "editing"
            }
          ],
          staticClass: "footer1"
        },
        [
          _c(
            "mdc-button",
            {
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("actions.save")))]
          ),
          _vm.hasData
            ? _c(
                "mdc-button",
                {
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.cancel($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
              )
            : _c(
                "mdc-button",
                {
                  staticClass: "red-button",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.deleteCustomer($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.delete")))]
              )
        ],
        1
      )
    ]),
    _vm.isTestingMode
      ? _c(
          "button",
          {
            staticClass: "mdc-button",
            on: {
              click: [
                function($event) {
                  _vm.autofill(true)
                },
                function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              ]
            }
          },
          [_vm._v("Auto fill")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }