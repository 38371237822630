var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll",
          value: _vm.onScroll,
          expression: "onScroll"
        },
        {
          name: "show",
          rawName: "v-show",
          value: _vm.scrollBtn,
          expression: "scrollBtn"
        }
      ],
      attrs: {
        fab: "",
        dark: "",
        fixed: "",
        bottom: "",
        right: "",
        color: "#3c339b"
      },
      on: { click: _vm.scrollTo }
    },
    [
      _vm.goDown
        ? _c("v-icon", [_vm._v("keyboard_arrow_down")])
        : _c("v-icon", [_vm._v("keyboard_arrow_up")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }