




import { Component, Vue, Prop } from 'vue-property-decorator';

interface TAndC {
    [c: string]: string;
}

interface statusList {
    [index: string]: TAndC;
}

@Component
export default class ChargeStatusTag extends Vue {
    // prettier-ignore
    @Prop() status!: String | Object;
    // prettier-ignore
    @Prop(Boolean) small?: Boolean;

    private statusProps_: statusList = {
        pending: {
            class: 'tags--pending',
            text: 'status.pending',
        },
        paying: {
            class: 'tags--pending',
            text: 'status.paying',
        },
        uncaptured: {
            class: 'tags--succeeded',
            text: 'status.authorized',
        },
        succeeded: {
            class: 'tags--succeeded',
            text: 'status.succeeded',
        },
        failed: {
            class: 'tags--failed',
            text: 'status.failed',
        },
        revoked: {
            class: 'tags--failed',
            text: 'status.failed',
        },
        refunded: {
            class: 'tags--refunded',
            text: 'status.refunded',
        },
        canceled: {
            class: 'tags--refunded',
            text: 'status.canceled',
        },
    };

    get status_() {
        let s: any = this.status;
        if (typeof s === 'string') {
            return s;
        } else {
            return s.status == 'canceled' && s.connect_refund_id ? 'canceled' : s.connect_refund_id || s.refunded ? 'refunded' : s.status;
        }
    }

    get statusProps() {
        for (let key in this.statusProps_) {
            // console.log(key);
            if (this.statusProps_.hasOwnProperty(key)) {
                this.statusProps_[key].text = this.$t(this.statusProps_[key].text) as string;
            }
        }
        return this.statusProps_;
    }
}
