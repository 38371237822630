export const currency = {
    name: '通貨',
    hkd: 'HKD',
    usd: 'USD',
    jpy: 'JPY',
    mop: 'MOP',
    gbp: 'GBP',
    cny: 'CNY',
    myr: 'MYR',
    sgd: 'SGD',
    thb: 'THB',
};
