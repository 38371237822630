























































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { moment_HK as moment } from '@/store';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import RemoteCampaignProductItem from '@/components/remote-campaign/RemoteCampaignProductItem.vue';
import RemoteCampaignLinkItem from '@/components/remote-campaign/RemoteCampaignLinkItem.vue';
import RemoteCampaignOpenQuestionItem from '@/components/remote-campaign/RemoteCampaignOpenQuestionItem.vue';
import RemoteCampaignContactItem from '@/components/remote-campaign/RemoteCampaignContactItem.vue';
import RemoteCampaignImageItem from '@/components/remote-campaign/RemoteCampaignImageItem.vue';
import { currencyNameWithSymbol } from '../../utils/helpers/currencyHelper';

@Component({
    components: {},
    filters: {
        discount: function (product: any) {
            let originalPrice = product.originalPrice;
            let price = product.price;
            return Number((price / originalPrice).toPrecision(2)) * 100;
        },
        currencySymbolDisplay: currencyNameWithSymbol,
    },
})
export default class RemoteCampaignPreview extends Vue {
    @Prop()
    previewData!: CampaignData;

    @Prop()
    bannerSrc!: string[];

    @Prop()
    merchantDisplayName!: string;

    @Prop()
    merchantLogo!: string;

    @Prop()
    baseUrl!: string;

    private reservedDate = '';
    private reservedTime = '';
    private gender = '';
    private age = '';
    private branchId = '';
    private answer = null;
    private selectedCountryCode = '';

    private countryCodeList = this.$store.state.countryCodeList;

    private mount() {
        // sort the country code due to language
        if (this.$i18n.locale == 'en') {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.en > b.en ? 1 : -1));
        } else {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.phone_code >= b.phone_code ? 1 : -1));
        }
    }

    get date() {
        return {
            from: moment(this.previewData.from).format('LL'),
            to: moment(this.previewData.to).format('LL'),
        };
    }

    // get discount() {
    //     return Number(this.previewData.productList.price / (this.previewData.productList.originalPrice) )
    // }

    @Watch('reservedDate')
    onFromChanged(v: string) {
        (this.$refs.reservedDate as any).mdcTextField.foundation_.setValue(v);
    }

    private back() {
        this.$emit('back');
    }

    private save() {
        this.$emit('save');
    }

    public beforeUpdate() {
        this.initMDCTextFields();
    }

    private initMDCTextFields() {
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
