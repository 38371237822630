





























































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { stripe } from '@/store';
import { isString } from '@/utils/validators';
import AUTH_MSG from '@/constants/AUTH';
import styles from '@/constants/styles';
import VirtualCard from '@/components/payment/VirtualCard.vue';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import { IMaskComponent } from 'vue-imask';
import { STRIPE_CODE, STRIPE_DECLINE_CODE } from '@/constants/ERROR';
import { isPlatform } from '@/utils/platform';
import moment from '@/utils/moment_HK_en';
import { currencyNameWithSymbol } from '../../utils/helpers/currencyHelper';

interface Token {
    id: string;
    type: string;
    card: {
        brand: string;
        exp_month: number;
        exp_year: number;
        last4: string;
        name: string;
    };
}
const env = process.env.VUE_APP_ENV;
@Component({
    filters: {
        currencySymbolDisplay: currencyNameWithSymbol,
    },
    components: {
        VirtualCard,
        'imask-input': IMaskComponent,
    },
})
export default class CreditCard extends Vue {
    @Prop() value!: any;

    @Prop(String) display!: string;

    @Prop() direct!: boolean;

    @Prop() currency?: string;

    @Prop() amount?: string;

    @Prop() description?: string;

    @Watch('display')
    onDisplayChanged(display: string) {
        if (display === 'card') {
            this.$store.commit('showNavbar', false);
        }
    }

    @Watch('token_')
    onValueChanged(v: string) {
        this.$emit('input', v);
    }

    public token: Token | null = null;

    private isInitiating: boolean = this.$store.state.isLoading;
    private invalidCardMessage = '';
    private invalidExpiryMessage = '';
    private invalidCvcMessage = '';
    private errors: string[] = [];

    private holderNameMask = /^[A-Z0-9 ]*$/;

    // stripe property
    private cssSrc = 'https://fonts.googleapis.com/css?family=Muli:400';
    private elements: any = null;
    private holderName = '';
    private cardNumber: any;
    private cardExpiry: any;
    private cardCvc: any;
    private stripeElements!: {
        cardNumber: any;
        cardExpiry: any;
        cardCvc: any;
    };
    private mdcTextField: any;

    get token_() {
        let token = this.token;
        return {
            ...(token && { token }),
        };
    }

    public mounted() {
        // console.log(env);
        this.token = null;
        this.initStripeElements();
        this.initMDCTextFields();
        this.$root.$on('reset', () => {
            this.reset();
        });
    }

    private onHolderNamePrepare = (s: String) => s.toUpperCase();

    private reset() {
        this.errors = [];
        this.holderName = '';
        this.ObjectValues(this.stripeElements).forEach((element: any) => {
            element.clear();
        });
    }

    get isTestingMode() {
        return env !== 'production' ? true : false;
    }

    private previousPage() {
        // this.$store.commit('show', 'amount');
        // this.$store.commit('showNavbar', true);
        this.$emit('back');
    }

    private async nextPage() {
        // setTimeout(async () => {
        this.errorCheck();

        if (this.errors.length || this.invalidCardMessage || this.invalidExpiryMessage || this.invalidCvcMessage || !this.holderName) {
            return;
        }

        await this.getToken();
        // this.$store.commit('show', 'summary');
        this.$emit('next');
        // }, 100);
    }

    public autoFill_getToken() {
        return new Promise((resolve, reject) => {
            this.token = {
                id: 'tok_visa',
                type: 'card',
                card: {
                    brand: 'Visa',
                    exp_month: 10,
                    exp_year: 2023,
                    last4: '4242',
                    name: 'CHAN SIU MING',
                },
            };
            this.$store.commit('storeToken', {
                id: 'tok_visa',
                holder_name: 'CHAN SIU MING',
                exp_month: '10',
                exp_year: '2023',
                last4: '4242',
            });
            resolve();
        });
    }

    public async autofill(isAutoFill?: boolean) {
        setTimeout(() => {
            this.autoFill_getToken();
        }, 1000);
        setTimeout(() => {
            this.$emit('next');
        }, 1000);
        setTimeout(() => {
            this.$emit('pay');
        }, 1000);
    }

    private async pay() {
        this.errorCheck();

        if (this.errors.length || this.invalidCardMessage || this.invalidExpiryMessage || this.invalidCvcMessage || !this.holderName) {
            return;
        }

        await this.getToken();

        this.$emit('pay');
    }

    private errorCheck() {
        this.errors = [];

        if (this.holderName.length > 200) {
            this.errors.push(AUTH_MSG.HOLDER_NAME_TOO_LONG);
        }

        if (this.holderName.length < 0) {
            this.errors.push(AUTH_MSG.INVALID_HOLDER_NAME);
        }

        if (!this.holderName) {
            this.errors.push(this.$t('general.card.error.missingCardHolderName') as string);
        }

        if ((this.$refs.paymentCardNumber as HTMLElement).classList.contains('empty')) {
            this.errors.push(this.$t('general.card.error.missingCardNumber') as string);
        }

        if ((this.$refs.paymentCardExpiry as HTMLElement).classList.contains('empty')) {
            this.errors.push(this.$t('general.card.error.missingExpiryDate') as string);
        }

        if ((this.$refs.paymentCardCVC as HTMLElement).classList.contains('empty')) {
            this.errors.push(this.$t('general.card.error.missingCvc') as string);
        }
    }

    // get token and store in vuex
    private getToken() {
        // console.log(this.currency);
        return new Promise((resolve, reject) => {
            this.$store
                .dispatch('createToken', {
                    purpose: this.stripeElements.cardNumber,
                    tokenInformation: {
                        currency: this.currency,
                        name: this.holderName,
                        address_country: 'hk',
                    },
                })
                .then(res => {
                    let t = res.token,
                        c = t.card;
                    this.token = {
                        id: t.id,
                        type: t.type,
                        card: {
                            brand: c.brand,
                            exp_month: c.exp_month,
                            exp_year: c.exp_year,
                            last4: c.last4,
                            name: c.name,
                        },
                    };
                    this.$store.commit('storeToken', {
                        id: res.token.id,
                        holder_name: res.token.card.name,
                        exp_month: res.token.card.exp_month,
                        exp_year: res.token.card.exp_year,
                        last4: res.token.card.last4,
                    });
                    resolve();
                })
                .catch(err => {
                    return this.$root.$emit('error', err);
                });
        });
    }

    // Init Stripe
    private async initStripeElements() {
        this.elements = (await stripe).elements({
            fonts: [
                {
                    cssSrc: this.cssSrc,
                },
            ],
            locale: 'en',
        });

        const containerStyles = this.getCompStyle((this.$refs.holderName as Vue).$el, [
            'height',
            'paddingTop',
            'paddingBottom',
            'fontFamily',
            'fontSize',
            'fontWeight',
            'letterSpacing',
            'webkitFontSmoothing',
            'color',
        ]);

        const color_focus = (containerStyles as any).color;
        const color_placeholder = 'rgba(52, 62, 87, 0.3)';
        const elementStyles = {
            base: {
                color: color_focus,

                ':focus': {
                    color: color_focus,
                },

                '::placeholder': {
                    color: color_placeholder,
                },

                ':focus::placeholder': {
                    color: color_placeholder,
                },
            },
            invalid: {
                color: styles.colors.error,
                ':focus': {
                    color: styles.colors.error,
                },
                '::placeholder': {
                    color: styles.colors.error,
                },
            },
        };

        (elementStyles.base as any).fontFamily = (containerStyles as any).fontFamily;
        (elementStyles.base as any).fontSize = (containerStyles as any).fontSize;
        (elementStyles.base as any).fontWeight = (containerStyles as any).fontWeight;
        (elementStyles.base as any).letterSpacing = (containerStyles as any).letterSpacing;
        (elementStyles.base as any).fontSmoothing = (containerStyles as any).webkitFontSmoothing;
        // (elementStyles.base as any).lineHeight = parseFloat((containerStyles as any).height) - parseFloat((containerStyles as any).paddingTop) - parseFloat((containerStyles as any).paddingBottom) + 'px';

        const elementClasses = {
            focus: 'mdc-text-field--focused',
            empty: 'empty',
            invalid: 'invalid',
        };

        this.stripeElements = {
            cardNumber: this.elements.create('cardNumber', {
                style: {
                    ...elementStyles,
                    ...{
                        base: {
                            ...elementStyles.base,
                            ...(!(isPlatform(window, 'hybrid') && isPlatform(window, 'ios')) && { lineHeight: '56px' }),
                        },
                    },
                },
                classes: elementClasses,
            }),
            cardExpiry: this.elements.create('cardExpiry', {
                style: elementStyles,
                classes: elementClasses,
            }),
            cardCvc: this.elements.create('cardCvc', {
                style: elementStyles,
                classes: elementClasses,
            }),
        };

        this.stripeElements.cardNumber.mount('#payment-card-number');
        this.stripeElements.cardExpiry.mount('#payment-card-expiry');
        this.stripeElements.cardCvc.mount('#payment-card-cvc');

        this.stripeElements.cardNumber.on('change', (event: any) => {
            if (event.error) {
                if (event.error.type === 'validation_error' && STRIPE_CODE[event.error.code]) {
                    this.invalidCardMessage = this.$t(`errors.STRIPE_CODE.` + event.error.code) as string;
                } else if (event.error.decline_code && STRIPE_DECLINE_CODE[event.error.decline_code]) {
                    // console.log(event.error.decline_code);
                    this.invalidCardMessage = this.$t(`errors.STRIPE_DECLINE_CODE.` + event.error.decline_code) as string;
                } else {
                    this.invalidCardMessage = this.$t(`errors.` + event.error.message) as string;
                }
            } else {
                this.invalidCardMessage = '';
            }
        });

        this.stripeElements.cardExpiry.on('change', (event: any) => {
            if (event.error) {
                if (event.error.type === 'validation_error' && STRIPE_CODE[event.error.code]) {
                    this.invalidExpiryMessage = this.$t(`errors.STRIPE_CODE.` + event.error.code) as string;
                } else if (event.error.decline_code && STRIPE_DECLINE_CODE[event.error.decline_code]) {
                    this.invalidExpiryMessage = this.$t(`errors.STRIPE_DECLINE_CODE.` + event.error.decline_code) as string;
                } else {
                    this.invalidExpiryMessage = this.$t(`errors.` + event.error.message) as string;
                }
            } else {
                this.invalidExpiryMessage = '';
            }
        });

        this.stripeElements.cardCvc.on('change', (event: any) => {
            if (event.error) {
                if (event.error.type === 'validation_error' && STRIPE_CODE[event.error.code]) {
                    this.invalidCvcMessage = this.$t(`errors.STRIPE_CODE.` + event.error.code) as string;
                } else if (event.error.decline_code && STRIPE_DECLINE_CODE[event.error.decline_code]) {
                    this.invalidCvcMessage = this.$t(`errors.STRIPE_DECLINE_CODE.` + event.error.decline_code) as string;
                } else {
                    this.invalidCvcMessage = this.$t(`errors.` + event.error.message) as string;
                }
            } else {
                this.invalidCvcMessage = '';
            }
        });

        const virtualCard = (this.$refs.virtualCard as Vue).$el;
        this.stripeElements.cardCvc.addEventListener('focus', () => virtualCard.classList.add('virtual-credit-card--flipped'));
        this.stripeElements.cardCvc.addEventListener('blur', () => virtualCard.classList.remove('virtual-credit-card--flipped'));

        this.stripeElements.cardCvc.on('ready', () => {
            this.$store.commit('isLoading', false);
        });

        Object.values(this.stripeElements)
            .concat([(this.$refs.holderName as Vue).$el])
            .map(s => s.addEventListener('change', () => (this.token = null)));
    }

    private ObjectValues = (x: Object): any[] => Object.keys(x).map(i => (x as any)[i]);

    private getCompStyle = (el: any, props: any) => {
        const elComp = window.getComputedStyle(el, null);
        return props.map((key: any) => elComp[key]).reduce((acc: any, cur: any, i: number) => {
            acc[props[i]] = cur;
            return acc;
        }, {});
    };

    // animation function
    private initMDCTextFields() {
        this.mdcTextField = Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
