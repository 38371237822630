var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-page app-page-payment app-page--active" },
    [
      _c("div", { staticClass: "app-page-container" }, [
        _c("div", { staticClass: "review" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid app-page-activity app-page-activity--active bottom-nav--hidden"
            },
            [
              _c(
                "form",
                {
                  staticClass: "mdc-layout-grid__inner",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.update($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6"
                    },
                    [_vm._v(_vm._s(_vm.$t("signup.preview")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6"
                    },
                    [_vm._v(_vm._s(_vm.$t("signup.confirm")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--subtitle1"
                    },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("signup.company")) + ":")
                      ]),
                      _c("table", [
                        _c("thead"),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("signup.name")) + "：")
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.dbData.company.businessName))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("signup.type")) + "：")
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.dbData.company.businessType == "limited"
                                    ? _vm.$t("signup.limited")
                                    : _vm.$t("signup.unlimited")
                                )
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [_vm._v("BR：")]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.dbData.company.businessBR))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [_vm._v("CI：")]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.dbData.company.businessCI))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("signup.clientName")) + "：")
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.dbData.company.statementDescriptor)
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("signup.phone")) + "：")
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.dbData.company.phone_country_code) +
                                  " " +
                                  _vm._s(_vm.dbData.company.phone)
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("signup.address")) + "：")
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.dbData.company.address))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("signup.city")) + "：")
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.dbData.company.city))])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("signup.state")) + "：")
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.dbData.company.state))])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.$t("signup.businessDescription")) +
                                  "："
                              )
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.dbData.company.businessDescription)
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.$t("signup.businessWeb")) + "："
                              )
                            ]),
                            _c("td", { staticStyle: { overflow: "hidden" } }, [
                              _vm._v(_vm._s(_vm.dbData.company.businessWeb))
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--subtitle1"
                    },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("signup.companyOwner")) + "：")
                      ]),
                      _c("table", [
                        _c("thead"),
                        _c(
                          "tbody",
                          [
                            _vm._l(_vm.dbData.owners, function(owner, i) {
                              return [
                                i != 0
                                  ? _c("br", { key: "divider" + i })
                                  : _vm._e(),
                                _c(
                                  "tr",
                                  {
                                    key: "owner" + i,
                                    staticStyle: {
                                      "font-weight": "600 !important"
                                    }
                                  },
                                  [
                                    _c("td", [_vm._v(_vm._s(i + 1) + "：")]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          owner.lastName + " " + owner.firstName
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _c("tr", { key: "owner-identity" + i }, [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        owner.identityType == "hkid"
                                          ? _vm.$t("signup.hkid") + "："
                                          : _vm.$t("signup.passport") + "："
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(owner.identityNumber))
                                  ])
                                ]),
                                _c("tr", { key: "owner-phone" + i }, [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.$t("signup.ownerPhone")) + "："
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(owner.countryCode) +
                                        " " +
                                        _vm._s(owner.phone)
                                    )
                                  ])
                                ]),
                                _c("tr", { key: "owner-address" + i }, [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.$t("signup.address")) + "："
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      "\n                                            " +
                                        _vm._s(owner.address + ",") +
                                        "\n                                            "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                                            " +
                                        _vm._s(owner.city + ",") +
                                        "\n                                            "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                                            " +
                                        _vm._s(owner.state) +
                                        "\n                                        "
                                    )
                                  ])
                                ])
                              ]
                            })
                          ],
                          2
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--subtitle1"
                    },
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("signup.externalAccountInfo")) + "："
                        )
                      ]),
                      _c("table", [
                        _c("thead"),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("signup.holderName")) + "：")
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.dbData.externalAccount.holderName)
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("signup.holderType")) + "：")
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.dbData.externalAccount.holderType)
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("signup.bank")) + "：")
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.dbData.externalAccount.bank))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("signup.branchCode")) + "：")
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.dbData.externalAccount.branchCode)
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.$t("signup.accountNumber")) + "："
                              )
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.dbData.externalAccount.accountNumber)
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("signup.state")) + "：")
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.dbData.externalAccount.country))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("signup.currency")) + "：")
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.dbData.externalAccount.currency)
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--subtitle1"
                    },
                    [_c("label", [_vm._v(_vm._s(_vm.$t("signup.document")))])]
                  ),
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet",
                      attrs: { flat: "" }
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        {
                          attrs: { expand: "", dark: "" },
                          model: {
                            value: _vm.panel,
                            callback: function($$v) {
                              _vm.panel = $$v
                            },
                            expression: "panel"
                          }
                        },
                        [
                          _c(
                            "v-expansion-panel-content",
                            {
                              staticStyle: {
                                "background-color": "#673ab7",
                                color: "white",
                                "border-radius": "10px",
                                "min-height": "60px",
                                border: "0px"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mdc-typography--headline6",
                                  staticStyle: { "font-weight": "300" },
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [
                                  _vm._v(
                                    "\n                                    * " +
                                      _vm._s(_vm.$t("signup.br")) +
                                      "\n                                    "
                                  ),
                                  JSON.stringify(_vm.brFile) != "{}"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "#69cb59" } },
                                        [_vm._v("check_circle")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                {
                                  staticStyle: {
                                    "background-color": "#46287d",
                                    color: "white",
                                    "border-radius": "0px 0px 10px 10px"
                                  },
                                  attrs: { light: "" }
                                },
                                [
                                  JSON.stringify(_vm.brFile) != "{}"
                                    ? _c("v-card-text", { staticStyle: {} }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mdc-card mdc-card--outlined",
                                            staticStyle: { color: "black" }
                                          },
                                          [
                                            _c("br"),
                                            _c(
                                              "div",
                                              { staticClass: "file-preview" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "upload-file-img"
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.brFile.url,
                                                        width: "100px",
                                                        height: "100px"
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "upload-file-label mdc-typography--subtitle1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.brFile.file.name
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.ciRequired
                            ? _c(
                                "v-expansion-panel-content",
                                {
                                  staticStyle: {
                                    "background-color": "#673ab7",
                                    color: "white",
                                    "border-radius": "10px",
                                    "min-height": "60px",
                                    border: "0px"
                                  },
                                  attrs: { dark: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mdc-typography--headline6",
                                      staticStyle: { "font-weight": "300" },
                                      attrs: { slot: "header" },
                                      slot: "header"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    * " +
                                          _vm._s(_vm.$t("signup.businessCI")) +
                                          "\n                                    "
                                      ),
                                      JSON.stringify(_vm.ciFile) != "{}"
                                        ? _c(
                                            "v-icon",
                                            { attrs: { color: "#69cb59" } },
                                            [_vm._v("check_circle")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card",
                                    {
                                      staticStyle: {
                                        "background-color": "#46287d",
                                        color: "white",
                                        "border-radius": "0px 0px 10px 10px"
                                      },
                                      attrs: { light: "" }
                                    },
                                    [
                                      JSON.stringify(_vm.ciFile) != "{}"
                                        ? _c(
                                            "v-card-text",
                                            { staticStyle: {} },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mdc-card mdc-card--outlined",
                                                  staticStyle: {
                                                    color: "black"
                                                  }
                                                },
                                                [
                                                  _c("br"),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "file-preview"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upload-file-img"
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src:
                                                                _vm.ciFile.url,
                                                              width: "100px",
                                                              height: "100px"
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "upload-file-label mdc-typography--subtitle1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.ciFile.file
                                                                .name
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(_vm.ownerNo, function(i) {
                            return [
                              _c(
                                "v-expansion-panel-content",
                                {
                                  key: i,
                                  staticStyle: {
                                    "background-color": "#673ab7",
                                    color: "white",
                                    "border-radius": "10px",
                                    "min-height": "60px",
                                    border: "0px"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mdc-typography--headline6",
                                      staticStyle: { "font-weight": "300" },
                                      attrs: { slot: "header" },
                                      slot: "header"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        * " +
                                          _vm._s(
                                            _vm.$t("signup.companyOwner")
                                          ) +
                                          " " +
                                          _vm._s(i) +
                                          _vm._s(_vm.$t("signup.proof")) +
                                          " -\n                                        "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "nowrap"
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.ownerName[i - 1]))]
                                      ),
                                      _vm.owners.identityFront[i - 1] !=
                                        undefined &&
                                      _vm.owners.identityBack[i - 1] !=
                                        undefined &&
                                      _vm.owners.address[i - 1] != undefined
                                        ? _c(
                                            "v-icon",
                                            { attrs: { color: "#69cb59" } },
                                            [_vm._v("check_circle")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card",
                                    {
                                      staticStyle: {
                                        "background-color": "#46287d",
                                        color: "white",
                                        "border-radius": "0px 0px 10px 10px"
                                      },
                                      attrs: { light: "" }
                                    },
                                    [
                                      _vm.owners.identityFront[i - 1] !=
                                      undefined
                                        ? _c(
                                            "v-card-text",
                                            { staticStyle: {} },
                                            [
                                              _vm.owners.identityFront[i - 1] !=
                                              undefined
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mdc-card mdc-card--outlined",
                                                      staticStyle: {
                                                        color: "black"
                                                      }
                                                    },
                                                    [
                                                      _c("br"),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "file-preview"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upload-file-img"
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src:
                                                                    _vm.owners
                                                                      .identityFront[
                                                                      i - 1
                                                                    ].url,
                                                                  width:
                                                                    "100px",
                                                                  height:
                                                                    "100px"
                                                                }
                                                              })
                                                            ]
                                                          ),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "upload-file-label mdc-typography--subtitle1"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.owners
                                                                    .identityFront[
                                                                    i - 1
                                                                  ].file.name
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.owners.identityBack[i - 1] !=
                                      undefined
                                        ? _c(
                                            "v-card-text",
                                            { staticStyle: {} },
                                            [
                                              _vm.owners.identityBack[i - 1] !=
                                              undefined
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mdc-card mdc-card--outlined",
                                                      staticStyle: {
                                                        color: "black"
                                                      }
                                                    },
                                                    [
                                                      _c("br"),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "file-preview"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upload-file-img"
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src:
                                                                    _vm.owners
                                                                      .identityBack[
                                                                      i - 1
                                                                    ].url,
                                                                  width:
                                                                    "100px",
                                                                  height:
                                                                    "100px"
                                                                }
                                                              })
                                                            ]
                                                          ),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "upload-file-label mdc-typography--subtitle1"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.owners
                                                                    .identityBack[
                                                                    i - 1
                                                                  ].file.name
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.owners.address[i - 1] != undefined
                                        ? _c(
                                            "v-card-text",
                                            { staticStyle: {} },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mdc-card mdc-card--outlined",
                                                  staticStyle: {
                                                    color: "black"
                                                  }
                                                },
                                                [
                                                  _c("br"),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "file-preview"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upload-file-img"
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src:
                                                                _vm.owners
                                                                  .address[
                                                                  i - 1
                                                                ].url,
                                                              width: "100px",
                                                              height: "100px"
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "upload-file-label mdc-typography--subtitle1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.owners
                                                                .address[i - 1]
                                                                .file.name
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }),
                          _c(
                            "v-expansion-panel-content",
                            {
                              staticStyle: {
                                "background-color": "#673ab7",
                                color: "white",
                                "border-radius": "10px",
                                "min-height": "60px",
                                border: "0px"
                              },
                              attrs: { dark: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mdc-typography--headline6",
                                  staticStyle: { "font-weight": "300" },
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [
                                  _vm._v(
                                    "\n                                    * " +
                                      _vm._s(_vm.$t("signup.bankProof")) +
                                      "\n                                    "
                                  ),
                                  JSON.stringify(_vm.bank) != "{}"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "#69cb59" } },
                                        [_vm._v("check_circle")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                {
                                  staticStyle: {
                                    "background-color": "#46287d",
                                    color: "white",
                                    "border-radius": "0px 0px 10px 10px"
                                  },
                                  attrs: { light: "" }
                                },
                                [
                                  JSON.stringify(_vm.bank) != "{}"
                                    ? _c("v-card-text", { staticStyle: {} }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mdc-card mdc-card--outlined"
                                          },
                                          [
                                            _c("br"),
                                            _c(
                                              "div",
                                              { staticClass: "file-preview" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "upload-file-img"
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.bank.url,
                                                        width: "100px",
                                                        height: "100px"
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "upload-file-label mdc-typography--subtitle1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.bank.file.name)
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-expansion-panel-content",
                            {
                              staticStyle: {
                                "background-color": "#673ab7",
                                color: "white",
                                "border-radius": "10px",
                                "min-height": "60px",
                                border: "0px"
                              },
                              attrs: { dark: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mdc-typography--headline6",
                                  staticStyle: { "font-weight": "300" },
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [_vm._v(_vm._s(_vm.$t("signup.otherInfo")))]
                              ),
                              _c(
                                "v-card",
                                {
                                  staticStyle: {
                                    "background-color": "#46287d",
                                    color: "white",
                                    "border-radius": "0px 0px 10px 10px"
                                  },
                                  attrs: { light: "" }
                                },
                                [
                                  JSON.stringify(_vm.others) != "[]"
                                    ? _c(
                                        "v-card-text",
                                        { staticStyle: {} },
                                        [
                                          _vm._l(_vm.others, function(file, i) {
                                            return [
                                              _c("br", { key: "br" + i }),
                                              _c(
                                                "div",
                                                {
                                                  key: "otherfile" + i,
                                                  staticClass:
                                                    "mdc-card mdc-card--outlined",
                                                  staticStyle: {
                                                    color: "black"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "file-preview"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upload-file-img"
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: file.url,
                                                              width: "100px",
                                                              height: "100px"
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "upload-file-label mdc-typography--subtitle1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              file.file.name
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "mdc-fab mdc-fab--extended",
                          attrs: {
                            type: "button",
                            "aria-label": _vm.$t("signup.changeInfo")
                          },
                          on: { click: _vm.goPrevious }
                        },
                        [
                          _c("span", { staticClass: "mdc-fab__label" }, [
                            _vm._v(_vm._s(_vm.$t("signup.changeInfo")))
                          ])
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "mdc-fab mdc-fab--extended",
                          attrs: {
                            type: "submit",
                            "aria-label": _vm.$t("actions.submit")
                          }
                        },
                        [
                          _c("span", { staticClass: "mdc-fab__label" }, [
                            _vm._v(_vm._s(_vm.$t("actions.submit")))
                          ])
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ])
      ]),
      _c(
        "mdc-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            accept: _vm.$t("actions.accept"),
            cancel: _vm.$t("actions.cancel")
          },
          on: {
            cancel: function($event) {
              _vm.confirmDialogOpen = false
            },
            accept: _vm.onAccept
          },
          model: {
            value: _vm.confirmDialogOpen,
            callback: function($$v) {
              _vm.confirmDialogOpen = $$v
            },
            expression: "confirmDialogOpen"
          }
        },
        [_c("div", [_vm._v(_vm._s(_vm.dialogMessage))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }