var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.charge
    ? _c("div", { staticClass: "history-item" }, [
        _c("div", { staticClass: "payment-history-list-item" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "payment-history-list-item__amount mdc-typography--headline6"
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("currencySymbolDisplay")(_vm.charge.currency)
                    ) +
                    _vm._s(_vm.charge.amount) +
                    "\n            "
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "tags__group recurrents-list-item__status" },
              [
                _vm.charge.connect_recurrence_id && _vm.charge.source === "card"
                  ? _c("RecurrentStatusTag")
                  : _vm._e(),
                _vm.charge.connect_remote_id && _vm.charge.source === "card"
                  ? _c("RemoteStatusTag", { attrs: { status: "default" } })
                  : _vm._e(),
                _vm.charge.connect_campaign_order_id &&
                _vm.charge.source === "card"
                  ? _c("div", { staticClass: "tags tags--campaign filled" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("general.campaign")) +
                          "\n                "
                      )
                    ])
                  : _vm._e(),
                _vm.charge.connect_online_payment_id &&
                _vm.charge.source === "card"
                  ? _c("div", { staticClass: "tags tags--online filled" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("general.online")) +
                          "\n                "
                      )
                    ])
                  : _vm._e(),
                !_vm.charge.connect_recurrence_id &&
                !_vm.charge.connect_remote_id &&
                !_vm.charge.connect_campaign_order_id &&
                !_vm.charge.connect_online_payment_id &&
                _vm.charge.source === "card"
                  ? _c("div", { staticClass: "tags tags--card filled" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("general.paymentMethods.card")) +
                          "\n                "
                      )
                    ])
                  : _vm._e(),
                _vm.charge.source === "wechat"
                  ? _c("div", { staticClass: "tags tags--wechat filled" }, [
                      _vm._v(_vm._s(_vm.$t("general.paymentMethods.wechat")))
                    ])
                  : _vm._e(),
                _vm.charge.source === "alipay"
                  ? _c("div", { staticClass: "tags tags--alipay filled" }, [
                      _vm._v(_vm._s(_vm.$t("general.paymentMethods.alipay")))
                    ])
                  : _vm._e(),
                _c("ChargeStatusTag", { attrs: { status: _vm.charge } })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _vm.charge.description
              ? _c(
                  "div",
                  {
                    staticClass:
                      "payment-history-list-item__desc mdc-typography--body2"
                  },
                  [_vm._v(_vm._s(_vm.charge.description))]
                )
              : _c(
                  "div",
                  {
                    staticClass:
                      "payment-history-list-item__desc mdc-typography--body2"
                  },
                  [_vm._v("—")]
                ),
            _c(
              "div",
              {
                staticClass:
                  "payment-history-list-item__datetime mdc-typography--caption"
              },
              [_vm._v(_vm._s(_vm.formatDate))]
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }