

























































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { currencyNameWithSymbol } from '@/utils/helpers/currencyHelper';

@Component({
    filters: {
        currencySymbolDisplay: currencyNameWithSymbol,
    },
})
export default class CampaignPurchaseInformation extends Vue {
    @Prop()
    displayName: any;

    @Prop()
    campaignData: any;

    @Prop()
    purchaseData: any;

    private tncDialog: boolean = false;

    private openTNCDialog() {
        this.tncDialog = true;
    }

    // private payDialogConfirm_accept() {
    //     console.log('yo');
    // }

    get account() {
        return this.campaignData.accountList.filter((account: any) => account.id === this.purchaseData.accountId)[0];
    }

    get branchName() {
        return this.account ? this.account.campaign_store_name : '';
    }

    get branchAddress() {
        return this.account ? this.account.campaign_store_address : '';
    }

    get tncPreview() {
        //check no of line > 5, if yes then
        return (this.campaignData.tnc.match(new RegExp('\n', 'g')) || []).length > 5
            ? this.campaignData.tnc.split('\n').slice(0, 5).join('\n') + '...(點擊以繼續閱讀)'
            : this.campaignData.tnc;
    }
}
