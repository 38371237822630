var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mdc-layout-grid",
      class: _vm.langClass,
      attrs: { id: "amount" }
    },
    [
      _c("div", { staticClass: "mdc-layout-grid__inner" }, [
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
          },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.paymentMethod,
                    expression: "paymentMethod"
                  }
                ],
                staticClass: "mdc-text-field__input",
                class: { "select--hidden": !_vm.paymentMethod },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.paymentMethod = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c(
                  "option",
                  { attrs: { disabled: "" }, domProps: { value: null } },
                  [_vm._v(_vm._s(_vm.$t("general.paymentMethods.label")))]
                ),
                _c(
                  "option",
                  { attrs: { disabled: !_vm.wechatEnabled, value: "wechat" } },
                  [_vm._v(_vm._s(_vm.$t("general.paymentMethods.wechat")))]
                ),
                _c(
                  "option",
                  { attrs: { disabled: !_vm.alipayEnabled, value: "alipay" } },
                  [_vm._v(_vm._s(_vm.$t("general.paymentMethods.alipay")))]
                )
              ]
            ),
            _c(
              "label",
              {
                staticClass: "mdc-floating-label",
                class: [
                  {
                    " mdc-floating-label--float-above": _vm.paymentMethod
                      ? true
                      : false
                  }
                ]
              },
              [_vm._v(_vm._s(this.$t("general.paymentMethods.label")))]
            ),
            _c("div", { staticClass: "mdc-notched-outline" }, [
              _c("svg", [
                _c("path", { staticClass: "mdc-notched-outline__path" })
              ])
            ]),
            _c("div", { staticClass: "mdc-notched-outline__idle" })
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--xlarge mdc-text-field--outlined mdc-text-field--not-notched"
          },
          [
            _c("imask-input", {
              ref: "paymentInputUser",
              staticClass: "mdc-text-field__input",
              staticStyle: { "text-align": "right", color: "transparent" },
              attrs: {
                mask: Number,
                prepare: _vm.convertPlusToDot,
                radix: ".",
                thousandsSeparator: ",",
                scale: 2,
                unmask: "typed",
                min: 0,
                max: 100000,
                placeholder: "$0.00",
                type: "tel",
                autocorrect: "off",
                spellcheck: "false",
                autocomplete: "off"
              },
              on: { complete: _vm.onCompleteHandler },
              model: {
                value: _vm.amount,
                callback: function($$v) {
                  _vm.amount = $$v
                },
                expression: "amount"
              }
            }),
            _c(
              "div",
              {
                staticClass: "mdc-text-field__input",
                staticStyle: {
                  "text-align": "right",
                  position: "absolute",
                  top: "1px",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  "pointer-events": "none"
                },
                attrs: { readonly: "" }
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "line-height": "1",
                      margin: "auto 0 auto auto"
                    }
                  },
                  [_vm._v(_vm._s(_vm.amountShown))]
                )
              ]
            ),
            _c("div", { staticClass: "mdc-text-field-outline" })
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.description,
                  expression: "description"
                }
              ],
              staticClass: "mdc-text-field__input",
              attrs: {
                type: "text ",
                id: "payment-description",
                autocomplete: "off",
                autocorrect: "off",
                spellcheck: "false"
              },
              domProps: { value: _vm.description },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.description = $event.target.value
                }
              }
            }),
            _c(
              "label",
              {
                staticClass: "mdc-floating-label",
                class: [
                  {
                    " mdc-floating-label--float-above": _vm.description
                      ? true
                      : false
                  }
                ],
                attrs: { for: "payment-description" }
              },
              [_vm._v(_vm._s(_vm.$t("general.paymentDetails.description")))]
            ),
            _c("div", { staticClass: "mdc-notched-outline" }, [
              _c("svg", [
                _c("path", { staticClass: "mdc-notched-outline__path" })
              ])
            ]),
            _c("div", { staticClass: "mdc-notched-outline__idle" })
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
          },
          [
            _vm.check
              ? _c("p", { staticClass: "error-prompt" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("errors.PAYMENT_DETAILS.invalid_transaction", [
                        _vm.chargeLimitDisplay
                      ])
                    )
                  )
                ])
              : _vm._e()
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
          },
          [
            _c(
              "button",
              { staticClass: "mdc-button", on: { click: _vm.reset } },
              [_vm._v(_vm._s(_vm.$t("actions.reset")))]
            ),
            _c(
              "button",
              {
                staticClass: "mdc-fab mdc-fab--extended",
                attrs: { "aria-label": _vm.$t("actions.qrPay") },
                on: { click: _vm.turnOn }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "mdc-fab__icon",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewbox: "0 0 24 24"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M11.7 11.1c-1.8-.5-2.4-1-2.4-1.7 0-.9.8-1.5 2.2-1.5 1.1 0 1.7.4 1.9 1.1.1.3.4.6.7.6h.2c.5 0 .9-.5.7-1-.3-1-1.1-1.7-2.4-2V6c0-.7-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2v.5c-1.5.3-2.7 1.3-2.7 2.9 0 1.9 1.5 2.8 3.8 3.3 2 .5 2.4 1.2 2.4 1.9 0 .6-.4 1.4-2.2 1.4-1.3 0-2-.5-2.3-1.2-.1-.3-.4-.5-.7-.5h-.2c-.5 0-.9.5-.7 1 .5 1.1 1.5 1.8 2.7 2v.7c0 .7.5 1.2 1.2 1.2s1.2-.5 1.2-1.2v-.5c1.6-.3 2.8-1.2 2.8-2.9 0-2.2-1.9-3-3.8-3.5z"
                      }
                    })
                  ]
                ),
                _c("span", { staticClass: "mdc-fab__label" }, [
                  _vm._v(_vm._s(_vm.$t("actions.qrPay")))
                ])
              ]
            )
          ]
        ),
        _vm.nativeJPAYQrScanner
          ? _c("div")
          : _vm.cameraActive && _vm.isPWA && _vm.isIOS
            ? _c(
                "div",
                {
                  staticClass: "fullscreen whitescreen",
                  class: [{ closed: !_vm.capturingQrCode }]
                },
                [
                  _c(
                    "i",
                    {
                      attrs: {
                        tabindex: "0",
                        id: "close-button",
                        role: "button"
                      },
                      on: { click: _vm.turnOff }
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "24",
                            height: "24",
                            viewBox: "0 0 18 18"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm.capturingQrCode
                    ? _c("qrcode-capture", {
                        ref: "qrcodeCapture",
                        staticStyle: { display: "none" },
                        on: { decode: _vm.onDecode }
                      })
                    : _vm._e(),
                  _vm.capturingQrCode
                    ? _c(
                        "div",
                        { staticClass: "qrCodeButton" },
                        [
                          _c(
                            "mdc-button",
                            {
                              staticStyle: {
                                transition: "transform 0.4s",
                                "will-change": "transform"
                              },
                              attrs: { raised: "" },
                              on: { click: _vm.triggerQrcodeCapture }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "mdc-button__icon",
                                  staticStyle: { transform: "scale(0.833333)" },
                                  attrs: {
                                    "aria-hidden": "true",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 24 24"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M20 4h-3.17l-1.24-1.35A1.99 1.99 0 0 0 14.12 2H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"
                                    }
                                  })
                                ]
                              ),
                              _c("span", { staticClass: "mdc-button__text" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("payment.wechatPay.scanQRCode"))
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm.cameraActive
              ? _c(
                  "div",
                  {
                    staticClass: "fullscreen",
                    class: [{ closed: !_vm.capturingQrCode }]
                  },
                  [
                    _c(
                      "i",
                      {
                        attrs: {
                          tabindex: "0",
                          id: "close-button",
                          role: "button"
                        },
                        on: { click: _vm.turnOff }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "24",
                              height: "24",
                              viewBox: "0 0 18 18"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm.capturingQrCode
                      ? _c(
                          "qrcode-stream",
                          { on: { decode: _vm.onDecode, init: _vm.onInit } },
                          [
                            _vm.loading
                              ? _c(
                                  "div",
                                  { staticClass: "loading-indicator" },
                                  [_c("DoubleBounce")],
                                  1
                                )
                              : _vm._e(),
                            _c("p", { staticStyle: { color: "white" } }, [
                              _vm._v(_vm._s(_vm.error))
                            ])
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }