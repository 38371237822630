export const settings = {
    captureNow: '送金を開始 (一回払い)',
    authorized: {
        transaction: '認可済み支払い',
        volume: '認可済み金額',
    },
    user: {
        label: 'ユーザー',
        create: 'ユーザー作成',
        type: 'ユーザータイプ',
        superuser: '管理者',
        normaluser: '一般ユーザー',
        email: 'メール',
        display: 'ユーザーネーム',
        phone: '電話番号 (任意項目)',
        info: 'ユーザー情報',
    },
    branch: {
        label: '支店',
        create: '支店作成',
        modify: '支店変更',
        receipt: {
            name: '支店名 (英字) - 領収書',
            address: '支店の所在地 (英字) - 領収書',
        },
        campaign: {
            name: '支店名 (英字) - 広告',
            address: '支店の所在地 (英字) - 広告',
        },
    },
    merchant: {
        setup: '初期設定',
        setupMessage: '変更をする場合は設定のページで行って下さい。',
        modify: '会社情報を変更',
        name: {
            receipt: '会社名 (領収書)',
            campaign: '会社名 (広告 )',
        },
        logo: {
            campaign: '会社のロゴ (広告 )',
        },
        current: '現在の名前',
        new: '新しい名前',
        confirm: '新しい名前を確認',
        display: 'ユーザーネーム',
        url: 'リンク名 (英字のみ)',
        uploadLogoWarning: '会社のロゴをアップロードして下さい。',
        modifyCampaignWarning: '変更により前のリンクが無効になりますので注意して下さい。',
    },
    export: {
        title: '書き出す',
        label: 'レポートを書き出す',
        type: {
            transaction: '支払いレポート',
            journal: '現金流出入レポート',
            online: 'オンライン取引レポート',
            statement: '利用明細',
            payout: '入金レポート',
        },
        reminder: {
            type: 'レポートの種類を選択して下さい。',
            period: '期間を選択して下さい。',
            branch: '支店を選択して下さい。',
            bybranch: '請選擇匯出的月結單選項',
        },
        bybranch: {
            title: '月結單選項',
            true: '分開每個分店',
            false: '整合所有分店',
        },
        period: '期間',
        downloadData: 'データをダウンロード',
        downloadAndOpen: 'ダウンロード (新しいタブを開く)',
        copyLinkAndPassword: 'リンクとパスワードを複製',
        longPressOrCopy: '長押して共有/複製',
        expire: 'リンクは{0}に無効になります。',
    },
    printer: 'プリンター',
    connectPrinter: 'プリンターに接続',
    connectPrinterFailed: 'プリンターに接続失敗',
    capture: {
        title: '送金 (一回払い)',
        enableAuthorization: '送金モードをオン',
        mode: {
            label: 'モード',
            auto: '自動送金 (毎日)',
            manual: '手動',
        },
        summary: {
            succeededTrx: '{0}件の支払いが成功',
            failedTrx: '{0}件の支払い<b>失敗</b>',
            contact: '問題があればJarvix Payに連絡して下さい。',
        },
        confirm: '送金モードの変更を確認',
    },
    permissions: {
        title: '利用制限',
        account: {
            addAccount: '支店を追加',
            addUser: 'ユーザーを追加',
            editPermission: '利用制限を変更',
            modifyInformation: '会社情報を変更',
        },
        functional: {
            campaignAgent: '代理プロモーション',
            dashboard: '概要',
            export: '書き出す',
            payout: '入金',
            printer: 'プリンター',
            transactionsHistory: '支払い履歴',
        },
        payment: {
            cancelRecurrence: '月払いをキャンセル',
            capture: '送金モード',
            changeRecurrenceCreditCard: '月払いのクレジットカードを変更',
            editChargeDescription: '説明を変更',
            refund: '返金',
            enable_recurrence: '月払いを許可',
            enable_remote: 'リクエストを許可',
            enable_campaign: '広告を許可',
        },
        notification: {
            payment: '支払い通知',
            campaign: '広告通知',
            summary: '概要通知',
        },
    },
    language: '言語',
    password: {
        label: 'パスワード',
        change: 'パスワードを再設定',
        reminder: '強度の高いパスワードを使用して下さい。',
        current: '現在のパスワード',
        new: '新しいパスワード',
        confirm: 'パスワード確認',
    },
    logout: {
        title: 'ログアウト',
    },
};
