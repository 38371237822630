const phoneValidate = require('phone');

export const isMobileNumber = (input: any) => {
    if (input.substring(0, 4) == '+852') {
        const regex = /^1[0-9]{10}$|^[456789][0-9]{7}$/;
        return regex.test(input.substring(4));
    } else return phoneValidate(input).length > 0;
};

export const isPhoneNumber = (input: any) => {
    if (input.substring(0, 4) == '+852') {
        const regex = /^[23456789][0-9]{7}$/;
        // const regex = /^1[0-9]{10}$|^[456789][0-9]{7}$/;
        return regex.test(input.substring(4));
    } else return phoneValidate(input).length > 0;
};

export const isUUID4 = (input: any) => {
    const regex = /^[0-9a-f\-]{36}$/;
    return regex.test(input);
};

export const isString = (input: any) => {
    return typeof input === 'string';
};

export const isEmail = (input: any) => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(input);
};

export const isHKID = (str: string) => {
    const strValidChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    // basic check length
    if (str.length < 8) {
        return false;
    }

    // handling bracket
    if (str.charAt(str.length - 3) === '(' && str.charAt(str.length - 1) === ')') {
        str = str.substring(0, str.length - 3) + str.charAt(str.length - 2);
    }

    // convert to upper case
    str = str.toUpperCase();

    // regular expression to check pattern and split
    const hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
    const matchArray = str.match(hkidPat);

    // not match, return false
    if (matchArray === null) {
        return false;
    }

    // the character part, numeric part and check digit part
    const charPart = matchArray[1];
    const numPart = matchArray[2];
    const checkDigit = matchArray[3];

    // calculate the checksum for character part
    let checkSum = 0;
    if (charPart.length === 2) {
        checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
        checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
    } else {
        checkSum += 9 * 36;
        checkSum += 8 * (10 + strValidChars.indexOf(charPart));
    }

    // calculate the checksum for numeric part
    for (let i = 0, j = 7; i < numPart.length; i++, j--) {
        checkSum += j * Number(numPart.charAt(i));
    }

    // verify the check digit
    const remaining = checkSum % 11;
    const verify = remaining === 0 ? 0 : 11 - remaining;

    return verify === Number(checkDigit) || (verify === 10 && checkDigit === 'A');
};
