var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mdc-dialog",
    {
      staticClass: "mdc-dialog",
      attrs: {
        title: this.$t("signup.accountVerification"),
        accept: this.$t("actions.submit")
      },
      on: { validate: _vm.verify, cancel: _vm.onCancel },
      model: {
        value: _vm.modalShow,
        callback: function($$v) {
          _vm.modalShow = $$v
        },
        expression: "modalShow"
      }
    },
    [
      _c(
        "form",
        {
          staticClass:
            "mdc-text-field mdc-text-field--outlined mdc-text-field--not-notched",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.verify($event)
            }
          }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.token,
                expression: "token"
              }
            ],
            ref: "input_field",
            staticClass: "mdc-text-field__input",
            attrs: {
              type: "tel",
              placeholder: this.$t("signup.verificationInputText"),
              autocomplete: "off",
              autocorrect: "off",
              spellcheck: "false"
            },
            domProps: { value: _vm.token },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.token = $event.target.value
              }
            }
          }),
          _c("div", { staticClass: "mdc-text-field-outline" })
        ]
      ),
      _vm.errors.length
        ? _c("div", [
            _c("p", { staticClass: "error-prompt" }, [
              _c(
                "ul",
                _vm._l(_vm.errors, function(error) {
                  return _c("li", { key: error }, [_vm._v(_vm._s(error))])
                })
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }