var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "campaign-summary-item" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "title mdc-typography--headline6" }, [
        _vm._v(_vm._s(_vm.campaign.external_name))
      ])
    ]),
    _c(
      "div",
      { staticClass: "row flex" },
      [
        _c(
          "mdc-button",
          {
            staticClass: "button",
            on: {
              click: function($event) {
                _vm.$emit("preview")
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("campaign.copywriting")))]
        ),
        _c(
          "mdc-button",
          {
            staticClass: "button",
            on: {
              click: function($event) {
                _vm.$emit("orders")
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("campaign.orderList")))]
        ),
        _c(
          "div",
          {
            staticClass:
              "tags__group campaign-order-summary-item__status ml-auto"
          },
          [
            _vm.agentPromotion === true
              ? _c("div", { staticClass: "tags filled tags--active" }, [
                  _vm._v(_vm._s(_vm.$t("status.agentPromotion")))
                ])
              : _vm._e(),
            _vm.status === "inactive"
              ? _c("div", { staticClass: "tags filled tags--inactive" }, [
                  _vm._v(_vm._s(_vm.$t("status.inactive")))
                ])
              : _vm._e(),
            _vm.status === "notYetStarted"
              ? _c("div", { staticClass: "tags filled tags--inactive" }, [
                  _vm._v(_vm._s(_vm.$t("status.notYetStarted")))
                ])
              : _vm._e(),
            _vm.status === "active"
              ? _c("div", { staticClass: "tags filled tags--active" }, [
                  _vm._v(_vm._s(_vm.$t("status.active")))
                ])
              : _vm._e(),
            _vm.status === "completed"
              ? _c("div", { staticClass: "tags filled tags--completed" }, [
                  _vm._v(_vm._s(_vm.$t("status.completed")))
                ])
              : _vm._e(),
            _vm.status === "canceled"
              ? _c("div", { staticClass: "tags filled tags--failed" }, [
                  _vm._v(_vm._s(_vm.$t("status.canceled")))
                ])
              : _vm._e()
          ]
        )
      ],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "row-left-section" }, [
        _c("div", { staticClass: "mdc-typography--body2" }, [
          _vm._v(
            _vm._s(_vm.$t("campaign.internalName")) +
              ": " +
              _vm._s(_vm.campaign.internal_name)
          )
        ]),
        _c("div", { staticClass: "mdc-typography--body2" }, [
          _vm._v(_vm._s(_vm.$t("campaign.period")) + ":")
        ]),
        _c("div", { staticClass: "mdc-typography--body2" }, [
          _vm._v(_vm._s(_vm.from) + " - " + _vm._s(_vm.to))
        ]),
        _c("div", { staticClass: "mdc-typography--body2" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$t("campaign.orderSummary.totalTransaction")) +
              ": " +
              _vm._s(_vm._f("currency")(_vm.campaign.total_amount || 0)) +
              "\n            "
          )
        ]),
        _c("div", { staticClass: "mdc-typography--body2" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$t("campaign.orderSummary.totalOrder")) +
              ": " +
              _vm._s(_vm.campaign.total_orders || 0) +
              " (" +
              _vm._s(_vm.$t("campaign.orderSummary.paid")) +
              ":\n                " +
              _vm._s(_vm.campaign.total_paid_orders || 0) +
              ")\n            "
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "row flex" }, [
      _c(
        "div",
        { staticClass: "mdc-typography--caption ml-auto align-self-center" },
        [_vm._v(_vm._s(_vm.moment(_vm.campaign.created_at).format("LLL")))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }