















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import Signing from '@/components/signup/Signing.vue';
import BANKS from '../../constants/BANKS';
import { isEmail } from '../../utils/validators';
import * as moment from 'moment';

const ENV = (process.env.VUE_APP_ENV || 'development') as 'development' | 'staging' | 'production';

@Component({
    components: {
        Signing,
    },
})
export default class Signature extends Vue {
    private panel: Array<boolean> = [];
    private signApplication: boolean = false;
    private applicationSignature: string = '';
    private signContract: boolean = false;
    private contractSignature: string = '';
    private finishSigning: boolean = false;
    private reviewing: boolean = false;
    private pdfEmail: string = '';
    private name: string = '';
    private title: string = '';
    private gender: string = '';
    private today: string = moment()
        .format('YYYY-MM-DD')
        .toString();
    private taxRate: any = {
        rate: {
            visa_master: 0,
            ae: 0,
            unionpay: 0,
            foreign: 0,
            wechat_ali: 0,
        },
        rebate_rate: {
            visa_master: 0,
            ae: 0,
            unionpay: 0,
            foreign: 0,
            wechat_ali: 0,
        },
    };
    private signUpData: any = {
        company: {
            email: '',
            businessName: '',
            businessType: '',
            businessBR: '',
            businessCI: '',
            statementDescriptor: '',
            address: '',
            phone: '',
            city: '',
            state: '',
        },
        owners: [
            {
                id: 1,
                firstName: '',
                lastName: '',
                identityType: '',
                identityNumber: '',
                dob: '',
                address: '',
                city: '',
                state: '',
            },
        ],
        externalAccount: {
            holderName: '',
            holderType: '',
            bank: null,
            branchCode: '',
            accountNumber: '',
            country: '',
            currency: '',
        },
    };
    private errors: string[] = [];
    private status: string = 'undelivered';

    @Watch('gender')
    onGenderChange() {
        if (this.signContract && this.signApplication && this.gender != '' && this.title != '' && this.name != '') {
            this.finishSigning = true;
        } else {
            this.finishSigning = false;
        }
    }

    @Watch('title')
    onTitleChange() {
        if (this.signContract && this.signApplication && this.gender != '' && this.title != '' && this.name != '') {
            this.finishSigning = true;
        } else {
            this.finishSigning = false;
        }
    }
    @Watch('name')
    onNameChange() {
        if (this.signContract && this.signApplication && this.gender != '' && this.title != '' && this.name != '') {
            this.finishSigning = true;
        } else {
            this.finishSigning = false;
        }
    }

    // since the signature is base64, convert the signature into file
    private dataURLtoFile(dataurl: string, filename: string) {
        let arr = dataurl.split(',');
        let mime = arr[0].match(/:(.*?);/)![1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    public async mounted() {
        this.initMDCTextFields();
        this.status = await this.$store.dispatch('getMerchantStatus');
        if (this.status == 'pending' || this.status == 'rejected') {
            this.signApplication = true;
            this.signContract = true;
            this.reviewing = true;
            this.finishSigning = true;
        }

        this.taxRate = await this.$store.dispatch('getTaxRate');
        this.signUpData = await this.$store.dispatch('retrieveSignUpData');
    }

    // animation function
    private initMDCTextFields() {
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }

    protected bankName(bank: any) {
        if (!bank) return;
        const name = (BANKS.bankOptions[ENV] as any).find((data: any) => data.bank_code == bank);
        return name.bank_name;
    }

    protected saveSignApplication(data: any) {
        this.signApplication = true;
        this.applicationSignature = data;
        this.panel = [];
        if (this.signContract && this.gender != '' && this.title != '' && this.name != '') {
            this.finishSigning = true;
        } else {
            this.finishSigning = false;
        }
    }

    protected cleanSignApplication() {
        this.signApplication = false;
        this.applicationSignature = '';
        this.finishSigning = false;
    }

    protected saveSignContract(data: any) {
        this.signContract = true;
        this.contractSignature = data;
        this.panel = [];
        if (this.signApplication && this.gender != '' && this.title != '' && this.name != '') {
            this.finishSigning = true;
        } else {
            this.finishSigning = false;
        }
    }

    protected cleanSignContract() {
        this.signContract = false;
        this.contractSignature = '';
        this.finishSigning = false;
    }

    protected async submitApplication() {
        try {
            this.errors = [];

            const imgSignApplicaton = this.dataURLtoFile(this.applicationSignature, 'imgSignApplicaton.png');

            const imgSignContract = this.dataURLtoFile(this.contractSignature, 'imgSignContract.png');

            // add the bankName field
            this.signUpData.externalAccount.bankName = (BANKS.bankOptions[ENV] as any).find(
                (data: any) => data.bank_code == this.signUpData.externalAccount.bank
            ).bank_name;

            if (!isEmail(this.pdfEmail.trim())) {
                this.errors.push(this.$t('AUTH.INVALID_EMAIL_FORAMT') as any);
            }

            if (this.errors.length) {
                return;
            }
            await this.$store.dispatch('uploadSignature', {
                imgSignApplicaton: imgSignApplicaton,
                imgSignContract: imgSignContract,
                data: {
                    title: this.title,
                    name: this.name,
                    gender: this.gender,
                    signUpData: this.signUpData,
                    taxRate: this.taxRate,
                },
                email: this.pdfEmail.trim(),
            });

            this.reviewing = true;
        } catch (error) {
            // console.log(error);
            return this.$root.$emit('error', new Error(`${this.$t('errors.GENERAL.retry')}`));
        }
    }
}
