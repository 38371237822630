import Vue from 'vue';
import Router from 'vue-router';
import { RawLocation } from 'vue-router';
import store from '@/store';

import { scrollTop } from '@/utils/scrollTop';

import RouterView from './views/RouterView.vue';
import Login from './views/Login.vue';
import Signup from './views/Signup.vue';
import Payment from './views/Payment.vue';
import Transaction from './views/Transaction.vue';
import History from './views/History.vue';
import Payout from './views/Payout.vue';
import Settings from './views/settings/Settings.vue';
import PrinterSettings from './views/settings/PrinterSettings.vue';
import ExportSettings from './views/settings/ExportSettings.vue';
import Receipt from './components/Receipt.vue';
import ChinesePaymentReceipt from './components/ChinesePaymentReceipt.vue';

//dashboard
import DashboardView from './views/dashboard/DashboardView.vue';
import Dashboard from './views/dashboard/Dashboard.vue';
import PayoutCardExpanded from './components/dashboard/payouts/PayoutCardExpanded.vue';

// recurrents
import RecurrentsView from './views/recurrents/RecurrentsView.vue';
import Recurrents from './views/recurrents/Recurrents.vue';
import RecurrentDetails from './views/recurrents/RecurrentDetails.vue';

// settings
import SettingsList from './components/settings/SettingsList.vue';
import Password from './components/settings/Password.vue';
import Printer from './components/settings/printer/Printer.vue';
import PrinterConnect from './components/settings/printer/PrinterConnect.vue';
import NewStore from './components/settings/NewStore.vue';
import EditStore from './components/settings/EditStore.vue';
import NewUser from './components/settings/NewUser.vue';
import EditPermission from './components/settings/EditPermission.vue';
import ExportSelection from './components/settings/export/ExportSelection.vue';
import ExportDownload from './components/settings/export/ExportDownload.vue';
import Capture from './components/settings/Capture.vue';
import CaptureSetting from './components/settings/CaptureSetting.vue';
import LanguageSettings from './components/settings/LanguageSettings.vue';
import About from './components/settings/About.vue';

//  signup
import Registration from './components/signup/Registration.vue';
import Company from './components/signup/Company.vue';
import Owner from './components/signup/Owner.vue';
import ExternalAccount from './components/signup/ExternalAccount.vue';
import DocumentUpload from './components/signup/DocumentUpload.vue';
import Review from './components/signup/Review.vue';
import Signature from './components/signup/Signature.vue';
import RejectedPage from './components/signup/RejectedPage.vue';

// remote payment
import Remote from './views/remote/Remote.vue';
import RemoteDetails from './views/remote/RemoteDetails.vue';
import RemotePaymentLink from './views/remote/RemotePaymentLink.vue';

// remote campaign
import OnlinePayment from './views/OnlinePayment.vue';

import CampaignHistory from './views/campaign-history/CampaignHistory.vue';
import CampaignHistoryList from './views/campaign-history/CampaignHistoryList.vue';
import CampaignHistoryPreview from './views/campaign-history/CampaignHistoryPreview.vue';
import CampaignOrderList from './views/campaign-history/CampaignOrderList.vue';
import CampaignOrderInformation from './views/campaign-history/CampaignOrderInformation.vue';

import RemoteCampaign from './views/remote-campaign/RemoteCampaign.vue';
import CampaignLink from './views/campaign-link/CampaignLink.vue';

import MerchantSettings from './views/settings/MerchantSettings.vue';
import MerchantSettingList from './components/settings/merchant/MerchantSettingList.vue';
import MerchantCampaignDisplayName from './components/settings/merchant/MerchantCampaignDisplayName.vue';
import MerchantName from './components/settings/merchant/MerchantName.vue';
import MerchantLogo from './components/settings/merchant/MerchantLogo.vue';

import Dummy from './views/Dummy.vue';
import LendingWebView from './views/LendingWebView.vue';
import { isAbsolute } from 'path';

// import { i18n } from './plugins/i18n';

// helper function
const loggedIn = async () => {
    /* const loggedIn = window.localStorage.getItem('loggedIn');

    if (loggedIn === null) {
        return false;
    }

    return Number(loggedIn) > new Date().getTime() ? true : false; */
    try {
        await store.dispatch('retrieveCountryCodeList');
        const user = await store.dispatch('checkLogin');
        if (user) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
    // return true;
};

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: (to: any) => {
                if (to.hash && to.hash[1] === '/') {
                    return {
                        path: to.hash.substring(1),
                        hash: '',
                    };
                } else {
                    return { name: 'login', hash: '' };
                }
            },
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            props: true,
            meta: {
                showNavbar: false,
                showTopbar: false,
            },
            beforeEnter: async (to, from, next) => {
                if (await loggedIn()) {
                    if (localStorage['remote-data']) {
                        return next('/remote');
                    }

                    return next('/payment');
                }
                next();
            },
        },
        {
            path: '/signup',
            component: Signup,
            meta: {
                showNavbar: false,
            },
            children: [
                {
                    path: '',
                    name: 'signup',
                    component: Registration,
                },
                {
                    path: 'company',
                    name: 'company',
                    component: Company,
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: 'owner',
                    name: 'owner',
                    component: Owner,
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: 'external_account',
                    name: 'external_account',
                    component: ExternalAccount,
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: 'document_upload',
                    name: 'document_upload',
                    component: DocumentUpload,
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: 'review',
                    name: 'review',
                    component: Review,
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: 'signature',
                    name: 'signature',
                    component: Signature,
                    meta: {
                        requiresAuth: true,
                        showScrollBtn: true,
                    },
                },
                {
                    path: 'unsuccessful',
                    name: 'unsuccessful',
                    component: RejectedPage,
                    meta: {
                        requiresAuth: true,
                    },
                },
            ],
        },
        {
            path: '/dashboard',
            component: DashboardView,
            meta: {
                requiresAuth: true,
            },
            beforeEnter: function(to, from, next) {
                if ((store.state.user as any).permissions_.functional.dashboard) {
                    next();
                } else {
                    next('/payment');
                }
            },
            children: [
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    component: Dashboard,
                },
                {
                    path: 'transfers',
                    name: 'dashboard.transfers',
                    component: PayoutCardExpanded,
                    props: true,
                    meta: {
                        showNavbar: false,
                    },
                },
                {
                    path: 'transfers/:id',
                    name: 'dashboard.transfer.details',
                    component: Payout,
                    props: true,
                    meta: {
                        requiresAuth: true,
                        showNavbar: false,
                    },
                    beforeEnter: (to, from, next) => {
                        if (!to.params.payout) {
                            next(false);
                            router.go(-2);
                            return;
                        }
                        next();
                    },
                },
            ],
        },
        {
            path: '/payment',
            name: 'payment',
            component: Payment,
            meta: {
                requiresAuth: true,
            },
            beforeEnter: (to, from, next) => {
                if (from.name === 'receipt') {
                    store.commit('show', 'amount');
                }
                next();
            },
        },
        {
            path: '/transactions',
            component: Transaction,
            name: 'transactions',
            meta: {
                showNavbar: true,
            },
            beforeEnter: function(to, from, next) {
                if ((store.state.user as any).permissions_.functional.transactions_history) {
                    next();
                } else {
                    next('/payment');
                }
            },
            redirect: '/transactions/history',
            children: [
                {
                    path: 'history',
                    name: 'transactions.history',
                    component: History,
                    meta: {
                        requiresAuth: true,
                        appbarTitle: 'general.transactions',
                    },
                },
                {
                    path: 'recurrence_history',
                    name: 'transactions.recurrence_history',
                    component: Recurrents,
                    meta: {
                        requiresAuth: true,
                        appbarTitle: 'general.recurrent',
                    },
                },
            ],
        },
        {
            path: '/receipt/:chargeId',
            name: 'receipt',
            component: Receipt,
            props: true,
            meta: {
                requiresAuth: true,
                showNavbar: false,
                appbarTitle: 'general.transactions',
            },
            beforeEnter: (to, from, next) => {
                if (to.params.chargeId) {
                    next();
                } else {
                    next(false);
                    router.go(-2);
                }
            },
        },
        {
            path: '/chinese-payment-receipt/:chargeId',
            name: 'chinese-payment-receipt',
            component: ChinesePaymentReceipt,
            props: true,
            meta: {
                requiresAuth: true,
                showNavbar: false,
                appbarTitle: 'general.transactions',
            },
            beforeEnter: (to, from, next) => {
                if (to.params.chargeId) {
                    next();
                } else {
                    next(false);
                    router.go(-2);
                }
            },
        },
        {
            path: '/recurrence/:id',
            name: 'recurrent.details',
            props: true,
            component: RecurrentDetails,
            meta: {
                showNavbar: false,
            },
        },
        {
            path: '/transfer',
            name: 'payout',
            component: Payout,
            props: true,
            meta: {
                requiresAuth: true,
                showNavbar: false,
            },
            beforeEnter: (to, from, next) => {
                if (!to.params.payout) {
                    next(false);
                    router.go(-2);
                    return;
                }
                next();
            },
        },
        {
            path: '/online-payment',
            component: OnlinePayment,
            name: 'online-payment',
            redirect: () => {
                if (store.state.user && !(store.state.user as any).permissions_.master) {
                    return '/online-payment/campaign-history/summary';
                }

                return '/online-payment/remote-campaign';
            },
            beforeEnter: (to, from, next) => {
                if (!store.state.user || !(store.state.user as any).permissions_.payment.enable_campaign) {
                    return next('/payment');
                } else {
                    return next();
                }
            },
            meta: {
                showNavbar: true,
                requiresAuth: true,
                appbarTitle: 'general.campaign',
            },
            children: [
                {
                    path: 'remote-campaign',
                    component: RemoteCampaign,
                    name: 'remote-campaign',
                    props: true,
                    beforeEnter: async (to, from, next) => {
                        if (store.state.user && !(store.state.user as any).permissions_.master) {
                            return next('/payment');
                        }
                        next();
                    },
                },
                {
                    path: 'campaign-history',
                    component: CampaignHistory,
                    name: 'campaign-history',
                    props: true,
                    children: [
                        {
                            path: 'summary',
                            name: 'campaign-history-list',
                            component: CampaignHistoryList,
                        },
                        {
                            path: ':id',
                            name: 'campaign-history-preview',
                            props: true,
                            component: CampaignHistoryPreview,
                        },
                        {
                            path: ':campaignId/orders',
                            name: 'campaign-order-list',
                            props: true,
                            component: CampaignOrderList,
                        },
                        {
                            path: ':campaignId/orders/:orderId',
                            name: 'campaign-order-information',
                            props: true,
                            component: CampaignOrderInformation,
                        },
                    ],
                },
            ],
        },
        {
            path: '/campaigns/:brand/:campaignName/:tracking?',
            component: CampaignLink,
            name: 'campaign-link',
            props: true,
            meta: {
                showNavbar: false,
                showTopbar: false,
                showMerchantTopbar: true,
            },
        },
        {
            path: '/remote',
            component: Remote,
            name: 'remote',
            props: true,
            meta: {
                requiresAuth: true,
                appbarTitle: 'general.remote',
            },
            beforeEnter: async (to, from, next) => {
                if (!store.state.user || !(store.state.user as any).permissions_.payment.enable_remote) {
                    // if (store.state.user) {
                    return next('/payment');
                }
                next();
            },
        },
        {
            path: '/remotes/:id',
            component: RemoteDetails,
            name: 'remote.details',
            props: true,
            meta: {
                requiresAuth: true,
                showNavbar: false,
                appbarTitle: 'general.remote',
            },
            // beforeEnter: async (to, from, next) => {
            //     if (store.state.user && !(store.state.user as any).permissions_.payment.remote) {
            //         return next('/payment');
            //     }
            //     next();
            // },
        },
        {
            path: '/remote/:brand/:remoteId',
            component: RemotePaymentLink,
            name: 'remote-link',
            meta: {
                showNavbar: false,
            },
        },
        {
            path: '/dummy',
            component: Dummy,
            name: 'dummy',
            meta: {
                showNavbar: true,
            },
        },
        {
            path: '/lending',
            component: LendingWebView,
            name: 'lending',
            meta: {
                requiresAuth: true,
                showNavbar: false,
            },
        },
        {
            path: '/settings',
            component: Settings,
            name: 'settings',
            meta: {
                requiresAuth: true,
                showNavbar: false,
                appbarTitle: 'general.settings',
            },
            children: [
                {
                    path: '/settings',
                    name: 'settings',
                    component: SettingsList,
                },
                {
                    path: 'merchant',
                    component: MerchantSettings,
                    meta: {
                        appbarTitle: 'settings.merchant.modify',
                    },
                    children: [
                        {
                            path: '',
                            name: 'settings.merchants',
                            component: MerchantSettingList,
                        },
                        {
                            path: 'campaign_display_name',
                            name: 'settings.merchant.name.campaign',
                            component: MerchantCampaignDisplayName,
                        },
                        {
                            path: 'name',
                            name: 'settings.merchant.modify',
                            component: MerchantName,
                        },
                        {
                            path: 'logo',
                            name: 'settings.merchant.logo.campaign',
                            component: MerchantLogo,
                        },
                    ],
                },
                {
                    path: 'password',
                    name: 'settings.password',
                    component: Password,
                    meta: {
                        appbarTitle: 'settings.password.change',
                    },
                },
                {
                    path: 'printer',
                    component: PrinterSettings,
                    meta: {
                        appbarTitle: 'settings.printer',
                    },
                    children: [
                        {
                            path: '',
                            name: 'settings.printer',
                            component: Printer,
                        },
                        {
                            path: 'connect',
                            name: 'settings.printer.connect',
                            component: PrinterConnect,
                            meta: {
                                showTopbar: false,
                            },
                        },
                        {
                            path: 'test',
                            name: 'settings.printer.test',
                            component: PrinterConnect,
                        },
                        {
                            path: 'advanced',
                            name: 'settings.printer.advanced',
                            component: PrinterConnect,
                        },
                    ],
                },
                {
                    path: 'capture',
                    name: 'settings.capture',
                    component: Capture,
                    meta: {
                        appbarTitle: 'settings.captureNow',
                    },
                },
                {
                    path: 'capture-settings',
                    name: 'settings.capture-settings',
                    component: CaptureSetting,
                    meta: {
                        appbarTitle: 'settings.capture.title',
                    },
                },
                {
                    path: 'new_store',
                    name: 'settings.new_store',
                    component: NewStore,
                    meta: {
                        appbarTitle: 'settings.branch.create',
                    },
                },
                {
                    path: 'new_user',
                    name: 'settings.new_user',
                    component: NewUser,
                    meta: {
                        appbarTitle: 'settings.user.create',
                    },
                },
                {
                    path: 'edit_store',
                    name: 'settings.edit_store',
                    component: EditStore,
                    meta: {
                        appbarTitle: 'settings.branch.modify',
                    },
                },
                {
                    path: 'edit_permission',
                    name: 'settings.edit_permission',
                    component: EditPermission,
                    meta: {
                        appbarTitle: 'settings.permissions.title',
                    },
                },
                {
                    path: 'language_settings',
                    name: 'settings.language_settings',
                    component: LanguageSettings,
                    meta: {
                        appbarTitle: 'settings.language',
                    },
                },
                {
                    path: 'export',
                    component: ExportSettings,
                    meta: {
                        appbarTitle: 'settings.export.label',
                    },
                    children: [
                        {
                            path: '/settings/export',
                            name: 'settings.export',
                            component: ExportSelection,
                        },
                        {
                            path: 'download',
                            name: 'settings.export.download',
                            component: ExportDownload,
                            props: true,
                            beforeEnter: (to, from, next) => {
                                if (to.params.id && to.params.code && to.params.expire_at) {
                                    next();
                                } else {
                                    next(false);
                                    router.go(-2);
                                }
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: '*',
            redirect: {
                path: '/login',
                hash: '',
            },
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        return savedPosition ? savedPosition : { x: 0, y: 0 };
    },
});

let _first_load = true;

router.beforeEach(async (to, from, next) => {
    let _next: RawLocation | undefined;

    try {
        if (to.matched.some((m: any) => m.meta.requiresAuth) && !(await loggedIn())) {
            if (to.name && to.name !== 'login') {
                _next = {
                    name: 'login',
                    params: {
                        next: to.path,
                    },
                };
            } else {
                _next = {
                    name: 'login',
                };
            }
        } else if (await loggedIn()) {
            const resume_page = (store.state.user as any).resume_page;
            const status = await store.dispatch('getMerchantStatus');

            if (resume_page && _first_load) {
                store.dispatch('logUserNavigation', { to: to.name, from: from.name });
                return next(resume_page);
            }

            // check everytime the merchant status is rejected or not, even click previous page. Force redirect to unsuccessful page
            if (status == 'rejected' && to.name != 'unsuccessful') {
                return next('/signup/unsuccessful');
            }

            // block approved merchant go back signup page
            if (status == 'approved' && to.fullPath.split('/')[1] == 'signup') {
                return next('payment');
            }
        }
    } catch (error) {
    } finally {
        _first_load = false;
    }

    store.dispatch('logUserNavigation', { to: to.name, from: from.name });

    next(_next);
});

router.beforeResolve((to, from, next) => {
    try {
        let showNavbar = to.matched
            .slice()
            .reverse()
            .find(m => typeof m.meta.showNavbar !== 'undefined');
        let showTopbar = to.matched
            .slice()
            .reverse()
            .find(m => typeof m.meta.showTopbar !== 'undefined');
        let showMerchantTopbar = to.matched
            .slice()
            .reverse()
            .find(m => typeof m.meta.showMerchantTopbar !== 'undefined');
        let showScrollBtn = to.matched
            .slice()
            .reverse()
            .find(m => typeof m.meta.showScrollBtn !== 'undefined');
        store.commit('showNavbar', showNavbar ? showNavbar.meta.showNavbar : true);
        store.commit('showTopbar', showTopbar ? showTopbar.meta.showTopbar : true);
        store.commit('showMerchantTopbar', showMerchantTopbar ? showMerchantTopbar.meta.showMerchantTopbar : false);
        store.commit('showScrollBtn', showScrollBtn ? showScrollBtn.meta.showScrollBtn : false);
    } catch (error) {}
    next();
});

router.afterEach((to, from) => {
    if (to.name == 'login' || to.name == 'settings') {
        (self as any).zE('webWidget', 'show');
    } else {
        (self as any).zE('webWidget', 'hide');
    }

    // remove local storge if not navigate to remote pages
    if (from.name === 'remote' || (localStorage['remote-data'] && to.name !== 'remote')) {
        localStorage.removeItem('remote-data');
    }

    router.app.$root.$emit('route-change', {
        to,
        from,
    });

    // if (from.name === '/settings') {
    //     router.app.$forceUpdate();
    // }

    // scrollTop();
});

export default router;
