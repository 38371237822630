var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "link-item" }, [
    !_vm.displayCount
      ? _c("div", { staticClass: "copy-link" }, [_vm._v(_vm._s(_vm.link))])
      : _vm._e(),
    _vm.displayCount
      ? _c("div", { staticClass: "copy-link" }, [
          _c("div", [_vm._v(_vm._s(_vm.link))]),
          _c("br"),
          _c("div", [
            _vm._v(
              "(" +
                _vm._s(_vm.$t("campaign.hitRate")) +
                ": " +
                _vm._s(_vm.count) +
                ")"
            )
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "copy-btn" },
      [
        _c("mdc-button", { ref: "copyBtn", staticClass: "button" }, [
          _vm._v(_vm._s(_vm.$t("actions.copy")))
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }