
































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import * as moment from 'moment';
import RemoteCampaignLinkItem from '@/views/remote-campaign/RemoteCampaignLinkItem.vue';

@Component({
    components: {
        RemoteCampaignLinkItem,
    },
})
export default class CampaignHistoryPreview extends Vue {
    @Prop()
    id!: string;

    @Prop()
    displayNameProp!: string;

    @Prop()
    urlNameProp!: string;

    private cancelDialogOpen = false;
    private confirmDialogOpen = false;

    private mdcTextFields!: any;

    private reservedDate = '';
    private reservedTime = '';
    private branchId = '';

    private campaignData: any = null;
    private displayName = '';
    private urlName = '';
    private selectedCountryCode = '';
    private countryCodeList = this.$store.state.countryCodeList;

    get status() {
        if (this.campaignData.status === 'canceled') {
            return 'canceled';
        }

        if (moment(this.campaignData.from) > moment()) {
            return 'inactive';
        }

        if (moment(this.campaignData.to) < moment()) {
            return 'completed';
        }

        return 'active';
    }

    get baseUrl() {
        return `${window.location.origin}/campaigns/${this.urlName}`;
    }

    get date() {
        moment.locale(this.$i18n.locale);
        return {
            from: moment(this.campaignData.from).format('LL'),
            to: moment(this.campaignData.to).format('LL'),
        };
    }

    private mounted() {
        // sort the country code due to language
        if (this.$i18n.locale == 'en') {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.en > b.en ? 1 : -1));
        } else {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.phone_code >= b.phone_code ? 1 : -1));
        }
    }
    public async created() {
        await this.getCampaign();
    }

    public async getCampaign() {
        try {
            if (!this.displayNameProp || !this.urlNameProp) {
                const data = await this.$store.dispatch('retreiveMerchantCampaignNames');
                this.displayName = data.displayName;
                this.urlName = data.urlName;
            } else {
                this.displayName = this.displayNameProp;
                this.urlName = this.urlNameProp;
            }

            this.campaignData = await this.$store.dispatch('retreiveCampaignByCampaignId', {
                campaignId: this.id,
            });
        } catch (error) {
            this.$root.$emit('error', error);
        }
    }

    public beforeUpdate() {
        this.initMDCTextFields();
    }

    private initMDCTextFields() {
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }

    protected toggleCancelDialog() {
        return (this.cancelDialogOpen = true);
    }

    protected async cancel() {
        try {
            await this.$store.dispatch('cancelCampaign', this.id);
            return (this.confirmDialogOpen = true);
        } catch (error) {
            this.$root.$emit('error', error);
        }
    }
}
