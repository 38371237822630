var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-edit-item" }, [
    _c(
      "div",
      { staticClass: "customer-list-item", class: { editing: _vm.editing } },
      [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { ref: "title", staticClass: "mdc-typography--headline6" },
            [_vm._v(_vm._s(_vm.$t("customer.title")) + " " + _vm._s(_vm.index))]
          ),
          _c(
            "div",
            { staticClass: "row-right-section" },
            [
              !_vm.customer.sent && !_vm.editing
                ? _c(
                    "mdc-button",
                    {
                      staticClass: "edit-button",
                      attrs: { dense: "", type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.edit($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("actions.edit")) +
                          "\n                "
                      )
                    ]
                  )
                : _vm._e(),
              !_vm.customer.sent && !_vm.editing
                ? _c(
                    "mdc-button",
                    {
                      staticClass:
                        "edit-button edit-button--edit edit-button--red-color",
                      attrs: { dense: "", type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.deleteCustomer($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("actions.delete")))]
                  )
                : _vm._e(),
              !_vm.customer.sent && _vm.editing
                ? _c(
                    "mdc-button",
                    {
                      staticClass: "edit-button edit-button--editing",
                      attrs: { dense: "", disabled: "", type: "button" }
                    },
                    [_vm._v(_vm._s(_vm.$t("actions.editing")) + "...")]
                  )
                : _vm._e(),
              _vm.customer.sent
                ? _c(
                    "mdc-button",
                    {
                      staticClass:
                        "edit-button edit-button--edit edit-button--red-color",
                      attrs: { dense: "", type: "button" }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("actions.sent")) +
                          "\n                "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ]),
        _vm.hasData
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.editing,
                    expression: "!editing"
                  }
                ],
                staticClass: "row"
              },
              [
                _c("div", [_vm._v(_vm._s(_vm.customer.name))]),
                _c("div", { staticClass: "row-right-section" }, [
                  _vm._v(
                    _vm._s(
                      "" +
                        _vm.customer.countryCode.phone_code +
                        _vm.customer.phone
                    )
                  )
                ])
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editing,
                expression: "editing"
              }
            ],
            staticClass: "fields"
          },
          [
            _vm._l(_vm.fields, function(f, k) {
              return [
                k != "countryCode"
                  ? _c(
                      "div",
                      { key: k },
                      [
                        _c("mdc-textfield", {
                          key: k,
                          attrs: {
                            label: _vm.$t(f.label) + _vm.$t(f.postfix),
                            fulliwdth: "",
                            outline: "",
                            autocorrect: "off",
                            spellcheck: "false",
                            autocomplete: "off"
                          },
                          model: {
                            value: _vm.customerEditingInfo[k],
                            callback: function($$v) {
                              _vm.$set(_vm.customerEditingInfo, k, $$v)
                            },
                            expression: "customerEditingInfo[k]"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", { key: k }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                        },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.customerEditingInfo.countryCode,
                                  expression: "customerEditingInfo.countryCode"
                                }
                              ],
                              staticClass: "mdc-text-field__input",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.customerEditingInfo,
                                    "countryCode",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { disabled: "" },
                                  domProps: { value: null }
                                },
                                [_vm._v(_vm._s(_vm.$t("customer.countryCode")))]
                              ),
                              _vm._l(_vm.countryCodeList, function(code) {
                                return _c(
                                  "option",
                                  { key: code.en, domProps: { value: code } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$i18n.locale == "zh_HK"
                                          ? code.zh
                                          : _vm.$i18n.locale == "zh_CN"
                                            ? code.cn
                                            : code.en
                                      ) +
                                        " " +
                                        _vm._s("(" + code.phone_code + ")")
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _c(
                            "label",
                            {
                              staticClass:
                                "mdc-floating-label mdc-floating-label--float-above"
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("customer.countryCode")) +
                                  _vm._s(_vm.$t("customer.required"))
                              )
                            ]
                          ),
                          _c("div", { staticClass: "mdc-notched-outline" }, [
                            _c("svg", [
                              _c("path", {
                                staticClass: "mdc-notched-outline__path"
                              })
                            ])
                          ]),
                          _c("div", {
                            staticClass: "mdc-notched-outline__idle"
                          })
                        ]
                      )
                    ])
              ]
            })
          ],
          2
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editing,
                expression: "editing"
              }
            ],
            staticClass: "footer1"
          },
          [
            _c(
              "mdc-button",
              {
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.save($event)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("actions.save")))]
            ),
            _vm.hasData
              ? _c(
                  "mdc-button",
                  {
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.cancel($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                )
              : _c(
                  "mdc-button",
                  {
                    staticClass: "red-button",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.deleteCustomer($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("actions.delete")))]
                )
          ],
          1
        )
      ]
    ),
    _vm.isTestingMode
      ? _c(
          "button",
          {
            staticClass: "mdc-button",
            on: {
              click: [
                function($event) {
                  _vm.autofill(true)
                },
                function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              ]
            }
          },
          [_vm._v("Auto fill")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }