export const AUTH = {
    INVALID_EMAIL_FORAMT: '無効なメールです。',
    INVALID_STATEMENTDESCRIPTOR_FORMAT_TOO_SHORT: '明細書表記は5文字以上にして下さい。',
    INVALID_STATEMENTDESCRIPTOR_FORMAT_TOO_LONG: '明細書表記は22文字以下にして下さい。',
    INVALID_NAME_FORMAT: 'ユーザーネームのフォーマットが無効です。',
    INVALID_COMPANY_NAME_FORMAT: '会社名のフォーマットが無効です。',
    INVALID_PASSWORD_FORMAT: 'パスワードのフォーマットが無効です。パスワードは8文字以上にして下さい。',
    CONFIRM_FAILURE: 'パスワードが一致していません。',
    INVALID_MOBILE_FORMAT: '無効な電話番号です。',
    INVALID_VERIFICATION_CODE: '無効な検証コードです。',
    INVALID_HKID: '無効なIDカード番号です。',
    INVALID_SUPPORTING_DOCUMENT: '無効な補足文書です。',
    ADDRESS_TOO_LONG: '住所また所在地は200文字以下にして下さい。',
    DESCRIPTION_TOO_LONG: '業務内容は200文字以下にして下さい。',
    INVALID_WEBSITE_FORMAT: '無効な公式サイトです。',
    EMPTY_FIELD: '必須な情報を入力して下さい。',
    NO_SELECTED_ACCOUNT: '支店をまだ選択しません。',
    NO_SELECTED_USER: 'ユーザーをまだ選択しません。',
    INCORRECT_CONFIRMED_VALUE: '確認できません。',
    DUPLICATED_OLD_VALUE: '新しい情報は前の情報と同じです。',
    DUPLICATED_MERCHANT_NAME: '会社の名前は既に登録済みです。',
    EMAIL_USED: 'メールは既に登録済みです。',
    SIGNUP_SUCCESS: '登録しました。',
    LOGIN_SUCCESS: 'ログインしました。',
    ACTIVATE_USER_SUCCESS: 'アカウントが認証されました。',
    INCORRECT_CREDENTIALS: 'パスワードが違います。',
    HOLDER_NAME_TOO_LONG: '持ち主の名前は200文字以下にして下さい。',
    INVALID_HOLDER_NAME: '持ち主の名前のフォーマットが無効です。',
    NOT_ACCEPTING_TOS: 'Jarvix Payの利用規約に同意します。',
    DOCUMENT_ERROR: 'jpegまたpngのフォーマットでアップロードして下さい。',
    LOGIN_AGAIN: 'もう一度ログインして下さい。',
};
