















































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { stripe } from '@/store';
import styles from '@/constants/styles';
import { STRIPE_CODE, STRIPE_DECLINE_CODE } from '@/constants/ERROR';
import AUTH_MSG from '@/constants/AUTH';
import { isPlatform } from '@/utils/platform';
import { IMaskComponent } from 'vue-imask';
import VirtualCard from '@/components/payment/VirtualCard.vue';
import moment from '@/utils/moment_HK_en';

interface Token {
    id: string;
    type: string;
    card: {
        brand: string;
        exp_month: number;
        exp_year: number;
        last4: string;
        name: string;
    };
}

@Component({
    components: {
        'imask-input': IMaskComponent,
        VirtualCard,
    },
})
export default class CardChange extends Vue {
    @Prop()
    recurrenceId!: string;

    @Prop()
    remainingDuration!: number;

    private cssSrc = 'https://fonts.googleapis.com/css?family=Muli:400';
    private elements: any = null;
    private invalidCardMessage = '';
    private invalidExpiryMessage = '';
    private invalidCvcMessage = '';
    private errors: string[] = [];

    private holderName = '';
    private onHolderNamePrepare = (s: String) => s.toUpperCase();
    private holderNameMask = /^[A-Z0-9 ]*$/;
    private token!: Token;

    private stripeElements!: {
        cardNumber: any;
        cardExpiry: any;
        cardCvc: any;
    };

    public mounted() {
        this.initStripeElements();
    }

    public async submitCardChange() {
        this.errorCheck();

        if (this.errors.length || this.invalidCardMessage || this.invalidExpiryMessage || this.invalidCvcMessage || !this.holderName) {
            return;
        }

        await this.getToken();

        const expired = this.checkExpiryDate();

        if (!expired) {
            await this.changeCard();
        }
    }

    private ObjectValues = (x: Object): any[] => Object.keys(x).map(i => (x as any)[i]);

    private closeCardChange() {
        this.reset();
        this.$emit('closeCardChange');
    }

    private checkExpiryDate() {
        const earliestPossibleExpiryDate = moment()
            .add(this.remainingDuration + 1, 'M')
            .startOf('month');

        const cardExpiryData = moment(`${this.token.card.exp_month}/${this.token.card.exp_year}`, 'MM/YYYY');

        if (cardExpiryData.isBefore(earliestPossibleExpiryDate, 'M')) {
            this.$root.$emit('error', {
                message: `${this.$t('general.card.error.invalidExpiryDate')},${this.$t('recurrent.warningMessage', [
                    earliestPossibleExpiryDate.format('MM/YYYY'),
                ])}`,
            });
            return true;
        }

        return false;
    }

    private reset() {
        this.errors = [];
        this.holderName = '';
        this.ObjectValues(this.stripeElements).forEach((element: any) => {
            element.clear();
        });
        return;
    }

    private getToken() {
        return new Promise((resolve, reject) => {
            this.$store
                .dispatch('createToken', {
                    purpose: this.stripeElements.cardNumber,
                    tokenInformation: {
                        currency: 'hkd',
                        name: this.holderName,
                        address_country: 'hk',
                    },
                })
                .then(res => {
                    let t = res.token,
                        c = t.card;
                    this.token = {
                        id: t.id,
                        type: t.type,
                        card: {
                            brand: c.brand,
                            exp_month: c.exp_month,
                            exp_year: c.exp_year,
                            last4: c.last4,
                            name: c.name,
                        },
                    };
                    resolve();
                })
                .catch(err => {
                    return this.$root.$emit('error', err);
                });
        });
    }

    private changeCard() {
        this.$store
            .dispatch('changeRecurrenceCard', {
                recurrenceId: this.recurrenceId,
                sourceToken: (this.token as Token).id,
            })
            .then(() => {
                this.$emit('refreshRecurrent');
                this.closeCardChange();
                return;
            })
            .catch(err => {
                return this.$root.$emit('error', err);
            });
    }

    private getCompStyle = (el: any, props: any) => {
        const elComp = window.getComputedStyle(el, null);
        return props
            .map((key: any) => elComp[key])
            .reduce((acc: any, cur: any, i: number) => {
                acc[props[i]] = cur;
                return acc;
            }, {});
    };

    private async initStripeElements() {
        this.elements = (await stripe).elements({
            fonts: [
                {
                    cssSrc: this.cssSrc,
                },
            ],
            locale: 'en',
        });

        const containerStyles = this.getCompStyle((this.$refs.holderName as Vue).$el, [
            'height',
            'paddingTop',
            'paddingBottom',
            'fontFamily',
            'fontSize',
            'fontWeight',
            'letterSpacing',
            'webkitFontSmoothing',
            'color',
        ]);

        const color_focus = (containerStyles as any).color;
        const color_placeholder = 'rgba(52, 62, 87, 0.3)';
        const elementStyles = {
            base: {
                color: color_focus,

                ':focus': {
                    color: color_focus,
                },

                '::placeholder': {
                    color: color_placeholder,
                },

                ':focus::placeholder': {
                    color: color_placeholder,
                },
            },
            invalid: {
                color: styles.colors.error,
                ':focus': {
                    color: styles.colors.error,
                },
                '::placeholder': {
                    color: styles.colors.error,
                },
            },
        };

        (elementStyles.base as any).fontFamily = (containerStyles as any).fontFamily;
        (elementStyles.base as any).fontSize = (containerStyles as any).fontSize;
        (elementStyles.base as any).fontWeight = (containerStyles as any).fontWeight;
        (elementStyles.base as any).letterSpacing = (containerStyles as any).letterSpacing;
        (elementStyles.base as any).fontSmoothing = (containerStyles as any).webkitFontSmoothing;
        // (elementStyles.base as any).lineHeight = parseFloat((containerStyles as any).height) - parseFloat((containerStyles as any).paddingTop) - parseFloat((containerStyles as any).paddingBottom) + 'px';

        const elementClasses = {
            focus: 'mdc-text-field--focused',
            empty: 'empty',
            invalid: 'invalid',
        };

        this.stripeElements = {
            cardNumber: this.elements.create('cardNumber', {
                style: {
                    ...elementStyles,
                    ...{
                        base: {
                            ...elementStyles.base,
                            ...(!(isPlatform(window, 'hybrid') && isPlatform(window, 'ios')) && { lineHeight: '56px' }),
                        },
                    },
                },
                classes: elementClasses,
            }),
            cardExpiry: this.elements.create('cardExpiry', {
                style: elementStyles,
                classes: elementClasses,
            }),
            cardCvc: this.elements.create('cardCvc', {
                style: elementStyles,
                classes: elementClasses,
            }),
        };

        this.stripeElements.cardNumber.mount('#payment-card-number');
        this.stripeElements.cardExpiry.mount('#payment-card-expiry');
        this.stripeElements.cardCvc.mount('#payment-card-cvc');

        this.stripeElements.cardNumber.on('change', (event: any) => {
            if (event.error) {
                if (event.error.type === 'validation_error' && STRIPE_CODE[event.error.code]) {
                    this.invalidCardMessage = this.$t(`errors.STRIPE_CODE.` + event.error.code) as string;
                } else if (event.error.decline_code && STRIPE_DECLINE_CODE[event.error.decline_code]) {
                    this.invalidCardMessage = this.$t(`errors.STRIPE_DECLINE_CODE.` + event.error.decline_code) as string;
                } else {
                    this.invalidCardMessage = this.$t(`errors.` + event.error.message) as string;
                }
            } else {
                this.invalidCardMessage = '';
            }
        });

        this.stripeElements.cardExpiry.on('change', (event: any) => {
            if (event.error) {
                if (event.error.type === 'validation_error' && STRIPE_CODE[event.error.code]) {
                    this.invalidExpiryMessage = this.$t(`errors.STRIPE_CODE.` + event.error.code) as string;
                } else if (event.error.decline_code && STRIPE_DECLINE_CODE[event.error.decline_code]) {
                    this.invalidExpiryMessage = this.$t(`errors.STRIPE_DECLINE_CODE.` + event.error.decline_code) as string;
                } else {
                    this.invalidExpiryMessage = this.$t(`errors.` + event.error.message) as string;
                }
            } else {
                this.invalidExpiryMessage = '';
            }
        });

        this.stripeElements.cardCvc.on('change', (event: any) => {
            if (event.error) {
                if (event.error.type === 'validation_error' && STRIPE_CODE[event.error.code]) {
                    this.invalidCvcMessage = this.$t(`errors.STRIPE_CODE.` + event.error.code) as string;
                } else if (event.error.decline_code && STRIPE_DECLINE_CODE[event.error.decline_code]) {
                    this.invalidCvcMessage = this.$t(`errors.STRIPE_DECLINE_CODE.` + event.error.decline_code) as string;
                } else {
                    this.invalidCvcMessage = this.$t(`errors.` + event.error.message) as string;
                }
            } else {
                this.invalidCvcMessage = '';
            }
        });

        this.stripeElements.cardCvc.on('ready', () => {
            this.$store.commit('isLoading', false);
        });

        // Object.values(this.stripeElements)
        //     .concat([(this.$refs.holderName as Vue).$el])
        //     .map(s => s.addEventListener('change', () => (this.token = null)));
    }

    private errorCheck() {
        this.errors = [];

        if (this.holderName.length > 200) {
            this.errors.push(AUTH_MSG.HOLDER_NAME_TOO_LONG);
        }

        if (this.holderName.length < 0) {
            this.errors.push(AUTH_MSG.INVALID_HOLDER_NAME);
        }

        if (!this.holderName) {
            this.errors.push(this.$t('general.card.error.missingCardHolderName') as string);
        }

        if ((this.$refs.paymentCardNumber as HTMLElement).classList.contains('empty')) {
            this.errors.push(this.$t('general.card.error.missingCardNumber') as string);
        }

        if ((this.$refs.paymentCardExpiry as HTMLElement).classList.contains('empty')) {
            this.errors.push(this.$t('general.card.error.missingExpiryDate') as string);
        }

        if ((this.$refs.paymentCardCVC as HTMLElement).classList.contains('empty')) {
            this.errors.push(this.$t('general.card.error.missingCvc') as string);
        }
    }
}
