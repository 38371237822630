var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "campaign-link-item" }, [
    _c("div", { staticClass: "list-item" }, [
      _c("div", { staticClass: "link" }, [
        _c("p", { staticClass: "prefix" }, [
          _vm._v(
            "/" +
              _vm._s(
                _vm.campaignName
                  ? _vm.campaignName.toLowerCase().replace(/[ -]/g, "‑")
                  : "campaign"
              ) +
              "/"
          )
        ]),
        _c(
          "div",
          {
            staticClass: "mdc-textfield-wrapper",
            staticStyle: { width: "100%" }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "mdc-textfield mdc-text-field mdc-text-field--upgraded mdc-text-field--fullwidth mdc-text-field--with-trailing-icon"
              },
              [
                _c("imask-input", {
                  staticClass: "mdc-text-field__input",
                  attrs: {
                    mask: _vm.mask,
                    prepare: _vm.prepare,
                    type: "text",
                    autocorrect: "off",
                    spellcheck: "false",
                    autocomplete: "off"
                  },
                  model: {
                    value: _vm.name_,
                    callback: function($$v) {
                      _vm.name_ = $$v
                    },
                    expression: "name_"
                  }
                }),
                _c(
                  "i",
                  {
                    staticClass: "mdc-text-field__icon",
                    attrs: { tabindex: "0", role: "button" }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "16",
                          height: "16",
                          viewBox: "0 0 24 24"
                        },
                        on: { click: _vm.deleteLink }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3a.996.996 0 0 1-1.41 0L12 13.41 9.11 16.3a.996.996 0 1 1-1.41-1.41L10.59 12 7.7 9.11A.996.996 0 1 1 9.11 7.7L12 10.59l2.89-2.89a.996.996 0 1 1 1.41 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z",
                            opacity: "0.54"
                          }
                        })
                      ]
                    )
                  ]
                ),
                _c("div", { staticClass: "mdc-line-ripple" })
              ],
              1
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }