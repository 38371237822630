
















import { Component, Vue, Prop } from 'vue-property-decorator';
import { sleep } from '@/store';
const animation_duration = 120;

@Component
export default class Verification extends Vue {
    private modalShow: boolean = false;

    private token: string = '';
    private errors: string[] = [];

    private reset() {
        this.token = '';
    }

    private verify() {
        this.errors = [];

        console.log(this.token);
        if (this.token.length !== 6) {
            this.errors.push(this.$t(`AUTH.INVALID_VERIFICATION_CODE`) as any);
            // this.errors.push('驗證碼無效。');
        }

        if (this.errors.length) {
            return;
        } else
            this.$store
                .dispatch('activate', this.token)
                .then(() => {
                    this.$store.commit('loggedIn', undefined);
                    this.$router.push({ name: 'company' });
                })
                .catch((error: any) => {
                    return this.$root.$emit('error', error);
                });
    }

    public async onCancel() {
        await sleep(0);
        this.modalShow = true;
        await sleep(10);
        this.modalShow = true;
    }

    public show() {
        this.modalShow = true;

        setTimeout(() => {
            (this.$refs.input_field as HTMLElement).focus();
        }, animation_duration);
    }
}
