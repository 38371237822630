







import { Component, Prop, Vue } from 'vue-property-decorator';
import { MDCDialog } from '@material/dialog';
import PLATFORM_USER_AGREEMENT_HTML from '@/constants/PLATFORM_USER_AGREEMENT_HTML';

@Component
export default class PlatformAgreement extends Vue {
    private agreementHTML = PLATFORM_USER_AGREEMENT_HTML;
    private modalOpen: boolean = false;
    public dialog!: any;

    public show() {
        this.modalOpen = true;
    }
}
