export const general = {
    payment: 'Payment',
    direct: 'Direct',
    recurrent: 'Recurrent',
    remote: 'Remote',
    campaign: 'Promotion',
    online: 'Online',
    dashboard: 'Dashboard',
    payout: 'Payout',
    transactions: 'Transactions',
    settings: 'Settings',
    notifications: 'Notifications',
    planning: 'Planning',
    summary: 'Summary',
    keyword: 'Keyword',
    searchCategory: {
        title: 'Category',
        all: 'All',
        description: 'Description',
        receiptNumber: 'Receipt Number',
        status: 'Status',
        type: 'Type',
        cashflow: 'Cashflow',
    },
    paymentMethods: {
        label: 'Payment Type',
        qrPayment: 'QR Code Payment',
        card: 'Credit Card',
        wechat: 'WeChat Pay',
        alipay: 'Alipay',
    },
    card: {
        label: 'Credit Card',
        number: 'Card Number',
        holderName: 'Card Holder Name',
        expiryDate: 'Expiry Date',
        info: 'Credit card information',
        error: {
            missingCardNumber: 'Please fill in Card Number.',
            missingExpiryDate: 'Please fill in Expiry Date.',
            missingCvc: 'Please fill in CVC',
            missingCardHolderName: 'PLease fill in Cardholder Name.',
            invalidExpiryDate: 'The provided credit card expires during recurrent charge duration.',
        },
    },
    paymentDetails: {
        quantity: 'Quantity',
        amount: 'Amount',
        description: 'Description',
        remoteMessage: 'Message',
        totalAmount: 'Total Amount',
    },
    date: {
        start: 'Start Date',
        end: 'End Date',
    },
    history: {
        title: 'History',
        payment: 'Payment History',
        expectedIncome: 'Expected Income',
        expectedCount: 'Expected Count',
        chargeCount: 'Charge count',
    },
    login: {
        agreementHeader: 'By signing in, you understand and agree with our',
        privacy: 'Privacy Policy',
        tnc: 'Terms of Service',
    },
    thisStore: 'This Store',
    warningMsg: 'Please double check your information. Once submitted, it cannot be changed.',
    confirmInfo: 'Comfirm information',
    confirmMsg: 'Are you sure you entered the information correctly?',
    wechatPayDisable: 'WeChat payment has not yet been opened , please contact the account manager.(3618 7750 / 6010 5244)',
    qrPaymentDisable: 'WeChat / AliPay payment has not yet been opened , please contact the account manager.(3618 7750 / 6010 5244)',
};
