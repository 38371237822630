export const status = {
    title: 'ステータス',
    all: '全部',
    succeeded: '成功',
    authorized: '認可済み',
    unauthorized: '認可キャンセル済み',
    pending: '処理中',
    paying: '支払い中',
    failed: '失敗',
    refunded: '返金済み',
    canceled: 'キャンセル済み',
    arrived: '入金済み',
    inTransit: '送金中',
    active: '問題なし',
    inactive: '問題あり',
    completed: '終了',
    paused: '停止中',
    agentPromotion: '代理プロモーション',
    free: '無料',
    success: '成功',
    failure: '失敗',
};
