var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "mdc-top-app-bar" }, [
    _c("div", { staticClass: "mdc-top-app-bar__row" }, [
      _c(
        "section",
        {
          staticClass:
            "mdc-top-app-bar__section mdc-top-app-bar__section--align-start"
        },
        [
          _c("m-ripple", { attrs: { unbounded: "" } }, [
            _c(
              "a",
              {
                staticClass: "mdc-top-app-bar__navigation-icon",
                class:
                  _vm.$route.params.remoteId || _vm.$route.params.campaignName
                    ? "disabled"
                    : "",
                on: {
                  click: function($event) {
                    _vm.$route.path.includes("settings")
                      ? _vm.$router.go(-1)
                      : (_vm.$route.path == "/signup" ||
                          _vm.$route.path == "/signup/" ||
                          !_vm.$route.path.includes("signup")) &&
                        _vm.$router.push(
                          _vm.showSettings
                            ? {
                                name:
                                  _vm.$route.name === "settings"
                                    ? "payment"
                                    : "settings"
                              }
                            : { name: "login" }
                        )
                  }
                }
              },
              [
                !_vm.$route.path.includes("settings")
                  ? _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 288 288"
                        }
                      },
                      [
                        _c("circle", {
                          attrs: {
                            cx: "144",
                            cy: "144",
                            r: "144",
                            fill: "#3c339b"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            fill: "#fff",
                            d: "M144 40v69.333l60.044-34.666L144 40z"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            fill: "#fff",
                            opacity: ".77",
                            d: "M83.956 74.667L144 109.333V40L83.956 74.667z"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            fill: "#fff",
                            opacity: ".62",
                            d: "M204.044 74.667L144 109.333 204.044 144V74.667z"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            fill: "#fff",
                            opacity: ".15",
                            d: "M83.956 144v69.333L144 178.667 83.956 144z"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            fill: "#fff",
                            opacity: ".24",
                            d: "M83.956 144L144 178.667v-69.334L83.956 144z"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            fill: "#fff",
                            opacity: ".38",
                            d: "M144 109.333v69.334L204.044 144 144 109.333z"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            fill: "#fff",
                            opacity: ".09",
                            d: "M83.956 213.333L144 248v-69.333l-60.044 34.666z"
                          }
                        })
                      ]
                    )
                  : _c(
                      "svg",
                      {
                        staticStyle: { width: "24px", height: "24px" },
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "24",
                          height: "24",
                          viewBox: "0 0 24 24"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1z"
                          }
                        })
                      ]
                    )
              ]
            )
          ]),
          _c(
            "span",
            {
              staticClass: "mdc-top-app-bar__title",
              class: { "fw-550": /[^\x00-\x7F]+/.test(_vm.title) },
              attrs: { id: "app-title" }
            },
            [_vm._v(_vm._s(_vm.title))]
          )
        ],
        1
      ),
      _c(
        "section",
        {
          staticClass:
            "mdc-top-app-bar__section mdc-top-app-bar__section--align-end",
          attrs: { role: "toolbar" }
        },
        [
          _c("m-ripple", { attrs: { unbounded: "" } }, [
            _vm.$route.path.includes("signup")
              ? _c(
                  "a",
                  {
                    staticClass: "mdc-top-app-bar__navigation-logout",
                    on: { click: _vm.logout }
                  },
                  [_vm._v(_vm._s(_vm.$t("actions.logout")))]
                )
              : _c(
                  "a",
                  {
                    staticClass: "mdc-top-app-bar__navigation-icon",
                    style: { pointerEvents: _vm.showSettings ? "all" : "none" },
                    on: {
                      click: function($event) {
                        _vm.showSettings &&
                          _vm.$router.push({ name: "settings" })
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticStyle: { width: "24px", height: "24px" },
                        style: { opacity: _vm.showSettings ? 1 : 0 },
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "24",
                          height: "24",
                          viewBox: "0 0 24 24"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65A.488.488 0 0 0 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"
                          }
                        })
                      ]
                    )
                  ]
                )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }