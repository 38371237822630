export const customer = {
    title: 'Customer',
    name: 'Name',
    countryCode: 'Country Code',
    phone: 'Phone',
    email: 'Email',
    address: 'Address',
    remarks: 'Remarks',
    refNo: 'Reference Number',
    optional: ' (Optional)',
    required: ' (Required)',
    new: 'Create new client',
    currentClient: 'Current client',
    newClient: 'New client',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    age: 'Age',
    orderDate: 'Order Date',
    orderTime: 'Order Time',
    specialRequest: 'Client Special Request',
};
