var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mdc-layout-grid__inner", attrs: { id: "product-summary" } },
    [
      _c(
        "div",
        {
          staticClass:
            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet payment-summary",
          staticStyle: { flex: "1 1 auto" }
        },
        [
          _c("div", { staticClass: "payment-summary__wrapper-inner" }, [
            _c(
              "div",
              { staticClass: "payment-summary__inner" },
              [
                _c("div", { staticClass: "mdc-typography--headline6" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("general.summary")))])
                ]),
                _c("div", { staticClass: "mdc-typography--body2" }, [
                  _vm._v(_vm._s(_vm.$t("general.warningMsg")))
                ]),
                _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                  _vm._v(_vm._s(_vm.$t("general.paymentDetails.amount")))
                ]),
                _c("div", { staticClass: "mdc-typography--body1" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("currencySymbolDisplay")(_vm.chargeData.currency)
                    ) + _vm._s(_vm.chargeData.amount)
                  )
                ]),
                _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                  _vm._v(_vm._s(_vm.$t("general.paymentDetails.description")))
                ]),
                _c("div", { staticClass: "mdc-typography--body2" }, [
                  _vm._v(_vm._s(_vm.chargeData.description || "—"))
                ]),
                _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                  _vm._v(_vm._s(_vm.$t("general.paymentDetails.remoteMessage")))
                ]),
                _vm._l(_vm.chargeData.message.split("\n"), function(
                  line,
                  index
                ) {
                  return [
                    _c(
                      "div",
                      { key: index, staticClass: "mdc-typography--body2" },
                      [_vm._v(_vm._s(line))]
                    )
                  ]
                })
              ],
              2
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }