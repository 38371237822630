export const receipt = {
    number: '收據編號',
    copy: '收據副本',
    refundConfirmation: '確認退款',
    cancelRecurrentFailedMsg: '取消月付失敗',
    refundFailed: '退款失敗。',
    refundSucceeded: '成功退款。',
    refundOnceOnlyMsg: '您只能進行一次退款，確認後將不能取消。',
    refundAndCancelRecurrentButton: '退款並取消月付',
    notEnoughBalanceMsg: '餘額不足，請聯絡JARVIX PAY。',
    printer: {
        printingReceipt: '正在列印收據',
        failedToPrint: '印單失敗',
        checkPrint: '請檢查打印機連接或稍候再試。',
    },
};
