export const actions = {
    accept: '确认',
    back: '返回',
    backOrEdit: '返回 / 更改资料',
    create: '建立',
    copy: '复制',
    cancel: '取消',
    change: '更改',
    confirm: '确认',
    createLink: '生成连结',
    delete: '删除',
    edit: '更改',
    editing: '更改中',
    save: '储存',
    send: '发送',
    sent: '已发送',
    submit: '提交',
    preview: '预览',
    print: '印单',
    noMore: '没有更多',
    loadMore: '载入更多...',
    next: '下一步',
    pay: '支款',
    qrPay: '二维码支付',
    reset: '重置',
    remind: '提醒',
    refund: '退款',
    register: '立即登记',
    login: '登入',
    logout: '登出',
    loading: '载入中...',
    upload: '上传文件',
    processing: '处理中...',
    removeAll: '清除全部',
    reupload: '重新上传文件',
    retake: '重新拍摄文件',
    takePhoto: '拍摄文件',
    noYetUploaded: '收据还未上传，请按「上传」按钮。',
};
