var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "app-page-activity mdc-layout-grid app-page-activity--active",
      attrs: { id: "merchant-campaign-display-name-setting" }
    },
    [
      _c("h4", { staticClass: "heading" }, [
        _vm._v(_vm._s(_vm.$t("settings.merchant.name.campaign")))
      ]),
      _c("p", { staticClass: "prompt" }, [
        _vm._v(_vm._s(_vm.$t("settings.merchant.modifyCampaignWarning")))
      ]),
      _c(
        "form",
        {
          staticClass: "mdc-layout-grid__inner",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.type,
                      expression: "type"
                    }
                  ],
                  staticClass: "mdc-text-field__input",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.type = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { disabled: "" }, domProps: { value: null } },
                    [_vm._v(_vm._s(_vm.$t("general.searchCategory.title")))]
                  ),
                  _c("option", { attrs: { value: "display" } }, [
                    _vm._v(_vm._s(_vm.$t("settings.merchant.display")))
                  ]),
                  _c("option", { attrs: { value: "url" } }, [
                    _vm._v(_vm._s(_vm.$t("settings.merchant.url")))
                  ])
                ]
              ),
              _c(
                "label",
                {
                  staticClass: "mdc-floating-label",
                  attrs: { for: "keyword" }
                },
                [_vm._v(_vm._s(_vm.$t("general.searchCategory.title")))]
              ),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _vm.type === "display"
                ? _c("input", {
                    staticClass: "mdc-text-field__input",
                    staticStyle: { "pointer-events": "none" },
                    attrs: {
                      type: "text",
                      autocomplete: "off",
                      autocorrect: "off",
                      spellcheck: "false"
                    },
                    domProps: { value: _vm.name.display.oldName }
                  })
                : _vm._e(),
              _vm.type === "url"
                ? _c("input", {
                    staticClass: "mdc-text-field__input",
                    staticStyle: { "pointer-events": "none" },
                    attrs: {
                      type: "text",
                      autocomplete: "off",
                      autocorrect: "off",
                      spellcheck: "false"
                    },
                    domProps: { value: _vm.name.url.oldName }
                  })
                : _vm._e(),
              _c(
                "label",
                {
                  staticClass:
                    "mdc-floating-label mdc-floating-label--float-above"
                },
                [_vm._v(_vm._s(_vm.$t("settings.merchant.current")))]
              ),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _vm.type === "display"
                ? _c("imask-input", {
                    staticClass: "mdc-text-field__input test",
                    attrs: {
                      type: "text",
                      autocorrect: "off",
                      spellcheck: "false",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.name.display.newName,
                      callback: function($$v) {
                        _vm.$set(_vm.name.display, "newName", $$v)
                      },
                      expression: "name.display.newName"
                    }
                  })
                : _vm._e(),
              _vm.type === "url"
                ? _c("imask-input", {
                    staticClass: "mdc-text-field__input test",
                    attrs: {
                      mask: _vm.mask,
                      prepare: _vm.prepare,
                      type: "text",
                      autocorrect: "off",
                      spellcheck: "false",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.name.url.newName,
                      callback: function($$v) {
                        _vm.$set(_vm.name.url, "newName", $$v)
                      },
                      expression: "name.url.newName"
                    }
                  })
                : _vm._e(),
              _c("label", { staticClass: "mdc-floating-label" }, [
                _vm._v(_vm._s(_vm.$t("settings.merchant.new")))
              ]),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _vm.type === "display"
                ? _c("imask-input", {
                    staticClass: "mdc-text-field__input",
                    attrs: {
                      type: "text",
                      autocorrect: "off",
                      spellcheck: "false",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.name.display.confirmedName,
                      callback: function($$v) {
                        _vm.$set(_vm.name.display, "confirmedName", $$v)
                      },
                      expression: "name.display.confirmedName"
                    }
                  })
                : _vm._e(),
              _vm.type === "url"
                ? _c("imask-input", {
                    staticClass: "mdc-text-field__input",
                    attrs: {
                      mask: _vm.mask,
                      prepare: _vm.prepare,
                      type: "text",
                      autocorrect: "off",
                      spellcheck: "false",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.name.url.confirmedName,
                      callback: function($$v) {
                        _vm.$set(_vm.name.url, "confirmedName", $$v)
                      },
                      expression: "name.url.confirmedName"
                    }
                  })
                : _vm._e(),
              _c("label", { staticClass: "mdc-floating-label" }, [
                _vm._v(_vm._s(_vm.$t("settings.merchant.confirm")))
              ]),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
            },
            [
              _c(
                "div",
                {
                  staticClass: "mdc-button",
                  on: {
                    click: function($event) {
                      _vm.$router.go(-1)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.back")))]
              ),
              _c(
                "button",
                {
                  staticClass: "mdc-fab mdc-fab--extended",
                  attrs: { type: "submit", "aria-label": "更改" }
                },
                [
                  _c("span", { staticClass: "mdc-fab__label" }, [
                    _vm._v(_vm._s(_vm.$t("actions.change")))
                  ]),
                  _c(
                    "svg",
                    {
                      staticClass: "mdc-fab__icon",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }