var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      staticClass: "bottom-nav__action",
      attrs: { to: _vm.params.to, "active-class": "bottom-nav__action--active" }
    },
    [
      _c("div", {
        ref: "rippleSurface",
        staticClass: "mdc-ripple-surface mdc-ripple-surface--primary"
      }),
      _c("mdc-ripple"),
      _c("svg", {
        staticClass: "bottom-nav__icon",
        attrs: { viewBox: "0 0 24 24" },
        domProps: { innerHTML: _vm._s(_vm.params.icon_path) }
      }),
      _c("span", { staticClass: "bottom-nav__label" }, [
        _vm._v(_vm._s(_vm.params.label))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }