






import { Component, Prop, Vue } from 'vue-property-decorator';
import SettingsListItem from '@/components/settings/SettingsListItem.vue';

@Component({
    components: {
        SettingsListItem,
    },
})
export default class MerchantSettingList extends Vue {
    private items = [
        {
            to: { name: 'settings.merchant.modify' },
            label: 'settings.merchant.name.receipt',
        },
        {
            to: { name: 'settings.merchant.name.campaign' },
            label: 'settings.merchant.name.campaign',
        },
        {
            to: { name: 'settings.merchant.logo.campaign' },
            label: 'settings.merchant.logo.campaign',
        },
    ];
}
