export const settings = {
    captureNow: '立即清機 (直碌)',
    authorized: {
        transaction: '已授權筆數',
        volume: '已授權金額',
    },
    user: {
        label: '用戶',
        create: '創建用戶',
        type: '用戶類型',
        superuser: '管理員',
        normaluser: '普通用戶',
        email: '電郵',
        display: '顯示名稱',
        phone: '電話 (選填)',
        info: '用戶資料',
    },
    branch: {
        label: '分店',
        create: '創建分店',
        modify: '更改分店',
        receipt: {
            name: '分店名稱 (英文) - 收據',
            address: '分店地址 (英文) - 收據',
        },
        campaign: {
            name: '分店名稱 (英文) - 互聯網',
            address: '分店地址 (英文) - 互聯網',
        },
    },
    merchant: {
        setup: '初次設定',
        setupMessage: '如果需要再次更改，請前往設定頁面。',
        modify: '更改商戶資料',
        name: {
            receipt: '商戶名稱 (收據)',
            campaign: '商戶名稱 (互聯網)',
        },
        logo: {
            campaign: '商戶Logo (互聯網)',
        },
        current: '目前名稱',
        new: '新名稱',
        confirm: '確認新名稱',
        display: '顯示名稱',
        url: 'URL名稱 (English Only)',
        uploadLogoWarning: '如有需要，請上傳貴商戶的Logo。',
        modifyCampaignWarning: '注意！如果更改了域名名稱，過往的連結將會失效，並立即更新您的連結。',
    },
    export: {
        title: '匯出',
        label: '匯出報表',
        type: {
            transaction: '交易記錄',
            journal: '流水記錄',
            online: '網上通道記錄',
            statement: '月結單',
            payout: '轉帳記錄',
        },
        reminder: {
            type: '請選擇匯出的報表。',
            period: '請選擇匯出的日期',
            branch: '請選擇匯出的分店。',
            bybranch: '請選擇匯出的月結單選項',
        },
        bybranch: {
            title: '月結單選項',
            true: '分開每個分店',
            false: '整合所有分店',
        },
        period: '範圍',

        // Export Download Page
        downloadData: '數據下載',
        downloadAndOpen: '下載 (開啓新視窗)',
        copyLinkAndPassword: '複製連結及密碼',
        longPressOrCopy: '長按分享/複製',
        expire: '連結將於{0}失效。',
    },
    printer: '打印機',
    connectPrinter: '連接打印機',
    connectPrinterFailed: '連接打印機失敗',
    capture: {
        title: '清機 (直碌)',
        enableAuthorization: '開啟清機模式',
        mode: {
            label: '模式',
            auto: '自動清機 (每天)',
            manual: '手動',
        },
        summary: {
            succeededTrx: '{0}個款項成功收取',
            failedTrx: '{0}個款項<b>不成功</b>收取',
            contact: '如有問題，請與Jarvix Pay聯絡',
        },
        confirm: '確認更改清機模式',
    },
    permissions: {
        title: '權限',
        account: {
            addAccount: '新增分店',
            addUser: '新增用戶',
            editPermission: '修改權限',
            modifyInformation: '更新商戶資料',
        },
        functional: {
            campaignAgent: '代客推廣',
            dashboard: '總覧',
            export: '匯出',
            payout: '銀行轉帳',
            printer: '打印機',
            transactionsHistory: '交易記錄',
        },
        payment: {
            cancelRecurrence: '取消月付',
            capture: '清機',
            changeRecurrenceCreditCard: '轉換月付信用卡',
            editChargeDescription: '更改交易詳情',
            refund: '退款',
            // enable_direct: '啟用直碌功能',
            enable_recurrence: '啟用月付功能',
            enable_remote: '啟用遙距功能',
            enable_campaign: '啟用互聯網功能',
        },
        notification: {
            payment: '付款通知',
            campaign: '宣傳通知',
            summary: '帳戶總覽通知',
        },
    },
    language: '語言',
    password: {
        label: '密碼',
        change: '更改密碼',
        reminder: '建議使用與其他服務不同的高強度密碼。',
        current: '目前密碼',
        new: '新密碼',
        confirm: '確認新密碼',
    },
    about: '關於',
    logout: {
        title: '登出',
    },
};
