export const signup = {
    // Registration
    accountRegistration: '帳戶注冊',
    companyName: '公司名稱',
    email: '電郵',
    password: '密碼',
    confirmPassword: '確認密碼',
    countryCode: '國家代碼',
    phone: '電話號碼',
    iAccept: '我已瞭解並同意 ',
    tos: ' Jarvix Pay 服務條款',
    and: ' 和',
    privacy: ' 私隱政策',
    fullStop: '。',
    signup: '註冊',

    // Verification
    accountVerification: '帳戶驗證',
    verificationInputText: '請輸入你的驗證碼',

    // Company
    companyInfo: '公司資料',
    businessType: '公司類別',
    limited: '有限公司',
    unlimited: '無限公司',
    businessBR: '商業登記號碼 (BR)',
    businessCI: '公司註冊證書 (CI)',
    statementDescriptor: '客戶帳單顯示名稱',
    companyPhone: '公司電話',
    CompanyAddress: '公司地址',
    city: '城市',
    state: '國家 / 地區',
    businessDescription: '業務簡介',
    businessWeb: '公司網站',

    // Owner
    companyOwner: '公司擁有人',
    companyRemind: '提醒：凡擁有公司超過25%, 相關公司擁有人資料必須申報',
    companyImportantOwner: '公司重要控制人',
    owner: '擁有人',
    lastName: '姓氏',
    firstName: '名字',
    identityType: '證件類別',
    identityNumber: '證件號碼',
    hkid: '香港身份證',
    passport: '護照',
    dob: '出生日期',
    ownerPhone: '手機號碼',
    address: '地址',
    addOwner: '+ 新增擁有人',

    // ExternalAccount
    externalAccountInfo: '收款銀行戶口資料',
    holderName: '賬戶持有人',
    holderType: '賬戶類型',
    companyAccount: 'Company (公司戶口)',
    individualAccount: 'Individual (個人戶口)',
    bank: '銀行',
    branchCode: '分行編號',
    accountNumber: '賬戶號碼',
    currency: '貨幣',

    // DocumentUpload
    uploadDocument: '上載證明文件',
    remind: '提醒：',
    remindItem: '* 為必填項目',
    remindDocs: '文件格式只接受 pdf / png / jpg',
    selectDocs: '選擇文件',
    br: '商業登記証 (BR)',
    proof: '的證明文件',
    idProof: '身份證明文件只接受圖片格式為png或jpg ',
    idFront: '選擇身份證明文件（正面）',
    idBack: '選擇身份證明文件（背面）',
    addressProof: '選擇地址證明文件',
    bankProof: '收款銀行戶口證明',
    bankProofExample: '例如銀行卡、月結單、支票簿',
    otherInfo: '其他資料 （可多於一項）',
    errorBR: '商業登記証 (BR)已上載檔案的格式並不是 png, jpg或pdf',
    errorCI: '公司註冊證書 (CI)已上載檔案的格式並不是 png, jpg或pdf',
    errorBank: '收款銀行戶口證明已上載檔案的格式並不是 png, jpg或pdf',
    errorIdentityFront: '擁有人{ownerNo}的身份證明文件（正面）已上載檔案的格式並不是 png或jpg',
    errorIdentityBack: '擁有人{ownerNo}的身份證明文件（背面）已上載檔案的格式並不是 png或jpg',
    errorAddress: '擁有人{ownerNo}的地址證明文件已上載檔案的格式並不是 png, jpg或pdf',
    errorOther: '其他資料{otherNo}已上載檔案的格式並不是 png, jpg或pdf',

    // Review
    preview: '預覧',
    confirm: '請確保所填資料正確無誤，提交後將無法修改任何內容',
    company: '公司',
    name: '名稱',
    type: '類別',
    clientName: '客戶顯示名稱',
    document: '證明文件',
    changeInfo: '更改資料',

    // Signature
    sign: '簽署',
    remindTerms: '必須填寫所有資料以及簽署所有條款',
    remindEmail: '提交簽署後將有副本發送至聯絡電郵',
    contactEmail: '聯絡電郵',
    peopleName: '簽署人全名',
    peopleTitle: '簽署人職銜',
    gender: '稱謂',
    male: '先生',
    female: '小姐',
    applicationForm: 'Jarvix Pay 商戶申請表',
    partA: '甲部：公司資料',
    companySignAddress: '公司註冊地址',
    brNumber: '商業登記號碼',
    companyEmail: '公司電郵地址',
    partB: '乙部：申請人資料',
    idNumber: '身份證號碼',
    personalAddress: '個人住址',
    partC: '丙部：商戶收款銀行資料',
    bankName: '收款銀行名稱',
    bankHolderName: '賬戶名稱',
    partD: '丁部：Jarvix Pay 交易手續費',
    visaMaster: 'Visa/MasterCard',
    cardIssuer: '(由香港信用卡機構發行)',
    rateFee: '交易費率及交易手續費用',
    tradeAmount: '交易金額的',
    eachTrade: '每筆交易港幣',
    remark: '備注',
    cashRebate: '現金回贈交易金額的',
    ae: 'American Express',
    unionpay: 'UnionPay',
    foreign: 'Visa/MasterCard/American Express/UnionPay',
    noneHKIssuer: '由非香港信用卡機構發行',
    arrivalDay: '到帳日',
    requiredDocs: 'Jarvix Pay 申請所需文件：',
    brCopy: '商業登記證副本',
    submitted: '已提交',
    ciCopy: '公司註冊證書副本',
    limitedProvided: '有限公司需要提供',
    bankDocs: '收款銀行賬戶文件',
    remindBank: '需顯示公司名稱及銀行賬戶號',
    ownerIdCopy: '公司主要控制人的身份證副本',
    ownerAddressCopy: '公司主要控制人的住址證明副本',
    remindLegal: '我們確認上述資料屬實，合法及有效。 如果上述資料不符真相或違法，我們將承擔一切後果及法律責任。',
    contract: '合同協議',
    submitContract: '提交申請',
    thanks: '感謝你的申請',
    pending: '你的帳户正在審批中。',
    reSign: '重新簽署',
    remindReSign: '重新簽署後舊合約會立即廢除，一切將根據新合約內容進行',

    //Signing
    signHereLabel: '按此簽署',
    signHere: '在此簽名',

    // Rejected
    unfortunately: '很遺憾',
    unsuccessful: '你的申請不成功',
    contact_us: '如有疑問，請聯絡我們，謝謝！',
};
