var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "editing-remote-campaign" } }, [
    _vm.isTestingMode
      ? _c(
          "button",
          {
            staticClass: "mdc-button",
            on: {
              click: function($event) {
                _vm.autofill(true)
              }
            }
          },
          [_vm._v("Auto fill")]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "mdc-layout-grid__inner" },
      [
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
          },
          [_vm._v(_vm._s(_vm.$t("campaign.title")))]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.internalCampaignName,
                  expression: "internalCampaignName"
                }
              ],
              staticClass: "mdc-text-field__input",
              attrs: {
                type: "text",
                id: "internal-campagin-name",
                autocomplete: "off",
                autocorrect: "off",
                spellcheck: "false"
              },
              domProps: { value: _vm.internalCampaignName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.internalCampaignName = $event.target.value
                }
              }
            }),
            _c(
              "label",
              {
                staticClass: "mdc-floating-label",
                attrs: { for: "internal-campagin-name" }
              },
              [_vm._v(_vm._s(_vm.$t("campaign.internalName")))]
            ),
            _c("div", { staticClass: "mdc-notched-outline" }, [
              _c("svg", [
                _c("path", { staticClass: "mdc-notched-outline__path" })
              ])
            ]),
            _c("div", { staticClass: "mdc-notched-outline__idle" })
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.agentPromotionAllowed,
                expression: "agentPromotionAllowed"
              }
            ],
            staticClass:
              "mdc-layout-grid__cell mdc mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet agentPromotionBox"
          },
          [
            _c("mdc-switch", {
              staticClass:
                "agentPromotionSwitch mdc-typography--headline6 title-font-size",
              attrs: {
                "align-end": true,
                label: _vm.$t("campaign.agentPromotion")
              },
              model: {
                value: _vm.agentPromotion,
                callback: function($$v) {
                  _vm.agentPromotion = $$v
                },
                expression: "agentPromotion"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.agentPromotion && _vm.agentPromotionAllowed,
                expression: "agentPromotion && agentPromotionAllowed"
              }
            ],
            staticClass:
              "agentPromtionContent mdc-layout-grid__cell mdc mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
          },
          [
            _c(
              "div",
              { attrs: { id: "radio-test" } },
              [
                _c("mdc-radio", {
                  attrs: {
                    label: _vm.$t("customer.currentClient"),
                    name: "clientType",
                    value: "currentClient"
                  },
                  model: {
                    value: _vm.clientType,
                    callback: function($$v) {
                      _vm.clientType = $$v
                    },
                    expression: "clientType"
                  }
                }),
                _c("mdc-radio", {
                  attrs: {
                    label: _vm.$t("customer.new"),
                    name: "clientType",
                    value: "newClient"
                  },
                  model: {
                    value: _vm.clientType,
                    callback: function($$v) {
                      _vm.clientType = $$v
                    },
                    expression: "clientType"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.clientType == "currentClient" &&
                  _vm.agentPromotion &&
                  _vm.agentPromotionAllowed,
                expression:
                  "clientType == 'currentClient' && agentPromotion && agentPromotionAllowed"
              }
            ],
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
          },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedClient,
                    expression: "selectedClient"
                  }
                ],
                staticClass: "mdc-text-field__input",
                class: { "select--hidden": !_vm.selectedClient },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedClient = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c(
                  "option",
                  { attrs: { disabled: "" }, domProps: { value: null } },
                  [_vm._v(_vm._s(_vm.$t("customer.title")))]
                ),
                _vm._l(_vm.clientList, function(client) {
                  return _c(
                    "option",
                    { key: client.name, domProps: { value: client } },
                    [_vm._v(_vm._s(client.name))]
                  )
                })
              ],
              2
            ),
            _c(
              "label",
              {
                staticClass: "mdc-floating-label",
                class: {
                  "mdc-floating-label--float-above": _vm.selectedClient.name
                }
              },
              [_vm._v(_vm._s(_vm.$t("customer.title")) + "(s)")]
            ),
            _c("div", { staticClass: "mdc-notched-outline" }, [
              _c("svg", [
                _c("path", { staticClass: "mdc-notched-outline__path" })
              ])
            ]),
            _c("div", { staticClass: "mdc-notched-outline__idle" })
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.agentPromotion && _vm.agentPromotionAllowed,
                expression: "agentPromotion && agentPromotionAllowed"
              }
            ],
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.clientDetail.name,
                  expression: "clientDetail.name"
                }
              ],
              staticClass: "mdc-text-field__input",
              attrs: {
                type: "text",
                id: "name",
                autocomplete: "off",
                autocorrect: "off",
                spellcheck: "false",
                readonly: _vm.clientType == "currentClient"
              },
              domProps: { value: _vm.clientDetail.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.clientDetail, "name", $event.target.value)
                }
              }
            }),
            _c(
              "label",
              {
                staticClass: "mdc-floating-label",
                class: {
                  "mdc-floating-label--float-above": _vm.clientDetail.name
                },
                attrs: { for: "name" }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("customer.name")) +
                    "\n            "
                )
              ]
            ),
            _c("div", { staticClass: "mdc-notched-outline" }, [
              _c("svg", [
                _c("path", { staticClass: "mdc-notched-outline__path" })
              ])
            ]),
            _c("div", { staticClass: "mdc-notched-outline__idle" })
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.agentPromotion && _vm.agentPromotionAllowed,
                expression: "agentPromotion && agentPromotionAllowed"
              }
            ],
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.clientDetail.email,
                  expression: "clientDetail.email"
                }
              ],
              staticClass: "mdc-text-field__input",
              attrs: {
                type: "email",
                id: "email",
                autocomplete: "off",
                autocorrect: "off",
                spellcheck: "false",
                readonly: _vm.clientType == "currentClient"
              },
              domProps: { value: _vm.clientDetail.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.clientDetail, "email", $event.target.value)
                }
              }
            }),
            _c(
              "label",
              {
                staticClass: "mdc-floating-label",
                class: {
                  "mdc-floating-label--float-above": _vm.clientDetail.email
                },
                attrs: { for: "email" }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("customer.email")) +
                    "\n            "
                )
              ]
            ),
            _c(
              "div",
              {
                ref: "email-notched-outline",
                staticClass: "mdc-notched-outline"
              },
              [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]
            ),
            _c("div", { staticClass: "mdc-notched-outline__idle" })
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
          },
          [_vm._v(_vm._s(_vm.$t("campaign.period")))]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
          },
          [
            _c(
              "m-textfield",
              {
                ref: "dateRangeFrom",
                class: { "mdc-text-field--empty": !_vm.dateRange.from.date },
                attrs: {
                  type: "date",
                  autocorrect: "off",
                  spellcheck: "false",
                  autocomplete: "off",
                  outlined: ""
                },
                model: {
                  value: _vm.dateRange.from.date,
                  callback: function($$v) {
                    _vm.$set(_vm.dateRange.from, "date", $$v)
                  },
                  expression: "dateRange.from.date"
                }
              },
              [
                _c(
                  "m-floating-label",
                  { attrs: { floatAbove: !!_vm.dateRange.from.date } },
                  [_vm._v(_vm._s(_vm.$t("general.date.start")))]
                ),
                _c("m-notched-outline", {
                  attrs: { notched: !!_vm.dateRange.from.date }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
          },
          [
            _c(
              "m-textfield",
              {
                ref: "dateRangeTo",
                class: { "mdc-text-field--empty": !_vm.dateRange.to.date },
                attrs: {
                  type: "date",
                  autocorrect: "off",
                  spellcheck: "false",
                  autocomplete: "off",
                  outlined: ""
                },
                model: {
                  value: _vm.dateRange.to.date,
                  callback: function($$v) {
                    _vm.$set(_vm.dateRange.to, "date", $$v)
                  },
                  expression: "dateRange.to.date"
                }
              },
              [
                _c(
                  "m-floating-label",
                  { attrs: { floatAbove: !!_vm.dateRange.to.date } },
                  [_vm._v(_vm._s(_vm.$t("general.date.end")))]
                ),
                _c("m-notched-outline", {
                  attrs: { notched: !!_vm.dateRange.to.date }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
          },
          [_vm._v(_vm._s(_vm.$t("campaign.copywriting")))]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.externalCampaignName,
                  expression: "externalCampaignName"
                }
              ],
              staticClass: "mdc-text-field__input",
              attrs: {
                type: "text",
                id: "external-campagin-name",
                autocomplete: "off",
                autocorrect: "off",
                spellcheck: "false"
              },
              domProps: { value: _vm.externalCampaignName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.externalCampaignName = $event.target.value
                }
              }
            }),
            _c(
              "label",
              {
                staticClass: "mdc-floating-label",
                attrs: { for: "external-campagin-name" }
              },
              [_vm._v(_vm._s(_vm.$t("campaign.externalName")))]
            ),
            _c("div", { staticClass: "mdc-notched-outline" }, [
              _c("svg", [
                _c("path", { staticClass: "mdc-notched-outline__path" })
              ])
            ]),
            _c("div", { staticClass: "mdc-notched-outline__idle" })
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched mdc-text-field--textarea"
          },
          [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.advertisement,
                  expression: "advertisement"
                }
              ],
              staticClass: "mdc-text-field__input",
              attrs: { rows: "5", cols: "100" },
              domProps: { value: _vm.advertisement },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.advertisement = $event.target.value
                }
              }
            }),
            _c("label", { staticClass: "mdc-floating-label" }, [
              _vm._v(_vm._s(_vm.$t("campaign.content")))
            ]),
            _c("div", { staticClass: "mdc-notched-outline" }, [
              _c("svg", [
                _c("path", { staticClass: "mdc-notched-outline__path" })
              ])
            ]),
            _c("div", { staticClass: "mdc-notched-outline__idle" })
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
          },
          [_vm._v(_vm._s(_vm.$t("campaign.banners")))]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet no-line-height"
          },
          [
            _vm._l(_vm.banners, function(banner, index) {
              return [
                _c("RemoteCampaignImageItem", {
                  key: banner.id,
                  attrs: { imageFile: banner.file },
                  on: {
                    delete: function($event) {
                      _vm.deleteBannerItem(index)
                    }
                  }
                })
              ]
            })
          ],
          2
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet list-item",
            style: { position: "relative", transform: "translateZ(0)" }
          },
          [
            _c("input", {
              ref: "upload",
              staticClass: "mdc-text-field__input file",
              attrs: {
                type: "file",
                accept: "image/*",
                hidden: "",
                autocomplete: "off",
                autocorrect: "off",
                spellcheck: "false",
                multiple: ""
              },
              on: {
                change: function($event) {
                  _vm.getBanners($event)
                }
              }
            }),
            _c(
              "div",
              { staticClass: "add-button", on: { click: _vm.addBannerItem } },
              [_vm._v(_vm._s(_vm.$t("actions.upload")))]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
          },
          [_vm._v(_vm._s(_vm.$t("campaign.currency")))]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--large mdc-text-field--outlined mdc-text-field--not-notched"
          },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currency,
                    expression: "currency"
                  }
                ],
                staticClass: "mdc-text-field__input",
                attrs: { options: _vm.currencyList },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.currency = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c(
                  "option",
                  { attrs: { disabled: "" }, domProps: { value: null } },
                  [_vm._v(_vm._s(_vm.$t("currency.name")))]
                ),
                _vm._l(_vm.currencyList, function(c) {
                  return _c("option", { key: c.text, domProps: { value: c } }, [
                    _vm._v(_vm._s(_vm.$t(c.text)))
                  ])
                })
              ],
              2
            ),
            _c("label", { staticClass: "mdc-floating-label" }, [
              _vm._v(_vm._s(_vm.$t("currency.name")))
            ]),
            _c("div", { staticClass: "mdc-notched-outline" }, [
              _c("svg", [
                _c("path", { staticClass: "mdc-notched-outline__path" })
              ])
            ]),
            _c("div", { staticClass: "mdc-notched-outline__idle" })
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
          },
          [_vm._v(_vm._s(_vm.$t("campaign.product.title")))]
        ),
        _vm._l(_vm.productList, function(product, index) {
          return _c(
            "div",
            {
              key: product.id,
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
            },
            [
              _c("RemoteCampaignProductItem", {
                attrs: {
                  currency: _vm.$t(_vm.currency.text),
                  index: index + 1,
                  product: product,
                  editingProductList: _vm.editingProductList
                },
                on: {
                  edit: function($event) {
                    _vm.editProductItem($event, index)
                  },
                  save: function($event) {
                    _vm.saveProductItem($event, index)
                  },
                  delete: function($event) {
                    _vm.deleteProductItem(index)
                  }
                }
              })
            ],
            1
          )
        }),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet list-item"
          },
          [
            _c(
              "div",
              { staticClass: "add-button", on: { click: _vm.addProductItem } },
              [_vm._v("+ " + _vm._s(_vm.$t("campaign.newProduct")))]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
          },
          [_vm._v(_vm._s(_vm.$t("campaign.link")))]
        ),
        _vm._l(_vm.linkList, function(link, index) {
          return _c(
            "div",
            {
              key: link.id,
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
            },
            [
              _c("RemoteCampaignLinkItem", {
                attrs: {
                  index: index + 1,
                  campaignName: _vm.externalCampaignName,
                  name: link.name
                },
                on: {
                  "update:name": function($event) {
                    _vm.$set(link, "name", $event)
                  },
                  delete: function($event) {
                    _vm.deleteLinkItem(index)
                  }
                }
              })
            ],
            1
          )
        }),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet list-item",
            style: { position: "relative", transform: "translateZ(0)" }
          },
          [
            _c(
              "div",
              { staticClass: "add-button", on: { click: _vm.addLinkItem } },
              [_vm._v("+ " + _vm._s(_vm.$t("campaign.newLink")))]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
          },
          [_vm._v(_vm._s(_vm.$t("campaign.newQuestion")))]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
          },
          [
            _c(
              "div",
              { staticClass: "list-item" },
              [
                _c(
                  "mdc-checkbox",
                  {
                    staticClass: "extra-option",
                    model: {
                      value: _vm.dateOption,
                      callback: function($$v) {
                        _vm.dateOption = $$v
                      },
                      expression: "dateOption"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("customer.orderDate")) +
                        " - " +
                        _vm._s(_vm.$t("campaign.additionalOptions.dateFormat"))
                    )
                  ]
                ),
                _c(
                  "mdc-checkbox",
                  {
                    staticClass: "extra-option",
                    model: {
                      value: _vm.timeOption,
                      callback: function($$v) {
                        _vm.timeOption = $$v
                      },
                      expression: "timeOption"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("customer.orderTime")) +
                        " - " +
                        _vm._s(_vm.$t("campaign.additionalOptions.timeFormat"))
                    )
                  ]
                ),
                _c(
                  "mdc-checkbox",
                  {
                    staticClass: "extra-option",
                    model: {
                      value: _vm.emailOption,
                      callback: function($$v) {
                        _vm.emailOption = $$v
                      },
                      expression: "emailOption"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("customer.email")) +
                        " - " +
                        _vm._s(_vm.$t("campaign.additionalOptions.textFormat"))
                    )
                  ]
                ),
                _c(
                  "mdc-checkbox",
                  {
                    staticClass: "extra-option",
                    model: {
                      value: _vm.genderOption,
                      callback: function($$v) {
                        _vm.genderOption = $$v
                      },
                      expression: "genderOption"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("customer.gender")) +
                        " - " +
                        _vm._s(
                          _vm.$t("campaign.additionalOptions.optionFormat")
                        )
                    )
                  ]
                ),
                _c(
                  "mdc-checkbox",
                  {
                    staticClass: "extra-option",
                    model: {
                      value: _vm.ageOption,
                      callback: function($$v) {
                        _vm.ageOption = $$v
                      },
                      expression: "ageOption"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("customer.age")) +
                        " - " +
                        _vm._s(
                          _vm.$t("campaign.additionalOptions.optionFormat")
                        )
                    )
                  ]
                ),
                _c(
                  "mdc-checkbox",
                  {
                    staticClass: "extra-option",
                    model: {
                      value: _vm.addressOption,
                      callback: function($$v) {
                        _vm.addressOption = $$v
                      },
                      expression: "addressOption"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("customer.address")) +
                        " - " +
                        _vm._s(
                          _vm.$t("campaign.additionalOptions.optionFormat")
                        )
                    )
                  ]
                ),
                _c(
                  "mdc-checkbox",
                  {
                    staticClass: "extra-option",
                    model: {
                      value: _vm.mcOption,
                      callback: function($$v) {
                        _vm.mcOption = $$v
                      },
                      expression: "mcOption"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("campaign.additionalOptions.question")) +
                        " - " +
                        _vm._s(_vm.$t("campaign.additionalOptions.mcFormat"))
                    )
                  ]
                ),
                _c(
                  "mdc-checkbox",
                  {
                    staticClass: "extra-option",
                    model: {
                      value: _vm.specialRequestOption,
                      callback: function($$v) {
                        _vm.specialRequestOption = $$v
                      },
                      expression: "specialRequestOption"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("customer.specialRequest")) +
                        " - " +
                        _vm._s(_vm.$t("campaign.additionalOptions.textFormat"))
                    )
                  ]
                ),
                _c(
                  "mdc-checkbox",
                  {
                    staticClass: "extra-option",
                    model: {
                      value: _vm.branchOption,
                      callback: function($$v) {
                        _vm.branchOption = $$v
                      },
                      expression: "branchOption"
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$t("campaign.additionalOptions.branchSelection")
                        ) +
                        " -\n                    " +
                        _vm._s(
                          _vm.$t("campaign.additionalOptions.optionFormat")
                        ) +
                        "\n                "
                    )
                  ]
                )
              ],
              1
            )
          ]
        ),
        _vm.branchOption
          ? _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("campaign.additionalOptions.branchSelection"))
                )
              ]
            )
          : _vm._e(),
        _vm.branchOption
          ? _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
              },
              [
                _c(
                  "div",
                  { staticClass: "list-item" },
                  [
                    _vm._l(_vm.branchList, function(branch) {
                      return _c(
                        "div",
                        { key: branch.id },
                        [
                          _c(
                            "mdc-checkbox",
                            {
                              staticClass: "extra-option",
                              model: {
                                value: branch.selected,
                                callback: function($$v) {
                                  _vm.$set(branch, "selected", $$v)
                                },
                                expression: "branch.selected"
                              }
                            },
                            [_vm._v(_vm._s(branch.name || "N/A"))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mdc-checkbox-wrapper extra-option mdc-form-field branch-address"
                            },
                            [_vm._v(_vm._s(branch.address || "N/A"))]
                          )
                        ],
                        1
                      )
                    }),
                    _c(
                      "div",
                      [
                        _c(
                          "mdc-checkbox",
                          {
                            staticClass: "extra-option",
                            model: {
                              value: _vm.checkAllButton,
                              callback: function($$v) {
                                _vm.checkAllButton = $$v
                              },
                              expression: "checkAllButton"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("status.all")))]
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ]
            )
          : _vm._e(),
        _vm.mcOption
          ? _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
              },
              [_vm._v(_vm._s(_vm.$t("campaign.question.title")))]
            )
          : _vm._e(),
        _vm._l(_vm.questionList, function(question, index) {
          return _c(
            "div",
            {
              key: question.id,
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
            },
            [
              _vm.mcOption
                ? _c("RemoteCampaignOpenQuestionItem", {
                    attrs: {
                      question: question.question,
                      options: question.options,
                      index: index + 1
                    },
                    on: {
                      "update:question": function($event) {
                        _vm.$set(question, "question", $event)
                      },
                      "update:options": function($event) {
                        _vm.$set(question, "options", $event)
                      },
                      delete: function($event) {
                        _vm.deleteQuestionItem(index)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        _vm.mcOption
          ? _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet list-item",
                style: { position: "relative", transform: "translateZ(0)" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "add-button",
                    on: { click: _vm.addQuestionItem }
                  },
                  [_vm._v("+ " + _vm._s(_vm.$t("campaign.newQuestion")))]
                )
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
          },
          [_vm._v(_vm._s(_vm.$t("campaign.tnc")))]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched mdc-text-field--textarea"
          },
          [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.tnc,
                  expression: "tnc"
                }
              ],
              staticClass: "mdc-text-field__input",
              attrs: { rows: "5", cols: "100" },
              domProps: { value: _vm.tnc },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.tnc = $event.target.value
                }
              }
            }),
            _c("label", { staticClass: "mdc-floating-label" }, [
              _vm._v(_vm._s(_vm.$t("campaign.pasteTNC")))
            ]),
            _c("div", { staticClass: "mdc-notched-outline" }, [
              _c("svg", [
                _c("path", { staticClass: "mdc-notched-outline__path" })
              ])
            ]),
            _c("div", { staticClass: "mdc-notched-outline__idle" })
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
          },
          [_vm._v("Call To Action")]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.cta,
                  expression: "cta"
                }
              ],
              staticClass: "mdc-text-field__input",
              attrs: {
                type: "text",
                id: "cta",
                autocomplete: "off",
                autocorrect: "off",
                spellcheck: "false",
                maxlength: "12"
              },
              domProps: { value: _vm.cta },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.cta = $event.target.value
                }
              }
            }),
            _c(
              "label",
              { staticClass: "mdc-floating-label", attrs: { for: "cta" } },
              [_vm._v(_vm._s(_vm.$t("campaign.content")))]
            ),
            _c("div", { staticClass: "mdc-notched-outline" }, [
              _c("svg", [
                _c("path", { staticClass: "mdc-notched-outline__path" })
              ])
            ]),
            _c("div", { staticClass: "mdc-notched-outline__idle" })
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
          },
          [_vm._v(_vm._s(_vm.$t("campaign.contact.title")))]
        ),
        _vm._l(_vm.contactList, function(contact, index) {
          return _c(
            "div",
            {
              key: contact.id,
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
            },
            [
              _c("RemoteCampaignContactItem", {
                attrs: {
                  index: index + 1,
                  contact: contact,
                  editingContactList: _vm.editingContactList
                },
                on: {
                  edit: function($event) {
                    _vm.editContactItem($event, index)
                  },
                  save: function($event) {
                    _vm.saveContactItem($event, index)
                  },
                  delete: function($event) {
                    _vm.deleteContactItem(index)
                  }
                }
              })
            ],
            1
          )
        }),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet list-item",
            style: { position: "relative", transform: "translateZ(0)" }
          },
          [
            _c(
              "div",
              { staticClass: "add-button", on: { click: _vm.addContactItem } },
              [_vm._v("+ " + _vm._s(_vm.$t("campaign.newContact")))]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
          },
          [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                staticClass: "mdc-button"
              },
              [_vm._v(_vm._s(_vm.$t("actions.reset")))]
            ),
            _c(
              "button",
              {
                staticClass: "mdc-fab mdc-fab--extended",
                attrs: { "aria-label": "預覽活動" },
                on: { click: _vm.preview }
              },
              [
                _c("span", { staticClass: "mdc-fab__label" }, [
                  _vm._v(_vm._s(_vm.$t("actions.preview")))
                ]),
                _c(
                  "svg",
                  {
                    staticClass: "mdc-fab__icon",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 24 24"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                      }
                    })
                  ]
                )
              ]
            )
          ]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }