







import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IMaskComponent } from 'vue-imask';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';

@Component({})
export default class RemoteCampaignImageItem extends Vue {
    @Prop()
    imageFile!: any;

    private imageSrc:any = '';

    public mounted() {
        this.getTheBanner(this.imageFile)
    }

    private getTheBanner(imageFile: any){
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            this.imageSrc = reader.result;
        })

        reader.readAsDataURL(imageFile);
    }

    private deleteImage(){
        this.$emit('delete');
    }
}
