











































import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class Transaction extends Vue {
    @Watch('$route')
    onUrlChange(newVal: any) {
        if (newVal.name === 'transactions.history') {
            return (this.activeIndex = 0);
        }

        if (newVal.name === 'transactions.recurrence_history') {
            return (this.activeIndex = 1);
        }
    }

    private mdcTabBar!: any;
    private activeIndex = 0;

    public mounted() {
        if (this.$router.currentRoute.name === 'transactions.recurrence_history') {
            (this.$el.getElementsByClassName('mdc-tab__label')[1] as HTMLElement).click();
            return;
        }
    }

    displayHistory() {
        this.$router.push({ name: 'transactions.history' });
    }

    displayRecurrenceHistory() {
        this.$router.push({ name: 'transactions.recurrence_history' });
    }
}
