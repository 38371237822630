export const campaign = {
    title: '营销活动',
    internalName: '内部名称',
    externalName: '营销活动名称',
    agentPromotion: '代客推广',
    period: '推广期',
    copywriting: '宣传文案',
    content: '内容',
    banners: '广告图片',
    details: '汇出报表',
    product: {
        title: '销售产品',
        label: '产品项目',
        name: '名称',
        price: '实价',
        originalPrice: '原价',
        customDiscountMessage: '自订优惠信息',
        quantity: '总数量',
        quantityPerOrder: '每次限购数量',
        discount: '折扣优惠',
        remaining: '剩余',
    },
    link: '营销活动连结',
    additionalOptions: {
        title: '收集更多客户意见',
        dateFormat: '日期形式',
        timeFormat: '时间形式',
        textFormat: '文字形式',
        optionFormat: '选项形式',
        mcFormat: 'MC形式',
        question: '自订问题',
        branchSelection: '分店选项',
    },
    question: {
        title: '自订问题',
        label: '问题',
        option: '选项',
        newOption: '新增选项',
        answer: '答案',
    },
    newProduct: '新增产品',
    newLink: '新增连结',
    newQuestion: '新增问题',
    newContact: '新增联络资料',
    tnc: '条款及细则 (T&C)',
    pasteTNC: '请贴上服务/产品的条款及细则',
    cta: 'Call To Action',
    currency: '收款货币',
    contact: {
        title: '联络资料',
        label: '联络',
        shopName: '店铺名称',
        address: '联络地址',
        number: '联络电话',
    },
    orderList: '订单',
    orderSummary: {
        totalTransaction: '总额',
        totalOrder: '总订单数量',
        paid: '已支付',
        latestTransaction: '最新订单金额',
        latestDate: '最新订单日期',
    },
    survey: '问卷',
    cancelMsg: '营销活动将会关闭。',
    discountMsg: '优惠数量有限，优惠即将有效至{0}',
    hitRate: '点击率',
};
