var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("div", { attrs: { id: "overlay" } }, [
        _c(
          "div",
          {
            staticClass: "spinner spinner__container spinner--active",
            staticStyle: { "font-size": "4rem", "margin-bottom": "1.125rem" }
          },
          [
            _c(
              "svg",
              {
                staticClass: "spinner spinner__icon",
                attrs: {
                  viewBox: "0 0 66 66",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("circle", {
                  staticClass: "path",
                  attrs: {
                    fill: "none",
                    "stroke-width": "3.5",
                    "stroke-linecap": "round",
                    cx: "33",
                    cy: "33",
                    r: "30"
                  }
                })
              ]
            )
          ]
        ),
        _c("div", { staticClass: "mdc-typography--body1" }, [
          _vm._v(_vm._s(_vm.$t("actions.loading")))
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }