





























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import HistoryItem from '@/components/HistoryItem.vue';
import { moment_HK as moment } from '@/store';
import { scrollTop } from '@/utils/scrollTop';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import { rnd } from '@/utils';

@Component({
    components: {
        HistoryItem,
    },
})
export default class History extends Vue {
    private items = [];
    private limit = 10;
    private addItemno = 10;
    private searchItems = [];
    private searchFlag: boolean = false;
    private timestamp?: number;
    private moreButton = true;
    private moreSearchButton = true;
    public gettingCharges = false;
    private keyword = '';
    private keywordOffset = 0;
    private subject = new BehaviorSubject<any>([]);
    private dataBySearch$ = this.subject.asObservable();
    private searchType = 'description';
    private showStatusOptions = false;
    private showTypeOptions = false;

    private from = '';
    private to = '';
    private dateRange = {
        from: { id: rnd(), label: { zhHK: '開始日期' }, modified: false, ref: undefined },
        to: { id: rnd(), label: { zhHK: '結束日期' }, modified: false, ref: undefined },
    };

    private paymentStatus = 'all';
    private paymentType = 'all';

    private statusOptions = [
        {
            value: 'all',
            text: 'status.all',
        },
        {
            value: 'pending',
            text: 'status.pending',
        },
        {
            value: 'paying',
            text: 'status.paying',
        },
        {
            value: 'succeeded',
            text: 'status.succeeded',
        },
        {
            value: 'failed,revoked',
            text: 'status.failed',
        },
        {
            value: 'refunded',
            text: 'status.refunded',
        },
    ];

    private typeOptions = [
        {
            value: 'all',
            text: 'status.all',
        },
        {
            value: 'cardDirect',
            text: 'general.paymentMethods.card',
        },
        {
            value: 'cardRecurrent',
            text: 'general.recurrent',
        },
        {
            value: 'cardRemote',
            text: 'general.remote',
        },
        {
            value: 'cardCampaign',
            text: 'general.campaign',
        },
        {
            value: 'cardOnline',
            text: 'general.online',
        },
        {
            value: 'wechat',
            text: 'general.paymentMethods.wechat',
        },
        {
            value: 'alipay',
            text: 'general.paymentMethods.alipay',
        },
    ];

    get rnd() {
        return rnd;
    }

    @Watch('keyword')
    keywordOnChanged(val: string, oldVal: string) {
        this.keywordOffset = 0;
        this.moreSearchButton = true;

        if (!val) {
            this.searchFlag = false;
            return;
        }
        this.searchFlag = true;

        return this.subject.next(val);
    }

    @Watch('searchType')
    searchTypeOnChanged() {
        if (this.searchType === 'status') {
            this.showTypeOptions = false;
            this.paymentType = 'all';
            this.showStatusOptions = true;
        } else if (this.searchType === 'type') {
            this.showStatusOptions = false;
            this.paymentStatus = 'all';
            this.showTypeOptions = true;
        } else {
            this.showStatusOptions = false;
            this.showTypeOptions = false;
            this.paymentStatus = 'all';
            this.paymentType = 'all';
        }
        (this.$el.querySelector('#keyword') as HTMLElement).focus();

        this.keyword = '';
        return;
    }

    @Watch('paymentStatus')
    onStatusChanged(val: string) {
        this.paymentStatus = val;
        this.fetchItems();
    }

    @Watch('paymentType')
    onTypeChanged(val: string) {
        this.paymentType = val;
        this.fetchItems();
    }

    public mounted() {
        this.initMDCTextFields();
    }

    public async created() {
        this.fetchItems();
        this.dataBySearch$.pipe(debounceTime(250)).subscribe((keyword) => {
            return this.initSearch(keyword);
        });
    }

    public triggerReceipt(chargeId: string, source: 'card' | 'wechat' | 'alipay') {
        if (source === 'wechat' || source === 'alipay') {
            return this.$router.push({ name: 'chinese-payment-receipt', params: { chargeId } });
        }

        return this.$router.push({ name: 'receipt', params: { chargeId } });
    }

    public async fetchItems() {
        if (!this.gettingCharges) {
            this.moreButton = true;
            this.timestamp = moment().unix();
            this.items = await this.getCharges(this.limit);
            scrollTop();
        }
    }

    public async moreItems() {
        const newItem = await this.getCharges(this.addItemno);
        if (newItem != undefined && newItem.length) {
            this.items = this.items.concat(newItem);
            this.limit += this.addItemno;
        }
    }

    public async getCharges(querySize: number) {
        let newItems: any;
        this.gettingCharges = true;
        try {
            newItems = await this.$store.dispatch('getCharges', {
                timestamp: this.timestamp,
                limit: querySize,
                status: this.paymentStatus,
                type: this.paymentType,
            });
            if (newItems.length < 1) {
                this.moreButton = false;
                newItems = [];
                return;
            } else {
                this.moreButton = true;
            }
            this.timestamp = moment((newItems[newItems.length - 1] as any).created_at).unix();
        } catch (error) {
            this.$root.$emit('error', error);
        } finally {
            this.gettingCharges = false;
        }
        if (newItems) return newItems;
    }

    public async moreSearchItems() {
        const newItem = await this.search(this.keyword);

        if (newItem.length) {
            this.searchItems = this.searchItems.concat(newItem);
        }

        return;
    }

    public async initSearch(keyword: string) {
        this.searchItems = await this.search(keyword);

        return;
    }

    public async search(keyword: string) {
        let items = [];

        if (this.searchType === 'description') {
            items = await this.searchByDescription(keyword);
        }

        if (this.searchType === 'receiptNumber') {
            items = await this.searchByReceipt(keyword);
        }

        if (items.length < this.limit) {
            this.moreSearchButton = false;
        }

        this.keywordOffset += this.limit;
        return items;
    }

    private searchByReceipt(receiptNumber: string) {
        return this.$store.dispatch('searchChargesByReceiptNumber', {
            receiptNumber: receiptNumber,
            limit: this.limit,
            offset: this.keywordOffset,
        });
    }

    private searchByDescription(description: string) {
        return this.$store.dispatch('searchChargesByDescription', {
            description: description,
            limit: this.limit,
            offset: this.keywordOffset,
        });
    }

    // animation function
    private initMDCTextFields() {
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
