var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "recurrents-list-item",
      on: {
        click: function($event) {
          _vm.$emit("click")
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass:
              "payment-history-expanded-item__amount mdc-typography--headline6"
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("currencyDisplay")(_vm.recurrent.currency)) +
                " " +
                _vm._s(_vm.recurrent.amount) +
                "\n        "
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "tags__group recurrents-list-item__status" },
          [
            _c("RecurrentStatusTag", {
              attrs: { status: _vm.recurrent.status }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "recurrents-list-item__desc mdc-typography--body2",
            staticStyle: { "text-transform": "capitalize" }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("general.history.chargeCount")) +
                ":\n            " +
                _vm._s(
                  _vm.recurrent.charge_count
                    ? _vm.recurrent.charge_count +
                      " / " +
                      _vm.recurrent.duration
                    : "N / A"
                ) +
                "\n            " +
                _vm._s(
                  _vm.recurrent.charge_count && _vm.recurrent.auto_extend
                    ? "- " + _vm.$t("recurrent.autoRenewal")
                    : ""
                ) +
                "\n        "
            )
          ]
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "recurrents-list-item__desc mdc-typography--body2",
            staticStyle: { "text-transform": "capitalize" }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.recurrent.customer.name || "—") +
                "\n        "
            )
          ]
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "recurrents-list-item__desc mdc-typography--caption",
            staticStyle: { opacity: ".54" }
          },
          [_vm._v(_vm._s(_vm.recurrent.description || "—"))]
        ),
        _c(
          "div",
          {
            staticClass:
              "recurrents-list-item__datetime mdc-typography--caption"
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm
                    .moment(_vm.recurrent.created_at)
                    .locale(_vm.$i18n.locale)
                    .format("LLL")
                ) +
                "\n        "
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }