var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message-list" },
    _vm._l(_vm.notificationMessages, function(item) {
      return _c("NotificationMessage", {
        key: item.id,
        staticClass:
          "message-item mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet",
        attrs: { item: item }
      })
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }