











































import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class OnlinePayment extends Vue {
    @Watch('$route')
    onUrlChange(newVal: any) {
        if (newVal.name === 'remote-campaign') {
            return (this.activeIndex = 0);
        }

        if (newVal.path.includes('campaign-history')) {
            return (this.activeIndex = 1);
        }
    }

    private mdcTabBar!: any;
    private activeIndex = 0;

    public created() {
        if (this.$router.currentRoute.path.includes('campaign-history')) {
            this.activeIndex = 1;
            return;
        }
    }

    displayPlanning() {
        this.$router.push({ name: 'remote-campaign' });
    }

    displayHistory() {
        this.$router.push({ name: 'campaign-history-list' });
    }
}
