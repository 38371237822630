







































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as moment from 'moment';
import ChargeStatusTag from '@/components/charges/ChargeStatusTag.vue';
import RecurrentStatusTag from '@/components/recurrents/RecurrentStatusTag.vue';
import RemoteStatusTag from '@/components/remote-payment/RemoteStatusTag.vue';
import { currencyNameWithSymbol } from '@/utils/helpers/currencyHelper';

@Component({
    filters: {
        currencySymbolDisplay: currencyNameWithSymbol,
    },
    components: {
        ChargeStatusTag,
        RecurrentStatusTag,
        RemoteStatusTag,
    },
})
export default class HistoryItem extends Vue {
    @Prop() charge!: any;

    get formatDate() {
        moment.locale(this.$i18n.locale);
        return moment(this.charge.created_at).format('lll');
    }
}
