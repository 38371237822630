






















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ExpiredLink extends Vue {
    private close(){
        window.close();
    }
}
