





























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import PayoutStatus from '@/components/payouts/PayoutStatus.vue';
// import { moment_HK as moment } from '@/store';
import * as moment from 'moment';
import { getUnique } from '@/utils/helpers/getUnique';

@Component({
    components: {
        PayoutStatus,
    },
})
export default class Payout extends Vue {
    @Prop()
    payout!: any;

    private payoutId: string = '';
    private items: any[] = [];
    // private startingAfter = '';
    private offset = 0;
    private moreButton = true;
    public gettingPayoutTransactions = false;

    public async created() {
        this.payoutId = this.payout.id;
        this.fetchItems();
    }

    public async fetchItems() {
        if (!this.gettingPayoutTransactions) {
            this.items = await this.getPayoutTransactions();
        }
    }

    public async moreItems() {
        const newItem = await this.getPayoutTransactions();

        if (newItem.length) {
            this.items = this.items.concat(newItem);
            // this.items = getUnique(this.items, 'chargeId');
        }
    }

    public async getPayoutTransactions() {
        let newList: any;
        let newItems: any;
        this.gettingPayoutTransactions = true;
        try {
            newList = await this.$store.dispatch('getPayoutTransactions', {
                payoutId: this.payoutId,
                limit: 10,
                offset: this.offset,
            });
            newItems = newList.data;
            this.moreButton = newList.has_more;

            this.offset = this.offset + newItems.length;
        } catch (error) {
            this.$root.$emit('error', error);
        } finally {
            this.gettingPayoutTransactions = false;
        }
        if (newItems) return newItems;
    }

    createdFormated(timestamp: number) {
        moment.locale(this.$i18n.locale);
        return moment(timestamp).format('LLL');
    }

    get arrivalFormated() {
        moment.locale(this.$i18n.locale);
        return moment(this.payout.arrival_date).format('LL');
    }
}
