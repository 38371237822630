export const AUTH = {
    INVALID_EMAIL_FORAMT: 'Invalid Email Format.',
    INVALID_STATEMENTDESCRIPTOR_FORMAT_TOO_SHORT: 'Statment Descriptor should be longer than 5 characters.',
    INVALID_STATEMENTDESCRIPTOR_FORMAT_TOO_LONG: 'Statment Descriptor should be less than 22 characters.',
    INVALID_NAME_FORMAT: 'Invalid Username.',
    INVALID_COMPANY_NAME_FORMAT: 'Invalid Company Name',
    INVALID_PASSWORD_FORMAT: 'Invalid Password. Password should be at least 8 characters.',
    CONFIRM_FAILURE: 'Invalid Confirmed Password',
    INVALID_MOBILE_FORMAT: 'Invalid mobile number.',
    INVALID_VERIFICATION_CODE: 'Invalid verification code.',
    INVALID_HKID: 'Invalid HKID.',
    INVALID_SUPPORTING_DOCUMENT: 'Invalid supporting document.',
    ADDRESS_TOO_LONG: 'Address should be less than 200 characters.',
    DESCRIPTION_TOO_LONG: 'Business Description should be less than 200 characters.',
    INVALID_WEBSITE_FORMAT: 'Invalid business website.',
    EMPTY_FIELD: 'Input all the required fields.',
    NO_SELECTED_ACCOUNT: 'Please select Account.',
    NO_SELECTED_USER: 'Please select user.',
    INCORRECT_CONFIRMED_VALUE: 'The confirmed value is incorrect.',
    DUPLICATED_OLD_VALUE: 'The new value should not be the same as the old value.',
    DUPLICATED_MERCHANT_NAME: 'The merchant name has been already used. Please try another name.',
    EMAIL_USED: 'Email address is already registered.',
    SIGNUP_SUCCESS: 'Signup successfully.',
    LOGIN_SUCCESS: 'Login successfully.',
    ACTIVATE_USER_SUCCESS: 'Activate user successfully.',
    INCORRECT_CREDENTIALS: 'Incorrect password.',
    HOLDER_NAME_TOO_LONG: 'Holder Name should be less than 200 characters.',
    INVALID_HOLDER_NAME: 'Invalid holder name.',
    NOT_ACCEPTING_TOS: 'Please read and agree about the terms and conditions of Jarvix Pay',
    DOCUMENT_ERROR:
        "We don't currently support that file type. Try uploading a file with one of the following mimetypes: image/jpeg, image/png",
    LOGIN_AGAIN: 'Login again.',
};
