

































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
// import * as padStart from 'pad-start';
import e_at from '@/utils/catch_error_at';
import { sleep, moment_HK as moment } from '@/store';
import ChargeStatusTag from '@/components/charges/ChargeStatusTag.vue';

interface charge {
    receipt_no: string;
    status: string;
    amount: number;
    currency: number;
    description: string;
    created_at: string;
    refunded_at: string;
}

@Component({
    components: {
        ChargeStatusTag,
    },
})
export default class LabpayReceipt extends Vue {
    private _destroyed = false;
    // prettier-ignore
    @Prop() chargeId!: string;
    private charge: charge | null = null;
    private created_at = '';
    private refunded_at = '';

    private refundDialogConfirm?: HTMLElement;
    private refundDialogConfirm_open = false;
    private refundDialogLoading?: HTMLElement;
    private refundDialogLoading_open = false;
    private refundDialogFail_open = false;

    get canRefund() {
        try {
            if (this.charge) {
                const timeDifference = moment().unix() - moment(this.charge.created_at).unix();

                if (timeDifference > 24 * 60 * 60) {
                    return false;
                }
            }

            return (
                this.charge &&
                this.charge.status === 'succeeded' &&
                this.$store.state.user.permissions_.payment &&
                this.$store.state.user.permissions_.payment.refund
            );
        } catch (error) {
            return false;
        }
    }

    get formatFullCreatedDate() {
        return `${moment(this.created_at).format('LL')} ${moment(this.created_at).format('LTS')}`;
    }

    get formatFullRefundedDate() {
        return `${moment(this.refunded_at).format('LL')} ${moment(this.refunded_at).format('LTS')}`;
    }

    get formatCreatedDate() {
        return moment(this.created_at).format('LL');
    }

    get formatCreatedTime() {
        return moment(this.created_at).format('LT');
    }

    public async created() {
        if (!this.chargeId) return this.$router.go(-1);
        this.$store.commit('isLoading', true);
        try {
            await this.initAttribute();
        } catch (e) {
            return this.$root.$emit('receipt_error', e);
        } finally {
            this.$store.commit('isLoading', false);
        }
    }

    protected async initAttribute() {
        this.charge = await this.$store.dispatch('getWechatPayCharge', this.chargeId);
        if (!this.charge) return;
        this.created_at = this.charge.created_at;
        this.refunded_at = this.charge.refunded_at;
    }

    protected async refundDialogConfirm_accept() {
        this.refundDialogLoading_open = true;
        try {
            await this.$store
                .dispatch('wechatPayRefund', {
                    chargeId: this.chargeId,
                })
                .catch(e_at('refund'));
            await this.initAttribute().catch(e_at('initAttribute'));

            this.refundDialogLoading_open = false;
            this.$root.$emit('confirm', {
                path: '/payment',
                message: `${this.$t('receipt.refundSucceeded')}`,
            });
        } catch (e) {
            if (e.error_at && e.error_at == 'refund') {
                await this.initAttribute().catch(e_at('initAttribute'));
            }
            this.refundDialogFail_open = true;
        } finally {
            this.refundDialogLoading_open = false;
        }
    }

    protected async preventCancel() {
        await sleep(0);
        this.refundDialogLoading_open = true;
        await sleep(10);
        this.refundDialogLoading_open = true;
    }

    protected refund() {
        this.refundDialogConfirm_open = true;
    }
}
