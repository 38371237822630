















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { moment_HK as moment } from '@/store';
import ChargeStatusTag from '@/components/charges/ChargeStatusTag.vue';

@Component({
    components: {
        ChargeStatusTag,
    },
})
export default class CampaignOrderInformation extends Vue {
    @Prop()
    campaignId!: string;

    @Prop()
    orderId!: string;

    get moment() {
        return moment;
    }

    private order = {};

    private charges = {};
    private countryCodeList = this.$store.state.countryCodeList;

    @Watch('$i18n.locale')
    async onLangChanged() {
        const countryCodeObj = await this.countryCodeList.filter((code: any) => code.en === (this.order as any).customer_country)[0];
        let countryName;
        switch (this.$i18n.locale) {
            case 'en':
                countryName = countryCodeObj.en;
                break;
            case 'zh_HK':
                countryName = countryCodeObj.zh;
                break;
            case 'zh_CN':
                countryName = countryCodeObj.cn;
                break;
        }
        (this.order as any).customer_country_code = `${countryName} (${countryCodeObj.phone_code})`;
    }
    public async created() {
        try {
            this.order = await this.$store.dispatch('getCampaignOrder', {
                campaignId: this.campaignId,
                orderId: this.orderId,
            });
            this.charges = await this.$store.dispatch('retreiveAllChargesFromCampaignOrder', {
                orderId: this.orderId,
            });

            const countryCodeObj = await this.countryCodeList.filter((code: any) => code.en === (this.order as any).customer_country)[0];
            let countryName;
            switch (this.$i18n.locale) {
                case 'en':
                    countryName = countryCodeObj.en;
                    break;
                case 'zh_HK':
                    countryName = countryCodeObj.zh;
                    break;
                case 'zh_CN':
                    countryName = countryCodeObj.cn;
                    break;
            }
            (this.order as any).customer_country_code = `${countryName} (${countryCodeObj.phone_code})`;
        } catch (err) {
            this.$root.$emit('error', err);
        }
    }
}
