var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "settings-list-item mdc-typography--body1",
      on: { click: _vm.routerLink }
    },
    [_c("span", [_vm._v(_vm._s(_vm.$t(_vm.params.label)))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }