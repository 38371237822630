export const campaign = {
    title: '營銷活動',
    internalName: '內部名稱',
    externalName: '營銷活動名稱',
    agentPromotion: '代客推廣',
    period: '推廣期',
    copywriting: '宣傳文案',
    content: '內容',
    banners: '廣告圖片',
    details: '匯出報表',
    product: {
        title: '銷售產品',
        label: '產品項目',
        name: '名稱',
        price: '實價',
        originalPrice: '原價',
        customDiscountMessage: '自訂優惠信息',
        quantity: '總數量',
        quantityPerOrder: '每次限購數量',
        discount: '折扣優惠',
        remaining: '剩餘',
    },
    link: '營銷活動連結',
    additionalOptions: {
        title: '收集更多客戶意見',
        dateFormat: '日期形式',
        timeFormat: '時間形式',
        textFormat: '文字形式',
        optionFormat: '選項形式',
        mcFormat: 'MC形式',
        question: '自訂問題',
        branchSelection: '分店選項',
    },
    question: {
        title: '自訂問題',
        label: '問題',
        option: '選項',
        newOption: '新增選項',
        answer: '答案',
    },
    newProduct: '新增產品',
    newLink: '新增連結',
    newQuestion: '新增問題',
    newContact: '新增聯絡資料',
    tnc: '條款及細則 (T&C)',
    pasteTNC: '請貼上服務/產品的條款及細則',
    cta: 'Call To Action',
    currency: '收款貨幣',
    contact: {
        title: '聯絡資料',
        label: '聯絡',
        shopName: '店鋪名稱',
        address: '聯絡地址',
        number: '聯絡電話',
    },
    orderList: '訂單',
    orderSummary: {
        totalTransaction: '總額',
        totalOrder: '總訂單數量',
        paid: '已支付',
        latestTransaction: '最新訂單金額',
        latestDate: '最新訂單日期',
    },
    survey: '問卷',
    cancelMsg: '營銷活動將會關閉。',
    discountMsg: '優惠數量有限，優惠有限期至{0}',
    hitRate: '點擊率',
};
