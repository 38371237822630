export const actions = {
    accept: '確認',
    back: '返回',
    backOrEdit: '返回 / 更改資料',
    create: '建立',
    copy: '複制',
    cancel: '取消',
    change: '更改',
    confirm: '確認',
    createLink: '生成連結',
    delete: '刪除',
    edit: '更改',
    editing: '更改中',
    save: '儲存',
    send: '發送',
    sent: '已發送',
    submit: '提交',
    preview: '預覽',
    print: '印單',
    noMore: '沒有更多',
    loadMore: '載入更多...',
    next: '下一步',
    pay: '支款',
    qrPay: '二維碼支付',
    reset: '重置',
    remind: '提醒',
    refund: '退款',
    register: '立即登記',
    login: '登入',
    logout: '登出',
    loading: '載入中...',
    upload: '上傳文件',
    processing: '處理中...',
    removeAll: '清除全部',
    reupload: '重新上傳文件',
    retake: '重新拍攝文件',
    takePhoto: '拍攝文件',
    noYetUploaded: '收據還未上傳，請按「上傳」按鈕。',
};
