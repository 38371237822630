var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row receipt-copy" }, [
    _c("input", {
      ref: "preview",
      staticClass: "file",
      attrs: { type: "file", name: "preview-file", accept: "image/*" },
      on: {
        change: function($event) {
          _vm.previewFile($event)
        }
      }
    }),
    _c("input", {
      ref: "previewCapture",
      staticClass: "file",
      attrs: {
        type: "file",
        name: "preview-capture",
        accept: "image/*",
        capture: ""
      },
      on: {
        change: function($event) {
          _vm.previewFile($event)
        }
      }
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.curr.url,
            expression: "curr.url"
          }
        ],
        staticClass: "preview",
        class: { "preview-hover": _vm.isPreviewHover },
        on: {
          click: function($event) {
            _vm.isPreviewHover = true
          }
        }
      },
      [
        _vm.curr.url && !_vm.loaded
          ? _c(
              "div",
              {
                staticClass:
                  "spinner spinner__container spinner--active center",
                staticStyle: {
                  "font-size": "1rem",
                  "margin-bottom": "1.125rem"
                }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "spinner spinner__icon",
                    attrs: {
                      viewBox: "0 0 66 66",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("circle", {
                      staticClass: "path",
                      attrs: {
                        fill: "none",
                        "stroke-width": "3.5",
                        "stroke-linecap": "round",
                        cx: "33",
                        cy: "33",
                        r: "30"
                      }
                    })
                  ]
                )
              ]
            )
          : _vm._e(),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.curr.url && _vm.loaded,
              expression: "curr.url && loaded"
            }
          ],
          ref: "img",
          staticClass: "center",
          attrs: { src: _vm.curr.url }
        }),
        _c("div", { staticClass: "middle" }, [
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.curr.url && _vm.loaded,
                  expression: "curr.url && loaded"
                }
              ],
              staticClass: "mdc-button mdc-button--change",
              on: {
                click: function($event) {
                  _vm.$refs.preview.click()
                }
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "mdc-button__icon",
                  staticStyle: { "margin-top": "-1px" },
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 24 24"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M21.02 5H19V2.98c0-.54-.44-.98-.98-.98h-.03c-.55 0-.99.44-.99.98V5h-2.01c-.54 0-.98.44-.99.98v.03c0 .55.44.99.99.99H17v2.01c0 .54.44.99.99.98h.03c.54 0 .98-.44.98-.98V7h2.02c.54 0 .98-.44.98-.98v-.04c0-.54-.44-.98-.98-.98zM16 9.01V8h-1.01c-.53 0-1.03-.21-1.41-.58-.37-.38-.58-.88-.58-1.44 0-.36.1-.69.27-.98H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-8.28c-.3.17-.64.28-1.02.28-1.09-.01-1.98-.9-1.98-1.99zM15.96 19H6c-.41 0-.65-.47-.4-.8l1.98-2.63c.21-.28.62-.26.82.02L10 18l2.61-3.48c.2-.26.59-.27.79-.01l2.95 3.68c.26.33.03.81-.39.81z"
                    }
                  })
                ]
              ),
              _c("span", { staticClass: "mdc-button__text" }, [
                _vm._v(_vm._s(_vm.$t("actions.reupload")))
              ])
            ]
          ),
          _vm.isMobile
            ? _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.curr.url && _vm.loaded,
                      expression: "curr.url && loaded"
                    }
                  ],
                  staticClass: "mdc-button mdc-button--change",
                  on: {
                    click: function($event) {
                      _vm.$refs.previewCapture.click()
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "mdc-button__icon",
                      staticStyle: {
                        "margin-top": "-1px",
                        "padding-right": "2px"
                      },
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M21 6h2c.5 0 1-.4 1-1s-.5-1-1-1h-2V2c0-.5-.4-1-1-1-.5 0-1 .5-1 1v2h-2c-.5 0-1 .4-1 1s.5 1 1 1h2v2c0 .6.5 1 1 1s1-.4 1-1V6z"
                        }
                      }),
                      _c("circle", { attrs: { cx: "11", cy: "14", r: "3" } }),
                      _c("path", {
                        attrs: {
                          d:
                            "M1 8v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V9.7c-.3.2-.6.3-1 .3-1.1 0-2-.9-2-2V7h-1c-1.1 0-2-.9-2-2 0-.4.1-.7.3-1H8.9c-.6 0-1.1.2-1.5.7L6.2 6H3c-1.1 0-2 .9-2 2zm5 6c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z"
                        }
                      })
                    ]
                  ),
                  _c("span", { staticClass: "mdc-button__text" }, [
                    _vm._v(_vm._s(_vm.$t("actions.retake")))
                  ])
                ]
              )
            : _vm._e()
        ])
      ]
    ),
    _vm.curr.url && !_vm.curr.uploaded
      ? _c(
          "p",
          {
            staticStyle: {
              color: "red",
              "text-align": "center",
              "margin-left": "auto",
              "margin-right": "auto"
            }
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("actions.noYetUploaded")) + "\n    "
            )
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "buttons__group center" }, [
      !_vm.curr.url && _vm.addButtonShown && !_vm.existing
        ? _c(
            "button",
            {
              staticClass: "mdc-button mdc-button--upload",
              on: {
                click: function($event) {
                  _vm.$refs.preview.click()
                }
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "mdc-button__icon",
                  staticStyle: { "margin-top": "-1px" },
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 24 24"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M21.02 5H19V2.98c0-.54-.44-.98-.98-.98h-.03c-.55 0-.99.44-.99.98V5h-2.01c-.54 0-.98.44-.99.98v.03c0 .55.44.99.99.99H17v2.01c0 .54.44.99.99.98h.03c.54 0 .98-.44.98-.98V7h2.02c.54 0 .98-.44.98-.98v-.04c0-.54-.44-.98-.98-.98zM16 9.01V8h-1.01c-.53 0-1.03-.21-1.41-.58-.37-.38-.58-.88-.58-1.44 0-.36.1-.69.27-.98H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-8.28c-.3.17-.64.28-1.02.28-1.09-.01-1.98-.9-1.98-1.99zM15.96 19H6c-.41 0-.65-.47-.4-.8l1.98-2.63c.21-.28.62-.26.82.02L10 18l2.61-3.48c.2-.26.59-.27.79-.01l2.95 3.68c.26.33.03.81-.39.81z"
                    }
                  })
                ]
              ),
              _c("span", { staticClass: "mdc-button__text" }, [
                _vm._v(_vm._s(_vm.$t("actions.upload")))
              ])
            ]
          )
        : _vm._e(),
      _vm.isMobile && !_vm.curr.url && _vm.addButtonShown && !_vm.existing
        ? _c(
            "button",
            {
              staticClass: "mdc-button mdc-button--upload",
              on: {
                click: function($event) {
                  _vm.$refs.previewCapture.click()
                }
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "mdc-button__icon",
                  staticStyle: { "margin-top": "-1px", "padding-right": "2px" },
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 24 24"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M21 6h2c.5 0 1-.4 1-1s-.5-1-1-1h-2V2c0-.5-.4-1-1-1-.5 0-1 .5-1 1v2h-2c-.5 0-1 .4-1 1s.5 1 1 1h2v2c0 .6.5 1 1 1s1-.4 1-1V6z"
                    }
                  }),
                  _c("circle", { attrs: { cx: "11", cy: "14", r: "3" } }),
                  _c("path", {
                    attrs: {
                      d:
                        "M1 8v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V9.7c-.3.2-.6.3-1 .3-1.1 0-2-.9-2-2V7h-1c-1.1 0-2-.9-2-2 0-.4.1-.7.3-1H8.9c-.6 0-1.1.2-1.5.7L6.2 6H3c-1.1 0-2 .9-2 2zm5 6c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z"
                    }
                  })
                ]
              ),
              _c("span", { staticClass: "mdc-button__text" }, [
                _vm._v(_vm._s(_vm.$t("actions.takePhoto")))
              ])
            ]
          )
        : _vm._e(),
      _vm.curr.url && _vm.editing && !_vm.curr.uploaded
        ? _c(
            "button",
            {
              staticClass: "mdc-button mdc-button--cancel",
              on: { click: _vm.cancelFile }
            },
            [
              _c("span", { staticClass: "mdc-button__text" }, [
                _vm._v(_vm._s(_vm.$t("actions.cancel")))
              ])
            ]
          )
        : _vm._e(),
      _vm.curr.url && !_vm.curr.uploaded
        ? _c(
            "button",
            {
              staticClass: "mdc-button mdc-button--upload",
              on: { click: _vm.uploadFile }
            },
            [
              _c("span", { staticClass: "mdc-button__text" }, [
                _vm._v(_vm._s(_vm.$t("actions.upload")))
              ])
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }