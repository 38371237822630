





















































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import PlatformAgreement from '@/components/PlatformAgreement.vue';
import Privacy from '@/components/Privacy.vue';

import utils from '@/utils';

@Component({
    components: {
        PlatformAgreement,
        Privacy,
    },
})
export default class PoweredBy extends Vue {
    private showPlatformAgreement() {
        (this.$refs.platformAgreement as any).show();
    }

    private showPrivacy() {
        (this.$refs.privacy as any).show();
    }
}
