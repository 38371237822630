




import { Component, Vue, Prop } from 'vue-property-decorator';

interface TAndC {
    [c: string]: string;
}

interface statusList {
    [index: string]: TAndC;
}

@Component
export default class RemoteStatusTag extends Vue {
    // prettier-ignore
    @Prop() status!: String | Object;
    // prettier-ignore
    @Prop(Boolean) small?: Boolean;

    private statusProps_: statusList = {
        pending: {
            class: 'tags--remote--pending',
            text: 'status.pending',
        },
        succeeded: {
            class: 'tags--succeeded',
            text: 'status.succeeded',
        },
        failed: {
            class: 'tags--failed',
            text: 'status.failed',
        },
        default: {
            class: 'tags--remote',
            text: 'general.remote',
        },
    };

    get status_() {
        return this.status;
    }

    get statusProps() {
        for (let key in this.statusProps_) {
            // console.log(key);
            if (this.statusProps_.hasOwnProperty(key)) {
                this.statusProps_[key].text = this.$t(this.statusProps_[key].text) as string;
            }
        }
        return this.statusProps_;
    }
}
