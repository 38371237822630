var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "campaign-purchase-information" }, [
    _c(
      "div",
      { staticClass: "activity-payment-campaign-item mdc-layout-grid" },
      [
        _c("div", { staticClass: "mdc-layout-grid__inner" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet campaign-purchase-information",
              staticStyle: { flex: "1 1 auto" }
            },
            [
              _c(
                "div",
                { staticClass: "campaign-purchase-information__wrapper-inner" },
                [
                  _c(
                    "div",
                    { staticClass: "campaign-purchase-information__inner" },
                    [
                      _vm._m(0),
                      _c(
                        "div",
                        { staticClass: "mdc-typography--subtitle1 enlarged" },
                        [_vm._v("商戶 - " + _vm._s(_vm.displayName || "—"))]
                      ),
                      _c(
                        "div",
                        { staticClass: "mdc-typography--subtitle1 enlarged" },
                        [
                          _vm._v(
                            "限時優惠 - " +
                              _vm._s(
                                _vm.campaignData.externalCampaignName || "—"
                              )
                          )
                        ]
                      ),
                      _c("br"),
                      _c("div", { staticClass: "mdc-typography--body2" }, [
                        _vm._v("請細閱有關款項資料。如有問題，請與我們聯絡。")
                      ]),
                      _vm._m(1),
                      _vm._l(
                        _vm.purchaseData.productList.filter(function(product) {
                          return product.selected === true
                        }),
                        function(product) {
                          return _c(
                            "div",
                            { key: product.id, staticClass: "product" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mdc-typography--body1 product--name"
                                },
                                [
                                  _vm._v(
                                    _vm._s(product.name) +
                                      " x " +
                                      _vm._s(product.quantity)
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mdc-typography--body1 product--amount"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currencySymbolDisplay")(
                                        _vm.campaignData.currency
                                      )
                                    ) + _vm._s(product.totalPrice)
                                  )
                                ]
                              )
                            ]
                          )
                        }
                      ),
                      _c("hr"),
                      _c("div", { staticClass: "product" }, [
                        _c(
                          "div",
                          { staticClass: "mdc-typography--subtitle1" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("campaign.orderSummary.totalTransaction")
                              )
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "mdc-typography--subtitle1 ml-auto" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("currencySymbolDisplay")(
                                  _vm.campaignData.currency
                                )
                              ) + _vm._s(_vm.purchaseData.sum)
                            )
                          ]
                        )
                      ]),
                      _vm.campaignData.branchOption &&
                      _vm.campaignData.accountList.length
                        ? _c(
                            "div",
                            {
                              staticClass: "mdc-typography--subtitle1 enlarged"
                            },
                            [_vm._v("分店")]
                          )
                        : _vm._e(),
                      _vm.campaignData.branchOption &&
                      _vm.campaignData.accountList.length
                        ? _c(
                            "div",
                            { staticClass: "mdc-typography--subtitle1" },
                            [
                              _c(
                                "div",
                                { staticClass: "mdc-typography--body1" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.branchName) +
                                      " - " +
                                      _vm._s(_vm.branchAddress)
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "mdc-typography--subtitle1 enlarged" },
                        [_vm._v("您的聯絡資料")]
                      ),
                      _c(
                        "div",
                        { staticClass: "mdc-typography--subtitle1 customer" },
                        [
                          _c("div", { staticClass: "mdc-typography--body1" }, [
                            _vm._v(
                              "姓名: " + _vm._s(_vm.purchaseData.customer.name)
                            )
                          ]),
                          _vm.campaignData.genderOption
                            ? _c(
                                "div",
                                { staticClass: "mdc-typography--body1" },
                                [
                                  _vm._v(
                                    "性別: " +
                                      _vm._s(
                                        _vm.purchaseData.customer.gender ===
                                        "male"
                                          ? "男性"
                                          : "女性"
                                      )
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.campaignData.ageOption
                            ? _c(
                                "div",
                                { staticClass: "mdc-typography--body1" },
                                [
                                  _vm._v(
                                    "年齡: " +
                                      _vm._s(_vm.purchaseData.customer.age)
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "mdc-typography--body1" }, [
                            _vm._v(
                              "電話: " +
                                _vm._s(
                                  _vm.purchaseData.customer.countryCode
                                    .phone_code
                                ) +
                                _vm._s(_vm.purchaseData.customer.contact)
                            )
                          ]),
                          _vm.campaignData.emailOption
                            ? _c(
                                "div",
                                { staticClass: "mdc-typography--body1" },
                                [
                                  _c("div", [_vm._v("電郵:")]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.purchaseData.customer.email)
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.campaignData.addressOption
                            ? _c(
                                "div",
                                { staticClass: "mdc-typography--body1" },
                                [
                                  _c("div", [_vm._v("地址:")]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.purchaseData.customer.address)
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm.campaignData.dateOption ||
                      _vm.campaignData.timeOption ||
                      _vm.campaignData.specialRequestOption
                        ? _c(
                            "div",
                            {
                              staticClass: "mdc-typography--subtitle1 enlarged"
                            },
                            [_vm._v("其他資料")]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "mdc-typography--subtitle1 others" },
                        [
                          _vm.campaignData.dateOption
                            ? _c(
                                "div",
                                { staticClass: "mdc-typography--body1" },
                                [
                                  _vm._v(
                                    "預訂日期: " +
                                      _vm._s(
                                        _vm.purchaseData.customer.reservedDate
                                      )
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.campaignData.timeOption
                            ? _c(
                                "div",
                                { staticClass: "mdc-typography--body1" },
                                [
                                  _vm._v(
                                    "預訂時間: " +
                                      _vm._s(
                                        _vm.purchaseData.customer.reservedTime
                                      )
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.campaignData.specialRequestOption
                            ? _c(
                                "div",
                                { staticClass: "mdc-typography--body1" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "padding-bottom": "8px" }
                                    },
                                    [_vm._v("諮詢內容:")]
                                  ),
                                  _vm._l(
                                    _vm.purchaseData.customer.specialRequest.split(
                                      "\n"
                                    ),
                                    function(line, index) {
                                      return [
                                        _c("div", { key: index }, [
                                          _vm._v(_vm._s(line))
                                        ])
                                      ]
                                    }
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm.campaignData.mcOption
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mdc-typography--subtitle1 enlarged"
                              },
                              [_vm._v("問卷內容")]
                            ),
                            _vm._l(_vm.campaignData.questionList, function(
                              question,
                              i
                            ) {
                              return _c("div", { key: question.id }, [
                                _c("div", [_vm._v(_vm._s(question.name))]),
                                _c(
                                  "div",
                                  { staticClass: "radio-margin-top" },
                                  _vm._l(question.options, function(option, j) {
                                    return _c(
                                      "mdc-radio",
                                      {
                                        key: j,
                                        staticClass: "custom-radio",
                                        attrs: {
                                          name: "radios_answer_" + i,
                                          checked:
                                            option.id ===
                                            _vm.purchaseData.questionList.find(
                                              function(q) {
                                                return q.id === question.id
                                              }
                                            ).answerId,
                                          disabled: true
                                        }
                                      },
                                      [_vm._v(_vm._s(option.name))]
                                    )
                                  })
                                )
                              ])
                            })
                          ]
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet tnc",
                          on: { click: _vm.openTNCDialog }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "padding-bottom": "12px" } },
                            [_vm._v("商品條款及細則 (T&C)")]
                          ),
                          _vm._l(_vm.tncPreview.split("\n"), function(
                            line,
                            index
                          ) {
                            return [
                              _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: { "padding-bottom": "8px" }
                                },
                                [_vm._v(_vm._s(line))]
                              )
                            ]
                          })
                        ],
                        2
                      ),
                      _c(
                        "mdc-dialog",
                        {
                          attrs: {
                            title: "商品條款及細則 (T&C)",
                            accept: "關閉",
                            scrollable: true
                          },
                          model: {
                            value: _vm.tncDialog,
                            callback: function($$v) {
                              _vm.tncDialog = $$v
                            },
                            expression: "tncDialog"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet tnc"
                            },
                            [
                              _vm._l(_vm.campaignData.tnc.split("\n"), function(
                                line,
                                index
                              ) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: { "padding-bottom": "8px" }
                                    },
                                    [_vm._v(_vm._s(line))]
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ],
                    2
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
            },
            [
              _c(
                "button",
                {
                  staticClass: "mdc-button",
                  on: {
                    click: function($event) {
                      _vm.$emit("back")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.back")))]
              ),
              _c(
                "button",
                {
                  staticClass: "mdc-fab mdc-fab--extended",
                  attrs: { "aria-label": _vm.$t("actions.confirm") },
                  on: {
                    click: function($event) {
                      _vm.$emit("next")
                    }
                  }
                },
                [
                  _c("span", { staticClass: "mdc-fab__label" }, [
                    _vm._v(_vm._s(_vm.$t("actions.confirm")))
                  ]),
                  _c(
                    "svg",
                    {
                      staticClass: "mdc-fab__icon",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mdc-typography--headline6" }, [
      _c("span", [_vm._v("正在搶購的優惠項目")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product" }, [
      _c("div", { staticClass: "mdc-typography--subtitle1 product--name" }, [
        _vm._v("商品")
      ]),
      _c("div", { staticClass: "mdc-typography--subtitle1 product--amount" }, [
        _vm._v("金額")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }