







import { Component, Prop, Vue } from 'vue-property-decorator';
import { MDCDialog } from '@material/dialog';
import PRIVACY_HTML from '@/constants/PRIVACY_HTML';

@Component
export default class Terms extends Vue {
    private privacyHTML = PRIVACY_HTML;
    private modalOpen: boolean = false;
    public dialog!: any;

    public show() {
        this.modalOpen = true;
    }
}
