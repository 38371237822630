








































import { Component, Vue, Prop } from 'vue-property-decorator';
// import RemoteStatusTag from '@/components/remote-payment/RemoteStatusTag.vue';
import * as moment from 'moment';
// import * as uuid62 from 'uuid62';

@Component({})
export default class CampaignSummaryItem extends Vue {
    @Prop() campaign: any;

    get agentPromotion() {
        return this.campaign.agentPromotion;
    }

    get status() {
        if (this.campaign.status === 'canceled') {
            return 'canceled';
        }

        // if (moment(this.campaign.from) > moment()) {
        //     return 'inactive';
        // }

        // if (moment(this.campaign.from) > moment()) {
        //     return 'notYetStarted';
        // }

        if (moment(this.campaign.to).add(1, 'days') < moment()) {
            return 'completed';
        }

        return 'active';
    }

    get from() {
        return moment(this.campaign.from).format('LL');
    }

    get to() {
        return moment(this.campaign.to).format('LL');
    }

    get moment() {
        moment.locale(this.$i18n.locale);
        return moment;
    }
}
