var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "client-receipt" }, [
    _c(
      "div",
      { staticClass: "app-page app-page-payment-history app-page--active" },
      [
        _c("div", { staticClass: "app-page-container" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid app-page-activity app-page-activity--active"
            },
            [
              _vm._m(0),
              _c("br"),
              _vm._m(1),
              _c("br"),
              _c("div", { staticClass: "activity-payment-history-expanded" }, [
                _c("div", { staticClass: "payment-history-expanded-item" }, [
                  _c("div", {
                    staticClass: "payment-history-expanded-item__background"
                  }),
                  _c(
                    "div",
                    { staticClass: "payment-history-expanded-item__content" },
                    [
                      _c("div", { staticClass: "section" }, [
                        _vm._m(2),
                        _c(
                          "div",
                          {
                            staticClass:
                              "row row--more-margin row--space-between"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "payment-history-expanded-item__date mdc-typography--caption"
                              },
                              [_vm._v(_vm._s(_vm.formatCreatedDate))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "payment-history-expanded-item__time mdc-typography--caption"
                              },
                              [_vm._v(_vm._s(_vm.formatCreatedTime))]
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "section" }, [
                        _vm._m(3),
                        _c("div", { staticClass: "row" }, [
                          _vm.displayName
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "payment-history-expanded-item__desc mdc-typography--body1"
                                },
                                [_vm._v(_vm._s(_vm.displayName))]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "payment-history-expanded-item__desc mdc-typography--body1"
                                },
                                [_vm._v("—")]
                              )
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "section" },
                        [
                          _vm._m(4),
                          _vm._l(_vm.productList, function(product) {
                            return _c(
                              "div",
                              { key: product.id, staticClass: "product" },
                              [
                                _c("div", { staticClass: "product--name" }, [
                                  _vm._v(
                                    _vm._s(product.name) +
                                      " x " +
                                      _vm._s(product.quantity)
                                  )
                                ]),
                                _c("div", { staticClass: "product--amount" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(product.totalPrice)
                                    )
                                  )
                                ])
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]
                  )
                ])
              ])
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
      },
      [_c("div", [_vm._v("取得免費優惠")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
      },
      [
        _c("div", { staticClass: "mdc-typography--body1" }, [
          _vm._v(
            "您已經成功取得免費優惠項目，如有任何查詢，請致電本頁面下方顯示的聯絡電話。"
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row row--more-margin" }, [
      _c(
        "div",
        {
          staticClass:
            "payment-history-expanded-item__amount mdc-typography--headline4"
        },
        [_vm._v("免費優惠")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "mdc-typography--headline6" }, [_vm._v("商戶")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "mdc-typography--headline6" }, [
        _vm._v("商品詳情")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }