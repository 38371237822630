































import { Component, Vue } from 'vue-property-decorator';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
const langs = [
    {
        name: 'English',
        nativeName: 'English',
        value: 'en',
    },
    {
        name: 'Traditional_Chinese',
        nativeName: '中文(香港)',
        value: 'zh_HK',
    },
    {
        name: 'Simplified_Chinese',
        nativeName: '中文(简体)',
        value: 'zh_CN',
    },
    {
        name: 'Japanese',
        nativeName: '日本語',
        value: 'jp',
    },
];
@Component
export default class LanguageSettings extends Vue {
    private mdcTextField: any;
    private selectedLanguage = '';
    private currentLanguage = '';
    private langs = langs;

    public async mounted() {
        this.initMDCTextFields();
    }

    protected async submit() {
        return this.$store
            .dispatch('updateUserLanguage', {
                languageCode: this.selectedLanguage,
            })
            .then((response: any) => {
                this.$i18n.locale = response.languageCode;

                if (this.$i18n.locale == 'zh_HK') {
                    (self as any).zE('webWidget', 'setLocale', 'zh_TW');
                } else if (this.$i18n.locale == 'en') {
                    (self as any).zE('webWidget', 'setLocale', 'en-us');
                }

                return this.$root.$emit('confirm', {
                    path: '/settings',
                    title: this.$t('status.success'),
                });
            })
            .catch((error: any) => {
                console.log(error);
                return this.$root.$emit('error', { message: this.$t('settings.language.errorMessage') });
            });
    }

    private initMDCTextFields() {
        this.mdcTextField = Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
