


















import { Component, Vue, Watch } from 'vue-property-decorator';
import NotificationMessageList from '@/components/notification/NotificationMessageList.vue';
import vClickOutside from 'v-click-outside';

@Component({
    components: {
        NotificationMessageList,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
})
export default class NotificationCenter extends Vue {
    private open = false;
    private title = 'Notification Center';
    private notificationMessages = [];
    componentKey = 0;

    onClickOutside(event: any) {
        console.log('Clicked outside. Event: ', event);
        this.open = false;
        console.log('open:', this.open);
    }

    created() {
        this.$root.$on('notification', (payload: any) => {
            if (payload) {
                if (payload.messages) {
                    this.notificationMessages = payload.messages;
                }
                this.open = payload.open;
                console.log('messages in notification center:', this.notificationMessages);
                console.log('open in center:', this.open);
            }
        });
        // this.notificationMessages = this.$store.state.notificationMessages;
    }

    async clearAllMessages() {
        this.open = false;
        await this.$store.dispatch('removeAllNotificationMessages');
    }
}
