























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ExpiredLink extends Vue {
    @Prop()
    expiredStatus!: string;

    private close() {
        window.close();
    }
}
