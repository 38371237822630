import { STRIPE_CODE, STRIPE_DECLINE_CODE } from '@/constants/ERROR';

export function standardMethodResponse(method: Promise<any>, context: any) {
    return method
        .then((response: any) => {
            const res = getResObj(response);
            context.commit('isLoading', false);
            return res;
        })
        .catch((error: any) => {
            const err = getErrObj(error);
            context.commit('isLoading', false);
            err.rawError = error;
            throw err;
        });
}

export const getResObj = (res: any) => {
    // stripe token is sucessfully created
    if (res.token) {
        return res;
    }

    // stripe element error catching becoz error is on response
    if (res.error) {
        throw res.error;
    }

    // our backend API response
    if (!res.data.success) {
        throw res.data;
    }

    if (res.data.hasOwnProperty('has_more')) {
        return res.data;
    }

    return res.data.data;
};

export const getErrObj = (err: any) => {
    // our backend API error and esp for payment
    if (err.response && err.response.data.error.error_body) {
        const error = err.response.data.error;
        const user_message = STRIPE_CODE[error.error_body.code]
            ? `errors.STRIPE_CODE.` + error.error_body.code
            : STRIPE_DECLINE_CODE[error.error_body.decline_code]
                ? `errors.STRIPE_DECLINE_CODE.` + error.error_body.decline_code
                : error.error_body.message === 'Your card was declined.'
                    ? `errors.STRIPE_DECLINE_CODE.your_card_was_declined`
                    : error.error_body.message;

        return { error_type: `payment_${error.error_body.type}`, user_message: user_message, error_body: error.error_body };
    }

    // our other backend API error
    if (err.response) {
        return err.response.data.error;
    }

    if (err.message && err.type) {
        return { error_type: `payment_${err.type}`, user_message: err.message, error_body: err };
    }

    if (err.message) {
        return { user_message: err.message };
    }

    return { error_type: 'unknown_error', user_message: 'Please contact Jarvix Pay.' };
};
