























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { currencyNameWithSymbol } from '../../utils/helpers/currencyHelper';

@Component({
    filters: {
        currencySymbolDisplay: currencyNameWithSymbol,
    },
})
export default class ProductSummary extends Vue {
    @Prop()
    chargeData!: any;
}
