var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "activity-settings-export-download app-page-activity app-page-activity--active mdc-layout-grid"
    },
    [
      _c("div", { staticClass: "layout-grid__inner" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card__section" }, [
            _c("div", { staticClass: "mdc-typography--headline6" }, [
              _vm._v(_vm._s(_vm.$t("settings.export.downloadData")))
            ]),
            _c("div", { staticClass: "mdc-typography--body2" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t("settings.export.expire", [
                      _vm
                        .moment(_vm.expire_at)
                        .format("LLL")
                        .replace(" ", "")
                    ])
                  ) +
                  "\n                "
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "card__section download-link" },
            [
              _c("mdc-textfield", {
                ref: "textField",
                staticClass: "download-link__input",
                attrs: {
                  label: _vm.$t("settings.export.longPressOrCopy"),
                  outline: "",
                  readonly: ""
                },
                model: {
                  value: _vm.downloadLink,
                  callback: function($$v) {
                    _vm.downloadLink = $$v
                  },
                  expression: "downloadLink"
                }
              }),
              _c(
                "a",
                {
                  staticClass: "download-link__link",
                  attrs: { href: _vm.downloadLink },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.$emit("click-prevented")
                    }
                  }
                },
                [_c("span", [_vm._v(_vm._s(_vm.downloadLink))])]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "card__section" },
            [
              _c("mdc-textfield", {
                ref: "textField",
                staticClass: "download-link__input",
                staticStyle: { "pointer-events": "none" },
                attrs: {
                  label: _vm.$t("settings.password.label"),
                  outline: "",
                  readonly: ""
                },
                model: {
                  value: _vm.code,
                  callback: function($$v) {
                    _vm.code = $$v
                  },
                  expression: "code"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.type == "statement",
                  expression: "type == 'statement'"
                }
              ],
              staticClass: "mdc-typography--body2"
            },
            [
              _vm._v(
                "\n                A copy has been sent to your email.\n            "
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "card__section buttons-group" },
            [
              _c(
                "mdc-button",
                {
                  attrs: { outlined: "" },
                  on: { click: _vm.downloadButtonClicked }
                },
                [
                  _c("span", { staticClass: "mdc-button__text" }, [
                    _vm._v(_vm._s(_vm.$t("settings.export.downloadAndOpen")))
                  ])
                ]
              ),
              _c(
                "mdc-button",
                {
                  ref: "copyButton",
                  attrs: { unelevated: "" },
                  on: { click: _vm.copyButtonClicked }
                },
                [
                  _c("span", { staticClass: "mdc-button__text" }, [
                    _vm._v(
                      _vm._s(_vm.$t("settings.export.copyLinkAndPassword"))
                    )
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.copyMessage,
                          expression: "copyMessage"
                        }
                      ],
                      staticClass: "copy-button--message"
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.copyMessage))])]
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }