var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message-item" }, [
    _c("div", { staticClass: "payment-history-list-item" }, [
      _c("div", { staticClass: "mdc-card demo-card demo-ui-control" }, [
        _c(
          "div",
          {
            staticClass:
              "mdc-card__primary-action demo-card__primary-action demo-card__priamry_hover",
            attrs: { tabindex: "0" },
            on: { click: _vm.goRoute }
          },
          [
            _c("div", { staticClass: "demo-card__primary" }, [
              _c(
                "div",
                {
                  staticClass:
                    "message mdc-typography mdc-typography--subtitle1"
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.item.message.notification.title) +
                      "\n                    "
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "message mdc-layout-grid__cell payment-history-list-item__desc mdc-typography--body2"
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.item.message.notification.body) +
                      "\n                    "
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "message mdc-layout-grid__cell payment-history-list-item__datetime mdc-typography--caption"
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.item.message.data.date) +
                      "\n                    "
                  )
                ]
              )
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }