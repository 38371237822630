



































import { Component, Vue, Prop } from 'vue-property-decorator';
import RecurrentStatusTag from '@/components/recurrents/RecurrentStatusTag.vue';
// import ChargeStatusTag from '@/components/charges/ChargeStatusTag.vue';
import { moment_HK as moment } from '@/store';

@Component({
    filters: {
        currencyDisplay: (currency: string) => {
            if (currency.toLowerCase() == 'usd') {
                return currency.toUpperCase() + '$';
            } else if (currency.toLowerCase() == 'hkd') {
                return currency.toUpperCase() + '$';
            } else if (currency.toLowerCase() == 'cny') {
                return currency.toUpperCase() + '¥';
            } else if (currency.toLowerCase() == 'mop') {
                return currency.toUpperCase() + '$';
            } else if (currency.toLowerCase() == 'jpy') {
                return currency.toUpperCase() + '¥';
            } else if (currency.toLowerCase() == 'gbp') {
                return currency.toUpperCase() + '£';
            }
        },
    },
    components: {
        RecurrentStatusTag,
    },
})
export default class RecurrentItem extends Vue {
    @Prop()
    recurrent!: {
        amount: number;
        status: string;
        currency: string;
        charge_count: number;
        duration: number;
        auto_extend: boolean;
        description: string;
        created_at: string;
    };

    get formatDate() {
        return moment(this.recurrent.created_at).format('LLL');
    }

    get moment() {
        return moment;
    }
}
