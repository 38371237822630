var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "activity-payment-summary",
      attrs: { id: "remote-payment" }
    },
    [
      !_vm.confirmed
        ? _c("ProductEditInfo", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editing,
                expression: "editing"
              }
            ],
            on: { update: _vm.updateProduct }
          })
        : _vm._e(),
      _c("ProductSummary", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.editing,
            expression: "!editing"
          }
        ],
        attrs: { chargeData: _vm.chargeData }
      }),
      _c("div", { staticClass: "mdc-layout-grid__inner" }, [
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
          },
          [_vm._v(_vm._s(_vm.$t("customer.title")))]
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.editing,
              expression: "editing"
            }
          ],
          staticClass: "mdc-layout-grid__inner",
          attrs: { id: "customer-edit-list" }
        },
        [
          _vm._l(_vm.customerList, function(customer, index) {
            return _c(
              "div",
              {
                key: customer.id,
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
              },
              [
                _c("CustomerEditItem", {
                  attrs: {
                    customer: customer,
                    phoneList: _vm.phoneList,
                    editingCustomerList: _vm.editingCustomerList,
                    index: index + 1
                  },
                  on: {
                    edit: function($event) {
                      _vm.edit($event, index)
                    },
                    save: function($event) {
                      _vm.save($event, index)
                    },
                    delete: function($event) {
                      _vm.deleteCustomer(index)
                    }
                  }
                })
              ],
              1
            )
          }),
          _vm.editing &
          (!_vm.editingCustomerList || _vm.customerList.length == 0)
            ? _c(
                "div",
                {
                  staticClass:
                    "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet customer-list-item"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "add-customer-button",
                      on: { click: _vm.addCustomer }
                    },
                    [_vm._v("+ " + _vm._s(_vm.$t("customer.new")))]
                  )
                ]
              )
            : _vm._e()
        ],
        2
      ),
      !_vm.editing
        ? _c(
            "div",
            {
              staticClass: "mdc-layout-grid__inner",
              attrs: { id: "customer-list" }
            },
            _vm._l(_vm.customerList, function(customer) {
              return _c(
                "div",
                {
                  key: customer.id,
                  staticClass:
                    "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                },
                [
                  _c("CustomerItem", {
                    attrs: {
                      customer: customer,
                      message: _vm.chargeData.message
                    }
                  })
                ],
                1
              )
            })
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mdc-layout-grid__inner", attrs: { id: "button-list" } },
        [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
            },
            [
              !_vm.editing && !_vm.confirmed
                ? _c(
                    "button",
                    {
                      staticClass: "mdc-button",
                      attrs: { disabled: _vm.confirmed },
                      on: { click: _vm.backToEdit }
                    },
                    [_vm._v(_vm._s(_vm.$t("actions.backOrEdit")))]
                  )
                : _vm._e(),
              !_vm.editing && _vm.confirmed
                ? _c(
                    "button",
                    {
                      staticClass: "mdc-button",
                      on: {
                        click: function($event) {
                          _vm.$emit("toHistoryTab")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("status.title")))]
                  )
                : _vm._e(),
              _vm.editing
                ? _c(
                    "button",
                    {
                      staticClass:
                        "mdc-fab mdc-fab--extended mdc-button--right-button",
                      attrs: { "aria-label": "下一步" },
                      on: { click: _vm.confirm }
                    },
                    [
                      _c("span", { staticClass: "mdc-fab__label" }, [
                        _vm._v(_vm._s(_vm.$t("actions.next")))
                      ]),
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-fab__icon",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 24 24"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                            }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              !_vm.editing && !_vm.confirmed
                ? _c(
                    "button",
                    {
                      staticClass:
                        "mdc-fab mdc-fab--extended mdc-button--right-button",
                      attrs: { "aria-label": _vm.$t("actions.createLink") },
                      on: { click: _vm.createLinks }
                    },
                    [
                      _c("span", { staticClass: "mdc-fab__label" }, [
                        _vm._v(_vm._s(_vm.$t("actions.createLink")))
                      ]),
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-fab__icon",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 24 24"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                            }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              !_vm.editing && _vm.confirmed
                ? _c(
                    "button",
                    {
                      staticClass:
                        "mdc-fab mdc-fab--extended mdc-button--right-button",
                      attrs: { "aria-label": _vm.$t("actions.back") },
                      on: { click: _vm.reset }
                    },
                    [
                      _c("span", { staticClass: "mdc-fab__label" }, [
                        _vm._v(_vm._s(_vm.$t("actions.back")))
                      ])
                    ]
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }