export const status = {
    title: '狀態',
    all: '全部',
    succeeded: '成功付款',
    authorized: '已授權',
    unauthorized: '取消授權',
    pending: '處理中',
    paying: '付款中',
    failed: '付款失敗',
    refunded: '已退款',
    canceled: '已取消',
    arrived: '已到帳',
    inTransit: '轉帳中',
    active: '使用中',
    inactive: '未能正常付款',
    notYetStarted: '未開始',
    completed: '已完結',
    paused: '已暫停',
    agentPromotion: '代客推廣',
    free: '免費',

    success: '成功',
    failure: '失敗',
};
