var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "activity-settings-printer app-page-activity mdc-layout-grid app-page-activity--active"
    },
    [
      _vm._l(_vm.items, function(item) {
        return _c("SettingsListItem", {
          key: _vm.$t(item.label),
          attrs: { params: item }
        })
      }),
      _vm.nativeJPAYQrScanner
        ? [
            _c(
              "mdc-dialog",
              {
                ref: "connectingDialogLoading",
                staticClass: "dialog--no_cancel",
                attrs: {
                  title: _vm.$t("settings.printer.connectingTitle"),
                  accept: ""
                },
                on: { cancel: _vm.preventCancel },
                model: {
                  value: _vm.connectingDialogLoading__open,
                  callback: function($$v) {
                    _vm.connectingDialogLoading__open = $$v
                  },
                  expression: "connectingDialogLoading__open"
                }
              },
              [
                _c("div", { staticStyle: { "margin-bottom": ".5em" } }, [
                  _vm._v(_vm._s(_vm.$t("settings.printer.pending")) + "...")
                ])
              ]
            ),
            _c(
              "mdc-dialog",
              {
                attrs: {
                  title: _vm.$t("settings.printer.succeededTitle"),
                  accept: _vm.$t("actions.confirm")
                },
                model: {
                  value: _vm.connectingDialogSuccess__open,
                  callback: function($$v) {
                    _vm.connectingDialogSuccess__open = $$v
                  },
                  expression: "connectingDialogSuccess__open"
                }
              },
              [
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("settings.printer.succeededMsg")))
                ])
              ]
            ),
            _c(
              "mdc-dialog",
              {
                attrs: {
                  title: _vm.$t("settings.printer.failedTitle"),
                  accept: _vm.$t("retry"),
                  cancel: _vm.$t("actions.cancel")
                },
                on: { accept: _vm.launchScannerNative },
                model: {
                  value: _vm.connectingDialogFail__open,
                  callback: function($$v) {
                    _vm.connectingDialogFail__open = $$v
                  },
                  expression: "connectingDialogFail__open"
                }
              },
              [
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("settings.connectPrinterFailed")))
                ])
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }