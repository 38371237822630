export const recurrent = {
    amount: '月払い金額',
    totalAmount: '総金額：${0} x ${1} (ヶ月) = ${2}',
    duration: '回数 (ヶ月)',
    autoRenewal: '自動更新',
    autoRenewalMessage: '{0}ヶ月後自動更新 ',
    summary: '月払い概要',
    warningMessage: '{0}以降も有効なクレジットカードを利用して下さい。',
    durationMessage: '{0}ヶ月',
    left: '残り{0}回',
    cancelRemainingMessage: '残り{0}回の支払いを今すぐキャンセルします。',
    nonRefundMessage: '** 支払い済みの金額を返金しません。',
};
