var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "mdc-layout-grid app-page-activity app-page-activity--active",
      attrs: { id: "edit-permission-setting" }
    },
    [
      _c(
        "form",
        {
          staticClass: "mdc-layout-grid__inner",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _vm.master
            ? _c(
                "div",
                {
                  staticClass:
                    "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.accountId,
                          expression: "accountId"
                        }
                      ],
                      staticClass: "mdc-text-field__input",
                      class: { "select--hidden": !_vm.accountId },
                      attrs: { options: _vm.accountOptions },
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.accountId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.changeStore
                        ]
                      }
                    },
                    [
                      _c(
                        "option",
                        { attrs: { disabled: "" }, domProps: { value: null } },
                        [_vm._v(_vm._s(_vm.$t("settings.branch.label")))]
                      ),
                      _vm._l(_vm.accountOptions, function(account) {
                        return _c(
                          "option",
                          { key: account.id, domProps: { value: account.id } },
                          [_vm._v(_vm._s(account.store_name))]
                        )
                      })
                    ],
                    2
                  ),
                  _c("label", { staticClass: "mdc-floating-label" }, [
                    _vm._v(_vm._s(_vm.$t("settings.branch.label")))
                  ]),
                  _c("div", { staticClass: "mdc-notched-outline" }, [
                    _c("svg", [
                      _c("path", { staticClass: "mdc-notched-outline__path" })
                    ])
                  ]),
                  _c("div", { staticClass: "mdc-notched-outline__idle" })
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched",
              class: { disabled: !_vm.accountId }
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user,
                      expression: "user"
                    }
                  ],
                  staticClass: "mdc-text-field__input",
                  class: { "select--hidden": !_vm.user },
                  attrs: { options: _vm.userOptions },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.user = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.changeUser
                    ]
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { disabled: "" }, domProps: { value: null } },
                    [_vm._v(_vm._s(_vm.$t("settings.user.label")))]
                  ),
                  _vm._l(_vm.userOptions, function(user) {
                    return _c(
                      "option",
                      { key: user.id, domProps: { value: user.id } },
                      [_vm._v(_vm._s(user.name))]
                    )
                  })
                ],
                2
              ),
              _c("label", { staticClass: "mdc-floating-label" }, [
                _vm._v(_vm._s(_vm.$t("settings.user.label")))
              ]),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
            },
            [
              _c(
                "div",
                {
                  staticClass: " mdc-typography--headline6",
                  class: { disabled: !_vm.accountId || !_vm.user }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("settings.permissions.title")) +
                      "\n            "
                  )
                ]
              ),
              _vm._l(_vm.options, function(option) {
                return _c(
                  "div",
                  {
                    key: option["name"],
                    staticClass: "mdc-form-field",
                    class: { disabled: !_vm.accountId || !_vm.user },
                    staticStyle: { "margin-bottom": "-11px" }
                  },
                  [
                    _c("div", { staticClass: "mdc-checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: option["value"],
                            expression: "option['value']"
                          }
                        ],
                        staticClass: "mdc-checkbox__native-control",
                        attrs: {
                          type: "checkbox",
                          autocomplete: "off",
                          autocorrect: "off",
                          spellcheck: "false",
                          disabled: !_vm.userEditable
                        },
                        domProps: {
                          checked: Array.isArray(option["value"])
                            ? _vm._i(option["value"], null) > -1
                            : option["value"]
                        },
                        on: {
                          change: function($event) {
                            var $$a = option["value"],
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(option, "value", $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    option,
                                    "value",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(option, "value", $$c)
                            }
                          }
                        }
                      }),
                      _c("div", { staticClass: "mdc-checkbox__background" }, [
                        _c(
                          "svg",
                          {
                            staticClass: "mdc-checkbox__checkmark",
                            attrs: { viewBox: "0 0 24 24" }
                          },
                          [
                            _c("path", {
                              staticClass: "mdc-checkbox__checkmark-path",
                              attrs: {
                                fill: "none",
                                d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                              }
                            })
                          ]
                        ),
                        _c("div", { staticClass: "mdc-checkbox__mixedmark" })
                      ])
                    ]),
                    _c("label", [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.permissionTranslate[option["name"]])
                          )
                        )
                      ])
                    ])
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav",
              class: { disabled: !_vm.accountId || !_vm.user }
            },
            [
              _c(
                "div",
                {
                  staticClass: "mdc-button",
                  on: {
                    click: function($event) {
                      _vm.$router.go(-1)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.back")))]
              ),
              _c(
                "button",
                {
                  staticClass: "mdc-fab mdc-fab--extended",
                  attrs: {
                    type: "submit",
                    "aria-label": _vm.$t("actions.confirm")
                  }
                },
                [
                  _c("span", { staticClass: "mdc-fab__label" }, [
                    _vm._v(_vm._s(_vm.$t("actions.confirm")))
                  ]),
                  _c(
                    "svg",
                    {
                      staticClass: "mdc-fab__icon",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewbox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }