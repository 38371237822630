var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notification-center" },
    [
      _c("transition", { attrs: { name: "fadeStart" } }, [
        _vm.open
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.onClickOutside,
                    expression: "onClickOutside"
                  }
                ],
                staticClass: "tooltip"
              },
              [
                _c("div", { staticClass: "heading" }, [
                  _c("div", { staticClass: "heading-title" }, [
                    _vm._v(_vm._s(_vm.$t("general.notifications")))
                  ]),
                  _c("div", { staticClass: "heading-button" }, [
                    _c(
                      "a",
                      {
                        staticClass: "notification-link",
                        on: { click: _vm.clearAllMessages }
                      },
                      [_vm._v(_vm._s(_vm.$t("actions.removeAll")))]
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "notification-list" },
                  [
                    _c("NotificationMessageList", {
                      key: _vm.componentKey,
                      attrs: { messages: _vm.notificationMessages }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }