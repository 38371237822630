


































import { Component, Vue } from 'vue-property-decorator';
import PayoutList from '@/components/dashboard/payouts/PayoutList.vue';
import Spinner1 from '@/components/spinners/Spinner1.vue';

@Component({
    components: {
        PayoutList,
        Spinner1,
    },
})
export default class PayoutCardExpanded extends Vue {
    private payouts = [];
    private startingAfter = '';
    private moreButton = true;
    public gettingPayouts = false;

    public mounted() {
        try {
            let p: any = this.$route.params.payouts;
            if (p && p.length) {
                this.payouts = this.payouts.concat(p);
            }
            if (p.length) {
                this.startingAfter = (this.payouts[this.payouts.length - 1] as any).id;
            }
        } catch (e) {}
        this.moreItems();
    }

    // public activated() {
    //     console.log('activated');
    // }

    public showPayout(payout: any) {
        this.$router.push({
            name: 'dashboard.transfer.details',
            params: { id: payout.id, payout },
        });
    }

    public async moreItems() {
        if (!this.gettingPayouts) {
            const newItem = await this.getPayouts();

            if (newItem.length) {
                this.payouts = this.payouts.concat(newItem);
            }
        }
    }

    public async getPayouts() {
        let newList: any;
        let newItems: any;
        this.gettingPayouts = true;
        try {
            newList = await this.$store.dispatch('getPayouts', {
                limit: 10,
                startingAfter: this.startingAfter,
            });
            newItems = newList.data;
            this.moreButton = newList.has_more;

            this.startingAfter = newItems.length ? newItems[newItems.length - 1].id : '';
        } catch (error) {
            this.$root.$emit('error', error);
        } finally {
            this.gettingPayouts = false;
        }

        if (newItems) return newItems;
    }
}
