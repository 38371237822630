var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "activity-settings-printer app-page-activity mdc-layout-grid app-page-activity--active"
    },
    _vm._l(_vm.items, function(item) {
      return _c("SettingsListItem", {
        key: item.label,
        attrs: { params: item }
      })
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }