var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mdc-dialog",
    {
      ref: "dialog",
      attrs: {
        title: "Jarvix Pay 平台用戶協議",
        accept: "關閉",
        scrollable: true
      },
      model: {
        value: _vm.modalOpen,
        callback: function($$v) {
          _vm.modalOpen = $$v
        },
        expression: "modalOpen"
      }
    },
    [_c("div", { domProps: { innerHTML: _vm._s(_vm.agreementHTML) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }