







































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { isEmail, isMobileNumber } from '../../utils/validators';
import AUTH_MSG from '@/constants/AUTH';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import { IMaskComponent } from 'vue-imask';

@Component({
    components: {
        'imask-input': IMaskComponent,
    },
})
export default class EditStore extends Vue {
    private mdcTextField: any;
    private accountId = '';

    private receiptStoreName = '';
    private receiptStoreAddress = '';
    private campaignStoreName = '';
    private campaignStoreAddress = '';

    private accountOptions = [];

    get account() {
        return this.accountOptions.find((account: any) => account.id == this.accountId) as any;
    }

    private errors: string[] = [];

    private engCharMask = /^[a-zA-Z0-9!@#$%^&~`_\-+=,.?/:;{}\[\]| ]*$/;

    get master() {
        return this.$store.state.user.permissions_ ? this.$store.state.user.permissions_.master : false;
    }

    protected changeStore() {
        this.receiptStoreName = this.account.store_name.replace(/(\(本分店\) \(Master\))|(\(This store\) \(Master\))/g, '').trim();
        this.receiptStoreAddress = this.account.store_address;
        this.campaignStoreName = this.account.campaign_store_name;
        this.campaignStoreAddress = this.account.campaign_store_address;
    }

    public async mounted() {
        this.initMDCTextFields();
        if (this.master) {
            this.accountOptions = await this.$store.dispatch('retreiveStores');
        }
    }

    protected submit() {
        this.errors = [];

        if (!this.receiptStoreName && !this.receiptStoreAddress && !this.campaignStoreName && !this.campaignStoreAddress) {
            this.errors.push(AUTH_MSG.EMPTY_FIELD);
        }

        if (this.errors.length) {
            return;
        }

        return this.$store
            .dispatch('updateStore', {
                accountId: this.accountId,
                receiptStoreName: this.receiptStoreName,
                receiptStoreAddress: this.receiptStoreAddress,
                campaignStoreName: this.campaignStoreName,
                campaignStoreAddress: this.campaignStoreAddress,
            })
            .then((response: any) => {
                return this.$root.$emit('confirm', {
                    path: '/payment',
                    title: this.$t('status.success'),
                });
            })
            .catch((error: any) => {
                return this.$root.$emit('error', error);
            });
    }

    // animation function
    private initMDCTextFields() {
        this.mdcTextField = Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
