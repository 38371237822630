

















import { Component, Vue, Prop } from 'vue-property-decorator';
const trunc = (s: string, l: number, e = '...') => (s.length > l ? s.slice(0, l - e.length) + e : s);

@Component
export default class VirtualCard extends Vue {
    // prettier-ignore
    @Prop(Boolean) twosides?: boolean;
    // prettier-ignore
    @Prop()card?: any;

    get last4() {
        return this.card && this.card.last4;
    }

    get maskName() {
        if (!this.card) return;
        let n: string = this.card.cardholder_name;
        return (
            n &&
            trunc(
                n
                    .trim()
                    .split(' ')
                    .filter(s => s.length > 0)
                    .map(s => s.toUpperCase())
                    .map((s, i) => (i ? s.charAt(0) + '***' : s))
                    .join(' '),
                16
            )
        );
    }

    get exp() {
        return this.card && this.card.exp_date;
    }
}
