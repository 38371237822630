export const actions = {
    accept: '確認',
    back: '戻る',
    backOrEdit: '戻る/変更',
    create: '作成',
    copy: 'コピー',
    cancel: 'キャンセル',
    change: '変更',
    confirm: '確認',
    createLink: 'リンクを作成',
    delete: '削除',
    edit: '変更',
    editing: '変更中',
    save: '保存',
    send: '送信',
    sent: '送信済み',
    submit: '提出',
    preview: 'プレビュー',
    print: 'プリント',
    noMore: 'これ以上ない',
    loadMore: 'もっと見る',
    next: '次へ',
    pay: '支払い',
    qrPay: 'QRコード決済',
    reset: 'リセット',
    remind: '再送信',
    refund: '返金',
    register: '申込み',
    login: 'ログイン',
    logout: 'ログアウト',
    loading: '読込中...',
    upload: 'アップロード',
    processing: '読込中...',
    removeAll: '全てを削除',
    reupload: '再アップロード',
    retake: '再撮影',
    takePhoto: 'カメラで撮影',
    noYetUploaded: 'アップロードのボタンを押して下さい。',
};
