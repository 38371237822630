




import { Component, Vue, Prop } from 'vue-property-decorator';
import util from '@/utils';

interface TAndC {
    [c: string]: string;
}

interface statusList {
    [index: string]: TAndC;
}

@Component
export default class RecurrentStatusTag extends Vue {
    // prettier-ignore
    @Prop(String) status!: string;
    // prettier-ignore
    @Prop(Boolean) small?: boolean;

    private statusProps_: statusList = {
        pending: {
            class: 'tags--pending',
            text: 'status.pending',
        },
        active: {
            class: 'tags--recurrent',
            text: 'status.active',
        },
        inactive: {
            class: 'tags--failed',
            text: 'status.inactive',
        },
        completed: {
            class: 'tags--pending',
            text: 'status.completed',
        },
        paused: {
            class: 'tags--pending',
            text: 'status.paused',
        },
        canceled: {
            class: 'tags--refunded',
            text: 'status.canceled',
        },
        failed: {
            class: 'tags--failed',
            text: 'status.failed',
        },
        default: {
            class: 'tags--recurrent',
            text: 'general.recurrent',
        },
    };

    get status_() {
        return util.switch(this.status, this.statusProps);
    }

    get statusProps() {
        for (let key in this.statusProps_) {
            // console.log(key);
            if (this.statusProps_.hasOwnProperty(key)) {
                this.statusProps_[key].text = this.$t(this.statusProps_[key].text) as string;
            }
        }
        return this.statusProps_;
    }
}
