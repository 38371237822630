


























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isHKID } from '../../utils/validators';
import { isPhoneNumber } from '../../utils/validators';
import { validationMixin } from 'vuelidate';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';

@Component({
    mixins: [validationMixin],
    validations: {},
})
export default class Owner extends Vue {
    private owners = [
        {
            id: 1,
            firstName: '',
            lastName: '',
            identityType: '',
            identityNumber: '',
            dob: '',
            countryCode: { phone_code: '+852', en: 'Hong Kong (SAR)', zh: '香港', cn: '香港', alphanumeric_sender: 1 },
            phone: '',
            address: '',
            city: '',
            state: '',
        },
    ];
    private errors: string[] = [];
    private countryCodeList = this.$store.state.countryCodeList;

    public async mounted() {
        this.initMDCTextFields();
        this.owners = this.$store.state.signUp.owners.map((owner: any) => {
            return {
                id: owner.id,
                firstName: owner.firstName,
                lastName: owner.lastName,
                identityType: owner.identityType,
                identityNumber: owner.identityNumber,
                dob: owner.dob,
                countryCode: this.countryCodeList.filter((code: any) => code.en === owner.country)[0],
                // countryCode: owner.countryCode.phone_code,
                phone: owner.phone,
                address: owner.address,
                city: owner.city,
                state: owner.state,
            };
        });
        if (
            this.owners[0].id == undefined ||
            this.owners[0].firstName == '' ||
            this.owners[0].lastName == '' ||
            this.owners[0].identityType == '' ||
            this.owners[0].identityNumber == '' ||
            this.owners[0].dob == '' ||
            this.owners[0].countryCode == undefined ||
            this.owners[0].phone == '' ||
            this.owners[0].address == '' ||
            this.owners[0].city == '' ||
            this.owners[0].state == ''
        ) {
            await this.$store.dispatch('retrieveSignUpData');
            this.owners = this.$store.state.signUp.owners.map((owner: any) => {
                return {
                    id: owner.id,
                    firstName: owner.firstName,
                    lastName: owner.lastName,
                    identityType: owner.identityType,
                    identityNumber: owner.identityNumber,
                    dob: owner.dob,
                    countryCode:
                        owner.country != ''
                            ? this.countryCodeList.filter((code: any) => code.en === owner.country)[0]
                            : { phone_code: '+852', en: 'Hong Kong (SAR)', zh: '香港', cn: '香港', alphanumeric_sender: 1 },
                    // countryCode: owner.countryCode.phone_code,
                    phone: owner.phone,
                    address: owner.address,
                    city: owner.city,
                    state: owner.state,
                };
            });
        }

        // sort the country code due to language
        if (this.$i18n.locale == 'en') {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.en > b.en ? 1 : -1));
        } else {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.phone_code >= b.phone_code ? 1 : -1));
        }
    }

    public updated() {
        this.initMDCTextFields();
    }

    protected async update() {
        this.errors = [];

        this.owners.forEach((owner) => {
            if (
                owner.id == undefined ||
                owner.firstName == '' ||
                owner.lastName == '' ||
                owner.identityType == '' ||
                owner.identityNumber == '' ||
                owner.dob == '' ||
                owner.countryCode.phone_code == '' ||
                owner.phone == '' ||
                owner.address == '' ||
                owner.city == '' ||
                owner.state == ''
            ) {
                this.errors.push(this.$t('AUTH.EMPTY_FIELD') as any);
                // this.errors.push('請輸入所有資料。');
            }
            if (!isPhoneNumber(`${owner.countryCode.phone_code}${owner.phone}`)) {
                this.errors.push(this.$t('AUTH.INVALID_MOBILE_FORMAT') as any);
                // this.errors.push('電話號碼無效。');
            }
            if (owner.identityType == 'hkid') {
                if (!isHKID(owner.identityNumber)) {
                    this.errors.push(this.$t('AUTH.INVALID_HKID') as any);
                    // this.errors.push('身份證號碼不正確。');
                }
            }

            if (owner.address.length > 200) {
                this.errors.push(this.$t('AUTH.ADDRESS_TOO_LONG') as any);
                // this.errors.push('地址需少於200個字元。');
            }
        });

        if (this.errors.length) {
            return;
        } else {
            this.$store.dispatch('saveOwnersData', {
                nextPage: 'external_account',
                legal_entity: this.owners,
            });

            this.$router.push({ name: 'external_account' });
        }
    }

    // validation
    protected prevent(event: any, ownerIdx: number) {
        const strValidChars = "[$&+,:;=?@#|'<>.-^*()%!]";

        if (strValidChars.indexOf(event.key) !== -1 || this.owners[ownerIdx].identityNumber.length > 9) {
            event.preventDefault();
            return;
        }

        return;
    }

    protected formatHKID(ownerIdx: number) {
        this.owners[ownerIdx].identityNumber = this.owners[ownerIdx].identityNumber.toUpperCase();
        const double = /^([A-Za-z]{2})+/;
        const single = /^([A-Za-z]{1})([0-9]{1})+/;

        if (this.owners[ownerIdx].identityType == 'hkid') {
            if (
                single.test(this.owners[ownerIdx].identityNumber) &&
                this.owners[ownerIdx].identityNumber.length !== 8 &&
                this.owners[ownerIdx].identityNumber.length !== 10
            ) {
                return (this.owners[ownerIdx].identityNumber = this.owners[ownerIdx].identityNumber.replace(/[()]/, '').slice(0, 7));
            }

            if (single.test(this.owners[ownerIdx].identityNumber) && this.owners[ownerIdx].identityNumber.length === 8) {
                return (this.owners[ownerIdx].identityNumber =
                    this.owners[ownerIdx].identityNumber.slice(0, 7) + '(' + this.owners[ownerIdx].identityNumber.slice(-1) + ')');
            }

            if (
                double.test(this.owners[ownerIdx].identityNumber) &&
                this.owners[ownerIdx].identityNumber.length !== 9 &&
                this.owners[ownerIdx].identityNumber.length !== 11
            ) {
                return (this.owners[ownerIdx].identityNumber = this.owners[ownerIdx].identityNumber.replace(/[()]/, '').slice(0, 8));
            }

            if (double.test(this.owners[ownerIdx].identityNumber) && this.owners[ownerIdx].identityNumber.length === 9) {
                return (this.owners[ownerIdx].identityNumber =
                    this.owners[ownerIdx].identityNumber.slice(0, 8) + '(' + this.owners[ownerIdx].identityNumber.slice(-1) + ')');
            }
        } else if (this.owners[ownerIdx].identityType == 'passport') {
            return this.owners[ownerIdx].identityNumber;
        }
    }

    // animation function
    private initMDCTextFields() {
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }

    protected addOwner() {
        this.owners.push({
            id: this.owners.length + 1,
            firstName: '',
            lastName: '',
            identityType: '',
            identityNumber: '',
            dob: '',
            countryCode: { phone_code: '+852', en: 'Hong Kong (SAR)', zh: '香港', cn: '香港', alphanumeric_sender: 1 },
            phone: '',
            address: '',
            city: '',
            state: '',
        });
    }

    protected removeOwner(ownerIdx: number) {
        this.owners.splice(ownerIdx, 1);
    }

    protected goPrevious() {
        this.$store.dispatch('saveOwnersData', {
            nextPage: 'company',
            legal_entity: this.owners,
        });
        this.$router.push({ name: 'company' });
    }
}
