import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import 'vuetify/src/stylus/main.styl';
import '../stylus/main.styl';

Vue.use(Vuetify, {
    iconfont: 'md',
    theme: {
        baseColor: '#33407d',
        secondary: '#5656f1',
        navDrawerBgColor: '#0f4e75',
        backgroundColor: '#fafafa',
        fontColor: '#eeeeee',
        primary: '#21aaff',
    },
});
