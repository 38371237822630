export const errors = {
    label: 'エラー',
    GENERAL: {
        retry: 'エラーが発生しました。もう一度試して下さい。',
        contact: 'JARVIX PAYに連絡して下さい。',
        editing: '編集中の内容を保存して下さい。',
    },
    PAYMENT_DETAILS: {
        invalid_transaction: '金額は$10-${0}になります。',
        missing_description: '説明は必須項目です。',
        invalid_recurrent_duration: '回数は2-99ヶ月にして下さい。',
        missing_message: 'メッセージを入力しなければなりません。',
    },
    REMOTE: {
        empty_customer: 'お名前と電話番号を入力しなければなりません。',
        duplicated_customer: '電話番号は重複しています。',
        missing_transaction: '支払いの説明を入力して下さい。',
        max_customers: '宛先は10名までです。',
        min_customers: '宛先は少なくとも1名です。',
    },
    CAMPAIGN: {
        empty_contact: '店舗名と電話番号を入力しなければなりません。',
        empty_product: '商品名と価格と1回あたりの最大注文個数を入力しなければなりません。',
        invalid_price: '価格は0以上にして下さい。',
        missing_original_price: '割引前の価格を入力して下さい。',
        invalid_discount: '価格は割引前の価格以下にして下さい。',
        invalid_quantity_per_order: '1回あたりの最大注文個数は総個数以下にして下さい。',
        exceed_photo_limit: '写真は5枚までです。',
        exceed_product_limit: '商品は20件までです。',
        exceed_link_limit: 'リンクは25まです。',
        less_than_minimum: '少なくとも1つの{0}を入力して下さい。',
        missing_input: '{0}を入力して下さい。',
        mc_option: 'カスタム質問オプションがオンになっています。質問とオプションを入力してください。',
    },
    ERROR_MSG: {
        title: 'エラー',
        Invalid_Credentials: 'メールまたパスワードが違います。',
        Network_Error: 'ネットワークエラーが発生しました。接続を確認して下さい。 [Code: n00]',
        The_requested_charge_is_invalid: 'この取引の詳しい情報が表示できません。 [Code: d01]',
        Email_is_already_taken: 'メールは登録済みです。',
        The_verification_code_field_is_invalid: '無効な検証コードです。',
        The_password_is_invalid: '前のパスワードを正しく入力して下さい。',
        The_new_password_is_repeated: '前のパスワードと同じです。',
        Duplicate_Entry_Error: 'お名前は登録済みです。他のお名前を入力して下さい。',
        Payment_Error_b01: '支払いに異常が発生したため、Jarvix Payに連絡して下さい (3618 7750/6922 5068)。 [Code: b01]',
        Payment_Error_r01: '支払いに異常が発生したため、Jarvix Payに連絡して下さい (3618 7750/6922 5068)。 [Code: r01]',
        "Front:_We_don't_currently_support_that_file_type_Try_uploading_a_file_with_one_of_the_following_mimetypes:_image/jpeg,_image/png":
            'jpegまたpngのフォーマットで香港IDカード正面の写真をアップロードして下さい。',
        "Back:_We_don't_currently_support_that_file_type_Try_uploading_a_file_with_one_of_the_following_mimetypes:_image/jpeg,_image/png":
            'jpegまたpngのフォーマットで香港IDカード裏面の写真をアップロードして下さい。',
    },
    LAP_PAY_ERROR_MSG: {
        exceed_daily_trx_limit: '1日あたりの支払い制限を超えています。',
        exceed_monthly_trx_limit: '1ヶ月あたりの支払い制限を超えています。',
        exceed_daily_and_monthly_trx_limit: '1日あたりと1ヶ月の支払い制限を超えています。',
        refund_rejected_after_100_days: '100日以前の支払いは返金できません。',
        qr_code_expired: 'QRコードの有効期限が切れました。',
        insufficient_balance: '残高が足りません。',
        unsupported_card_type: 'このクレジットカードの種類をサポートしていません。',
        unsupported_payment_type: 'このクレジットカードの支払いタイプをサポートしていません。',
        'user_paying, please_input_password': '支払い中。暗証番号を入力して下さい。',
        unable_to_pay_at_overseas_merchants: 'ポリシーにより非実名アカウントは海外で支払うことができません。',
        user_paying: '支払い中。',
        internal_server_error: 'エラーが発生しました。JARVIX PAYに連絡して下さい。 [Code: w00]',
        payment_gateway_error: 'エラーが発生しました。JARVIX PAYに連絡して下さい。 [Code: w01]',
    },
    ERROR_TYPE: {
        unknown_error: 'エラーが発生しました。しばらくしてからもう一度お試し下さい。 [Code: u00]',
        invalid_param_error: 'エラーが発生しました。しばらくしてからもう一度お試し下さい。 [Code: i00]',
        database_error: 'エラーが発生しました。しばらくしてからもう一度お試し下さい。 [Code: d00]',
        database_content_not_found: 'エラーが発生しました。しばらくしてからもう一度お試し下さい。 [Code: d01]',
        passport_error: 'エラーが発生しました。しばらくしてからもう一度お試し下さい。 [Code p00]',
        unexpected_error: 'エラーが発生しました。しばらくしてからもう一度お試し下さい。 [Code: u01]',
        permission_error: '操作に許可がありません。管理者またJARVIX PAYに連絡して下さい。 [Code: p01]',
        duplicated_campaign_error: '広告名は使用済みです。新しい広告名を作成して下さい。',
        oversold_error: '割引商品が売り切れてしまいました。',
    },
    STRIPE_CODE: {
        customer_canceled_authentication: '支払いを完了するには検証が必要です。',
        expired_card: 'クレジットカードの有効期限が切れました。他のクレジットカードを利用して下さい。',
        incomplete_card: '不完全なクレジットカード情報です。',
        incomplete_cvc: '不完全なCVCコードです。',
        incomplete_expiry: '不完全な有効期限です。',
        incomplete_iban: '不完全なIBANコードです。',
        incomplete_number: '不完全なクレジットカード番号です。',
        incomplete_zip: '不完全なZIPコードです。',
        incorrect_cvc: '不完全なCVCコードです。',
        incorrect_number: 'クレジットカード番号が違います。',
        invalid_expiry_month: '無効な有効期限です。',
        invalid_expiry_month_past: '有効期限が切れました。',
        invalid_expiry_year: '無効な有効期限です。',
        invalid_expiry_year_past: '有効期限が切れました。',
        invalid_iban: '無効なIBANコードです。',
        invalid_iban_country_code: 'IBANコードまた国番号/ZIPコードが無効です。',
        invalid_iban_start: 'IBANコードの最初の2桁は国番号にして下さい。',
        invalid_number: '無効なクレジットカードです。',
        processing_error: 'エラーが発生しました。しばらくしてからもう一度お試し下さい。',
        process_error_intransient: 'エラーが発生しました。',
        token_already_used: 'トークンは利用されています。戻してもう一度試して下さい。',
        unexpected: 'エラーが発生しました。',
        account_invalid: 'エラーが発生しました。JARVIX PAYに連絡して下さい。 [Code: s01]',
        resource_missing: 'エラーが発生しました。JARVIX PAYに連絡して下さい。 [Code: s02]',
    },
    STRIPE_DECLINE_CODE: {
        authentication_required: 'クレジットカードは利用できません。発行会社に連絡して下さい。',
        approve_with_id: 'クレジットカードは利用できません。しばらくしてからもう一度お試し下さい。',
        call_issuer: 'クレジットカードは利用できません。発行会社に連絡して下さい。',
        card_not_supported: 'クレジットカードは利用できません。',
        card_velocity_exceeded: 'クレジットカードは利用できません。支払い試行の回数が上限を超えました。',
        currency_not_supported: 'クレジットカードは指定された通貨をサポートしていません。',
        do_not_honor: 'クレジットカードは利用できません。残高不足の可能性があります。発行会社に連絡して下さい。',
        do_not_try_again: 'クレジットカードは利用できません。発行会社に連絡して下さい。',
        duplicate_transaction: '支払いが重複している可能性があります。',
        expired_card: '有効期限が切れました。他のクレジットカードを試して下さい。',
        fraudulent: '不正なクレジットカードを利用している可能性があります。他のクレジットカードを試して下さい。',
        fake_merchant_test_card: 'クレジットカードは利用できません。テスト環境専用カードを利用しているからです。',
        generic_decline: 'クレジットカードは利用できません。残高不足の可能性があります。発行会社に連絡して下さい。',
        incorrect_cvc: '無効なCVCです。',
        incorrect_number: '無効なクレジットカード番号です。',
        invalid_expiry_month: '無効な有効期限です。',
        invalid_expiry_month_past: '無効な有効期限です。',
        incorrect_pin: '無効な暗証番号です。',
        insufficient_funds: '残高が足りません。',
        invalid_pin: '無効な暗証番号です。',
        invalid_account: '無効な口座です。',
        invalid_amount: '無効な金額です。',
        issuer_not_available: 'クレジットカードは利用できません。しばらくしてからもう一度お試し下さい。',
        lost_card: 'クレジットカードは利用できません。紛失または盗難にあったからです。',
        live_mode_test_card: 'クレジットカードは利用できません。テスト環境専用カードを利用しているからです。',
        merchant_blacklist: 'クレジットカードは利用できません。残高不足の可能性があります。発行会社に連絡して下さい。',
        new_account_information_available: 'クレジットカードは利用できません。発行会社に連絡して下さい。',
        no_action_taken: 'クレジットカードは利用できません。残高不足の可能性があります。発行会社に連絡して下さい。',
        not_permitted: 'クレジットカードは利用できません。発行会社に連絡して下さい。',
        pickup_card: 'クレジットカードは利用できません。紛失または盗難にあったからです。',
        stolen_card: 'クレジットカードは利用できません。紛失または盗難にあったからです。',
        processing_error: 'エラーが発生しました。しばらくしてからもう一度お試し下さい。',
        restricted_card: 'クレジットカードは利用できません。紛失または盗難にあったの可能性があります。',
        revocation_of_all_authorizations: 'クレジットカードは利用できません。発行会社に連絡して下さい。',
        revocation_of_authorization: 'クレジットカードは利用できません。発行会社に連絡して下さい。',
        security_violation: 'クレジットカードは利用できません。発行会社に連絡して下さい。',
        service_not_allowed: 'クレジットカードは利用できません。発行会社に連絡して下さい。',
        stop_payment_order: 'クレジットカードは利用できません。発行会社に連絡して下さい。',
        pin_try_exceeded: '暗証番号試行の回数が上限を超えました。',
        test_mode_live_card: 'クレジットカードは利用できません。テスト環境専用カードを試して下さい。',
        transaction_not_allowed: 'クレジットカードは利用できません。',
        your_card_was_declined: 'クレジットカードは利用できません。他のクレジットカードを試して下さい。',
    },
    USER_INPUT_ERRORS: {
        please_select_at_least_one_item: '商品を少なくとも1件選択して下さい。',
        please_enter_a_customer_name: 'お名前を入力して下さい。',
        please_enter_the_correct_mobile_phone_number: '正しい携帯電話番号を入力して下さい。',
        please_enter_the_customer_age: '年齢を入力して下さい。',
        please_enter_the_customer_address: '住所を入力して下さい。',
        please_select_a_branch: '支店を選択して下さい。',
        please_enter_customer_gender: '性別を入力して下さい。',
        please_enter_customer_email: 'メールを入力して下さい。',
        please_enter_a_reservation_date: '予約日付を入力して下さい。',
        the_system_does_not_allow_selection_of_past_day: '過去の日付が選択できません。',
        please_enter_a_customer_inquiry: 'お問い合わせの内容を入力して下さい。なければN/Aと入力して下さい。',
        please_answer_all_mc_questions: '全ての質問に答えて下さい。',
    },
};
