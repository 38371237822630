import { en } from './en';
import { zh_HK } from './zh_HK';
import { jp } from './jp';
import { zh_CN } from './zh_CN';

export const messages = {
    en: en,
    zh_HK: zh_HK,
    zh_CN: zh_CN,
    jp: jp,
};
