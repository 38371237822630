var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "campaign-link" } }, [
    _c("div", { staticClass: "app-page app-page-payment app-page--active" }, [
      _c(
        "div",
        { staticClass: "app-page-container" },
        [
          _vm.expired ? _c("ExpiredLink") : _vm._e(),
          _vm.campaignData && _vm.purchaseData && !_vm.expired
            ? _c(
                "div",
                { staticClass: "app-page-activity app-page-activity--active" },
                [
                  _c("CampaignLinkDetails", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.display === "campaign",
                        expression: "display === 'campaign'"
                      }
                    ],
                    attrs: {
                      campaignData: _vm.campaignData,
                      purchaseData: _vm.purchaseData
                    },
                    on: {
                      "update:purchaseData": function($event) {
                        _vm.purchaseData = $event
                      },
                      next: _vm.next
                    }
                  }),
                  _c("CampaignPurchaseInformation", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.display === "purchaseInfo",
                        expression: "display === 'purchaseInfo'"
                      }
                    ],
                    attrs: {
                      displayName: _vm.merchantDisplayName,
                      campaignData: _vm.campaignData,
                      purchaseData: _vm.purchaseData
                    },
                    on: { back: _vm.back, next: _vm.createOrEditOrder }
                  }),
                  !_vm.tokenExpired
                    ? _c("CreditCard", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.display === "card",
                            expression: "display === 'card'"
                          }
                        ],
                        staticClass: "activity-payment-card",
                        class: [{ "app-page-activity--active": true }],
                        attrs: {
                          currency: _vm.campaignData.currency,
                          amount: _vm.sum,
                          display: "card"
                        },
                        on: { back: _vm.back, next: _vm.next },
                        model: {
                          value: _vm.creditCardData,
                          callback: function($$v) {
                            _vm.creditCardData = $$v
                          },
                          expression: "creditCardData"
                        }
                      })
                    : _vm._e(),
                  _c("Summary", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.display === "summary",
                        expression: "display === 'summary'"
                      }
                    ],
                    staticClass: "activity-payment-summary",
                    attrs: {
                      displayName: _vm.merchantDisplayName,
                      productList: _vm.purchaseData.productList.filter(function(
                        product
                      ) {
                        return product.selected === true
                      }),
                      sum: _vm.purchaseData.sum,
                      currency: _vm.campaignData.currency,
                      creditCard: _vm.creditCard
                    },
                    on: { back: _vm.back, pay: _vm.pay }
                  }),
                  _vm.display === "receipt"
                    ? _c("Receipt", {
                        attrs: {
                          productList: _vm.purchaseData.productList.filter(
                            function(product) {
                              return product.selected === true
                            }
                          ),
                          chargeId: _vm.chargeId,
                          orderId: _vm.orderId,
                          currency: _vm.campaignData.currency,
                          displayName: _vm.merchantDisplayName
                        },
                        on: { retry: _vm.retry }
                      })
                    : _vm._e(),
                  _vm.display === "freeOrderReceipt"
                    ? _c("FreeOrderReceipt", {
                        attrs: {
                          displayName: _vm.merchantDisplayName,
                          productList: _vm.purchaseData.productList.filter(
                            function(product) {
                              return product.selected === true
                            }
                          )
                        }
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "mdc-layout-grid no-padding-top" }, [
                    _c("div", { staticClass: "mdc-layout-grid__inner" }, [
                      _vm._m(0),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--body1 contact"
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "padding-bottom": "16px" } },
                            [_vm._v(_vm._s(_vm.$t("campaign.contact.title")))]
                          ),
                          _vm._l(_vm.campaignData.contactList, function(
                            contact,
                            index
                          ) {
                            return [
                              _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: { "padding-bottom": "16px" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "padding-bottom": "8px" }
                                    },
                                    [_vm._v(_vm._s(contact.shopName))]
                                  ),
                                  contact.address
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "8px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("customer.address") +
                                                ":" +
                                                contact.address
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "padding-bottom": "8px" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(_vm.$t("customer.phone")) +
                                          "\n                                        "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "tel:" +
                                              contact.countryCode +
                                              contact.number
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(contact.countryCode) +
                                              _vm._s(contact.number)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ])
                  ]),
                  _c("PoweredBy")
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet line"
      },
      [_c("hr")]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }