



























import { Component, Vue, Prop } from 'vue-property-decorator';
import RemoteStatusTag from '@/components/remote-payment/RemoteStatusTag.vue';
// import { moment_HK as moment } from '@/store';
import * as moment from 'moment';
import * as uuid62 from 'uuid62';
import { currencyNameWithSymbol } from '@/utils/helpers/currencyHelper';
const baseRemoteURI = process.env.VUE_APP_ONLINE_PAYMENT_BASE_DOMAIN;
@Component({
    filters: {
        currencySymbolDisplay: currencyNameWithSymbol,
    },
    components: {
        RemoteStatusTag,
    },
})
export default class RemoteHistoryItem extends Vue {
    @Prop()
    remote!: {
        id: string;
        amount: number;
        currency: string;
        customer_name: string;
        customer_country_code: string;
        customer_phone: string;
        status: string;
        description: string;
        message: string;
        created_at: string;
    };

    get merchant() {
        return this.$store.state.user.statement_descriptor.split(' ').join('_');
    }

    private send() {
        const whatsapp = window.open(this.whatsappLink) as Window;
        whatsapp.close();

        return;
    }

    get whatsappLink() {
        // const remoteLinkId = uuid62.encode(this.remote.id);
        const remoteLinkId = this.remote.id;
        const message = this.remote.message ? `${this.remote.message}${'\n'}` : '';

        const link = `http://wa.me/${this.remote.customer_country_code}${this.remote.customer_phone}?text=${message}${'\n'}${this.$t(
            'remote.click2PayMsg'
        )}${'\n'}${baseRemoteURI}/remote/v2/${this.merchant}/${remoteLinkId}`;
        return encodeURI(link);
    }

    get formatDate() {
        return moment(this.remote.created_at)
            .locale(this.$i18n.locale)
            .format('LLL');
    }

    get moment() {
        return moment;
    }
}
