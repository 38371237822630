var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "mdc-layout-grid app-page-activity app-page-activity--active",
      attrs: { id: "new-store-setting" }
    },
    [
      _c(
        "form",
        {
          staticClass: "mdc-layout-grid__inner",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _c("imask-input", {
                staticClass: "mdc-text-field__input",
                attrs: {
                  mask: _vm.engCharMask,
                  id: "receiptStoreName",
                  type: "text",
                  autocomplete: "off",
                  autocorrect: "off",
                  spellcheck: "false"
                },
                model: {
                  value: _vm.receiptStoreName,
                  callback: function($$v) {
                    _vm.receiptStoreName = $$v
                  },
                  expression: "receiptStoreName"
                }
              }),
              _c(
                "label",
                {
                  staticClass: "mdc-floating-label ",
                  attrs: { for: "storeName" }
                },
                [_vm._v(_vm._s(_vm.$t("settings.branch.receipt.name")))]
              ),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _c("imask-input", {
                staticClass: "mdc-text-field__input",
                attrs: {
                  mask: _vm.engCharMask,
                  id: "receiptStoreAddress",
                  type: "text",
                  autocomplete: "off",
                  autocorrect: "off",
                  spellcheck: "false"
                },
                model: {
                  value: _vm.receiptStoreAddress,
                  callback: function($$v) {
                    _vm.receiptStoreAddress = $$v
                  },
                  expression: "receiptStoreAddress"
                }
              }),
              _c(
                "label",
                {
                  staticClass: "mdc-floating-label ",
                  attrs: { for: "storeAddress" }
                },
                [_vm._v(_vm._s(_vm.$t("settings.branch.receipt.address")))]
              ),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _c("imask-input", {
                staticClass: "mdc-text-field__input",
                attrs: {
                  id: "campaignStoreName",
                  type: "text",
                  autocomplete: "off",
                  autocorrect: "off",
                  spellcheck: "false"
                },
                model: {
                  value: _vm.campaignStoreName,
                  callback: function($$v) {
                    _vm.campaignStoreName = $$v
                  },
                  expression: "campaignStoreName"
                }
              }),
              _c(
                "label",
                {
                  staticClass: "mdc-floating-label ",
                  attrs: { for: "storeAddress" }
                },
                [_vm._v(_vm._s(_vm.$t("settings.branch.campaign.name")))]
              ),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
            },
            [
              _c("imask-input", {
                staticClass: "mdc-text-field__input",
                attrs: {
                  id: "campaignStoreAddress",
                  type: "text",
                  autocomplete: "off",
                  autocorrect: "off",
                  spellcheck: "false"
                },
                model: {
                  value: _vm.campaignStoreAddress,
                  callback: function($$v) {
                    _vm.campaignStoreAddress = $$v
                  },
                  expression: "campaignStoreAddress"
                }
              }),
              _c(
                "label",
                {
                  staticClass: "mdc-floating-label ",
                  attrs: { for: "storeAddress" }
                },
                [_vm._v(_vm._s(_vm.$t("settings.branch.campaign.address")))]
              ),
              _c("div", { staticClass: "mdc-notched-outline" }, [
                _c("svg", [
                  _c("path", { staticClass: "mdc-notched-outline__path" })
                ])
              ]),
              _c("div", { staticClass: "mdc-notched-outline__idle" })
            ],
            1
          ),
          _vm.errors.length
            ? _c(
                "div",
                {
                  staticClass:
                    "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                },
                [
                  _c("p", { staticClass: "error-prompt" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.errors, function(error) {
                        return _c("li", { key: error }, [_vm._v(_vm._s(error))])
                      })
                    )
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
            },
            [
              _c("div", { staticClass: "mdc-typography--headline6" }, [
                _vm._v(_vm._s(_vm.$t("settings.permissions.title")))
              ]),
              _c(
                "div",
                {
                  staticClass: "mdc-form-field",
                  staticStyle: { "margin-bottom": "-11px" }
                },
                [
                  _c("div", { staticClass: "mdc-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.permissions.account.add_user,
                          expression: "permissions.account.add_user"
                        }
                      ],
                      staticClass: "mdc-checkbox__native-control",
                      attrs: {
                        type: "checkbox",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.permissions.account.add_user)
                          ? _vm._i(_vm.permissions.account.add_user, null) > -1
                          : _vm.permissions.account.add_user
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.permissions.account.add_user,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.permissions.account,
                                  "add_user",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.permissions.account,
                                  "add_user",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.permissions.account, "add_user", $$c)
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "mdc-checkbox__background" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-checkbox__checkmark",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("path", {
                            staticClass: "mdc-checkbox__checkmark-path",
                            attrs: {
                              fill: "none",
                              d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "mdc-checkbox__mixedmark" })
                    ])
                  ]),
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.permissions.account.addUser"))
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-form-field",
                  staticStyle: { "margin-bottom": "-11px" }
                },
                [
                  _c("div", { staticClass: "mdc-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.permissions.account.edit_permission,
                          expression: "permissions.account.edit_permission"
                        }
                      ],
                      staticClass: "mdc-checkbox__native-control",
                      attrs: {
                        type: "checkbox",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.permissions.account.edit_permission
                        )
                          ? _vm._i(
                              _vm.permissions.account.edit_permission,
                              null
                            ) > -1
                          : _vm.permissions.account.edit_permission
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.permissions.account.edit_permission,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.permissions.account,
                                  "edit_permission",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.permissions.account,
                                  "edit_permission",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.permissions.account,
                              "edit_permission",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "mdc-checkbox__background" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-checkbox__checkmark",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("path", {
                            staticClass: "mdc-checkbox__checkmark-path",
                            attrs: {
                              fill: "none",
                              d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "mdc-checkbox__mixedmark" })
                    ])
                  ]),
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.permissions.account.editPermission")
                        )
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-form-field",
                  staticStyle: { "margin-bottom": "-11px" }
                },
                [
                  _c("div", { staticClass: "mdc-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.permissions.payment.refund,
                          expression: "permissions.payment.refund"
                        }
                      ],
                      staticClass: "mdc-checkbox__native-control",
                      attrs: {
                        type: "checkbox",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.permissions.payment.refund)
                          ? _vm._i(_vm.permissions.payment.refund, null) > -1
                          : _vm.permissions.payment.refund
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.permissions.payment.refund,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "refund",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "refund",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.permissions.payment, "refund", $$c)
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "mdc-checkbox__background" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-checkbox__checkmark",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("path", {
                            staticClass: "mdc-checkbox__checkmark-path",
                            attrs: {
                              fill: "none",
                              d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "mdc-checkbox__mixedmark" })
                    ])
                  ]),
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.permissions.payment.refund"))
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-form-field",
                  staticStyle: { "margin-bottom": "-11px" }
                },
                [
                  _c("div", { staticClass: "mdc-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.permissions.payment.cancel_recurrence,
                          expression: "permissions.payment.cancel_recurrence"
                        }
                      ],
                      staticClass: "mdc-checkbox__native-control",
                      attrs: {
                        type: "checkbox",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.permissions.payment.cancel_recurrence
                        )
                          ? _vm._i(
                              _vm.permissions.payment.cancel_recurrence,
                              null
                            ) > -1
                          : _vm.permissions.payment.cancel_recurrence
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.permissions.payment.cancel_recurrence,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "cancel_recurrence",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "cancel_recurrence",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.permissions.payment,
                              "cancel_recurrence",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "mdc-checkbox__background" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-checkbox__checkmark",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("path", {
                            staticClass: "mdc-checkbox__checkmark-path",
                            attrs: {
                              fill: "none",
                              d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "mdc-checkbox__mixedmark" })
                    ])
                  ]),
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "settings.permissions.payment.cancelRecurrence"
                          )
                        )
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-form-field",
                  staticStyle: { "margin-bottom": "-11px" }
                },
                [
                  _c("div", { staticClass: "mdc-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.permissions.payment
                              .change_recurrence_credit_card,
                          expression:
                            "permissions.payment.change_recurrence_credit_card"
                        }
                      ],
                      staticClass: "mdc-checkbox__native-control",
                      attrs: {
                        type: "checkbox",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.permissions.payment.change_recurrence_credit_card
                        )
                          ? _vm._i(
                              _vm.permissions.payment
                                .change_recurrence_credit_card,
                              null
                            ) > -1
                          : _vm.permissions.payment
                              .change_recurrence_credit_card
                      },
                      on: {
                        change: function($event) {
                          var $$a =
                              _vm.permissions.payment
                                .change_recurrence_credit_card,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "change_recurrence_credit_card",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "change_recurrence_credit_card",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.permissions.payment,
                              "change_recurrence_credit_card",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "mdc-checkbox__background" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-checkbox__checkmark",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("path", {
                            staticClass: "mdc-checkbox__checkmark-path",
                            attrs: {
                              fill: "none",
                              d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "mdc-checkbox__mixedmark" })
                    ])
                  ]),
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "settings.permissions.payment.changeRecurrenceCreditCard"
                          )
                        )
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-form-field",
                  staticStyle: { "margin-bottom": "-11px" }
                },
                [
                  _c("div", { staticClass: "mdc-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.permissions.payment.edit_charge_description,
                          expression:
                            "permissions.payment.edit_charge_description"
                        }
                      ],
                      staticClass: "mdc-checkbox__native-control",
                      attrs: {
                        type: "checkbox",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.permissions.payment.edit_charge_description
                        )
                          ? _vm._i(
                              _vm.permissions.payment.edit_charge_description,
                              null
                            ) > -1
                          : _vm.permissions.payment.edit_charge_description
                      },
                      on: {
                        change: function($event) {
                          var $$a =
                              _vm.permissions.payment.edit_charge_description,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "edit_charge_description",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "edit_charge_description",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.permissions.payment,
                              "edit_charge_description",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "mdc-checkbox__background" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-checkbox__checkmark",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("path", {
                            staticClass: "mdc-checkbox__checkmark-path",
                            attrs: {
                              fill: "none",
                              d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "mdc-checkbox__mixedmark" })
                    ])
                  ]),
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "settings.permissions.payment.editChargeDescription"
                          )
                        )
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-form-field",
                  staticStyle: { "margin-bottom": "-11px" }
                },
                [
                  _c("div", { staticClass: "mdc-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.permissions.payment.capture,
                          expression: "permissions.payment.capture"
                        }
                      ],
                      staticClass: "mdc-checkbox__native-control",
                      attrs: {
                        type: "checkbox",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.permissions.payment.capture)
                          ? _vm._i(_vm.permissions.payment.capture, null) > -1
                          : _vm.permissions.payment.capture
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.permissions.payment.capture,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "capture",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "capture",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.permissions.payment, "capture", $$c)
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "mdc-checkbox__background" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-checkbox__checkmark",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("path", {
                            staticClass: "mdc-checkbox__checkmark-path",
                            attrs: {
                              fill: "none",
                              d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "mdc-checkbox__mixedmark" })
                    ])
                  ]),
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.permissions.payment.capture"))
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-form-field",
                  staticStyle: { "margin-bottom": "-11px" }
                },
                [
                  _c("div", { staticClass: "mdc-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.permissions.payment.enable_recurrence,
                          expression: "permissions.payment.enable_recurrence"
                        }
                      ],
                      staticClass: "mdc-checkbox__native-control",
                      attrs: {
                        type: "checkbox",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.permissions.payment.enable_recurrence
                        )
                          ? _vm._i(
                              _vm.permissions.payment.enable_recurrence,
                              null
                            ) > -1
                          : _vm.permissions.payment.enable_recurrence
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.permissions.payment.enable_recurrence,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "enable_recurrence",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "enable_recurrence",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.permissions.payment,
                              "enable_recurrence",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "mdc-checkbox__background" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-checkbox__checkmark",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("path", {
                            staticClass: "mdc-checkbox__checkmark-path",
                            attrs: {
                              fill: "none",
                              d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "mdc-checkbox__mixedmark" })
                    ])
                  ]),
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "settings.permissions.payment.enable_recurrence"
                          )
                        )
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-form-field",
                  staticStyle: { "margin-bottom": "-11px" }
                },
                [
                  _c("div", { staticClass: "mdc-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.permissions.payment.enable_remote,
                          expression: "permissions.payment.enable_remote"
                        }
                      ],
                      staticClass: "mdc-checkbox__native-control",
                      attrs: {
                        type: "checkbox",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.permissions.payment.enable_remote
                        )
                          ? _vm._i(
                              _vm.permissions.payment.enable_remote,
                              null
                            ) > -1
                          : _vm.permissions.payment.enable_remote
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.permissions.payment.enable_remote,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "enable_remote",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "enable_remote",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.permissions.payment,
                              "enable_remote",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "mdc-checkbox__background" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-checkbox__checkmark",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("path", {
                            staticClass: "mdc-checkbox__checkmark-path",
                            attrs: {
                              fill: "none",
                              d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "mdc-checkbox__mixedmark" })
                    ])
                  ]),
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.permissions.payment.enable_remote")
                        )
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-form-field",
                  staticStyle: { "margin-bottom": "-11px" }
                },
                [
                  _c("div", { staticClass: "mdc-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.permissions.payment.enable_campaign,
                          expression: "permissions.payment.enable_campaign"
                        }
                      ],
                      staticClass: "mdc-checkbox__native-control",
                      attrs: {
                        type: "checkbox",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.permissions.payment.enable_campaign
                        )
                          ? _vm._i(
                              _vm.permissions.payment.enable_campaign,
                              null
                            ) > -1
                          : _vm.permissions.payment.enable_campaign
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.permissions.payment.enable_campaign,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "enable_campaign",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.permissions.payment,
                                  "enable_campaign",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.permissions.payment,
                              "enable_campaign",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "mdc-checkbox__background" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-checkbox__checkmark",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("path", {
                            staticClass: "mdc-checkbox__checkmark-path",
                            attrs: {
                              fill: "none",
                              d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "mdc-checkbox__mixedmark" })
                    ])
                  ]),
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.permissions.payment.enable_campaign")
                        )
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-form-field",
                  staticStyle: { "margin-bottom": "-11px" }
                },
                [
                  _c("div", { staticClass: "mdc-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.permissions.functional.printer,
                          expression: "permissions.functional.printer"
                        }
                      ],
                      staticClass: "mdc-checkbox__native-control",
                      attrs: {
                        type: "checkbox",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.permissions.functional.printer
                        )
                          ? _vm._i(_vm.permissions.functional.printer, null) >
                            -1
                          : _vm.permissions.functional.printer
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.permissions.functional.printer,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.permissions.functional,
                                  "printer",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.permissions.functional,
                                  "printer",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.permissions.functional, "printer", $$c)
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "mdc-checkbox__background" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-checkbox__checkmark",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("path", {
                            staticClass: "mdc-checkbox__checkmark-path",
                            attrs: {
                              fill: "none",
                              d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "mdc-checkbox__mixedmark" })
                    ])
                  ]),
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.permissions.functional.printer")
                        )
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-form-field",
                  staticStyle: { "margin-bottom": "-11px" }
                },
                [
                  _c("div", { staticClass: "mdc-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.permissions.functional.dashboard,
                          expression: "permissions.functional.dashboard"
                        }
                      ],
                      staticClass: "mdc-checkbox__native-control",
                      attrs: {
                        type: "checkbox",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.permissions.functional.dashboard
                        )
                          ? _vm._i(_vm.permissions.functional.dashboard, null) >
                            -1
                          : _vm.permissions.functional.dashboard
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.permissions.functional.dashboard,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.permissions.functional,
                                  "dashboard",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.permissions.functional,
                                  "dashboard",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.permissions.functional,
                              "dashboard",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "mdc-checkbox__background" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-checkbox__checkmark",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("path", {
                            staticClass: "mdc-checkbox__checkmark-path",
                            attrs: {
                              fill: "none",
                              d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "mdc-checkbox__mixedmark" })
                    ])
                  ]),
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.permissions.functional.dashboard")
                        )
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-form-field",
                  staticStyle: { "margin-bottom": "-11px" }
                },
                [
                  _c("div", { staticClass: "mdc-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.permissions.functional.export,
                          expression: "permissions.functional.export"
                        }
                      ],
                      staticClass: "mdc-checkbox__native-control",
                      attrs: {
                        type: "checkbox",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.permissions.functional.export
                        )
                          ? _vm._i(_vm.permissions.functional.export, null) > -1
                          : _vm.permissions.functional.export
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.permissions.functional.export,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.permissions.functional,
                                  "export",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.permissions.functional,
                                  "export",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.permissions.functional, "export", $$c)
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "mdc-checkbox__background" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-checkbox__checkmark",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("path", {
                            staticClass: "mdc-checkbox__checkmark-path",
                            attrs: {
                              fill: "none",
                              d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "mdc-checkbox__mixedmark" })
                    ])
                  ]),
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.permissions.functional.export"))
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-form-field",
                  staticStyle: { "margin-bottom": "-11px" }
                },
                [
                  _c("div", { staticClass: "mdc-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.permissions.functional.transactions_history,
                          expression:
                            "permissions.functional.transactions_history"
                        }
                      ],
                      staticClass: "mdc-checkbox__native-control",
                      attrs: {
                        type: "checkbox",
                        autocomplete: "off",
                        autocorrect: "off",
                        spellcheck: "false"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.permissions.functional.transactions_history
                        )
                          ? _vm._i(
                              _vm.permissions.functional.transactions_history,
                              null
                            ) > -1
                          : _vm.permissions.functional.transactions_history
                      },
                      on: {
                        change: function($event) {
                          var $$a =
                              _vm.permissions.functional.transactions_history,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.permissions.functional,
                                  "transactions_history",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.permissions.functional,
                                  "transactions_history",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.permissions.functional,
                              "transactions_history",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "mdc-checkbox__background" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "mdc-checkbox__checkmark",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("path", {
                            staticClass: "mdc-checkbox__checkmark-path",
                            attrs: {
                              fill: "none",
                              d: "M1.73,12.91 8.1,19.28 22.79,4.59"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "mdc-checkbox__mixedmark" })
                    ])
                  ]),
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "settings.permissions.functional.transactionsHistory"
                          )
                        )
                      )
                    ])
                  ])
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
            },
            [
              _c(
                "div",
                {
                  staticClass: "mdc-button",
                  on: {
                    click: function($event) {
                      _vm.$router.go(-1)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.back")))]
              ),
              _c(
                "button",
                {
                  staticClass: "mdc-fab mdc-fab--extended",
                  attrs: {
                    type: "submit",
                    "aria-label": _vm.$t("actions.create")
                  }
                },
                [
                  _c("span", { staticClass: "mdc-fab__label" }, [
                    _vm._v(_vm._s(_vm.$t("actions.create")))
                  ]),
                  _c(
                    "svg",
                    {
                      staticClass: "mdc-fab__icon",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewbox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }