export const recurrent = {
    amount: '每月支款金额',
    totalAmount: '帐单总金额：${0} x ${1} (月) = ${2}',
    duration: '期数 (月)',
    autoRenewal: '自动续期',
    autoRenewalMessage: '{0}个月后自动续期 (每月)',
    summary: '月付总结',
    warningMessage: '请选择在{0}或之后到期的信用卡。',
    durationMessage: '{0}个月',
    left: '余下{0}期',
    cancelRemainingMessage: '立即取消余下{0}期的款项。',
    nonRefundMessage: '** 已支付的款项不会退款。',
};
