







































import { Component, Vue, Prop } from 'vue-property-decorator';
import { currencyNameWithSymbol } from '../../utils/helpers/currencyHelper';

@Component({
    filters: {
        currencySymbolDisplay: currencyNameWithSymbol,
    },
})
export default class Summary extends Vue {
    @Prop()
    item: any;

    @Prop()
    creditCard: any;

    get holderName() {
        try {
            return this.creditCard.name;
        } catch (error) {}
        // return this.$store.state.token.holder_name;
    }

    get cardNumber() {
        try {
            return `**** **** **** ${this.creditCard.last4}`;
        } catch (error) {}
        // return `**** **** **** ${this.$store.state.token.last4}`;
    }

    get expiryDate() {
        try {
            let exp_month = this.creditCard.exp_month,
                exp_year = this.creditCard.exp_year;
            return `${this.creditCard.exp_month.toString().padStart(2, '0')} / ${this.creditCard.exp_year.toString().slice(-2)}`;
        } catch (error) {}
        // return `${this.$store.state.token.exp_month.toString().padStart(2, '0')} / ${this.$store.state.token.exp_year
        //     .toString()
        //     .slice(-2)}`;
    }

    protected pay() {
        return this.$emit('pay');
    }
}
