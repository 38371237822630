var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "remote-link" } }, [
    _c("div", { staticClass: "app-page app-page-settings app-page--active" }, [
      _c("div", { staticClass: "app-page-container" }, [
        _c(
          "div",
          { staticClass: "app-page-activity app-page-activity--active" },
          [
            _vm.expired
              ? _c("ExpiredLink", {
                  attrs: { expiredStatus: _vm.expiredStatus }
                })
              : _vm._e(),
            !_vm.expired
              ? _c("RemoteLinkItem", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.display === "item",
                      expression: "display === 'item'"
                    }
                  ],
                  attrs: { item: _vm.remoteItem },
                  on: { next: _vm.next }
                })
              : _vm._e(),
            !_vm.expired && !_vm.confirmed
              ? _c("CreditCard", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.display === "card",
                      expression: "display === 'card'"
                    }
                  ],
                  staticClass: "activity-payment-card",
                  class: [
                    { "app-page-activity--active": _vm.display === "card" }
                  ],
                  attrs: {
                    display: _vm.display,
                    amount: _vm.remoteItem.amount,
                    currency: _vm.remoteItem.currency
                  },
                  on: { back: _vm.back, next: _vm.next },
                  model: {
                    value: _vm.creditCardData,
                    callback: function($$v) {
                      _vm.creditCardData = $$v
                    },
                    expression: "creditCardData"
                  }
                })
              : _vm._e(),
            !_vm.expired
              ? _c("Summary", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.display === "summary",
                      expression: "display === 'summary'"
                    }
                  ],
                  staticClass: "activity-payment-summary",
                  class: [
                    { "app-page-activity--active": _vm.display === "summary" }
                  ],
                  attrs: { item: _vm.remoteItem, creditCard: _vm.creditCard },
                  on: { back: _vm.back, pay: _vm.pay }
                })
              : _vm._e(),
            _vm.display === "receipt"
              ? _c("ClientReceipt", {
                  attrs: {
                    chargeId: _vm.chargeId,
                    remoteId: _vm.remoteId,
                    statementDescriptor: _vm.remoteItem.statement_descriptor
                  },
                  on: { retry: _vm.retry }
                })
              : _vm._e(),
            _c("PoweredBy")
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }