
















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import ProductEditInfo from '@/components/remote-payment/ProductEditInfo.vue';
import ProductSummary from '@/components/remote-payment/ProductSummary.vue';
import CustomerEditItem from '@/components/remote-payment/CustomerEditItem.vue';
import CustomerItem from '@/components/remote-payment/CustomerItem.vue';

@Component({
    components: {
        ProductEditInfo,
        ProductSummary,
        CustomerEditItem,
        CustomerItem,
    },
})
export default class RemotePayment extends Vue {
    @Prop({ default: true })
    editing!: boolean;

    private chargeData = {
        amount: 0,
        description: '',
        currency: 'hkd',
        message: '',
    };

    get chargeLimit() {
        return this.$store.state.user.charge_limit_for_each_currency && this.chargeData.currency
            ? this.$store.state.user.charge_limit_for_each_currency[this.chargeData.currency]
            : this.$store.state.user.charge_limit
                ? this.$store.state.user.charge_limit
                : 100000;
    }

    private customerList: CustomerData[] = [
        {
            id: Date.now(),
            name: '',
            countryCode: {},
            phone: '',
            remarks: '',
            refNo: '',
            editing: true,
            hasData: false,
            sent: false,
            remoteLinkId: '',
        },
    ];

    get phoneList() {
        return this.customerList.map(customer => customer.phone);
    }

    private confirmed = false;

    private backToEdit() {
        this._editing = true;
        return;
    }

    private reset() {
        this.chargeData = {
            amount: 0,
            description: '',
            currency: 'hkd',
            message: '',
        };
        this.customerList = [
            {
                id: Date.now(),
                name: '',
                countryCode: { phone_code: '+852', en: 'Hong Kong (SAR)', zh: '香港', cn: '香港', alphanumeric_sender: 1 },
                phone: '',
                remarks: '',
                refNo: '',
                editing: true,
                hasData: false,
                sent: false,
                remoteLinkId: '',
            },
        ];
        this._editing = true;
        this.confirmed = false;
        localStorage.removeItem('remote-data');
    }

    public created() {
        if (localStorage['remote-data']) {
            const remoteData = JSON.parse(localStorage['remote-data']);
            this.chargeData = remoteData.charge;
            this.customerList = remoteData.customerList;
            this._editing = false;
            this.confirmed = true;
        }
    }

    get _editing() {
        return this.editing;
    }

    set _editing(v) {
        this.$emit('update:editing', v);
    }

    private confirm() {
        if (!this.hasChargeData) {
            return this.$root.$emit('error', { message: this.$t('errors.REMOTE.missing_transaction') });
        }

        if (this.chargeData.amount < 10 || this.chargeData.amount > this.chargeLimit) {
            return;
        }

        if (!this.hasCustomerData) {
            return this.$root.$emit('error', { message: this.$t('errors.REMOTE.empty_customer') });
        }

        this._editing = false;
    }

    // button display function
    get editingCustomerList() {
        return this.customerList.some(item => item.editing === true);
    }

    get hasChargeData() {
        return this.chargeData.amount && this.chargeData.description && this.chargeData.message ? true : false;
    }

    get hasCustomerData() {
        return this.customerList.every(item => item.hasData === true);
    }

    // product info function
    private updateProduct(obj: any) {
        this.chargeData = { ...this.chargeData, ...obj };
    }

    // customer-items function
    private addCustomer() {
        if (this.customerList.length >= 10) {
            this.$root.$emit('error', { message: this.$t('errors.REMOTE.max_customers') });
            return;
        }

        this.customerList.push({
            id: Date.now(),
            name: '',
            countryCode: {},
            phone: '',
            remarks: '',
            refNo: '',
            editing: true,
            hasData: false,
            sent: false,
            remoteLinkId: '',
        });
    }

    private edit(editingFlag: boolean, index: number) {
        this.customerList[index].editing = editingFlag;
    }

    private save(customer: any, index: number) {
        let item = this.customerList[index];

        item.name = customer.name;
        item.countryCode = customer.countryCode;
        item.phone = customer.phone;
        item.remarks = customer.remarks;
        item.refNo = customer.refNo;
        item.hasData = true;
    }

    private deleteCustomer(index: number) {
        if (this.customerList.length < 2) {
            return this.$root.$emit('error', { message: this.$t('errors.REMOTE.max_customers') });
        }

        this.customerList.splice(index, 1);
    }

    // database related
    private async createLinks() {
        if (!this.hasChargeData || !this.hasCustomerData) {
            return;
        }

        await Promise.all(
            this.customerList.map((customer: CustomerData) => {
                return this.createRemoteItem(customer).then(async data => {
                    customer.remoteLinkId = data.remoteId;
                });
            })
        );

        localStorage.setItem(
            'remote-data',
            JSON.stringify({
                charge: this.chargeData,
                customerList: this.customerList,
            })
        );
        this.confirmed = true;
        this._editing = false;
        return;
    }

    private async createRemoteItem(customer: any) {
        return await this.$store.dispatch('createRemoteItem', {
            charge: this.chargeData,
            customer: {
                name: customer.name,
                country: customer.countryCode.en,
                countryCode: customer.countryCode.phone_code,
                phone: customer.phone,
                refNo: customer.refNo,
                remarks: customer.remarks,
            },
        });
    }
}
