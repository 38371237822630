

































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import * as moment from 'moment';
import { isMobileNumber } from '../../utils/validators';
import { currencyNameWithSymbol } from '@/utils/helpers/currencyHelper';

interface selectedProduct {
    id: string;
    quantity: number;
    selected: boolean;
}

@Component({
    filters: {
        currencySymbolDisplay: currencyNameWithSymbol,
    },
    components: {},
})
export default class campaignLinkDetails extends Vue {
    @Prop() campaignData!: any;

    @Prop() purchaseData!: any;
    private countryCodeList = this.$store.state.countryCodeList;

    get date() {
        moment.locale(this.$i18n.locale);
        return {
            from: moment(this.campaignData.from).format('LL'),
            to: moment(this.campaignData.to).format('LL'),
        };
    }

    private updateDefault() {
        console.log('1');
    }

    private next() {
        if (this.purchaseData_.productList.every((product: any) => product.selected === false)) {
            return this.$root.$emit('error', {
                message: this.$t('errors.USER_INPUT_ERRORS.please_select_at_least_one_item') as string,
            });
        }

        if (!this.purchaseData_.customer.name) {
            return this.$root.$emit('error', {
                message: this.$t('errors.USER_INPUT_ERRORS.please_enter_a_customer.name') as string,
            });
        }

        if (
            !this.purchaseData_.customer.contact ||
            !isMobileNumber(`${this.purchaseData_.customer.countryCode.phone_code}${this.purchaseData_.customer.contact}`)
        ) {
            return this.$root.$emit('error', {
                message: this.$t('errors.USER_INPUT_ERRORS.please_enter_the_correct_mobile_phone_number') as string,
            });
        }

        if (this.campaignData.branchOption && !this.purchaseData_.accountId) {
            return this.$root.$emit('error', {
                message: this.$t('errors.USER_INPUT_ERRORS.please_select_a_branch') as string,
            });
        }

        if (this.campaignData.genderOption && !this.purchaseData_.customer.gender) {
            return this.$root.$emit('error', {
                message: this.$t('errors.USER_INPUT_ERRORS.please_enter_customer_gender') as string,
            });
        }

        if (this.campaignData.ageOption && !this.purchaseData_.customer.age) {
            return this.$root.$emit('error', {
                message: this.$t('errors.USER_INPUT_ERRORS.please_enter_the_customer_age') as string,
            });
        }

        if (this.campaignData.addressOption && !this.purchaseData_.customer.address) {
            return this.$root.$emit('error', {
                message: this.$t('errors.USER_INPUT_ERRORS.please_enter_the_customer_address') as string,
            });
        }

        if (this.campaignData.emailOption && !this.purchaseData_.customer.email) {
            return this.$root.$emit('error', {
                message: this.$t('errors.USER_INPUT_ERRORS.please_enter_customer_email') as string,
            });
        }

        if (this.campaignData.dateOption) {
            if (!this.purchaseData_.customer.reservedDate) {
                return this.$root.$emit('error', {
                    message: this.$t('errors.USER_INPUT_ERRORS.please_enter_a_reservation_date') as string,
                });
            }

            if (this.purchaseData_.customer.reservedDate < moment().format('YYYY-MM-DD')) {
                return this.$root.$emit('error', {
                    message: this.$t('errors.USER_INPUT_ERRORS.the_system_does_not_allow_selection_of_past_day') as string,
                });
            }
        }

        if (this.campaignData.timeOption && !this.purchaseData_.customer.reservedTime) {
            return this.$root.$emit('error', {
                message: this.$t('errors.USER_INPUT_ERRORS.the_system_does_not_allow_selection_of_past_day') as string,
            });
        }

        if (this.campaignData.questionList.length > 0) {
            const noAnswer: Array<any> = this.purchaseData_.questionList.filter((question: any) => !question.answerId);
            if (noAnswer.length > 0) {
                return this.$root.$emit('error', {
                    message: this.$t('errors.USER_INPUT_ERRORS.please_answer_all_mc_questions') as string,
                });
            }
        }

        if (this.campaignData.specialRequestOption && !this.purchaseData_.customer.specialRequest) {
            return this.$root.$emit('error', {
                message: this.$t('errors.USER_INPUT_ERRORS.please_enter_a_customer_inquiry') as string,
            });
        }

        return this.$emit('next');
    }

    get purchaseData_() {
        return this.purchaseData;
    }

    set purchaseData_(v: any) {
        this.$emit('update:purchaseData', v);
    }

    public mounted() {
        this.initMDCTextFields();
        // sort the country code due to language
        if (this.$i18n.locale == 'en') {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.en > b.en ? 1 : -1));
        } else {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.phone_code >= b.phone_code ? 1 : -1));
        }

        this.purchaseData_.customer.countryCode = {
            phone_code: '+852',
            en: 'Hong Kong (SAR)',
            zh: '香港',
            cn: '香港',
            alphanumeric_sender: 1,
        };
    }

    private initMDCTextFields() {
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
