

































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Logout from '@/components/Logout.vue';
import MRipple from 'material-components-vue/dist/ripple';
import NotificationBell from 'vue-notification-bell';
import NotificationCenter from '@/components/notification/NotificationCenter.vue';
// import NotificationButton from '@/components/notification/NotificationButton.vue';

Vue.use(MRipple);

const defaultTitle = 'Jarvix Pay';
// const ASCII = /[^\x00-\x7F]+/;

@Component({
    components: {
        NotificationBell,
        NotificationCenter,
        // NotificationButton
    },
})
export default class Topbar extends Vue {
    private open = false;
    private visible = false;
    private messages = [];

    private logout() {
        this.$root.$emit('logout');
    }

    async mounted() {
        this.messages = await this.$store.dispatch('getNotificationMessages');
    }

    get showSettings() {
        return this.$store.state.loggedIn && !this.$route.path.includes('settings') && !this.$route.path.includes('signup');
    }

    async showNotification() {
        this.open = !this.open;
        this.$root.$emit('notification', { messages: this.messages, open: this.open });
        // await this.$store.dispatch('showNotificationCenter');
        this.messages = await this.$store.dispatch('getNotificationMessages');
    }

    get title() {
        let x: any = this.$route.matched
            .slice()
            .reverse()
            .find((m) => m.meta && m.meta.appbarTitle);
        let title = (x && x.meta.appbarTitle) || defaultTitle;
        if (title.includes('.')) {
            title = this.$t(title);
        }
        return title;
    }
}
