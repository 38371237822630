



















import { Plugins } from '@capacitor/core';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class LendingWebView extends Vue {
    private parentHeight: number = 0;
    mounted() {
        this.parentHeight = this.$parent.$el.offsetHeight;
        // console.log(this.parentHeight);
    }

    // redirect() {
    //     var url = 'https://jarvixcapital.com/zh-hk';
    //     this.$router.push({ path: '/dashboard' });
    //     window.open(url, '_target');
    // }
    // async openInAppBrowser() {
    //     await Browser.open({ url: 'https://jarvixcapital.com/zh-hk' });
    // }
}
