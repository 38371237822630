








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SettingsListItem extends Vue {
    // prettier-ignore
    @Prop() private params!: any;
    private routerLink() {
        this.params.onClick ? this.params.onClick() : this.$router.push(this.params.to);
    }
}
