



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ClipboardJS from 'clipboard';

const copyMessageDuration = 1e3;
let copyMessageTimeout = 0;
const copyMessages = {
    success: 'status.success',
    failed: 'status.failure',
};

const baseRemoteURI = process.env.VUE_APP_ONLINE_PAYMENT_BASE_DOMAIN;

@Component({})
export default class CustomerItem extends Vue {
    @Prop() customer!: CustomerData;

    @Prop() message!: string;

    private clipboard!: any;
    private copyMessage = '';
    private sent = false;

    get merchant() {
        return this.$store.state.user.statement_descriptor.split(' ').join('_');
    }

    get whatsappLink() {
        const link = `http://wa.me/${this.customer.countryCode.phone_code}${this.customer.phone}?text=${this.message}${'\n'}${this.$t(
            'remote.click2PayMsg'
        )}${'\n'}${baseRemoteURI}/remote/v2/${this.merchant}/${this.customer.remoteLinkId}`;
        return encodeURI(link);
    }

    get copyText() {
        return `${this.message}${'\n'}${this.$t('remote.click2PayMsg')}${'\n'}${baseRemoteURI}/remote/v2/${this.merchant}/${
            this.customer.remoteLinkId
        }`;
    }

    private send() {
        const whatsapp = window.open(this.whatsappLink) as Window;
        whatsapp.close();

        return (this.sent = true);
    }

    public mounted() {
        this.initClipboard();
    }

    private initClipboard() {
        this.clipboard = new ClipboardJS((this.$refs.copyBtn as Vue).$el, { text: () => this.copyText });

        let showCopyMessage = (status: 'success' | 'failed') => {
            window.clearTimeout(copyMessageTimeout);
            this.copyMessage = this.$t(copyMessages[status]) as string;
            copyMessageTimeout = window.setTimeout(() => {
                this.copyMessage = '';
            }, copyMessageDuration);
        };
        this.clipboard.on('success', (e: any) => {
            this.$emit('copy-success', e);
            showCopyMessage('success');
        });
        this.clipboard.on('error', (e: any) => {
            this.$emit('copy-failed', e);
            showCopyMessage('failed');
        });
    }

    public beforeDestroy() {
        this.clipboard && this.clipboard.destroy();
        window.clearTimeout(copyMessageTimeout);
    }
}
