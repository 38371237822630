var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payment" }, [
    _c(
      "div",
      {
        staticClass: "app-page app-page-payment app-page--active",
        class: [_vm.recurrentClass]
      },
      [
        _c("div", { staticClass: "app-page-container" }, [
          _c(
            "div",
            {
              staticClass:
                "app-page-activity app-page-payment app-page-activity--active"
            },
            [
              _c(
                "div",
                {
                  staticClass: "lang--zh-HK",
                  attrs: { id: "payment-method-switch" }
                },
                [
                  _c(
                    "mdc-tab-bar",
                    {
                      ref: "mdcTabBar",
                      class: [
                        { "credit-card": _vm.paymentMethod === "card" },
                        {
                          wechat:
                            _vm.qrPaymentMethod === "wechat" &&
                            _vm.paymentMethod === "qrPayment"
                        },
                        {
                          alipay:
                            _vm.qrPaymentMethod === "alipay" &&
                            _vm.paymentMethod === "qrPayment"
                        }
                      ],
                      on: { change: _vm.onTabUpdated }
                    },
                    [
                      _c(
                        "mdc-tab",
                        { attrs: { active: _vm.paymentMethod === "card" } },
                        [
                          _c("div", { staticClass: "mdc-tab__label" }, [
                            _c(
                              "svg",
                              {
                                staticClass: "mdc-tab__icon",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 50 50"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M39 38H11c-1.7 0-3-1.3-3-3V15c0-1.7 1.3-3 3-3h28c1.7 0 3 1.3 3 3v20c0 1.7-1.3 3-3 3zM11 14c-.6 0-1 .4-1 1v20c0 .6.4 1 1 1h28c.6 0 1-.4 1-1V15c0-.6-.4-1-1-1H11z",
                                    fill: "#626262"
                                  }
                                }),
                                _c("path", {
                                  attrs: { d: "M9 16h32v6H9z", fill: "#626262" }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M12 26h1v2h-1z",
                                    fill: "#626262"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M14 26h1v2h-1z",
                                    fill: "#626262"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M16 26h1v2h-1z",
                                    fill: "#626262"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M19 26h1v2h-1z",
                                    fill: "#626262"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M21 26h1v2h-1z",
                                    fill: "#626262"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M23 26h1v2h-1z",
                                    fill: "#626262"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M26 26h1v2h-1z",
                                    fill: "#626262"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M28 26h1v2h-1z",
                                    fill: "#626262"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M30 26h1v2h-1z",
                                    fill: "#626262"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M33 26h1v2h-1z",
                                    fill: "#626262"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M35 26h1v2h-1z",
                                    fill: "#626262"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M37 26h1v2h-1z",
                                    fill: "#626262"
                                  }
                                })
                              ]
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("general.paymentMethods.card"))
                              )
                            ])
                          ])
                        ]
                      ),
                      false
                        ? _c(
                            "mdc-tab",
                            {
                              attrs: {
                                active: _vm.paymentMethod === "qrPayment"
                              }
                            },
                            [
                              _c("div", { staticClass: "mdc-tab__label" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "mdc-tab__icon",
                                    attrs: {
                                      version: "1.1",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      "xmlns:xlink":
                                        "http://www.w3.org/1999/xlink",
                                      x: "0px",
                                      y: "0px",
                                      width: "512px",
                                      height: "512px",
                                      viewBox: "0 0 512 512",
                                      "xml:space": "preserve"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M456.66,0H385c-8.28,0-15,6.72-15,15s6.72,15,15,15h71.66C470.63,30,482,41.37,482,55.34V127c0,8.28,6.72,15,15,15\n\t\ts15-6.72,15-15V55.34C512,24.83,487.17,0,456.66,0z",
                                        fill: "#626262"
                                      }
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M15,142c8.28,0,15-6.72,15-15V55.34C30,41.37,41.37,30,55.34,30H127c8.28,0,15-6.72,15-15s-6.72-15-15-15H55.34\n\t\tC24.83,0,0,24.83,0,55.34V127C0,135.28,6.72,142,15,142z",
                                        fill: "#626262"
                                      }
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M127,482H55.34C41.37,482,30,470.63,30,456.66V385c0-8.28-6.72-15-15-15s-15,6.72-15,15v71.66\n\t\tC0,487.17,24.83,512,55.34,512H127c8.28,0,15-6.72,15-15S135.28,482,127,482z",
                                        fill: "#626262"
                                      }
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M497,370c-8.28,0-15,6.72-15,15v71.66c0,13.97-11.37,25.34-25.34,25.34H385c-8.28,0-15,6.72-15,15s6.72,15,15,15h71.66\n\t\tc30.52,0,55.34-24.83,55.34-55.34V385C512,376.72,505.28,370,497,370z",
                                        fill: "#626262"
                                      }
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M447,432V272c0-8.28-6.72-15-15-15H272c-8.28,0-15,6.72-15,15v160c0,8.28,6.72,15,15,15h160C440.28,447,447,440.28,447,432\n\t\tz M417,417H287V287h130V417z",
                                        fill: "#626262"
                                      }
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M312,223h112c8.28,0,15-6.72,15-15V96c0-8.28-6.72-15-15-15H312c-8.28,0-15,6.72-15,15v112C297,216.28,303.72,223,312,223z\n\t\t M327,111h82v82h-82V111z",
                                        fill: "#626262"
                                      }
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M224,65H80c-8.28,0-15,6.72-15,15v144c0,8.28,6.72,15,15,15h144c8.28,0,15-6.72,15-15V80C239,71.72,232.28,65,224,65z\n\t\t M209,209H95V95h114V209z",
                                        fill: "#626262"
                                      }
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M208,417H95V288c0-8.28-6.72-15-15-15s-15,6.72-15,15v144c0,8.28,6.72,15,15,15h128c8.28,0,15-6.72,15-15\n\t\tS216.28,417,208,417z",
                                        fill: "#626262"
                                      }
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M159,368v-80c0-8.28-6.72-15-15-15s-15,6.72-15,15v80c0,8.28,6.72,15,15,15S159,376.28,159,368z",
                                        fill: "#626262"
                                      }
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M208,273c-8.28,0-15,6.72-15,15v80c0,8.28,6.72,15,15,15s15-6.72,15-15v-80C223,279.72,216.28,273,208,273z",
                                        fill: "#626262"
                                      }
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M336,367h32c8.28,0,15-6.72,15-15s-6.72-15-15-15h-32c-8.28,0-15,6.72-15,15S327.72,367,336,367z",
                                        fill: "#626262"
                                      }
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M152,175c8.28,0,15-6.72,15-15v-16c0-8.28-6.72-15-15-15s-15,6.72-15,15v16C137,168.28,143.72,175,152,175z",
                                        fill: "#626262"
                                      }
                                    })
                                  ]
                                ),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("general.paymentMethods.qrPayment")
                                    )
                                  )
                                ])
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.paymentMethod === "card",
                      expression: "paymentMethod === 'card'"
                    }
                  ],
                  attrs: { id: "credit-card-payment" }
                },
                [
                  _c("Amount", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.display === "amount",
                        expression: "display === 'amount'"
                      }
                    ],
                    ref: "amount",
                    staticClass: "activity-payment-amount",
                    class: [_vm.recurrentClass],
                    attrs: {
                      display: _vm.display,
                      isRecurrent: _vm.isRecurrent
                    },
                    on: {
                      reset: function($event) {
                        _vm.$emit("reset")
                      },
                      next: _vm.next,
                      "update:isRecurrent": function($event) {
                        _vm.isRecurrent = $event
                      }
                    },
                    model: {
                      value: _vm.amountData,
                      callback: function($$v) {
                        _vm.amountData = $$v
                      },
                      expression: "amountData"
                    }
                  }),
                  _vm.isCapacitorNative
                    ? [
                        _c("NativeCreditCard", {
                          attrs: {
                            amount: !_vm.isRecurrent && _vm.maskedAmount,
                            description: _vm.chargeData.description,
                            direct: !_vm.isRecurrent,
                            display: _vm.display
                          },
                          on: { back: _vm.back, next: _vm.next, pay: _vm.pay },
                          model: {
                            value: _vm.creditCardData,
                            callback: function($$v) {
                              _vm.creditCardData = $$v
                            },
                            expression: "creditCardData"
                          }
                        })
                      ]
                    : [
                        !_vm.isCapacitorNative
                          ? _c("CreditCard", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.display === "card",
                                  expression: "display === 'card'"
                                }
                              ],
                              ref: "creditCard",
                              staticClass: "activity-payment-card",
                              class: [_vm.recurrentClass],
                              attrs: {
                                direct: !_vm.isRecurrent,
                                display: _vm.display,
                                amount: !_vm.isRecurrent && _vm.maskedAmount,
                                currency: _vm.chargeData.currency,
                                description: _vm.chargeData.description
                              },
                              on: {
                                back: _vm.back,
                                next: _vm.next,
                                pay: _vm.pay
                              },
                              model: {
                                value: _vm.creditCardData,
                                callback: function($$v) {
                                  _vm.creditCardData = $$v
                                },
                                expression: "creditCardData"
                              }
                            })
                          : _vm._e()
                      ],
                  _c("Summary", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.display === "summary",
                        expression: "display === 'summary'"
                      }
                    ],
                    ref: "summary",
                    staticClass: "activity-payment-summary",
                    class: [_vm.recurrentClass],
                    attrs: {
                      display: _vm.display,
                      isRecurrent: _vm.isRecurrent,
                      chargeData: _vm.chargeData
                    },
                    on: { back: _vm.back, pay: _vm.pay },
                    model: {
                      value: _vm.customerData,
                      callback: function($$v) {
                        _vm.customerData = $$v
                      },
                      expression: "customerData"
                    }
                  })
                ],
                2
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.paymentMethod === "qrPayment",
                      expression: "paymentMethod === 'qrPayment'"
                    }
                  ],
                  class: [
                    { "wechat-payment": _vm.qrPaymentMethod === "wechat" },
                    { "alipay-payment": _vm.qrPaymentMethod === "alipay" }
                  ]
                },
                [
                  _vm.wechatEnabled || _vm.alipayEnabled
                    ? _c("QRPayment", {
                        ref: "wechat",
                        class: [
                          {
                            "activity-payment-wechat":
                              _vm.qrPaymentMethod === "wechat"
                          },
                          {
                            "activity-payment-alipay":
                              _vm.qrPaymentMethod === "alipay"
                          }
                        ],
                        on: { pay: _vm.labpay },
                        model: {
                          value: _vm.amountData,
                          callback: function($$v) {
                            _vm.amountData = $$v
                          },
                          expression: "amountData"
                        }
                      })
                    : _vm._e(),
                  _vm.isDialogEnabled
                    ? _c(
                        "div",
                        {
                          staticClass: "mdc-layout-grid",
                          staticStyle: { "padding-top": "0" }
                        },
                        [
                          _c("div", { staticClass: "mdc-layout-grid__inner" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                              },
                              [
                                _c("p", { staticClass: "wechat-disabled" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("general.qrPaymentDisable"))
                                  )
                                ])
                              ]
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }