


















































































































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import VueNumeric from 'vue-numeric';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import { MDCFormField } from '@material/form-field';
import { MDCCheckbox } from '@material/checkbox';
import { IMaskComponent } from 'vue-imask';
import { QrcodeStream, QrcodeCapture } from 'vue-qrcode-reader';
import { DoubleBounce } from 'vue-loading-spinner';
import {
    // getPlatforms, // getPlatform not using for now
    isPlatform,
    Platforms,
} from '@/utils/platform';

@Component({
    components: {
        VueNumeric,
        'imask-input': IMaskComponent,
        QrcodeStream,
        QrcodeCapture,
        DoubleBounce,
    },
})
export default class QRPayment extends Vue {
    @Prop() value!: any;

    @Watch('value_')
    onValueChanged(v: string) {
        this.$emit('input', v);
    }

    private test = 'hello';

    isPlatform(platform: Platforms) {
        return isPlatform(window, platform);
    }

    get wechatEnabled() {
        return this.$store.state.user.wechat_enabled;
    }

    get alipayEnabled() {
        return this.$store.state.user.alipay_enabled;
    }

    get isPWA() {
        return this.isPlatform('pwa');
    }

    get isIOS() {
        return this.isPlatform('ios');
    }

    // method not in use for now
    // get isHybrid() {
    //     return this.isPlatform('capacitor');
    // }

    get nativeJPAYQrScanner() {
        // try to get native QR code scanner
        try {
            const isCapacitor = this.isPlatform('capacitor');
            // only for ios, android not yet implemented
            if (isCapacitor && this.isIOS) {
                return (window as any).Capacitor.Plugins.JPAYQrScanner;
            }
        } catch (error) {
            return undefined;
        }
    }

    public amount = 0;
    public description = '';
    public currency = 'hkd';
    private paymentMethod = ''; // 'wechat' or 'alipay'

    @Watch('paymentMethod')
    onPaymentMethodChanged(paymentMethod: string) {
        this.$store.dispatch('setQRPaymentMethodAction', paymentMethod);
    }
    // @change="onPaymentMethodChange($event)"

    private cameraActive = false;
    private capturingQrCode = false;
    private loading = false;

    private check = 0;
    private chargeLimit = this.$store.state.user.charge_limit;
    private error = '';

    private mdcTextFields!: any;
    private amountShown = '';
    private input_user_el: any = { value: '' };

    private convertPlusToDot = (amount: any) => amount.replace('+', '.');

    private async onCompleteHandler() {
        this.amountShown = this.input_user_el.value.length > 0 ? `$${this.input_user_el.value}` : '';
        this.check = this.amount > this.chargeLimit ? 1 : 0;
    }

    get chargeLimitDisplay() {
        return this.chargeLimit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    get value_() {
        return {
            amount: this.amount,
            currency: this.currency,
            description: this.description,
        };
    }

    get currentLang() {
        return document.documentElement.lang;
    }

    get langClass() {
        return {
            'lang--zh-HK': this.currentLang == 'zh-HK',
        };
    }

    public mounted() {
        this.$emit('input', this.value_);
        this.input_user_el = (this.$refs.paymentInputUser as any).$el;
        this.initMDCTextFields();

        if (this.wechatEnabled && !this.alipayEnabled) {
            this.paymentMethod = 'wechat';
        } else if (this.alipayEnabled && !this.wechatEnabled) {
            this.paymentMethod = 'alipay';
        } else if (this.alipayEnabled && this.alipayEnabled) {
            this.paymentMethod = 'wechat';
        }
        this.$store.dispatch('setQRPaymentMethodAction', this.paymentMethod);

        this.$root.$on('switchToQRPayment', () => {
            this.cameraActive = false;
            this.amount = this.value.amount;
            this.description = this.value.description;
        });
    }

    private reset() {
        this.amount = 0;
        this.description = '';
    }

    // animation function
    private initMDCTextFields() {
        this.mdcTextFields = Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }

    private triggerQrcodeCapture() {
        (this.$refs.qrcodeCapture as any).$el.click();
    }

    public onDecode(decodedString: string) {
        this.turnOff();
        this.$emit('pay', { decodedString: decodedString, paymentMethod: this.paymentMethod });
    }

    public async onInit(promise: any) {
        try {
            this.loading = true;
            await promise;
        } catch (error) {
            if (error.name === 'NotAllowedError') {
                this.error = 'ERROR: you need to grant camera access permisson';
            } else if (error.name === 'NotFoundError') {
                this.error = 'ERROR: no camera on this device';
            } else if (error.name === 'NotSupportedError') {
                this.error = 'ERROR: secure context required (HTTPS, localhost)';
            } else if (error.name === 'NotReadableError') {
                this.error = 'ERROR: is the camera already in use?';
            } else if (error.name === 'OverconstrainedError') {
                this.error = 'ERROR: installed cameras are not suitable';
            } else if (error.name === 'StreamApiNotSupportedError') {
                this.error = 'ERROR: Stream API is not supported in this browser';
            }
        } finally {
            this.loading = false;
        }
    }

    private async turnOn() {
        this.check = 0;

        // check for amount
        if (this.amount < 0.1 || this.amount > this.chargeLimit) {
            // failed amount checking
            this.check = 1;
        } else if (this.nativeJPAYQrScanner) {
            // native scanner exists, open native scanner
            try {
                const res = await this.nativeJPAYQrScanner.show();
                this.onDecode(res.data);
            } catch (error) {}
        } else {
            // open web qr code scanner
            this.capturingQrCode = true;
            this.cameraActive = true;

            if (this.isPWA && this.isIOS) {
                // ios fix
                setTimeout(() => this.triggerQrcodeCapture(), 50);
            }
        }
    }

    private turnOff() {
        this.capturingQrCode = false;
    }
}
