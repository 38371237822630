





























































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MDCRipple } from '@material/ripple';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import * as FileType from 'file-type';

@Component({})
export default class DocumentUpload extends Vue {
    private panel: Array<boolean> = [];
    private brFile: any = {};
    private ciRequired: boolean = true;
    private ciFile: any = {};
    private ownerNo: number = 3;
    private ownerName: Array<string> = [];
    private owners = {
        identityFront: new Array(),
        identityBack: new Array(),
        address: new Array(),
    };
    private bank: any = {};
    private others = new Array();
    private errors: string[] = [];

    public async mounted() {
        this.initMDC();
        await this.$store.dispatch('retrieveSignUpData');
        this.brFile = this.$store.state.signUp.documentUpload.brFile;
        this.ciFile = this.$store.state.signUp.documentUpload.ciFile;
        this.ciRequired = this.$store.state.signUp.company.businessCI != '' ? true : false;
        this.ownerNo = this.$store.state.signUp.owners.length;
        this.ownerName = this.$store.state.signUp.owners.map((owner: any) => {
            return owner.lastName + ' ' + owner.firstName;
        });
        this.owners = this.$store.state.signUp.documentUpload.owners;
        this.bank = this.$store.state.signUp.documentUpload.bank;
        this.others = this.$store.state.signUp.documentUpload.others;

        if (
            JSON.stringify(this.owners.identityFront) == '[]' ||
            JSON.stringify(this.owners.identityBack) == '[]' ||
            JSON.stringify(this.owners.address) == '[]'
        ) {
            this.owners = {
                identityFront: new Array(this.ownerNo),
                identityBack: new Array(this.ownerNo),
                address: new Array(this.ownerNo),
            };
        }
    }

    public updated() {
        this.initMDC();
    }

    // animation function
    private initMDC() {
        const selector = '.mdc-button, .mdc-icon-button, .mdc-card__primary-action';
        const ripples = [].map.call(document.querySelectorAll(selector), function(el: any) {
            return new MDCRipple(el);
        });
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }

    protected async selectedBRFile(event: any) {
        const reader = new FileReader();
        await reader.readAsArrayBuffer(event.target.files[0]);
        const THIS = this;
        reader.onload = function() {
            THIS.brFile = {
                url: URL.createObjectURL(event.target.files.item(0)),
                file: event.target.files[0],
                arrayBuffer: reader.result as ArrayBuffer,
                filename: '',
            };
        };
        this.panel = [];
    }
    protected async selectedCIFile(event: any) {
        const reader = new FileReader();
        await reader.readAsArrayBuffer(event.target.files[0]);
        const THIS = this;
        reader.onload = function() {
            THIS.ciFile = {
                url: URL.createObjectURL(event.target.files.item(0)),
                file: event.target.files[0],
                arrayBuffer: reader.result as ArrayBuffer,
                filename: '',
            };
        };
        this.panel = [];
    }
    protected async selectedOwnerIdentityFrontFile(event: any, ownerIdx: any) {
        const reader = new FileReader();
        await reader.readAsArrayBuffer(event.target.files[0]);
        const THIS = this;
        reader.onload = function() {
            THIS.owners.identityFront[ownerIdx] = {
                url: URL.createObjectURL(event.target.files.item(0)),
                file: event.target.files[0],
                arrayBuffer: reader.result as ArrayBuffer,
                filename: '',
            };
            THIS.owners.identityFront.splice(0, 0); // fix the vue array rendering problem
        };
        if (this.owners.identityBack[ownerIdx] != undefined && this.owners.address[ownerIdx] != undefined) {
            this.panel = [];
        }
    }

    protected async selectedOwnerIdentityBackFile(event: any, ownerIdx: any) {
        const reader = new FileReader();
        await reader.readAsArrayBuffer(event.target.files[0]);
        const THIS = this;
        reader.onload = function() {
            THIS.owners.identityBack[ownerIdx] = {
                url: URL.createObjectURL(event.target.files.item(0)),
                file: event.target.files[0],
                arrayBuffer: reader.result as ArrayBuffer,
                filename: '',
            };
            THIS.owners.identityBack.splice(0, 0); // fix the vue array rendering problem
        };
        if (this.owners.identityFront[ownerIdx] != undefined && this.owners.address[ownerIdx] != undefined) {
            this.panel = [];
        }
    }

    protected async selectedOwnerAddressFile(event: any, ownerIdx: any) {
        const reader = new FileReader();
        await reader.readAsArrayBuffer(event.target.files[0]);
        const THIS = this;
        reader.onload = function() {
            THIS.owners.address[ownerIdx] = {
                url: URL.createObjectURL(event.target.files.item(0)),
                file: event.target.files[0],
                arrayBuffer: reader.result as ArrayBuffer,
                filename: '',
            };
            THIS.owners.address.splice(0, 0); // fix the vue array rendering problem
        };
        if (this.owners.identityFront[ownerIdx] != undefined && this.owners.identityBack[ownerIdx] != undefined) {
            this.panel = [];
        }
    }
    protected async selectedBankFile(event: any, ownerIdx: any) {
        const reader = new FileReader();
        await reader.readAsArrayBuffer(event.target.files[0]);
        const THIS = this;
        reader.onload = function() {
            THIS.bank = {
                url: URL.createObjectURL(event.target.files.item(0)),
                file: event.target.files[0],
                arrayBuffer: reader.result as ArrayBuffer,
                filename: '',
            };
        };
        this.panel = [];
    }
    protected async selectedOthersFile(event: any, ownerIdx: any) {
        const reader = new FileReader();
        await reader.readAsArrayBuffer(event.target.files[0]);
        const THIS = this;
        reader.onload = function() {
            THIS.others.push({
                url: URL.createObjectURL(event.target.files.item(0)),
                file: event.target.files[0],
                arrayBuffer: reader.result as ArrayBuffer,
                filename: '',
            });
        };
        this.panel = [];
    }
    protected removeOther(fileIdx: number) {
        this.others.splice(fileIdx, 1);
    }

    protected async update() {
        this.$store.dispatch('saveDocumentUploadData', {
            brFile: this.brFile,
            ciFile: this.ciFile,
            owners: this.owners,
            bank: this.bank,
            others: this.others,
        });

        this.errors = [];

        // check empty
        let ownerEmpty = false;
        for (let i = 0; i < this.ownerNo; i++) {
            if (this.owners.identityFront[i] == undefined) {
                ownerEmpty = true;
                break;
            } else if (this.owners.identityBack[i] == undefined) {
                ownerEmpty = true;
                break;
            } else if (this.owners.address[i] == undefined) {
                ownerEmpty = true;
                break;
            }
        }

        if (
            JSON.stringify(this.brFile) == '{}' ||
            (this.ciRequired && JSON.stringify(this.ciFile) == '{}') ||
            JSON.stringify(this.bank) == '{}' ||
            ownerEmpty
        ) {
            this.errors.push(this.$t('AUTH.EMPTY_FIELD') as any);
            // this.errors.push('請輸入所有資料。');
        }
        if (this.errors.length) {
            return;
        }

        // check file type
        if (
            (await FileType(new Uint8Array(this.brFile.arrayBuffer)))!.ext != 'png' &&
            (await FileType(new Uint8Array(this.brFile.arrayBuffer)))!.ext != 'jpg' &&
            (await FileType(new Uint8Array(this.brFile.arrayBuffer)))!.ext != 'pdf'
        ) {
            this.errors.push(this.$t('signup.errorBR') as any);
        } else {
            this.brFile.filename = 'br.' + (await FileType(new Uint8Array(this.brFile.arrayBuffer)))!.ext;
        }

        if (this.ciRequired) {
            if (
                (await FileType(new Uint8Array(this.ciFile.arrayBuffer)))!.ext != 'png' &&
                (await FileType(new Uint8Array(this.ciFile.arrayBuffer)))!.ext != 'jpg' &&
                (await FileType(new Uint8Array(this.ciFile.arrayBuffer)))!.ext != 'pdf'
            ) {
                this.errors.push(this.$t('signup.errorCI') as any);
            } else {
                this.ciFile.filename = 'ci.' + (await FileType(new Uint8Array(this.ciFile.arrayBuffer)))!.ext;
            }
        }
        if (
            (await FileType(new Uint8Array(this.bank.arrayBuffer)))!.ext != 'png' &&
            (await FileType(new Uint8Array(this.bank.arrayBuffer)))!.ext != 'jpg' &&
            (await FileType(new Uint8Array(this.bank.arrayBuffer)))!.ext != 'pdf'
        ) {
            this.errors.push(this.$t('signup.errorBank') as any);
        } else {
            this.bank.filename = 'bank.' + (await FileType(new Uint8Array(this.bank.arrayBuffer)))!.ext;
        }

        let ownerValid = true;
        for (let i = 0; i < this.ownerNo; i++) {
            ownerValid = true;
            if (
                (await FileType(new Uint8Array(this.owners.identityFront[i].arrayBuffer)))!.ext != 'png' &&
                (await FileType(new Uint8Array(this.owners.identityFront[i].arrayBuffer)))!.ext != 'jpg'
            ) {
                this.errors.push(this.$t('signup.errorIdentityFront', {ownerNo: i+1}) as any);
                ownerValid = false;
            }
            if (
                (await FileType(new Uint8Array(this.owners.identityBack[i].arrayBuffer)))!.ext != 'png' &&
                (await FileType(new Uint8Array(this.owners.identityBack[i].arrayBuffer)))!.ext != 'jpg'
            ) {
                this.errors.push(this.$t('signup.errorIdentityBack', {ownerNo: i+1}) as any);
                ownerValid = false;
            }
            if (
                (await FileType(new Uint8Array(this.owners.address[i].arrayBuffer)))!.ext != 'png' &&
                (await FileType(new Uint8Array(this.owners.address[i].arrayBuffer)))!.ext != 'jpg' &&
                (await FileType(new Uint8Array(this.owners.address[i].arrayBuffer)))!.ext != 'pdf'
            ) {
                this.errors.push(this.$t('signup.errorAddress', {ownerNo: i+1}) as any);
                ownerValid = false;
            }

            if (ownerValid) {
                this.owners.identityFront[i].filename =
                    `owner${i + 1}_identity_front.` + (await FileType(new Uint8Array(this.owners.identityFront[i].arrayBuffer)))!.ext;
                this.owners.identityBack[i].filename =
                    `owner${i + 1}_identity_back.` + (await FileType(new Uint8Array(this.owners.identityBack[i].arrayBuffer)))!.ext;
                this.owners.address[i].filename =
                    `owner${i + 1}_address.` + (await FileType(new Uint8Array(this.owners.address[i].arrayBuffer)))!.ext;
            }
        }

        for (let i = 0; i < this.others.length; i++) {
            if (
                (await FileType(new Uint8Array(this.others[i].arrayBuffer)))!.ext != 'png' &&
                (await FileType(new Uint8Array(this.others[i].arrayBuffer)))!.ext != 'jpg' &&
                (await FileType(new Uint8Array(this.others[i].arrayBuffer)))!.ext != 'pdf'
            ) {
                this.errors.push(this.$t('signup.errorOther', {otherNo: i+1}) as any);
            } else {
                this.others[i].filename = `other${i + 1}.` + (await FileType(new Uint8Array(this.others[i].arrayBuffer)))!.ext;
            }
        }

        if (this.errors.length) {
            return;
        } else this.$router.push({ name: 'review' });
    }
    protected async goPrevious() {
        this.$store.dispatch('saveDocumentUploadData', {
            brFile: this.brFile,
            ciFile: this.ciFile,
            owners: this.owners,
            bank: this.bank,
            others: this.others,
        });
        this.$router.push({ name: 'external_account' });
    }
}
