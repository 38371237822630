


























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CampaignOrderSummaryItem from '@/components/campaign-history/CampaignOrderSummaryItem.vue';

import { moment_HK as moment } from '@/store';
import { scrollTop } from '@/utils/scrollTop';
import VueExcelXlsx from 'vue-excel-xlsx';
import VueForExcel from 'vue';
import joinjs from 'join-js';
VueForExcel.use(VueExcelXlsx);

@Component({
    components: {
        CampaignOrderSummaryItem,
    },
})
export default class CampaignOrderList extends Vue {
    @Prop()
    campaignId!: string;

    get moment() {
        return moment;
    }

    private summary = {
        total_amount: 0,
        total_orders: 0,
        total_paid_orders: 0,
        latest_trx_amount: 0,
        latest_trx_timestamp: '',
    };

    private detailedInfo = {
        header:[],
        item: [],
    }

    private detailedInfoExcelField = new Array();
    private items = [];
    private timestamp?: number;
    private moreButton = true;
    public gettingOrders = false;


    public async created() {
        this.getSummary();
        this.fetchItems();
        this.getDetailedOrders();
    }

    public async fetchItems() {
        if (!this.gettingOrders) {
            this.moreButton = true;
            this.timestamp = moment().unix();
            this.items = await this.getOrders();
            scrollTop();
        }
    }

    public async moreItems() {
        const newItem = await this.getOrders();

        if (newItem.length) {
            this.items = this.items.concat(newItem);
        }
    }

    public async getOrders() {
        let newItems: any;

        try {
            newItems = await this.$store.dispatch('getCampaignOrders', {
                campaignId: this.campaignId,
                timestamp: this.timestamp,
                limit: 10,
            });

            if (newItems.length < 10) {
                this.moreButton = false;
            } else {
                this.timestamp = moment((newItems[newItems.length - 1] as any).created_at).unix();
            }
        } catch (error) {
            this.$root.$emit('error', error);
        } finally {
            this.gettingOrders = false;
        }

        if (newItems) {
            return newItems;
        } else {
            return [];
        }
    }

    public async getDetailedOrders() {
        return this.$store
            .dispatch('getCampaignOrderByCampaignId', {
                campaignId: this.campaignId,
            })
            .then((res: any) =>{
                this.detailedInfo = res;
                //console.log(this.detailedInfo);
            })
            .catch((err: any) => {
                this.$root.$emit('error', err);
            })
    }

    public async getSummary() {
        return this.$store
            .dispatch('getCampaignSummary', {
                campaignId: this.campaignId,
            })
            .then((res: any) => {
                if (res.length) {
                    this.summary.total_amount = res[0].total_amount | 0;
                    this.summary.total_orders = res[0].total_orders | 0;
                    this.summary.total_paid_orders = res[0].total_paid_orders | 0;
                    this.summary.latest_trx_amount = res[0].latest_trx_amount ? res[0].latest_trx_amount : 0;
                    this.summary.latest_trx_timestamp = res[0].latest_trx_timestamp ? moment(res[0].latest_trx_timestamp).format('LL') : '';
                }
            })
            .catch((err: any) => {
                this.$root.$emit('error', err);
            });
    }
}
