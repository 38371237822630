var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chinese-payment-receipt" }, [
    _c(
      "div",
      { staticClass: "app-page app-page-payment-history app-page--active" },
      [
        _c("div", { staticClass: "app-page-container" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid app-page-activity app-page-activity--active activity-payment-history-expanded"
            },
            [
              _vm.charge
                ? _c("div", { staticClass: "payment-history-expanded-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "close-icon",
                        on: {
                          click: function($event) {
                            _vm.$router.go(-1)
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "close-icon__icon",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 24 24"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _c("div", {
                      staticClass: "payment-history-expanded-item__background"
                    }),
                    _vm.charge
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "payment-history-expanded-item__content"
                          },
                          [
                            _c("div", { staticClass: "section" }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mdc-typography--headline6",
                                    staticStyle: {
                                      margin: "0",
                                      "font-size": "1em",
                                      opacity: ".75"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    #" +
                                        _vm._s(_vm.charge.receipt_no) +
                                        "\n                                "
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "section" }, [
                              _c(
                                "div",
                                { staticClass: "row row--more-margin" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "payment-history-expanded-item__amount mdc-typography--headline4"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.charge.amount
                                            )
                                          ) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row row--more-margin row--space-between"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "payment-history-expanded-item__date mdc-typography--caption"
                                    },
                                    [_vm._v(_vm._s(_vm.formatCreatedDate))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "payment-history-expanded-item__time mdc-typography--caption"
                                    },
                                    [_vm._v(_vm._s(_vm.formatCreatedTime))]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row row--more-margin row--space-between"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tags__group" },
                                    [
                                      _c("ChargeStatusTag", {
                                        attrs: { status: _vm.charge }
                                      }),
                                      _vm.charge.payment_platform ===
                                      "WeChatPay"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tags tags--wechat filled"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "general.paymentMethods.wechat"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.charge.payment_platform === "AliPay"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tags tags--alipay filled"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "general.paymentMethods.alipay"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "buttons__group" }, [
                                    !_vm.charge.refunded && _vm.canRefund
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "mdc-button mdc-button--raised",
                                            on: { click: _vm.refund }
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "material-icons mdc-button__icon",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  viewBox: "0 0 24 24"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d:
                                                      "M11.9 6.5V4.4c0-.3-.4-.5-.6-.3L8.4 7c-.2.2-.2.4 0 .5l2.9 2.9c.2.2.6.1.6-.3V8c2.8 0 5 2.6 4.4 5.5-.4 1.7-1.7 3.1-3.4 3.4-2.7.6-5.1-1.3-5.4-3.8-.1-.4-.4-.6-.7-.6-.5 0-.8.4-.8.9.5 3.3 3.6 5.7 7.2 5.1 2.3-.5 4.2-2.3 4.7-4.7.6-3.9-2.3-7.3-6-7.3z"
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "mdc-button__text"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("actions.refund")
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "section" }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "mdc-typography--headline6" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "general.paymentDetails.description"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _vm.charge.description
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "payment-history-expanded-item__desc mdc-typography--body1"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(_vm.charge.description) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "payment-history-expanded-item__desc mdc-typography--body1"
                                      },
                                      [_vm._v("—")]
                                    )
                              ])
                            ]),
                            _c("div", { staticClass: "section" }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "mdc-typography--headline6" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("general.history.title"))
                                    )
                                  ]
                                )
                              ]),
                              _vm.refunded_at
                                ? _c(
                                    "div",
                                    { staticClass: "row row--space-between" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "payment-history-expanded-item__desc mdc-typography--body2"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("status.refunded"))
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "payment-history-expanded-item__datetime mdc-typography--caption"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.formatFullRefundedDate
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.charge.status
                                ? _c(
                                    "div",
                                    { staticClass: "row row--space-between" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "payment-history-expanded-item__desc mdc-typography--body2"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                {
                                                  succeeded: _vm.$t(
                                                    "status.succeeded"
                                                  ),
                                                  failed: _vm.$t(
                                                    "status.failed"
                                                  ),
                                                  revoked: _vm.$t(
                                                    "status.failed"
                                                  ),
                                                  pending: _vm.$t(
                                                    "status.pending"
                                                  ),
                                                  paying: _vm.$t(
                                                    "status.paying"
                                                  )
                                                }[_vm.charge.status]
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "payment-history-expanded-item__datetime mdc-typography--caption"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.formatFullCreatedDate
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _c(
                "div",
                [
                  _c(
                    "mdc-dialog",
                    {
                      ref: "refundDialogConfirm",
                      attrs: {
                        title: _vm.$t("receipt.refundConfirmation"),
                        accept: _vm.$t("actions.refund"),
                        cancel: _vm.$t("actions.cancel")
                      },
                      on: { accept: _vm.refundDialogConfirm_accept },
                      model: {
                        value: _vm.refundDialogConfirm_open,
                        callback: function($$v) {
                          _vm.refundDialogConfirm_open = $$v
                        },
                        expression: "refundDialogConfirm_open"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("receipt.refundOnceOnlyMsg")))
                      ])
                    ]
                  ),
                  _c(
                    "mdc-dialog",
                    {
                      ref: "refundDialogLoading",
                      staticClass: "dialog--no_cancel",
                      attrs: {
                        title: _vm.$t("actions.processing"),
                        accept: ""
                      },
                      on: { cancel: _vm.preventCancel },
                      model: {
                        value: _vm.refundDialogLoading_open,
                        callback: function($$v) {
                          _vm.refundDialogLoading_open = $$v
                        },
                        expression: "refundDialogLoading_open"
                      }
                    },
                    [
                      _c("div", { staticStyle: { "margin-bottom": ".5em" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "spinner spinner__container",
                            class: {
                              "spinner--active": _vm.refundDialogLoading_open
                            },
                            staticStyle: { "margin-right": ".5em" }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "spinner spinner__icon",
                                attrs: {
                                  viewBox: "0 0 66 66",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("circle", {
                                  staticClass: "path",
                                  attrs: {
                                    fill: "none",
                                    "stroke-width": "6",
                                    "stroke-linecap": "round",
                                    cx: "33",
                                    cy: "33",
                                    r: "30"
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _c("span", [_vm._v(_vm._s(_vm.$t("actions.loading")))])
                      ])
                    ]
                  ),
                  _c(
                    "mdc-dialog",
                    {
                      attrs: {
                        title: _vm.$t("status.failure"),
                        accept: _vm.$t("actions.accept")
                      },
                      model: {
                        value: _vm.refundDialogFail_open,
                        callback: function($$v) {
                          _vm.refundDialogFail_open = $$v
                        },
                        expression: "refundDialogFail_open"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("receipt.refundFailed")) +
                            " " +
                            _vm._s(_vm.$t("errors.GENERAL.retry"))
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }