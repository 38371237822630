





















































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import RemoteCampaignProductItem from '@/components/remote-campaign/RemoteCampaignProductItem.vue';
import RemoteCampaignLinkItem from '@/components/remote-campaign/RemoteCampaignLinkItem.vue';
import RemoteCampaignOpenQuestionItem from '@/components/remote-campaign/RemoteCampaignOpenQuestionItem.vue';
import RemoteCampaignContactItem from '@/components/remote-campaign/RemoteCampaignContactItem.vue';
import RemoteCampaignImageItem from '@/components/remote-campaign/RemoteCampaignImageItem.vue';
import { IMaskComponent } from 'vue-imask';
import bankOptions from '@/constants/BANKS';
const currencyList = bankOptions.currencyOptions;

function escapeURLSpecialChar(str: string) {
    let emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
    let urlSpecialCharRegex = /[\s\\\/?&={}<>()[]#?？＆＝｛｝＜＞（）［］＃？,，]/g;
    return str
        .replace(emojiRegex, '-')
        .replace(urlSpecialCharRegex, '-')
        .replace(/-{2,}/g, '-');
}

function trimUnderline(str: string) {
    return str.replace(/^-+/g, '').replace(/-*$/g, '');
}

const env = process.env.VUE_APP_ENV;
@Component({
    components: {
        RemoteCampaignProductItem,
        RemoteCampaignLinkItem,
        RemoteCampaignOpenQuestionItem,
        RemoteCampaignContactItem,
        RemoteCampaignImageItem,
        'imask-input': IMaskComponent,
    },
})
export default class EditingRemoteCampaign extends Vue {
    @Prop() merchantDisplayName!: string;

    @Prop() merchantLogo!: string;

    @Prop() branchOptions!: {}[];

    private agentPromotionAllowed: boolean = false;

    private agentPromotion: boolean = false;
    private clientType: string = 'currentClient';
    private clientList: Array<object> = [
        // {
        //     name: 'Paul',
        //     email: 'abc@gmail.com',
        // },
        // {
        //     name: 'Thomas',
        //     email: 'def@gmail.com',
        // },
        // {
        //     name: 'Dickson',
        //     email: 'ghi@gmail.com',
        // },
    ];
    private clientDetail: ClientDetail = {
        name: '',
        email: '',
    };
    private mask = /^[a-z0-9_.-]+@[a-z0-9-]+.([a-z]{2,4}.)?[a-z]{2,4}$/;
    private selectedClient: ClientDetail = {
        name: '',
        email: '',
    };
    private internalCampaignName = '';
    private externalCampaignName = '';
    private mdcTextFields!: any;
    private advertisement = '';
    private tnc = '';
    private cta = '';
    private dateRange = {
        from: {
            date: '',
            modified: false,
            ref: '',
        },
        to: {
            date: '',
            modified: false,
            ref: '',
        },
    };
    public currency = {
        text: 'currency.hkd',
        value: 'hkd',
    };
    // private currencyList = currencyList;
    private banners: any[] = [];

    private getBanners(event: any) {
        for (let i = 0; i < event.target.files.length; i++) {
            this.banners.push({
                id: `banner_${Date.now()}_${i}`,
                file: event.target.files[i],
            });
        }
    }

    private addBannerItem() {
        if (this.banners.length >= 5) {
            this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.exceed_photo_limit') });
            return;
        }

        (this.$refs.upload as any).click();
    }

    private deleteBannerItem(index: number) {
        this.banners.splice(index, 1);
    }

    //If clientType changed, we need to clear the selectedClient
    @Watch('clientType')
    onClientTypeChanged(newValue: any, oldValue: any) {
        if (oldValue == 'newClient' && newValue == 'currentClient') {
            this.clientDetail['name'] = this.selectedClient['name'];
            this.clientDetail['email'] = this.selectedClient['email'];
        } else {
            this.clientDetail['name'] = '';
            this.clientDetail['email'] = '';
        }
        // }
    }

    //If selectedClient changed, we need to modify the clientDetail
    @Watch('selectedClient')
    onSelectedClientChanged(newValue: any, oldValue: any) {
        let { name, email } = newValue;
        this.clientDetail['email'] = email;
        this.clientDetail['name'] = name;
    }

    @Watch('dateRange.from.date')
    onFromChanged(v: string, o: string) {
        v != o && (this.dateRange.from.modified = true);
        if (!this.dateRange.to.modified && v) {
            this.dateRange.to.date = v;
            try {
                (this.dateRange.to.ref as any).mdcTextField.foundation_.setValue(v);
            } catch (e) {}
            this.$nextTick(() => {
                this.dateRange.to.modified = false;
            });
        }
    }

    @Watch('dateRange.to.date')
    onToChanged(v: string, o: string) {
        v != o && (this.dateRange.to.modified = true);
        if (!this.dateRange.from.modified && v) {
            this.dateRange.from.date = v;
            try {
                (this.dateRange.from.ref as any).mdcTextField.foundation_.setValue(v);
            } catch (e) {}
            this.$nextTick(() => {
                this.dateRange.from.modified = false;
            });
        }
    }

    private branchList: {}[] = [];

    get checkAllButton() {
        return this.branchList.every((branch: any) => branch.selected === true);
    }

    set checkAllButton(val: boolean) {
        this.branchList.map((branch: any) => (branch.selected = val));
    }

    public created() {
        this.branchList = this.branchOptions.map((branch: any) => ({
            id: branch.id,
            name: branch.campaign_store_name,
            address: branch.campaign_store_address,
            selected: false,
        }));
    }

    get isTestingMode() {
        return env !== 'production' ? true : false;
    }

    // product list
    private productList: CampaignProductData[] = [
        {
            id: `product_${Date.now()}`,
            name: '',
            price: null,
            discountMessage: null,
            quantity: null,
            quantityPerOrder: null,
            editing: true,
        },
    ];

    get editingProductList() {
        return this.productList.some(item => item.editing === true);
    }

    get currencyList() {
        return currencyList.filter(({ text, value: currency }) => {
            return this.$store.state.user.allowedCurrencies['remote'].includes(currency);
        });
    }

    private addProductItem() {
        if (this.productList.length >= 20) {
            this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.exceed_product_limit') });
            return;
        }

        if (this.editingProductList) {
            this.$root.$emit('error', { message: this.$t('errors.GENERAL.editing') });
            return;
        }

        this.productList.push({
            id: `product_${Date.now()}`,
            name: '',
            price: null,
            originalPrice: null,
            discountMessage: null,
            quantity: null,
            quantityPerOrder: null,
            editing: true,
        });
    }

    private editProductItem(editingFlag: boolean, index: number) {
        this.productList[index].editing = editingFlag;
    }

    private saveProductItem(product: any, index: number) {
        let productItem = this.productList[index];
        productItem.name = product.name;
        productItem.originalPrice = product.originalPrice ? product.originalPrice : null;
        productItem.discountMessage = product.discountMessage ? product.discountMessage : null;
        productItem.price = product.price;
        productItem.quantity = product.quantity;
        productItem.quantityPerOrder = product.quantityPerOrder;
    }

    private deleteProductItem(index: number) {
        if (this.productList.length <= 1) {
            return this.$root.$emit('error', {
                message: this.$t('errors.CAMPAIGN.less_than_minimum', [this.$t('campaign.product.label')]),
            });
        }

        this.productList.splice(index, 1);
    }

    // link list
    private linkList = [
        {
            id: `link_${Date.now()}`,
            name: '',
        },
    ];

    private addLinkItem() {
        if (this.linkList.length >= 25) {
            this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.exceed_link_limit') });
            return;
        }

        this.linkList.push({
            id: `link_${Date.now()}`,
            name: '',
        });
    }

    private deleteLinkItem(index: number) {
        if (this.linkList.length < 2) {
            return this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.less_than_minimum', [this.$t('campaign.link')]) });
        }

        this.linkList.splice(index, 1);
    }

    // extra options

    private dateOption = false;
    private timeOption = false;
    private mcOption = false;
    private specialRequestOption = false;
    private emailOption = false;
    private genderOption = false;
    private ageOption = false;
    private addressOption = false;
    private branchOption = false;

    // mc questions

    private questionList = [
        {
            id: `question_${Date.now()}`,
            question: '',
            options: [''],
        },
    ];

    private addQuestionItem() {
        this.questionList.push({
            id: `question_${Date.now()}`,
            question: '',
            options: [''],
        });
    }

    private deleteQuestionItem(index: number) {
        if (this.questionList.length < 2) {
            return this.$root.$emit('error', {
                message: this.$t('errors.CAMPAIGN.less_than_minimum', [this.$t('campaign.question.label')]),
            });
        }

        this.questionList.splice(index, 1);
    }

    //contact list
    private contactList = [
        {
            id: `contact_${Date.now()}`,
            shopName: '',
            address: '',
            countryCode: '',
            number: '',
            editing: true,
        },
    ];

    get editingContactList() {
        return this.contactList.some(item => item.editing === true);
    }

    private addContactItem() {
        if (this.editingContactList) {
            this.$root.$emit('error', { message: this.$t('errors.GENERAL.editing') });
            return;
        }

        this.contactList.push({
            id: `contact_${Date.now()}`,
            shopName: '',
            address: '',
            countryCode: '',
            number: '',
            editing: true,
        });
    }

    private editContactItem(editingFlag: boolean, index: number) {
        this.contactList[index].editing = editingFlag;
    }

    private saveContactItem(contact: any, index: number) {
        let contactItem = this.contactList[index];

        contactItem.shopName = contact.shopName;
        contactItem.address = contact.address;
        contactItem.countryCode = contact.countryCode;
        contactItem.number = contact.number;
    }

    private deleteContactItem(index: number) {
        if (this.contactList.length < 2) {
            return this.$root.$emit('error', {
                message: this.$t('errors.CAMPAIGN.less_than_minimum', [this.$t('campaign.contact.title')]),
            });
        }

        this.contactList.splice(index, 1);
    }

    public mounted() {
        this.initMDCTextFields();
        this.dateRange.from.ref = (this.$refs as any).dateRangeFrom;
        this.dateRange.to.ref = (this.$refs as any).dateRangeTo;
        if (this.$store.state.user.permissions_.functional.campaign_agent == 1) {
            this.agentPromotionAllowed = true;
            this.$store
                .dispatch('retreiveCampaignAgentPromotionClientList')
                .then(res => {
                    this.clientList = res;
                })
                .catch(err => {
                    this.agentPromotionAllowed = false;
                });
        } else {
            this.agentPromotionAllowed = false;
        }
    }

    private initMDCTextFields() {
        // console.log(this.$el.querySelector('.mdc-text-field:not(.test)'));
        this.mdcTextFields = Array.from(this.$el.querySelectorAll('.mdc-text-field:not(.test)')).map((el: any) => new MDCTextField(el));
        // console.log(this.$el.querySelector('.mdc-text-field:not(.test)'));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }

    get previewData() {
        return {
            internalCampaignName: this.internalCampaignName.trim(),
            externalCampaignName: this.externalCampaignName.trim(),
            from: this.dateRange.from.date,
            to: this.dateRange.to.date,
            advertisement: this.advertisement,
            clientDetail: this.agentPromotion ? this.clientDetail : {},
            banners: this.banners.map(banner => banner.file),
            productList: this.productList,
            linkList: [...new Set([...this.linkList.map(link => `${link.name}`), ''])].map(
                link =>
                    link === ''
                        ? trimUnderline(escapeURLSpecialChar(this.externalCampaignName.trim().replace(/ /g, '-')))
                        : trimUnderline(escapeURLSpecialChar(this.externalCampaignName.trim().replace(/ /g, '-'))) +
                          '/' +
                          trimUnderline(escapeURLSpecialChar(`${link}`))
                // link === ''
                //     ? this.externalCampaignName.trim().replace(/ /g, '-')
                //     : `${this.externalCampaignName.trim().replace(/ /g, '-')}/${link}`
            ),
            contactList: this.contactList,
            questionList: this.mcOption ? this.questionList : null,
            branchInfoList: this.branchOption ? this.branchList.filter((branch: any) => branch.selected) : [],
            branchList: this.branchOption ? this.branchList.filter((branch: any) => branch.selected).map((branch: any) => branch.id) : [],

            dateOption: this.dateOption,
            timeOption: this.timeOption,
            genderOption: this.genderOption,
            ageOption: this.ageOption,
            addressOption: this.addressOption,
            emailOption: this.emailOption,
            mcOption: this.mcOption,
            specialRequestOption: this.specialRequestOption,
            branchOption: this.branchOption,
            currency: this.currency,
            tnc: this.tnc,
            cta: this.cta,
        };
    }

    public autofill(isAutoFill?: boolean) {
        this.externalCampaignName = 'beautiful campaign';
        this.internalCampaignName = 'beautiful';
        this.dateRange.from.date = '2020-06-09';
        this.dateRange.to.date = '2020-06-12';
        this.advertisement = 'advertisement';
        this.dateOption = true;
        this.timeOption = true;
        this.genderOption = true;
        this.ageOption = true;
        this.addressOption = true;
        this.mcOption = true;
        this.specialRequestOption = true;
        this.branchOption = true;
        this.emailOption = true;
        this.tnc = 'term and condition';
        this.cta = 'call to action';
    }

    private preview() {
        if (!this.internalCampaignName) {
            return this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.missing_input', [this.$t('campaign.internalName')]) });
        }

        if (this.agentPromotion === true && (this.clientDetail.name == '' || this.clientDetail.email == '')) {
            return this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.missing_input', [this.$t('campaign.agentPromotion')]) });
        }

        if (!this.dateRange.from.date || !this.dateRange.to.date) {
            return this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.missing_input', [this.$t('campaign.period')]) });
        }

        if (!this.externalCampaignName) {
            return this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.missing_input', [this.$t('campaign.externalName')]) });
        }

        if (!this.advertisement) {
            return this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.missing_input', [this.$t('campaign.copywriting')]) });
        }

        if (this.banners.length <= 0) {
            return this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.less_than_minimum', [this.$t('campaign.banners')]) });
        }

        if (this.editingProductList) {
            return this.$root.$emit('error', { message: this.$t('errors.GENERAL.editing') });
        }

        if (
            this.mcOption &&
            this.questionList.some(question => {
                return question.question === '' || question.options.some(option => option === '');
            })
        ) {
            return this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.mc_option') });
        }

        if (!this.tnc) {
            return this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.missing_input', [this.$t('campaign.tnc')]) });
        }

        if (!this.cta) {
            return this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.missing_input', [this.$t('campaign.cta')]) });
        }

        if (this.editingContactList) {
            return this.$root.$emit('error', { message: this.$t('errors.GENERAL.editing') });
        }

        this.$emit('preview', this.previewData);
    }
}
