






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class CaptureSetting extends Vue {
    private clearingDialogOpen = false;
    private captureModeDialogOpen = false;

    private clearing = false;
    private originalClearing = false;

    private captureMode = 'auto';
    private originalCaptureMode = 'auto';

    public async beforeCreate() {
        await this.$store.dispatch('updateUser');
        this.clearing = !this.$store.state.user.capture;
        this.originalClearing = this.clearing;

        this.captureMode = this.$store.state.user.capture_mode ? this.$store.state.user.capture_mode : 'auto';
        this.originalCaptureMode = this.captureMode;
    }

    private accept() {
        this.$store
            .dispatch('changeCaptureMode', {
                capture: false,
                captureMode: this.clearing ? this.captureMode : null,
            })
            .then((res: any) => {
                this.$store.commit('changeCaptureMode', res);
                this.clearing = !res.capture;
                this.captureMode = res.capture_mode ? res.capture_mode : 'auto';
                this.originalClearing = this.clearing;
                this.originalCaptureMode = this.captureMode;
            })
            .catch((error: any) => {
                this.isClearingChanged ? this.clearingChangeCanceled() : null;
                this.iscaptureModeChanged ? this.captureModeChangeCanceled() : null;
                return this.$root.$emit('error', error);
            });
    }

    private clearingChangeCanceled() {
        (this.$el.querySelectorAll('.switch-button input')[0] as HTMLElement).click();
    }

    get isClearingChanged() {
        return this.clearing === this.originalClearing ? false : true;
    }

    @Watch('clearing')
    clearingChanged() {
        if (this.isClearingChanged) {
            this.clearingDialogOpen = true;
        }
    }

    private captureModeChangeCanceled() {
        this.originalCaptureMode == 'auto'
            ? (this.$el.querySelectorAll('.capture-mode input')[0] as HTMLElement).click()
            : (this.$el.querySelectorAll('.capture-mode input')[1] as HTMLElement).click();
    }

    get iscaptureModeChanged() {
        return this.captureMode === this.originalCaptureMode ? false : true;
    }

    @Watch('captureMode')
    onValueChanged() {
        if (this.iscaptureModeChanged) {
            this.captureModeDialogOpen = true;
        }
    }
}
