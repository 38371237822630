








import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ConfirmDialog extends Vue {
    private open = false;
    private message = '';
    private title = '';
    private path = '';

    mounted() {
        this.$root.$on('confirm', (payload: any) => {
            this.open = true;
            this.title = payload.title ? payload.title : this.$t('actions.remind');
            this.path = payload.path;
            this.message = payload.message ? payload.message : '';
        });
    }

    onAccept() {
        // console.log(this.path);
        if (this.path == '/settings') {
            return this.$router.go(-1);
        } else {
            return this.$router.push({ path: this.path });
        }
    }
}
