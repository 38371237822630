export const placeHolderForCurrency = (currency: string) => {
    if (currency.toLowerCase() == 'usd') {
        return '$ ' + '0.00';
    } else if (currency.toLowerCase() == 'hkd') {
        return '$ ' + '0.00';
    } else if (currency.toLowerCase() == 'cny') {
        return '¥ ' + '0.00';
    } else if (currency.toLowerCase() == 'mop') {
        return '$ ' + '0.00';
    } else if (currency.toLowerCase() == 'jpy') {
        return '¥ ' + '0';
    } else if (currency.toLowerCase() == 'gbp') {
        return '£ ' + '0.00';
    } else if (currency.toLowerCase() == 'sgd') {
        return '$ ' + '0.00';
    } else if (currency.toLowerCase() == 'myr') {
        return 'RM ' + '0.00';
    } else if (currency.toLowerCase() == 'thb') {
        return '฿ ' + '0.00';
    }
};

export const symbolDisplay = (currency: string) => {
    if (currency.toLowerCase() == 'usd') {
        return '$ ';
    } else if (currency.toLowerCase() == 'hkd') {
        return '$ ';
    } else if (currency.toLowerCase() == 'cny') {
        return '¥ ';
    } else if (currency.toLowerCase() == 'mop') {
        return '$ ';
    } else if (currency.toLowerCase() == 'jpy') {
        return '¥ ';
    } else if (currency.toLowerCase() == 'gbp') {
        return '£ ';
    } else if (currency.toLowerCase() == 'sgd') {
        return '$ ';
    } else if (currency.toLowerCase() == 'myr') {
        return 'RM ';
    } else if (currency.toLowerCase() == 'thb') {
        return '฿ ';
    }
};

export const currencyNameWithSymbol = (currency: string) => {
    if (currency.toLowerCase() == 'usd') {
        return 'US$';
    } else if (currency.toLowerCase() == 'hkd') {
        return 'HK$';
    } else if (currency.toLowerCase() == 'cny') {
        return 'CN¥';
    } else if (currency.toLowerCase() == 'mop') {
        return 'MO$';
    } else if (currency.toLowerCase() == 'jpy') {
        return 'JP¥';
    } else if (currency.toLowerCase() == 'gbp') {
        return '£';
    } else if (currency.toLowerCase() == 'sgd') {
        return 'SG$';
    } else if (currency.toLowerCase() == 'myr') {
        return 'RM';
    } else if (currency.toLowerCase() == 'thb') {
        return '฿';
    }
};
