var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "remote-link-item" } }, [
    _c(
      "div",
      {
        staticClass:
          "mdc-layout-grid app-page-activity app-page-activity--active activity-payment-summary"
      },
      [
        _c("div", { staticClass: "mdc-layout-grid__inner" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet payment-summary",
              staticStyle: { flex: "1 1 auto" }
            },
            [
              _c("div", { staticClass: "payment-summary__wrapper-inner" }, [
                _c("div", { staticClass: "payment-summary__inner" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                    _vm._v(
                      "商戶 - " + _vm._s(_vm.item.statement_descriptor || "—")
                    )
                  ]),
                  _c("br"),
                  _c("div", { staticClass: "mdc-typography--body2" }, [
                    _vm._v("請仔細檢查所輸入的資料，一經送出將不能更改！")
                  ]),
                  _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                    _vm._v("金額")
                  ]),
                  _c("div", { staticClass: "mdc-typography--body1" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("currencySymbolDisplay")(_vm.item.currency)
                      ) + _vm._s(_vm.item.amount)
                    )
                  ]),
                  _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                    _vm._v("商品詳情")
                  ]),
                  _c("div", { staticClass: "mdc-typography--body1" }, [
                    _vm._v(_vm._s(_vm.item.description || "—"))
                  ]),
                  _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                    _vm._v("持卡人")
                  ]),
                  _c("div", { staticClass: "mdc-typography--body1" }, [
                    _vm._v(_vm._s(_vm.holderName || "—"))
                  ]),
                  _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                    _vm._v("信用卡號碼")
                  ]),
                  _c("div", { staticClass: "mdc-typography--body1" }, [
                    _vm._v(_vm._s(_vm.cardNumber || "—"))
                  ]),
                  _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                    _vm._v("信用卡到期日")
                  ]),
                  _c("div", { staticClass: "mdc-typography--body1" }, [
                    _vm._v(_vm._s(_vm.expiryDate || "—"))
                  ])
                ])
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
            },
            [
              _c(
                "button",
                {
                  staticClass: "mdc-button",
                  on: {
                    click: function($event) {
                      _vm.$emit("back")
                    }
                  }
                },
                [_vm._v("上一步")]
              ),
              _c(
                "button",
                {
                  staticClass: "mdc-fab mdc-fab--extended",
                  attrs: { "aria-label": "確認付款" },
                  on: { click: _vm.pay }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "mdc-fab__icon",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewbox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M11.7 11.1c-1.8-.5-2.4-1-2.4-1.7 0-.9.8-1.5 2.2-1.5 1.1 0 1.7.4 1.9 1.1.1.3.4.6.7.6h.2c.5 0 .9-.5.7-1-.3-1-1.1-1.7-2.4-2V6c0-.7-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2v.5c-1.5.3-2.7 1.3-2.7 2.9 0 1.9 1.5 2.8 3.8 3.3 2 .5 2.4 1.2 2.4 1.9 0 .6-.4 1.4-2.2 1.4-1.3 0-2-.5-2.3-1.2-.1-.3-.4-.5-.7-.5h-.2c-.5 0-.9.5-.7 1 .5 1.1 1.5 1.8 2.7 2v.7c0 .7.5 1.2 1.2 1.2s1.2-.5 1.2-1.2v-.5c1.6-.3 2.8-1.2 2.8-2.9 0-2.2-1.9-3-3.8-3.5z"
                        }
                      })
                    ]
                  ),
                  _c("span", { staticClass: "mdc-fab__label" }, [
                    _vm._v("確認付款")
                  ])
                ]
              )
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mdc-typography--headline6" }, [
      _c("span", [_vm._v("總結")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }