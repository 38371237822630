


































import { Component, Vue } from 'vue-property-decorator';
import jsQR from 'jsqr';
import base64js from 'base64-js';
import { setTimeout } from 'timers';
import { axios, webrtcAdapter } from '@/store';
import Loop from '@/utils/Loop';

// #region helpers
const sleep = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

/* function addrToBuff(ip: string, port: number) {
    let buffer = new ArrayBuffer(6);
    let u8view = new Uint8Array(buffer);
    let u16view = new Uint16Array(buffer);
    u8view.set(ip.split('.').map(i => parseInt(i)));
    u16view.set([port], 2);
    return buffer;
}

function buffToAddr(buff: any) {
    let ip = Array.from(new Uint8Array(buff))
        .slice(0, 4)
        .join('.');
    let port = new Uint16Array(buff)[2];
    return [ip, port].join(':');
}

function decodeAddr(encoded_addr: string) {
    const prefix = 'https://';
    return prefix + buffToAddr(base64js.toByteArray(encoded_addr).buffer);
} */
// #endregion helpers

// #region definations
const mediaSettings = {
    video: {
        facingMode: 'environment',
        width: { ideal: 1920 },
        height: { ideal: 1080 },
    },
    audio: false,
};
const maxCanvasSize = 400;
const requestTimeout = 5000;
// #endregion definations

class Scanner {
    public running = false;
    public video: any;
    private canvas: any;
    private virtualCanvas = false;
    private canvasContext: any;
    private videoStream: any;
    private scannerLoop?: Loop;
    private onDecoded?: any;
    private onDecodedContext?: any;
    public previousScan = '';
    constructor({ video, canvas, onDecoded, onDecodedContext }: { video: any; canvas?: any; onDecoded?: any; onDecodedContext?: any }) {
        this.video = video;
        if (canvas) this.canvas = canvas;
        else this.virtualCanvas = true;
        this.onDecoded = onDecoded;
        this.onDecodedContext = onDecodedContext;
        this.scannerLoop = new Loop(this, this.findQR);
    }
    public start = async () => {
        let video = this.video;
        this.videoStream = await navigator.mediaDevices.getUserMedia(mediaSettings);
        video.srcObject = this.videoStream;
        video.setAttribute('playsinline', true);
        video.play();
        await (async () => {
            while (this.video.readyState !== this.video.HAVE_ENOUGH_DATA) {
                await sleep(30);
            }
        })();
        video.style.display = 'block';
        let max = maxCanvasSize;
        let h, nh, w, nw;
        nh = h = video.videoHeight;
        nw = w = video.videoWidth;
        if (h > max || w > max) {
            if (h > w) {
                nh = max;
                nw = (max / h) * w;
            } else {
                nw = max;
                nh = (max / w) * h;
            }
        }
        if (this.virtualCanvas) this.canvas = document.createElement('canvas');
        this.canvasContext = this.canvas.getContext('2d');
        this.canvas.height = nh;
        this.canvas.width = nw;

        this.addListeners();
        await this.startLoop();
        this.running = true;
    };
    public stop = async () => {
        try {
            this.removeListeners();
        } catch (e) {}
        try {
            this.video.srcObject.getVideoTracks().forEach((track: any) => track.stop());
        } catch (e) {}
        try {
            if (this.virtualCanvas) this.canvas.remove();
        } catch (e) {}
        await this.stopLoop();
        this.running = false;
    };

    public pause = async () => {
        if (!this.running) return;
        this.running = false;
        this.video.style.filter = 'blur(25px)';
        this.video.pause();
        await this.stopLoop();
    };

    public resume = () => {
        if (this.running) return;
        this.video.play();
        this.video.style.filter = '';
        this.startLoop();
        this.running = true;
    };

    private startLoop = () => (this.scannerLoop as Loop).start(this.video, this.onDecoded, this.onDecodedContext);
    private stopLoop = () => (this.scannerLoop as Loop).stop();

    private addListeners = () => {
        window.addEventListener('focus', this.focusHandler);
        window.addEventListener('blur', this.blurHandler);
    };

    private removeListeners = () => {
        window.removeEventListener('focus', this.focusHandler);
        window.removeEventListener('blur', this.blurHandler);
    };

    private async findQR(video: any, cb?: any, cbContext?: any) {
        if (!this.running) return;
        try {
            if (video.readyState !== video.HAVE_ENOUGH_DATA) return await sleep(30);
            this.canvasContext.drawImage(video, 0, 0, this.canvas.width, this.canvas.height);
            let imageData = this.canvasContext.getImageData(0, 0, this.canvas.width, this.canvas.height);
            let code = jsQR(imageData.data, imageData.width, imageData.height /* , {inversionAttempts: 'dontInvert'} */);
            if (!(this.running && code && code.data && code.data !== this.previousScan)) return;
            this.previousScan = code.data;
            cb && cb.call(cbContext, code);
        } catch (error) {
            // console.log(error);
        } finally {
            await sleep(100);
        }
    }

    public focusHandler = () => {
        this.resume();
    };

    public blurHandler = () => {
        this.pause();
    };
}

@Component
export default class ConnectPrinter extends Vue {
    private video: any;
    private scanner?: Scanner;
    private connectDialog__open = false;
    private successDialog__open = false;
    private failscanDialog__open = false;
    private _connectDialog__accept?: () => void;
    private fileUpload = false;

    private fileInputHandler = async (e: any) => {
        let file = e.target.files[0];
        if (!(file && /^image\//i.test(file.type))) return;
        (this.$refs.imgUploaded as any).src = window.URL.createObjectURL(file);
        await new Promise(res => (this.$refs.imgUploaded as any).addEventListener('load', res, { once: true }));
        (this.$refs.imgUploaded as any).style.opacity = 1;
        (this.$refs.uploadButton as any).$el.style.transform = `translateZ(0) translateY(calc(50vh - ${
            window.matchMedia('(display-mode: standalone)').matches ? 150 : 300
        }%))`;
        await sleep(200);
        try {
            await this.decodeImage(await this.loadImage(file));
            (this.$refs.imgUploaded as any).style.filter = 'blur(25px)';
            (this.$refs.uploadButton as any).$el.style.filter = 'blur(25px)';
        } catch (error) {
            this.failscanDialog__open = true;
            (this.$refs.imgUploaded as any).style.opacity = 0.4;
        }
        /* this.loadImage(file)
            .then(this.decodeImage)
            .then(() => {
                (this.$refs.fileCanvas as any).style.filter = 'blur(25px)';
                (this.$refs.uploadButton as any).$el.style.filter = 'blur(25px)';
            })
            .catch(e => {
                console.log(this);
                this.failscanDialog__open = true;
                // (this.$refs.imgUploaded as any).style.opacity = 0.4;
            }); */
    };

    private loadImage(file: any) {
        return new Promise((res, rej) => {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let img = new Image();
                img.onload = () => {
                    res(img);
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        });
    }

    private decodeImage = async (img: any) => {
        let canvas: any = document.createElement('canvas');
        let canvasContext = canvas.getContext('2d');

        let max = 700;
        let h, nh, w, nw;
        nh = h = img.height;
        nw = w = img.width;
        if (h > max || w > max) {
            if (h > w) {
                nh = max;
                nw = (max / h) * w;
            } else {
                nw = max;
                nh = (max / w) * h;
            }
        }
        canvas.width = nw;
        canvas.height = nh;
        canvasContext.drawImage(img, 0, 0, canvas.width, canvas.height);

        let imageData = canvasContext.getImageData(0, 0, canvas.width, canvas.height);
        let code = jsQR(imageData.data, imageData.width, imageData.height /* , {inversionAttempts: 'dontInvert'} */);
        canvas.remove();
        if (!(code && code.data)) throw new Error('Decode failed!');
        await this.onDecoded({
            data: code.data,
            fromUpload: true,
        });
    };

    public async mounted() {
        this.video = this.$refs.video;
        await webrtcAdapter;
        this.scanner = new Scanner({
            video: this.video,
            onDecoded: this.onDecoded,
            onDecodedContext: this,
        });
        try {
            await this.scanner.start();
        } catch (error) {
            this.fileUpload = true;
            (this.$refs.qrupload as HTMLElement).addEventListener('change', this.fileInputHandler);
            await this.scanner.stop();
            (this.$refs.imgUploaded as HTMLElement).addEventListener('load', function() {
                window.URL.revokeObjectURL((this as any).src);
            });
            // console.log(error);
        }
    }
    public async beforeDestroy() {
        this.scanner && (await this.scanner.stop());
    }
    public async onDecoded({ data, noPopup, fromUpload }: { data: any; noPopup?: boolean; fromUpload?: boolean }) {
        if ((!fromUpload && !(this.scanner as Scanner).running) || this.connectDialog__open) return;
        let decoded_addr: string, response;
        // decode data
        /* try {
            if (data.length !== 8) throw new Error('invalid format');
            decoded_addr = decodeAddr(data);
        } catch (e) {
            return;
        } */
        decoded_addr = 'https://' + data + '.ap.ngrok.io';

        // test connection
        if ((!fromUpload && !(this.scanner as Scanner).running) || this.connectDialog__open) return;
        !fromUpload && (await (this.scanner as Scanner).pause());
        try {
            response = await axios.request({ url: decoded_addr, timeout: requestTimeout });
            if (!(response && response.data === 'OK')) throw new Error();
        } catch (e) {
            if (fromUpload) throw e;
            (this.scanner as Scanner).resume();
            setTimeout(() => {
                if ((this.scanner as Scanner).previousScan == data) {
                    (this.scanner as Scanner).previousScan = '';
                }
            }, 2500);
            //pop connect dialog
            /* if (this.connectDialog__open || noPopup) return;
            this._connectDialog__accept = () => {
                this.connectDialog__open = false;
                this._connectDialog__accept = undefined;
                // (this.scanner as Scanner).resume();
                setTimeout(() => {
                    let new_window = window.open(decoded_addr + '/connect');
                    let pollTimer = setInterval(() => {
                        if ((new_window as Window).closed !== false) {
                            clearInterval(pollTimer);
                            (this.scanner as Scanner).resume();
                            this.onDecoded({ data, noPopup: true });
                        }
                    }, 100);
                }, 0);
                setTimeout(() => {
                    if ((this.scanner as Scanner).previousScan == data) {
                        (this.scanner as Scanner).previousScan = '';
                    }
                }, 2500);
            };
            this.connectDialog__open = true; */
            return;
        }
        await this.$store.dispatch('printerAddr', decoded_addr);
        // this.$router.go(-1);
        this.successDialog__open = true;
    }

    /* public connectDialog__accept() {
        this._connectDialog__accept && this._connectDialog__accept();
    }

    public connectDialog__cancel() {
        (this.scanner as Scanner).resume();
        let data = (this.scanner as Scanner).previousScan;
        setTimeout(() => {
            if ((this.scanner as Scanner).previousScan == data) {
                (this.scanner as Scanner).previousScan = '';
            }
        }, 1000);
    } */
    public successDialog__accept() {
        this.$router.go(-1);
    }

    public upload() {
        (this.$refs.qrupload as HTMLElement).click();
    }
}
