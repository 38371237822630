export const actions = {
    accept: 'Accept',
    back: 'Back',
    backOrEdit: 'Back / Edit',
    create: 'Create',
    copy: 'Copy',
    cancel: 'Cancel',
    change: 'Change',
    confirm: 'Confirm',
    createLink: 'Create Link',
    delete: 'Delete',
    edit: 'Edit',
    editing: 'Editing',
    save: 'Save',
    send: 'Send',
    sent: 'Sent',
    submit: 'Submit',
    preview: 'Preview',
    print: 'Print',
    noMore: 'No More',
    loadMore: 'Load More...',
    next: 'Next',
    pay: 'Pay',
    qrPay: 'QR Pay',
    reset: 'Reset',
    remind: 'Remind',
    refund: 'Refund',
    register: 'Register',
    login: 'Login',
    logout: 'Logout',
    loading: 'Loading',
    upload: 'Upload',
    processing: 'Processing...',
    removeAll: 'Remove all',
    reupload: 'Re-upload the file',
    retake: 'Retake photo of the file',
    takePhoto: 'Camera',
    noYetUploaded: 'The receipt has not uploaded yet. Please pause the upload button.',
};
