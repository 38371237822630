


















































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MTextfield, MFormField, MCheckbox, MFloatingLabel, MNotchedOutline, MFab } from '@/../libraries/material-components-vue';
import { moment_HK as moment, axios } from '@/store';
import { rnd } from '@/utils';
import Spinner1 from '@/components/spinners/Spinner1.vue';
import { setTimeout } from 'timers';

function cvtD(x: string) {
    // converInputDate
    return moment(x, 'YYYY-MM-DD').format('YYYYMMDD');
}

Vue.use(MTextfield);
Vue.use(MFormField);
Vue.use(MCheckbox);
Vue.use(MFloatingLabel);
Vue.use(MNotchedOutline);
Vue.use(MFab);

@Component({
    components: {
        Spinner1,
    },
})
export default class ExportSelection extends Vue {
    @Watch('from')
    onFromChanged(v: string, o: string) {
        v != o && (this.dateRange.from.modified = true);
        if (!this.dateRange.to.modified && v) {
            this.to = v;
            try {
                (this.dateRange.to.ref as any).mdcTextField.foundation_.setValue(v);
            } catch (e) {}
            this.$nextTick(() => {
                this.dateRange.to.modified = false;
            });
        }
    }

    @Watch('to')
    onToChanged(v: string, o: string) {
        v != o && (this.dateRange.to.modified = true);
        if (!this.dateRange.from.modified && v) {
            this.from = v;
            try {
                (this.dateRange.from.ref as any).mdcTextField.foundation_.setValue(v);
            } catch (e) {}
            this.$nextTick(() => {
                this.dateRange.from.modified = false;
            });
        }
    }

    private byBranchToggle = "false";
    private exportType = '';
    private from = '';
    private to = '';

    private exportTypes = {
        transactions: { label: { zhHK: 'settings.export.type.transaction' } },
        journal: { label: { zhHK: 'settings.export.type.journal' } },
        onlinePayments: { label: { zhHK: 'settings.export.type.online' } },
        statement: { label: { zhHK: 'settings.export.type.statement' } },
        ...(this.master && this.permissions.functional.payout && { payouts: { label: { zhHK: 'settings.export.type.payout' } } }),
    };

    private dateRange = {
        from: { id: rnd(), label: { zhHK: 'general.date.start' }, modified: false, ref: undefined },
        to: { id: rnd(), label: { zhHK: 'general.date.end' }, modified: false, ref: undefined },
    };

    private shopsSelection: {
        id: string;
        store_name: string;
        checked: boolean;
        uid: string;
    }[] = [];

    private selectionsName_ = rnd();
    private shopsSelectionAll_ = rnd();

    public created() {
        let currentBranch = this.$t('general.thisStore');
        this.master &&
            axios
                .get(`/account/stores`)
                .then(res => {
                    if (res.data.success) {
                        return res.data.data;
                    }
                    throw res;
                })
                .then(d =>
                    d.map((s: any) =>
                        Object.assign(s, {
                            store_name: (
                                (s.store_name || '') +
                                (s.isThisStore ? ` ${currentBranch}` : '') +
                                (s.master ? ' (master)' : '')
                            ).trim(),
                        })
                    )
                )
                .then(s => this.addShopSelections(s));
    }

    public mounted() {
        this.dateRange.from.ref = (this.$refs as any).dateRangeFrom;
        this.dateRange.to.ref = (this.$refs as any).dateRangeTo;
    }

    private addShopSelections(s: any[]) {
        this.shopsSelection = this.shopsSelection.concat(
            s.map(s =>
                Object.assign(s, {
                    checked: false,
                    uid: rnd(),
                })
            )
        );
    }

    private generateExportDownloadLink() {
        let byBranch = (this.byBranchToggle === 'true');
        this.$store
            .dispatch('generateExportDownloadLink', {
                startDate: cvtD(this.from),
                endDate: cvtD(this.to),
                type: this.exportType,
                ...(this.shopsSelectionSome &&
                    this.master &&
                    this.exportType != 'payouts' && {
                        accountList: this.shopsSelection.filter(s => s.checked).map(s => s.id),
                    }),
                isByBranch: byBranch,
                locale: this.$i18n.locale,
            })
            .then(d => {
                this.$router.push({ name: 'settings.export.download', params: { id: d.tokenId, code: d.code, expire_at: d.expireAt, type: this.exportType} });
            })
            .catch((e: any) => {
                this.$root.$emit('error', e);
            });
    }

    get master() {
        try {
            return this.$store.state.user.permissions_.master;
        } catch (e) {
            return false;
        }
    }

    get permissions() {
        try {
            return this.$store.state.user.permissions_;
        } catch (e) {
            return {};
        }
    }

    get shopsSelectionAll() {
        return this.shopsSelection.length && this.shopsSelection.every(s => s.checked);
    }

    set shopsSelectionAll(v: any) {
        this.shopsSelection.forEach(s => (s.checked = v));
    }

    get shopsSelectionSome() {
        return this.shopsSelection.some(s => s.checked);
    }

    get shopsSelectionCount() {
        return this.shopsSelection.filter(s => s.checked).length;
    }

    get rnd() {
        return rnd;
    }

    get moment() {
        return moment;
    }
}
