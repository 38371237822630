import moment from '@/utils/moment_HK_en';
import { Moment } from 'moment';
import { CMD, EscposGenerator } from '@/../libraries/escpos-generator-browser';

interface GenerateReceiptParamsCharge {
    receipt_info: {
        merchant_name: string;
        merchant_legal_entity: string;
        store_name?: string;
        store_address?: string;
        mid: string;
        sid: string;
    };
    receipt_no: string;
    created_at: string;
    creditcard: {
        last4: string;
        brand: string;
        funding: string;
    };
    status: string;
    network_status: string;
    amount: number;
    currency: string;
}
type GenerateReceiptParamsCopy = 'cardholder' | 'merchant';
type GenerateReceiptParamsWidth = 80 | 58;
type GenerateReceiptParamsFormat = 'escpos' | 'htmlTemplate';
interface GenerateReceiptParams {
    charge: GenerateReceiptParamsCharge;
    copy: GenerateReceiptParamsCopy;
    refund?: boolean;
    reprint?: boolean;
    width?: GenerateReceiptParamsWidth;
    format?: GenerateReceiptParamsFormat;
}

interface GenerateReceiptFunctionParams extends GenerateReceiptParams {
    refund: boolean;
    reprint: boolean;
    width: GenerateReceiptParamsWidth;
    format: GenerateReceiptParamsFormat;
    now: Moment;
}

const cardholderAgreement =
    `The issuer of the card identified on this item is authorised to pay the amount shown as total. Upon proper presentation, I promise to pay such total (together with any other charges due thereon) subject to and in accordance with the agreement covering the use of such card. I acknowledge satisfactory receipt of all relative goods/services.\n` +
    `I irrevocably agree to indemnify, defend, and hold harmless [merchant_name] and Jarvix Pay Limited, their officers, directors, employees, agents, suppliers and third party partners from and against all losses, expenses, damages and costs, including reasonable attorneys' fees. resulting from: (1) my payment; (2) any use of any services provided by Jarvix Pay Limited.`;

const aidList = {
    'American Express': {
        d: 'A00000002501',
    },
    'Diners Club': {
        d: 'A0000001523010',
    },
    Discover: {
        d: 'A0000001523010',
    },
    JCB: {
        d: 'A0000000651010',
    },
    MasterCard: {
        d: 'A0000000041010',
    },
    UnionPay: {
        credit: 'A000000333010102',
        debit: 'A000000333010101',
        d: 'A000000333010100',
    },
    Visa: {
        d: 'A0000000031010',
    },
    d: { d: '' },
};

function leftRight(width: number, rows: Array<[string, string]>) {
    return rows.map((x: Array<string>) => x[0].padEnd(width - x[1].length) + x[1]).join('\n');
}

function tabStopLeft(widths: Array<number>, rows: Array<Array<string>>) {
    return rows.map((x: Array<string>) => x.map((y: string, i: number) => y.padEnd(widths[i])).join('')).join('\n');
}

function wrapText(paragraph: string, width: number) {
    let _final = [];
    let words = paragraph.split(' ');
    let rows = [];
    while (words.length) {
        let row = [];
        while (words.length && row.concat(words[0]).join(' ').length <= width) {
            row.push(words.shift());
        }
        rows.push(row.join(' '));
    }
    return rows.join('\n');
}

function find(list: { [_: string]: any; d: any }, key: any) {
    return list[key] || list.d;
}

function aid({ brand, funding }: { brand: string; funding: string }) {
    return find(find(aidList, brand), funding);
}

/* function wrapText2(paragraph, width, wordBreak = false) {
    let words = paragraph.split(' ');
    let rows = [];
    do {
        let row = [];
        while (words.length) {
            if (row.concat(words[0]).join(' ').length <= width) {
                row.push(words.shift());
            } else if (wordBreak && width - row.join(' ').length >= 5 && row.concat(words[0]).join(' ').length - width >= 3) {
                let indexEnd = width - row.join(' ').length - 2;
                let word = words.shift();
                row.push(word.substring(0, indexEnd) + '-');
                words.unshift(word.substring(indexEnd));
                break;
            } else {
                break;
            }
        }
        rows.push(row.join(' '));
    } while (words.length);
    return rows.join('\n');
} */

export default async function({
    // logo,
    charge,
    copy,
    refund = false,
    reprint = false,
    width = 80,
    format = 'escpos',
}: GenerateReceiptParams) {
    let _charge: GenerateReceiptParamsCharge = JSON.parse(JSON.stringify(charge));
    let now = moment();
    width = width === 80 || width === 58 ? width : 80;

    if (charge.status === 'uncaptured') _charge.status = 'succeeded';
    if (format === 'escpos') {
        return generateEscpos({ charge: _charge, copy, refund, reprint, width, format, now });
    } else if (format === 'htmlTemplate') {
        return generateHtmlTemplate({ charge: _charge, copy, refund, reprint, width, format, now });
    } else {
        throw new Error('Invalid format option!');
    }
}

function generateEscpos({ charge, copy, refund, reprint, width, format, now }: GenerateReceiptFunctionParams): Uint8Array {
    let escpos = new EscposGenerator().init();
    const copyText = ({
        cardholder: "CARDHOLDER'S COPY",
        merchant: "MERCHANT'S COPY",
    } as { [_: string]: string })[copy];
    const pageWidth_smallText = ({
        80: 56,
        58: 40,
    } as { [_: number]: number })[width];
    const pageWidth = ({
        80: 42,
        58: 30,
    } as { [_: number]: number })[width];
    const tabStops = ({
        80: [[14, pageWidth - 14]],
        58: [[11, pageWidth - 11]],
    } as { [_: number]: Array<[number, number]> })[width];
    const lineSpace_default = 48;

    escpos.init();
    escpos.align('CT');
    escpos
        .size(2, 2)
        .boldOn()
        .font('B')
        .lineText(wrapText(charge.receipt_info.merchant_name, pageWidth_smallText / 2))
        .font('A')
        .boldOff()
        .size(1, 1)
        .feed(1);

    escpos.text(
        wrapText(
            charge.receipt_info.merchant_legal_entity.concat(charge.receipt_info.store_name ? ` - ${charge.receipt_info.store_name}` : ''),
            pageWidth
        )
    );

    escpos
        .lineSpace(lineSpace_default * 1.5)
        .feed(1)
        .lineSpace();

    charge.receipt_info.store_address &&
        escpos
            .font('B')
            .lineSpace(0)
            .text(wrapText(charge.receipt_info.store_address, pageWidth_smallText))
            .lineSpace()
            .font('A');
    escpos.feed(3).align('LT');

    escpos
        .text(
            leftRight(pageWidth, [
                [pageWidth > 30 ? 'PRINT TIME' : 'PRT TIME', now.format('L') + ' ' + now.format('LTS')],
                ['MID', charge.receipt_info.mid],
                ['SID', charge.receipt_info.sid],
                ['RECEIPT NO.', charge.receipt_no],
            ])
        )
        .feed(3);

    escpos
        .boldOn()
        .text('SALE' + (refund ? ' (REFUNDED)' : ''))
        .boldOff()
        .feed(2);

    escpos
        .align('CT')
        .underlineOn(2)
        .text('TRANSACTION INFORMATION')
        .underlineOff()
        .feed(2)
        .align('LT');

    escpos
        .text(
            tabStopLeft(tabStops[0], [
                ['DATE/TIME:', moment(charge.created_at).format('L') + ' ' + moment(charge.created_at).format('LTS')],
                ['CARD NUM:', charge.creditcard.last4.padStart(16, '*')],
                ['EXP:', '**/**'],
                ['APP:', charge.creditcard.brand + (charge.creditcard.funding !== 'unknown' ? ' ' + charge.creditcard.funding : '')],
                ['RESULT:', charge.status],
                ['STATUS:', charge.network_status.replace(/_/g, ' ')],
                ['AID:', aid(charge.creditcard)],
                [],
                ['AMOUNT:', `${charge.currency.toUpperCase()} ${charge.amount.toFixed(2)}`],
            ]).toUpperCase()
        )
        .feed(3);

    escpos
        .feed(2)
        .underlineOn()
        .lineText(
            Array(pageWidth)
                .fill(' ')
                .join('')
        )
        .underlineOff()
        .text('CARDHOLDER SIGNATURE')
        .feed(2)
        .font('B')
        .lineSpace(0)
        .text(wrapText(cardholderAgreement.replace('[merchant_name]', charge.receipt_info.merchant_name), pageWidth_smallText))
        .lineSpace()
        .feed(3)
        .font('A');

    escpos
        .align('CT')
        .boldOn()
        .text(`**** ${copyText} ****`)
        .boldOff()
        .align('LT')
        .feed(2);

    copy === 'cardholder' && escpos.text('THANK YOU!');

    return escpos.cutB().flush();
}

function generateHtmlTemplate({ charge, copy, refund, reprint, width, format, now }: GenerateReceiptFunctionParams) {
    return {
        charge: {
            receipt_info: {
                merchant_name: charge.receipt_info.merchant_name,
                merchant_legal_entity: charge.receipt_info.merchant_legal_entity,
                store_name: charge.receipt_info.store_name,
                store_address: charge.receipt_info.store_address,
                mid: charge.receipt_info.mid,
                sid: charge.receipt_info.sid,
            },
            receipt_no: charge.receipt_no,
            creditcard: {
                last4: charge.creditcard.last4,
                brand: charge.creditcard.brand,
                funding: charge.creditcard.funding,
            },
            status: charge.status,
            network_status: charge.network_status.replace(/_/g, ' '),
            amount: charge.amount.toFixed(2),
            created_date_time: moment(charge.created_at).format('L') + ' ' + moment(charge.created_at).format('LTS'),
            aid: aid(charge.creditcard),
            currency: charge.currency.toUpperCase(),
        },
        print_date_time: now.format('L') + ' ' + now.format('LTS'),
        copy,
        refund,
        reprint,
        width,
    };
}
