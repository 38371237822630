var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "registration" }, [
    _c(
      "div",
      { staticClass: "app-page app-page-registration app-page--active" },
      [
        _c("div", { staticClass: "app-page-container" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid app-page-activity app-page-activity--active bottom-nav--hidden"
            },
            [
              !_vm.hidden
                ? _c(
                    "form",
                    {
                      staticClass: "mdc-layout-grid__inner",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.register($event)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6"
                        },
                        [_vm._v(_vm._s(_vm.$t("signup.accountRegistration")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                        },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedLanguage,
                                  expression: "selectedLanguage"
                                }
                              ],
                              staticClass: "mdc-text-field__input",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedLanguage = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { disabled: "" },
                                  domProps: { value: null }
                                },
                                [_vm._v(_vm._s(_vm.$t("settings.language")))]
                              ),
                              _vm._l(_vm.langs, function(lang) {
                                return _c(
                                  "option",
                                  {
                                    key: lang.name,
                                    domProps: { value: lang.value }
                                  },
                                  [_vm._v(_vm._s(lang.nativeName))]
                                )
                              })
                            ],
                            2
                          ),
                          _c("label", { staticClass: "mdc-floating-label" }, [
                            _vm._v(_vm._s(_vm.$t("settings.language")))
                          ]),
                          _c("div", { staticClass: "mdc-notched-outline" }, [
                            _c("svg", [
                              _c("path", {
                                staticClass: "mdc-notched-outline__path"
                              })
                            ])
                          ]),
                          _c("div", {
                            staticClass: "mdc-notched-outline__idle"
                          })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                        },
                        [
                          _c("imask-input", {
                            ref: "companyName",
                            staticClass: "mdc-text-field__input",
                            attrs: {
                              mask: _vm.engCharMask,
                              id: "companyName",
                              type: "text",
                              autocomplete: "off",
                              autocorrect: "off",
                              spellcheck: "false"
                            },
                            model: {
                              value: _vm.companyName,
                              callback: function($$v) {
                                _vm.companyName = $$v
                              },
                              expression: "companyName"
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "mdc-floating-label ",
                              attrs: { for: "companyName" }
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.companyName")))]
                          ),
                          _c("div", { staticClass: "mdc-notched-outline" }, [
                            _c("svg", [
                              _c("path", {
                                staticClass: "mdc-notched-outline__path"
                              })
                            ])
                          ]),
                          _c("div", {
                            staticClass: "mdc-notched-outline__idle"
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.email,
                                expression: "email"
                              }
                            ],
                            staticClass: "mdc-text-field__input",
                            attrs: {
                              type: "email",
                              autocomplete: "off",
                              autocorrect: "off",
                              spellcheck: "false"
                            },
                            domProps: { value: _vm.email },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.email = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "mdc-floating-label ",
                              attrs: { for: "email" }
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.email")))]
                          ),
                          _c("div", { staticClass: "mdc-notched-outline" }, [
                            _c("svg", [
                              _c("path", {
                                staticClass: "mdc-notched-outline__path"
                              })
                            ])
                          ]),
                          _c("div", {
                            staticClass: "mdc-notched-outline__idle"
                          })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password"
                              }
                            ],
                            staticClass: "mdc-text-field__input",
                            attrs: {
                              type: "password",
                              autocomplete: "off",
                              autocorrect: "off",
                              spellcheck: "false"
                            },
                            domProps: { value: _vm.password },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.password = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "mdc-floating-label ",
                              attrs: { for: "password" }
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.password")))]
                          ),
                          _c("div", { staticClass: "mdc-notched-outline" }, [
                            _c("svg", [
                              _c("path", {
                                staticClass: "mdc-notched-outline__path"
                              })
                            ])
                          ]),
                          _c("div", {
                            staticClass: "mdc-notched-outline__idle"
                          })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.confirmPassword,
                                expression: "confirmPassword"
                              }
                            ],
                            staticClass: "mdc-text-field__input",
                            attrs: {
                              type: "password",
                              autocomplete: "off",
                              autocorrect: "off",
                              spellcheck: "false"
                            },
                            domProps: { value: _vm.confirmPassword },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.confirmPassword = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "mdc-floating-label ",
                              attrs: { for: "confirmPassword" }
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.confirmPassword")))]
                          ),
                          _c("div", { staticClass: "mdc-notched-outline" }, [
                            _c("svg", [
                              _c("path", {
                                staticClass: "mdc-notched-outline__path"
                              })
                            ])
                          ]),
                          _c("div", {
                            staticClass: "mdc-notched-outline__idle"
                          })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                        },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedCountryCode,
                                  expression: "selectedCountryCode"
                                }
                              ],
                              staticClass: "mdc-text-field__input",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedCountryCode = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { disabled: "" },
                                  domProps: { value: null }
                                },
                                [_vm._v(_vm._s(_vm.$t("signup.countryCode")))]
                              ),
                              _vm._l(_vm.countryCodeList, function(code) {
                                return _c(
                                  "option",
                                  { key: code.en, domProps: { value: code } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selectedLanguage == "zh_HK"
                                          ? code.zh
                                          : _vm.selectedLanguage == "zh_CN"
                                            ? code.cn
                                            : code.en
                                      ) +
                                        " " +
                                        _vm._s("(" + code.phone_code + ")")
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _c("label", { staticClass: "mdc-floating-label" }, [
                            _vm._v(_vm._s(_vm.$t("signup.countryCode")))
                          ]),
                          _c("div", { staticClass: "mdc-notched-outline" }, [
                            _c("svg", [
                              _c("path", {
                                staticClass: "mdc-notched-outline__path"
                              })
                            ])
                          ]),
                          _c("div", {
                            staticClass: "mdc-notched-outline__idle"
                          })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.phone,
                                expression: "phone"
                              }
                            ],
                            staticClass: "mdc-text-field__input",
                            attrs: {
                              type: "tel",
                              autocomplete: "off",
                              autocorrect: "off",
                              spellcheck: "false"
                            },
                            domProps: { value: _vm.phone },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.phone = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "mdc-floating-label",
                              attrs: { for: "phone" }
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.phone")))]
                          ),
                          _c("div", { staticClass: "mdc-notched-outline" }, [
                            _c("svg", [
                              _c("path", {
                                staticClass: "mdc-notched-outline__path"
                              })
                            ])
                          ]),
                          _c("div", {
                            staticClass: "mdc-notched-outline__idle"
                          })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mdc-form-field",
                              staticStyle: { "margin-bottom": "-11px" }
                            },
                            [
                              _c("div", { staticClass: "mdc-checkbox" }, [
                                _c("input", {
                                  staticClass: "mdc-checkbox__native-control",
                                  attrs: {
                                    type: "checkbox",
                                    id: "checkbox-signup-accept-terms",
                                    autocomplete: "off",
                                    autocorrect: "off",
                                    spellcheck: "false"
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "mdc-checkbox__background" },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "mdc-checkbox__checkmark",
                                        attrs: { viewBox: "0 0 24 24" }
                                      },
                                      [
                                        _c("path", {
                                          staticClass:
                                            "mdc-checkbox__checkmark-path",
                                          attrs: {
                                            fill: "none",
                                            d:
                                              "M1.73,12.91 8.1,19.28 22.79,4.59"
                                          }
                                        })
                                      ]
                                    ),
                                    _c("div", {
                                      staticClass: "mdc-checkbox__mixedmark"
                                    })
                                  ]
                                )
                              ]),
                              _c(
                                "label",
                                {
                                  attrs: { for: "checkbox-signup-accept-terms" }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("signup.iAccept")))
                                  ]),
                                  _c("span", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.showTOS($event)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("signup.tos")))]
                                    ),
                                    _vm._v(_vm._s(_vm.$t("signup.and"))),
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.showPrivacy($event)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("signup.privacy")))]
                                    ),
                                    _vm._v(_vm._s(_vm.$t("signup.fullStop")))
                                  ])
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm.errors.length
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                            },
                            [
                              _c("p", { staticClass: "error-prompt" }, [
                                _c(
                                  "ul",
                                  _vm._l(_vm.errors, function(error) {
                                    return _c("li", { key: error }, [
                                      _vm._v(_vm._s(error))
                                    ])
                                  })
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
                        },
                        [
                          _c("div"),
                          _c(
                            "button",
                            {
                              staticClass: "mdc-fab mdc-fab--extended",
                              attrs: { type: "submit", "aria-label": "註冊" }
                            },
                            [
                              _c("span", { staticClass: "mdc-fab__label" }, [
                                _vm._v(_vm._s(_vm.$t("signup.signup")))
                              ]),
                              _c(
                                "svg",
                                {
                                  staticClass: "mdc-fab__icon",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewbox: "0 0 24 24"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _c("div")
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _c("Verification", { ref: "verificationDialog" }),
              _c("Terms", { ref: "terms" }),
              _c("Privacy", { ref: "privacy" })
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }