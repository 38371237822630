export const signup = {
    // Registration
    accountRegistration: '登録',
    companyName: '会社名',
    email: 'メールアドレス',
    password: 'パスワード',
    confirmPassword: 'パスワード確認',
    countryCode: '国番号',
    phone: '電話番号',
    iAccept: '同意して次へ',
    tos: ' Jarvix Pay 利用規約',
    and: ' と',
    privacy: ' プライバシーポリシー',
    fullStop: '。',
    signup: '作成',

    // Verification
    accountVerification: 'アカウント認証',
    verificationInputText: '認証コードを入力して下さい',

    // Company
    companyInfo: '会社情報',
    businessType: '会社種類',
    limited: '有限公司',
    unlimited: '無限公司',
    businessBR: '商業登記証 (BR)',
    businessCI: '会社設立証 (CI)',
    statementDescriptor: 'ご利用明細書店名',
    companyPhone: '会社電話番号',
    CompanyAddress: '会社所在地',
    city: '市',
    state: '国',
    businessDescription: '業務内容',
    businessWeb: '公式サイト',

    // Owner
    companyOwner: '会社オーナー',
    companyRemind: '注意: 25%の株式以上を有している会社オーナーの情報を記入しなければなりません',
    companyImportantOwner: '会社オーナー',
    owner: 'オーナー',
    lastName: '姓',
    firstName: '名',
    identityType: '本人確認書類',
    identityNumber: '本人確認書類番号',
    hkid: '香港IDカード',
    passport: 'パスポート',
    dob: '生年月日',
    ownerPhone: '携帯番号',
    address: '住所',
    addOwner: '+ オーナー追加',

    // ExternalAccount
    externalAccountInfo: '銀行口座設定',
    holderName: '口座名義',
    holderType: '口座種類',
    companyAccount: '法人口座',
    individualAccount: '一般口座',
    bank: '銀行名',
    branchCode: '支店コード',
    accountNumber: '口座番号',
    currency: '通貨',

    // DocumentUpload
    uploadDocument: '書類アップロード',
    remind: '注意:',
    remindItem: '* は必須項目です',
    remindDocs: '文件格式只接受 pdf / png / jpg’, ‘アップロードできる画像形式はpdf / png / jpgのみです',
    selectDocs: '書類選択',
    br: '商業登記証 (BR)',
    proof: ' の書類',
    idProof: '身份證明文件只接受圖片格式為png或jpg ‘, ‘アップロードできる画像形式はpng /jpgのみです',
    idFront: '本人確認書類 (表)',
    idBack: '本人確認書類 (裏)',
    addressProof: '住所証明書',
    bankProof: ' 銀行口座証明書',
    bankProofExample: '例えば銀行カード、取引明細書、小切手帳などです',
    otherInfo: 'その他の書類',
    errorBR: 'アップロードされた商業登記証 (BR)の画像形式がpdf / png / jpgではありません。',
    errorCI: 'アップロードされた会社設立証 (CI)の画像形式がpdf / png / jpgではありません',
    errorBank: 'アップロードされた銀行口座証明書の画像形式がpdf / png / jpgではありません',
    errorIdentityFront: 'アップロードされた本人確認書類 (表)の画像形式がpng / jpgではありません',
    errorIdentityBack: 'アップロードされた本人確認書類 (裏)の画像形式がpng / jpgではありません',
    errorAddress: 'アップロードされた住所証明書の画像形式がpdf / png / jpgではありません',
    errorOther: 'アップロードさ れたその他の書類の画像形式がpdf / png / jpgではありません',

    // Review
    preview: 'プレビュー',
    confirm: '確認して下さい。提 出した後は変更はできません',
    company: '会社',
    name: '名称',
    type: '種類',
    clientName: 'ご利用明細書店名',
    document: '書類',
    changeInfo: '情報変更',

    // Signature
    sign: 'サイン',
    remindTerms: 'すべての情報を入力し、サインして下さい',
    remindEmail: 'サインを提出した後はメールアドレスにコピーを送ります',
    contactEmail: 'メールアドレス',
    peopleName: '姓名',
    peopleTitle: '職名',
    gender: '性別',
    male: '男性',
    female: '女性',
    applicationForm: 'Jarvix Pay 申込書',
    partA: 'A. 会社情報',
    companySignAddress: '会社所在地',
    brNumber: '商業登記証番号',
    companyEmail: 'メールアドレス',
    partB: 'B. 申込者',
    idNumber: '本人確認書類番号',
    personalAddress: '住所',
    partC: 'C. 銀行口座情報',
    bankName: '銀行名',
    bankHolderName: '口座名義',
    partD: 'D. Jarvix Pay 決済手数料',
    visaMaster: 'Visa/MasterCard',
    cardIssuer: '(カード発行元が香港の場合)',
    rateFee: '決済手数料',
    tradeAmount: '決済金額の',
    eachTrade: '1件の決済ごとに香港ドル',
    remark: '備考欄',
    cashRebate: '払い戻し (決済金額の)',
    ae: 'American Express',
    unionpay: 'UnionPay',
    foreign: 'Visa/MasterCard/American Express/UnionPay',
    noneHKIssuer: 'カード発行元が香港ではないの場合',
    arrivalDay: '所要時間',
    requiredDocs: 'Jarvix Pay 申込書類:',
    brCopy: '商業登記証コピー',
    submitted: '提出した',
    ciCopy: '会社設立証コピー',
    limitedProvided: '有限公司のみ',
    bankDocs: '銀行口座証明書',
    remindBank: '口座名義と口座番号の表示が必要です',
    ownerIdCopy: '本人確認書類コピー',
    ownerAddressCopy: '住所証明書コピー',
    remindLegal: '私は、上記の通り相違ないことを証明します。',
    contract: '使用契約書',
    submitContract: '申込む',
    thanks: 'お申込いただき、ありがとうございます',
    pending: '現在、承認待ちの状態です。少々お待ちください',
    reSign: 'もう一度サイン',
    remindReSign: 'もう一度サ インした後は旧契約が無効になり、新契約が成立します。',

    //Signing
    signHereLabel: 'サインへ',
    signHere: 'サイン',

    // Rejected
    unfortunately: '残念ながら',
    unsuccessful: 'あなたのアカウントは審査に通りませんでした',
    contact_us: 'お問い合わせは下記の電話番号までお願い致します',
};
