



















































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Amount from '@/components/payment/Amount.vue';
import CreditCard from '@/components/payment/CreditCard.vue';
import NativeCreditCard from '@/components/payment/NativeCreditCard.vue';
import Summary from '@/components/payment/Summary.vue';
import QRPayment from '@/components/QRPayment.vue';
import utils from '@/utils';
import { isPlatform } from '@/utils/platform';
import { async } from 'rxjs/internal/scheduler/async';

const paymentSteps: ['amount', 'card', 'summary'] = ['amount', 'card', 'summary'];

@Component({
    components: {
        Amount,
        CreditCard,
        NativeCreditCard,
        Summary,
        QRPayment,
    },
})
export default class Payment extends Vue {
    private amountComponent!: Amount;
    private creditCardComponent!: CreditCard;
    private summaryComponent!: Summary;
    public display: 'amount' | 'card' | 'summary' = paymentSteps[0];
    public paymentMethod: 'card' | 'qrPayment' = 'card';
    public isRecurrent = false;
    private isDialogEnabled = true;

    get wechatEnabled() {
        return this.$store.state.user.wechat_enabled;
    }

    get alipayEnabled() {
        return this.$store.state.user.alipay_enabled;
    }

    get isCapacitorNative() {
        return isPlatform(window, 'capacitor');
    }

    get qrPaymentMethod() {
        return this.$store.state.qrPaymentMethod;
    }

    private onTabUpdated(index: number) {
        this.display = 'amount';

        if (index === 0) {
            this.paymentMethod = 'card';
            this.$root.$emit('switchToCard');
            this.$store.dispatch('logUserNavigation', { to: 'payment', from: 'payment.wechat' });
        }

        if (index === 1) {
            this.paymentMethod = 'qrPayment';
            this.$root.$emit('switchToQRPayment');
            this.$store.dispatch('logUserNavigation', { to: 'payment.wechat', from: 'payment' });
        }
    }

    private amountData = {
        amount: 0,
        currency: '',
        description: '',
    };
    private creditCardData = {};
    private customerData = {};

    get maskedAmount() {
        // return `$${utils.convertNumberToCurrency(this.chargeData.amount)}`;
        return this.chargeData.amount + '';
    }

    @Watch('display')
    onDisplayChanged() {
        utils.scrollTop();
    }

    get recurrentClass() {
        return {
            recurrent: this.isRecurrent,
        };
    }

    public mounted() {
        this.$root.$emit('reset');

        this.amountComponent = this.$refs.amount as Amount;
        this.creditCardComponent = this.$refs.creditCard as CreditCard;
        this.summaryComponent = this.$refs.summary as Summary;
        if (this.wechatEnabled || this.alipayEnabled) {
            this.isDialogEnabled = false;
        }

        this.$root.$on('resetPayment', () => {
            this.display = paymentSteps[0];
        });
    }

    get chargeData(): {
        amount: number;
        currency: string;
        description?: string;
        recurrent?: {
            duration: number;
            autoExtend: boolean;
        };
        token?: {
            id: string;
            type: string;
            card: {
                brand: string;
                exp_month: number;
                exp_year: number;
                last4: string;
                name: string;
            };
        };
        customer?: {
            name?: string;
            countryCode?: any;
            phone?: string;
            email?: string;
            address?: string;
            remarks?: string;
            refNo?: string;
        };
    } {
        return {
            ...Object(this.amountData),
            ...Object(this.creditCardData),
            ...Object(this.customerData),
        };
    }

    private back() {
        let s = paymentSteps;
        this.display = s[s.indexOf(this.display) - 1];
    }

    private next() {
        let s = paymentSteps;
        this.display = s[s.indexOf(this.display) + 1];
    }

    private pay() {
        if (this.isRecurrent) {
            if (!this.chargeData.customer) {
                return this.$root.$emit('error', { message: '客戶資料必須填寫。' });
            }

            return this.recurringCharge();
        }

        return this.directCharge();
    }

    protected directCharge() {
        let d = this.chargeData;

        if (!d.token) {
            return;
        }

        // console.log(d);

        return this.$store
            .dispatch('charge', {
                amount: d.amount,
                currency: d.currency,
                token: d.token.id,
                tokenizingMethod: this.isCapacitorNative ? 'source' : 'token',
                description: d.description,
            })
            .then((response: any) => {
                this.$router.push({ name: 'receipt', params: { chargeId: response.chargeId } });
                this.$root.$emit('resetPayment');
            })
            .catch((err: any) => {
                if (err.rawError.response || err.rawError.request) {
                    this.$root.$once('error-dismiss', () => {
                        this.$root.$emit('resetPayment');
                        try {
                            let chargeId = err.rawError.response.data.error.error_body.chargeId;
                            if (!chargeId) throw 0;
                            this.$router.push({ name: 'receipt', params: { chargeId } });
                        } catch (e) {
                            this.display = 'card';
                            // this.$router.push({ name: 'payment' });
                        }
                    });
                }
                // console.log(err);
                this.$root.$emit('error', err);
            });
    }

    protected recurringCharge() {
        if (!this.chargeData.token) {
            return;
        }

        const charge = {
            amount: this.chargeData.amount,
            currency: this.chargeData.currency,
            description: this.chargeData.description,
        };
        const customer = {
            name: this.chargeData.customer!.name,
            country: this.chargeData.customer!.countryCode!.en,
            countryCode: this.chargeData.customer!.countryCode!.phone_code,
            phone: this.chargeData.customer!.phone,
            email: this.chargeData.customer!.email,
            address: this.chargeData.customer!.address,
            remarks: this.chargeData.customer!.remarks,
            refNo: this.chargeData.customer!.refNo,
        };
        return this.$store
            .dispatch('recurringCharge', {
                customer: customer,
                charge: charge,
                recurrenceInfo: this.chargeData.recurrent,
                sourceToken: this.chargeData.token.id,
                tokenizingMethod: this.isCapacitorNative ? 'source' : 'token',
            })
            .then((response: any) => {
                this.$router.push({ name: 'recurrent.details', params: { id: response.recurrenceId } });
                this.$root.$emit('resetPayment');
            })
            .catch((err: any) => {
                if (err.rawError.response || err.rawError.request) {
                    this.$root.$once('error-dismiss', () => {
                        this.$root.$emit('resetPayment');
                        // this.$root.$once('route-change', () => this.$store.commit('show', 'amount'));
                        try {
                            let recurrenceId = err.rawError.response.data.error.recurrenceId;
                            let chargeId = err.rawError.response.data.error.chargeId;
                            this.$router.push({ name: 'recurrent.details', params: { id: recurrenceId } });
                        } catch (e) {
                            this.$router.push({ name: 'recurrents' });
                        }
                    });
                }
                this.$root.$emit('error', err);
            });
    }

    private labpay(payload: any) {
        const { decodedString: authCode, paymentMethod } = payload;
        let paymentType = '';
        if (paymentMethod === 'wechat') {
            paymentType = 'MICROPAY';
        } else if (paymentMethod === 'alipay') {
            paymentType = 'BARCODE';
        }

        return this.$store
            .dispatch('labpayCharge', {
                authCode: authCode,
                amount: this.chargeData.amount,
                currency: this.chargeData.currency,
                description: this.chargeData.description || '产品 / 服务 - Product / Service',
                paymentType: paymentType,
            })
            .then((response: any) => {
                this.$router.push({ name: 'chinese-payment-receipt', params: { chargeId: response.id } });
                this.$root.$emit('resetPayment');
            })
            .catch((err: any) => {
                this.$root.$once('error-dismiss', () => {
                    this.$root.$emit('resetPayment');

                    try {
                        let chargeId = err.chargeId;
                        this.$router.push({ name: 'chinese-payment-receipt', params: { chargeId: chargeId } });
                    } catch (e) {
                        this.$router.push({ name: 'payment' });
                    }
                });

                this.$root.$emit('error', err);
            });

        // return this.$store.dispatch('labpay')
    }
}
