var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "campaign-order-summary-item",
      on: {
        click: function($event) {
          _vm.$router.push({
            name: "campaign-order-information",
            params: { campaignId: _vm.campaignId, orderId: _vm.order.id }
          })
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass:
              "campaign-order-summary-item__amount mdc-typography--headline5"
          },
          [_vm._v(_vm._s(_vm._f("currency")(_vm.order.amount)))]
        ),
        _c(
          "div",
          { staticClass: "tags__group campaign-order-summary-item__status" },
          [
            _vm.order.amount === 0
              ? _c("div", { staticClass: "tags tags--succeeded filled" }, [
                  _vm._v(_vm._s(_vm.$t("status.free")))
                ])
              : _vm.order.refunded
                ? _c("div", { staticClass: "tags tags--refunded filled" }, [
                    _vm._v(_vm._s(_vm.$t("status.refunded")))
                  ])
                : _vm.order.paid
                  ? _c("div", { staticClass: "tags tags--succeeded filled" }, [
                      _vm._v(_vm._s(_vm.$t("status.succeeded")))
                    ])
                  : !_vm.order.paid
                    ? _c(
                        "div",
                        { staticClass: "tags tags--remote--pending filled" },
                        [_vm._v(_vm._s(_vm.$t("status.pending")))]
                      )
                    : _vm._e()
          ]
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "row-left-section" }, [
          _c("div", { staticClass: "mdc-typography--body2" }, [
            _vm._v(
              _vm._s(_vm.$t("customer.name")) +
                ": " +
                _vm._s(_vm.order.customer_name)
            )
          ]),
          _c("div", { staticClass: "mdc-typography--body2" }, [
            _vm._v(
              _vm._s(_vm.$t("customer.phone")) +
                ": " +
                _vm._s(_vm.order.customer_country_code) +
                _vm._s(_vm.order.customer_phone)
            )
          ]),
          _vm.order.receipt_no
            ? _c("div", { staticClass: "mdc-typography--body2" }, [
                _vm._v(
                  _vm._s(_vm.$t("receipt.number")) +
                    ": " +
                    _vm._s(_vm.order.receipt_no)
                )
              ])
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "row flex" }, [
        _c(
          "div",
          {
            staticClass:
              "campaign-order-summary-item__datetime mdc-typography--caption ml-auto"
          },
          [_vm._v(_vm._s(_vm.moment(_vm.order.created_at).format("LLL")))]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }