var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "campaign-product-item" }, [
    _c(
      "div",
      { staticClass: "list-item" },
      [
        _c(
          "div",
          { staticClass: "fields" },
          [
            _c("mdc-textfield", {
              attrs: {
                fullwidth: "",
                label: _vm.$t("campaign.question.label") + " " + _vm.index
              },
              model: {
                value: _vm.question_,
                callback: function($$v) {
                  _vm.question_ = $$v
                },
                expression: "question_"
              }
            })
          ],
          1
        ),
        _vm._l(_vm.options_, function(option, optionIndex) {
          return _c("div", { key: optionIndex, staticClass: "fields option" }, [
            _c("div", { staticClass: "circle" }),
            _c(
              "div",
              { staticClass: "option-input" },
              [
                _c(
                  "mdc-textfield",
                  {
                    attrs: {
                      label:
                        _vm.$t("campaign.question.option") +
                        " " +
                        (optionIndex + 1),
                      fullwidth: ""
                    },
                    model: {
                      value: _vm.options_[optionIndex],
                      callback: function($$v) {
                        _vm.$set(_vm.options_, optionIndex, $$v)
                      },
                      expression: "options_[optionIndex]"
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          slot: "trailing-icon",
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "16",
                          height: "16",
                          viewBox: "0 0 24 24"
                        },
                        on: {
                          click: function($event) {
                            _vm.deleteOption(optionIndex)
                          }
                        },
                        slot: "trailing-icon"
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3a.996.996 0 0 1-1.41 0L12 13.41 9.11 16.3a.996.996 0 1 1-1.41-1.41L10.59 12 7.7 9.11A.996.996 0 1 1 9.11 7.7L12 10.59l2.89-2.89a.996.996 0 1 1 1.41 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z",
                            opacity: "0.54"
                          }
                        })
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ])
        }),
        _c("div", { staticClass: "fields option" }, [
          _c("div", { staticClass: "circle" }),
          _c(
            "div",
            { staticClass: "add-button", on: { click: _vm.addOption } },
            [_vm._v(_vm._s(_vm.$t("campaign.question.newOption")))]
          )
        ]),
        _c(
          "div",
          { staticClass: "footer1" },
          [
            _c(
              "mdc-button",
              {
                staticClass: "red-button",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.deleteQuestion($event)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("actions.delete")))]
            )
          ],
          1
        ),
        _vm.isTestingMode
          ? _c(
              "button",
              {
                staticClass: "mdc-button",
                on: {
                  click: function($event) {
                    _vm.autofill(true)
                  }
                }
              },
              [_vm._v("Auto Fill")]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }