export const currency = {
    name: '貨幣',
    hkd: '港幣',
    usd: '美元',
    jpy: '日元',
    mop: '澳門元',
    gbp: '英鎊',
    cny: '人民幣',
    myr: '馬來西亞令吉',
    sgd: '新加坡元',
    thb: '泰銖',
};
