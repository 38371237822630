var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "app-page-activity mdc-layout-grid app-page-activity--active",
      attrs: { id: "merchant-logo-setting" }
    },
    [
      _c("h4", { staticClass: "heading" }, [
        _vm._v(_vm._s(_vm.$t("settings.merchant.logo.campaign")))
      ]),
      !_vm.hasLogo
        ? _c("p", { staticClass: "prompt" }, [
            _vm._v(_vm._s(_vm.$t("settings.merchant.uploadLogoWarning")))
          ])
        : _vm._e(),
      _c("div", { staticClass: "preview" }, [
        _c("input", {
          ref: "preview",
          staticClass: "file",
          attrs: {
            type: "file",
            autocomplete: "off",
            autocorrect: "off",
            spellcheck: "false"
          },
          on: {
            change: function($event) {
              _vm.previewFile($event)
            }
          }
        }),
        _vm.src
          ? _c("img", { staticClass: "center", attrs: { src: _vm.src } })
          : _vm._e(),
        _c("br")
      ]),
      _c("br"),
      !_vm.changed
        ? _c("div", { staticClass: "buttons__group" }, [
            _c(
              "button",
              {
                staticClass: "mdc-button mdc-button--upload",
                on: {
                  click: function($event) {
                    _vm.$refs.preview.click()
                  }
                }
              },
              [
                !_vm.src
                  ? _c("span", { staticClass: "mdc-button__text" }, [
                      _vm._v(_vm._s(_vm.$t("actions.upload")))
                    ])
                  : _vm._e(),
                _vm.src
                  ? _c("span", { staticClass: "mdc-button__text" }, [
                      _vm._v(_vm._s(_vm.$t("actions.change")))
                    ])
                  : _vm._e()
              ]
            )
          ])
        : _vm._e(),
      _vm.changed
        ? _c("div", { staticClass: "buttons__group center" }, [
            _c(
              "button",
              {
                staticClass: "mdc-button mdc-button--upload",
                on: { click: _vm.upload }
              },
              [
                _c("span", { staticClass: "mdc-button__text" }, [
                  _vm._v(_vm._s(_vm.$t("actions.upload")))
                ])
              ]
            ),
            _c(
              "button",
              {
                staticClass: "mdc-button mdc-button--cancel",
                on: {
                  click: function($event) {
                    _vm.cancel()
                  }
                }
              },
              [
                _c("span", { staticClass: "mdc-button__text" }, [
                  _vm._v(_vm._s(_vm.$t("actions.cancel")))
                ])
              ]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }