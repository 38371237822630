



















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IMaskComponent } from 'vue-imask';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import { currencyNameWithSymbol } from '../../utils/helpers/currencyHelper';

const env = process.env.VUE_APP_ENV;
@Component({
    filters: {
        currencySymbolDisplay: currencyNameWithSymbol,
    },
    components: {
        'imask-input': IMaskComponent,
    },
})
export default class RemoteCampaignProductItem extends Vue {
    private mdcTextFields!: any;

    @Prop() index!: number;

    @Prop() product!: CampaignProductData;

    @Prop() editingProductList!: boolean;

    @Prop() currency!: string;

    private productEditingInfo = { ...this.product };
    private editing = true;

    private discount: boolean = false;

    public mounted() {
        this.initMDCTextFields();
    }

    @Watch('discount')
    OnDiscountChanged(n: boolean, o: boolean) {
        this.productEditingInfo.originalPrice = null;
    }

    private edit() {
        if (this.editingProductList) {
            this.$root.$emit('error', { message: this.$t('errors.GENERAL.editing') });
            return;
        }

        this.productEditingInfo = { ...this.product };
        this.editing = true;
        this.$emit('edit', true);
    }

    private cancel() {
        this.editing = false;
        this.$emit('edit', false);
    }

    private save() {
        // console.log(this.productEditingInfo);
        if (!this.productEditingInfo.name || this.productEditingInfo.price == null || this.productEditingInfo.quantityPerOrder == null) {
            this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.empty_product') });
            return;
        }

        if (this.productEditingInfo.price < 0) {
            this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.invalid_price') });
            return;
        }

        if (this.discount == true) {
            if (!this.productEditingInfo.originalPrice) {
                this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.missing_original_price') });
                return;
            }

            if (this.productEditingInfo.originalPrice <= this.productEditingInfo.price) {
                this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.invalid_discount') });
                return;
            }
        }

        if (!this.productEditingInfo.quantity) {
            this.productEditingInfo.quantity = 999;
            (this.$refs.quantity as any).$el.dispatchEvent(new MouseEvent('focus'));
            (this.$refs.quantity as any).$el.dispatchEvent(new MouseEvent('blur'));
        }

        if (this.productEditingInfo.quantityPerOrder > this.productEditingInfo.quantity) {
            this.$root.$emit('error', { message: this.$t('errors.CAMPAIGN.invalid_quantity_per_order') });
            return;
        }

        this.editing = false;
        this.$emit('edit', false);
        this.$emit('save', this.productEditingInfo);
    }

    private deleteCustomer() {
        this.$emit('delete');
    }

    get hasData() {
        return this.product.name && this.product.price != null ? true : false;
    }

    get isTestingMode() {
        return env !== 'production' ? true : false;
    }

    public autofill(isAutoFill?: boolean) {
        let p = this.productEditingInfo;
        p.name = 'product';
        p.price = 1800;
        p.quantity = 100;
        p.quantityPerOrder = 1;
        p.originalPrice = 1980;
        p.discountMessage = 'discount is good';
    }

    private initMDCTextFields() {
        this.mdcTextFields = Array.from(this.$el.querySelectorAll('.mdc-text-field:not(.test)')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
