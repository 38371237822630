export const campaign = {
    title: 'Campaign',
    internalName: 'Internal Campaign Name',
    externalName: 'External Campaign Name',
    agentPromotion: 'Agent Promotion',
    period: 'Promotion Period',
    copywriting: 'Copywriting',
    content: 'Content',
    banners: 'Advertising Banner',
    details: 'Export Report',
    product: {
        title: 'Product List',
        label: 'Product Item',
        name: 'Name',
        price: 'Price',
        originalPrice: 'Original Price',
        customDiscountMessage: 'Custom Discount Message',
        quantity: 'Total Quantity',
        quantityPerOrder: 'Quantity Per Order',
        discount: 'Discount',
        remaining: 'Remaining',
    },
    link: 'Campaign Link',
    additionalOptions: {
        title: 'Additional Client Information',
        dateFormat: 'Date Format',
        timeFormat: 'Time Format',
        textFormat: 'Text Format',
        optionFormat: 'Option Format',
        mcFormat: 'MC Format',
        question: 'Custom Question',
        branchSelection: 'Branch Option',
    },
    question: {
        title: 'Custom Question',
        label: 'Question',
        option: 'Option',
        newOption: 'New Option',
        answer: 'Answer',
    },
    newProduct: 'New Product',
    newLink: 'New Link',
    newQuestion: 'New Question',
    newContact: 'New Contact',
    tnc: 'Terms and Conditions (T&C)',
    pasteTNC: 'Please paste Terms & Conditions',
    cta: 'Call To Action',
    currency: 'Currency',
    contact: {
        title: 'Contact Information',
        label: 'Contact',
        shopName: 'Shop Name',
        address: 'Address',
        number: 'Contact Number',
    },
    orderList: 'Orders',
    orderSummary: {
        totalTransaction: 'Total Transaction',
        totalOrder: 'Total Order',
        paid: 'Paid',
        latestTransaction: 'Latest Order Transaction',
        latestDate: 'Latest Order Date',
    },
    survey: 'Survey',
    cancelMsg: 'The campaign will be closed immediately after confirmation.',
    discountMsg: 'Limited offer will be expired on {0}',
    hitRate: 'Hit Rate',
};
