var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-page app-page-payment app-page--active" },
    [
      _c("div", { staticClass: "app-page-container" }, [
        _c("div", { staticClass: "review" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid app-page-activity app-page-activity--active bottom-nav--hidden"
            },
            [
              _c(
                "div",
                { staticClass: "mdc-layout-grid__inner" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6"
                    },
                    [
                      _vm.status == "re-signing"
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("signup.reSign")))])
                        : _c("span", [_vm._v(_vm._s(_vm.$t("signup.sign")))]),
                      !_vm.reviewing
                        ? _c(
                            "div",
                            { staticClass: "mdc-typography--subtitle1" },
                            [_vm._v(_vm._s(_vm.$t("signup.remind")))]
                          )
                        : _vm._e(),
                      !_vm.reviewing
                        ? _c(
                            "ol",
                            { staticClass: "mdc-typography--subtitle1" },
                            [
                              _vm.status == "re-signing"
                                ? _c("li", { staticClass: "reminder-item" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("signup.remindReSign"))
                                    )
                                  ])
                                : _vm._e(),
                              _c("li", { staticClass: "reminder-item" }, [
                                _vm._v(_vm._s(_vm.$t("signup.remindTerms")))
                              ]),
                              _c("li", { staticClass: "reminder-item" }, [
                                _vm._v(_vm._s(_vm.$t("signup.remindEmail")))
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  !_vm.reviewing
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.pdfEmail,
                                expression: "pdfEmail"
                              }
                            ],
                            staticClass: "mdc-text-field__input",
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              autocorrect: "off",
                              spellcheck: "false"
                            },
                            domProps: { value: _vm.pdfEmail },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.pdfEmail = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "mdc-floating-label",
                              class:
                                _vm.pdfEmail != ""
                                  ? "mdc-floating-label--float-above"
                                  : "",
                              attrs: { for: "pdfEmail" }
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.contactEmail")))]
                          ),
                          _c("div", { staticClass: "mdc-notched-outline" }, [
                            _c("svg", [
                              _c("path", {
                                staticClass: "mdc-notched-outline__path"
                              })
                            ])
                          ]),
                          _c("div", {
                            staticClass: "mdc-notched-outline__idle"
                          })
                        ]
                      )
                    : _vm._e(),
                  !_vm.reviewing
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.name,
                                expression: "name"
                              }
                            ],
                            staticClass: "mdc-text-field__input",
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              autocorrect: "off",
                              spellcheck: "false"
                            },
                            domProps: { value: _vm.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.name = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "mdc-floating-label",
                              class:
                                _vm.name != ""
                                  ? "mdc-floating-label--float-above"
                                  : "",
                              attrs: { for: "name" }
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.peopleName")))]
                          ),
                          _c("div", { staticClass: "mdc-notched-outline" }, [
                            _c("svg", [
                              _c("path", {
                                staticClass: "mdc-notched-outline__path"
                              })
                            ])
                          ]),
                          _c("div", {
                            staticClass: "mdc-notched-outline__idle"
                          })
                        ]
                      )
                    : _vm._e(),
                  !_vm.reviewing
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.title,
                                expression: "title"
                              }
                            ],
                            staticClass: "mdc-text-field__input",
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              autocorrect: "off",
                              spellcheck: "false"
                            },
                            domProps: { value: _vm.title },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.title = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "mdc-floating-label",
                              class:
                                _vm.title != ""
                                  ? "mdc-floating-label--float-above"
                                  : "",
                              attrs: { for: "title" }
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.peopleTitle")))]
                          ),
                          _c("div", { staticClass: "mdc-notched-outline" }, [
                            _c("svg", [
                              _c("path", {
                                staticClass: "mdc-notched-outline__path"
                              })
                            ])
                          ]),
                          _c("div", {
                            staticClass: "mdc-notched-outline__idle"
                          })
                        ]
                      )
                    : _vm._e(),
                  !_vm.reviewing
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                        },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.gender,
                                  expression: "gender"
                                }
                              ],
                              staticClass: "mdc-text-field__input",
                              class: { "select--hidden": !_vm.gender },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.gender = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { disabled: "" },
                                  domProps: { value: null }
                                },
                                [_vm._v(_vm._s(_vm.$t("signup.gender")))]
                              ),
                              _c("option", { attrs: { value: "male" } }, [
                                _vm._v(_vm._s(_vm.$t("signup.male")))
                              ]),
                              _c("option", { attrs: { value: "female" } }, [
                                _vm._v(_vm._s(_vm.$t("signup.female")))
                              ])
                            ]
                          ),
                          _c(
                            "label",
                            {
                              staticClass: "mdc-floating-label",
                              class:
                                _vm.gender != ""
                                  ? "mdc-floating-label--float-above"
                                  : "",
                              attrs: { for: "gender" }
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.gender")))]
                          ),
                          _c("div", { staticClass: "mdc-notched-outline" }, [
                            _c("svg", [
                              _c("path", {
                                staticClass: "mdc-notched-outline__path"
                              })
                            ])
                          ]),
                          _c("div", {
                            staticClass: "mdc-notched-outline__idle"
                          })
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet",
                      attrs: { flat: "" }
                    },
                    [
                      (_vm.pdfEmail != "" &&
                        _vm.name != "" &&
                        _vm.title != "" &&
                        _vm.gender != "") ||
                      _vm.reviewing
                        ? _c(
                            "v-expansion-panel",
                            {
                              attrs: { expand: "", dark: "" },
                              model: {
                                value: _vm.panel,
                                callback: function($$v) {
                                  _vm.panel = $$v
                                },
                                expression: "panel"
                              }
                            },
                            [
                              _c(
                                "v-expansion-panel-content",
                                {
                                  staticStyle: {
                                    "background-color": "#673ab7",
                                    color: "white",
                                    "border-radius": "10px",
                                    "min-height": "60px",
                                    border: "0px"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mdc-typography--headline6",
                                      staticStyle: { "font-weight": "300" },
                                      attrs: { slot: "header" },
                                      slot: "header"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.$t("signup.applicationForm")
                                          ) +
                                          "\n                                    "
                                      ),
                                      _vm.signApplication
                                        ? _c(
                                            "v-icon",
                                            { attrs: { color: "#69cb59" } },
                                            [_vm._v("check_circle")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card",
                                    {
                                      staticStyle: {
                                        "background-color": "#46287d",
                                        color: "white",
                                        "border-radius": "0px 0px 10px 10px"
                                      },
                                      attrs: { light: "" }
                                    },
                                    [
                                      _c("v-card-text", [
                                        _c("div", [
                                          _c("div", { staticClass: "part" }, [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("signup.partA"))
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "signup.companyName"
                                                      )
                                                    ) +
                                                      "： " +
                                                      _vm._s(
                                                        _vm.signUpData.company
                                                          .businessName
                                                      )
                                                  )
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "signup.companySignAddress"
                                                      )
                                                    ) +
                                                      "： " +
                                                      _vm._s(
                                                        _vm.signUpData.company
                                                          .address
                                                      )
                                                  )
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("signup.brNumber")
                                                    ) +
                                                      "： " +
                                                      _vm._s(
                                                        _vm.signUpData.company
                                                          .businessBR
                                                      )
                                                  )
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "signup.companyEmail"
                                                      )
                                                    ) +
                                                      "： " +
                                                      _vm._s(
                                                        _vm.signUpData.company
                                                          .email
                                                      )
                                                  )
                                                ])
                                              ]
                                            )
                                          ]),
                                          _c("br"),
                                          _c("div", { staticClass: "part" }, [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("signup.partB"))
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("signup.gender")
                                                    ) +
                                                      "： " +
                                                      _vm._s(
                                                        _vm.gender == "male"
                                                          ? _vm.$t(
                                                              "signup.male"
                                                            )
                                                          : _vm.gender ==
                                                            "female"
                                                            ? _vm.$t(
                                                                "signup.female"
                                                              )
                                                            : ""
                                                      )
                                                  )
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "signup.companyImportantOwner"
                                                      )
                                                    ) +
                                                      "： " +
                                                      _vm._s(
                                                        _vm.signUpData.owners[0]
                                                          .lastName +
                                                          " " +
                                                          _vm.signUpData
                                                            .owners[0].firstName
                                                      )
                                                  )
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("signup.idNumber")
                                                    ) +
                                                      "： " +
                                                      _vm._s(
                                                        _vm.signUpData.owners[0]
                                                          .identityNumber
                                                      )
                                                  )
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "signup.companyPhone"
                                                      )
                                                    ) +
                                                      "： " +
                                                      _vm._s(
                                                        _vm.signUpData.company
                                                          .phone
                                                      )
                                                  )
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "signup.ownerPhone"
                                                      )
                                                    ) +
                                                      "： " +
                                                      _vm._s(
                                                        _vm.signUpData.owners[0]
                                                          .phone
                                                      )
                                                  )
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "signup.personalAddress"
                                                      )
                                                    ) +
                                                      "： " +
                                                      _vm._s(
                                                        _vm.signUpData.owners[0]
                                                          .address
                                                      )
                                                  )
                                                ])
                                              ]
                                            )
                                          ]),
                                          _c("br"),
                                          _c("div", { staticClass: "part" }, [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("signup.partC"))
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("signup.bankName")
                                                    ) +
                                                      "： " +
                                                      _vm._s(
                                                        _vm.bankName(
                                                          _vm.signUpData
                                                            .externalAccount
                                                            .bank
                                                        )
                                                      )
                                                  )
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "signup.bankHolderName"
                                                      )
                                                    ) +
                                                      "： " +
                                                      _vm._s(
                                                        _vm.signUpData
                                                          .externalAccount
                                                          .holderName
                                                      )
                                                  )
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "signup.branchCode"
                                                      )
                                                    ) +
                                                      "： " +
                                                      _vm._s(
                                                        _vm.signUpData
                                                          .externalAccount
                                                          .branchCode
                                                      )
                                                  )
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "signup.accountNumber"
                                                      )
                                                    ) +
                                                      "： " +
                                                      _vm._s(
                                                        _vm.signUpData
                                                          .externalAccount
                                                          .accountNumber
                                                      )
                                                  )
                                                ])
                                              ]
                                            )
                                          ]),
                                          _c("br"),
                                          _c("div", { staticClass: "part" }, [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("signup.partD"))
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "item" },
                                                  [
                                                    _c("ul", [
                                                      _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "item-title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                                " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "signup.visaMaster"
                                                                )
                                                              ) +
                                                              "\n                                                                "
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "signup.cardIssuer"
                                                              )
                                                            ) +
                                                              "\n                                                            "
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _c("br"),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-inform"
                                                      },
                                                      [
                                                        _c(
                                                          "ul",
                                                          {
                                                            staticStyle: {
                                                              "list-style-type":
                                                                "square"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "item-inform-subtitle"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "signup.rateFee"
                                                                    )
                                                                  ) + ":"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-inform-value"
                                                          },
                                                          [
                                                            _vm.taxRate.rate
                                                              .visa_master != 0
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "\n                                                                    " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "signup.tradeAmount"
                                                                        )
                                                                      ) +
                                                                      "\n                                                                    "
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        color:
                                                                          "#90ff83"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .taxRate
                                                                            .rate
                                                                            .visa_master
                                                                        ) + "%"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " +\n                                                                    "
                                                                  ),
                                                                  _c("br")
                                                                ])
                                                              : _vm._e(),
                                                            _vm._v(
                                                              "\n                                                                " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "signup.eachTrade"
                                                                  )
                                                                ) +
                                                                "\n                                                                "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#90ff83"
                                                                }
                                                              },
                                                              [_vm._v("$2.35")]
                                                            )
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _c(
                                                          "ul",
                                                          {
                                                            staticStyle: {
                                                              "list-style-type":
                                                                "square"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "item-inform-subtitle"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "signup.remark"
                                                                    )
                                                                  ) + ":"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-inform-value"
                                                          },
                                                          [
                                                            _vm.taxRate
                                                              .rebate_rate
                                                              .visa_master != 0
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "\n                                                                    " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "signup.cashRebate"
                                                                        )
                                                                      ) +
                                                                      "\n                                                                    "
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        color:
                                                                          "#90ff83"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .taxRate
                                                                            .rebate_rate
                                                                            .visa_master
                                                                        ) + "%"
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v("N/A")
                                                                ])
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c("br"),
                                                _c(
                                                  "div",
                                                  { staticClass: "item" },
                                                  [
                                                    _c("ul", [
                                                      _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "item-title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                                " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "signup.ae"
                                                                )
                                                              ) +
                                                              "\n                                                                "
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "signup.cardIssuer"
                                                              )
                                                            ) +
                                                              "\n                                                            "
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _c("br"),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-inform"
                                                      },
                                                      [
                                                        _c(
                                                          "ul",
                                                          {
                                                            staticStyle: {
                                                              "list-style-type":
                                                                "square"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "item-inform-subtitle"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "signup.rateFee"
                                                                    )
                                                                  ) + ":"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-inform-value"
                                                          },
                                                          [
                                                            _vm.taxRate.rate
                                                              .ae != 0
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "\n                                                                    " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "signup.tradeAmount"
                                                                        )
                                                                      ) +
                                                                      "\n                                                                    "
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        color:
                                                                          "#90ff83"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .taxRate
                                                                            .rate
                                                                            .ae
                                                                        ) + "%"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " +\n                                                                    "
                                                                  ),
                                                                  _c("br")
                                                                ])
                                                              : _vm._e(),
                                                            _vm._v(
                                                              "\n                                                                " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "signup.eachTrade"
                                                                  )
                                                                ) +
                                                                "\n                                                                "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#90ff83"
                                                                }
                                                              },
                                                              [_vm._v("$2.35")]
                                                            )
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _c(
                                                          "ul",
                                                          {
                                                            staticStyle: {
                                                              "list-style-type":
                                                                "square"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "item-inform-subtitle"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "signup.remark"
                                                                    )
                                                                  ) + ":"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-inform-value"
                                                          },
                                                          [
                                                            _vm.taxRate
                                                              .rebate_rate.ae !=
                                                            0
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "\n                                                                    " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "signup.cashRebate"
                                                                        )
                                                                      ) +
                                                                      "\n                                                                    "
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        color:
                                                                          "#90ff83"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .taxRate
                                                                            .rebate_rate
                                                                            .ae
                                                                        ) + "%"
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v("N/A")
                                                                ])
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "item" },
                                                  [
                                                    _c("ul", [
                                                      _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "item-title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                                " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "signup.unionpay"
                                                                )
                                                              ) +
                                                              "\n                                                                "
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "signup.cardIssuer"
                                                              )
                                                            ) +
                                                              "\n                                                            "
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _c("br"),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-inform"
                                                      },
                                                      [
                                                        _c(
                                                          "ul",
                                                          {
                                                            staticStyle: {
                                                              "list-style-type":
                                                                "square"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "item-inform-subtitle"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "signup.rateFee"
                                                                    )
                                                                  ) + ":"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-inform-value"
                                                          },
                                                          [
                                                            _vm.taxRate.rate
                                                              .unionpay != 0
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "\n                                                                    " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "signup.tradeAmount"
                                                                        )
                                                                      ) +
                                                                      "\n                                                                    "
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        color:
                                                                          "#90ff83"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .taxRate
                                                                            .rate
                                                                            .unionpay
                                                                        ) + "%"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " +\n                                                                    "
                                                                  ),
                                                                  _c("br")
                                                                ])
                                                              : _vm._e(),
                                                            _vm._v(
                                                              "\n                                                                " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "signup.eachTrade"
                                                                  )
                                                                ) +
                                                                "\n                                                                "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#90ff83"
                                                                }
                                                              },
                                                              [_vm._v("$2.35")]
                                                            )
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _c(
                                                          "ul",
                                                          {
                                                            staticStyle: {
                                                              "list-style-type":
                                                                "square"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "item-inform-subtitle"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "signup.remark"
                                                                    )
                                                                  ) + ":"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-inform-value"
                                                          },
                                                          [
                                                            _vm.taxRate
                                                              .rebate_rate
                                                              .unionpay != 0
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "\n                                                                    " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "signup.cashRebate"
                                                                        )
                                                                      ) +
                                                                      "\n                                                                    "
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        color:
                                                                          "#90ff83"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .taxRate
                                                                            .rebate_rate
                                                                            .unionpay
                                                                        ) + "%"
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v("N/A")
                                                                ])
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c("br"),
                                                _c(
                                                  "div",
                                                  { staticClass: "item" },
                                                  [
                                                    _c("ul", [
                                                      _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "item-title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                                " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "signup.foreign"
                                                                )
                                                              ) +
                                                              "\n                                                                "
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "signup.noneHKIssuer"
                                                              )
                                                            ) +
                                                              "\n                                                            "
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _c("br"),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-inform"
                                                      },
                                                      [
                                                        _c(
                                                          "ul",
                                                          {
                                                            staticStyle: {
                                                              "list-style-type":
                                                                "square"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "item-inform-subtitle"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "signup.rateFee"
                                                                    )
                                                                  ) + ":"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-inform-value"
                                                          },
                                                          [
                                                            _vm.taxRate.rate
                                                              .foreign != 0
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "\n                                                                    " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "signup.tradeAmount"
                                                                        )
                                                                      ) +
                                                                      "\n                                                                    "
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        color:
                                                                          "#90ff83"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .taxRate
                                                                            .rate
                                                                            .foreign
                                                                        ) + "%"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " +\n                                                                    "
                                                                  ),
                                                                  _c("br")
                                                                ])
                                                              : _vm._e(),
                                                            _vm._v(
                                                              "\n                                                                " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "signup.eachTrade"
                                                                  )
                                                                ) +
                                                                "\n                                                                "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#90ff83"
                                                                }
                                                              },
                                                              [_vm._v("$2.35")]
                                                            )
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _c(
                                                          "ul",
                                                          {
                                                            staticStyle: {
                                                              "list-style-type":
                                                                "square"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "item-inform-subtitle"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "signup.remark"
                                                                    )
                                                                  ) + ":"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-inform-value"
                                                          },
                                                          [
                                                            _vm.taxRate
                                                              .rebate_rate
                                                              .foreign != 0
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "\n                                                                    " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "signup.cashRebate"
                                                                        )
                                                                      ) +
                                                                      "\n                                                                    "
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        color:
                                                                          "#90ff83"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .taxRate
                                                                            .rebate_rate
                                                                            .foreign
                                                                        ) + "%"
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v("N/A")
                                                                ])
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c("br"),
                                                _c(
                                                  "div",
                                                  { staticClass: "item" },
                                                  [
                                                    _c("ul", [
                                                      _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "item-title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "signup.arrivalDay"
                                                              )
                                                            ) + " T+4"
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          ]),
                                          _c("br"),
                                          _c("div", { staticClass: "part" }, [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "signup.requiredDocs"
                                                    )
                                                  ) + "："
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c("ol", [
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "item-inform-subtitle"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                            " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "signup.brCopy"
                                                            )
                                                          ) +
                                                          "\n                                                            "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#90ff83"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "（" +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "signup.submitted"
                                                                )
                                                              ) +
                                                              "）"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm.signUpData.company
                                                    .businessCI != ""
                                                    ? _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "item-inform-subtitle"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "signup.ciCopy"
                                                                )
                                                              ) +
                                                              "\n                                                            "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "#90ff83"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "（" +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "signup.submitted"
                                                                    )
                                                                  ) +
                                                                  "）"
                                                              )
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            "[" +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "signup.limitedProvided"
                                                                )
                                                              ) +
                                                              "]\n                                                        "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "item-inform-subtitle"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                            " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "signup.bankDocs"
                                                            )
                                                          ) +
                                                          "\n                                                            "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#90ff83"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "（" +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "signup.submitted"
                                                                )
                                                              ) +
                                                              "）"
                                                          )
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        "[" +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "signup.remindBank"
                                                            )
                                                          ) +
                                                          "]\n                                                        "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "item-inform-subtitle"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                            " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "signup.ownerIdCopy"
                                                            )
                                                          ) +
                                                          "\n                                                            "
                                                      ),
                                                      _c("br"),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#90ff83"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "（" +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "signup.submitted"
                                                                )
                                                              ) +
                                                              "）"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "item-inform-subtitle"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                            " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "signup.ownerAddressCopy"
                                                            )
                                                          ) +
                                                          "\n                                                            "
                                                      ),
                                                      _c("br"),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#90ff83"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "（" +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "signup.submitted"
                                                                )
                                                              ) +
                                                              "）"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("signup.remindLegal")
                                                )
                                              )
                                            ])
                                          ])
                                        ])
                                      ]),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          !_vm.reviewing
                                            ? _c("Signing", {
                                                on: {
                                                  save: _vm.saveSignApplication,
                                                  clean:
                                                    _vm.cleanSignApplication
                                                }
                                              })
                                            : _vm._e(),
                                          _c("v-spacer")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-expansion-panel-content",
                                {
                                  staticStyle: {
                                    "background-color": "#673ab7",
                                    color: "white",
                                    "border-radius": "10px",
                                    "min-height": "60px",
                                    border: "0px"
                                  },
                                  attrs: { dark: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mdc-typography--headline6",
                                      staticStyle: { "font-weight": "300" },
                                      attrs: { slot: "header" },
                                      slot: "header"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.$t("signup.contract")) +
                                          "\n                                    "
                                      ),
                                      _vm.signContract
                                        ? _c(
                                            "v-icon",
                                            { attrs: { color: "#69cb59" } },
                                            [_vm._v("check_circle")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card",
                                    {
                                      staticStyle: {
                                        "background-color": "#46287d",
                                        color: "white",
                                        "border-radius": "0px 0px 10px 10px"
                                      },
                                      attrs: { light: "" }
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        {
                                          staticStyle: {
                                            "text-align": "justify"
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _c("p", [_vm._v("BETWEEN")]),
                                            _c("p", { staticClass: "inform" }, [
                                              _vm._v("(A) JARVIX PAY LIMITED")
                                            ]),
                                            _c("p", [_vm._v("AND")]),
                                            _c("p", { staticClass: "inform" }, [
                                              _vm._v(
                                                "(B) " +
                                                  _vm._s(
                                                    _vm.signUpData.company
                                                      .businessName
                                                  )
                                              )
                                            ]),
                                            _c("p", [
                                              _vm._v(
                                                'This Jarvix Pay User Agreement ("Agreement") is an agreement between Jarvix Pay Limited and ' +
                                                  _vm._s(
                                                    _vm.signUpData.company
                                                      .businessName
                                                  ) +
                                                  ' and governs your use of all services of Jarvix Pay Limited ("Jarvix Pay"). Using the Service means that you must accept all of the terms and conditions contained in this Agreement and the Terms of Service on the legal agreements page of the Jarvix Pay platform. You should read all of these terms carefully. All capitalized terms shall have the definitions provided in this Agreement, or if a definition is not provided in this Agreement, the definition in the Terms of Service shall apply. You must read, agree with and accept all of the terms and conditions contained in this Agreement. We may amend this Agreement at any time by posting a revised version on our website. The revised version will be effective at the time we post it. In addition, if the revised version includes a Substantial Change, we will provide you with 30-day\'s prior notice of Substantial Change by posting notice on our website.'
                                              )
                                            ]),
                                            _c("br"),
                                            _c("br"),
                                            _c(
                                              "ol",
                                              { staticClass: "inform" },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "item-inform-subtitle title"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        "DEFINITIONS AND INTERPRETATIONS"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "Unless otherwise defined herein, in this Agreement, the terms below shall have their meanings as follows:"
                                                  )
                                                ]),
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "order-alphabet"
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v('"JARVIX"')
                                                        ]),
                                                        _vm._v(
                                                          ",\n                                                        "
                                                        ),
                                                        _c("strong", [
                                                          _vm._v('"we"')
                                                        ]),
                                                        _vm._v(
                                                          ",\n                                                        "
                                                        ),
                                                        _c("strong", [
                                                          _vm._v('"us"')
                                                        ]),
                                                        _vm._v(
                                                          " or\n                                                        "
                                                        ),
                                                        _c("strong", [
                                                          _vm._v('"our"')
                                                        ]),
                                                        _vm._v(
                                                          " means Jarvix Pay Limited.\n                                                    "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v('"Merchant"')
                                                        ]),
                                                        _vm._v(
                                                          ",\n                                                        "
                                                        ),
                                                        _c("strong", [
                                                          _vm._v('"you"')
                                                        ]),
                                                        _vm._v(
                                                          " or\n                                                        "
                                                        ),
                                                        _c("strong", [
                                                          _vm._v('"your"')
                                                        ]),
                                                        _vm._v(
                                                          "\n                                                        means " +
                                                            _vm._s(
                                                              _vm.signUpData
                                                                .company
                                                                .businessName
                                                            ) +
                                                            ".\n                                                    "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v('"Customer"')
                                                        ]),
                                                        _vm._v(
                                                          " means the persons transferring payment to Merchant's Account.\n                                                    "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v('"Account"')
                                                        ]),
                                                        _vm._v(
                                                          " means the account(s) in acquiring institutes(s) created and managed by JARVIX and owned by Merchant that stores payment received by Merchant from Customer.\n                                                    "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v('"Service"')
                                                        ]),
                                                        _vm._v(
                                                          " means JARVIX's provision of a proprietary platform that allows Merchant to receive payment and have those amounts automatically transferred from the Account to Merchant's linked Hong Kong bank account. All payments in the Account are subject to a 7-day hold period.\n                                                    "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v('"Affiliates"')
                                                        ]),
                                                        _vm._v(
                                                          ' means any other Person which, directly or indirectly, controls or is controlled by or is under common control with such Person, and for the purposes of this definition, "control" (including correlative meanings of the terms "controlled by" and "under common control with") means the power to direct or cause the direction of the management and policies of any Person, whether through the ownership of shares or by contract or otherwise.\n                                                    '
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            '"Transaction Amount"'
                                                          )
                                                        ]),
                                                        _vm._v(
                                                          " means total transaction amount excluding any chargeback, reversal, claim or sum that is otherwise invalidated.\n                                                    "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Words importing the singular shall include the plural and vice versa."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          'Where any provision contains the expression "and/or" then this shall mean that the relevant provision may apply to either or both or all or any of the matters that such expression shall connect.'
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Headings are inserted for ease of reference only and, save where referred to otherwise, shall not form part of the terms of the Agreement."
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _c(
                                              "ol",
                                              {
                                                staticClass: "inform",
                                                attrs: { start: "2" }
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "item-inform-subtitle title"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v("POWER OF JARVIX")
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "JARVIX reserves the following rights:"
                                                  )
                                                ]),
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "order-alphabet"
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "To terminate, suspend or limit Merchant's access to the Account or the access to the Service, and/or access to the funds in the Account if you are found to be in violation of this Agreement or any other agreement you enter with JARVIX."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "To temporarily hold the funds in your Account as specified in this Agreement."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "To hold the Service temporarily unavailable for scheduled maintenance or unscheduled emergency maintenance, either by JARVIX or third-party providers."
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _c(
                                              "ol",
                                              {
                                                staticClass: "inform",
                                                attrs: { start: "3" }
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "item-inform-subtitle title"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v("ELIGIBILITY")
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "order-alphabet"
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Provide the followings for registration:"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "ul",
                                                      { staticClass: "inform" },
                                                      [
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "item-inform-subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Merchant’s Business Registration Certificate"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "item-inform-subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Merchant’s Certificate of Incorporate"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "item-inform-subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Hong Kong ID Card and Proof of Address of Merchant’s duly authorised officer"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "item-inform-subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Proof of Hong Kong bank account linked to the Account."
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "In order to receive payments with the Account, you must link your Hong Kong bank account to your Account."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Complete the bank account verification process."
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _c(
                                              "ol",
                                              {
                                                staticClass: "inform",
                                                attrs: { start: "4" }
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "item-inform-subtitle title"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        "AUTOMATIC TRANSFER"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "order-alphabet"
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "JARVIX will automatically transfer the amounts from your Account to your linked Hong Kong bank account on a regular basis."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "JARVIX does not charge the Merchant for automatic transfers."
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _c(
                                              "ol",
                                              {
                                                staticClass: "inform",
                                                attrs: { start: "5" }
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "item-inform-subtitle title"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        "FINANCIAL INSTRUMENTS - LINKED HONG KONG BANK ACCOUNTS"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "order-alphabet"
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "If JARVIX is unable to transfer the amounts to your Hong Kong bank account due to any reasons, you must connect your Account to a new Hong Kong bank account within the time limit provided by us. Failure to do so may lead to a permanent loss of the funds stored in the Account."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "JARVIX has the sole and final discretion to decide whether to accept the new bank account provided by the Merchant. JARVIX shall provide reasons upon such denial."
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _c(
                                              "ol",
                                              {
                                                staticClass: "inform",
                                                attrs: { start: "6" }
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "item-inform-subtitle title"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v("TRANSACTION FEES")
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c(
                                                  "table",
                                                  {
                                                    staticStyle: {
                                                      "border-spacing": "10px",
                                                      "text-align": "left"
                                                    },
                                                    attrs: { width: "100%" }
                                                  },
                                                  [
                                                    _c("tbody", [
                                                      _c("tr", [
                                                        _c("td", [
                                                          _c("p", [
                                                            _c("strong", [
                                                              _vm._v("Item")
                                                            ])
                                                          ])
                                                        ]),
                                                        _c("td", [
                                                          _c("p", [
                                                            _c("strong", [
                                                              _vm._v("Charge")
                                                            ])
                                                          ])
                                                        ]),
                                                        _c("td", [
                                                          _c("p", [
                                                            _c("strong", [
                                                              _vm._v("Remarks")
                                                            ])
                                                          ])
                                                        ])
                                                      ]),
                                                      _c("tr", [
                                                        _c("td", [
                                                          _c("p", [
                                                            _vm._v(
                                                              "Transaction Fee for the transactions of Visa or MasterCard issued in Hong Kong"
                                                            )
                                                          ])
                                                        ]),
                                                        _c("td", [
                                                          _c("p", [
                                                            _vm.taxRate.rate
                                                              .visa_master != 0
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      color:
                                                                        "#90ff83"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .taxRate
                                                                          .rate
                                                                          .visa_master
                                                                      ) + "% +"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#90ff83"
                                                                }
                                                              },
                                                              [_vm._v("$2.35")]
                                                            ),
                                                            _vm._v(
                                                              " per transaction\n                                                                "
                                                            )
                                                          ])
                                                        ]),
                                                        _c("td", [
                                                          _vm.taxRate
                                                            .rebate_rate
                                                            .visa_master != 0
                                                            ? _c("p", [
                                                                _vm._v(
                                                                  "\n                                                                    Rebate\n                                                                    "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      color:
                                                                        "#90ff83"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .taxRate
                                                                          .rebate_rate
                                                                          .visa_master
                                                                      ) + "%"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " of transaction amount\n                                                                "
                                                                )
                                                              ])
                                                            : _c("p", [
                                                                _vm._v("N/A")
                                                              ])
                                                        ])
                                                      ]),
                                                      _c("tr", [
                                                        _c("td", [
                                                          _c("p", [
                                                            _vm._v(
                                                              "Transaction Fee for the transactions of American Express issued in Hong Kong"
                                                            )
                                                          ])
                                                        ]),
                                                        _c("td", [
                                                          _c("p", [
                                                            _vm.taxRate.rate
                                                              .ae != 0
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      color:
                                                                        "#90ff83"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .taxRate
                                                                          .rate
                                                                          .ae
                                                                      ) + "% +"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#90ff83"
                                                                }
                                                              },
                                                              [_vm._v("$2.35")]
                                                            ),
                                                            _vm._v(
                                                              " per transaction\n                                                                "
                                                            )
                                                          ])
                                                        ]),
                                                        _c("td", [
                                                          _vm.taxRate
                                                            .rebate_rate.ae != 0
                                                            ? _c("p", [
                                                                _vm._v(
                                                                  "\n                                                                    Rebate\n                                                                    "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      color:
                                                                        "#90ff83"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .taxRate
                                                                          .rebate_rate
                                                                          .ae
                                                                      ) + "%"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " of transaction amount\n                                                                "
                                                                )
                                                              ])
                                                            : _c("p", [
                                                                _vm._v("N/A")
                                                              ])
                                                        ])
                                                      ]),
                                                      _c("tr", [
                                                        _c("td", [
                                                          _c("p", [
                                                            _vm._v(
                                                              "Transaction Fee for the transactions of UnionPay issued in Hong Kong"
                                                            )
                                                          ])
                                                        ]),
                                                        _c("td", [
                                                          _c("p", [
                                                            _vm.taxRate.rate
                                                              .unionpay != 0
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      color:
                                                                        "#90ff83"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .taxRate
                                                                          .rate
                                                                          .unionpay
                                                                      ) + "% +"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#90ff83"
                                                                }
                                                              },
                                                              [_vm._v("$2.35")]
                                                            ),
                                                            _vm._v(
                                                              " per transaction\n                                                                "
                                                            )
                                                          ])
                                                        ]),
                                                        _c("td", [
                                                          _vm.taxRate
                                                            .rebate_rate
                                                            .unionpay != 0
                                                            ? _c("p", [
                                                                _vm._v(
                                                                  "\n                                                                    Rebate\n                                                                    "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      color:
                                                                        "#90ff83"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .taxRate
                                                                          .rebate_rate
                                                                          .unionpay
                                                                      ) + "%"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " of transaction amount\n                                                                "
                                                                )
                                                              ])
                                                            : _c("p", [
                                                                _vm._v("N/A")
                                                              ])
                                                        ])
                                                      ]),
                                                      _c("tr", [
                                                        _c("td", [
                                                          _c("p", [
                                                            _vm._v(
                                                              "Transaction Fee for the transactions of Visa, MasterCard, American Express or UnionPay issued outside of Hong Kong"
                                                            )
                                                          ])
                                                        ]),
                                                        _c("td", [
                                                          _c("p", [
                                                            _vm.taxRate.rate
                                                              .foreign
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      color:
                                                                        "#90ff83"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .taxRate
                                                                          .rate
                                                                          .foreign
                                                                      ) + "% +"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#90ff83"
                                                                }
                                                              },
                                                              [_vm._v("$2.35")]
                                                            ),
                                                            _vm._v(
                                                              " per transaction\n                                                                "
                                                            )
                                                          ])
                                                        ]),
                                                        _c("td", [
                                                          _vm.taxRate
                                                            .rebate_rate
                                                            .foreign != 0
                                                            ? _c("p", [
                                                                _vm._v(
                                                                  "\n                                                                    Rebate\n                                                                    "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      color:
                                                                        "#90ff83"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .taxRate
                                                                          .rebate_rate
                                                                          .foreign
                                                                      ) + "%"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " of transaction amount\n                                                                "
                                                                )
                                                              ])
                                                            : _c("p", [
                                                                _vm._v("N/A")
                                                              ])
                                                        ])
                                                      ]),
                                                      _c("tr", [
                                                        _c("td", [
                                                          _c("p", [
                                                            _vm._v(
                                                              "Transaction Fee for the transactions of Alipay and WeChat Pay"
                                                            )
                                                          ])
                                                        ]),
                                                        _c("td", [
                                                          _vm.taxRate.rate
                                                            .wechat_ali != 0
                                                            ? _c("p", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      color:
                                                                        "#90ff83"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .taxRate
                                                                          .rate
                                                                          .wechat_ali
                                                                      ) + "%"
                                                                    )
                                                                  ]
                                                                )
                                                              ])
                                                            : _c("p", [
                                                                _vm._v("N/A")
                                                              ])
                                                        ]),
                                                        _c("td", [
                                                          _vm.taxRate
                                                            .rebate_rate
                                                            .wechat_ali != 0
                                                            ? _c("p", [
                                                                _vm._v(
                                                                  "\n                                                                    Rebate\n                                                                    "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      color:
                                                                        "#90ff83"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .taxRate
                                                                          .rebate_rate
                                                                          .wechat_ali
                                                                      ) + "%"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " of transaction amount\n                                                                "
                                                                )
                                                              ])
                                                            : _c("p", [
                                                                _vm._v("N/A")
                                                              ])
                                                        ])
                                                      ])
                                                    ])
                                                  ]
                                                ),
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "order-alphabet"
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Corresponding Transaction Fees will be collected in the above applicable payment scenario."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "All Transaction Fees will be collected in Hong Kong Dollars."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Transaction Fee is applicable to all amounts you received with the Account, including amounts that are placed in a pending balance. For the purpose of clearer explanation, Transaction Fee will still be collected if the amount is refunded to your Customer."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Transaction Fee is collected by the acquiring institution(s), application fee charged by JARVIX is included in Transaction Fee."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "The Transaction Fee applies to all amounts you receive into your Jarvix Pay connect account for commercial transactions, including amounts that are placed in a pending balance. The Transaction Fee is a percentage of the transaction plus a fixed amount based on the currency of the payment you receive."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "A Foreign Exchange Fee is applied where the presentment currency (the currency paid by the customer) is different from the payout currency. Payment processor(s) will charge a Foreign Exchange Fee equal to one percent of Transaction Amount (as converted into the payout currency) on a per Transaction basis."
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _c(
                                              "ol",
                                              {
                                                staticClass: "inform",
                                                attrs: { start: "7" }
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "item-inform-subtitle title"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        "ACQUIRING INSTITUTION"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "order-alphabet"
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Acquiring Institution(s) are in assistance to the rendering of JARVIX’s Service whereby JARVIX has the sole and final discretion in beginning, alternating and terminating its agreement with any acquiring institution. Information about acquiring institution(s) and the terms and conditions of them are listed on the legal agreements page of the Jarvix Pay platform."
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _c(
                                              "ol",
                                              {
                                                staticClass: "inform",
                                                attrs: { start: "8" }
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "item-inform-subtitle"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v("RESERVE POLICY")
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "order-alphabet"
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          'JARVIX may place a reserve ("Reserve") on your Account if the transaction volume of your Account exceeded HK$500,000 and/or a high risk is believed to be associated with the Merchant, the Account, the linked bank account and/or the transactions made with the Account.'
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "The placement of the Reserve is at the sole discretion of JARVIX. JARVIX shall provide reasons for the placement of any Reserve. Factors considered including but not limited to the followings:"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "ul",
                                                      { staticClass: "inform" },
                                                      [
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "item-inform-subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "How long the Merchant has been in business"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "item-inform-subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Length of the subscription of the Service"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "item-inform-subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Merchant's payment processing history with JARVIX and other associated service providers"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "item-inform-subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Merchant's commercial credit history and/or legal business owner's personal credit history"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "item-inform-subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Merchant's delivery time frames"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "item-inform-subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Likelihood of chargebacks in Merchant's business and/or industry"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "item-inform-subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Frequency of returns, chargebacks, claims and/or disputes associated with the Account"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          'A Reserve can be placed anytime during the subscription of the Service. Upon the placement of Reserve, the funds will appear as "Pending". Merchant will be notified of the terms of the Reserve.'
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Placement of Reserves including but not limited to the followings:"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "ul",
                                                      { staticClass: "inform" },
                                                      [
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "item-inform-subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Rolling Reserve: A percentage of each transaction received with the Account is being held, released later on a scheduled basis."
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "item-inform-subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Minimum Reserve: A specific minimum amount is required to keep available in the Account at all times. The minimum reserve is either taken as an upfront amount deposited all at once or is established on a rolling basis from percentages of sales until the minimum reserve is achieved."
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "JARVIX will periodically review the reserved account every twenty eight (28) days to adjust, terminate or suspend the Reserve."
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _c(
                                              "ol",
                                              {
                                                staticClass: "inform",
                                                attrs: { start: "9" }
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "item-inform-subtitle title"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        "REFUNDS, REVERSALS AND CHARGEBACKS"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "order-alphabet"
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "When you receive payments from your Customer which are later refunded or invalidated, you are responsible for the full amount of the payment received by you plus any fees (including any applicable chargeback fee)."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Whenever a transaction is refunded or otherwise reversed, JARVIX will refund or reverse the fund from your Account in the same currency as the original transaction to the Customer."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "If a payment is refunded, JARVIX may retain the Transaction Fee you paid as user of the Service. Customer's credit card will be credited with the amount paid in full. Upon retainment of the relevant Transaction Fee, it will be deducted from your Account in addition to the refunded amount."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "To the extent permitted by law, JARVIX may collect any obligations you owe us under this Agreement by requesting that the acquiring institution deduct the corresponding amounts from the Account (as such term is defined in the processor terms) or from funds payable to you arising from the settlement of card transactions, and remit such amounts to us on your behalf. If these amounts are not sufficient to meet your obligations to us, we may charge or debit your bank account or credit card associated with your Account for any amounts owed to us. Your failure to fully pay amounts that you owe us on demand will be a breach of this Agreement. You will be liable for our costs associated with collection in addition to the amount owed, including without limitation attorneys' fees and expenses, costs of any arbitration or court proceeding, collection agency fees, and any applicable interest."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Merchant and the directors and significant controllers of Merchant should assume sole and exclusive responsibility for transactions under the payment processor terms, verification of the identity of Customer and of the eligibility of a presented credit card used by your Customer for purchasing your product and/or service. JARVIX does not guarantee or assume any liability for transactions authorised and completed which may later be refunded, reversed or charged back. Merchant and the directors and significant controllers of Merchant are solely responsible for all refunded, reversed or charged back transactions, regardless of the nature of, reason for and/or time of the refund, reversal or chargeback."
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _c(
                                              "ol",
                                              {
                                                staticClass: "inform",
                                                attrs: { start: "10" }
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "item-inform-subtitle title"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        "SCOPE OF LIABILITY"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "order-alphabet"
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          'The duly authorised officer of the Merchant ("Authorised Officer") shall be responsible and liable as well as the Merchant, for all moneys, debts and liabilities incurred by and owing and/or payable from Merchant in respect of the Responsible Sum to JARVIX or any JARVIX Entities, whether actual or contingent, past, present or future, or as principal or surety, at any time and from time to time. For the avoidance of doubt, this guarantee shall exist for so long as there is any Responsible Sum.'
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"Responsible Sum" means all or any money and liabilities which shall from time to time (and whether on or at any time after demand) be due, owing or incurred and/or payable in whatsoever manner to JARVIX or any JARVIX Entity by the Merchant in relation to the use of the Service, including but not limited to the Transaction Fee, Chargeback Amount, Reversed Amount, Disputed Amount, Dispute handling fee, administrative expenses and any applicable fee in relation to the use of Service.'
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "The Authorised Officer will be required to pay, on demand by JARVIX and/or any JARVIX Entity all Responsible Sum. By ways of examples and without limitation, the Authorised Officer may be called upon to pay under this Agreement if the Merchant is unable or admit inability to settle the Responsible Sum as they become due or in the event of any proceeding in or analogous to bankruptcy, insolvency, winding up or liquidation against the Merchant. The Authorised Officer's maximum liability under this Agreement shall be the total sum of all historical transactions."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "The Authorised Officer will also be assumed the full responsibility for administrative and legal events, including but not limited to the submission of invoice, credit card receipt and contract signing, in compliance to the specified method and time requested by JARVIX. The delay or failure to do so will be deemed as a breach of this Agreement."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "In any event where the Authorised Officer and/or the Merchant fails to fulfil the obligations, JARVIX may initiate commercially reasonable and legitimate actions, including but not limited to legal proceedings in against either one or both in retrieving the applicable funds and/or necessary information. JARVIX also reserves the right to authorise any individual or entity to exercise this right."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "JARVIX and/or any JARVIX Entities reserve the right to claim any direct and/or indirect loss from the Authorised Officer and/or the Merchant including but not limited to reputation loss, expectation loss, expenditure loss, loss of chance arising from any chargeback, reversal, chargeback and dispute regarding Merchant's use of Service and failure to fulfil its obligations."
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _c(
                                              "ol",
                                              {
                                                staticClass: "inform",
                                                attrs: { start: "11" }
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "item-inform-subtitle title"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        "TERMINATE THE USE OF SERVICE"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "order-alphabet"
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "You may terminate the use of the Service at any time after seeking JARVIX's approval. Upon the closure of your Account, all pending transactions will be cancelled, unless otherwise legally prohibited. All funds must be withdrawn by the Merchant prior to the termination of the use of the Service."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "The Account shall not be closed to evade any investigation. Should you seek to close your Account while an investigation is being conducted, JARVIX reserves the right to hold your funds to protect ourselves, our Affiliates and/or any third party against the risk of reversals, chargebacks, claims, fees, fines, penalties and any other liability. You will remain liable for all obligations associated to your Account and/or your use of Service even upon closure of the Account."
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _c(
                                              "ol",
                                              {
                                                staticClass: "inform",
                                                attrs: { start: "12" }
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "item-inform-subtitle title"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        "GENERAL PROVISIONS"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "order-alphabet"
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "If any provision of this Agreement is found to be unenforceable or invalid, the provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect, and enforceable. The failure of JARVIX to exercise or enforce any right or provision of this Agreement shall not be a waiver of that right."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "You hereby explicitly agree that all communication in relation to your Accounts will be made by electronic mail or by phone, as provided to JARVIX by you. Such communication may be made by JARVIX or by anyone on our behalf, including but not limited to a third party collection agent."
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          'Merchant, and the directors and significant controllers of Merchant agree to indemnify and defend JARVIX, our group companies, our affiliates, and their respective employees, agents and service providers (each, a "JARVIX Entity") against any claim, suit, demand, loss, liability, damage, action and/or proceeding (each, a "Claim") brought by a third pay against any JARVIX Entity. Merchant, and the directors and significant controllers of Merchant agree to fully reimburse the JARVIX entities for any Claims resulting from: (i) your breach of any provision of this Agreement; (ii) any Fees, fines, penalties, Disputes, Reversals, Returns, chargebacks (as such terms are defined in the processor Terms) or any other liability we incur that results from your use of the Services; (iii) negligent or willful misconduct of your owners, employees, contractors, or agents; (iv) contractual or other relationships between you and your Customer; or (v) third party indemnity obligations we incur as a direct or indirect result of your acts or omissions, including but not limited to indemnification of processor or any payment network.'
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _c(
                                              "ol",
                                              {
                                                staticClass: "inform",
                                                attrs: { start: "13" }
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "item-inform-subtitle title"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        "GOVERNING LAW AND JURISDICTION"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "inform" },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "order-alphabet"
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          'This Agreement shall be deemed to have been executed in Hong Kong Special Administrative Region of the People\'s Republic of China ("Hong Kong") and will be governed by and construed in accordance with the laws of Hong Kong.'
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "item-inform-subtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          'Any dispute, controversy, difference or claim arising out of or relating to the Agreement including the existence, validity, interpretation, performance, breach or termination thereof or any dispute regarding non-contractual obligations arising out of or relating to it shall be referred to and finally resolved by arbitration administered by the Hong Kong International Arbitration Centre (HKIAC( under the HKIAC Administered Arbitration Rules (the "Rules") in force when the notice of arbitration is submitted. The seat of the arbitration shall be in Hong Kong. The number of arbitrators shall be three (3), one (1) being appointed by JARVIX, one (1) appointed by the Merchant and one (1) appointed by the HKIAC in accordance with the applicable Rules. The arbitration proceedings shall be conducted in English.'
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _c("p", [
                                              _vm._v(
                                                "By signing below, both parties confirm and agree that JPUA shall be valid and effective."
                                              )
                                            ]),
                                            _c("p", [
                                              _vm._v(
                                                "IN WITNESS WHEREOF, the parties hereto have caused this Agreement be executed by their respective duly authorized officers."
                                              )
                                            ]),
                                            _c(
                                              "table",
                                              {
                                                staticStyle: {
                                                  "text-align": "left"
                                                },
                                                attrs: { width: "100%" }
                                              },
                                              [
                                                _c("tbody", [
                                                  _c("tr", [
                                                    _c("td", [
                                                      _c("p", [
                                                        _vm._v(
                                                          "For and on behalf of"
                                                        )
                                                      ]),
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.signUpData
                                                              .company
                                                              .businessName
                                                          )
                                                        )
                                                      ]),
                                                      _c("p", [
                                                        _vm._v(
                                                          "Authorized Signature:"
                                                        )
                                                      ])
                                                    ]),
                                                    _c("td")
                                                  ]),
                                                  _c("tr", [
                                                    _c("td", [
                                                      _c("p", [
                                                        _vm._v(
                                                          "\n                                                                Name :\n                                                                "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          "\n                                                                " +
                                                            _vm._s(_vm.name) +
                                                            "\n                                                            "
                                                        )
                                                      ])
                                                    ]),
                                                    _c("td")
                                                  ]),
                                                  _c("tr", [
                                                    _c("td", [
                                                      _c("p", [
                                                        _vm._v(
                                                          "\n                                                                Title :\n                                                                "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          "\n                                                                " +
                                                            _vm._s(_vm.title) +
                                                            "\n                                                            "
                                                        )
                                                      ])
                                                    ]),
                                                    _c("td")
                                                  ]),
                                                  _c("tr", [
                                                    _c("td", [
                                                      _c("p", [
                                                        _vm._v(
                                                          "\n                                                                Date :\n                                                                "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          "\n                                                                " +
                                                            _vm._s(_vm.today) +
                                                            "\n                                                            "
                                                        )
                                                      ])
                                                    ]),
                                                    _c("td")
                                                  ])
                                                ])
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          !_vm.reviewing
                                            ? _c("Signing", {
                                                on: {
                                                  save: _vm.saveSignContract,
                                                  clean: _vm.cleanSignContract
                                                }
                                              })
                                            : _vm._e(),
                                          _c("v-spacer")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.errors.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                        },
                        [
                          _c("p", { staticClass: "error-prompt" }, [
                            _c(
                              "ul",
                              _vm._l(_vm.errors, function(error) {
                                return _c(
                                  "li",
                                  { key: error, staticClass: "error" },
                                  [_vm._v(_vm._s(error))]
                                )
                              })
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.finishSigning && !_vm.reviewing
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
                        },
                        [
                          _c("div"),
                          _c(
                            "button",
                            {
                              staticClass: "mdc-fab mdc-fab--extended",
                              attrs: {
                                type: "button",
                                "aria-label": _vm.$t("signup.submitContract")
                              },
                              on: { click: _vm.submitApplication }
                            },
                            [
                              _c("span", { staticClass: "mdc-fab__label" }, [
                                _vm._v(_vm._s(_vm.$t("signup.submitContract")))
                              ])
                            ]
                          ),
                          _c("div")
                        ]
                      )
                    : _vm._e(),
                  _vm.reviewing
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
                        },
                        [_c("v-divider")],
                        1
                      )
                    : _vm._e(),
                  _vm.reviewing
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
                        },
                        [
                          _c("div"),
                          _c(
                            "div",
                            [
                              _c("v-icon", { attrs: { "x-large": "" } }, [
                                _vm._v("alarm")
                              ])
                            ],
                            1
                          ),
                          _c("div")
                        ]
                      )
                    : _vm._e(),
                  _vm.reviewing
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
                        },
                        [
                          _c("div"),
                          _c(
                            "div",
                            {
                              staticClass: "mdc-typography--headline6",
                              staticStyle: { "text-align": "center" }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("signup.thanks")) +
                                  "\n                            "
                              ),
                              _c("br"),
                              _vm._v(
                                _vm._s(_vm.$t("signup.pending")) +
                                  "\n                        "
                              )
                            ]
                          ),
                          _c("div")
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }