var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-page app-page-payment app-page--active" },
    [
      _c("div", { staticClass: "app-page-container" }, [
        _c("div", { staticClass: "personal-info" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid app-page-activity app-page-activity--active bottom-nav--hidden"
            },
            [
              _c(
                "form",
                {
                  staticClass: "mdc-layout-grid__inner",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.update($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6"
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(this.$t("signup.companyInfo")) +
                          "\n                    "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.businessName,
                            expression: "businessName"
                          }
                        ],
                        staticClass: "mdc-text-field__input",
                        attrs: {
                          type: "text",
                          autocomplete: "off",
                          autocorrect: "off",
                          spellcheck: "false"
                        },
                        domProps: { value: _vm.businessName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.businessName = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label",
                          class:
                            _vm.businessName != ""
                              ? "mdc-floating-label--float-above"
                              : "",
                          attrs: { for: "businessName" }
                        },
                        [_vm._v(_vm._s(this.$t("signup.companyName")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.businessType,
                              expression: "businessType"
                            }
                          ],
                          staticClass: "mdc-text-field__input",
                          class: { "select--hidden": !_vm.businessType },
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.businessType = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function($event) {
                                _vm.businessBR = ""
                                _vm.businessCI = ""
                              }
                            ]
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { disabled: "" },
                              domProps: { value: null }
                            },
                            [_vm._v(_vm._s(this.$t("signup.businessType")))]
                          ),
                          _c("option", { attrs: { value: "limited" } }, [
                            _vm._v(_vm._s(this.$t("signup.limited")))
                          ]),
                          _c("option", { attrs: { value: "unlimited" } }, [
                            _vm._v(_vm._s(this.$t("signup.unlimited")))
                          ])
                        ]
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label",
                          class:
                            _vm.businessType != ""
                              ? "mdc-floating-label--float-above"
                              : ""
                        },
                        [_vm._v(_vm._s(this.$t("signup.businessType")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.businessBR,
                            expression: "businessBR"
                          }
                        ],
                        staticClass: "mdc-text-field__input",
                        attrs: {
                          type: "text",
                          autocomplete: "off",
                          autocorrect: "off",
                          spellcheck: "false"
                        },
                        domProps: { value: _vm.businessBR },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.businessBR = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label",
                          class:
                            _vm.businessBR != ""
                              ? "mdc-floating-label--float-above"
                              : "",
                          attrs: { for: "businessBR" }
                        },
                        [_vm._v(_vm._s(this.$t("signup.businessBR")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _vm.businessType == "limited" || _vm.businessType == ""
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.businessCI,
                                expression: "businessCI"
                              }
                            ],
                            staticClass: "mdc-text-field__input",
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              autocorrect: "off",
                              spellcheck: "false"
                            },
                            domProps: { value: _vm.businessCI },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.businessCI = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "mdc-floating-label ",
                              class:
                                _vm.businessCI != ""
                                  ? "mdc-floating-label--float-above"
                                  : "",
                              attrs: { for: "businessCI" }
                            },
                            [_vm._v(_vm._s(this.$t("signup.businessCI")))]
                          ),
                          _c("div", { staticClass: "mdc-notched-outline" }, [
                            _c("svg", [
                              _c("path", {
                                staticClass: "mdc-notched-outline__path"
                              })
                            ])
                          ]),
                          _c("div", {
                            staticClass: "mdc-notched-outline__idle"
                          })
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c("imask-input", {
                        ref: "statementDescriptor",
                        staticClass: "mdc-text-field__input",
                        attrs: {
                          mask: _vm.statementDescriptorMask,
                          prepare: _vm.statementDescriptorMask_onPrepare,
                          id: "statementDescriptor",
                          type: "text",
                          autocomplete: "off",
                          autocorrect: "off",
                          spellcheck: "false"
                        },
                        model: {
                          value: _vm.statementDescriptor,
                          callback: function($$v) {
                            _vm.statementDescriptor = $$v
                          },
                          expression: "statementDescriptor"
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label",
                          class:
                            _vm.statementDescriptor != ""
                              ? "mdc-floating-label--float-above"
                              : "",
                          attrs: { for: "statementDescriptor" }
                        },
                        [_vm._v(_vm._s(this.$t("signup.statementDescriptor")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedCountryCode,
                              expression: "selectedCountryCode"
                            }
                          ],
                          staticClass: "mdc-text-field__input",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedCountryCode = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { disabled: "" },
                              domProps: { value: null }
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.countryCode")))]
                          ),
                          _vm._l(_vm.countryCodeList, function(code) {
                            return _c(
                              "option",
                              { key: code.en, domProps: { value: code } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.locale == "zh_HK"
                                      ? code.zh
                                      : _vm.$i18n.locale == "zh_CN"
                                        ? code.cn
                                        : code.en
                                  ) +
                                    " " +
                                    _vm._s("(" + code.phone_code + ")")
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c("label", { staticClass: "mdc-floating-label" }, [
                        _vm._v(_vm._s(_vm.$t("signup.countryCode")))
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.phone,
                            expression: "phone"
                          }
                        ],
                        staticClass: "mdc-text-field__input",
                        attrs: {
                          type: "tel",
                          autocomplete: "off",
                          autocorrect: "off",
                          spellcheck: "false"
                        },
                        domProps: { value: _vm.phone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.phone = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label",
                          class:
                            _vm.phone != ""
                              ? "mdc-floating-label--float-above"
                              : "",
                          attrs: { for: "phone" }
                        },
                        [_vm._v(_vm._s(this.$t("signup.companyPhone")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.address,
                            expression: "address"
                          }
                        ],
                        staticClass: "mdc-text-field__input",
                        attrs: {
                          type: "text",
                          autocomplete: "off",
                          autocorrect: "off",
                          spellcheck: "false"
                        },
                        domProps: { value: _vm.address },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.address = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label ",
                          class:
                            _vm.address != ""
                              ? "mdc-floating-label--float-above"
                              : "",
                          attrs: { for: "address" }
                        },
                        [_vm._v(_vm._s(this.$t("signup.CompanyAddress")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.city,
                            expression: "city"
                          }
                        ],
                        staticClass: "mdc-text-field__input",
                        attrs: {
                          type: "text",
                          autocomplete: "off",
                          autocorrect: "off",
                          spellcheck: "false"
                        },
                        domProps: { value: _vm.city },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.city = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label",
                          class:
                            _vm.city != ""
                              ? "mdc-floating-label--float-above"
                              : "",
                          attrs: { for: "city" }
                        },
                        [_vm._v(_vm._s(this.$t("signup.city")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.state,
                            expression: "state"
                          }
                        ],
                        staticClass: "mdc-text-field__input",
                        attrs: {
                          type: "text",
                          autocomplete: "off",
                          autocorrect: "off",
                          spellcheck: "false"
                        },
                        domProps: { value: _vm.state },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.state = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label ",
                          class:
                            _vm.state != ""
                              ? "mdc-floating-label--float-above"
                              : "",
                          attrs: { for: "state" }
                        },
                        [_vm._v(_vm._s(this.$t("signup.state")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched",
                      staticStyle: { height: "100px" }
                    },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.businessDescription,
                            expression: "businessDescription"
                          }
                        ],
                        staticClass: "mdc-text-field__input",
                        attrs: {
                          type: "text",
                          autocomplete: "off",
                          autocorrect: "off",
                          spellcheck: "false"
                        },
                        domProps: { value: _vm.businessDescription },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.businessDescription = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label ",
                          class:
                            _vm.businessDescription != ""
                              ? "mdc-floating-label--float-above"
                              : "",
                          staticStyle: { bottom: "65%" },
                          attrs: { for: "businessDescription" }
                        },
                        [_vm._v(_vm._s(this.$t("signup.businessDescription")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.businessWeb,
                            expression: "businessWeb"
                          }
                        ],
                        staticClass: "mdc-text-field__input",
                        attrs: {
                          type: "text",
                          autocomplete: "off",
                          autocorrect: "off",
                          spellcheck: "false"
                        },
                        domProps: { value: _vm.businessWeb },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.businessWeb = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "mdc-floating-label ",
                          class:
                            _vm.businessWeb != ""
                              ? "mdc-floating-label--float-above"
                              : "",
                          attrs: { for: "businessWeb" }
                        },
                        [_vm._v(_vm._s(this.$t("signup.businessWeb")))]
                      ),
                      _c("div", { staticClass: "mdc-notched-outline" }, [
                        _c("svg", [
                          _c("path", {
                            staticClass: "mdc-notched-outline__path"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "mdc-notched-outline__idle" })
                    ]
                  ),
                  _vm.errors.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                        },
                        [
                          _c("p", { staticClass: "error-prompt" }, [
                            _c(
                              "ul",
                              _vm._l(_vm.errors, function(error) {
                                return _c("li", { key: error }, [
                                  _vm._v(_vm._s(error))
                                ])
                              })
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
                    },
                    [
                      _c("div"),
                      _c(
                        "button",
                        {
                          staticClass: "mdc-fab mdc-fab--extended",
                          attrs: {
                            type: "submit",
                            "aria-label": this.$t("actions.next")
                          }
                        },
                        [
                          _c("span", { staticClass: "mdc-fab__label" }, [
                            _vm._v(_vm._s(this.$t("actions.next")))
                          ]),
                          _c(
                            "svg",
                            {
                              staticClass: "mdc-fab__icon",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewbox: "0 0 24 24"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }