export const general = {
    payment: '支払い',
    direct: '一回払い',
    recurrent: '月払い',
    remote: 'リクエスト',
    campaign: '広告',
    online: 'オンライン',
    dashboard: 'ダッシュボード',
    payout: '入金履歴',
    transactions: '支払い履歴',
    settings: '設定',
    planning: '計画',
    summary: '概要',
    keyword: 'キーワード',
    searchCategory: {
        title: 'カテゴリー',
        all: '全部',
        description: '説明',
        receiptNumber: '領収書番号',
        status: 'ステータス',
        type: 'タイプ',
        cashflow: '月払いの現金流入',
    },
    paymentMethods: {
        label: '支払いタイプ',
        qrPayment: 'QRコード支払い',
        card: 'クレジットカード',
        wechat: 'WeChat Pay',
        alipay: 'Alipay',
    },
    card: {
        label: 'クレジットカード',
        number: 'クレジットカード番号',
        holderName: '持ち主',
        expiryDate: '有効期限',
        info: 'クレジットカード情報',
        error: {
            missingCardNumber: 'クレジットカード番号を入力して下さい。',
            missingExpiryDate: 'クレジットカードの有効期限を入力して下さい。',
            missingCvc: 'クレジットカードのCVCを入力して下さい。',
            missingCardHolderName: '持ち主の名前を入力して下さい。',
            invalidExpiryDate: '月払いが終了する前にクレジットカードの有効期限が切れます。',
        },
    },
    paymentDetails: {
        quantity: '量',
        amount: '金額',
        description: '説明',
        remoteMessage: 'メッセージ',
        totalAmount: '総金額',
    },
    date: {
        start: '開始日',
        end: '終了日',
    },
    history: {
        title: '履歴',
        payment: '支払い履歴',
        expectedIncome: '予想現金流入',
        expectedCount: '予想件数',
        chargeCount: '回数',
    },
    login: {
        agreementHeader: '同意して続行',
        privacy: 'プライバシーポリシー',
        tnc: '利用規約',
    },
    thisStore: '当店',
    warningMsg: '提出した後、変更ができないためもう一度確認して下さい。',
    notifications: '通知',
    confirmInfo: '確認',
    confirmMsg: '入力した内容が正しいですか？',
    wechatPayDisable: 'WeChat Payは利用できません。JARVIX PAYに連絡して下さい。',
    qrPaymentDisable: 'WeChat Pay / AliPay は利用できません。JARVIX PAYに連絡して下さい。',
};
