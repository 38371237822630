













import { Component, Vue } from 'vue-property-decorator';
import { ERROR_MSG, LAP_PAY_ERROR_MSG, ERROR_TYPE } from '../constants/ERROR';

@Component
export default class ErrorDialog extends Vue {
    private open = false;
    private message = '';
    private receipt = false;
    private receiptMessage = '';
    private type = 'merchant';

    mounted() {
        this.$root.$on('error', (error: any) => {
            this.message = '';
            if (error.error_type === 'unauthorized_error') {
                this.$store
                    .dispatch('logout')
                    .then((response: any) => {
                        this.$root.$emit('resetPayment');
                        this.$router.push({ name: 'login' });
                        return (this.message = this.$t('AUTH.LOGIN_AGAIN') as string);
                    })
                    .catch((error: any) => {
                        return this.$root.$emit('error', error);
                    });
            } else if (ERROR_MSG.hasOwnProperty(error.user_message)) {
                const user_message = (error.user_message as string).replace(/\s/g, '_').replace(/\./g, ''); //remove bad char
                this.message = this.$t(`errors.ERROR_MSG.` + user_message) as string; // specific error
            } else if (LAP_PAY_ERROR_MSG.hasOwnProperty(error.user_message)) {
                const user_message = (error.user_message as string).replace(/\s/g, '_').replace(/\./g, '');
                this.message = this.$t(`errors.LAP_PAY_ERROR_MSG.` + error.user_message) as string; // specific error
            } else if (ERROR_TYPE.hasOwnProperty(error.error_type)) {
                // console.log(`errors.ERROR_TYPE.` + error.error_type);
                this.message = this.$t(`errors.ERROR_TYPE.` + error.error_type) as string; // general error - fallback
            } else if (error.user_message) {
                // console.log(error.user_message);
                this.message = this.$t(error.user_message) as string; // unknown error
            } else {
                this.message = this.$t(error.message) as string;
            }

            this.open = true;
        });

        this.$root.$on('receipt_error', (error: any, type?: 'client | merchant') => {
            type ? (this.type = type) : '';

            this.receiptMessage = '';
            if (ERROR_MSG.hasOwnProperty(error.user_message)) {
                this.receiptMessage = this.$t(`errors.ERROR_MSG.` + error.user_message) as string; // specific error
            } else if (ERROR_TYPE.hasOwnProperty(error.error_type)) {
                this.receiptMessage = this.$t(`errors.ERROR_TYPE.` + error.error_type) as string; // general error - fallback
            } else if (error.user_message) {
                this.receiptMessage = this.$t(error.user_message) as string; // unknown error
            } else {
                this.receiptMessage = this.$t(error.message) as string;
            }

            this.receipt = true;
        });
    }

    onEnter() {
        if (this.type === 'client') {
            return;
        }

        return this.$router.go(-1);
    }
}
