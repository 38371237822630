export const AUTH = {
    INVALID_EMAIL_FORAMT: '電子郵件地址無效。',
    INVALID_STATEMENTDESCRIPTOR_FORMAT_TOO_SHORT: '客戶帳單顯示名稱需多於5英文字母。',
    INVALID_STATEMENTDESCRIPTOR_FORMAT_TOO_LONG: '客戶帳單顯示名稱需少於22個字元。',
    INVALID_NAME_FORMAT: '用戶名稱格式無效。',
    INVALID_COMPANY_NAME_FORMAT: '公司名稱格式無效。',
    INVALID_PASSWORD_FORMAT: '密碼格式無效, 密碼必須需要至少8個字元。',
    CONFIRM_FAILURE: '確認密碼無效。',
    INVALID_MOBILE_FORMAT: '電話號碼無效。',
    INVALID_VERIFICATION_CODE: '驗證碼無效。',
    INVALID_HKID: '身份證號碼不正確。',
    INVALID_SUPPORTING_DOCUMENT: '證明文件無效。',
    ADDRESS_TOO_LONG: '地址需少於200個字元。',
    DESCRIPTION_TOO_LONG: '業務簡介需少於200個字元。',
    INVALID_WEBSITE_FORMAT: '公司網站無效。',
    EMPTY_FIELD: '請輸入所有資料。',
    NO_SELECTED_ACCOUNT: '未選擇分店',
    NO_SELECTED_USER: '未選擇用戶',
    INCORRECT_CONFIRMED_VALUE: '確認資料不正確。',
    DUPLICATED_OLD_VALUE: '新資料不能與舊資料一樣。',
    DUPLICATED_MERCHANT_NAME: '該名稱已被使用, 請嘗試另一個名稱。',

    EMAIL_USED: '電子郵件已被註冊。',
    SIGNUP_SUCCESS: '成功註冊。',
    LOGIN_SUCCESS: '成功登入。',
    ACTIVATE_USER_SUCCESS: '成功驗證帳號。',
    INCORRECT_CREDENTIALS: '密碼不正確。',

    HOLDER_NAME_TOO_LONG: '持卡人姓名需少於200個字元。',
    INVALID_HOLDER_NAME: '持卡人姓名格式無效。',
    NOT_ACCEPTING_TOS: '閣下必須瞭解並同意 Jarvix Pay 服務條款。',

    DOCUMENT_ERROR: '文件格式錯誤，我們只支持jpeg, png的格式, 請重新上傳',
    LOGIN_AGAIN: '請重新登入',
};
