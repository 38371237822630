export const recurrent = {
    amount: '每月支款金額',
    totalAmount: '帳單總金額：{0}{1} x {2}(月) = {3}{4}',
    duration: '期數 (月)',
    autoRenewal: '自動續期',
    autoRenewalMessage: '{0}個月後自動續期 (每月)',
    summary: '月付總結',
    warningMessage: '請選擇在{0}或之後到期的信用卡。',
    durationMessage: '{0}個月',
    left: '餘下{0}期',

    cancelRemainingMessage: '立即取消餘下{0}期的款項。',
    nonRefundMessage: '** 已支付的款項不會退款。',
};
