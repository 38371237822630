



import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

interface Token {
    id: string;
    type: string;
    card: {
        brand: string;
        exp_month: number;
        exp_year: number;
        last4: string;
        name: string;
    };
}

@Component({})
export default class CreditCard extends Vue {
    @Prop(String)
    display!: string;

    @Prop()
    direct!: boolean;

    @Prop()
    amount?: string;

    @Prop()
    description?: string;

    private sacid = this.$store.state.user.sacid;

    @Watch('display')
    onDisplayChanged(newVal: string, oldVal: string) {
        if (newVal === 'card') {
            if (oldVal == 'summary') {
                return this.showPluginIn({ scanner: false });
            }

            return this.showPluginIn({ scanner: true });
        }
    }

    @Watch('token_')
    onValueChanged(v: string) {
        this.$emit('input', v);
    }

    // get title() {
    //     return `收款金額: ${this.amount}${this.description ? ` - 交易詳情: ${this.description}` : ''}`;
    // }

    public token: Token | null = null;

    get token_() {
        let token = this.token;
        return {
            ...(token && { token }),
        };
    }

    public async showPluginIn({ scanner: scanner }: { scanner: boolean }) {
        const { JPAYCardInput } = (window as any).Capacitor.Plugins;

        try {
            await (async () => {
                this.$store.commit('isLoading', true);

                let options: {
                    stripe_account: string;
                    skip_scanner: boolean;
                    title?: string;
                    subtitle?: string;
                    show_pay_button?: boolean;
                } = {
                    stripe_account: this.sacid,
                    skip_scanner: !scanner,
                };

                if (this.direct) {
                    this.amount && (options.title = `${this.amount}`);
                    this.description && (options.subtitle = this.description);
                    options.show_pay_button = true;
                }

                const t = await JPAYCardInput.show(options);
                this.token = {
                    id: t.data.source_id,
                    type: t.data.source_type,
                    card: {
                        brand: t.data.card_brand,
                        exp_month: t.data.card_exp_month,
                        exp_year: t.data.card_exp_year,
                        last4: t.data.card_last4,
                        name: t.data.card_name,
                    },
                };
            })();

            if (this.direct) {
                this.$emit('pay');
            } else {
                this.$store.commit('isLoading', false);
                this.$emit('next');
            }
        } catch (err) {
            this.$emit('back');
            this.$store.commit('isLoading', false);
        } finally {
            document.body.classList.add('no-transition');
            this.$nextTick(() => {
                document.body.classList.remove('no-transition');
            });
        }
    }

    public reset() {
        const { JPAYCardInput } = (window as any).Capacitor.Plugins;

        return JPAYCardInput.clearCachedUserInput();
    }

    public mounted() {
        this.token = null;

        this.$root.$on('reset', () => {
            this.reset();
        });
    }
}
