











import { Component, Vue, Watch } from 'vue-property-decorator';
import MRipple from 'material-components-vue/dist/ripple';
Vue.use(MRipple);


@Component
export default class MerchantTopbar extends Vue {
    private merchantDisplayName = '';
    private merchantLogo = '';

    public async created(){
        return this.$store.dispatch('getMerchantLogoFromMerchantName', {
            merchantName: this.$route.params.brand
        }).then((res: any) => {
            this.merchantDisplayName = res.displayName;
            this.merchantLogo = res.src;
        }).catch((err: any) => {
            return this.$root.$emit('error', err);
        })
    }

}
