












import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import NotificationMessage from '@/components/notification/NotificationMessage.vue';

import { moment_HK as moment } from '@/store';
import { scrollTop } from '@/utils/scrollTop';

@Component({
    components: {
        NotificationMessage,
    },
})
export default class NotificationMessageList extends Vue {
    @Prop() messages!: any;
    private notificationMessages = [];

    @Watch('messages')
    onMessagesChanged() {
        console.log('onMessagesChanged');
        this.fetchMessages();
    }

    public async created() {
        // this.notificationMessages = this.messages;
        this.notificationMessages = this.$store.state.notificationMessages;
        console.log('notificationMessages in Message List:', this.notificationMessages);
    }

    public async fetchMessages() {
        this.notificationMessages = this.$store.state.notificationMessages;
    }
}
