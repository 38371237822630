




















































import { Component, Vue, Prop } from 'vue-property-decorator';
import RemoteStatusTag from '@/components/remote-payment/RemoteStatusTag.vue';
import { moment_HK as moment } from '@/store';
import * as uuid62 from 'uuid62';

@Component({
    components: {
        RemoteStatusTag,
    },
})
export default class CampaignOrderSummaryItem extends Vue {
    @Prop()
    campaignId!: string;

    @Prop()
    order!: {
        id: string;
        amount: number;
        customer_name: string;
        customer_country_code: string;
        customer_phone: string;
        receipt_no?: string;
        paid: boolean;
        created_at: string;
    };

    // private send(){
    //     window.open(this.whatsappLink);
    //     return;
    // }

    // get whatsappLink() {
    //     const remoteLinkId = uuid62.encode(this.remote.id);
    //     const message = this.remote.message ? `${this.remote.message}${'\n'}` : '';

    //     const link = `http://wa.me/852${this.remote.customer_phone}?text=${message}請按此連結付款:${'\n'}${window.location.origin}/remote/${this.merchant}/${remoteLinkId}`;
    //     return encodeURI(link);
    // }

    get formatDate() {
        return moment(this.order.created_at).format('LLL');
    }

    get moment() {
        return moment;
    }
}
