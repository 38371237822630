































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { moment_HK as moment } from '@/store';
import ChargeStatusTag from '@/components/charges/ChargeStatusTag.vue';
import RecurrentStatusTag from '@/components/recurrents/RecurrentStatusTag.vue';
import VirtualCard from '@/components/payment/VirtualCard.vue';
import bandOptions from '@/constants/BANKS';
import { currencyNameWithSymbol } from '@/utils/helpers/currencyHelper';

const stripeFxFee = bandOptions.stripeFxFee;

interface charge {
    receipt_no: string;
    created_at: string;
    amount: number;
    description: string;
    status: string;
    creditcard: {
        last4: string;
        cardholder_name: string;
        exp_date: string;
        application_brand: string;
        funding: string;
    };
    connect_remote_id: string;
}

@Component({
    filters: {
        currencySymbolDisplay: currencyNameWithSymbol,
    },
    components: {
        ChargeStatusTag,
        RecurrentStatusTag,
        VirtualCard,
    },
})
export default class Receipt extends Vue {
    @Prop() chargeId!: string;
    @Prop() remoteId!: string;
    @Prop() statementDescriptor!: string;
    private charge: charge | null = null;
    private last4 = '';
    private cardholder_name = '';
    private exp_date = '';
    private created_at = '';
    private refunded_at = '';

    get maskName() {
        return this.cardholder_name
            .split(' ')
            .filter(s => s.length > 0)
            .map(s => s.toUpperCase())
            .map((s, i) => (i ? s.charAt(0) + '***' : s))
            .join(' ');
    }

    get formatFullCreatedDate() {
        return `${moment(this.created_at).format('LL')} ${moment(this.created_at).format('LTS')}`;
    }

    get formatFullRefundedDate() {
        return `${moment(this.refunded_at).format('LL')} ${moment(this.refunded_at).format('LTS')}`;
    }

    get formatCreatedDate() {
        return moment(this.created_at).format('LL');
    }

    get formatCreatedTime() {
        return moment(this.created_at).format('LT');
    }

    get hkdConvent() {
        if (this.charge && (this.charge as any).currency !== 'hkd') {
            let fxRates = this.$store.state.fxRates[(this.charge as any).currency.toUpperCase() + '_HKD'];
            return '(≈HKD$ ' + (this.charge.amount * fxRates * (1 - stripeFxFee)).toFixed(2) + ')';
        } else {
            return '';
        }
    }

    public async created() {
        this.$store.commit('isLoading', true);
        try {
            await this.initAttribute();
        } catch (e) {
            return this.$root.$emit('receipt_error', e, 'client');
        } finally {
            this.$store.commit('isLoading', false);
        }
    }

    protected async initAttribute() {
        this.charge = await this.$store.dispatch('getSingleRemoteCharge', {
            remoteId: this.remoteId,
            chargeId: this.chargeId,
        });
        if (!this.charge) return;
        this.last4 = this.charge.creditcard.last4;
        this.cardholder_name = this.charge.creditcard.cardholder_name;
        this.exp_date = this.charge.creditcard.exp_date;
        this.created_at = this.charge.created_at;
    }
}
