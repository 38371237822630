





























































import { Component, Prop, Vue } from 'vue-property-decorator';
import Terms from '@/components/Terms.vue';
import Privacy from '@/components/Privacy.vue';
import { isEmail } from '../utils/validators';
import AUTH_MSG from '../constants/AUTH';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
// import { Plugins, PushNotification, PushNotificationActionPerformed } from '@capacitor/core';
// const { PushNotifications } = Plugins;
// import { fcm_pushNotification, getFCMToken } from '@/utils/notificationService';

//
// with type support
// import { FCM } from 'capacitor-fcm';
// const fcm = new FCM();

//
// alternatively - without types
// const { FCMPlugin } = Plugins;

@Component({
    components: {
        Terms,
        Privacy,
    },
})
export default class Login extends Vue {
    // prettier-ignore
    @Prop() next!: string;
    private email: string = '';
    private password: string = '';
    private errors: string[] = [];

    public mounted() {
        this.initMDCTextFields();
    }

    protected async login() {
        // let token = await getFCMToken;
        this.errors = [];

        // let tokenVuex = this.$store.state.deviceToken.token;
        // console.log('Token Vuex:', tokenVuex);
        // validation
        if (!isEmail(this.email)) {
            this.errors.push(this.$t(`AUTH.INVALID_EMAIL_FORAMT`) as string);
        }

        if (this.password.length <= 0) {
            this.errors.push(this.$t(`AUTH.INVALID_PASSWORD_FORMAT`) as string);
        }

        if (this.errors.length) {
            return;
        }

        try {
            await this.$store.dispatch('login', {
                email: this.email,
                password: this.password,
            });
            // await fcm_pushNotification;
            // await this.$store.dispatch('saveDeviceToken', { device_token: token });
            return window.location.replace(this.next ? '/#' + this.next : '/');
        } catch (error) {
            return this.$root.$emit('error', error);
        }
        // API CALL
        // this.$store
        //     .dispatch('login', {
        //         email: this.email,
        //         password: this.password,
        //     })
        //     .then((response: any) => {
        //         // run saveDeviceToken
        //         fcm_pushNotification;
        //         this.$store.dispatch('saveDeviceToken', { device_token: token });
        //         return window.location.replace(this.next ? '/#' + this.next : '/');
        //     })
        //     .catch((error: any) => {
        //         return this.$root.$emit('error', error);
        //     });
    }

    private showTOS() {
        (this.$refs.terms as any).show();
    }

    private showPrivacy() {
        (this.$refs.privacy as any).show();
    }

    // animation function
    private initMDCTextFields() {
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
