var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "client-receipt" }, [
    _c(
      "div",
      { staticClass: "app-page app-page-payment-history app-page--active" },
      [
        _vm.charge
          ? _c("div", { staticClass: "app-page-container" }, [
              _c(
                "div",
                {
                  staticClass:
                    "mdc-layout-grid app-page-activity app-page-activity--active"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6 title-font-size"
                    },
                    [
                      _vm.charge.status == "succeeded"
                        ? _c("div", [_vm._v("成功付款")])
                        : _vm._e(),
                      _vm.charge.status == "failed"
                        ? _c("div", [_vm._v("付款失敗")])
                        : _vm._e()
                    ]
                  ),
                  _c("br"),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                    },
                    [
                      _vm.charge.status == "succeeded"
                        ? _c("div", [
                            _vm._v(
                              "\n                        您已經成功搶購優惠項目，如有任何查詢，請致電本頁面下方顯示的聯絡電話。(已發送確認短訊，請同時請擷取螢幕畫面以保留交易紀錄)\n                    "
                            )
                          ])
                        : _vm._e(),
                      _vm.charge.status == "failed"
                        ? _c("div", [
                            _vm._v(
                              "\n                        對不起，付款失敗，請再次付款。如有任何查詢，請致電本頁面下方顯示的聯絡電話。\n                    "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c("br"),
                  _c(
                    "div",
                    { staticClass: "activity-payment-history-expanded" },
                    [
                      _c(
                        "div",
                        { staticClass: "payment-history-expanded-item" },
                        [
                          _c("div", {
                            staticClass:
                              "payment-history-expanded-item__background"
                          }),
                          _vm.charge
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "payment-history-expanded-item__content"
                                },
                                [
                                  _c("div", { staticClass: "section" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mdc-typography--headline6",
                                          staticStyle: {
                                            margin: "0",
                                            "font-size": "1em",
                                            opacity: ".75"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        #" +
                                              _vm._s(_vm.charge.receipt_no) +
                                              "\n                                    "
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "section" }, [
                                    _c(
                                      "div",
                                      { staticClass: "row row--more-margin" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "payment-history-expanded-item__amount mdc-typography--headline4"
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm._f(
                                                    "currencySymbolDisplay"
                                                  )(_vm.charge.currency)
                                                ) +
                                                _vm._s(_vm.charge.amount) +
                                                "\n                                    "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row row--more-margin row--space-between"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "payment-history-expanded-item__date mdc-typography--caption"
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(_vm.formatCreatedDate) +
                                                "\n                                    "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "payment-history-expanded-item__time mdc-typography--caption"
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(_vm.formatCreatedTime) +
                                                "\n                                    "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row row--more-margin row--space-between"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "tags__group" },
                                          [
                                            _c("ChargeStatusTag", {
                                              attrs: { status: _vm.charge }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "buttons__group" },
                                          [
                                            _vm.charge.status === "failed"
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "mdc-button mdc-button--raised",
                                                    on: {
                                                      click: function($event) {
                                                        _vm.$emit("retry")
                                                      }
                                                    }
                                                  },
                                                  [_vm._m(0)]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", { staticClass: "section" }, [
                                    _vm._m(1),
                                    _c("div", { staticClass: "row" }, [
                                      _vm.displayName
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "payment-history-expanded-item__desc mdc-typography--body1"
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.displayName) +
                                                  "\n                                    "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "payment-history-expanded-item__desc mdc-typography--body1"
                                            },
                                            [_vm._v("—")]
                                          )
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "section" },
                                    [
                                      _vm._m(2),
                                      _vm._l(_vm.productList, function(
                                        product
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: product.id,
                                            staticClass: "product"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "product--name" },
                                              [
                                                _vm._v(
                                                  _vm._s(product.name) +
                                                    " x " +
                                                    _vm._s(product.quantity)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "product--amount"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "currencySymbolDisplay"
                                                      )(_vm.charge.currency)
                                                    ) +
                                                    _vm._s(product.totalPrice) +
                                                    "\n                                    "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  ),
                                  _c("div", { staticClass: "section" }, [
                                    _vm._m(3),
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c("VirtualCard", {
                                          attrs: { card: _vm.charge.creditcard }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "section" }, [
                                    _vm._m(4),
                                    _vm.charge.status
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row row--space-between"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "payment-history-expanded-item__desc mdc-typography--body2"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      {
                                                        succeeded: "成功付款",
                                                        failed: "付款失敗",
                                                        pending: "處理中"
                                                      }[_vm.charge.status]
                                                    ) +
                                                    "\n                                    "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "payment-history-expanded-item__datetime mdc-typography--caption"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm.formatFullCreatedDate
                                                    ) +
                                                    "\n                                    "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                ]
              )
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "mdc-button__text" }, [
      _c("span", [_vm._v("請再次付款")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "mdc-typography--headline6" }, [_vm._v("商戶")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "mdc-typography--headline6" }, [
        _vm._v("商品詳情")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "mdc-typography--headline6" }, [
        _vm._v("信用卡資料")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "mdc-typography--headline6" }, [_vm._v("記錄")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }