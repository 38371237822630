var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.status_ && _vm.status_ in _vm.statusProps
    ? _c(
        "div",
        {
          staticClass: "tags",
          class: [
            _vm.statusProps[_vm.status_].class,
            { "tags--small": _vm.small }
          ]
        },
        [_vm._v(_vm._s(_vm.statusProps[_vm.status_].text))]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }