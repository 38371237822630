var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "transaction" } }, [
    _c(
      "div",
      {
        staticClass:
          "app-page app-page-payment app-page-payment-history app-page--active"
      },
      [
        _c("div", { staticClass: "app-page-container" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid app-page-activity app-page-activity--active"
            },
            [
              _c("div", { staticClass: "mdc-layout-grid__inner lang--zh-HK" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet tab__wrapper"
                  },
                  [
                    _c(
                      "mdc-tab-bar",
                      { ref: "mdcTabBar", staticClass: "tab-bar" },
                      [
                        _c(
                          "mdc-tab",
                          {
                            attrs: { active: _vm.activeIndex === 0 },
                            on: { click: _vm.displayHistory }
                          },
                          [
                            _c("div", { staticClass: "mdc-tab__label" }, [
                              _c(
                                "svg",
                                {
                                  staticClass: "mdc-tab__icon",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewbox: "0 0 24 24"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M17.35,2.219h-5.934c-0.115,0-0.225,0.045-0.307,0.128l-8.762,8.762c-0.171,0.168-0.171,0.443,0,0.611l5.933,5.934c0.167,0.171,0.443,0.169,0.612,0l8.762-8.763c0.083-0.083,0.128-0.192,0.128-0.307V2.651C17.781,2.414,17.587,2.219,17.35,2.219M16.916,8.405l-8.332,8.332l-5.321-5.321l8.333-8.332h5.32V8.405z M13.891,4.367c-0.957,0-1.729,0.772-1.729,1.729c0,0.957,0.771,1.729,1.729,1.729s1.729-0.772,1.729-1.729C15.619,5.14,14.848,4.367,13.891,4.367 M14.502,6.708c-0.326,0.326-0.896,0.326-1.223,0c-0.338-0.342-0.338-0.882,0-1.224c0.342-0.337,0.881-0.337,1.223,0C14.84,5.826,14.84,6.366,14.502,6.708"
                                    }
                                  })
                                ]
                              ),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("general.transactions")))
                              ])
                            ])
                          ]
                        ),
                        _c(
                          "mdc-tab",
                          {
                            attrs: { active: _vm.activeIndex === 1 },
                            on: { click: _vm.displayRecurrenceHistory }
                          },
                          [
                            _c("div", { staticClass: "mdc-tab__label" }, [
                              _c(
                                "svg",
                                {
                                  staticClass: "mdc-tab__icon",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewbox: "0 0 24 24"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M8.627,7.885C8.499,8.388,7.873,8.101,8.13,8.177L4.12,7.143c-0.218-0.057-0.351-0.28-0.293-0.498c0.057-0.218,0.279-0.351,0.497-0.294l4.011,1.037C8.552,7.444,8.685,7.667,8.627,7.885 M8.334,10.123L4.323,9.086C4.105,9.031,3.883,9.162,3.826,9.38C3.769,9.598,3.901,9.82,4.12,9.877l4.01,1.037c-0.262-0.062,0.373,0.192,0.497-0.294C8.685,10.401,8.552,10.18,8.334,10.123 M7.131,12.507L4.323,11.78c-0.218-0.057-0.44,0.076-0.497,0.295c-0.057,0.218,0.075,0.439,0.293,0.495l2.809,0.726c-0.265-0.062,0.37,0.193,0.495-0.293C7.48,12.784,7.35,12.562,7.131,12.507M18.159,3.677v10.701c0,0.186-0.126,0.348-0.306,0.393l-7.755,1.948c-0.07,0.016-0.134,0.016-0.204,0l-7.748-1.948c-0.179-0.045-0.306-0.207-0.306-0.393V3.677c0-0.267,0.249-0.461,0.509-0.396l7.646,1.921l7.654-1.921C17.91,3.216,18.159,3.41,18.159,3.677 M9.589,5.939L2.656,4.203v9.857l6.933,1.737V5.939z M17.344,4.203l-6.939,1.736v9.859l6.939-1.737V4.203z M16.168,6.645c-0.058-0.218-0.279-0.351-0.498-0.294l-4.011,1.037c-0.218,0.057-0.351,0.28-0.293,0.498c0.128,0.503,0.755,0.216,0.498,0.292l4.009-1.034C16.092,7.085,16.225,6.863,16.168,6.645 M16.168,9.38c-0.058-0.218-0.279-0.349-0.498-0.294l-4.011,1.036c-0.218,0.057-0.351,0.279-0.293,0.498c0.124,0.486,0.759,0.232,0.498,0.294l4.009-1.037C16.092,9.82,16.225,9.598,16.168,9.38 M14.963,12.385c-0.055-0.219-0.276-0.35-0.495-0.294l-2.809,0.726c-0.218,0.056-0.351,0.279-0.293,0.496c0.127,0.506,0.755,0.218,0.498,0.293l2.807-0.723C14.89,12.825,15.021,12.603,14.963,12.385"
                                    }
                                  })
                                ]
                              ),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("general.recurrent")))
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                  },
                  [_c("router-view")],
                  1
                )
              ])
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }