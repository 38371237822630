


































import { Component, Vue } from 'vue-property-decorator';
import { QrcodeStream, QrcodeCapture } from 'vue-qrcode-reader';
import CreditCard from '@/components/payment/CreditCard.vue';

@Component({
    components: {
        QrcodeStream,
        QrcodeCapture,
        CreditCard,
    },
})
export default class Dummy extends Vue {
    private error = '';

    public mounted() {
        // console.log('Dummy mounted');
    }

    public onTabUpdated(index: number) {}

    public onDecode(decodedString: string) {
        // console.log(decodedString)
    }

    public async onInit(promise: any) {
        try {
            await promise;
        } catch (error) {
            if (error.name === 'NotAllowedError') {
                this.error = 'ERROR: you need to grant camera access permisson';
            } else if (error.name === 'NotFoundError') {
                this.error = 'ERROR: no camera on this device';
            } else if (error.name === 'NotSupportedError') {
                this.error = 'ERROR: secure context required (HTTPS, localhost)';
            } else if (error.name === 'NotReadableError') {
                this.error = 'ERROR: is the camera already in use?';
            } else if (error.name === 'OverconstrainedError') {
                this.error = 'ERROR: installed cameras are not suitable';
            } else if (error.name === 'StreamApiNotSupportedError') {
                this.error = 'ERROR: Stream API is not supported in this browser';
            }
        }
    }
}
