export const ERROR_MSG: RandomMappingWithIndexSignature = {
    'Invalid Credentials': true,
    'Network Error': true,
    'The requested charge is invalid': true,
    'Email is already taken': true,
    'The verification code field is invalid': true,
    'The password is invalid': true,
    'The new password is repeated': true,
    'Duplicate Entry Error': true,
    'Payment Error b01': true,
    'Payment Error r01': true,
    // upload documents
    "Front: We don't currently support that file type. Try uploading a file with one of the following mimetypes: image/jpeg, image/png": true,
    "Back: We don't currently support that file type. Try uploading a file with one of the following mimetypes: image/jpeg, image/png": true,
};

export const LAP_PAY_ERROR_MSG: RandomMappingWithIndexSignature = {
    exceed_daily_trx_limit: true,
    exceed_monthly_trx_limit: true,
    exceed_daily_and_monthly_trx_limit: true,
    refund_rejected_after_100_days: true,
    qr_code_expired: true,
    insufficient_balance: true,
    unsupported_card_type: true,
    unsupported_payment_type: true,
    'user_paying, please_input_password': true,
    unable_to_pay_at_overseas_merchants: true,
    user_paying: true,
    internal_server_error: true,
    payment_gateway_error: true,
};

export const ERROR_TYPE: RandomMappingWithIndexSignature = {
    unknown_error: true,
    invalid_param_error: true,
    database_error: true,
    database_content_not_found: true,
    passport_error: true,
    unexpected_error: true,
    permission_error: true,
    duplicated_campaign_error: true,
    oversold_error: true,
};

export const STRIPE_CODE: RandomMappingWithIndexSignature = {
    customer_canceled_authentication: true,
    expired_card: true,
    incomplete_card: true,
    incomplete_cvc: true,
    incomplete_expiry: true,
    incomplete_iban: true,
    incomplete_number: true,
    incomplete_zip: true,
    incorrect_cvc: true,
    incorrect_number: true,
    invalid_expiry_month: true,
    invalid_expiry_month_past: true,
    invalid_expiry_year: true,
    invalid_expiry_year_past: true,
    invalid_iban: true,
    invalid_iban_country_code: true,
    invalid_iban_start: true,
    invalid_number: true,
    processing_error: true,
    process_error_intransient: true,
    token_already_used: true,
    unexpected: true,

    account_invalid: true, // stripeId
    resource_missing: true, // e.g. no such customer
};

export const STRIPE_DECLINE_CODE: RandomMappingWithIndexSignature = {
    authentication_required: true,
    approve_with_id: true,
    call_issuer: true,
    card_not_supported: true,
    card_velocity_exceeded: true,
    currency_not_supported: true,
    do_not_honor: true,
    do_not_try_again: true,
    duplicate_transaction: true,
    expired_card: true,
    fraudulent: true,
    fake_merchant_test_card: true,
    generic_decline: true,
    incorrect_cvc: true,
    incorrect_number: true,
    invalid_expiry_month: true,
    invalid_expiry_month_past: true,
    incorrect_pin: true,
    insufficient_funds: true,
    invalid_pin: true,
    invalid_account: true,
    invalid_amount: true,
    issuer_not_available: true,
    lost_card: true,
    live_mode_test_card: true,
    merchant_blacklist: true,
    new_account_information_available: true,
    no_action_taken: true,
    not_permitted: true,
    pickup_card: true,
    stolen_card: true,
    processing_error: true,
    restricted_card: true,
    revocation_of_all_authorizations: true,
    revocation_of_authorization: true,
    security_violation: true,
    service_not_allowed: true,
    stop_payment_order: true,
    pin_try_exceeded: true,
    test_mode_live_card: true,
    transaction_not_allowed: true,
};

interface RandomMappingWithIndexSignature {
    [propName: string]: boolean;
}
