















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Spinner1 extends Vue {
    @Prop({ default: true }) active?: string;

    private border2_ = false;

    public mounted() {
        if (parseFloat(window.getComputedStyle(this.$el.querySelector('.circle') as Element).borderWidth as string) < 2) {
            this.border2_ = true;
        }
    }
}
