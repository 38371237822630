export const errors = {
    label: 'Error',
    GENERAL: {
        retry: 'An error occurred. Please retry.',
        contact: 'Please contact to Jarvix Pay.',
        editing: 'Please save the editing information.',
    },
    PAYMENT_DETAILS: {
        invalid_transaction: 'Transaction amount should be from $10-${0}.',
        missing_description: 'Description is missing.',
        invalid_recurrent_duration: 'Recurrent duration should be from 2 months to 99 months.',
        missing_message: 'Client message is missing.',
    },
    REMOTE: {
        empty_customer: 'Customer name and phone should not be empty.',
        duplicated_customer: 'Phone number should not be duplicated.',
        missing_transaction: 'Please fill in the transaction information.',
        max_customers: 'The maximum number of customers is 10 per remote request.',
        min_customers: 'The minimum number of customers is 1 per remote request.',
    },
    CAMPAIGN: {
        empty_contact: 'Shop and phone number are required.',
        empty_product: 'Product name, price and quantity per order are required.',
        invalid_price: 'Price should not be lower than $0.',
        missing_original_price: 'Please input the original price.',
        invalid_discount: 'Actual price should be lower than original price.',
        invalid_quantity_per_order: 'Quantity per order should not be greater than total quantity',
        exceed_photo_limit: 'At most 5 banners can be added.',
        exceed_product_limit: 'At most 20 products can be added.',
        exceed_link_limit: 'At most 25 links can be added.',
        less_than_minimum: 'Please input at least 1 {0}.',
        missing_input: 'The {0} is required.',
        mc_option: 'Custom Question Option have been enabled. Please input the Question and Choice.',
    },
    ERROR_MSG: {
        title: 'Error',
        Invalid_Credentials: 'Incorrect Account or Password. [Code: c00]',
        Network_Error: 'Network Error. Please check your network connection and try again. [Code: n00]',
        The_requested_charge_is_invalid: 'The transaction is invalid. [Code: d01]',
        Email_is_already_taken: 'Email address is already registered.',
        The_verification_code_field_is_invalid: 'Invalid verification code.',
        The_password_is_invalid: 'Please enter the correct password',
        The_new_password_is_repeated: 'The new password should be different with old password',
        Duplicate_Entry_Error: 'The name is already registered. Please try another name.',
        Payment_Error_b01: 'There is a problem with this transaction. Please contact Jarvix Pay at 3618 7750 or 6922 5068. [Code: b01]',
        Payment_Error_r01: 'There is a problem with this transaction. Please contact Jarvix Pay at 3618 7750 or 6922 5068. [Code: r01]',
        // upload documents
        "Front:_We_don't_currently_support_that_file_type_Try_uploading_a_file_with_one_of_the_following_mimetypes:_image/jpeg,_image/png":
            "Front: We don't currently support that file type. Try uploading a file with one of the following mimetypes: image/jpeg, image/png",
        "Back:_We_don't_currently_support_that_file_type_Try_uploading_a_file_with_one_of_the_following_mimetypes:_image/jpeg,_image/png":
            "Back: We don't currently support that file type. Try uploading a file with one of the following mimetypes: image/jpeg, image/png",
    },
    LAP_PAY_ERROR_MSG: {
        exceed_daily_trx_limit: 'Exceed daily transaction limit.',
        exceed_monthly_trx_limit: 'Exceed monthly transaction limit.',
        exceed_daily_and_monthly_trx_limit: 'Exceed daily and monthly transaction limit.',
        refund_rejected_after_100_days: 'Refund is rejected after 100 days。',
        qr_code_expired: 'QR code is expired.',
        insufficient_balance: 'Insufficient Balance.',
        unsupported_card_type: 'Unspported card type.',
        unsupported_payment_type: 'Unspported payment type',
        'user_paying, please_input_password': 'User paying, please input password',
        unable_to_pay_at_overseas_merchants: 'Due to payment policy, non-real name account is unable to pay overseas.',
        user_paying: 'User playing.',
        internal_server_error: 'Internal Server Error. Please contact JARVIX PAY. [Code: w00]',
        payment_gateway_error: 'Internal Server Error. Please contact JARVIX PAY. [Code: w01]',
    },
    ERROR_TYPE: {
        unknown_error: 'Sorry! Unknown error and please try again. [Code: u00]',
        invalid_param_error: 'Internal Server Error. Please try again. [Code: i00]',
        database_error: 'Internal Server Error. Please try again. [Code: d00]',
        database_content_not_found: 'Internal Server Error. Please try again. [Code: d01]',
        passport_error: 'Invalid User Error. Please try again.  [Code p00]',
        unexpected_error: 'Internal Server Error. Please try again. [Code: u01]',
        permission_error: 'Your permission is not supported. Please contact to your manager or Jarvix Pay. [Code: p01]',
        duplicated_campaign_error: 'Campaign Name is duplicated. Please use another name.',
        oversold_error: 'Promotion items are sold out.',
    },
    STRIPE_CODE: {
        customer_canceled_authentication: 'You must authenticate to complete this transaction.',
        expired_card: 'Your card is expired.',
        incomplete_card: 'Your card is incomplete.',
        incomplete_cvc: 'Your CVC is incomplete.',
        incomplete_expiry: 'Your card expiry date is incomplete.',
        incomplete_iban: 'Your IBAN is incomplete.',
        incomplete_number: 'Your card number is incomplete.',
        incomplete_zip: 'Your zip number is incomplete.',
        incorrect_cvc: 'Your CVC is incorrect.',
        incorrect_number: 'Your credit card number is incomplete.',
        invalid_expiry_month: 'The card’s expiration month is invalid',
        invalid_expiry_month_past: 'The card’s expiration month is invalid',
        invalid_expiry_year: 'The card’s expiration year is invalid',
        invalid_expiry_year_past: 'The card’s expiration year is invalid',
        invalid_iban: 'Your IBAN is invalid',
        invalid_iban_country_code: 'The country code of the entered IBAN is invalid.',
        invalid_iban_start: 'Your IBAN should start with a two-letter country code',
        invalid_number: 'The card number is not a valid credit card number',
        processing_error: 'An error occurred while processing the card.',
        process_error_intransient: 'An error occurred while processing your card.',
        token_already_used: 'You cannot use a token more than once.',
        unexpected: 'Unexpected Error.',
        not_accepted: 'Sorry! Your credit card will not be accepted, please contact us.',
        account_invalid: 'Internal Server Error. Please contact Jarvix Pay. [Code: s01]', // stripeId
        resource_missing: 'Internal Server Error. Please contact Jarvix Pay. [Code: s02]',
    },
    STRIPE_DECLINE_CODE: {
        authentication_required: 'The card was declined as the transaction requires authentication.',
        approve_with_id: 'The payment cannot be authorized.	',
        call_issuer: 'The card has been declined for an unknown reason.	',
        card_not_supported: 'The card does not support this type of purchase.	',
        card_velocity_exceeded: 'The customer has exceeded the balance or credit limit available on their card.	',
        currency_not_supported: 'The card does not support the specified currency.	',
        do_not_honor: 'The card has been declined for an unknown reason.	',
        do_not_try_again: 'The card has been declined for an unknown reason.',
        duplicate_transaction: 'A transaction with identical amount and credit card information was submitted very recently.',
        expired_card: 'The card has expired.',
        fraudulent: 'The payment has been declined as Stripe suspects it is fraudulent.',
        fake_merchant_test_card: 'Your card was declined. Your request was in live mode, but used a test card.',
        generic_decline: 'The card has been declined for an unknown reason.',
        incorrect_cvc: 'The CVC number is incorrect.',
        incorrect_number: 'The card number is incorrect.',
        invalid_expiry_month: 'The card’s expiration month is invalid.',
        invalid_expiry_month_past: 'The card’s expiration year is invalid',
        incorrect_pin: 'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.',
        insufficient_funds: 'The card has insufficient funds to complete the purchase.',
        invalid_pin: 'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.	',
        invalid_account: 'The card, or account the card is connected to, is invalid.',
        invalid_amount: 'The payment amount is invalid, or exceeds the amount that is allowed.',
        issuer_not_available: 'The card issuer could not be reached, so the payment could not be authorized.',
        lost_card: 'The payment has been declined because the card is reported lost.',
        live_mode_test_card: 'Your card was declined. Your request was in live mode, but used a test card.',
        merchant_blacklist: "The payment has been declined because it matches a value on the Stripe user's block list.",
        new_account_information_available: 'The card, or account the card is connected to, is invalid.	',
        no_action_taken: 'The card has been declined for an unknown reason.	',
        not_permitted: 'The payment is not permitted.',
        pickup_card: 'The card cannot be used to make this payment (it is possible it has been reported lost or stolen).',
        stolen_card: 'The payment has been declined because the card is reported stolen.',
        processing_error: 'An error occurred while processing the card.',
        restricted_card: 'The card cannot be used to make this payment (it is possible it has been reported lost or stolen).',
        revocation_of_all_authorizations: 'The card has been declined for an unknown reason.',
        revocation_of_authorization: 'The card has been declined for an unknown reason.',
        security_violation: 'The card has been declined for an unknown reason.',
        service_not_allowed: 'The card has been declined for an unknown reason.',
        stop_payment_order: 'The card has been declined for an unknown reason.',
        pin_try_exceeded: 'The allowable number of PIN tries has been exceeded.',
        test_mode_live_card: 'Your card was declined. Your request was in test mode, but used a non test card.',
        transaction_not_allowed: 'The card has been declined for an unknown reason.',
        your_card_was_declined: 'Sorry ! Your card was declined, please try another card.',
        not_accepted: 'Sorry! Your credit card will not be accepted, please contact us.',
    },
    USER_INPUT_ERRORS: {
        please_select_at_least_one_item: 'Please select at least one item',
        please_enter_a_customer_name: 'Please enter a customer name',
        please_enter_the_correct_mobile_phone_number: 'Please enter the correct mobile phone number',
        please_enter_the_customer_age: 'Please enter the customer age',
        please_enter_the_customer_address: 'Please enter the customer address',
        please_select_a_branch: 'Please select a branch',
        please_enter_customer_gender: 'Please enter customer gender',
        please_enter_customer_email: 'Please enter customer email',
        please_enter_a_reservation_date: 'Please enter a reservation date',
        the_system_does_not_allow_selection_of_past_day: 'the system does not allow selection of past day',
        please_enter_a_customer_inquiry: 'Please enter a customer inquiry. If not, please fill in N/A',
        please_answer_all_mc_questions: 'Please answer all multiple choice questions',
    },
};
