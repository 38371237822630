var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "virtual-credit-card",
      class: { "virtual-credit-card--twosides": _vm.twosides }
    },
    [
      _c("div", { staticClass: "virtual-credit-card__card" }, [
        _c(
          "div",
          {
            staticClass:
              "virtual-credit-card__inner virtual-credit-card__inner--face"
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "500",
                  height: "309",
                  viewBox: "0 0 500 309"
                }
              },
              [
                _c(
                  "defs",
                  [
                    _c("clipPath", { attrs: { id: "a" } }, [
                      _c("path", {
                        attrs: { d: "M308.953 309H500V0H130.551z" }
                      })
                    ]),
                    _c(
                      "linearGradient",
                      {
                        attrs: {
                          id: "b",
                          x1: ".5",
                          y1: "1",
                          x2: ".5",
                          gradientUnits: "objectBoundingBox"
                        }
                      },
                      [
                        _c("stop", {
                          attrs: {
                            offset: "0",
                            "stop-color": "#faf7f7",
                            "stop-opacity": ".051"
                          }
                        }),
                        _c("stop", {
                          attrs: {
                            offset: "1",
                            "stop-color": "#fff",
                            "stop-opacity": ".314"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("path", {
                  attrs: {
                    d:
                      "M480 309H20a20 20 0 0 1-20-20V20A20 20 0 0 1 20 0h460a20 20 0 0 1 20 20v269a20 20 0 0 1-20 20z",
                    fill: "#626262"
                  }
                }),
                _c("g", { attrs: { "clip-path": "url(#a)" } }, [
                  _c("path", {
                    attrs: {
                      d:
                        "M480 309H20a20 20 0 0 1-20-20V20A20 20 0 0 1 20 0h460a20 20 0 0 1 20 20v269a20 20 0 0 1-20 20z",
                      fill: "url(#b)"
                    }
                  })
                ]),
                _c("path", {
                  attrs: {
                    d:
                      "M112 148H68a9 9 0 0 1-9-9v-26a9 9 0 0 1 9-9h44a9 9 0 0 1 9 9v26a9 9 0 0 1-9 9z",
                    fill: "#d8b76c"
                  }
                }),
                _c("text", { attrs: { transform: "translate(55 203)" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.twosides
                        ? "8888 8888 8888 8888"
                        : "**** **** **** " + (_vm.last4 || "****")
                    )
                  )
                ]),
                _c(
                  "text",
                  {
                    attrs: {
                      transform:
                        "translate(" +
                        (_vm.twosides ? "56 274" : "56 268") +
                        ")"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.twosides ? "CHAN TAI MAN" : _vm.maskName || "****"
                      )
                    )
                  ]
                ),
                _c(
                  "text",
                  {
                    attrs: {
                      transform:
                        "translate(" +
                        (_vm.twosides ? "230 245" : "361 268") +
                        ")"
                    }
                  },
                  [_vm._v(_vm._s(_vm.twosides ? "88/88" : _vm.exp || "**/**"))]
                )
              ]
            )
          ]
        ),
        _vm.twosides
          ? _c(
              "div",
              {
                staticClass:
                  "virtual-credit-card__inner virtual-credit-card__inner--back"
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "500",
                      height: "309",
                      viewBox: "0 0 500 309"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M480 309H20a20 20 0 0 1-20-20V20A20 20 0 0 1 20 0h460a20 20 0 0 1 20 20v269a20 20 0 0 1-20 20z",
                        fill: "#626262"
                      }
                    }),
                    _c("path", {
                      attrs: { d: "M303 137h53v34h-53z", fill: "#fff" }
                    }),
                    _c("path", {
                      attrs: { d: "M24 128h279v51H24z", fill: "#a8a8a8" }
                    }),
                    _c("path", {
                      attrs: { d: "M0 28h500v76H0z", fill: "#111" }
                    }),
                    _c("circle", {
                      attrs: {
                        cx: "45",
                        cy: "45",
                        r: "42",
                        transform: "translate(285 109)",
                        stroke: "#eb4545",
                        "stroke-width": "6",
                        fill: "none"
                      }
                    })
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }