























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';
import Navbar from '@/components/Navbar.vue';
import Topbar from '@/components/Topbar.vue';
import MerchantTopbar from '@/components/MerchantTopbar.vue';
import LogoutDialog from '@/components/LogoutDialog.vue';
import ErrorDialog from '@/components/ErrorDialog.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import ScrollBtn from '@/components/ScrollBtn.vue';
import { sleep, stripe } from '@/store';
import { scrollTop, isTop } from '@/utils/scrollTop';
import { isPlatform } from '@/utils/platform';
import { Capacitor } from '@capacitor/core/dist/esm/definitions';
import { SplashScreenPlugin } from '@capacitor/core/dist/esm/core-plugin-definitions';

@Component({
    components: {
        Loading,
        Navbar,
        Topbar,
        MerchantTopbar,
        LogoutDialog,
        ErrorDialog,
        ConfirmDialog,
        ScrollBtn,
    },
})
export default class APP extends Vue {
    // private keepAliveExcludes = ['Receipt', 'History'];
    //private keepAliveIncludes = ['Payment'];

    private lastRoute?: string;

    private onNavbarItemClicked(name: string) {
        if (name !== this.lastRoute) return (this.lastRoute = name);
        if (!isTop()) {
            return scrollTop(true);
        }
        if (name === 'history' && name === this.lastRoute) {
            (this.$refs.routerView as any).fetchItems && (this.$refs.routerView as any).fetchItems();
        }
    }
    public beforeCreate() {
        this.$root.$once('route-change', ({ to }: any) => {
            this.lastRoute = to.name;
        });
    }
    public async mounted() {
        let loadingScreen: HTMLElement = document.getElementById('overlay--app-loading') as HTMLElement;

        await new Promise(res => window.addEventListener('load', res));

        if (isPlatform(window, 'capacitor')) {
            // check for window.Capacitor
            const Capacitor: Capacitor | undefined = (window as any).Capacitor;
            if (!(Capacitor && Capacitor.Plugins)) {
                return;
            }

            // import SplashScreen Plugin
            const { SplashScreen } = Capacitor.Plugins;

            loadingScreen && loadingScreen.remove();
            await sleep(350);
            SplashScreen.hide();
            this.$i18n.locale = await (this.$store.state.user && this.$store.state.user.languageCode
                ? this.$store.state.user.languageCode
                : 'en');
        } else {
            try {
                // await stripe;
                // await (async () => {
                //     if ((window as any).await_stripe_fully_loaded) {
                //         await (window as any).await_stripe_fully_loaded;
                //     }
                //     // console.log('stripe loaded');
                // })();
            } catch (e) {
                console.error('stripe cant load!');
            }

            if (loadingScreen) {
                //await sleep(3000);
                loadingScreen.style.opacity = '0';
                loadingScreen.style.pointerEvents = 'none';
                await sleep(300);
                loadingScreen.remove();
            }
            this.$i18n.locale = await (this.$store.state.user && this.$store.state.user.languageCode
                ? this.$store.state.user.languageCode
                : 'en');

            if (this.$i18n.locale == 'zh_HK') {
                (self as any).zE('webWidget', 'setLocale', 'zh_TW');
            } else {
                (self as any).zE('webWidget', 'setLocale', 'en-us');
            }

            if (this.$router.currentRoute.name == 'campaign-link' || this.$router.currentRoute.name == 'remote-link') {
                this.$i18n.locale = 'zh_HK';
            }
        }
    }
    get keepAliveIncludes() {
        return this.$store.state.keepAliveIncludes;
    }

    get isLoading() {
        return this.$store.state.isLoading;
    }

    get showNavbar() {
        return this.$store.state.showNavbar;
    }

    get showTopbar() {
        return this.$store.state.showTopbar;
    }

    get showMerchantTopbar() {
        return this.$store.state.showMerchantTopbar;
    }

    get showScrollBtn() {
        return this.$store.state.showScrollBtn;
    }
}
