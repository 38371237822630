var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payment" }, [
    _c("div", { staticClass: "app-page app-page-payment app-page--active" }, [
      _c(
        "div",
        { staticClass: "app-page-container" },
        [
          _c("div", { staticClass: "test mdc-layout-grid lang--zh-HK" }, [
            _c("div", { staticClass: "mdc-layout-grid__inner" }, [
              _c(
                "div",
                {
                  staticClass:
                    "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet tab-payment-type__wrapper"
                },
                [
                  _c(
                    "mdc-tab-bar",
                    {
                      ref: "mdcTabBar",
                      staticClass: "tab-payment-type",
                      on: { change: _vm.onTabUpdated }
                    },
                    [
                      _c("mdc-tab", { attrs: { active: "" } }, [
                        _c("div", { staticClass: "mdc-tab__label" }, [
                          _c(
                            "svg",
                            {
                              staticClass: "mdc-tab__icon",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 50 50"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M39 38H11c-1.7 0-3-1.3-3-3V15c0-1.7 1.3-3 3-3h28c1.7 0 3 1.3 3 3v20c0 1.7-1.3 3-3 3zM11 14c-.6 0-1 .4-1 1v20c0 .6.4 1 1 1h28c.6 0 1-.4 1-1V15c0-.6-.4-1-1-1H11z",
                                  fill: "#626262"
                                }
                              }),
                              _c("path", {
                                attrs: { d: "M9 16h32v6H9z", fill: "#626262" }
                              }),
                              _c("path", {
                                attrs: { d: "M12 26h1v2h-1z", fill: "#626262" }
                              }),
                              _c("path", {
                                attrs: { d: "M14 26h1v2h-1z", fill: "#626262" }
                              }),
                              _c("path", {
                                attrs: { d: "M16 26h1v2h-1z", fill: "#626262" }
                              }),
                              _c("path", {
                                attrs: { d: "M19 26h1v2h-1z", fill: "#626262" }
                              }),
                              _c("path", {
                                attrs: { d: "M21 26h1v2h-1z", fill: "#626262" }
                              }),
                              _c("path", {
                                attrs: { d: "M23 26h1v2h-1z", fill: "#626262" }
                              }),
                              _c("path", {
                                attrs: { d: "M26 26h1v2h-1z", fill: "#626262" }
                              }),
                              _c("path", {
                                attrs: { d: "M28 26h1v2h-1z", fill: "#626262" }
                              }),
                              _c("path", {
                                attrs: { d: "M30 26h1v2h-1z", fill: "#626262" }
                              }),
                              _c("path", {
                                attrs: { d: "M33 26h1v2h-1z", fill: "#626262" }
                              }),
                              _c("path", {
                                attrs: { d: "M35 26h1v2h-1z", fill: "#626262" }
                              }),
                              _c("path", {
                                attrs: { d: "M37 26h1v2h-1z", fill: "#626262" }
                              })
                            ]
                          ),
                          _c("span", [_vm._v("信用卡收款")])
                        ])
                      ]),
                      _c("mdc-tab", [
                        _c("div", { staticClass: "mdc-tab__label" }, [
                          _c(
                            "svg",
                            {
                              staticClass: "mdc-tab__icon",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewbox: "0 0 24 24"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M23.541 12.748c-.609-1.38-1.758-2.476-3.092-3.151-2.354-1.192-5.281-1.185-7.629.03-1.631.837-2.993 2.337-3.379 4.162-.318 1.344-.033 2.791.68 3.961 1.061 1.762 2.979 2.887 4.971 3.248 1.443.293 2.936.119 4.338-.285.842.326 1.592.854 2.408 1.246a52.471 52.471 0 0 0-.676-2.102c.916-.65 1.746-1.461 2.244-2.479.744-1.415.789-3.171.135-4.63zm-9.924-9.466c-2.495-1.404-5.602-1.615-8.286-.645-1.764.635-3.36 1.815-4.346 3.42-.895 1.45-1.23 3.258-.799 4.917.433 1.84 1.711 3.383 3.262 4.413-.3.85-.585 1.699-.855 2.555.975-.51 1.95-1.043 2.926-1.561 1.17.375 2.415.559 3.66.518a6.159 6.159 0 0 1-.255-2.951c.225-1.371.975-2.625 1.994-3.554 1.726-1.615 4.171-2.296 6.496-2.131-.436-2.135-1.936-3.939-3.824-4.98h.027zm1.733 9.989c-.209.652-1.156.848-1.615.352-.506-.459-.309-1.418.355-1.623.734-.31 1.582.537 1.26 1.271zm4.795.092c-.256.586-1.141.723-1.576.27-.209-.191-.27-.479-.344-.73.104-.458.42-.933.93-.955.705-.098 1.336.773.975 1.416h.015zM12.99 6.909c.008.961-1.275 1.561-1.995.909-.747-.535-.535-1.837.342-2.106.785-.315 1.713.344 1.651 1.185l.002.012zm-6.059.244c-.172.835-1.291 1.238-1.946.678-.759-.535-.546-1.861.345-2.131.873-.336 1.865.55 1.601 1.453z",
                                  fill: "#626262"
                                }
                              })
                            ]
                          ),
                          _c("span", [_vm._v("微信收款")])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _c("CreditCard", {
            staticClass:
              "activity-payment-card bottom-nav--hidden app-page-activity--active zero",
            attrs: { display: "card" }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }