import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import localForage from 'localforage';
import moment_HK from '@/utils/moment_HK_zh';
import e_at from '@/utils/catch_error_at';
import { getResObj, getErrObj, standardMethodResponse } from '@/utils/helpers/formatResponse';
import { isPlatform } from '@/utils/platform';
import { i18n } from './plugins/i18n';

const STRIPE_KEY = process.env.VUE_APP_STRIPE_KEY || '';
const apiURL = process.env.VUE_APP_API_BASE_URI;

Vue.use(Vuex);

axios.defaults.baseURL = apiURL;
axios.defaults.withCredentials = true;

const sleep = (ms: number) => new Promise(res => setTimeout(res, ms));

const stripe = (async () => {
    const Stripe = await (async () => {
        if ((window as any).await_stripe) {
            await (window as any).await_stripe;
        } else {
            while (!(window as any).Stripe) {
                await sleep(100);
            }
        }
        return (window as any).Stripe;
    })();
    return await Stripe(STRIPE_KEY);
})();

const webrtcAdapter = (async () => {
    if ((window as any).await_webrtcAdapter) {
        await (window as any).await_webrtcAdapter;
    } else {
        while (!(window as any).adapter) {
            await sleep(100);
        }
    }
    return (window as any).adapter;
})();

const localStoreRegular = localForage.createInstance({ name: 'jarvixStoreRegular' });

try {
    (function(type: string, name: string, obj?: any) {
        obj = obj || window;
        var running = false;
        var func = function() {
            if (running) {
                return;
            }
            running = true;
            requestAnimationFrame(function() {
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };
        obj.addEventListener(type, func);
    })('resize', 'optimizedResize');
} catch (error) {}

try {
    (function(type: string, name: string, obj?: any) {
        obj = obj || window;
        var running = false;
        var func = function() {
            if (running) {
                return;
            }
            running = true;
            requestAnimationFrame(function() {
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };
        obj.addEventListener(type, func);
    })('resize', 'optimizedResize');
} catch (error) {}

const vueStore = new Vuex.Store({
    state: {
        isLoading: false,
        display: 'amount',
        showNavbar: true,
        showTopbar: false,
        showMerchantTopbar: false,
        showNotificationCenter: false,
        showScrollBtn: false,
        loggedIn: undefined,
        user: undefined,
        deviceToken: '',
        fxRates: {},
        printerAddr: '',
        paymentKey: true,
        qrPaymentMethod: '',
        keepAliveIncludes: {
            // routerView: ['Payment'],
            routerView: [],
        },
        apiURL,

        // stripe related
        stripe,
        amount: 0,
        description: '',
        token: {
            id: '',
            holder_name: '',
            exp_month: '',
            exp_year: '',
            last4: '',
        },
        notificationMessage: {
            id: '',
            message: {
                notification: {
                    title: '',
                    body: '',
                },
                data: {
                    topic: '',
                    id: '',
                    date: '',
                },
            },
        },
        notificationMessages: [],

        // signup use
        signUp: {
            company: {
                businessName: '',
                businessType: '',
                businessBR: '',
                businessCI: '',
                statementDescriptor: '',
                address: '',
                phone_country: '',
                phone_country_code: '',
                phone: '',
                city: '',
                state: '',
                businessDescription: '',
                businessWeb: '',
            },
            owners: [
                {
                    id: 1,
                    firstName: '',
                    lastName: '',
                    identityType: '',
                    identityNumber: '',
                    dob: '',
                    countryCode: {},
                    phone: '',
                    address: '',
                    city: '',
                    state: '',
                },
            ],
            externalAccount: {
                holderName: '',
                holderType: '',
                bank: null,
                branchCode: '',
                accountNumber: '',
                country: '',
                currency: '',
            },
            documentUpload: {
                brFile: {},
                ciFile: {},
                owners: {
                    identityFront: new Array(),
                    identityBack: new Array(),
                    address: new Array(),
                },
                bank: {},
                others: [],
            },
        },
        countryCodeList: [],
    },

    getters: {
        isMaster: state => {
            try {
                return !!(state.user as any).permissions_.master;
            } catch (e) {
                return false;
            }
        },
        permissions: state => {
            try {
                return (state.user as any).permissions_;
            } catch (e) {
                return {};
            }
        },
    },

    mutations: {
        loggedIn(state, loggedIn: boolean) {
            (state.loggedIn as any) = loggedIn;
        },

        user(state, val) {
            state.user = val;
        },

        fxRates(state, val) {
            state.fxRates = val;
        },

        printerAddr(state, val) {
            state.printerAddr = val;
        },

        deviceToken(state, val) {
            state.deviceToken = val;
        },

        isLoading(state, payload: boolean) {
            state.isLoading = payload;
            document.body.style.overflow = state.isLoading ? 'hidden' : 'auto';
        },

        reloadPaymentPage(state) {
            state.paymentKey = !state.paymentKey;
        },

        show(state, val) {
            state.display = val;
        },

        showNavbar(state, val) {
            state.showNavbar = val;
        },

        showTopbar(state, payload: boolean) {
            state.showTopbar = payload;
        },

        showMerchantTopbar(state, val) {
            state.showMerchantTopbar = val;
        },

        showNotificationCenter(state, val) {
            state.showNotificationCenter = val;
        },
        showScrollBtn(state, val) {
            state.showScrollBtn = val;
        },
        storeAmount(state, val) {
            state.amount = val;
        },

        setDescription(state, description) {
            state.description = description;
        },

        setNotificationMessages(state, messages) {
            state.notificationMessages = messages;
        },

        storeToken(state, token) {
            state.token = token;
            // console.log('state.token:', state.token);
        },
        changeCaptureMode(state, payload) {
            // (state.user as any).capture = payload.capture;
            (state.user as any).capture_mode = payload.capture_mode;
        },

        // sign up
        saveCompanyData(state, payload) {
            state.signUp.company = payload;
        },

        saveOwnersData(state, payload) {
            state.signUp.owners = payload;
        },

        saveExternalAccountData(state, payload) {
            state.signUp.externalAccount = payload;
        },

        saveDocumentUploadData(state, payload) {
            state.signUp.documentUpload = payload;
        },

        retrieveSignUpData(state, payload) {
            state.signUp.company = payload.company;
            state.signUp.owners = payload.owners;
            state.signUp.externalAccount = payload.externalAccount;
        },
        resetSignUp(state, context) {
            state.signUp = {
                company: {
                    businessName: '',
                    businessType: '',
                    businessBR: '',
                    businessCI: '',
                    statementDescriptor: '',
                    address: '',
                    phone_country: '',
                    phone_country_code: '',
                    phone: '',
                    city: '',
                    state: '',
                    businessDescription: '',
                    businessWeb: '',
                },
                owners: [
                    {
                        id: 1,
                        firstName: '',
                        lastName: '',
                        identityType: '',
                        identityNumber: '',
                        countryCode: {},
                        phone: '',
                        dob: '',
                        address: '',
                        city: '',
                        state: '',
                    },
                ],
                externalAccount: {
                    holderName: '',
                    holderType: '',
                    bank: null,
                    branchCode: '',
                    accountNumber: '',
                    country: '',
                    currency: '',
                },
                documentUpload: {
                    brFile: {},
                    ciFile: {},
                    owners: {
                        identityFront: new Array(),
                        identityBack: new Array(),
                        address: new Array(),
                    },
                    bank: {},
                    others: [],
                },
            };
        },
        saveCountryCodeList(state, payload) {
            state.countryCodeList = payload;
        },

        setQRPaymentMethod(state, payload) {
            state.qrPaymentMethod = payload;
        },
    },

    actions: {
        async checkLogin(context) {
            if (typeof context.state.loggedIn === 'undefined') {
                context.commit('isLoading', true);
                try {
                    let response;

                    if (isPlatform(window, 'hybrid')) {
                        response = await axios.get('/account/?sacid=true');
                    } else {
                        response = await axios.get('/account');
                    }

                    const res = getResObj(response);

                    context.commit('loggedIn', true);
                    context.commit('user', res.user);
                    context.commit('fxRates', res.fxRates);

                    if (isPlatform(window, 'capacitor')) {
                        const { JPAYCardInput } = (window as any).Capacitor.Plugins;
                        i18n.locale = await (res.user && res.user.languageCode ? res.user.languageCode : 'en');
                        await JPAYCardInput.initialize({ stripe_publishable_key: process.env.VUE_APP_STRIPE_KEY });
                    }
                } catch (error) {
                    context.commit('loggedIn', false);
                    context.commit('user', undefined);
                    throw error;
                } finally {
                    context.commit('isLoading', false);
                }
            }
            return context.state.loggedIn;
        },

        async updateUser(context) {
            let response;

            if (isPlatform(window, 'hybrid')) {
                response = await axios.get('/account/?sacid=true');
            } else {
                response = await axios.get('/account');
            }

            const res = getResObj(response);

            context.commit('user', res.user);
            context.commit('fxRates', res.fxRates);
        },

        async printerAddr(context, value?: string) {
            if (!value) {
                if (!context.state.printerAddr) {
                    context.state.printerAddr = ((await localStoreRegular.getItem('printerAddr')) as string) || '';
                }
                return context.state.printerAddr;
            } else {
                await localStoreRegular.setItem('printerAddr', value);
                context.commit('printerAddr', String(value));
            }
        },

        async checkPrinterStatus(context, timeout = 10000) {
            try {
                if (!context.state.printerAddr) throw new Error('no_printer');
                return await axios.request({
                    url: context.state.printerAddr + '/print',
                    timeout,
                });
            } catch (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code that falls
                    // out of the range of 2xx
                    /* console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers); */
                    if (error.response.data && error.response.data.status) {
                        throw new Error(error.response.data.status);
                    } else {
                        throw new Error('printer_server_offline');
                    }
                } else if (error.request) {
                    // The request was made but no response was received `error.request` is an
                    // instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    throw new Error('printer_server_offline');
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                    throw error;
                }
            }
        },

        async printReceipt(context, { printBuffer, receiptData }: { printBuffer?: ArrayBuffer; receiptData: string }) {
            try {
                if (!(printBuffer || receiptData)) {
                    throw new Error('Not enough params');
                }
                let requestConfig = {
                    url: context.state.printerAddr + '/print',
                    method: 'post',
                    timeout: 20000,
                };
                printBuffer &&
                    Object.assign(requestConfig, {
                        headers: {
                            'Content-Type': 'application/octet-stream',
                        },
                        data: new Blob([printBuffer], { type: 'application/octet-stream' }),
                    });
                receiptData &&
                    Object.assign(requestConfig, {
                        data: receiptData,
                    });
                await context.dispatch('checkPrinterStatus', 20000).catch(e_at('checkPrinterStatus'));
                return await axios.request(requestConfig).catch(e_at('printReceipt'));
            } catch (error) {
                throw error;
            }
        },
        // push notification related
        async saveDeviceToken(context, payload) {
            if (payload.device_token != null || undefined) {
                const device_token: string = payload.device_token;
                // console.log('vuex sotre -- saveDeviceToken:', device_token);
                context.commit('isLoading', true);
                const method = axios.post('/account/deviceToken', {
                    device_token: device_token,
                });
                return standardMethodResponse(method, context);
            }
        },

        async removeDeviceToken(context, payload) {
            if (payload.device_token != null || undefined) {
                context.commit('isLoading', true);
                const method = axios.delete(`/account/${payload.device_token}`);
                return standardMethodResponse(method, context);
            }
        },

        deviceToken(context, payload) {
            context.commit('deviceToken', payload);
        },

        showNotificationCenter(context) {
            const isShow = !context.state.showNotificationCenter;
            context.commit('showNotificationCenter', isShow);
        },

        async getNotificationMessages(context) {
            const method = axios.get(`/notification/messages`);
            const smethod = await standardMethodResponse(method, context);
            // context.commit('setNotificationMessages', res);
            context.commit('setNotificationMessages', smethod);

            // console.log('Notification Messages in store:', context.state.notificationMessages);
            return smethod;
        },

        async removeNotificationMessage(context, payload) {
            if (payload.id != null || undefined) {
                // console.log('id:', payload.id);
                context.commit('isLoading', true);
                const method = axios.delete(`/notification/message/${payload.id}`);
                standardMethodResponse(method, context);
            }
            const method = axios.get(`/notification/messages`);
            const smethod = await standardMethodResponse(method, context);
            return smethod;
        },

        async removeAllNotificationMessages(context) {
            context.commit('setNotificationMessages', []);
            axios.delete(`/notification/messages`);
            // const method = await axios.delete(`/notification/messages`);
            // return method;
            // const method_get = axios.get(`/notification/messages`);
            // const smethod = await standardMethodResponse(method_get, context);
            // return standardMethodResponse(method, context);
        },

        // platform-related functions
        login(context, credentials) {
            context.commit('isLoading', true);
            return axios
                .post('/auth/login', {
                    email: credentials.email,
                    password: credentials.password,
                })
                .then((response: any) => {
                    const res = getResObj(response);
                    window.localStorage.setItem('loggedIn', (new Date().getTime() + 6 * 60 * 60 * 1000).toString());
                    context.commit('loggedIn', true);
                    context.commit('user', res.user);
                    context.commit('fxRates', res.fxRates);
                    context.commit('isLoading', false);
                    return res;
                })
                .catch((error: any) => {
                    const err = getErrObj(error);
                    window.localStorage.removeItem('loggedIn');
                    context.commit('isLoading', false);
                    throw err;
                });
        },

        async logout(context) {
            context.commit('isLoading', true);
            try {
                const response = await axios.get('/auth/logout');
                const res = getResObj(response);
                window.localStorage.removeItem('loggedIn');
                context.commit('loggedIn', false);
                context.commit('user', undefined);
                context.commit('resetSignUp');
                return res;
            } catch (error) {
                const err = getErrObj(error);
                throw err;
            } finally {
                context.commit('isLoading', false);
            }
        },

        register(context, accountInformation) {
            context.commit('isLoading', true);

            const method = axios.post('/auth/signup', {
                lang: accountInformation.lang,
                companyName: accountInformation.companyName,
                email: accountInformation.email,
                password: accountInformation.password,
                country: accountInformation.country,
                countryCode: accountInformation.countryCode,
                phone: accountInformation.phone,
            });

            return standardMethodResponse(method, context);
        },

        // not implemented
        subscribe(
            context,
            subscribeInformation: {
                source: string;
                coupon?: string;
            }
        ) {
            context.commit('isLoading', true);
            return new Promise((resolve, reject) => {
                axios
                    .post('/account/subscribe', subscribeInformation)
                    .then((response: any) => {
                        resolve(response);
                    })
                    .catch(e_at('axios'))
                    .catch((error: any) => {
                        reject(error);
                    });
            });
        },

        activate(context, token) {
            context.commit('isLoading', true);

            const method = axios.post('/account/activation', { code: token });

            return standardMethodResponse(method, context);
        },

        waiveSubscription(context, token) {
            context.commit('isLoading', true);

            const method = axios.post('/account/waive_subscription', { code: token });

            return standardMethodResponse(method, context);
        },

        // stripe-related functions
        async createToken(context, payload) {
            context.commit('isLoading', true);
            const method = (await stripe).createToken(payload.purpose, payload.tokenInformation);
            return standardMethodResponse(method, context);
        },

        async createSource(context, payload) {
            context.commit('isLoading', true);
            return (await stripe)
                .createSource(payload.source, payload.sourceData)
                .then((response: any) => {
                    if (response.error) {
                        context.commit('isLoading', false);
                        throw response.error.message;
                    }

                    context.commit('isLoading', false);
                    return response;
                })
                .catch((error: any) => {
                    context.commit('isLoading', false);
                    throw error;
                });
        },

        // not implemented
        async uploadDocument(context, payload) {
            return new Promise(async (resolve, reject) => {
                const data: FormData = new FormData();
                let response: Response;
                let uploadedData: any;

                context.commit('isLoading', true);
                data.append('file', payload.document);
                data.append('purpose', payload.purpose);

                response = await fetch('https://uploads.stripe.com/v1/files', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${(await stripe)._apiKey}`,
                    },
                    body: data,
                });

                uploadedData = await response.json();
                context.commit('isLoading', false);

                if (uploadedData.error) {
                    reject(getErrObj(uploadedData.error));
                }

                resolve(uploadedData);
            });
        },

        updateStripeAccount(context, payload) {
            context.commit('isLoading', true);
            const method = axios.patch('/payment/stripe_account', {
                nextPage: payload.nextPage,
                token: payload.token,
            });

            return standardMethodResponse(method, context);
        },

        updateExternalAccount(context, token) {
            context.commit('isLoading', true);

            const method = axios.post('/payment/external_bank_account', { token });

            return standardMethodResponse(method, context);
        },

        submitInformation({ dispatch }, payload) {
            return new Promise((resolve, reject) => {
                dispatch('createToken', payload)
                    .then((response: any) => {
                        const token = response.token.id;

                        if (payload.purpose === 'account') {
                            return dispatch('updateStripeAccount', {
                                nextPage: payload.nextPage,
                                token: token,
                            });
                        }

                        if (payload.purpose === 'bank_account') {
                            return dispatch('updateExternalAccount', token);
                        }

                        return;
                    })
                    .then((response: any) => resolve(response))
                    .catch((error: any) => reject(error));
            });
        },

        charge(context, payload) {
            context.commit('isLoading', true);
            const method = axios.post('/payment/charge', {
                amount: payload.amount,
                currency: payload.currency,
                sourceToken: payload.token,
                tokenizingMethod: payload.tokenizingMethod,
                description: payload.description,
            });

            return standardMethodResponse(method, context);
        },

        recurringCharge(context, payload) {
            context.commit('isLoading', true);
            const method = axios.post('/payment/recurrence', {
                customer: payload.customer,
                charge: payload.charge,
                recurrenceInfo: payload.recurrenceInfo,
                sourceToken: payload.sourceToken,
                tokenizingMethod: payload.tokenizingMethod,
            });

            return standardMethodResponse(method, context);
        },

        refund(context, payload) {
            const method = axios.post('/payment/refund', {
                chargeId: payload.chargeId,
                reason: payload.reason,
            });

            return standardMethodResponse(method, context);
        },

        captureAll(context) {
            const method = axios.post('/payment/capture/all');

            return standardMethodResponse(method, context);
        },

        getUncapturedCharges(context) {
            const method = axios.get('payment/capture/uncaptured-info');

            return standardMethodResponse(method, context);
        },

        changeCaptureMode(context, payload) {
            const method = axios.patch('payment/capture/change-mode', {
                // capture: payload.capture,
                captureMode: payload.captureMode,
            });

            return standardMethodResponse(method, context);
        },

        // database related
        getCharges(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(
                `/payment/charges?timestamp=${payload.timestamp}&limit=${payload.limit}&status=${payload.status}&type=${payload.type}`
            );

            return standardMethodResponse(method, context);
        },

        searchChargesByReceiptNumber(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post(`/payment/search/receipt`, {
                receiptNumber: payload.receiptNumber,
                limit: payload.limit,
                offset: payload.offset,
            });

            return standardMethodResponse(method, context);
        },

        searchChargesByDescription(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post(`/payment/search/description`, {
                description: payload.description,
                limit: payload.limit,
                offset: payload.offset,
            });

            return standardMethodResponse(method, context);
        },

        getSingleCharge(context, chargeId) {
            context.commit('isLoading', true);

            const method = axios.get(`/payment/charges/${chargeId}`);

            return standardMethodResponse(method, context);
        },

        getSingleRemoteCharge(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post(
                `/payment/remote-charge`,
                {
                    remoteId: payload.remoteId,
                    chargeId: payload.chargeId,
                },
                {
                    baseURL: process.env.VUE_APP_API_BASE_CUSTOMER_URI,
                }
            );

            return standardMethodResponse(method, context);
        },

        getRecurringCharges(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`/payment/recurrences?timestamp=${payload.timestamp}&limit=${payload.limit}&status=${payload.status}`);

            return standardMethodResponse(method, context);
        },

        getSingleRecurringCharge(context, recurrenceId) {
            context.commit('isLoading', true);

            const method = axios.get(`/payment/recurrences/${recurrenceId}`);

            return standardMethodResponse(method, context);
        },

        getHistoryFromRecurringCharge(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`/payment/recurrences/${payload.recurrenceId}/charges`);

            return standardMethodResponse(method, context);
        },

        changeRecurrenceCard(context, payload) {
            context.commit('isLoading', true);

            const method = axios.put(`/payment/recurrences/sources`, {
                recurrenceId: payload.recurrenceId,
                sourceToken: payload.sourceToken,
            });

            return standardMethodResponse(method, context);
        },

        // remotes
        createRemoteItem(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post(`/payment/remote`, {
                charge: payload.charge,
                customer: payload.customer,
                v2: true,
            });

            return standardMethodResponse(method, context);
        },

        getAllRemoteItems(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`/payment/remotes?limit=${payload.limit}&offset=${payload.offset}&status=${payload.status}`);

            return standardMethodResponse(method, context);
        },

        getRemoteItem(context, remoteId) {
            context.commit('isLoading', true);

            const method = axios.get(`/payment/remote/${remoteId}`);

            return standardMethodResponse(method, context);
        },

        cancelRemoteItem(context, remoteId) {
            context.commit('isLoading', true);

            const method = axios.delete(
                `/payment/remote/${remoteId}`
                // ,{
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     data: {
                //         ''
                //     }
                // }
            );

            return standardMethodResponse(method, context);
        },

        getHistoryFromRemoteCharge(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`/payment/remote/${payload.remoteId}/charges`);

            return standardMethodResponse(method, context);
        },

        payRemoteItem(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post(
                `/payment/remote/${payload.remoteId}`,
                {
                    sourceToken: payload.sourceToken,
                },
                {
                    baseURL: process.env.VUE_APP_API_BASE_CUSTOMER_URI,
                }
            );

            return standardMethodResponse(method, context);
        },

        changePassword(context, payload) {
            context.commit('isLoading', true);

            const method = axios.patch(`/account/password`, {
                currentPwd: payload.currentPwd,
                newPwd: payload.newPwd,
            });

            return standardMethodResponse(method, context);
        },

        addStore(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post(`/account`, {
                receiptStoreName: payload.receiptStoreName,
                receiptStoreAddress: payload.receiptStoreAddress,
                campaignStoreName: payload.campaignStoreName,
                campaignStoreAddress: payload.campaignStoreAddress,
                permissions: payload.permissions,
            });

            return standardMethodResponse(method, context);
        },

        updateStore(context, payload) {
            context.commit('isLoading', true);

            const method = axios.patch(`/account/store`, {
                accountId: payload.accountId,
                receiptStoreName: payload.receiptStoreName,
                receiptStoreAddress: payload.receiptStoreAddress,
                campaignStoreName: payload.campaignStoreName,
                campaignStoreAddress: payload.campaignStoreAddress,
            });

            return standardMethodResponse(method, context);
        },

        addUser(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post(`/account/users`, {
                accountId: payload.accountId,
                username: payload.username,
                email: payload.email,
                password: payload.password,
                country: !!payload.country ? payload.country : null,
                countryCode: !!payload.countryCode ? payload.countryCode : null,
                phone: payload.phone ? payload.phone : null,
                permissions: payload.permissions,
                userType: payload.userType,
            });

            return standardMethodResponse(method, context);
        },

        retreiveStores(context, showLoading = true) {
            context.commit('isLoading', showLoading);

            const method = axios.get(`/account/stores`);

            return standardMethodResponse(method, context).then(d =>
                d.map((s: any) =>
                    Object.assign(s, {
                        store_name: (
                            (s.store_name || '') +
                            (s.isThisStore ? i18n.t('general.thisStore') : '') +
                            (s.master ? ' (Master)' : '')
                        ).trim(),
                    })
                )
            );
        },

        retreiveStoresAndUsers(context, showLoading = true) {
            context.commit('isLoading', showLoading);

            const method = axios.get(`/account/stores&users`);

            return standardMethodResponse(method, context).then(d =>
                d.map((s: any) =>
                    Object.assign(s, {
                        store_name: (
                            (s.store_name || '') +
                            (s.isThisStore ? i18n.t('general.thisStore') : '') +
                            (s.master ? ' (master)' : '')
                        ).trim(),
                    })
                )
            );
        },

        editPermissions(context, payload) {
            context.commit('isLoading', true);

            const method = axios.patch(`/account/permissions`, {
                userId: payload.userId,
                permissions: payload.permissions,
            });

            return standardMethodResponse(method, context);
        },

        // Payout related
        getPayouts(context, payload) {
            let method;
            // context.commit('isLoading', true);

            if (!payload.startingAfter) {
                method = axios.get(`/payment/payouts?limit=${payload.limit}`);
            } else {
                method = axios.get(`/payment/payouts?limit=${payload.limit}&startingAfter=${payload.startingAfter}`);
            }

            return standardMethodResponse(method, context);
        },

        getPayoutTransactions(context, payload) {
            let method;
            context.commit('isLoading', true);

            method = axios.get(`/payment/payout/${payload.payoutId}?limit=${payload.limit}&offset=${payload.offset}`);

            return standardMethodResponse(method, context);
        },

        generateExportDownloadLink(context, payload) {
            context.commit('isLoading', true);

            return standardMethodResponse(axios.post(`/export`, payload), context);
        },

        getDashboardSummary(context, payload) {
            return standardMethodResponse(axios.post(`/dashboard/summary`, payload), context);
        },

        getSignedS3UrlForView(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`payment/charges/${payload.chargeId}/${payload.index}/receipt/s3-signed-url`);

            return standardMethodResponse(method, context);
        },

        getSignedS3UrlForUpload(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post(`payment/charges/${payload.chargeId}/${payload.index}/receipt/s3-signed-url`, {
                filetype: payload.filetype,
            });

            return standardMethodResponse(method, context);
        },

        updateReceiptCopyStatus(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post(`payment/charges/${payload.chargeId}/receipt/status`, {
                receiptCopyIndex: payload.receiptCopyIndex,
            });

            return standardMethodResponse(method, context);
        },

        uploadToS3SignedUrl(context, payload) {
            context.commit('isLoading', true);

            const options = {
                headers: {
                    'Content-Type': payload.originalFile.type,
                },
            };

            const upload = (signedUrl: any, file: any) => {
                return axios.put(signedUrl, file, options).catch((error: any) => {
                    const parser = new DOMParser();
                    const oDOM = parser.parseFromString(error.response.data, 'text/xml');
                    throw {
                        message: oDOM.getElementsByTagName('Message')[0].childNodes[0].nodeValue,
                    };
                });
            };

            const p1 = upload(payload.thumbnailUrl, payload.thumbnailFile);
            const p2 = upload(payload.originalUrl, payload.originalFile);

            const method = Promise.all([p1, p2]).then(() => {
                return {
                    data: {
                        success: true,
                        data: '',
                    },
                };
            });

            return standardMethodResponse(method, context);
        },

        uploadReceipt({ dispatch }, payload) {
            return new Promise((resolve, reject) => {
                return dispatch('getSignedS3UrlForUpload', {
                    chargeId: payload.chargeId,
                    filetype: payload.original.type,
                    index: payload.index,
                })
                    .then((signedUrl: any) => {
                        return dispatch('uploadToS3SignedUrl', {
                            thumbnailUrl: signedUrl.thumbnail,
                            thumbnailFile: payload.thumbnail,
                            originalUrl: signedUrl.original,
                            originalFile: payload.original,
                        });
                    })
                    .then(() => {
                        return dispatch('updateReceiptCopyStatus', {
                            chargeId: payload.chargeId,
                            receiptCopyIndex: payload.index,
                        });
                    })
                    .then((response: any) => resolve(response))
                    .catch((error: any) => {
                        reject(error);
                    });
            });
        },

        /* Merchant Display Name in Receipt*/

        retreiveMerchantName(context) {
            context.commit('isLoading', true);

            const method = axios.get('account/merchant_name');

            return standardMethodResponse(method, context);
        },

        updateMerchantName(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post('account/merchant_name', {
                name: payload.name,
            });

            return standardMethodResponse(method, context);
        },

        /* End */

        /* Online Payment Related Actions */

        /* Merchant Display Name in Campaign */
        retreiveMerchantCampaignNames(context) {
            context.commit('isLoading', true);

            const method = axios.get('campaign/merchant_names');

            return standardMethodResponse(method, context);
        },

        updateMerchantNames(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post('campaign/merchant_names', {
                displayName: payload.displayName,
                urlName: payload.urlName,
            });

            return standardMethodResponse(method, context);
        },
        /* End */

        /* Display Merchant Logo */
        // General Purpose in Setting
        getMerchantLogo(context) {
            context.commit('isLoading', true);

            const method = axios.get(`account/merchant_logo`);

            return standardMethodResponse(method, context);
        },

        // Consumer Side Display
        getMerchantLogoFromMerchantName(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`campaign/merchant_logo/${payload.merchantName}`);

            return standardMethodResponse(method, context);
        },
        /* End */

        /* Update Merchant Logo */
        updateMerchantLogo({ dispatch }, payload) {
            return new Promise((resolve, reject) => {
                return dispatch('getMerchantLogoPreSignedUrl', {
                    file: payload.file,
                })
                    .then((signedUrl: any) => {
                        return dispatch('uploadToS3', {
                            file: payload.file,
                            signedUrl: signedUrl,
                        });
                    })
                    .then(() => {
                        return dispatch('updateMerchantLogoStatus', {
                            hasLogo: true,
                        });
                    })
                    .then((response: any) => resolve(response))
                    .catch((error: any) => {
                        reject(error);
                    });
            });
        },

        getMerchantLogoPreSignedUrl(context, payload) {
            context.commit('isLoading', true);

            const method = axios.put('account/merchant_logo', {
                filetype: payload.file.type,
            });

            return standardMethodResponse(method, context);
        },

        updateMerchantLogoStatus(context, payload) {
            context.commit('isLoading', true);

            const method = axios.put('account/merchant_logo_status', {
                hasLogo: payload.hasLogo,
            });

            return standardMethodResponse(method, context);
        },
        /* End */

        /* create campaign from merchant side */
        createCampaign(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post(`campaign`, {
                campaignData: payload.campaignData,
            });

            return standardMethodResponse(method, context);
        },

        cancelCampaign(context, campaignId) {
            context.commit('isLoading', true);

            const method = axios.delete(`campaign/${campaignId}`);

            return standardMethodResponse(method, context);
        },

        createCampaignBanners({ dispatch }, payload) {
            let p: any[] = [];

            for (let i = 0; i < payload.banners.length; i++) {
                const promise = dispatch('getPreSignedBannerLink', {
                    campaignId: payload.campaignId,
                    index: i + 1,
                    banner: payload.banners[i],
                }).then(signedUrl => {
                    return dispatch('uploadToS3', {
                        file: payload.banners[i],
                        signedUrl: signedUrl,
                    });
                });
                p.push(promise);
            }

            return new Promise((resolve, reject) => {
                return Promise.all(p)
                    .then((response: any) => {
                        resolve(response);
                    })
                    .catch((error: any) => {
                        reject(error);
                    });
            });
        },

        getPreSignedBannerLink(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post(`campaign/${payload.campaignId}/banners/${payload.index}`, {
                filetype: payload.banner.type,
            });

            return standardMethodResponse(method, context);
        },
        /* End */

        // retreive campaign from consumer side
        retreiveCampaign(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`campaign/${payload.brand}/${payload.trackingLink}`, {
                baseURL: process.env.VUE_APP_API_BASE_CUSTOMER_URI,
            });

            return standardMethodResponse(method, context);
        },

        /* campaign history related */
        retreiveCampaignByCampaignId(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`campaign/${payload.campaignId}`);

            return standardMethodResponse(method, context);
        },

        getCampaignsSummary(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`campaign/all/summary?timestamp=${payload.timestamp}&limit=${payload.limit}`);

            return standardMethodResponse(method, context);
        },

        getCampaignSummary(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`campaign/${payload.campaignId}/summary`);

            return standardMethodResponse(method, context);
        },

        getCampaignOrders(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`campaign/${payload.campaignId}/orders?timestamp=${payload.timestamp}&limit=${payload.limit}`);

            return standardMethodResponse(method, context);
        },

        getCampaignOrder(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`campaign/${payload.campaignId}/order/${payload.orderId}`);

            return standardMethodResponse(method, context);
        },

        //get customerInfo
        getCampaignOrderByCampaignId(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`campaign/${payload.campaignId}/orders/detailed`);

            return standardMethodResponse(method, context);
        },
        /* End */

        /* campaign order creation and payment */
        createCampaignOrder(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post(
                `campaign/${payload.campaignId}/order`,
                {
                    campaignOrder: payload.campaignOrder,
                    lang: i18n.locale,
                },
                {
                    baseURL: process.env.VUE_APP_API_BASE_CUSTOMER_URI,
                }
            );

            return standardMethodResponse(method, context);
        },

        editCampaignOrder(context, payload) {
            context.commit('isLoading', true);

            const method = axios.put(
                `campaign/${payload.campaignId}/order/${payload.orderId}`,
                {
                    campaignOrder: payload.campaignOrder,
                },
                {
                    baseURL: process.env.VUE_APP_API_BASE_CUSTOMER_URI,
                }
            );

            return standardMethodResponse(method, context);
        },

        chargeCampaignOrder(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post(
                `campaign/order/${payload.orderId}/charge`,
                {
                    amount: payload.amount,
                    currency: payload.currency,
                    description: payload.description,
                    sourceToken: payload.sourceToken,
                    lang: i18n.locale,
                },
                {
                    baseURL: process.env.VUE_APP_API_BASE_CUSTOMER_URI,
                }
            );

            return standardMethodResponse(method, context);
        },

        getCampaignOrderCharge(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`campaign/order/${payload.orderId}/charge/${payload.chargeId}`, {
                baseURL: process.env.VUE_APP_API_BASE_CUSTOMER_URI,
            });

            return standardMethodResponse(method, context);
        },
        /* End */

        /* End of Online Payment Related Actions */

        // Upload to S3 presigned link
        uploadToS3(context, payload) {
            context.commit('isLoading', true);

            const options = {
                headers: {
                    'Content-Type': payload.file.type,
                },
            };

            const method = axios
                .put(payload.signedUrl, payload.file, options)
                .then(() => {
                    return {
                        data: {
                            success: true,
                            data: '',
                        },
                    };
                })
                .catch((error: any) => {
                    const parser = new DOMParser();
                    const oDOM = parser.parseFromString(error.response.data, 'text/xml');
                    throw {
                        message: oDOM.getElementsByTagName('Message')[0].childNodes[0].nodeValue,
                    };
                });

            return standardMethodResponse(method, context);
        },

        getRecurringCashflow(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`/payment/recurrences-cashflow?start=${payload.startDate}&end=${payload.endDate}`);

            return standardMethodResponse(method, context);
        },

        getRecurringCashflowSummary(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`/payment/recurrences-cashflow/summary?start=${payload.startDate}&end=${payload.endDate}`);

            return standardMethodResponse(method, context);
        },

        labpayCharge(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post('/wechat_payment/charge', {
                authCode: payload.authCode,
                amount: payload.amount,
                currency: payload.currency.toUpperCase(),
                description: payload.description,
                paymentType: payload.paymentType,
            });

            return standardMethodResponse(method, context);
        },

        wechatPayRefund(context, payload) {
            context.commit('isLoading', true);

            const method = axios.post('/wechat_payment/refund', {
                chargeId: payload.chargeId,
            });

            return standardMethodResponse(method, context);
        },

        getWechatPayCharge(context, payload) {
            context.commit('isLoading', true);

            const method = axios.get(`/wechat_payment/charge/${payload}`);

            return standardMethodResponse(method, context);
        },

        retreiveCampaignAgentPromotionClientList(context, payload) {
            context.commit('isLoading', true);
            const method = axios.get('/campaign/agent_promotion/get_client_list');
            return standardMethodResponse(method, context);
        },

        cancelRecurrence(context, recurrenceId) {
            context.commit('isLoading', true);
            const method = axios.delete(`/payment/recurrences/${recurrenceId}`);
            return standardMethodResponse(method, context);
        },

        retreiveStorePermission(context, showLoading = true) {
            context.commit('isLoading', true);
            const method = axios.get('/account/permissions/stores');
            return standardMethodResponse(method, context);
        },

        retreiveStoresAndUsersPermission(context, showLoading = true) {
            context.commit('isLoading', true);
            const method = axios.get('/account/permissions/stores&users');
            return standardMethodResponse(method, context);
        },

        updateChargeDescription(context, payload) {
            context.commit('isLoading', true);
            const method = axios.patch(`/payment/charges/${payload.id}/description`, {
                description: payload.description,
            });
            return standardMethodResponse(method, context);
        },

        retreiveAllChargesFromCampaignOrder(context, payload) {
            context.commit('isLoading', true);
            const method = axios.get(`/campaign/order/${payload.orderId}`);
            return standardMethodResponse(method, context);
        },

        checkCampaignValid(context, payload) {
            context.commit('isLoading', true);
            const method = axios.get(`/campaign/${payload.campaignId}/isValid`);
            return standardMethodResponse(method, context);
        },

        updateUserLanguage(context, payload) {
            context.commit('isLoading', true);
            const method = axios.put('/account/Language', {
                languageCode: payload.languageCode,
            });
            return standardMethodResponse(method, context);
        },
        checkBalance(context, payload) {
            context.commit('isLoading', true);
            const method = axios.get(`/payment/check-balance`);
            return standardMethodResponse(method, context);
        },

        // sign up
        saveCompanyData(context, payload) {
            context.commit('isLoading', true);
            context.commit('saveCompanyData', payload.legal_entity);
            const method = axios.patch(`/account/company`, {
                nextPage: payload.nextPage,
                legal_entity: payload.legal_entity,
            });
            return standardMethodResponse(method, context);
        },
        saveOwnersData(context, payload) {
            context.commit('isLoading', true);
            context.commit('saveOwnersData', payload.legal_entity);
            const method = axios.patch(`/account/owners`, {
                nextPage: payload.nextPage,
                legal_entity: payload.legal_entity.map((owner: any) => {
                    return {
                        id: owner.id,
                        firstName: owner.firstName,
                        lastName: owner.lastName,
                        identityType: owner.identityType,
                        identityNumber: owner.identityNumber,
                        dob: owner.dob,
                        country: owner.countryCode != undefined ? owner.countryCode.en : '',
                        countryCode: owner.countryCode != undefined ? owner.countryCode.phone_code : '',
                        phone: owner.phone,
                        address: owner.address,
                        city: owner.city,
                        state: owner.state,
                    };
                }),
            });
            standardMethodResponse(method, context);
        },
        saveExternalAccountData(context, payload) {
            context.commit('isLoading', true);
            context.commit('saveExternalAccountData', payload.legal_entity);
            const method = axios.patch(`/account/external-account`, {
                nextPage: payload.nextPage,
                legal_entity: {
                    account_holder_type: payload.legal_entity.holderType == '' ? null : payload.legal_entity.holderType.trim(),
                    account_holder_name: payload.legal_entity.holderName == '' ? null : payload.legal_entity.holderName.trim(),
                    routing_number:
                        payload.legal_entity.bank == null || payload.legal_entity.branchCode == ''
                            ? ''
                            : `${payload.legal_entity.bank}-${payload.legal_entity.branchCode}`.trim(),
                    account_number: payload.legal_entity.accountNumber == '' ? null : payload.legal_entity.accountNumber.trim(),
                    country: payload.legal_entity.country == '' ? null : payload.legal_entity.country.trim(),
                    currency: payload.legal_entity.currency == '' ? null : payload.legal_entity.currency.trim(),
                },
            });
            return standardMethodResponse(method, context);
        },
        saveDocumentUploadData(context, payload) {
            context.commit('isLoading', true);
            context.commit('saveDocumentUploadData', payload);
            context.commit('isLoading', false);
        },
        async retrieveSignUpData(context) {
            context.commit('isLoading', true);
            const response = await axios.get(`/account/sign-up-data`);
            // if no reg doc
            if (response.data == '') {
                context.commit('isLoading', false);
                return;
            }
            await context.commit('retrieveSignUpData', response.data.data);
            context.commit('isLoading', false);
            return response.data.data;
        },
        async uploadSignUpDocument(context, payload) {
            context.commit('isLoading', true);
            try {
                // upload br
                const brLink = (await axios
                    .get(`/account/upload-link`, {
                        params: {
                            keyname: payload.brFile.filename,
                        },
                    })
                    .catch(error => {
                        throw error;
                    })).data.data;

                if (!!brLink) {
                    await axios
                        .put(brLink, payload.brFile.file, {
                            headers: {
                                'Content-Type': payload.brFile.file['type'],
                            },
                        })
                        .catch(error => {
                            throw error;
                        });
                }

                // upload ci
                if (JSON.stringify(payload.ciFile) != '{}') {
                    const ciLink = (await axios
                        .get(`/account/upload-link`, {
                            params: {
                                keyname: payload.ciFile.filename,
                            },
                        })
                        .catch(error => {
                            throw error;
                        })).data.data;

                    if (!!ciLink) {
                        await axios
                            .put(ciLink, payload.ciFile.file, {
                                headers: {
                                    'Content-Type': payload.ciFile.file['type'],
                                },
                            })
                            .catch(error => {
                                throw error;
                            });
                    }
                }

                // // these two variable is used to store the filename and let the db store the filename
                let identity_document_front: Array<string> = [];
                let identity_document_back: Array<string> = [];

                // // upload identity front
                // await payload.owners.identityFront.forEach((identity: any) => {
                //     axios
                //         .get(`/account/upload-link`, {
                //             params: {
                //                 keyname: identity.filename,
                //             },
                //         })
                //         .then((res: any) => {
                //             if (res.data.success) {
                //                 axios.put(res.data.data, identity.file, {
                //                     headers: {
                //                         'Content-Type': identity.file['type'],
                //                     },
                //                 });
                //             }
                //         });
                //     identity_document_front.push(identity.filename);
                // });

                // // upload identity back
                // await payload.owners.identityBack.forEach((identity: any) => {
                //     axios
                //         .get(`/account/upload-link`, {
                //             params: {
                //                 keyname: identity.filename,
                //             },
                //         })
                //         .then((res: any) => {
                //             if (res.data.success) {
                //                 axios.put(res.data.data, identity.file, {
                //                     headers: {
                //                         'Content-Type': identity.file['type'],
                //                     },
                //                 });
                //             }
                //         });

                //     identity_document_back.push(identity.filename);
                // });

                // // store the identity filename to db
                // await axios.patch('/account/identity-document', {
                //     identityFront: identity_document_front,
                //     identityBack: identity_document_back,
                // });

                // // upload address
                // await payload.owners.address.forEach((address: any) => {
                //     axios
                //         .get(`/account/upload-link`, {
                //             params: {
                //                 keyname: address.filename,
                //             },
                //         })
                //         .then((res: any) => {
                //             if (res.data.success) {
                //                 axios.put(res.data.data, address.file, {
                //                     headers: {
                //                         'Content-Type': address.file['type'],
                //                     },
                //                 });
                //             }
                //         });
                // });

                let identityFrontLink: string, identityBackLink: string, addressLink: string;
                for (let i = 0; i < payload.owners.identityFront.length; i++) {
                    identityFrontLink = (await axios
                        .get(`/account/upload-link`, {
                            params: {
                                keyname: payload.owners.identityFront[i].filename,
                            },
                        })
                        .catch(error => {
                            throw error;
                        })).data.data;

                    if (!!identityFrontLink) {
                        await axios
                            .put(identityFrontLink, payload.owners.identityFront[i].file, {
                                headers: {
                                    'Content-Type': payload.owners.identityFront[i].file['type'],
                                },
                            })
                            .catch(error => {
                                throw error;
                            });
                    }

                    identity_document_front.push(payload.owners.identityFront[i].filename);

                    identityBackLink = (await axios
                        .get(`/account/upload-link`, {
                            params: {
                                keyname: payload.owners.identityBack[i].filename,
                            },
                        })
                        .catch(error => {
                            throw error;
                        })).data.data;

                    if (!!identityBackLink) {
                        await axios
                            .put(identityBackLink, payload.owners.identityBack[i].file, {
                                headers: {
                                    'Content-Type': payload.owners.identityBack[i].file['type'],
                                },
                            })
                            .catch(error => {
                                throw error;
                            });
                    }

                    identity_document_back.push(payload.owners.identityFront[i].filename);

                    addressLink = (await axios
                        .get(`/account/upload-link`, {
                            params: {
                                keyname: payload.owners.address[i].filename,
                            },
                        })
                        .catch(error => {
                            throw error;
                        })).data.data;

                    if (!!addressLink) {
                        await axios
                            .put(addressLink, payload.owners.address[i].file, {
                                headers: {
                                    'Content-Type': payload.owners.address[i].file['type'],
                                },
                            })
                            .catch(error => {
                                throw error;
                            });
                    }
                }

                // update identity document in db
                await axios.patch('/account/identity-document', {
                    identityFront: identity_document_front,
                    identityBack: identity_document_back,
                });

                // upload bank
                const bankLink = (await axios
                    .get(`/account/upload-link`, {
                        params: {
                            keyname: payload.bank.filename,
                        },
                    })
                    .catch(error => {
                        throw error;
                    })).data.data;

                if (!!bankLink) {
                    await axios
                        .put(bankLink, payload.bank.file, {
                            headers: {
                                'Content-Type': payload.bank.file['type'],
                            },
                        })
                        .catch(error => {
                            throw error;
                        });
                }

                // upload others
                // await payload.others.forEach((other: any) => {
                //     axios
                //         .get(`/account/upload-link`, {
                //             params: {
                //                 keyname: other.filename,
                //             },
                //         })
                //         .then((res: any) => {
                //             if (res.data.success) {
                //                 axios.put(res.data.data, other.file, {
                //                     headers: {
                //                         'Content-Type': other.file['type'],
                //                     },
                //                 });
                //             }
                //         });
                // });

                let otherLink: string;
                for (let i = 0; i < payload.others.length; i++) {
                    otherLink = (await axios
                        .get(`/account/upload-link`, {
                            params: {
                                keyname: payload.others[i].filename,
                            },
                        })
                        .catch(error => {
                            throw error;
                        })).data.data;

                    if (!!otherLink) {
                        await axios
                            .put(otherLink, payload.others[i].file, {
                                headers: {
                                    'Content-Type': payload.others[i].file['type'],
                                },
                            })
                            .catch(error => {
                                throw error;
                            });
                    }
                }

                await axios.patch('/account/finish-upload');

                context.commit('isLoading', false);
            } catch (error) {
                context.commit('isLoading', false);
                throw error;
            }
        },
        getTaxRate(context) {
            context.commit('isLoading', true);
            const method = axios.get(`/account/tax-rate`);
            return standardMethodResponse(method, context);
        },
        async uploadSignature(context, payload) {
            context.commit('isLoading', true);
            try {
                const formData = new FormData();
                formData.append('signature', payload.imgSignApplicaton);
                formData.append('signature', payload.imgSignContract);
                formData.append('data', JSON.stringify(payload.data));
                formData.append('email', payload.email);
                const response = await axios.post(`/account/signature-upload`, formData).catch(error => {
                    throw error;
                });
                await new Promise(function(resolve, reject) {
                    setTimeout(async function() {
                        await axios.get(`/account/email-contract/${payload.email}`).catch(error => {
                            reject(error);
                        });
                        context.commit('isLoading', false);
                        resolve();
                    }, 5000);
                }).catch(error => {
                    throw error;
                });
                return response;
            } catch (error) {
                context.commit('isLoading', false);
                throw error;
            }
        },
        getMerchantStatus(context) {
            context.commit('isLoading', true);
            const method = axios.get('/account/merchant-status');
            return standardMethodResponse(method, context);
        },
        async sendEmailContract(context, payload) {
            context.commit('isLoading', true);
            const method = axios.get(`/account/email-contract/${payload}`);
            return standardMethodResponse(method, context);
        },
        getBackendVersion(context, payload) {
            context.commit('isLoading', true);
            const method = axios.get(`/account/version`);
            return standardMethodResponse(method, context);
        },
        logUserNavigation(context, payload) {
            context.commit('isLoading', true);
            const method = axios.post('/account/navigation', payload);
            return standardMethodResponse(method, context);
        },
        async retrieveCountryCodeList(context) {
            context.commit('isLoading', true);
            const result = await axios.get('/auth/country-code');
            context.commit('saveCountryCodeList', result.data.data);
            context.commit('isLoading', false);
        },
        async setQRPaymentMethodAction(context, payload) {
            context.commit('setQRPaymentMethod', payload);
        },
    },
});
vueStore.dispatch('printerAddr');

export default vueStore;
export { axios, moment_HK, sleep, stripe, webrtcAdapter };
