










































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import AUTH_MSG from '@/constants/AUTH';

@Component({})
export default class Password extends Vue {
    private currentPwd: string = '';
    private newPwd: string = '';
    private confirmPwd: string = '';
    private mdcTextField: any;

    public mounted() {
        this.initMDCTextFields();
    }

    // animation function
    private initMDCTextFields() {
        this.mdcTextField = Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }

    protected submit() {
        if (this.currentPwd.length < 8 || this.newPwd.length < 8) {
            return this.$root.$emit('error', { user_message: 'AUTH.INVALID_PASSWORD_FORMAT' });
        }

        if (this.confirmPwd !== this.newPwd) {
            return this.$root.$emit('error', { user_message: 'AUTH.CONFIRM_FAILURE' });
        }

        return this.$store
            .dispatch('changePassword', {
                currentPwd: this.currentPwd,
                newPwd: this.newPwd,
            })
            .then((response: any) => {
                return this.$root.$emit('confirm', {
                    path: '/settings',
                    title: this.$t('status.success'),
                });
            })
            .catch((error: any) => {
                return this.$root.$emit('error', error);
            });
    }
}
