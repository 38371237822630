















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class Capture extends Vue {
    private uncapturedAmount = 0;
    private uncapturedTrx = 0;

    private confirmOpen = false;
    private reminderOpen = false;
    private capturedData: { succeeded: number; failed: number } | null = null;

    public mounted() {
        this.getUncapturedInfo();
        console.log(this.$store.state.user);
    }

    private getUncapturedInfo() {
        this.$store
            .dispatch('getUncapturedCharges')
            .then((res: any) => {
                this.uncapturedAmount = res.amount_of_uncaptured;
                this.uncapturedTrx = res.no_of_uncaptured;
            })
            .catch((error: any) => {
                return this.$root.$emit('error', error);
            });
    }

    get displayAmount() {
        return Math.round(this.uncapturedAmount * 100) / 100;
    }

    private capture() {
        this.confirmOpen = true;
    }

    private onConfirmAccept() {
        this.$store
            .dispatch('captureAll')
            .then((res: any) => {
                this.capturedData = res;
                this.reminderOpen = true;
            })
            .catch((error: any) => {
                return this.$root.$emit('error', error);
            });
    }

    private onCaptureAccpet() {
        this.capturedData = null;
        return this.$router.push({ path: '/transactions/history' });
    }
}
