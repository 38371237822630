export const dashboard = {
    transaction: '交易',
    refund: '退款',
    volume: '金额',
    count: '笔数',
    daily: '今日',
    weekly: '7日',
    monthly: '本月',
    payout: {
        history: '转帐记录 (信用卡)',
        arrivalDate: '到帐日',
        expectedArrivalDate: '预计到帐日',
        total: '总额',
        net: '净额',
        type: {
            title: '转帐类别',
            charge: '付款',
            refund: '退款',
            adjustment: '其他调整',
        },
        payment: {
            title: '付款途径',
        },
    },
};
