var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "remote-link-item" } }, [
    _c(
      "div",
      {
        staticClass:
          "mdc-layout-grid app-page-activity app-page-activity--active activity-payment-remote-link-item"
      },
      [
        _c("div", { staticClass: "mdc-layout-grid__inner" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet payment-remote-link-item",
              staticStyle: { flex: "1 1 auto" }
            },
            [
              _c(
                "div",
                { staticClass: "payment-remote-link-item__wrapper-inner" },
                [
                  _c(
                    "div",
                    { staticClass: "payment-remote-link-item__inner" },
                    [
                      _vm._m(0),
                      _c(
                        "div",
                        { staticClass: "mdc-typography--subtitle1 enlarged" },
                        [
                          _vm._v(
                            "商戶 - " +
                              _vm._s(_vm.item.statement_descriptor || "—")
                          )
                        ]
                      ),
                      _c("br"),
                      _c("div", { staticClass: "mdc-typography--body2" }, [
                        _vm._v("請細閱有關款項資料。如有問題，請與我們聯絡。")
                      ]),
                      _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                        _vm._v("金額")
                      ]),
                      _c("div", { staticClass: "mdc-typography--body1" }, [
                        _vm._v(
                          _vm._s(_vm._f("currencyDisplay")(_vm.item.currency)) +
                            _vm._s(_vm.item.amount)
                        )
                      ]),
                      _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                        _vm._v("商品詳情")
                      ]),
                      _c("div", { staticClass: "mdc-typography--body1" }, [
                        _vm._v(_vm._s(_vm.item.description || "—"))
                      ]),
                      _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                        _vm._v("客戶名稱")
                      ]),
                      _c("div", { staticClass: "mdc-typography--body1" }, [
                        _vm._v(_vm._s(_vm.item.customer_name || "—"))
                      ]),
                      _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                        _vm._v("客戶電話")
                      ]),
                      _c("div", { staticClass: "mdc-typography--body1" }, [
                        _vm._v(_vm._s(_vm.item.customer_phone || "—"))
                      ])
                    ]
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
            },
            [
              _c("button", { staticClass: "mdc-button" }),
              _c(
                "button",
                {
                  staticClass: "mdc-fab mdc-fab--extended",
                  attrs: { "aria-label": "下一步" }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "mdc-fab__label",
                      on: {
                        click: function($event) {
                          _vm.$emit("next")
                        }
                      }
                    },
                    [_vm._v("付款")]
                  ),
                  _c(
                    "svg",
                    {
                      staticClass: "mdc-fab__icon",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewbox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mdc-typography--headline6" }, [
      _c("span", [_vm._v("款項")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }