var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tags filled",
      class: [_vm.status_.class, { "tags--small": _vm.small }]
    },
    [_vm._v(_vm._s(_vm.status_.text))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }