



















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CampaignLinkDetails from '@/components/campaign-link/CampaignLinkDetails.vue';
import CampaignPurchaseInformation from '@/components/campaign-link/CampaignPurchaseInformation.vue';
import Receipt from '@/components/campaign-link/Receipt.vue';
import FreeOrderReceipt from '@/components/campaign-link/FreeOrderReceipt.vue';
import ExpiredLink from '@/components/campaign-link/ExpiredLink.vue';
import Summary from '@/components/campaign-link/Summary.vue';
import CreditCard from '@/components/payment/CreditCard.vue';

import PoweredBy from '@/components/PoweredBy.vue';

import utils from '@/utils';

@Component({
    components: {
        CampaignLinkDetails,
        CampaignPurchaseInformation,
        CreditCard,
        Summary,
        Receipt,
        FreeOrderReceipt,
        ExpiredLink,
        PoweredBy,
    },
})
export default class CampaignLink extends Vue {
    private chargeId = '';
    private orderId = '';

    get isFreeOrder() {
        return this.purchaseData.sum === 0;
    }

    private modified = true;
    private tokenExpired = false;
    private expired = false;
    private paymentSteps: ['campaign', 'purchaseInfo', 'card', 'summary', 'receipt', 'freeOrderReceipt'] = [
        'campaign',
        'purchaseInfo',
        'card',
        'summary',
        'receipt',
        'freeOrderReceipt',
    ];

    private display: 'campaign' | 'purchaseInfo' | 'card' | 'summary' | 'receipt' | 'freeOrderReceipt' = 'campaign';

    private brand!: string;
    private merchantDisplayName = '';

    private trackingLink!: string;
    private campaignData: CampaignData | null = null;
    private purchaseData: any = null;
    private creditCardData = {
        token: {
            card: {},
            id: '',
            type: '',
        },
    };

    private showPlatformAgreement() {
        (this.$refs.platformAgreement as any).show();
    }

    private showPrivacy() {
        (this.$refs.privacy as any).show();
    }

    @Watch('display')
    onDisplayChanged() {
        utils.scrollTop();
    }

    @Watch('purchaseData', { deep: true })
    onPurchaseDataChanged() {
        this.modified = true;
    }

    get creditCard() {
        return this.creditCardData.token.card;
    }

    // public mounted() {
    //     console.log(this.$i18n.locale);
    //     this.$i18n.locale = 'zh_HK';
    // }

    private back() {
        let s = this.paymentSteps;
        this.display = s[s.indexOf(this.display) - 1];
    }

    private next() {
        let s = this.paymentSteps;
        this.display = s[s.indexOf(this.display) + 1];
    }

    private async pay() {
        if (!this.orderId) {
            return;
        }

        const isValid = await this.$store.dispatch('checkCampaignValid', {
            campaignId: (this.campaignData as CampaignData).id,
        });

        if (isValid === 'false') {
            return (this.expired = true);
        }

        this.tokenExpired = true;
        return await this.$store
            .dispatch('chargeCampaignOrder', {
                orderId: this.orderId,
                amount: this.purchaseData.sum,
                currency: (this.campaignData as any).currency,
                description: this.campaignData ? this.campaignData.internalCampaignName : '',
                sourceToken: this.creditCardData.token.id,
            })
            .then((response: any) => {
                this.chargeId = response.chargeId;
                this.display = 'receipt';
            })
            .catch((err: any) => {
                if (err.error_type === 'oversold_error') {
                    this.display = 'campaign';
                    this.tokenExpired = false;
                    return this.$root.$emit('error', err);
                }

                if (err.rawError.response || err.rawError.request) {
                    this.$root.$once('error-dismiss', () => {
                        this.chargeId = err.rawError.response.data.error.error_body.chargeId;
                        this.tokenExpired = false;
                        this.display = 'receipt';
                    });
                }

                this.$root.$emit('error', err);
            });
    }

    private retry() {
        this.chargeId = '';
        this.display = 'purchaseInfo';
    }

    private async createOrEditOrder() {
        if (!this.orderId) {
            return await this.createOrder();
        }

        if (this.orderId && this.modified) {
            return await this.editOrder();
        }

        return this.next();
    }

    private async createOrder() {
        if (this.campaignData == null) {
            return;
        }

        const isValid = await this.$store.dispatch('checkCampaignValid', {
            campaignId: this.campaignData.id,
        });

        if (isValid === 'false') {
            return (this.expired = true);
        }

        return await this.$store
            .dispatch('createCampaignOrder', {
                campaignId: this.campaignData.id,
                campaignOrder: this.purchaseData,
            })
            .then((response: any) => {
                this.modified = false;
                this.orderId = response.orderId;

                if (this.isFreeOrder) {
                    this.display = 'freeOrderReceipt';
                    return;
                }

                return this.next();
            })
            .catch((error: any) => {
                // console.error(error);
                // if (error.user_message == 'The requested campaign link is invalid') {
                //     return (this.expired = true);
                // }
                return this.$root.$emit('error', error);
            });
    }

    private async editOrder() {
        if (this.campaignData == null) {
            return;
        }

        const isValid = await this.$store.dispatch('checkCampaignValid', {
            campaignId: this.campaignData.id,
        });

        if (isValid === 'false') {
            return (this.expired = true);
        }

        return await this.$store
            .dispatch('editCampaignOrder', {
                campaignId: this.campaignData.id,
                orderId: this.orderId,
                campaignOrder: this.purchaseData,
            })
            .then((response: any) => {
                this.modified = false;
                this.orderId = response.orderId;
                return this.next();
            })
            .catch((error: any) => {
                return this.$root.$emit('error', error);
            });
    }

    get sum() {
        // console.log(this.purchaseData);
        // console.log((this.purchaseData as any).sum);
        return this.purchaseData && (this.purchaseData as any).sum ? (this.purchaseData as any).sum : 0;
    }

    public async created() {
        this.brand = this.$route.params.brand;
        this.trackingLink = this.$route.params.tracking
            ? `${this.$route.params.campaignName}/${this.$route.params.tracking}`
            : this.$route.params.campaignName;

        try {
            this.campaignData = await this.$store.dispatch('retreiveCampaign', {
                brand: this.brand,
                trackingLink: this.trackingLink,
            });

            if (this.campaignData) {
                this.merchantDisplayName = this.campaignData.merchantDisplayName;
            }
        } catch (err) {
            return (this.expired = true);
        }

        this.purchaseData = {
            customer: {
                name: '',
                countryCode: {},
                contact: '',
                reservedDate: '',
                reservedTime: '',
                gender: '',
                age: '',
                email: '',
                address: '',
                specialRequest: '',
            },

            productList: (this.campaignData as CampaignData).productList.map(product => {
                return {
                    id: product.id,
                    name: product.name,
                    price: product.price,
                    quantity: '1',
                    selected: false,
                    get totalPrice() {
                        return (this.price as number) * +this.quantity;
                    },
                };
            }),

            questionList: (this.campaignData as CampaignData).questionList.map((question: any) => {
                return {
                    id: question.id,
                    answerId: null,
                };
            }),

            get sum() {
                return this.productList
                    .filter((product: any) => product.selected === true)
                    .map((product: any) => product.totalPrice)
                    .reduce((acc: any, curr: any) => acc + curr, 0);
            },

            linkId: (this.campaignData as CampaignData).linkId,
        };
    }
}
