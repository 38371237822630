
















import { Component, Vue } from 'vue-property-decorator';
import PayoutList from '@/components/dashboard/payouts/PayoutList.vue';
import Spinner1 from '@/components/spinners/Spinner1.vue';

@Component({
    components: {
        PayoutList,
        Spinner1,
    },
})
export default class PayoutCard extends Vue {
    private payouts = [];
    private startingAfter?: string;
    private moreButton = true;
    public gettingPayouts = false;

    public created() {
        this.fetchItems();
    }

    public showPayout(payout: any) {
        this.$router.push({
            name: 'dashboard.transfer.details',
            params: { id: payout.id, payout },
        });
    }

    public showAll() {
        this.$router.push({ name: 'dashboard.transfers', params: { payouts: this.payouts as any } });
    }

    public async fetchItems() {
        if (!this.gettingPayouts) {
            this.gettingPayouts = true;
            try {
                this.payouts = (
                    await this.$store.dispatch('getPayouts', {
                        limit: 4,
                    })
                ).data;
            } catch (e) {
            } finally {
                this.gettingPayouts = false;
            }
        }
    }
}
