

















import { Component, Prop, Vue } from 'vue-property-decorator';
import SettingsListItem from '@/components/settings/SettingsListItem.vue';
import { axios } from '@/store';
import { isPlatform } from '@/utils/platform';

const sleep = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

const requestTimeout = 5000;

@Component({
    components: {
        SettingsListItem,
    },
})
export default class Printer extends Vue {
    private connectingDialogLoading__open: boolean = false;
    private connectingDialogSuccess__open: boolean = false;
    private connectingDialogFail__open: boolean = false;
    private scanning = false;

    private get nativeJPAYQrScanner() {
        // try to get native QR code scanner
        try {
            // only for ios, android not yet implemented
            if (isPlatform(window, 'capacitor') && isPlatform(window, 'ios')) {
                return (window as any).Capacitor.Plugins.JPAYQrScanner;
            }
        } catch (error) {
            return undefined;
        }
    }

    private items = [
        {
            onClick: this.toConnect,
            label: 'settings.connectPrinter',
        },
        // {
        //     to: { name: 'settings.printer.connect' },
        //     label: '進階設定',
        // },
    ];
    toConnect() {
        !!this.nativeJPAYQrScanner ? this.launchScannerNative() : this.$router.push({ name: 'settings.printer.connect' });
    }

    async launchScannerNative() {
        if (this.nativeJPAYQrScanner && !this.scanning) {
            this.scanning = true;
            try {
                const res = await this.nativeJPAYQrScanner.show();
                try {
                    const decoded_addr = 'https://' + res.data + '.ap.ngrok.io';

                    // test connection
                    this.connectingDialogLoading__open = true;
                    const response = await axios.request({ url: decoded_addr, timeout: requestTimeout });
                    if (!(response && response.data === 'OK')) throw new Error();
                    await this.$store.dispatch('printerAddr', decoded_addr);

                    this.connectingDialogLoading__open = false;
                    this.connectingDialogSuccess__open = true;
                } catch (error) {
                    this.connectingDialogLoading__open = false;
                    this.connectingDialogFail__open = true;
                }
            } catch (error) {}

            this.scanning = false;
        }
    }

    private async preventCancel() {
        await sleep(0);
        this.connectingDialogLoading__open = true;
        await sleep(10);
        this.connectingDialogLoading__open = true;
    }
}
