









import { Component, Vue, Prop } from 'vue-property-decorator';
import { MDCRipple } from '@material/ripple';

@Component
export default class NavbarbarItem extends Vue {
    // prettier-ignore
    @Prop() params!: any;
    private ripple!: MDCRipple;

    get show() {
        return this.params && (typeof this.params.if === 'undefined' || this.params.if);
    }

    public mounted() {
        this.ripple = new MDCRipple(this.$refs.rippleSurface as Element);
        this.ripple.unbounded = true;
    }

    public beforeDestroy() {
        this.ripple.destroy();
    }
}
