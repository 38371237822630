























































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
// import * as padStart from 'pad-start';
import e_at from '@/utils/catch_error_at';
import * as moment from 'moment';
import { sleep, moment_HK as _moment } from '@/store';
import Loop from '@/utils/Loop';
import generateReceipt from '@/utils/generateReceipt';
import Spinner2 from '@/components/spinners/Spinner2.vue';
import ChargeStatusTag from '@/components/charges/ChargeStatusTag.vue';
import RecurrentStatusTag from '@/components/recurrents/RecurrentStatusTag.vue';
import RemoteStatusTag from '@/components/remote-payment/RemoteStatusTag.vue';
import VirtualCard from '@/components/payment/VirtualCard.vue';
import ReceiptCopy from '@/components/ReceiptCopy.vue';
import { compressImageFile } from '@/utils/compressImageFile';
import * as Exif from 'exif-js';
import bandOptions from '@/constants/BANKS';
import { currencyNameWithSymbol } from '@/utils/helpers/currencyHelper';

const stripeFxFee = bandOptions.stripeFxFee;
const defaultPrinterPlatform = 'windows';

function joinBuffer(buffer1: Uint8Array, buffer2: Uint8Array) {
    let tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
    tmp.set(new Uint8Array(buffer1), 0);
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
    return tmp.buffer;
}

interface charge {
    connect_recurrence_id?: string;
    connect_campaign_order_id?: string;
    connect_online_payment_id?: string;
    connect_remote_id?: string;
    receipt_no: string;
    receipt_copy_length: number;
    created_at: string;
    amount: number;
    currency: string;
    description: string;
    status: string;
    network_status: string;
    creditcard: {
        last4: string;
        brand: string;
        cardholder_name: string;
        exp_date: string;
        application_brand: string;
        funding: string;
    };
    refunded_at: string;
    connect_refund_id: string;
    receipt_info: {
        merchant_name: string;
        merchant_legal_entity: string;
        store_name?: string;
        store_address?: string;
        mid: string;
        sid: string;
    };
    settlement_amount: number;
}

@Component({
    filters: {
        currencySymbolDisplay: currencyNameWithSymbol,
    },
    components: {
        Spinner2,
        ChargeStatusTag,
        RecurrentStatusTag,
        RemoteStatusTag,
        VirtualCard,
        ReceiptCopy,
    },
})
export default class Receipt extends Vue {
    private _destroyed = false;
    // prettier-ignore
    @Prop() chargeId!: string;
    private charge: charge | null = null;
    private enoughBalance = true;
    private last4 = '';
    private cardholder_name = '';
    private exp_date = '';
    private created_at = '';
    private refunded_at = '';
    // private show = false;
    private receiptCopyList: ReceiptCopyInterface[] = [];

    get addButtonShown() {
        return this.receiptCopyList.every(receiptCopy => receiptCopy.editing === false);
    }

    private addReceiptCopy(index: number) {
        if (this.receiptCopyList.length - index == 1 && this.receiptCopyList.length < 5) {
            return this.receiptCopyList.push({
                id: Date.now(),
                editing: false,
                existing: false,
            });
        }

        return;
    }

    private checkPrinterLoop!: Loop;
    private printerConnected = true;
    private printerReady = false;
    private printerPlatform = defaultPrinterPlatform;
    private printDialogLoading?: HTMLElement;
    private printDialogLoading_open = false;
    private printDialogFail_open = false;
    private refundDialogConfirm?: HTMLElement;
    private refundAndCancelDialogConfirm?: HTMLElement;
    private refundDialogConfirm_open = false;
    private refundAndCancelDialogConfirm_open = false;
    private refundDialogLoading?: HTMLElement;
    private refundAndCancelDialogLoading?: HTMLElement;
    private refundDialogLoading_open = false;
    private refundAndCancelDialogLoading_open = false;
    private refundDialogFail_open = false;
    private refundAndCancelDialogFail_open = false;
    private errorResult = '';
    private newChargeDescription = '';
    private newChargeDescriptionDialog_open = false;
    private newChargeDescriptionDialog?: HTMLElement;

    beforeRouteLeave(to: any, from: any, next: any) {
        // not working
        // console.log('leaving history item');
        this.$destroy();
        next();
    }

    get hkdConvent() {
        if (this.charge && (this.charge as any).currency !== 'hkd') {
            let fxRates = this.$store.state.fxRates[this.charge.currency.toUpperCase() + '_HKD'];
            // console.log(fxRates);
            return '(≈HKD$ ' + (this.charge.amount * fxRates * (1 - stripeFxFee)).toFixed(2) + ')';
        } else {
            return '';
        }
    }

    get showPrinterButton() {
        return this.canPrint && this.charge && (this.charge.status === 'succeeded' || this.charge.status === 'uncaptured');
    }

    get canPrint() {
        try {
            return (
                this.$store.state.user.permissions_.functional &&
                this.$store.state.user.permissions_.functional.printer &&
                this.$store.state.printerAddr
            );
        } catch (error) {
            return false;
        }
    }

    get canRefund() {
        try {
            if (this.charge) {
                const timeDifference = moment().unix() - moment(this.charge.created_at).unix();

                if (timeDifference > 24 * 60 * 60) {
                    return false;
                }
            }

            if (this.isDirectCharge && this.charge!.status === 'succeeded') {
                return false;
            }

            return (
                this.charge &&
                (this.charge.status === 'succeeded' || this.charge.status === 'uncaptured') &&
                this.$store.state.user.permissions_.payment &&
                this.$store.state.user.permissions_.payment.refund
            );
        } catch (error) {
            return false;
        }
    }

    get isDirectCharge() {
        return (
            !this.charge!.connect_campaign_order_id &&
            !this.charge!.connect_online_payment_id &&
            !this.charge!.connect_recurrence_id &&
            !this.charge!.connect_remote_id
        );
    }

    get cancelRecurrencePermission() {
        return this.$store.state.user.permissions_.payment && this.$store.state.user.permissions_.payment.cancel_recurrence;
    }

    get editChargeDescriptionPermission() {
        return this.$store.state.user.permissions_.payment && this.$store.state.user.permissions_.payment.edit_charge_description;
    }

    get maskName() {
        return this.cardholder_name
            .split(' ')
            .filter(s => s.length > 0)
            .map(s => s.toUpperCase())
            .map((s, i) => (i ? s.charAt(0) + '***' : s))
            .join(' ');
    }

    get formatFullCreatedDate() {
        return `${moment(this.created_at).format('LL')} ${moment(this.created_at).format('LTS')}`;
    }

    get formatFullRefundedDate() {
        return `${moment(this.refunded_at).format('LL')} ${moment(this.refunded_at).format('LTS')}`;
    }

    get formatCreatedDate() {
        return moment(this.created_at).format('LL');
    }

    get formatCreatedTime() {
        return moment(this.created_at).format('LT');
    }

    private async checkPrinter(checkAgain?: boolean) {
        try {
            let { platform = defaultPrinterPlatform } = ((await this.$store.dispatch('checkPrinterStatus')) as any).data;
            this.printerPlatform = platform;
            this.printerReady = true;
        } catch (e) {
            this.printerReady = false;
            if (e.message !== 'printer_server_offline') {
                this.printerConnected = true;
            } else {
                if (!checkAgain) {
                    await sleep(this.printerReady ? 3500 : 1000);
                    // await sleep(this.printerReady ? 350 : 100);
                    await this.checkPrinter(true);
                    // return;
                } else {
                    this.printerConnected = false;
                    this.printerReady = false;
                }
            }
            // return;
        } finally {
            await sleep(this.printerReady ? 3500 : 1000);
            // await sleep(this.printerReady ? 350 : 100);
        }
    }
    private async checkBalance() {
        const balanceObj = await this.$store.dispatch(`checkBalance`);
        const curAmount = balanceObj['available'][0]['amount'] / 100;
        const pendAmount = balanceObj['pending'][0]['amount'] / 100;
        const balance = Math.round((curAmount + pendAmount) * 100) / 100;
        if (this.charge) {
            if (this.charge.status === 'uncaptured') {
                return true;
            }
            if (this.charge.settlement_amount < balance) {
                return true;
            } else return false;
        } else return false;
    }
    /* constructor() {
        super();
        if (this.canPrint) {
            this.checkPrinterLoop = new Loop(this, this.checkPrinter);
            this.checkPrinterLoop.start();
        }
    } */

    /* public async mounted() {
        this.printDialogLoading = this.$refs.printDialogLoading as HTMLElement;
        this.refundDialogConfirm = this.$refs.refundDialogConfirm as HTMLElement;
        this.refundDialogLoading = this.$refs.refundDialogLoading as HTMLElement;

        if (!this.canPrint) {
            this.printerReady = true;
            return;
        }
        let tries = 0;
        do {
            try {
                await this.$store.dispatch('checkPrinterStatus');
                this.printerConnected = true;
            } catch (error) {
                this.printerConnected = false;
                await sleep(this.printerReady ? 3500 : 1500);
            } finally {
                if (this.printerConnected || ++tries >= 3) this.printerReady = true;
            }
        } while (!this.printerConnected && !this._destroyed);
    } */

    public async mounted() {
        moment.locale(this.$i18n.locale);
    }

    public async created() {
        if (this.canPrint) {
            this.checkPrinterLoop = new Loop(this, this.checkPrinter);
            this.checkPrinterLoop.start();
        }
        if (!this.chargeId) return this.$router.go(-1);
        this.$store.commit('isLoading', true);
        try {
            await this.initAttribute();
        } catch (e) {
            return this.$root.$emit('receipt_error', e);
        } finally {
            this.$store.commit('isLoading', false);
        }
    }

    beforeDestroy() {
        this.checkPrinterLoop && this.checkPrinterLoop.stop();
    }

    protected async initAttribute() {
        this.charge = await this.$store.dispatch('getSingleCharge', this.chargeId);
        this.enoughBalance = await this.checkBalance();
        if (!this.charge) return;
        this.last4 = this.charge.creditcard.last4;
        this.cardholder_name = this.charge.creditcard.cardholder_name;
        this.exp_date = this.charge.creditcard.exp_date;
        this.created_at = this.charge.created_at;
        this.refunded_at = this.charge.refunded_at;

        if (this.charge.receipt_copy_length > 0) {
            for (let i = 0; i < this.charge.receipt_copy_length; i++) {
                this.receiptCopyList.push({
                    id: Date.now(),
                    editing: false,
                    existing: true,
                });
            }
        }

        if (this.receiptCopyList.length < 5) {
            this.receiptCopyList.push({
                id: Date.now(),
                editing: false,
                existing: false,
            });
        }

        // this.show = true;
        try {
            this.charge.status === 'succeeded'
                ? this.checkPrinterLoop && this.checkPrinterLoop.start()
                : this.checkPrinterLoop && this.checkPrinterLoop.stop();
        } catch (error) {}
    }

    protected async refundAndCancelDialogConfirm_accept() {
        this.refundAndCancelDialogLoading_open = true;
        try {
            await this.$store
                .dispatch('refund', {
                    chargeId: this.chargeId,
                    reason: 'requested_by_customer',
                })
                .catch(e_at('refund'));

            await this.$store.dispatch('cancelRecurrence', (this.charge as charge).connect_recurrence_id).catch(e_at('cancelRecurrence'));
            await this.initAttribute().catch(e_at('initAttribute'));
        } catch (err) {
            if (err.error_at && err.error_at == 'refund') {
                await this.initAttribute().catch(e_at('initAttribute'));
                this.errorResult = this.$t('status.failure') as string;
            } else if (err.error_at && err.error_at == 'cancelRecurrence') {
                await this.initAttribute().catch(e_at('initAttribute'));
                this.errorResult = this.$t('receipt.cancelRecurrentFailed') as string;
            }
            this.refundAndCancelDialogFail_open = true;
        } finally {
            this.refundAndCancelDialogLoading_open = false;
        }
        // await sleep(10);
    }

    protected async refundDialogConfirm_accept() {
        this.refundDialogLoading_open = true;
        try {
            await this.$store
                .dispatch('refund', {
                    chargeId: this.chargeId,
                    reason: 'requested_by_customer',
                })
                .catch(e_at('refund'));
            await this.initAttribute().catch(e_at('initAttribute'));
        } catch (e) {
            if (e.error_at && e.error_at == 'refund') {
                await this.initAttribute().catch(e_at('initAttribute'));
            }
            this.refundDialogFail_open = true;
        } finally {
            this.refundDialogLoading_open = false;
        }
    }

    protected async preventCancel() {
        await sleep(0);
        this.refundDialogLoading_open = true;
        this.refundAndCancelDialogLoading_open = true;
        await sleep(10);
        this.refundDialogLoading_open = true;
        this.refundAndCancelDialogLoading_open = true;
    }

    protected refund() {
        this.refundDialogConfirm_open = true;
    }

    protected refundAndCancel() {
        this.refundAndCancelDialogConfirm_open = true;
    }

    protected triggerEditChargeDescriptionDialog() {
        this.newChargeDescriptionDialog_open = true;
    }

    protected async updateChargeDescription() {
        // this.refundDialogLoading_open = true;
        try {
            await this.$store
                .dispatch('updateChargeDescription', {
                    id: this.chargeId,
                    description: this.newChargeDescription,
                })
                .then(() => {
                    (this.charge as charge).description = this.newChargeDescription;
                })
                .catch(e_at('updataChargeDescription'));
            // await this.initAttribute().catch(e_at('initAttribute'));
        } catch (e) {
            if (e.error_at && e.error_at == 'updataChargeDescription') {
                await this.initAttribute().catch(e_at('initAttribute'));
            }
            // this.refundDialogFail_open = true;
        }
    }

    protected async print() {
        if (!this.charge) throw new Error();
        this.printDialogLoading_open = true;
        this.canPrint && this.checkPrinterLoop && this.checkPrinterLoop.stop();
        try {
            let generateReceiptOptions: {
                charge: charge;
                refund: boolean;
                width: 80 | 58;
                copy?: 'cardholder' | 'merchant';
                format?: 'escpos' | 'htmlTemplate';
            } = {
                charge: this.charge,
                refund: !!this.charge.refunded_at,
                width: 80,
            };

            let data;

            if (this.printerPlatform === 'mac') {
                Object.assign(generateReceiptOptions, { format: 'htmlTemplate' });
                let receiptData = [
                    await generateReceipt({
                        ...generateReceiptOptions,
                        ...{ copy: 'merchant' },
                    }),
                    await generateReceipt({
                        ...generateReceiptOptions,
                        ...{ copy: 'cardholder' },
                    }),
                ];
                data = { receiptData };
            } else {
                Object.assign(generateReceiptOptions, { format: 'escpos' });
                let printBuffer = joinBuffer(
                    (await generateReceipt({
                        ...generateReceiptOptions,
                        ...{ copy: 'merchant' },
                    })) as Uint8Array,
                    (await generateReceipt({
                        ...generateReceiptOptions,
                        ...{ copy: 'cardholder' },
                    })) as Uint8Array
                );
                data = { printBuffer };
            }

            await this.$store.dispatch('printReceipt', data);
        } catch (e) {
            this.printDialogFail_open = true;
            // if (e.error_at === 'checkPrinterStatus') this.printerReady = false;
        } finally {
            this.printDialogLoading_open = false;
            this.canPrint && this.checkPrinterLoop && this.checkPrinterLoop.start();
        }
    }
}
