var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "mdc-fab mdc-fab--extended",
          attrs: {
            type: "button",
            "aria-label": _vm.$t("signup.signHereLabel")
          },
          on: {
            click: function($event) {
              _vm.dialog = true
            }
          }
        },
        [
          _c("span", { staticClass: "mdc-fab__label" }, [
            _vm._v(_vm._s(_vm.$t("signup.signHereLabel")))
          ])
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass:
                "mdc-layout-grid app-page-activity app-page-activity--active bottom-nav--hidden"
            },
            [
              _c(
                "v-toolbar",
                {
                  staticStyle: { position: "fixed", "z-index": "1000" },
                  attrs: { dark: "", color: "#673ab7" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                  _c(
                    "v-toolbar-title",
                    { staticStyle: { "font-weight": "300" } },
                    [_vm._v(_vm._s(_vm.$t("signup.signHere")))]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.clean()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("delete")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.save()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("check")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md12: "", lg12: "" } },
                        [
                          _c("VueSignaturePad", {
                            ref: "signaturePad",
                            staticStyle: {
                              "background-color": "#f0f0f0",
                              opacity: "70%"
                            },
                            attrs: {
                              width: "auto",
                              height: "100vh",
                              options: {
                                onBegin: function() {
                                  _vm.$refs.signaturePad.resizeCanvas()
                                },
                                dotSize: (0.5 + 4.5) / 2,
                                maxWidth: 5
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }