var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-page app-page-payout app-page--active" },
    [
      _c("div", { staticClass: "app-page-container" }, [
        _c(
          "div",
          {
            staticClass:
              "app-page-activity mdc-layout-grid activity-payment-history-expanded app-page-activity--active bottom-nav--hidden"
          },
          [
            _c("div", { staticClass: "big-card" }, [
              _c(
                "a",
                {
                  staticClass: "close-icon",
                  on: {
                    click: function($event) {
                      _vm.$router.push({
                        name: "remote",
                        params: { page: "history" }
                      })
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "close-icon__icon",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                        }
                      })
                    ]
                  )
                ]
              ),
              _c("div", { staticClass: "big-card__background" }),
              _vm.remote && _vm.initial
                ? _c("div", { staticClass: "big-card__inner" }, [
                    _c("div", { staticClass: "big-card__section" }, [
                      _vm.remote.amount
                        ? _c(
                            "div",
                            {
                              staticClass: "mdc-typography--headline4",
                              staticStyle: { "font-weight": "500" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currencySymbolDisplay")(
                                    _vm.remote.currency
                                  )
                                ) + _vm._s(_vm.remote.amount)
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "flex-group flex-group__space-between" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mdc-typography--caption",
                              staticStyle: { opacity: "0.75" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .moment(_vm.remote.created_at)
                                    .format("LLL")
                                )
                              )
                            ]
                          ),
                          _c("RemoteStatusTag", {
                            attrs: { status: _vm.remote.status }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "big-card__section" }, [
                        _c(
                          "div",
                          { staticClass: "mdc-typography--headline6" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("general.paymentDetails.description")
                              )
                            )
                          ]
                        ),
                        _c("div", { staticClass: "mdc-typography--body1" }, [
                          _vm._v(_vm._s(_vm.remote.description || "—"))
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "big-card__section" },
                        [
                          _c(
                            "div",
                            { staticClass: "mdc-typography--headline6" },
                            [_vm._v(_vm._s(_vm.$t("general.history.title")))]
                          ),
                          _c(
                            "mdc-list",
                            { attrs: { interactive: "" } },
                            _vm._l(_vm.charges, function(item) {
                              return _c(
                                "mdc-list-item",
                                {
                                  key: item.id,
                                  on: {
                                    click: function($event) {
                                      _vm.$router.push({
                                        name: "receipt",
                                        params: { chargeId: item.id }
                                      })
                                    }
                                  }
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currencySymbolDisplay")(
                                          item.currency
                                        )
                                      ) + _vm._s(item.amount)
                                    )
                                  ]),
                                  _c("ChargeStatusTag", {
                                    attrs: { status: item, small: "" }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "mdc-typography--caption" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm
                                            .moment(item.created_at)
                                            .format("LL")
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            })
                          ),
                          _c(
                            "mdc-button",
                            { attrs: { dense: "", disabled: !false } },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.charges
                                      ? _vm.$t("actions.loadMore")
                                      : _vm.$t("actions.noMore")
                                  ) +
                                  "\n                            "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.customer
                        ? _c("div", { staticClass: "big-card__section" }, [
                            _c(
                              "div",
                              { staticClass: "mdc-typography--headline6" },
                              [_vm._v(_vm._s(_vm.$t("customer.title")))]
                            ),
                            _c(
                              "div",
                              { staticClass: "table-grid customer-table" },
                              [
                                _vm._l(_vm.customerFields, function(l, k) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        key: k + ".label",
                                        staticClass:
                                          "mdc-typography--body1 customer-table--label"
                                      },
                                      [_vm._v(_vm._s(_vm.$t(l)))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        key: k,
                                        staticClass: "mdc-typography--body1",
                                        style: {
                                          textTransform: [
                                            "name",
                                            "address"
                                          ].includes(k)
                                            ? "capitalize"
                                            : "none"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.customer[k] || "—"))]
                                    )
                                  ]
                                })
                              ],
                              2
                            ),
                            _c("div", { staticClass: "big-card__section" }, [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _vm.remote.status === "pending"
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "mdc-button red-color",
                                        on: { click: _vm.toggleCancelDialog }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        [
                          _c(
                            "mdc-dialog",
                            {
                              attrs: {
                                title: _vm.$t("actions.cancel"),
                                accept: _vm.$t("actions.accept"),
                                cancel: _vm.$t("actions.cancel")
                              },
                              on: { accept: _vm.cancel },
                              model: {
                                value: _vm.cancelDialogOpen,
                                callback: function($$v) {
                                  _vm.cancelDialogOpen = $$v
                                },
                                expression: "cancelDialogOpen"
                              }
                            },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.$t("remote.cancelMsg")))
                              ])
                            ]
                          ),
                          _c("mdc-dialog", {
                            attrs: {
                              title: _vm.$t("status.success"),
                              accept: _vm.$t("actions.accept")
                            },
                            on: {
                              accept: function($event) {
                                _vm.$router.push({
                                  name: "remote",
                                  params: { page: "history" }
                                })
                              }
                            },
                            model: {
                              value: _vm.confirmDialogOpen,
                              callback: function($$v) {
                                _vm.confirmDialogOpen = $$v
                              },
                              expression: "confirmDialogOpen"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }