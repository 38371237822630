import { actions } from './actions';
import { campaign } from './campaign';
import { customer } from './customer';
import { dashboard } from './dashboard';
import { general } from './general';
import { recurrent } from './recurrent';
import { status } from './status';
import { settings } from './settings';
import { errors } from './errors';
import { AUTH } from './auths';
import { receipt } from './receipt';
import { remote } from './remote';
import { currency } from './currency';
import { signup } from './signup';

export const en = {
    actions,
    campaign,
    customer,
    dashboard,
    general,
    recurrent,
    status,
    settings,
    errors,
    AUTH,
    receipt,
    remote,
    currency,
    signup,
};
