














































import { Component, Vue } from 'vue-property-decorator';
import { isEmpty } from '@/utils';
import RemoteLinkItem from '@/components/remote-link/RemoteLinkItem.vue';
import CreditCard from '@/components/payment/CreditCard.vue';
import Summary from '@/components/remote-link/Summary.vue';
import ExpiredLink from '@/components/remote-link/ExpiredLink.vue';
import ClientReceipt from '@/components/ClientReceipt.vue';

import PoweredBy from '@/components/PoweredBy.vue';

@Component({
    components: {
        RemoteLinkItem,
        CreditCard,
        Summary,
        ClientReceipt,
        ExpiredLink,
        PoweredBy,
    },
})
export default class RemotePaymentLink extends Vue {
    private remoteId!: string;
    private chargeId = '';
    private remoteItem = {};
    private paymentSteps: ['item', 'card', 'summary', 'receipt'] = ['item', 'card', 'summary', 'receipt'];

    private display: 'item' | 'card' | 'summary' | 'receipt' = 'item';
    private expired = false;
    private expiredStatus = '';
    private confirmed = false;

    private retry() {
        this.chargeId = '';
        this.display = 'item';
    }

    private creditCardData = {
        token: {
            card: {},
            id: '',
            type: '',
        },
    };

    get creditCard() {
        return this.creditCardData.token.card;
    }

    get sourceToken() {
        return this.creditCardData.token.id;
    }

    private back() {
        let s = this.paymentSteps;
        this.display = s[s.indexOf(this.display) - 1];
    }

    private next() {
        let s = this.paymentSteps;
        this.display = s[s.indexOf(this.display) + 1];
    }

    public async created() {
        this.remoteId = this.$route.params.remoteId;
        this.remoteItem = await this.getRemoteItem();

        (this.remoteItem as any).status === 'succeeded' || (this.remoteItem as any).status === 'canceled' ? (this.expired = true) : '';
        (this.remoteItem as any).status === 'succeeded' || (this.remoteItem as any).status === 'canceled'
            ? (this.expiredStatus = (this.remoteItem as any).status)
            : '';
    }

    public async getRemoteItem() {
        return await this.$store.dispatch('getRemoteItem', this.remoteId);
    }

    public async pay() {
        this.confirmed = true;
        return await this.$store
            .dispatch('payRemoteItem', {
                remoteId: this.remoteId,
                sourceToken: this.sourceToken,
            })
            .then((response: any) => {
                this.chargeId = response.chargeId;
                this.display = 'receipt';
            })
            .catch((err: any) => {
                if (err.rawError.response || err.rawError.request) {
                    this.$root.$once('error-dismiss', () => {
                        this.chargeId = err.rawError.response.data.error.error_body.chargeId;
                        this.confirmed = false;
                        this.display = 'receipt';
                    });
                }
                this.$root.$emit('error', err);
            });
    }
}
