
























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import EditingRemoteCampaign from '@/views/remote-campaign/EditingRemoteCampaign.vue';
import RemoteCampaignPreview from '@/views/remote-campaign/RemoteCampaignPreview.vue';
import RemoteCampaignLinkItem from '@/views/remote-campaign/RemoteCampaignLinkItem.vue';
import { IMaskComponent } from 'vue-imask';

@Component({
    components: {
        EditingRemoteCampaign,
        RemoteCampaignPreview,
        RemoteCampaignLinkItem,
        'imask-input': IMaskComponent,
    },
})
export default class RemoteCampaign extends Vue {
    private mask = /^[a-zA-Z0-9_]*$/;
    private prepare = (s: string) => s.replace(/ /g, '_');

    get baseUrl() {
        return `${window.location.origin}/campaigns/${this.urlName}`;
    }

    private displayName = '';
    private urlName = '';
    private merchantLogo = '';
    private editing = true;
    private submitted = false;
    private campaignData = null;
    private banners: any[] = [];
    private bannerSrc: string[] = [];
    private modalShow = false;
    private branchOptions = [];

    public async created() {
        try {
            if (this.$store.state.user.permissions_.master) {
                this.branchOptions = await this.$store.dispatch('retreiveStores');
            }

            const data = await this.$store.dispatch('retreiveMerchantCampaignNames');

            this.displayName = data.displayName;
            this.urlName = data.urlName;

            this.merchantLogo = (await this.$store.dispatch('getMerchantLogo')).src;

            if (!this.displayName || !this.urlName) {
                this.modalShow = true;
            }

            return;
        } catch (error) {
            return this.$root.$emit('error', error);
        }
    }

    protected async onValidate({ accept }: any) {
        if (!this.displayName || !this.urlName) {
            return;
        }

        await this.$store
            .dispatch('updateMerchantNames', {
                displayName: this.displayName,
                urlName: this.urlName,
            })
            .catch((err) => {
                this.$root.$emit('error', err);
                this.$router.push({ name: 'payment' });
            });

        accept();
    }

    protected accept() {
        return this.$root.$emit('confirm', {
            path: '',
            message: this.$t('settings.merchant.setupMessage'),
        });
    }

    protected quit() {
        this.$router.push({ name: 'payment' });
    }

    protected preview(previewData: any) {
        this.campaignData = previewData;
        this.banners = [];
        this.bannerSrc = [];
        for (let i = 0; i < previewData.banners.length; i++) {
            this.banners.push(previewData.banners[i]);
            this.bannerSrc.push(URL.createObjectURL(previewData.banners[i]));
        }

        this.editing = false;
    }

    protected back() {
        this.editing = true;
    }

    protected save() {
        // save to database
        (this.campaignData as any)!.currency = (this.campaignData as any)!.currency.value;

        this.$store
            .dispatch('createCampaign', {
                campaignData: this.campaignData,
            })
            .then((campaignId: string) => {
                this.$store
                    .dispatch('createCampaignBanners', {
                        campaignId: campaignId,
                        banners: this.banners,
                    })
                    .catch((err: any) => {
                        return this.$root.$emit('error', err);
                    });
            })
            .then(() => {
                this.submitted = true;
            })
            .catch((err: any) => {
                return this.$root.$emit('error', err);
            });
    }
}
