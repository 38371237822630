var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-page app-page-dashboard app-page--active" },
    [
      _c("div", { staticClass: "app-page-container" }, [
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid app-page-activity app-page-activity--active activity-dashboard"
          },
          [
            _c(
              "div",
              { staticClass: "mdc-layout-grid__inner" },
              [
                _vm.wechatEnabled
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.paymentMethod,
                                expression: "paymentMethod"
                              }
                            ],
                            staticClass: "mdc-text-field__input",
                            class: { "select--hidden": !_vm.paymentMethod },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.paymentMethod = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: { disabled: "" },
                                domProps: { value: null }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("general.searchCategory.type"))
                                )
                              ]
                            ),
                            _c("option", { attrs: { value: "all" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("general.searchCategory.all"))
                              )
                            ]),
                            _c("option", { attrs: { value: "card" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("general.paymentMethods.card"))
                              )
                            ]),
                            _c("option", { attrs: { value: "wechat" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("general.paymentMethods.wechat"))
                              )
                            ])
                          ]
                        ),
                        _c(
                          "label",
                          {
                            staticClass: "mdc-floating-label",
                            attrs: { for: "searchType" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("general.searchCategory.title"))
                            )
                          ]
                        ),
                        _c("div", { staticClass: "mdc-notched-outline" }, [
                          _c("svg", [
                            _c("path", {
                              staticClass: "mdc-notched-outline__path"
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "mdc-notched-outline__idle" })
                      ]
                    )
                  : _vm._e(),
                _vm._l(_vm.status, function(v, k) {
                  return _c(
                    "div",
                    {
                      key: k,
                      staticClass:
                        "dashboard-card--wrapper mdc-layout-grid__cell mdc-layout-grid__cell--span-4-tablet",
                      class: v.showMore
                        ? "mdc-layout-grid__cell--span-4-phone"
                        : "mdc-layout-grid__cell--span-2-phone",
                      on: {
                        click: function($event) {
                          _vm.isMaster && _vm.toggleCardDetails(k)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "dashboard-card dashboard-card--ondark",
                          class: {
                            "dashboard-card--show-more":
                              _vm.isMaster && v.showMore
                          },
                          style: {
                            backgroundColor: v.backgroundColor,
                            color: "#fff"
                          }
                        },
                        [
                          _c("div", { staticClass: "dashboard-card__title" }, [
                            _c("span", [_vm._v(_vm._s(v.label))])
                          ]),
                          _c(
                            "div",
                            { staticClass: "dashboard-card__content" },
                            [
                              _c("Spinner1", {
                                staticClass: "loading-spinner",
                                style: {
                                  visibility:
                                    v.value.sum == null ? "visible" : "hidden"
                                },
                                attrs: { active: v.value.sum == null }
                              }),
                              _c(
                                "div",
                                {
                                  style: {
                                    visibility:
                                      v.value.sum != null ? "visible" : "hidden"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dashboard-card__content--sum"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mdc-typography--headline5",
                                          staticStyle: {
                                            "font-weight": "500",
                                            "line-height": "1",
                                            height: "1em"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dashboard-card__content--sum-amount",
                                              staticStyle: {
                                                display: "inline-block"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  v.value.sum != null
                                                    ? v.metric.includes(
                                                        "amount"
                                                      )
                                                      ? _vm.formatCurrency(
                                                          v.value.sum
                                                        )
                                                      : v.value.sum
                                                    : "—"
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm.isMaster && _vm.accountsNameLoaded
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dashboard-card__content--more dashboard-card__content--extended"
                                        },
                                        [
                                          _c("hr", {
                                            staticClass:
                                              "dashboard-card__divider"
                                          }),
                                          _vm._l(
                                            Object.entries(v.value.shops)
                                              .filter(function(ref) {
                                                var a = ref[1]

                                                return a
                                              })
                                              .sort(function(ref, ref$1) {
                                                var a = ref[1]
                                                var b = ref$1[1]

                                                return b - a
                                              }),
                                            function(ref) {
                                              var shopId = ref[0]
                                              var amount = ref[1]
                                              return _c(
                                                "div",
                                                {
                                                  key: ["123", shopId].join(
                                                    "."
                                                  ),
                                                  staticClass:
                                                    "dashboard-card__content--shops-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mdc-typography--body2 dashboard-card__content--shops_name"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.accountsName[
                                                              shopId
                                                            ]
                                                          ) +
                                                          "\n                                        "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mdc-typography--body1 dashboard-card__content--shops_amount"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            v.metric.includes(
                                                              "amount"
                                                            )
                                                              ? _vm.formatCurrency(
                                                                  amount
                                                                )
                                                              : amount
                                                          ) +
                                                          "\n                                        "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isMaster,
                                  expression: "isMaster"
                                }
                              ],
                              staticClass: "dashboard-card__expand-icon",
                              class: {
                                "dashboard-card__expand-icon--disabled":
                                  !v.value.sum || !_vm.accountsNameLoaded
                              },
                              nativeOn: {
                                click: function($event) {
                                  _vm.isMaster && _vm.toggleCardDetails(k)
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "dashboard-card__expand-icon--icon",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 24 24"
                                  }
                                },
                                [
                                  !v.showMore
                                    ? _c("path", {
                                        attrs: {
                                          d:
                                            "M15.88 9.29L12 13.17 8.12 9.29a.996.996 0 1 0-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 0 0 0-1.41c-.39-.38-1.03-.39-1.42 0z"
                                        }
                                      })
                                    : _c("path", {
                                        attrs: {
                                          d:
                                            "M11.29 8.71L6.7 13.3a.996.996 0 1 0 1.41 1.41L12 10.83l3.88 3.88a.996.996 0 1 0 1.41-1.41L12.7 8.71a.996.996 0 0 0-1.41 0z"
                                        }
                                      })
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                }),
                _vm.payoutPermission
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.paymentMethod == "all" ||
                              _vm.paymentMethod == "card",
                            expression:
                              "paymentMethod == 'all' || paymentMethod == 'card'"
                          }
                        ],
                        staticClass:
                          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                      },
                      [_c("PayoutCard")],
                      1
                    )
                  : _vm._e()
              ],
              2
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }