

























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isMobileNumber } from '../../utils/validators';
import { MDCTextField } from '@material/textfield';
import { MDCFormField } from '@material/form-field';
import { MDCNotchedOutline } from '@material/notched-outline';
import AUTH_MSG from '../../constants/AUTH';

const env = process.env.VUE_APP_ENV;
@Component({})
export default class CustomerEditItem extends Vue {
    @Prop() index!: number;

    @Prop() customer!: CustomerData;

    @Prop() phoneList!: any[];

    @Prop() editingCustomerList: boolean = true;
    private countryCodeList = this.$store.state.countryCodeList;

    private fields = {
        name: { label: 'customer.name', postfix: 'customer.required' },
        countryCode: { label: 'customer.countryCode', postfix: 'customer.required' },
        phone: { label: 'customer.phone', postfix: 'customer.required' },
        remarks: { label: 'customer.remarks', postfix: 'customer.optional' },
        refNo: { label: 'customer.refNo', postfix: 'customer.optional' },
    };

    private customerEditingInfo = { ...this.customer };
    private editing = true;

    private edit() {
        if (this.editingCustomerList) {
            this.$root.$emit('error', { message: this.$t('errors.GENERAL.editing') });
            return;
        }

        this.customerEditingInfo = { ...this.customer };
        this.editing = true;
        this.$emit('edit', true);
    }

    private cancel() {
        this.editing = false;
        this.$emit('edit', false);
    }

    private save() {
        if (!this.customerEditingInfo.name || !this.customerEditingInfo.countryCode.phone_code || !this.customerEditingInfo.phone) {
            this.$root.$emit('error', { message: this.$t('errors.REMOTE.empty_customer') });
            return;
        }

        if (!isMobileNumber(`${this.customerEditingInfo.countryCode.phone_code}${this.customerEditingInfo.phone}`)) {
            this.$root.$emit('error', { message: AUTH_MSG.INVALID_MOBILE_FORMAT });
            return;
        }

        if (
            this.phoneList.some(phone => phone === `${this.customerEditingInfo.countryCode.phone_code}${this.customerEditingInfo.phone}`) &&
            this.phoneList[this.index - 1] != `${this.customerEditingInfo.countryCode.phone_code}${this.customerEditingInfo.phone}`
        ) {
            this.$root.$emit('error', { message: this.$t('errors.REMOTE.duplicated_customer') });
            return;
        }

        this.editing = false;
        this.$emit('edit', false);
        this.$emit('save', this.customerEditingInfo);
    }

    public autofill(isAutoFill?: boolean) {
        let c = this.customerEditingInfo;
        c.name = 'CHAN SIU MING';
        c.countryCode = {
            phone_code: '+852',
            en: 'Hong Kong (SAR)',
            cn: '香港',
            zh: '香港',
            alphanumeric_sender: 1,
        };
        c.phone = '65036201';
        c.remarks = 'testing';
        c.refNo = '000000';
    }

    private deleteCustomer() {
        this.$emit('delete');
    }

    get hasData() {
        return this.customer.name && this.customer.phone ? true : false;
    }

    get isTestingMode() {
        return process.env.VUE_APP_ENV !== 'production' ? true : false;
    }

    public async mounted() {
        // sort the country code due to language
        if (this.$i18n.locale == 'en') {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.en > b.en ? 1 : -1));
        } else {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.phone_code >= b.phone_code ? 1 : -1));
        }
        this.customerEditingInfo.countryCode = {
            phone_code: '+852',
            en: 'Hong Kong (SAR)',
            zh: '香港',
            cn: '香港',
            alphanumeric_sender: 1,
        };
        this.initMDCTextFields();
    }

    // animation function
    private initMDCTextFields() {
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
