var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "receipt" }, [
    _c(
      "div",
      { staticClass: "app-page app-page-payment-history app-page--active" },
      [
        _c("div", { staticClass: "app-page-container" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid app-page-activity app-page-activity--active activity-payment-history-expanded"
            },
            [
              _vm.charge
                ? _c("div", { staticClass: "payment-history-expanded-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "close-icon",
                        on: {
                          click: function($event) {
                            _vm.$router.go(-1)
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "close-icon__icon",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 24 24"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _c("div", {
                      staticClass: "payment-history-expanded-item__background"
                    }),
                    _vm.charge
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "payment-history-expanded-item__content"
                          },
                          [
                            _c("div", { staticClass: "section" }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mdc-typography--headline6",
                                    staticStyle: {
                                      margin: "0",
                                      "font-size": "1em",
                                      opacity: "0.75"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    #" +
                                        _vm._s(_vm.charge.receipt_no) +
                                        "\n                                "
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "section" }, [
                              _vm.charge
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row row--more-margin row--space-between"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "payment-history-expanded-item__amount mdc-typography--headline4"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm._f("currencySymbolDisplay")(
                                                  _vm.charge.currency
                                                )
                                              ) +
                                              _vm._s(_vm.charge.amount) +
                                              "\n                                "
                                          )
                                        ]
                                      ),
                                      _vm.charge.settlement_amount == 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "payment-history-expanded-item__amount mdc-typography--body2"
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(_vm.hkdConvent) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm.charge.currency != "hkd"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "payment-history-expanded-item__amount mdc-typography--body2"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    ($HKD" +
                                                    _vm._s(
                                                      _vm.charge
                                                        .settlement_amount
                                                    ) +
                                                    ")\n                                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row row--more-margin row--space-between"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "payment-history-expanded-item__date mdc-typography--caption"
                                    },
                                    [_vm._v(_vm._s(_vm.formatCreatedDate))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "payment-history-expanded-item__time mdc-typography--caption"
                                    },
                                    [_vm._v(_vm._s(_vm.formatCreatedTime))]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row row--more-margin row--space-between"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tags__group" },
                                    [
                                      _c("ChargeStatusTag", {
                                        attrs: { status: _vm.charge }
                                      }),
                                      _vm.charge.connect_remote_id
                                        ? _c("RemoteStatusTag", {
                                            attrs: { status: "default" }
                                          })
                                        : _vm.charge.connect_recurrence_id
                                          ? _c("RecurrentStatusTag")
                                          : _vm.charge.connect_campaign_order_id
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "tags tags--campaign filled"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "general.campaign"
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  )
                                                ]
                                              )
                                            : _vm.charge
                                                .connect_online_payment_id
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tags tags--online filled"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "general.online"
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tags tags--card filled"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "general.paymentMethods.card"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row row--more-margin row--space-between"
                                },
                                [
                                  _c("div", { staticClass: "buttons__group" }, [
                                    _vm.showPrinterButton
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "mdc-button mdc-button--raised",
                                            attrs: {
                                              disabled: !_vm.printerConnected
                                            },
                                            on: { click: _vm.print }
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "material-icons mdc-button__icon",
                                                staticStyle: {
                                                  transform:
                                                    "scale(0.833333333)"
                                                },
                                                attrs: {
                                                  "aria-hidden": "true",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  viewBox: "0 0 24 24"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d:
                                                      "M19,8H5c-1.66,0-3,1.34-3,3v4c0,1.1,0.9,2,2,2h2v2c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-2h2c1.1,0,2-0.9,2-2v-4,C22,9.34,20.66,8,19,8z M15,19H9c-0.55,0-1-0.45-1-1v-4h8v4C16,18.55,15.55,19,15,19z M19,12c-0.55,0-1-0.45-1-1s0.45-1,1-1,s1,0.45,1,1S19.55,12,19,12z M17,3H7C6.45,3,6,3.45,6,4v2c0,0.55,0.45,1,1,1h10c0.55,0,1-0.45,1-1V4C18,3.45,17.55,3,17,3z"
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "mdc-button__text"
                                              },
                                              [
                                                _vm.printerReady ||
                                                !_vm.printerConnected
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "actions.print"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : _c("Spinner2", {
                                                      staticStyle: {
                                                        "font-size": "0.5em"
                                                      },
                                                      attrs: { active: true }
                                                    })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.charge.connect_refund_id &&
                                    _vm.canRefund
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "mdc-button mdc-button--raised",
                                            on: { click: _vm.refund }
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "material-icons mdc-button__icon",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  viewBox: "0 0 24 24"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d:
                                                      "M11.9 6.5V4.4c0-.3-.4-.5-.6-.3L8.4 7c-.2.2-.2.4 0 .5l2.9 2.9c.2.2.6.1.6-.3V8c2.8 0 5 2.6 4.4 5.5-.4 1.7-1.7 3.1-3.4 3.4-2.7.6-5.1-1.3-5.4-3.8-.1-.4-.4-.6-.7-.6-.5 0-.8.4-.8.9.5 3.3 3.6 5.7 7.2 5.1 2.3-.5 4.2-2.3 4.7-4.7.6-3.9-2.3-7.3-6-7.3z"
                                                  }
                                                })
                                              ]
                                            ),
                                            _vm.charge.status === "succeeded"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "mdc-button__text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("actions.refund")
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.charge.status === "uncaptured"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "mdc-button__text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("actions.cancel")
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.charge.connect_recurrence_id &&
                                    !_vm.charge.connect_refund_id &&
                                    _vm.cancelRecurrencePermission &&
                                    _vm.canRefund
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "mdc-button mdc-button--raised",
                                            on: { click: _vm.refundAndCancel }
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "material-icons mdc-button__icon",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  viewBox: "0 0 24 24"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d:
                                                      "M11.9 6.5V4.4c0-.3-.4-.5-.6-.3L8.4 7c-.2.2-.2.4 0 .5l2.9 2.9c.2.2.6.1.6-.3V8c2.8 0 5 2.6 4.4 5.5-.4 1.7-1.7 3.1-3.4 3.4-2.7.6-5.1-1.3-5.4-3.8-.1-.4-.4-.6-.7-.6-.5 0-.8.4-.8.9.5 3.3 3.6 5.7 7.2 5.1 2.3-.5 4.2-2.3 4.7-4.7.6-3.9-2.3-7.3-6-7.3z"
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "mdc-button__text"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "receipt.refundAndCancelRecurrentButton"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "section" }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "mdc-typography--headline6" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "general.paymentDetails.description"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row row--more-margin row--space-between"
                                },
                                [
                                  _vm.charge.description
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "payment-history-expanded-item__desc mdc-typography--body1"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(_vm.charge.description) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "payment-history-expanded-item__desc mdc-typography--body1"
                                        },
                                        [_vm._v("—")]
                                      ),
                                  _vm.editChargeDescriptionPermission
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "mdc-button mdc-button--raised",
                                          on: {
                                            click:
                                              _vm.triggerEditChargeDescriptionDialog
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "mdc-button__text" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("actions.edit"))
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "section" },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mdc-typography--headline6"
                                    },
                                    [_vm._v(_vm._s(_vm.$t("receipt.copy")))]
                                  )
                                ]),
                                _vm._l(_vm.receiptCopyList, function(
                                  receiptCopy,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c("ReceiptCopy", {
                                        attrs: {
                                          existing: receiptCopy.existing,
                                          addButtonShown: _vm.addButtonShown,
                                          editing: receiptCopy.editing,
                                          chargeId: _vm.chargeId,
                                          index: index + 1
                                        },
                                        on: {
                                          "update:editing": function($event) {
                                            _vm.$set(
                                              receiptCopy,
                                              "editing",
                                              $event
                                            )
                                          },
                                          addReceiptCopy: function($event) {
                                            _vm.addReceiptCopy(index)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            ),
                            _c("div", { staticClass: "section" }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "mdc-typography--headline6" },
                                  [_vm._v(_vm._s(_vm.$t("general.card.info")))]
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c("VirtualCard", {
                                    attrs: { card: _vm.charge.creditcard }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c("div", { staticClass: "section" }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "mdc-typography--headline6" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("general.history.title"))
                                    )
                                  ]
                                )
                              ]),
                              _vm.refunded_at
                                ? _c(
                                    "div",
                                    { staticClass: "row row--space-between" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "payment-history-expanded-item__desc mdc-typography--body2"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("status.refunded"))
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "payment-history-expanded-item__datetime mdc-typography--caption"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.formatFullRefundedDate
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.charge.status
                                ? _c(
                                    "div",
                                    { staticClass: "row row--space-between" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "payment-history-expanded-item__desc mdc-typography--body2"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                {
                                                  succeeded: _vm.$t(
                                                    "status.succeeded"
                                                  ),
                                                  failed: _vm.$t(
                                                    "status.failed"
                                                  ),
                                                  pending: _vm.$t(
                                                    "status.pending"
                                                  ),
                                                  uncaptured: _vm.$t(
                                                    "status.authorized"
                                                  )
                                                }[_vm.charge.status]
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "payment-history-expanded-item__datetime mdc-typography--caption"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.formatFullCreatedDate
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _c(
                "div",
                [
                  _c(
                    "mdc-dialog",
                    {
                      ref: "printDialogLoading",
                      staticClass: "dialog--no_cancel",
                      attrs: {
                        title: _vm.$t("receipt.printer.printingReceipt"),
                        accept: ""
                      },
                      on: { cancel: _vm.preventCancel },
                      model: {
                        value: _vm.printDialogLoading_open,
                        callback: function($$v) {
                          _vm.printDialogLoading_open = $$v
                        },
                        expression: "printDialogLoading_open"
                      }
                    },
                    [
                      _c("div", { staticStyle: { "margin-bottom": "0.5em" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "spinner spinner__container",
                            class: {
                              "spinner--active": _vm.printDialogLoading_open
                            },
                            staticStyle: { "margin-right": "0.5em" }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "spinner spinner__icon",
                                attrs: {
                                  viewBox: "0 0 66 66",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("circle", {
                                  staticClass: "path",
                                  attrs: {
                                    fill: "none",
                                    "stroke-width": "6",
                                    "stroke-linecap": "round",
                                    cx: "33",
                                    cy: "33",
                                    r: "30"
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _c("span", [_vm._v(_vm._s(_vm.$t("actions.loading")))])
                      ])
                    ]
                  ),
                  _c(
                    "mdc-dialog",
                    {
                      attrs: {
                        title: _vm.$t("receipt.printer.failedToPrint"),
                        accept: _vm.$t("actions.accept")
                      },
                      model: {
                        value: _vm.printDialogFail_open,
                        callback: function($$v) {
                          _vm.printDialogFail_open = $$v
                        },
                        expression: "printDialogFail_open"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("receipt.printer.checkPrint")))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _vm.enoughBalance
                    ? _c(
                        "mdc-dialog",
                        {
                          ref: "refundDialogConfirm",
                          attrs: {
                            title: _vm.$t("receipt.refundConfirmation"),
                            accept: _vm.$t("actions.refund"),
                            cancel: _vm.$t("actions.cancel")
                          },
                          on: { accept: _vm.refundDialogConfirm_accept },
                          model: {
                            value: _vm.refundDialogConfirm_open,
                            callback: function($$v) {
                              _vm.refundDialogConfirm_open = $$v
                            },
                            expression: "refundDialogConfirm_open"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("receipt.refundOnceOnlyMsg")))
                          ])
                        ]
                      )
                    : _c(
                        "mdc-dialog",
                        {
                          ref: "refundDialogConfirm",
                          attrs: {
                            title: _vm.$t("receipt.refundFailed"),
                            accept: _vm.$t("actions.accept")
                          },
                          model: {
                            value: _vm.refundDialogConfirm_open,
                            callback: function($$v) {
                              _vm.refundDialogConfirm_open = $$v
                            },
                            expression: "refundDialogConfirm_open"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$t("receipt.notEnoughBalanceMsg"))
                            )
                          ])
                        ]
                      ),
                  _vm.enoughBalance
                    ? _c(
                        "mdc-dialog",
                        {
                          ref: "refundAndCancelDialogConfirm",
                          attrs: {
                            title:
                              _vm.$t("receipt.refundAndCancelRecurrent") + "?",
                            accept: _vm.$t("actions.accept"),
                            cancel: _vm.$t("actions.cancel")
                          },
                          on: {
                            accept: _vm.refundAndCancelDialogConfirm_accept
                          },
                          model: {
                            value: _vm.refundAndCancelDialogConfirm_open,
                            callback: function($$v) {
                              _vm.refundAndCancelDialogConfirm_open = $$v
                            },
                            expression: "refundAndCancelDialogConfirm_open"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("receipt.refundOnceOnlyMsg")))
                          ])
                        ]
                      )
                    : _c(
                        "mdc-dialog",
                        {
                          ref: "refundAndCancelDialogConfirm",
                          attrs: {
                            title: _vm.$t("receipt.refundFailed"),
                            accept: _vm.$t("actions.accept")
                          },
                          model: {
                            value: _vm.refundAndCancelDialogConfirm_open,
                            callback: function($$v) {
                              _vm.refundAndCancelDialogConfirm_open = $$v
                            },
                            expression: "refundAndCancelDialogConfirm_open"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$t("receipt.notEnoughBalanceMsg"))
                            )
                          ])
                        ]
                      ),
                  _c(
                    "mdc-dialog",
                    {
                      ref: "newChargeDescriptionDialog",
                      attrs: {
                        title: _vm.$t("actions.edit"),
                        accept: _vm.$t("actions.accept"),
                        cancel: _vm.$t("actions.cancel")
                      },
                      on: { accept: _vm.updateChargeDescription },
                      model: {
                        value: _vm.newChargeDescriptionDialog_open,
                        callback: function($$v) {
                          _vm.newChargeDescriptionDialog_open = $$v
                        },
                        expression: "newChargeDescriptionDialog_open"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-text-field mdc-text-field--outlined mdc-text-field--not-notched",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newChargeDescription,
                                expression: "newChargeDescription"
                              }
                            ],
                            staticClass: "mdc-text-field__input",
                            attrs: {
                              type: "text ",
                              id: "charge-description ",
                              autocomplete: "off",
                              autocorrect: "off",
                              spellcheck: "false"
                            },
                            domProps: { value: _vm.newChargeDescription },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.newChargeDescription = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "mdc-floating-label",
                              class: [
                                {
                                  "mdc-floating-label--float-above": _vm.newChargeDescription
                                    ? true
                                    : false
                                }
                              ],
                              attrs: { for: "payment-description" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("general.paymentDetails.description")
                                )
                              )
                            ]
                          ),
                          _c("div", { staticClass: "mdc-text-field-outline" })
                        ]
                      )
                    ]
                  ),
                  _c(
                    "mdc-dialog",
                    {
                      ref: "refundAndCancelDialogLoading",
                      staticClass: "dialog--no_cancel",
                      attrs: {
                        title: _vm.$t("actions.processing"),
                        accept: ""
                      },
                      on: { cancel: _vm.preventCancel },
                      model: {
                        value: _vm.refundAndCancelDialogLoading_open,
                        callback: function($$v) {
                          _vm.refundAndCancelDialogLoading_open = $$v
                        },
                        expression: "refundAndCancelDialogLoading_open"
                      }
                    },
                    [
                      _c("div", { staticStyle: { "margin-bottom": "0.5em" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "spinner spinner__container",
                            class: {
                              "spinner--active":
                                _vm.refundAndCancelDialogLoading_open
                            },
                            staticStyle: { "margin-right": "0.5em" }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "spinner spinner__icon",
                                attrs: {
                                  viewBox: "0 0 66 66",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("circle", {
                                  staticClass: "path",
                                  attrs: {
                                    fill: "none",
                                    "stroke-width": "6",
                                    "stroke-linecap": "round",
                                    cx: "33",
                                    cy: "33",
                                    r: "30"
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _c("span", [_vm._v(_vm._s(_vm.$t("actions.loading")))])
                      ])
                    ]
                  ),
                  _c(
                    "mdc-dialog",
                    {
                      ref: "refundDialogLoading",
                      staticClass: "dialog--no_cancel",
                      attrs: {
                        title: _vm.$t("actions.processing"),
                        accept: ""
                      },
                      on: { cancel: _vm.preventCancel },
                      model: {
                        value: _vm.refundDialogLoading_open,
                        callback: function($$v) {
                          _vm.refundDialogLoading_open = $$v
                        },
                        expression: "refundDialogLoading_open"
                      }
                    },
                    [
                      _c("div", { staticStyle: { "margin-bottom": "0.5em" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "spinner spinner__container",
                            class: {
                              "spinner--active": _vm.refundDialogLoading_open
                            },
                            staticStyle: { "margin-right": "0.5em" }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "spinner spinner__icon",
                                attrs: {
                                  viewBox: "0 0 66 66",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("circle", {
                                  staticClass: "path",
                                  attrs: {
                                    fill: "none",
                                    "stroke-width": "6",
                                    "stroke-linecap": "round",
                                    cx: "33",
                                    cy: "33",
                                    r: "30"
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _c("span", [_vm._v(_vm._s(_vm.$t("actions.loading")))])
                      ])
                    ]
                  ),
                  _c(
                    "mdc-dialog",
                    {
                      attrs: {
                        title: _vm.$t("status.failure"),
                        accept: _vm.$t("actions.accept")
                      },
                      model: {
                        value: _vm.refundDialogFail_open,
                        callback: function($$v) {
                          _vm.refundDialogFail_open = $$v
                        },
                        expression: "refundDialogFail_open"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("receipt.refundFailed")) +
                            " " +
                            _vm._s(_vm.$t("errors.GENERAL.retry"))
                        )
                      ])
                    ]
                  ),
                  _c(
                    "mdc-dialog",
                    {
                      attrs: {
                        title: _vm.errorResult,
                        accept: _vm.$t("actions.accept")
                      },
                      model: {
                        value: _vm.refundAndCancelDialogFail_open,
                        callback: function($$v) {
                          _vm.refundAndCancelDialogFail_open = $$v
                        },
                        expression: "refundAndCancelDialogFail_open"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.errorResult) +
                            " " +
                            _vm._s(
                              _vm.$t("receipt.cancelRecurrentFailedMsg", [
                                _vm.errorResult
                              ])
                            )
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }