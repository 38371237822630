


























































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import scrollIntoView from 'scroll-into-view-if-needed';
import { isEmail, isMobileNumber } from '../../utils/validators';
import AUTH_MSG from '../../constants/AUTH';
import moment from '@/utils/moment_HK_en';
import { currencyNameWithSymbol } from '../../utils/helpers/currencyHelper';
import { MDCTextField } from '@material/textfield';
import { MDCFormField } from '@material/form-field';
import { MDCNotchedOutline } from '@material/notched-outline';

const env = process.env.VUE_APP_ENV;
@Component({
    filters: {
        currencySymbolDisplay: currencyNameWithSymbol,
    },
})
export default class Summary extends Vue {
    @Prop() value!: any;

    @Prop({ default: false })
    isRecurrent!: boolean;

    @Prop() chargeData!: any;

    @Prop(String) display!: string;

    @Watch('display')
    onDisplayChanged(display: string) {
        if (display === 'summary') {
            let earliestPossibleExpiryData = moment().add(this.recurrentDuration, 'M').startOf('month');
            let cardExpiryData = moment(`${this.chargeData.token.card.exp_month}/${this.chargeData.token.card.exp_year}`, 'MM/YYYY');
            if (cardExpiryData.isBefore(earliestPossibleExpiryData, 'M')) {
                // this.$root.$emit('error', { message: `信用卡在支付所有期數前到期` });
                // this.$emit('back');
                this.expiredDiaglogConfirm_open = true;
            } else {
                this.$store.commit('showNavbar', false);
            }
        }
    }

    public customerData = {
        customer: {
            name: '',
            countryCode: { phone_code: '', en: '', cn: '', zh: '', alphanumeric_sender: 0 },
            phone: '',
            email: '',
            address: '',
            remarks: '',
            refNo: '',
        },
    };

    private fields = {
        name: { label: 'customer.name', postfix: 'customer.required' },
        countryCode: { label: 'customer.countryCode', postfix: 'customer.required' },
        phone: { label: 'customer.phone', postfix: 'customer.required' },
        email: { label: 'customer.email', postfix: 'customer.optional' },
        address: { label: 'customer.address', postfix: 'customer.optional' },
        remarks: { label: 'customer.remarks', postfix: 'customer.optional' },
        refNo: { label: 'customer.refNo', postfix: 'customer.optional' },
    };

    private payDialogConfirm?: HTMLElement;
    private payDialogConfirm_open = false;
    private expiredDiaglogConfirm_open = false;

    private countryCodeList = this.$store.state.countryCodeList;
    // computed properties

    get isTestingMode() {
        return env !== 'production' ? true : false;
    }

    get amount() {
        try {
            return this.chargeData.amount;
        } catch (error) {}
        // return this.$store.state.amount;
    }

    get currency() {
        return this.chargeData.currency;
    }

    get description() {
        try {
            return this.chargeData.description;
        } catch (error) {}
        // return this.$store.state.description;
    }

    get recurrentDuration() {
        try {
            return this.chargeData.recurrent.duration;
        } catch (error) {}
    }

    get recurrentAutoExtend() {
        try {
            return this.chargeData.recurrent.autoExtend;
        } catch (error) {}
    }

    get holderName() {
        try {
            return this.chargeData.token.card.name;
        } catch (error) {}
        // return this.$store.state.token.holder_name;
    }

    get cardNumber() {
        try {
            return `**** **** **** ${this.chargeData.token.card.last4}`;
        } catch (error) {}
        // return `**** **** **** ${this.$store.state.token.last4}`;
    }

    get expiryDate() {
        try {
            let exp_month = this.chargeData.token.card.exp_month,
                exp_year = this.chargeData.token.card.exp_year;
            return `${this.chargeData.token.card.exp_month
                .toString()
                .padStart(2, '0')} / ${this.chargeData.token.card.exp_year.toString().slice(-2)}`;
        } catch (error) {}
        // return `${this.$store.state.token.exp_month.toString().padStart(2, '0')} / ${this.$store.state.token.exp_year
        //     .toString()
        //     .slice(-2)}`;
    }

    get token() {
        try {
            return this.chargeData.token.id;
        } catch (error) {}
        // return this.$store.state.token.id;
    }
    public async mounted() {
        // sort the country code due to language
        if (this.$i18n.locale == 'en') {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.en > b.en ? 1 : -1));
        } else {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.phone_code >= b.phone_code ? 1 : -1));
        }

        this.initMDCTextFields();

        this.customerData.customer.countryCode = {
            phone_code: '+852',
            en: 'Hong Kong (SAR)',
            zh: '香港',
            cn: '香港',
            alphanumeric_sender: 1,
        };
    }
    public autofill(isAutoFill?: boolean) {
        let c = this.customerData.customer;
        c.name = 'CHAN SIU MING';
        c.countryCode = {
            phone_code: '+852',
            en: 'Hong Kong (SAR)',
            cn: '香港',
            zh: '香港',
            alphanumeric_sender: 1,
        };
        c.phone = '65036201';
        c.email = 'aigniter@gmail.com';
        c.address = 'happy street';
        c.remarks = 'happy';
        c.refNo = '000000';
    }

    protected pay() {
        if (this.isRecurrent) {
            let c = this.customerData.customer;
            if (!c.name || !c.phone) {
                this.$root.$emit('error', { message: '客戶姓名和電話是必須填寫。' });
                return;
            }
            if (!isMobileNumber(`${c.countryCode.phone_code}${c.phone}`)) {
                this.$root.$emit('error', { message: AUTH_MSG.INVALID_MOBILE_FORMAT });
                return;
            }

            if (c.email && !isEmail(c.email)) {
                this.$root.$emit('error', { message: AUTH_MSG.INVALID_EMAIL_FORAMT });
                return;
            }

            // console.log(earliestPossibleExpiryData.isAfter(moment()))

            this.payDialogConfirm_open = true;
            this.$emit('input', this.customerData);
            return;
        } else {
            return this.$emit('pay');
        }
    }

    protected previousPage() {
        // this.$store.commit('show', 'card');
        // this.$store.commit('showNavbar', false);
        this.$emit('back');
    }

    protected payDialogConfirm_accept() {
        if (this.isRecurrent) {
            this.$emit('input', this.customerData);
        }
        return this.$emit('pay');
    }

    // animation function
    private initMDCTextFields() {
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
