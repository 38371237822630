export const signup = {
    // Registration
    accountRegistration: '帐户注册',
    companyName: '公司名称',
    email: '电邮',
    password: '密码',
    confirmPassword: '确认密码',
    countryCode: '国家代码',
    phone: '电话号码',
    iAccept: '我已了解并同意 ',
    tos: ' Jarvix Pay 服务条款',
    and: ' 和',
    privacy: ' 私隐政策',
    fullStop: '。',
    signup: '注册',

    // Verification
    accountVerification: '帐户验证',
    verificationInputText: '请输入你的验证码',

    // Company
    companyInfo: '公司资料',
    businessType: '公司类别',
    limited: '有限公司',
    unlimited: '无限公司',
    businessBR: '商业登记号码 (BR)',
    businessCI: '公司注册证书 (CI)',
    statementDescriptor: '客户帐单显示名称',
    companyPhone: '公司电话',
    CompanyAddress: '公司地址',
    city: '城市',
    state: '国家 / 地区',
    businessDescription: '业务简介',
    businessWeb: '公司网站',

    // Owner
    companyOwner: '公司拥有人',
    companyRemind: '提醒：凡拥有公司超过25%, 相关公司拥有人资料必须申报',
    companyImportantOwner: '公司重要控制人',
    owner: '拥有人',
    lastName: '姓氏',
    firstName: '名字',
    identityType: '证件类别',
    identityNumber: '证件号码',
    hkid: '香港身份证',
    passport: '护照',
    dob: '出生日期',
    ownerPhone: '手机号码',
    address: '地址',
    addOwner: '+ 新增拥有人',

    // ExternalAccount
    externalAccountInfo: '收款银行户口资料',
    holderName: '账户持有人',
    holderType: '账户类型',
    companyAccount: 'Company (公司户口)',
    individualAccount: 'Individual (个人户口)',
    bank: '银行',
    branchCode: '分行编号',
    accountNumber: '账户号码',
    currency: '货币',

    // DocumentUpload
    uploadDocument: '上载证明文件',
    remind: '提醒：',
    remindItem: '* 为必填项目',
    remindDocs: '文件格式只接受 pdf / png / jpg',
    selectDocs: '选择文件',
    br: '商业登记证 (BR)',
    proof: '的证明文件',
    idProof: '身份证明文件只接受图片格式为png或jpg ',
    idFront: '选择身份证明文件（正面）',
    idBack: '选择身份证明文件（背面）',
    addressProof: '选择地址证明文件',
    bankProof: ' 收款银行户口证明',
    bankProofExample: '例如银行卡、月结单、支票簿',
    otherInfo: '其他资料 （可多于一项）',
    errorBR: '商业登记证 (BR)已上载档案的格式并不是 png, jpg或pdf',
    errorCI: '公司注册证书 (CI)已上载档案的格式并不是 png, jpg或pdf',
    errorBank: '收款银行户口证明已上载档案的格式并不是 png, jpg或pdf',
    errorIdentityFront: '拥有人{ownerNo}的身份证明文件（正面）已上载档案的格式并不是 png或jpg',
    errorIdentityBack: '拥有人{ownerNo}的身份证明文件（背面）已上载档案的格式并不是 png或jpg',
    errorAddress: '拥有人{ownerNo}的地址证明文件已上载档案的格式并不是 png, jpg或pdf',
    errorOther: '其他资料{otherNo}已上载档案的格式并不是 png, jpg或pdf',

    // Review
    preview: '预覧',
    confirm: '请确保所填资料正确无误，提交后将无法修改任何内容',
    company: '公司',
    name: '名称',
    type: '类别',
    clientName: '客户显示名称',
    document: '证明文件',
    changeInfo: '更改资料',

    // Signature
    sign: '签署',
    remindTerms: '必须填写所有资料以及签署所有条款',
    remindEmail: '提交签署后将有副本发送至联络电邮',
    contactEmail: '联络电邮',
    peopleName: '签署人全名',
    peopleTitle: '签署人职衔',
    gender: '称谓',
    male: '先生',
    female: '小姐',
    applicationForm: 'Jarvix Pay 商户申请表',
    partA: '甲部：公司资料',
    companySignAddress: '公司注册地址',
    brNumber: '商业登记号码',
    companyEmail: '公司电邮地址',
    partB: '乙部：申请人资料',
    idNumber: '身份证号码',
    personalAddress: '个人住址',
    partC: '丙部：商户收款银行资料',
    bankName: '收款银行名称',
    bankHolderName: '账户名称',
    partD: '丁部：Jarvix Pay 交易手续费',
    visaMaster: 'Visa/MasterCard',
    cardIssuer: '(由香港信用卡机构发行)',
    rateFee: '交易费率及交易手续费用',
    tradeAmount: '交易金额的',
    eachTrade: '每笔交易港币',
    remark: '备注',
    cashRebate: '现金回赠交易金额的',
    ae: 'American Express',
    unionpay: 'UnionPay',
    foreign: 'Visa/MasterCard/American Express/UnionPay',
    noneHKIssuer: '由非香港信用卡机构发行',
    arrivalDay: '到帐日',
    requiredDocs: 'Jarvix Pay 申请所需文件：',
    brCopy: '商业登记证副本',
    submitted: '已提交',
    ciCopy: '公司注册证书副本',
    limitedProvided: '有限公司需要提供',
    bankDocs: '收款银行账户文件',
    remindBank: '需显示公司名称及银行账户号',
    ownerIdCopy: '公司主要控制人的身份证副本',
    ownerAddressCopy: '公司主要控制人的住址证明副本',
    remindLegal: '我们确认上述资料属实，合法及有效。如果上述资料不符真相或违法，我们将承担一切后果及法律责任。 ',
    contract: '合同协议',
    submitContract: '提交申请',
    thanks: '感谢你的申请',
    pending: '你的帐户正在审批中。 ',
    reSign: '重新签署',
    remindReSign: '重新签署后旧合约会立即废除，一切将根据新合约内容进行',

    //Signing
    signHereLabel: '按此签署',
    signHere: '在此签名',

    // Rejected
    unfortunately: '很遗憾',
    unsuccessful: '你的申请不成功',
    contact_us: '如有疑问，请联络我们，谢谢！',
};
