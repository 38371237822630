var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mdc-layout-grid activity-payment-summary",
      attrs: { id: "summary" }
    },
    [
      _c("div", { staticClass: "mdc-layout-grid__inner" }, [
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet payment-summary",
            staticStyle: { flex: "1 1 auto" }
          },
          [
            _c("div", { staticClass: "payment-summary__wrapper-inner" }, [
              _c(
                "div",
                { staticClass: "payment-summary__inner" },
                [
                  _c("div", { staticClass: "mdc-typography--headline6" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("general.summary")))]),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.chargeData && _vm.chargeData.recurrent,
                            expression: "chargeData && chargeData.recurrent"
                          }
                        ],
                        staticClass: "tags filled tags--recurrent"
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("general.recurrent")) +
                            "\n                        "
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "mdc-typography--body2" }, [
                    _vm._v(_vm._s(_vm.$t("general.warningMsg")))
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isRecurrent,
                          expression: "!isRecurrent"
                        }
                      ],
                      staticClass: "mdc-typography--subtitle1"
                    },
                    [_vm._v(_vm._s(_vm.$t("payment.amount.paymentAmount")))]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isRecurrent,
                          expression: "isRecurrent"
                        }
                      ],
                      staticClass: "mdc-typography--subtitle1"
                    },
                    [_vm._v(_vm._s(_vm.$t("recurrent.amount")) + ":")]
                  ),
                  _c("div", { staticClass: "mdc-typography--body1" }, [
                    _vm._v(
                      _vm._s(_vm._f("currencySymbolDisplay")(_vm.currency)) +
                        _vm._s(_vm.amount)
                    )
                  ]),
                  _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                    _vm._v(_vm._s(_vm.$t("general.paymentDetails.description")))
                  ]),
                  _c("div", { staticClass: "mdc-typography--body1" }, [
                    _vm._v(_vm._s(_vm.description || "—"))
                  ]),
                  _vm.isRecurrent
                    ? [
                        _c(
                          "div",
                          { staticClass: "mdc-typography--subtitle1" },
                          [_vm._v(_vm._s(_vm.$t("recurrent.duration")))]
                        ),
                        _c("div", { staticClass: "mdc-typography--body1" }, [
                          _vm._v(_vm._s(_vm.recurrentDuration || "—"))
                        ]),
                        _c(
                          "div",
                          { staticClass: "mdc-typography--subtitle1" },
                          [_vm._v(_vm._s(_vm.$t("recurrent.autoRenewal")))]
                        ),
                        _c("div", { staticClass: "mdc-typography--body1" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.recurrentAutoExtend
                                  ? "" +
                                    (_vm.recurrentDuration
                                      ? _vm.$t("recurrent.autoRenewalMessage", [
                                          _vm.recurrentDuration
                                        ])
                                      : "")
                                  : "—"
                              ) +
                              "\n                        "
                          )
                        ])
                      ]
                    : _vm._e(),
                  _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                    _vm._v(_vm._s(_vm.$t("general.card.holderName")))
                  ]),
                  _c("div", { staticClass: "mdc-typography--body1" }, [
                    _vm._v(_vm._s(_vm.holderName || "—"))
                  ]),
                  _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                    _vm._v(_vm._s(_vm.$t("general.card.number")))
                  ]),
                  _c("div", { staticClass: "mdc-typography--body1" }, [
                    _vm._v(_vm._s(_vm.cardNumber || "—"))
                  ]),
                  _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                    _vm._v(_vm._s(_vm.$t("general.card.expiryDate")))
                  ]),
                  _c("div", { staticClass: "mdc-typography--body1" }, [
                    _vm._v(_vm._s(_vm.expiryDate || "—"))
                  ])
                ],
                2
              )
            ])
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.chargeData && _vm.chargeData.recurrent,
                expression: "chargeData && chargeData.recurrent"
              }
            ],
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
          },
          [
            _c(
              "form",
              {
                staticClass: "big-card editing",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c("div", {
                  ref: "scrollIntoViewArea",
                  staticClass: "scroll-into-view-area"
                }),
                _c(
                  "div",
                  {
                    ref: "inner",
                    staticClass: "big-card__inner",
                    style: { maxHeight: _vm.value.customer }
                  },
                  [
                    _c("div", { staticClass: "big-card__section" }, [
                      _c("div", { staticClass: "header" }, [
                        _c(
                          "div",
                          {
                            ref: "title",
                            staticClass: "mdc-typography--headline6"
                          },
                          [_vm._v(_vm._s(_vm.$t("customer.title")))]
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "big-card__section" },
                      [
                        _vm._l(_vm.fields, function(f, k) {
                          return [
                            k != "countryCode"
                              ? _c(
                                  "div",
                                  { key: k, staticClass: "fields" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mdc-typography--subtitle1"
                                      },
                                      [_vm._v(_vm._s(f.label.zhHK))]
                                    ),
                                    _c("mdc-textfield", {
                                      ref: "textfield-" + k,
                                      refInFor: true,
                                      attrs: {
                                        label:
                                          _vm.$t(f.label) + _vm.$t(f.postfix),
                                        fulliwdth: "",
                                        outline: "",
                                        autocorrect: "off",
                                        spellcheck: "false",
                                        autocomplete: "off"
                                      },
                                      model: {
                                        value: _vm.customerData.customer[k],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.customerData.customer,
                                            k,
                                            $$v
                                          )
                                        },
                                        expression: "customerData.customer[k]"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    key: k,
                                    staticClass: "fields",
                                    staticStyle: { height: "42px" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                                      },
                                      [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.customerData.customer
                                                    .countryCode,
                                                expression:
                                                  "customerData.customer.countryCode"
                                              }
                                            ],
                                            staticClass:
                                              "mdc-text-field__input",
                                            attrs: { id: "countryCode" },
                                            on: {
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  _vm.customerData.customer,
                                                  "countryCode",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: { disabled: "" },
                                                domProps: { value: null }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "customer.countryCode"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.countryCodeList,
                                              function(code) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: code.en,
                                                    domProps: { value: code }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$i18n.locale ==
                                                        "zh_HK"
                                                          ? code.zh
                                                          : _vm.$i18n.locale ==
                                                            "zh_CN"
                                                            ? code.cn
                                                            : code.en
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          "(" +
                                                            code.phone_code +
                                                            ")"
                                                        )
                                                    )
                                                  ]
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        ),
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "mdc-floating-label mdc-floating-label--float-above"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("customer.countryCode")
                                              ) +
                                                _vm._s(
                                                  _vm.$t("customer.required")
                                                )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mdc-notched-outline"
                                          },
                                          [
                                            _c("svg", [
                                              _c("path", {
                                                staticClass:
                                                  "mdc-notched-outline__path"
                                              })
                                            ])
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass:
                                            "mdc-notched-outline__idle"
                                        })
                                      ]
                                    )
                                  ]
                                )
                          ]
                        })
                      ],
                      2
                    )
                  ]
                ),
                _c(
                  "mdc-dialog",
                  {
                    ref: "payDialogConfirm",
                    attrs: {
                      title: _vm.$t("actions.confirm"),
                      accept: _vm.$t("actions.confirm"),
                      cancel: _vm.$t("actions.cancel")
                    },
                    on: { accept: _vm.payDialogConfirm_accept },
                    model: {
                      value: _vm.payDialogConfirm_open,
                      callback: function($$v) {
                        _vm.payDialogConfirm_open = $$v
                      },
                      expression: "payDialogConfirm_open"
                    }
                  },
                  [_c("div", [_vm._v(_vm._s(_vm.$t("actions.confirm")))])]
                ),
                _c(
                  "mdc-dialog",
                  {
                    ref: "errorDialog",
                    attrs: {
                      title: _vm.$t("errors.label"),
                      accept: _vm.$t("actions.accept")
                    },
                    on: {
                      change: function($event) {
                        _vm.$emit("back")
                      }
                    },
                    model: {
                      value: _vm.expiredDiaglogConfirm_open,
                      callback: function($$v) {
                        _vm.expiredDiaglogConfirm_open = $$v
                      },
                      expression: "expiredDiaglogConfirm_open"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("general.card.error.invalidExpiryDate"))
                    )
                  ]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
          },
          [
            _c(
              "button",
              { staticClass: "mdc-button", on: { click: _vm.previousPage } },
              [_vm._v(_vm._s(_vm.$t("actions.back")))]
            ),
            _vm.isTestingMode
              ? _c(
                  "button",
                  {
                    staticClass: "mdc-button",
                    on: {
                      click: function($event) {
                        _vm.autofill(true)
                      }
                    }
                  },
                  [_vm._v("Auto fill")]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "mdc-fab mdc-fab--extended",
                attrs: { "aria-label": _vm.$t("actions.confirm") },
                on: { click: _vm.pay }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "mdc-fab__icon",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 24 24"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M11.7 11.1c-1.8-.5-2.4-1-2.4-1.7 0-.9.8-1.5 2.2-1.5 1.1 0 1.7.4 1.9 1.1.1.3.4.6.7.6h.2c.5 0 .9-.5.7-1-.3-1-1.1-1.7-2.4-2V6c0-.7-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2v.5c-1.5.3-2.7 1.3-2.7 2.9 0 1.9 1.5 2.8 3.8 3.3 2 .5 2.4 1.2 2.4 1.9 0 .6-.4 1.4-2.2 1.4-1.3 0-2-.5-2.3-1.2-.1-.3-.4-.5-.7-.5h-.2c-.5 0-.9.5-.7 1 .5 1.1 1.5 1.8 2.7 2v.7c0 .7.5 1.2 1.2 1.2s1.2-.5 1.2-1.2v-.5c1.6-.3 2.8-1.2 2.8-2.9 0-2.2-1.9-3-3.8-3.5z"
                      }
                    })
                  ]
                ),
                _c("span", { staticClass: "mdc-fab__label" }, [
                  _vm._v(_vm._s(_vm.$t("actions.confirm")))
                ])
              ]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }