var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app",
      class: {
        "bottom-nav--hidden": !_vm.showNavbar,
        "mdc-top-app-bar--hidden": !_vm.showTopbar && !_vm.showMerchantTopbar
      },
      attrs: { id: "app" }
    },
    [
      _vm.showTopbar ? _c("Topbar") : _vm._e(),
      _vm.showMerchantTopbar ? _c("MerchantTopbar") : _vm._e(),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "transition",
            [
              _c(
                "keep-alive",
                { attrs: { include: _vm.keepAliveIncludes.routerView } },
                [_c("router-view", { ref: "routerView" })],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showScrollBtn ? _c("ScrollBtn") : _vm._e(),
      _c("Navbar", {
        on: {
          "navbar-item-clicked": function($event) {
            _vm.onNavbarItemClicked($event)
          }
        }
      }),
      _c("Loading", { attrs: { isLoading: _vm.isLoading } }),
      _c("LogoutDialog"),
      _c("ErrorDialog"),
      _c("ConfirmDialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }