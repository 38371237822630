export const AUTH = {
    INVALID_EMAIL_FORAMT: '电子邮件地址无效。',
    INVALID_STATEMENTDESCRIPTOR_FORMAT_TOO_SHORT: '客户帐单显示名称需多于5英文字母。',
    INVALID_STATEMENTDESCRIPTOR_FORMAT_TOO_LONG: '客户帐单显示名称需少于22个字元。',
    INVALID_NAME_FORMAT: '用户名称格式无效。',
    INVALID_COMPANY_NAME_FORMAT: '公司名称格式无效。',
    INVALID_PASSWORD_FORMAT: '密码格式无效, 密码必须需要至少8个字元。',
    CONFIRM_FAILURE: '确认密码无效。',
    INVALID_MOBILE_FORMAT: '电话号码无效。',
    INVALID_VERIFICATION_CODE: '验证码无效。',
    INVALID_HKID: '身份证号码不正确。',
    INVALID_SUPPORTING_DOCUMENT: '证明文件无效。',
    ADDRESS_TOO_LONG: '地址需少于200个字元。',
    DESCRIPTION_TOO_LONG: '业务简介需少于200个字元。',
    INVALID_WEBSITE_FORMAT: '公司网站无效。',
    EMPTY_FIELD: '请输入所有资料。',
    NO_SELECTED_ACCOUNT: '未选择分店',
    NO_SELECTED_USER: '未选择用户',
    INCORRECT_CONFIRMED_VALUE: '确认资料不正确。',
    DUPLICATED_OLD_VALUE: '新资料不能与旧资料一样。',
    DUPLICATED_MERCHANT_NAME: '该名称已被使用, 请尝试另一个名称。',
    EMAIL_USED: '电子邮件已被注册。',
    SIGNUP_SUCCESS: '成功注册。',
    LOGIN_SUCCESS: '成功登入。',
    ACTIVATE_USER_SUCCESS: '成功验证帐号。',
    INCORRECT_CREDENTIALS: '密码不正确。',
    HOLDER_NAME_TOO_LONG: '持卡人姓名需少于200个字元。',
    INVALID_HOLDER_NAME: '持卡人姓名格式无效。',
    NOT_ACCEPTING_TOS: '阁下必须了解并同意 Jarvix Pay 服务条款。',
    DOCUMENT_ERROR: '文件格式错误，我们只支持jpeg, png的格式, 请重新上传',
    LOGIN_AGAIN: '请重新登入',
};
