export const receipt = {
    number: 'Receipt Number',
    copy: 'Receipt Copy',
    refundConfirmation: 'Refund Confirmation',
    cancelRecurrentFailedMsg: 'Canceling Recurrent Failed',
    refundFailed: 'Refund Failed.',
    refundSucceeded: 'Refund Succeeded.',
    refundOnceOnlyMsg: 'You can refund once, and you cannot cancel after confirmation.',
    refundAndCancelRecurrentButton: 'Refund & Cancel Recurrent',
    notEnoughBalanceMsg: 'Insufficient balance, please contact JARVIX PAY.',
    printer: {
        printingReceipt: 'Printing receipt',
        failedToPrint: 'Failed to print',
        checkPrint: 'Please check the printer connection and try again',
    },
};
