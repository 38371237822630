











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import RemotePayment from '@/views/remote/RemotePayment.vue';
import RemoteHistory from '@/views/remote/RemoteHistory.vue';
import { async } from 'rxjs/internal/scheduler/async';

@Component({
    components: {
        RemotePayment,
        RemoteHistory,
    },
})
export default class Remote extends Vue {
    @Prop()
    page!: string;

    private mdcTabBar!: any;
    private display: 'payment' | 'history' = 'payment';
    private editing = true;

    protected onTabUpdated(index: number) {
        if (index === 0) {
            this.display = 'payment';
            this.$store.dispatch('logUserNavigation', { to: 'remote', from: 'remote.history' });
        }

        if (index === 1) {
            this.display = 'history';
            this.$store.dispatch('logUserNavigation', { to: 'remote.history', from: 'remote' });
        }
    }

    public mounted() {
        this.mdcTabBar = this.$refs.mdcTabBar;
        this.mdcTabBar.tabs[0].foundation.setActive(true);

        if (this.page == 'history') {
            this.toHistoryTab();
        }
    }

    private toHistoryTab() {
        (this.$el.getElementsByClassName('mdc-tab__label')[1] as HTMLElement).click();
        return;
    }
}
