



























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isEmail, isMobileNumber } from '../../utils/validators';
import AUTH_MSG from '@/constants/AUTH';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import { IMaskComponent } from 'vue-imask';

@Component({
    components: {
        'imask-input': IMaskComponent,
    },
})
export default class NewUser extends Vue {
    private mdcTextField: any;
    private accountId = '';
    private userType = '';
    private userTypeOptions = [];
    private username = '';
    private email = '';
    private password = '';
    private phone = '';
    private confirmPassword = '';
    private isMasterAccount = '';
    private accountPermissionList: any = '';
    private permissions = {
        account: {
            add_user: false,
            edit_permission: false,
            add_account: false,
            modify_information: false,
        },
        payment: {
            refund: false,
            cancel_recurrence: false,
            change_recurrence_credit_card: false,
            edit_charge_description: false,
            capture: false,
            enable_recurrent: false,
            enable_remote: false,
            enable_campaign: false,
        },
        functional: {
            dashboard: false,
            printer: false,
            export: false,
            payout: false,
            campaign_agent: false,
            transactions_history: false,
        },
        notification: {
            payment: false,
            campaign: false,
            summary: false,
        },
        // dashboard: false,
        // payout: false,
        // export: false,
    };
    private permissionTranslate = {
        add_user: 'settings.permissions.account.addUser',
        add_account: 'settings.permissions.account.addAccount',
        edit_permission: 'settings.permissions.account.editPermission',
        modify_information: 'settings.permissions.account.modifyInformation',

        refund: 'settings.permissions.payment.refund',
        cancel_recurrence: 'settings.permissions.payment.cancelRecurrence',
        change_recurrence_credit_card: 'settings.permissions.payment.changeRecurrenceCreditCard',
        edit_charge_description: 'settings.permissions.payment.editChargeDescription',
        capture: 'settings.permissions.payment.capture',
        enable_recurrence: 'settings.permissions.payment.enable_recurrence',
        enable_remote: 'settings.permissions.payment.enable_remote',
        enable_campaign: 'settings.permissions.payment.enable_campaign',

        dashboard: 'settings.permissions.functional.dashboard',
        payout: 'settings.permissions.functional.payout',
        export: 'settings.permissions.functional.export',
        printer: 'settings.permissions.functional.printer',
        // wechat: '微訊支付',
        campaign_agent: 'settings.permissions.functional.campaignAgent',
        transactions_history: 'settings.permissions.functional.transactionsHistory',

        payment: 'settings.permissions.notification.payment',
        campaign: 'settings.permissions.notification.campaign',
        summary: 'settings.permissions.notification.summary',
    };

    private allowedPermission: any = '';

    private accountOptions = [];

    private errors: string[] = [];

    private engCharMask = /^[a-zA-Z0-9!@#$%^&()~`_\-+=,.?/:;{}\[\]| ]*$/;

    private selectedCountryCode = null;

    private countryCodeList = this.$store.state.countryCodeList;

    get master() {
        return this.$store.state.user.permissions_ ? this.$store.state.user.permissions_.master : false;
    }

    get currentUserType() {
        return this.$store.state.user.permissions_.userType;
    }

    get okToSubmit() {
        return !!this.accountId && !!this.userType && !!this.username && !!this.email && !!this.password && !!this.confirmPassword;
    }

    //options is the list of option that the user can choose
    //the options will be shown based on which permission that the account have
    /*
        options = [
            {name: "add_user", value: true},
            {name: "add_account", value: false},
            {name: "printer", value: false}
            ...
        ]
    */
    get options() {
        let o: any = [];
        for (let key in this.allowedPermission) {
            if (key !== 'accountId') {
                for (let subkey in this.allowedPermission[key]) {
                    if (this.allowedPermission[key][subkey] != 0 && this.permissionTranslate.hasOwnProperty(subkey)) {
                        //default printer permission is true
                        if (
                            subkey != 'printer' &&
                            subkey != 'enable_remote' &&
                            subkey != 'enable_recurrence' &&
                            subkey != 'enable_campaign'
                        ) {
                            o.push({ name: subkey, value: false });
                        } else {
                            o.push({ name: subkey, value: true });
                        }
                    }
                }
            }
        }
        return o;
    }

    //while userType changed from user to superuser, all option.value in options will be set to true, which mean superuser has all permission(and all the option set to disable)
    //while userType changed from superuser to user, all option.value in options will be set to false expect printer
    @Watch('userType')
    OnUserTypeChanged(n: string, o: string) {
        if (n == 'superuser') {
            for (let option of this.options) {
                option.value = true;
            }
        } else if (n == 'user') {
            for (let option of this.options) {
                if (option.name != 'printer') {
                    option.value = false;
                } else {
                    option.value = true;
                }
            }
        }
    }

    //while the store(account) changed, the allowedPermission will be changed to the corresponding account allowed permissions
    protected changeStore() {
        this.isMasterAccount = this.accountOptions
            .filter((account: any) => account.id == this.accountId)
            .map((account: any) => account.master)[0];
        if (!this.isMasterAccount && this.userType == 'superuser') {
            this.userType = 'user';
        }
        for (let permission of this.accountPermissionList) {
            if (permission.accountId == this.accountId) {
                this.allowedPermission = permission;
            }
        }
        // this.userType = this.isMasterAccount ? this.userType : 'user';
    }

    public async mounted() {
        this.initMDCTextFields();
        if (this.master) {
            this.accountOptions = await this.$store.dispatch('retreiveStores');
        } else {
            this.accountId = this.$store.state.user.accountId;
        }
        this.accountPermissionList = await this.$store.dispatch('retreiveStorePermission');

        // sort the country code due to language
        if (this.$i18n.locale == 'en') {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.en > b.en ? 1 : -1));
        } else {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.phone_code >= b.phone_code ? 1 : -1));
        }
    }

    // plug the option.value to the corresponding permission in this.permissions
    private permissionFormation() {
        for (let k of this.options) {
            for (let key in this.permissions) {
                for (let subkey in (this.permissions as any)[key]) {
                    if (k['name'] == subkey) {
                        (this.permissions as any)[key][subkey] = k['value'];
                        break;
                    }
                }
            }
        }
    }

    protected submit() {
        this.errors = [];

        if (!this.username) {
            this.errors.push(AUTH_MSG.EMPTY_FIELD);
        }

        if (!isEmail(this.email)) {
            this.errors.push(AUTH_MSG.INVALID_EMAIL_FORAMT);
        }

        if (this.password.length < 8) {
            this.errors.push(AUTH_MSG.INVALID_PASSWORD_FORMAT);
        }

        if (this.confirmPassword !== this.password) {
            this.errors.push(AUTH_MSG.CONFIRM_FAILURE);
        }

        if (this.phone) {
            if (!isMobileNumber(`${(this.selectedCountryCode as any).phone_code}${this.phone}`)) {
                this.errors.push(AUTH_MSG.INVALID_MOBILE_FORMAT);
            }
        }

        if (this.errors.length) {
            return;
        }

        this.permissionFormation();

        // console.log(this.permissions, this.userType);
        return this.$store
            .dispatch('addUser', {
                accountId: this.accountId,
                email: this.email,
                username: this.username,
                password: this.password,
                permissions: this.permissions,
                userType: this.userType,
                country: this.selectedCountryCode != null ? (this.selectedCountryCode as any).en : null,
                countryCode: this.selectedCountryCode != null ? (this.selectedCountryCode as any).phone_code : null,
                phone: this.phone ? this.phone : null,
            })
            .then((response: any) => {
                return this.$root.$emit('confirm', {
                    path: '/settings',
                    title: this.$t('status.success'),
                });
            })
            .catch((error: any) => {
                return this.$root.$emit('error', error);
            });
    }

    // animation function
    private initMDCTextFields() {
        this.mdcTextField = Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
