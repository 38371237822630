export const general = {
    payment: '收款',
    direct: '直碌',
    recurrent: '月付',
    remote: '遙距',
    campaign: '宣傳',
    online: '網上通道',
    dashboard: '總覽',
    payout: '轉帳記錄',
    transactions: '交易記錄',
    settings: '設定',
    notifications: '通知',

    planning: '策劃',
    summary: '總結',
    keyword: '關鍵字',

    searchCategory: {
        title: '類別',
        all: '全部',
        description: '詳情',
        receiptNumber: '單據編號',
        status: '狀態',
        type: '種類',
        cashflow: '應收月付',
    },

    paymentMethods: {
        label: '付款方式',
        qrPayment: '二維碼支付',
        card: '信用卡',
        wechat: '微信支付',
        alipay: '支付寶',
    },

    card: {
        label: '信用卡',
        number: '信用卡號碼',
        holderName: '持卡人姓名',
        expiryDate: '到期日',
        info: '信用卡資料',
        error: {
            missingCardNumber: '請輸入信用卡號碼。',
            missingExpiryDate: '請輸入信用卡到期日。',
            missingCvc: '請輸入信用卡驗證碼。',
            missingCardHolderName: '請輸入持卡人姓名。',
            invalidExpiryDate: '信用卡在支付所有期數前到期。',
        },
    },

    paymentDetails: {
        quantity: '數量',
        amount: '金額',
        description: '詳情',
        remoteMessage: '客戶訊息',
        totalAmount: '總金額',
    },

    date: {
        start: '開始日期',
        end: '結束日期',
    },

    history: {
        title: '記錄',
        payment: '付款記錄',
        expectedIncome: '預期收入',
        expectedCount: '預期筆數',
        chargeCount: '期數',
    },

    login: {
        agreementHeader: '登入即代表您已瞭解並同意我們的',
        privacy: '私隱政策',
        tnc: '服務條款',
    },

    thisStore: '本分店',
    warningMsg: '請仔細檢查所輸入的資料，一經送出將不能更改！',
    confirmInfo: '確認資料',
    confirmMsg: '您確定輸入資料無誤?',
    wechatPayDisable: '微信支付尚未開通，請聯絡相關客戶經理。 (3618 7750 / 6010 5244)',
    qrPaymentDisable: '微信支付/支付寶尚未開通，請聯絡相關客戶經理。 (3618 7750 / 6010 5244)',
};
