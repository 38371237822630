export const compressImageFile = (file: any, quality: number, orientation: any) => {
	
    if (quality === void 0) { quality = 0.92; }
    return new Promise(function (resolve, reject) {
        if (file.type.startsWith('image')) {
            if (file.type.match(/jpeg|png/)) {
                const reader = new FileReader();
                reader.onload = function () {
                    const dataURL = this.result;
                    const img = new Image();

                    img.onload = function () {
						const pixel = img.width * img.height
						const thumbnailPixel = 4000000;
						const compressedPixel = 8000000;

						const thumbnailScaleRatio = ((thumbnailPixel / pixel) < 1) ?  (thumbnailPixel / pixel) : 1;
						const thumbnailWidth = img.width * thumbnailScaleRatio;
						const thumbnailHeight = img.height * thumbnailScaleRatio;
                        const thumbnailCanvas = document.createElement('canvas');
						const thumbnailCtx = thumbnailCanvas.getContext('2d') as CanvasRenderingContext2D;

						const scaleRatio = ((compressedPixel / pixel) < 1) ?  (compressedPixel / pixel) : 1;
						const width = img.width * scaleRatio;
						const height = img.height * scaleRatio;
                        const canvas = document.createElement('canvas');
						const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

						if(orientation != "" && orientation != 1 && orientation != undefined){
							switch(orientation){
								case 6://需要顺时针（向左）90度旋转
									{
										let degree = 90 * Math.PI / 180;

										thumbnailCanvas.width = thumbnailHeight;
										thumbnailCanvas.height = thumbnailWidth;
										thumbnailCtx.rotate(degree);
										thumbnailCtx.drawImage(img, 0, -thumbnailHeight, thumbnailWidth, thumbnailHeight);

										canvas.width = height;
										canvas.height = width;
										ctx.rotate(degree);
										ctx.drawImage(img, 0, -height, width, height);

										break;
									};

								case 3://需要180度旋转
									{
										let degree = 180 * Math.PI / 180;

										thumbnailCanvas.width = thumbnailWidth;
										thumbnailCanvas.height = thumbnailHeight;
										thumbnailCtx.rotate(degree);
										thumbnailCtx.drawImage(img, -thumbnailWidth, -thumbnailHeight, thumbnailWidth, thumbnailHeight);

										canvas.width = width;
										canvas.height = height;
										ctx.rotate(degree);
										ctx.drawImage(img, -width, -height, width, height);

										break;
									}

								case 8://需要逆时针（向右）90度旋转
									{
										let degree = 270 * Math.PI / 180;

										thumbnailCanvas.width = thumbnailHeight;
										thumbnailCanvas.height = thumbnailWidth;
										thumbnailCtx.rotate(degree);
										thumbnailCtx.drawImage(img, -thumbnailWidth, 0, thumbnailWidth, thumbnailHeight);

										canvas.width = height;
										canvas.height = width;
										ctx.rotate(degree);
										ctx.drawImage(img, -width, 0, width, height);

										break;
									}
							}
						} else {
							thumbnailCanvas.width = thumbnailWidth;
							thumbnailCanvas.height = thumbnailHeight;
							thumbnailCtx.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight);

							canvas.width = width;
							canvas.height = height;
							ctx.drawImage(img, 0, 0, width, height);
						}

						const compressedImageFile = new Promise((resolve, reject) => {
							ctx.canvas.toBlob((blob) => {
								const compressedFile = new File([blob as Blob], file.name, {
									type: 'image/jpeg',
									lastModified: Date.now()
								});
								resolve(compressedFile);
							}, 'image/jpeg', quality);
						})
						
						const thumbnailImageFile = new Promise((resolve, reject) => {
							thumbnailCtx.canvas.toBlob((blob) => {
								const compressedFile = new File([blob as Blob], file.name, {
									type: 'image/jpeg',
									lastModified: Date.now()
								});
								resolve(compressedFile);
							}, 'image/jpeg', quality);
						})
						
						return Promise.all([compressedImageFile, thumbnailImageFile]).then(files => resolve({
							compressed: files[0],
							thumbnail: files[1]
						}));
                    };

                    img.src = dataURL as string;
                };
                reader.readAsDataURL(file);
            }
            else {
                reject(new Error('Only support jpeg, jpg, png type'));
            }
        }
        else {
            reject(new Error('Not support no-image type'));
        }
    });
}

const rotateImg = (img: any, direction: any,canvas: any) => {
	const min_step = 0;
	const max_step = 3;
	if (img == null)return;

	let height = img.height;
	let width = img.width;
	let step = 2;
	if (step == null) {
	  	step = min_step;
	}
	if (direction == 'right') {
	  	step++;
	  	step > max_step && (step = min_step);
	} else if (direction == 'left') {
	  	step--;
	  	step < min_step && (step = max_step);
	} 

	let degree = step * 90 * Math.PI / 180;
	let ctx = canvas.getContext('2d');
	switch (step) {
	  case 0:
	  	canvas.width = width;
		canvas.height = height;
		ctx.drawImage(img, 0, 0);
		break;
	  case 1:
		canvas.width = height;
		canvas.height = width;
		ctx.rotate(degree);
		ctx.drawImage(img, 0, -height, 500, 500);
		break;
	  case 2:
		canvas.width = width;
		canvas.height = height;
		ctx.rotate(degree);
		ctx.drawImage(img, -width, -height);
		break;
	  case 3:
		canvas.width = height;
		canvas.height = width;
		ctx.rotate(degree);
		ctx.drawImage(img, -width, 0);
		break;
	}
}