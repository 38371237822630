import { render, staticRenderFns } from "./RemoteCampaignPreview.vue?vue&type=template&id=06724f50&scoped=true"
import script from "./RemoteCampaignPreview.vue?vue&type=script&lang=ts"
export * from "./RemoteCampaignPreview.vue?vue&type=script&lang=ts"
import style0 from "./RemoteCampaignPreview.vue?vue&type=style&index=0&id=06724f50&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06724f50",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/leonlai257/jarvixpay/front-end/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('06724f50', component.options)
    } else {
      api.reload('06724f50', component.options)
    }
    module.hot.accept("./RemoteCampaignPreview.vue?vue&type=template&id=06724f50&scoped=true", function () {
      api.rerender('06724f50', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/remote-campaign/RemoteCampaignPreview.vue"
export default component.exports