var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mdc-layout-grid activity-payment-card",
      attrs: { id: "credit-card" }
    },
    [
      _c("div", { staticClass: "mdc-layout-grid__inner" }, [
        _vm.amount || (_vm.amount && _vm.description)
          ? _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "amount-description mdc-typography--subtitle2"
                  },
                  [
                    _c("span", { staticClass: "amount-description__amount" }, [
                      _vm._v(
                        _vm._s(_vm.$t("general.paymentDetails.amount")) +
                          ": " +
                          _vm._s(
                            _vm._f("currencySymbolDisplay")(_vm.currency)
                          ) +
                          _vm._s(_vm.amount)
                      )
                    ]),
                    _vm.description
                      ? _c("span", [
                          _vm._v(
                            "（" +
                              _vm._s(
                                _vm.$t("general.paymentDetails.description")
                              ) +
                              ": " +
                              _vm._s(_vm.description) +
                              "）"
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
          },
          [_c("VirtualCard", { ref: "virtualCard", attrs: { twosides: "" } })],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--medium mdc-text-field--outlined mdc-text-field--not-notched"
          },
          [
            _c("div", {
              ref: "paymentCardNumber",
              staticClass: "mdc-text-field__input",
              attrs: { id: "payment-card-number" }
            }),
            _c("div", { staticClass: "mdc-text-field-outline" })
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-2-phone mdc-layout-grid__cell--span-4-tablet mdc-text-field mdc-text-field--medium mdc-text-field--outlined mdc-text-field--not-notched"
          },
          [
            _c("div", {
              ref: "paymentCardExpiry",
              staticClass: "mdc-text-field__input",
              attrs: { id: "payment-card-expiry" }
            }),
            _c("div", { staticClass: "mdc-text-field-outline" })
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-2-phone mdc-layout-grid__cell--span-4-tablet mdc-text-field mdc-text-field--medium mdc-text-field--outlined mdc-text-field--not-notched"
          },
          [
            _c("div", {
              ref: "paymentCardCVC",
              staticClass: "mdc-text-field__input",
              attrs: { id: "payment-card-cvc" }
            }),
            _c("div", { staticClass: "mdc-text-field-outline" })
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--medium mdc-text-field--outlined mdc-text-field--not-notched"
          },
          [
            _c("imask-input", {
              ref: "holderName",
              staticClass: "mdc-text-field__input",
              attrs: {
                mask: _vm.holderNameMask,
                prepare: _vm.onHolderNamePrepare,
                id: "payment-card-name",
                type: "text",
                autocorrect: "off",
                spellcheck: "false",
                autocomplete: "off",
                placeholder: _vm.$t("general.card.holderName")
              },
              model: {
                value: _vm.holderName,
                callback: function($$v) {
                  _vm.holderName = $$v
                },
                expression: "holderName"
              }
            }),
            _c("div", { staticClass: "mdc-text-field-outline" })
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
          },
          [
            _vm.errors.length ||
            _vm.invalidCardMessage ||
            _vm.invalidExpiryMessage ||
            _vm.invalidCvcMessage
              ? _c("p", { staticClass: "error-prompt" }, [
                  _c("b", [_vm._v(_vm._s(_vm.$t("errors.label")) + "：")]),
                  _c(
                    "ul",
                    [
                      _vm._l(_vm.errors, function(error) {
                        return _c("li", { key: error }, [_vm._v(_vm._s(error))])
                      }),
                      _vm.invalidCardMessage
                        ? _c("li", [_vm._v(_vm._s(_vm.invalidCardMessage))])
                        : _vm._e(),
                      _vm.invalidExpiryMessage
                        ? _c("li", [_vm._v(_vm._s(_vm.invalidExpiryMessage))])
                        : _vm._e(),
                      _vm.invalidCvcMessage
                        ? _c("li", [_vm._v(_vm._s(_vm.invalidCvcMessage))])
                        : _vm._e()
                    ],
                    2
                  )
                ])
              : _vm._e()
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
          },
          [
            _c(
              "button",
              { staticClass: "mdc-button", on: { click: _vm.previousPage } },
              [_vm._v(_vm._s(_vm.$t("actions.back")))]
            ),
            _vm.isTestingMode
              ? _c(
                  "button",
                  {
                    staticClass: "mdc-button",
                    on: {
                      click: function($event) {
                        _vm.autofill(true)
                      }
                    }
                  },
                  [_vm._v("Auto fill")]
                )
              : _vm._e(),
            _vm.direct
              ? _c(
                  "button",
                  {
                    staticClass: "mdc-fab mdc-fab--extended",
                    attrs: { "aria-label": _vm.$t("actions.next") },
                    on: { click: _vm.pay }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "mdc-fab__icon",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewbox: "0 0 24 24"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M11.7 11.1c-1.8-.5-2.4-1-2.4-1.7 0-.9.8-1.5 2.2-1.5 1.1 0 1.7.4 1.9 1.1.1.3.4.6.7.6h.2c.5 0 .9-.5.7-1-.3-1-1.1-1.7-2.4-2V6c0-.7-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2v.5c-1.5.3-2.7 1.3-2.7 2.9 0 1.9 1.5 2.8 3.8 3.3 2 .5 2.4 1.2 2.4 1.9 0 .6-.4 1.4-2.2 1.4-1.3 0-2-.5-2.3-1.2-.1-.3-.4-.5-.7-.5h-.2c-.5 0-.9.5-.7 1 .5 1.1 1.5 1.8 2.7 2v.7c0 .7.5 1.2 1.2 1.2s1.2-.5 1.2-1.2v-.5c1.6-.3 2.8-1.2 2.8-2.9 0-2.2-1.9-3-3.8-3.5z"
                          }
                        })
                      ]
                    ),
                    _c("span", { staticClass: "mdc-fab__label" }, [
                      _vm._v(_vm._s(_vm.$t("actions.confirm")))
                    ])
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "mdc-fab mdc-fab--extended",
                    attrs: { "aria-label": _vm.$t("actions.next") },
                    on: { click: _vm.nextPage }
                  },
                  [
                    _c("span", { staticClass: "mdc-fab__label" }, [
                      _vm._v(_vm._s(_vm.$t("actions.next")))
                    ]),
                    _c(
                      "svg",
                      {
                        staticClass: "mdc-fab__icon",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewbox: "0 0 24 24"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                          }
                        })
                      ]
                    )
                  ]
                )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }