var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "activity-settings app-page-activity mdc-layout-grid app-page-activity--active"
    },
    [
      _c("div", { staticClass: "user" }, [
        _vm._v(
          _vm._s(_vm.$t("settings.user.info")) +
            " - " +
            _vm._s(_vm.master) +
            " " +
            _vm._s(_vm.branch) +
            " ( " +
            _vm._s(_vm.user) +
            " )"
        )
      ]),
      _vm._l(_vm.items, function(item) {
        return _c("SettingsListItem", {
          key: item.label,
          attrs: { params: item }
        })
      }),
      _c("div", { staticClass: "bottom" }, [
        _c("div", [
          _c("span", { staticClass: "mdc-typography--body1" }, [
            _vm._v("Server version: " + _vm._s(_vm.shortBackEndVersion))
          ])
        ]),
        _c("div", [
          _c("span", { staticClass: "mdc-typography--body1" }, [
            _vm._v("App version: " + _vm._s(_vm.shortFrontEndVersion))
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }