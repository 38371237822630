const now = (() => {
    // Function('return this')()
    try {
        return (
            'performance' in
            ((): any => {
                if (typeof self !== 'undefined') {
                    return self;
                }
                if (typeof window !== 'undefined') {
                    return window;
                }
                if (typeof global !== 'undefined') {
                    return global;
                }
            })()
        );
    } catch (e) {}
})()
    ? () => performance.now() * 10e10
    : () => Date.now();

function rnd(prefix?: string) {
    return (prefix ? prefix + '-' : '') + Math.floor(now()).toString(36) + Math.floor(Math.random() * 10e16).toString(36);
}

export default rnd;
export { rnd };
