




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import RemoteHistoryItem from '@/components/RemoteHistoryItem.vue';
import * as moment from 'moment';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';

@Component({
    components: {
        RemoteHistoryItem,
    },
})
export default class RemoteHistory extends Vue {
    private limit = 10;
    private addItemno = 10;
    private offset = 0;
    private items = [];
    private moreButton = true;
    private remoteStatus = 'all';

    private statusOptions = [
        {
            value: 'all',
            text: 'status.all',
        },
        {
            value: 'pending',
            text: 'status.pending',
        },
        {
            value: 'succeeded',
            text: 'status.succeeded',
        },
        {
            value: 'failed',
            text: 'status.failed',
        },
    ];

    @Watch('remoteStatus')
    onStatusChanged(val: string) {
        this.remoteStatus = val;
        this.offset = 0;
        this.fetchItems();
    }

    public mounted() {
        this.initMDCTextFields();
    }

    public async created() {
        this.fetchItems();
    }

    private initMDCTextFields() {
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }

    public async fetchItems() {
        this.items = await this.getRemotes(this.limit);
        return;
    }

    public async moreItems() {
        const newItem = await this.getRemotes(this.addItemno);
        if (newItem.length) {
            this.items = this.items.concat(newItem);
            this.limit += this.addItemno;
        }
        return;
    }

    public async getRemotes(querySize: number) {
        const remotes = await this.$store.dispatch('getAllRemoteItems', {
            limit: querySize,
            offset: this.offset,
            status: this.remoteStatus,
        });

        if (remotes.length < 1) {
            this.moreButton = false;
        } else {
            this.moreButton = true;
        }
        this.offset += querySize;

        return remotes;
    }
}
