



















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import AUTH_MSG from '@/constants/AUTH';
import { IMaskComponent } from 'vue-imask';

@Component({
    components: {
        'imask-input': IMaskComponent,
    },
})
export default class MerchantName extends Vue {
    private mask = /^[a-zA-Z0-9 _-]*$/;
    private newName = '';
    private confirmedName = '';

    private oldName = '';
    private mdcTextField: any;

    public mounted() {
        this.initMDCTextFields();
    }

    public async created() {
        this.oldName = (await this.$store.dispatch('retreiveMerchantName')).name;
    }

    // animation function
    private initMDCTextFields() {
        this.mdcTextField = Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }

    protected submit() {
        if (!this.newName) {
            return this.$root.$emit('error', { user_message: this.$t(AUTH_MSG.EMPTY_FIELD) });
        }

        if (this.newName !== this.confirmedName) {
            return this.$root.$emit('error', { user_message: this.$t(AUTH_MSG.INCORRECT_CONFIRMED_VALUE) });
        }

        if (this.newName === this.oldName) {
            return this.$root.$emit('error', { user_message: this.$t(AUTH_MSG.DUPLICATED_OLD_VALUE) });
        }

        return this.$store
            .dispatch('updateMerchantName', {
                name: this.newName,
            })
            .then((response: any) => {
                return this.$root.$emit('confirm', {
                    path: '/payment',
                    title: this.$t('status.success'),
                });
            })
            .catch((error: any) => {
                if (error.user_message === 'Duplicate Entry Error') {
                    return this.$root.$emit('error', { user_message: this.$t(AUTH_MSG.DUPLICATED_MERCHANT_NAME) });
                }

                return this.$root.$emit('error', error);
            });
    }
}
