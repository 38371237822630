






















import { Component, Vue, Prop } from 'vue-property-decorator';
import * as moment from 'moment';

@Component({
    components: {},
})
export default class NotificationMessage extends Vue {
    @Prop() item!: any;

    async clearMessage() {
        console.log('clicked removeNotificationMessage');
        await this.$store.dispatch('removeNotificationMessage', { id: this.item.id });
        await this.$store.dispatch('getNotificationMessages');
    }

    goRoute() {
        console.log('show click!!');
        this.clearMessage();
        if (this.item.message.data.topic == 'payment') {
            const chargeId = this.item.message.data.id;
            this.$router.push(`/receipt/${chargeId}`);
        } else if (this.item.message.data.topic == 'Lending') {
            this.$router.push('/lending');
        }
        this.$root.$emit('notification', { open: false });
    }
}
