// const { MutableBuffer } = require('./mutable-buffer');
// const utils = require('./utils');
// const CMD = require('./commands');
import { MutableBuffer } from './mutable-buffer';
import * as utils from './utils';
import CMD from './commands';

class EscposGenerator {
  constructor() {
    this.buffer = new MutableBuffer();
  }

  init() {
    this.buffer.write(CMD.HARDWARE.HW_INIT);
    return this;
  }

  boldOn() {
    this.buffer.write(CMD.TEXT_FORMAT.TXT_BOLD_ON);
    return this;
  }

  boldOff() {
    this.buffer.write(CMD.TEXT_FORMAT.TXT_BOLD_OFF);
    return this;
  }

  underlineOn(width = 1) {
    this.buffer.write(CMD.TEXT_FORMAT[width != 2 ? 'TXT_UNDERL_ON' : 'TXT_UNDERL2_ON']);
    return this;
  }

  underlineOff() {
    this.buffer.write(CMD.TEXT_FORMAT.TXT_UNDERL_OFF);
    return this;
  }

  marginBottom(size) {
    this.buffer.write(CMD.MARGINS.BOTTOM);
    this.buffer.writeUInt8(size);
    return this;
  }

  marginLeft(size) {
    this.buffer.write(CMD.MARGINS.LEFT);
    this.buffer.writeUInt8(size);
    return this;
  }

  marginRight(size) {
    this.buffer.write(CMD.MARGINS.RIGHT);
    this.buffer.writeUInt8(size);
    return this;
  }

  lineText(content) {
    this.buffer.write(content + CMD.EOL);
    return this;
  }

  text(content) {
    this.buffer.write(content);
    return this;
  }

  eol() {
    this.buffer.write(CMD.EOL);
    return this;
  }

  feed(n = 3) {
    this.buffer.write(new Array(n || 1).fill(CMD.EOL).join(''));
    return this;
  }

  control(ctrl) {
    this.buffer.write(CMD.FEED_CONTROL_SEQUENCES['CTL_' + ctrl.toUpperCase()]);
    return this;
  }

  align(align) {
    this.buffer.write(CMD.TEXT_FORMAT['TXT_ALIGN_' + align.toUpperCase()]);
    return this;
  }

  font(family) {
    this.buffer.write(CMD.TEXT_FORMAT['TXT_FONT_' + family.toUpperCase()]);
    return this;
  }

  size(width, height) {
    if (2 >= width && 2 >= height) {
      this.buffer.write(CMD.TEXT_FORMAT.TXT_NORMAL);
      if (2 == width && 2 == height) {
        this.buffer.write(CMD.TEXT_FORMAT.TXT_4SQUARE);
      } else if (1 == width && 2 == height) {
        this.buffer.write(CMD.TEXT_FORMAT.TXT_2HEIGHT);
      } else if (2 == width && 1 == height) {
        this.buffer.write(CMD.TEXT_FORMAT.TXT_2WIDTH);
      }
    } else {
      this.buffer.write(CMD.TEXT_FORMAT.TXT_CUSTOM_SIZE(width, height));
    }
    return this;
  }

  lineSpace(n = null) {
    if (n === null) {
      this.buffer.write(CMD.LINE_SPACING.LS_DEFAULT);
    } else {
      this.buffer.write(CMD.LINE_SPACING.LS_SET);
      this.buffer.writeUInt8(n);
    }
    return this;
  }

  /* barcode(code, type = 'CODE128', width = 3, height = 100, position = 'BTH', font = 'B') {
    let convertCode = String(code);
    if (typeof type === 'undefined' || type === null) {
      throw new TypeError('barcode type is required');
    }
    if (type === 'EAN13' && convertCode.length != 12) {
      throw new Error('EAN13 Barcode type requires code length 12');
    }
    if (type === 'EAN8' && convertCode.length != 7) {
      throw new Error('EAN8 Barcode type requires code length 7');
    }
    if (width >= 2 || width <= 6) {
      this.buffer.write(CMD.BARCODE_FORMAT.BARCODE_WIDTH[width]);
    } else {
      this.buffer.write(CMD.BARCODE_FORMAT.BARCODE_WIDTH_DEFAULT);
    }
    if (height >= 1 || height <= 255) {
      this.buffer.write(CMD.BARCODE_FORMAT.BARCODE_HEIGHT(height));
    } else {
      this.buffer.write(CMD.BARCODE_FORMAT.BARCODE_HEIGHT_DEFAULT);
    }
    this.buffer.write(CMD.BARCODE_FORMAT['BARCODE_FONT_' + (font || 'B').toUpperCase()]);
    this.buffer.write(CMD.BARCODE_FORMAT['BARCODE_TXT_' + (position || 'BTH').toUpperCase()]);
    this.buffer.write(CMD.BARCODE_FORMAT['BARCODE_' + (type || 'EAN13').replace('-', '_').toUpperCase()]);
    let codeBytes = code.split('').map(s => s.charCodeAt(0));
    this.buffer.write(codeBytes.length);
    this.buffer.write(codeBytes);
    this.buffer.write('\x00');
    return this;
  } */

  barcode(code, type, options) {
    options = options || {};
    var width, height, position, font, includeParity;
    width = options.width;
    height = options.height;
    position = options.position;
    font = options.font;
    includeParity = options.includeParity !== false; // true by default

    type = type || 'EAN13'; // default type is EAN13, may a good choice ?
    var convertCode = String(code),
      parityBit = '',
      codeLength = '';
    if (typeof type === 'undefined' || type === null) {
      throw new TypeError('barcode type is required');
    }
    if (type === 'EAN13' && convertCode.length !== 12) {
      throw new Error('EAN13 Barcode type requires code length 12');
    }
    if (type === 'EAN8' && convertCode.length !== 7) {
      throw new Error('EAN8 Barcode type requires code length 7');
    }
    if (this._model === 'qsprinter') {
      this.buffer.write(CMD.MODEL.QSPRINTER.BARCODE_MODE.ON);
    }
    if (this._model === 'qsprinter') {
      // qsprinter has no BARCODE_WIDTH command (as of v7.5)
    } else if (width >= 2 || width <= 6) {
      this.buffer.write(CMD.BARCODE_FORMAT.BARCODE_WIDTH[width]);
    } else {
      this.buffer.write(CMD.BARCODE_FORMAT.BARCODE_WIDTH_DEFAULT);
    }
    if (height >= 1 || height <= 255) {
      this.buffer.write(CMD.BARCODE_FORMAT.BARCODE_HEIGHT(height));
    } else {
      if (this._model === 'qsprinter') {
        this.buffer.write(CMD.MODEL.QSPRINTER.BARCODE_HEIGHT_DEFAULT);
      } else {
        this.buffer.write(CMD.BARCODE_FORMAT.BARCODE_HEIGHT_DEFAULT);
      }
    }
    if (this._model === 'qsprinter') {
      // Qsprinter has no barcode font
    } else {
      this.buffer.write(CMD.BARCODE_FORMAT['BARCODE_FONT_' + (font || 'A').toUpperCase()]);
    }
    this.buffer.write(CMD.BARCODE_FORMAT['BARCODE_TXT_' + (position || 'BLW').toUpperCase()]);
    this.buffer.write(CMD.BARCODE_FORMAT['BARCODE_' + (type || 'EAN13').replace('-', '_').toUpperCase()]);
    if (type === 'EAN13' || type === 'EAN8') {
      parityBit = utils.getParityBit(code);
    }
    if (type == 'CODE128' || type == 'CODE93') {
      codeLength = utils.codeLength(code);
    }
    this.buffer.write(codeLength + code + (includeParity ? parityBit : '') + '\x00'); // Allow to skip the parity byte
    if (this._model === 'qsprinter') {
      this.buffer.write(CMD.MODEL.QSPRINTER.BARCODE_MODE.OFF);
    }
    return this;
  }

  qrcode(code, version = 3, level = 3, size = 8) {
    this.buffer.write(CMD.CODE2D_FORMAT.CODE2D);
    this.buffer.writeUInt8(version);
    this.buffer.writeUInt8(level);
    this.buffer.writeUInt8(size);
    this.buffer.writeUInt16LE(code.length);
    this.buffer.write(code);
    return this;
  }

  hardware(hw) {
    this.buffer.write(CMD.HARDWARE['HW_' + hw]);
    return this;
  }

  cashdraw(pin) {
    this.buffer.write(CMD.CASH_DRAWER['CD_KICK_' + (pin || 2)]);
    return this;
  }

  cut(part, feed) {
    this.feed(feed || 3);
    this.buffer.write(CMD.PAPER[part ? 'PAPER_PART_CUT' : 'PAPER_FULL_CUT']);
    return this;
  }

  cutB(part, feed) {
    feed = feed || 110;
    this.buffer.write(CMD.LF);
    this.buffer.write(CMD.PAPER[part ? 'PAPER_CUT_B' : 'PAPER_CUT_A']).writeUInt8(feed);
    return this;
  }

  printnfeed() {
    this.buffer.write(CMD.LF);
    return this;
  }

  raw(content) {
    this.buffer.write(content);
    return this;
  }

  print(content) {
    return this.raw.call(this, content);
  }

  flush() {
    return this.buffer.flush();
  }
}

// module.exports = Escpos;
export { EscposGenerator };
