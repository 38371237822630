







































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import AUTH_MSG from '@/constants/AUTH';
import { IMaskComponent } from 'vue-imask';

@Component({
    components: {
        'imask-input': IMaskComponent,
    },
})
export default class MerchantCampaignDisplayName extends Vue {
    private type: 'display' | 'url' = 'display';

    private mask = /^[a-zA-Z0-9 _]*$/;
    private prepare = (s: string) => s.replace(/ /g, '_');

    private name = {
        url: {
            oldName: '',
            newName: '',
            confirmedName: '',
        },
        display: {
            oldName: '',
            newName: '',
            confirmedName: '',
        },
    };

    get oldName() {
        return this.type === 'display' ? this.name.display.oldName : this.name.url.oldName;
    }

    get newName() {
        return this.type === 'display' ? this.name.display.newName : this.name.url.newName;
    }

    get confirmedName() {
        return this.type === 'display' ? this.name.display.confirmedName : this.name.url.confirmedName;
    }

    private mdcTextField: any;

    public mounted() {
        this.initMDCTextFields();
    }

    public async created() {
        const data = await this.$store.dispatch('retreiveMerchantCampaignNames');

        this.name.url.oldName = data.urlName;
        this.name.display.oldName = data.displayName;
    }

    // animation function
    private initMDCTextFields() {
        this.mdcTextField = Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }

    protected submit() {
        let data;

        if (!this.newName) {
            return this.$root.$emit('error', { user_message: this.$t(AUTH_MSG.EMPTY_FIELD) });
        }

        if (this.newName !== this.confirmedName) {
            return this.$root.$emit('error', { user_message: this.$t(AUTH_MSG.INCORRECT_CONFIRMED_VALUE) });
        }

        if (this.newName === this.oldName) {
            return this.$root.$emit('error', { user_message: this.$t(AUTH_MSG.DUPLICATED_OLD_VALUE) });
        }

        if (this.type === 'display') {
            data = { displayName: this.newName };
        } else if (this.type === 'url') {
            data = { urlName: this.newName };
        } else {
            return;
        }

        return this.$store
            .dispatch('updateMerchantNames', data)
            .then((response: any) => {
                return this.$root.$emit('confirm', {
                    path: '/payment',
                    title: this.$t('status.success'),
                });
            })
            .catch((error: any) => {
                if (error.user_message === 'Duplicate Entry Error') {
                    return this.$root.$emit('error', { user_message: this.$t(AUTH_MSG.DUPLICATED_MERCHANT_NAME) });
                }

                return this.$root.$emit('error', error);
            });
    }
}
