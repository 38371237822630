






































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import RemoteStatusTag from '@/components/remote-payment/RemoteStatusTag.vue';
import ChargeStatusTag from '@/components/charges/ChargeStatusTag.vue';
import * as moment from 'moment';
import bandOptions from '@/constants/BANKS';
import { currencyNameWithSymbol } from '../../utils/helpers/currencyHelper';

const stripeFxFee = bandOptions.stripeFxFee;

@Component({
    filters: {
        currencySymbolDisplay: currencyNameWithSymbol,
    },
    components: {
        RemoteStatusTag,
        ChargeStatusTag,
    },
})
export default class RemoteDetails extends Vue {
    @Prop() remoteProp?: any;

    @Prop() id!: string;

    private charges = [];
    private customer = {
        name: '',
        country_code: '',
        phone: '',
        remarks: '',
        reference_no: '',
    };
    private gettingHistory = false;
    private remote = <any>{};
    private initial = false;
    private cancelDialogOpen = false;
    private confirmDialogOpen = false;

    private customerFields = {
        name: 'customer.name',
        country_code: 'customer.countryCode',
        phone: 'customer.phone',
        remarks: 'customer.remarks',
        reference_no: 'customer.refNo',
    };

    get hkdConvent() {
        if (this.remote && (this.remote as any).currency !== 'hkd') {
            let fxRates = this.$store.state.fxRates[(this.remote as any).currency.toUpperCase() + '_HKD'];
            // console.log(fxRates);
            return '(≈HKD$ ' + (this.remote.amount * fxRates * (1 - stripeFxFee)).toFixed(2) + ')'; //fx cost charged by stripe
        } else {
            return '';
        }
    }
    private countryCodeList = this.$store.state.countryCodeList;

    // prevent refresh customer cannot translate
    @Watch('$i18n.locale')
    async onLangChanged() {
        const countryCodeObj = await this.countryCodeList.filter((code: any) => code.en === this.remote.customer_country)[0];
        let countryName;
        switch (this.$i18n.locale) {
            case 'en':
                countryName = countryCodeObj.en;
                break;
            case 'zh_HK':
                countryName = countryCodeObj.zh;
                break;
            case 'zh_CN':
                countryName = countryCodeObj.cn;
                break;
        }
        this.customer.name = this.remote.customer_name;
        this.customer.country_code = `${countryName} (${countryCodeObj.phone_code})`;
        this.customer.phone = this.remote.customer_phone;
        this.customer.remarks = this.remote.customer_remarks;
        this.customer.reference_no = this.remote.customer_reference_no;
    }
    public async created() {
        if (!this.remoteProp) {
            await this.getRemote();
        } else {
            this.remote = this.remoteProp;
        }
        const countryCodeObj = await this.countryCodeList.filter((code: any) => code.en === this.remote.customer_country)[0];
        let countryName;
        switch (this.$i18n.locale) {
            case 'en':
                countryName = countryCodeObj.en;
                break;
            case 'zh_HK':
                countryName = countryCodeObj.zh;
                break;
            case 'zh_CN':
                countryName = countryCodeObj.cn;
                break;
        }
        this.customer.name = this.remote.customer_name;
        this.customer.country_code = `${countryName} (${countryCodeObj.phone_code})`;
        this.customer.phone = this.remote.customer_phone;
        this.customer.remarks = this.remote.customer_remarks;
        this.customer.reference_no = this.remote.customer_reference_no;
        this.fetchItems();
    }

    public async fetchItems() {
        if (!this.gettingHistory) {
            this.charges = await this.getHisotry();
            this.initial = true;
        }
    }

    public async getHisotry() {
        let newItems: any;
        this.gettingHistory = true;
        try {
            newItems = await this.$store.dispatch('getHistoryFromRemoteCharge', {
                remoteId: this.id,
            });

            if (newItems.length < 1) {
                newItems = [];
                return;
            }
        } catch (error) {
            this.$root.$emit('error', error);
        } finally {
            this.gettingHistory = false;
        }
        if (newItems) return newItems;
    }

    public async getRemote() {
        try {
            this.remote = await this.$store.dispatch('getRemoteItem', this.id);
        } catch (error) {
            this.$root.$emit('error', error);
        }
    }

    protected toggleCancelDialog() {
        return (this.cancelDialogOpen = true);
    }

    protected async cancel() {
        try {
            await this.$store.dispatch('cancelRemoteItem', this.id);
        } catch (error) {
            this.$root.$emit('error', error);
        }

        return (this.confirmDialogOpen = true);
    }

    get moment() {
        moment.locale(this.$i18n.locale);
        return moment;
    }
}
