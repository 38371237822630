export const currency = {
    name: '货币',
    hkd: '港币',
    usd: '美元',
    jpy: '日元',
    mop: '澳门元',
    gbp: '英镑',
    cny: '人民币',
    myr: '马来西亚令吉',
    sgd: '新加坡元',
    thb: '泰铢',
};
