































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import RecurrentStatusTag from '@/components/recurrents/RecurrentStatusTag.vue';
import CardChange from '@/components/recurrents/CardChange.vue';
import ChargeStatusTag from '@/components/charges/ChargeStatusTag.vue';
import VirtualCard from '@/components/payment/VirtualCard.vue';
// import { moment_HK as moment } from '@/store';
import * as moment from 'moment';
import bandOptions from '@/constants/BANKS';
import { stripe } from '../../store';
import { currencyNameWithSymbol } from '../../utils/helpers/currencyHelper';

const stripeFxFee = bandOptions.stripeFxFee;

@Component({
    filters: {
        currencySymbolDisplay: currencyNameWithSymbol,
    },
    components: {
        RecurrentStatusTag,
        ChargeStatusTag,
        VirtualCard,
        CardChange,
    },
})
export default class RecurrentDetails extends Vue {
    @Prop({ default: () => {} })
    recurrentProp?: any;

    @Prop() id!: string;

    private items = [];
    private gettingHistory = false;
    private moreButton = true;
    private recurrent: any = {};
    private initial = false;
    private changingCard = false;
    private cancelDialogOpen = false;
    private confirmDialogOpen = false;
    get remainingDuration() {
        return this.recurrent.duration - this.recurrent.charge_count;
    }

    get canCancel() {
        return !(this.recurrent.status == 'canceled' || this.recurrent.status == 'completed') ? true : false;
    }

    get canRefund() {
        return this.$store.state.user.permissions_.payment && this.$store.state.user.permissions_.payment.refund;
    }

    get cancelRecurrencePermission() {
        return this.$store.state.user.permissions_.payment && this.$store.state.user.permissions_.payment.cancel_recurrence;
    }

    get changeCardPermission() {
        return this.$store.state.user.permissions_.payment && this.$store.state.user.permissions_.payment.change_recurrence_credit_card;
    }

    private customerFields = {
        // zhHK: {
        //     name: '姓名',
        //     phone: '電話',
        //     email: '電郵',
        //     address: '地址',
        //     remarks: '備註',
        //     reference_no: '參考編號',
        // },
    };
    private countryCodeList = this.$store.state.countryCodeList;

    // prevent refresh cannot translate
    @Watch('$i18n.locale')
    async onLangChanged() {
        if (!this.recurrentProp) {
            await this.getRecurrent();
            const countryCodeObj = await this.countryCodeList.filter((code: any) => code.en === this.recurrent.customer.country)[0];
            let countryName;
            switch (this.$i18n.locale) {
                case 'en':
                    countryName = countryCodeObj.en;
                    break;
                case 'zh_HK':
                    countryName = countryCodeObj.zh;
                    break;
                case 'zh_CN':
                    countryName = countryCodeObj.cn;
                    break;
            }
            this.recurrent.customer = {
                name: this.recurrent.customer.name,
                country_code: `${countryName} (${countryCodeObj.phone_code})`,
                phone: this.recurrent.customer.phone,
                email: this.recurrent.customer.email,
                address: this.recurrent.customer.address,
                remarks: this.recurrent.customer.remarks,
                reference_no: this.recurrent.customer.reference_no,
            };
            this.customerFields = {
                name: this.$t('customer.name'),
                country_code: this.$t('customer.countryCode'),
                phone: this.$t('customer.phone'),
                email: this.$t('customer.email'),
                address: this.$t('customer.address'),
                remarks: this.$t('customer.remarks'),
                reference_no: this.$t('customer.refNo'),
            };
        }
    }
    public async created() {
        if (!this.recurrentProp) {
            await this.getRecurrent();
        } else {
            this.recurrent = this.recurrentProp;
        }
        const countryCodeObj = await this.countryCodeList.filter((code: any) => code.en === this.recurrent.customer.country)[0];
        let countryName;
        switch (this.$i18n.locale) {
            case 'en':
                countryName = countryCodeObj.en;
                break;
            case 'zh_HK':
                countryName = countryCodeObj.zh;
                break;
            case 'zh_CN':
                countryName = countryCodeObj.cn;
                break;
        }
        this.recurrent.customer = {
            name: this.recurrent.customer.name,
            country_code: `${countryName} (${countryCodeObj.phone_code})`,
            phone: this.recurrent.customer.phone,
            email: this.recurrent.customer.email,
            address: this.recurrent.customer.address,
            remarks: this.recurrent.customer.remarks,
            reference_no: this.recurrent.customer.reference_no,
        };
        this.customerFields = {
            name: this.$t('customer.name'),
            country_code: this.$t('customer.countryCode'),
            phone: this.$t('customer.phone'),
            email: this.$t('customer.email'),
            address: this.$t('customer.address'),
            remarks: this.$t('customer.remarks'),
            reference_no: this.$t('customer.refNo'),
        };
        this.fetchItems();
    }

    public async fetchItems() {
        if (!this.gettingHistory) {
            this.moreButton = true;
            this.items = await this.getHisotry();
            this.initial = true;
        }
    }

    public async refreshRecurrent() {
        await this.getRecurrent();
    }

    public toggleCancelDialog() {
        return (this.cancelDialogOpen = true);
    }

    // public async moreItems() {
    //     const newItem = await this.getCharges();

    //     if (newItem.length) {
    //         this.items = this.items.concat(newItem);
    //     }
    // }

    protected async cancel() {
        // try {
        //     await this.$store.dispatch('cancelRemoteItem', this.id)
        // } catch (error) {
        //     this.$root.$emit('error', error);
        // }

        try {
            await this.$store.dispatch('cancelRecurrence', this.id);
            return (this.confirmDialogOpen = true);
        } catch (error) {
            this.$root.$emit('error', error);
        }
    }

    public async getHisotry() {
        let newItems: any;
        this.gettingHistory = true;
        try {
            newItems = await this.$store.dispatch('getHistoryFromRecurringCharge', {
                recurrenceId: this.id,
            });

            if (newItems.length < 1) {
                this.moreButton = false;
                newItems = [];
                return;
            }
        } catch (error) {
            this.$root.$emit('error', error);
        } finally {
            this.gettingHistory = false;
        }
        if (newItems) return newItems;
    }

    public async getRecurrent() {
        try {
            this.recurrent = await this.$store.dispatch('getSingleRecurringCharge', this.id);
        } catch (error) {
            this.$root.$emit('error', error);
        }
    }

    get hkdConvent() {
        if (this.recurrent && (this.recurrent as any).currency !== 'hkd') {
            let fxRates = this.$store.state.fxRates[(this.recurrent as any).currency.toUpperCase() + '_HKD'];
            return '(≈HKD$ ' + (this.recurrent.amount * fxRates * (1 - stripeFxFee)).toFixed(2) + ')';
        } else {
            return '';
        }
    }

    get source() {
        try {
            if (this.recurrent && (this.recurrent as any).customer) {
                let c = (this.recurrent as any).customer;
                return {
                    last4: c.source_last4,
                    cardholder_name: c.source_cardholder_name,
                    exp_date: c.source_exp_date,
                };
            }
        } catch (error) {}
    }

    get moment() {
        moment.locale(this.$i18n.locale);
        return moment;
    }

    public closeCardChange() {
        this.changingCard = false;
    }
}
