



















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import { isPhoneNumber } from '../../utils/validators';
import { IMaskComponent } from 'vue-imask';

@Component({
    components: {
        'imask-input': IMaskComponent,
    },
})
export default class Company extends Vue {
    private businessName = '';
    private businessType = ''; // 'limited' or 'unlimited'
    private businessBR = '';
    private businessCI = ''; // if businessType = 'unlimited', no need CI
    private statementDescriptor = '';
    private address = '';
    private phone = '';
    private city = '';
    private state = '';
    private businessDescription = '';
    private businessWeb = '';

    private token = '';

    private errors: string[] = [];

    private selectedCountryCode: any = { phone_code: '+852', en: 'Hong Kong (SAR)', zh: '香港', cn: '香港', alphanumeric_sender: 1 };
    private countryCodeList = this.$store.state.countryCodeList;

    public async mounted() {
        // sort the country code due to language
        if (this.$i18n.locale == 'en') {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.en > b.en ? 1 : -1));
        } else {
            this.countryCodeList = this.countryCodeList.sort((a: any, b: any) => (a.phone_code >= b.phone_code ? 1 : -1));
        }
        this.initMDCTextFields();
        // if (this.$store.state.signUp.company.businessName === '') {
        //     this.businessName = this.$store.state.user.user_name;
        // } else {
        //     this.businessName = this.$store.state.signUp.company.businessName;
        // }
        this.businessName =
            this.$store.state.signUp.company.businessName === ''
                ? this.$store.state.user.user_name
                : this.$store.state.signUp.company.businessName;
        this.businessType = this.$store.state.signUp.company.businessType;
        this.businessBR = this.$store.state.signUp.company.businessBR;
        this.businessCI = this.$store.state.signUp.company.businessCI;
        this.statementDescriptor = this.$store.state.signUp.company.statementDescriptor;
        this.address = this.$store.state.signUp.company.address;
        this.selectedCountryCode = (await this.countryCodeList.filter(
            (code: any) => code.en === this.$store.state.signUp.company.phone_country
        ))[0];
        this.phone = this.$store.state.signUp.company.phone;
        this.city = this.$store.state.signUp.company.city;
        this.state = this.$store.state.signUp.company.state;
        this.businessDescription = this.$store.state.signUp.company.businessDescription;
        this.businessWeb = this.$store.state.signUp.company.businessWeb;

        if (
            this.businessName == '' ||
            this.businessType == '' ||
            this.businessBR == '' ||
            (this.businessType == 'limited' && this.businessCI == '') ||
            this.statementDescriptor == '' ||
            this.address == '' ||
            this.city == '' ||
            this.state == '' ||
            this.selectedCountryCode == undefined ||
            this.phone == '' ||
            this.businessDescription == '' ||
            this.businessWeb == ''
        ) {
            await this.$store.dispatch('retrieveSignUpData');
            if (this.businessName == '') {
                this.businessName = !!this.$store.state.signUp.company.businessName ? this.$store.state.signUp.company.businessName : '';
            }
            this.businessBR = !!this.$store.state.signUp.company.businessBR ? this.$store.state.signUp.company.businessBR : '';
            this.businessCI = !!this.$store.state.signUp.company.businessCI ? this.$store.state.signUp.company.businessCI : '';

            if (this.businessCI != '') {
                this.businessType = this.$store.state.signUp.company.businessType; // depends on ci
            }

            this.statementDescriptor = !!this.$store.state.signUp.company.statementDescriptor
                ? this.$store.state.signUp.company.statementDescriptor
                : '';
            this.address = !!this.$store.state.signUp.company.address ? this.$store.state.signUp.company.address : '';
            this.phone = !!this.$store.state.signUp.company.phone ? this.$store.state.signUp.company.phone : '';

            // if the phone number still not fill in, empty the country code
            if (this.phone != '') {
                this.selectedCountryCode = (await this.countryCodeList.filter(
                    (code: any) => code.en === this.$store.state.signUp.company.phone_country
                ))[0];
            } else {
                this.selectedCountryCode = { phone_code: '+852', en: 'Hong Kong (SAR)', zh: '香港', cn: '香港', alphanumeric_sender: 1 };
            }

            this.city = !!this.$store.state.signUp.company.city ? this.$store.state.signUp.company.city : '';
            this.state = !!this.$store.state.signUp.company.state ? this.$store.state.signUp.company.state : '';

            this.businessDescription = !!this.$store.state.signUp.company.businessDescription
                ? this.$store.state.signUp.company.businessDescription
                : '';
            this.businessWeb = !!this.$store.state.signUp.company.businessWeb ? this.$store.state.signUp.company.businessWeb : '';
        }
    }
    public updated() {
        this.initMDCTextFields();
    }

    protected update() {
        this.errors = [];

        if (
            this.businessName == '' ||
            this.businessType == '' ||
            this.businessBR == '' ||
            (this.businessType == 'limited' && this.businessCI == '') ||
            this.statementDescriptor == '' ||
            this.address == '' ||
            this.city == '' ||
            this.state == '' ||
            this.phone == '' ||
            this.businessDescription == ''
        ) {
            this.errors.push(this.$t('AUTH.EMPTY_FIELD') as any);
            // this.errors.push('請輸入所有資料。');
        }

        if (5 > this.statementDescriptor.split('').filter(c => /[a-zA-Z]/.test(c)).length) {
            this.errors.push(this.$t(`AUTH.INVALID_STATEMENTDESCRIPTOR_FORMAT_TOO_SHORT`) as any);
            // this.errors.push('客戶帳單顯示名稱需多於5英文字母。');
        }

        if (this.statementDescriptor.length > 22) {
            this.errors.push(this.$t(`AUTH.INVALID_STATEMENTDESCRIPTOR_FORMAT_TOO_LONG`) as any);
            // this.errors.push('客戶帳單顯示名稱需少於22個字元。');
        }

        if (!isPhoneNumber(`${this.selectedCountryCode.phone_code}${this.phone}`)) {
            this.errors.push(this.$t('AUTH.INVALID_MOBILE_FORMAT') as any);
            // this.errors.push('電話號碼無效。');
        }

        if (this.address.length > 200) {
            this.errors.push(this.$t('AUTH.ADDRESS_TOO_LONG') as any);
            // this.errors.push('地址需少於200個字元。');
        }

        if (this.businessDescription.length > 200) {
            this.errors.push(this.$t('AUTH.DESCRIPTION_TOO_LONG') as any);
            // this.errors.push('地址需少於200個字元。');
        }

        // const urlPattern = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

        // if (this.businessWeb != '' && !urlPattern.test(this.businessWeb)) {
        //     this.errors.push(this.$t('AUTH.INVALID_WEBSITE_FORMAT') as any);
        // }

        if (this.errors.length) {
            return;
        } else {
            this.$store.dispatch('saveCompanyData', {
                nextPage: 'owner',
                legal_entity: {
                    businessName: this.businessName,
                    businessType: this.businessType,
                    businessBR: this.businessBR,
                    businessCI: this.businessCI,
                    statementDescriptor: this.statementDescriptor,
                    address: this.address,
                    country: this.selectedCountryCode.en,
                    countryCode: this.selectedCountryCode.phone_code,
                    phone: this.phone,
                    city: this.city,
                    state: this.state,
                    businessDescription: this.businessDescription,
                    businessWeb: this.businessWeb,
                },
            });
            this.$router.push({ name: 'owner' });
        }
    }

    /* #region statementDescriptorMask */
    private statementDescriptorMask = /^[a-zA-Z0-9!@#$%^&()~`_\-+=,.?/:;{}\[\]| ]{1,22}$/;
    private statementDescriptorMask_onPrepare = (s: String) => s.toUpperCase();
    /* #endregion statementDescriptorMask */

    // animation function
    private initMDCTextFields() {
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
