











import { Component, Prop, Vue } from 'vue-property-decorator';
import SettingsListItem from '@/components/settings/SettingsListItem.vue';

@Component({
    components: {
        SettingsListItem,
    },
})
export default class SettingsList extends Vue {
    private master = this.$store.state.user.permissions_.master ? '[Master]' : '';
    private branch = this.$store.state.user.branch;
    private user = this.$store.state.user.user_name;
    private frontEndVersion: string = process.env.VUE_APP_VERSION || '';
    private backEndVersion: string = '';

    created() {
        this.items.forEach(item => {
            item.label = this.$t(item.label) as string;
        });
    }

    beforeDestroyed() {
        this.items.forEach(item => {
            item.label = this.$t(item.label) as string;
        });
    }

    get shortFrontEndVersion() {
        return this.frontEndVersion.slice(0, 6);
    }

    get shortBackEndVersion() {
        return this.backEndVersion.slice(0, 6);
    }

    public mounted() {
        this.getVersion();
    }

    private getVersion() {
        this.$store
            .dispatch('getBackendVersion')
            .then((res: any) => {
                this.backEndVersion = res.hash;
            })
            .catch((error: any) => {
                return this.$root.$emit('error', error);
            });
    }

    private items = [
        {
            to: '/settings/capture',
            label: 'settings.captureNow',
            show: this.$store.state.user.permissions_.functional && this.$store.state.user.permissions_.payment.capture,
        },
        {
            to: '/settings/new_user',
            label: 'settings.user.create',
            show: this.$store.state.user.permissions_.account && this.$store.state.user.permissions_.account.add_user,
        },
        {
            to: '/settings/new_store',
            label: 'settings.branch.create',
            show:
                this.$store.state.user.permissions_.master &&
                this.$store.state.user.permissions_.account &&
                this.$store.state.user.permissions_.account.add_account,
        },
        {
            to: '/settings/edit_store',
            label: 'settings.branch.modify',
            show: this.$store.state.user.permissions_.account.modify_information,
        },
        {
            to: '/settings/merchant',
            label: 'settings.merchant.modify',
            show: this.$store.state.user.permissions_.master && this.$store.state.user.permissions_.account.modify_information,
        },
        {
            to: { name: 'settings.export' },
            label: 'settings.export.label',
            show: this.$store.state.user.permissions_.functional.export,
        },
        {
            to: '/settings/receipt_print',
            label: '收據(打印)設定',
            show: false,
        },
        {
            to: '/settings/printer',
            label: 'settings.printer',
            show: this.$store.state.user.permissions_.functional && this.$store.state.user.permissions_.functional.printer,
        },
        {
            to: '/settings/capture-settings',
            label: 'settings.capture.title',
            show: this.$store.state.user.permissions_.functional && this.$store.state.user.permissions_.payment.capture,
        },
        {
            to: '/settings/edit_permission',
            label: 'settings.permissions.title',
            show: this.$store.state.user.permissions_.account && this.$store.state.user.permissions_.account.edit_permission,
        },
        {
            to: '/settings/language_settings',
            label: 'settings.language',
            show: true,
        },
        {
            to: '/settings/password',
            label: 'settings.password.change',
            show: true,
        },
        {
            onClick: () => {
                this.$root.$emit('logout');
            },
            label: 'settings.logout.title',
            show: true,
        },
    ].filter(x => x.show);
}
