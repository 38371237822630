var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mdc-list",
    { attrs: { interactive: "" } },
    [
      _vm._l(_vm.payoutsRender, function(payout, i) {
        return [
          _c(
            "mdc-list-item",
            {
              key: payout.id,
              on: {
                click: function($event) {
                  _vm.$emit("click", _vm.payouts[i])
                }
              }
            },
            [
              _c("span", [_vm._v(_vm._s(_vm._f("currency")(payout.amount)))]),
              _c("PayoutStatus", {
                attrs: { status: payout.status, small: "" }
              }),
              _c("div", { staticClass: "flex-grow-1" }),
              _c("span", { staticClass: "mdc-typography--caption" }, [
                _vm._v(_vm._s(payout.arrival_date__formatted))
              ])
            ],
            1
          ),
          _vm.dividers
            ? _c("hr", {
                key: "divider-" + payout.id,
                staticClass: "mdc-list-divider"
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }