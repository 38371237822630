var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "mdc-top-app-bar" }, [
    _c("div", { staticClass: "mdc-top-app-bar__row" }, [
      _c(
        "section",
        {
          staticClass:
            "mdc-top-app-bar__section mdc-top-app-bar__section--align-start"
        },
        [
          _vm.merchantLogo
            ? _c("img", {
                staticClass: "logo",
                attrs: { src: _vm.merchantLogo }
              })
            : _vm._e(),
          !_vm.merchantLogo
            ? _c(
                "span",
                {
                  staticClass: "mdc-top-app-bar__title fw-550",
                  attrs: { id: "app-title" }
                },
                [_vm._v(_vm._s(_vm.merchantDisplayName))]
              )
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }