var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "error-dialog" },
    [
      _c(
        "mdc-dialog",
        {
          ref: "errorDialog",
          attrs: {
            title: _vm.$t("errors.label"),
            accept: _vm.$t("actions.accept")
          },
          on: {
            change: function($event) {
              _vm.$root.$emit("error-dismiss")
            }
          },
          model: {
            value: _vm.open,
            callback: function($$v) {
              _vm.open = $$v
            },
            expression: "open"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.message) + "\n    ")]
      ),
      _c(
        "mdc-dialog",
        {
          attrs: {
            title: _vm.$t("errors.label"),
            accept: _vm.$t("actions.accept")
          },
          on: { accept: _vm.onEnter, cancel: _vm.onEnter },
          model: {
            value: _vm.receipt,
            callback: function($$v) {
              _vm.receipt = $$v
            },
            expression: "receipt"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.receiptMessage) + "\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }