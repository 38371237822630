export const recurrent = {
    amount: 'Monthly Payment Amount',
    totalAmount: 'Total Payment Amount: {0}{1} x {2}（months）= {3}{4}',
    duration: 'Duration (months)',
    autoRenewal: 'Auto Renewal',
    autoRenewalMessage: 'Auto Renewal after {0} months',
    summary: 'Recurrent Summary',
    warningMessage: 'Please select a card with expiry date on or after {0}',
    durationMessage: '{0} month(s)',
    left: 'Left with {0} months',
    cancelRemainingMessage: 'The remaining {0} installments will be cancelled immediately.',
    nonRefundMessage: '** The received payment will not be refunded.',
};
