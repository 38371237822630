export const campaign = {
    title: '広告',
    internalName: '内部名',
    externalName: '広告名',
    agentPromotion: '代理プロモーション',
    period: '期間',
    copywriting: '文案',
    content: '内容',
    banners: '写真',
    details: 'レポートを書き出す',
    product: {
        title: '商品リスト',
        label: '商品',
        name: '商品名',
        price: '価格',
        originalPrice: '割引前の価格',
        customDiscountMessage: '割引メッセージ',
        quantity: '総個数',
        quantityPerOrder: '1回あたりの最大注文個数',
        discount: '割引',
        remaining: '残り',
    },
    link: '広告リンク',
    additionalOptions: {
        title: '情報をもっと集める',
        dateFormat: '日付形式',
        timeFormat: '時間形式',
        textFormat: 'テキスト形式',
        optionFormat: 'オプション形式',
        mcFormat: '複数選択肢形式',
        question: 'カスタム質問',
        branchSelection: '支店選択',
    },
    question: {
        title: 'カスタム質問',
        label: '質問',
        option: '選択肢',
        newOption: '選択肢を追加',
        answer: '答え',
    },
    newProduct: '商品を追加',
    newLink: 'リンクを追加',
    newQuestion: '質問を追加',
    newContact: '連絡先を追加',
    tnc: '取引条件',
    pasteTNC: '取引条件を記載して下さい。',
    cta: '支払いボタン',
    currency: '通貨',
    contact: {
        title: '連絡先の情報',
        label: '連絡先',
        shopName: '店舗名',
        address: '所在地',
        number: '電話番号',
    },
    orderList: '注文',
    orderSummary: {
        totalTransaction: '総売上高',
        totalOrder: '総注文件数',
        paid: '支払い済み',
        latestTransaction: '最新注文金額',
        latestDate: '最新注文日付',
    },
    survey: 'アンケート',
    cancelMsg: '確認後、この広告が無効になります。',
    discountMsg: '割引有効期限は{0}まで',
    hitRate: 'クリック率',
};
