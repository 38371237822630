












import { Component, Vue } from 'vue-property-decorator';
// import { getFCMToken } from '@/utils/notificationService';

@Component
export default class LogoutDialog extends Vue {
    private open = false;

    mounted() {
        this.$root.$on('logout', () => {
            this.open = true;
        });
    }

    private async logout() {
        // let token = await getFCMToken;
        try {
            // console.log(`device_token:${token}`);
            // await this.$store.dispatch('removeDeviceToken', { device_token: token });
            await this.$store
                .dispatch('logout')
                .then((response: any) => {
                    this.$root.$emit('resetPayment');
                    return this.$router.push({
                        name: 'login',
                    });
                })
                .catch((error: any) => {
                    return this.$root.$emit('error', error);
                });
        } catch (error) {
            console.log(error);
        }
    }

    private onAccept() {
        this.logout();
    }
}
