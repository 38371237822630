var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "personal-info" }, [
    _c("div", { staticClass: "app-page app-page-payment app-page--active" }, [
      _c("div", { staticClass: "app-page-container" }, [
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid app-page-activity app-page-activity--active bottom-nav--hidden"
          },
          [
            _c(
              "form",
              {
                staticClass: "mdc-layout-grid__inner",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.update($event)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-typography--headline6"
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("signup.companyOwner")) +
                        "\n                        "
                    ),
                    _c("div", { staticClass: "mdc-typography--subtitle1" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("signup.companyRemind")) +
                          "\n                        "
                      )
                    ])
                  ]
                ),
                _vm._l(_vm.owners, function(owner, i) {
                  return [
                    i != 0 ? _c("br", { key: i }) : _vm._e(),
                    _c(
                      "div",
                      {
                        key: "owner" + i,
                        staticClass:
                          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet",
                        staticStyle: { "margin-bottom": "10px" }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { height: "24px" } },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "19px" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("signup.owner")) +
                                    " - " +
                                    _vm._s(i + 1) +
                                    " "
                                ),
                                i == 0
                                  ? _c("span", [
                                      _vm._v(
                                        "（" +
                                          _vm._s(
                                            _vm.$t(
                                              "signup.companyImportantOwner"
                                            )
                                          ) +
                                          "）"
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            _vm.owners.length > 1
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-0",
                                    staticStyle: { float: "right" },
                                    attrs: { small: "", icon: "", flat: "" }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        on: {
                                          click: function($event) {
                                            _vm.removeOwner(i)
                                          }
                                        }
                                      },
                                      [_vm._v("close")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        key: "lastName" + i,
                        staticClass:
                          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: owner.lastName,
                              expression: "owner.lastName"
                            }
                          ],
                          staticClass: "mdc-text-field__input",
                          attrs: {
                            type: "text",
                            autocomplete: "off",
                            autocorrect: "off",
                            spellcheck: "false"
                          },
                          domProps: { value: owner.lastName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(owner, "lastName", $event.target.value)
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "mdc-floating-label",
                            class:
                              owner.lastName != ""
                                ? "mdc-floating-label--float-above"
                                : "",
                            attrs: { for: "lastName" }
                          },
                          [_vm._v(_vm._s(_vm.$t("signup.lastName")))]
                        ),
                        _c("div", { staticClass: "mdc-notched-outline" }, [
                          _c("svg", [
                            _c("path", {
                              staticClass: "mdc-notched-outline__path"
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "mdc-notched-outline__idle" })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        key: "firstName" + i,
                        staticClass:
                          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: owner.firstName,
                              expression: "owner.firstName"
                            }
                          ],
                          staticClass: "mdc-text-field__input",
                          attrs: {
                            type: "text",
                            autocomplete: "off",
                            autocorrect: "off",
                            spellcheck: "false"
                          },
                          domProps: { value: owner.firstName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(owner, "firstName", $event.target.value)
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "mdc-floating-label",
                            class:
                              owner.firstName != ""
                                ? "mdc-floating-label--float-above"
                                : "",
                            attrs: { for: "firstName" }
                          },
                          [_vm._v(_vm._s(_vm.$t("signup.firstName")))]
                        ),
                        _c("div", { staticClass: "mdc-notched-outline" }, [
                          _c("svg", [
                            _c("path", {
                              staticClass: "mdc-notched-outline__path"
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "mdc-notched-outline__idle" })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        key: "identityType" + i,
                        staticClass:
                          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: owner.identityType,
                                expression: "owner.identityType"
                              }
                            ],
                            staticClass: "mdc-text-field__input",
                            class: { "select--hidden": !owner.identityType },
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    owner,
                                    "identityType",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function($event) {
                                  owner.identityNumber = ""
                                }
                              ]
                            }
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: { disabled: "" },
                                domProps: { value: null }
                              },
                              [_vm._v(_vm._s(_vm.$t("signup.identityType")))]
                            ),
                            _c("option", { attrs: { value: "hkid" } }, [
                              _vm._v(_vm._s(_vm.$t("signup.hkid")))
                            ]),
                            _c("option", { attrs: { value: "passport" } }, [
                              _vm._v(_vm._s(_vm.$t("signup.passport")))
                            ])
                          ]
                        ),
                        _c(
                          "label",
                          {
                            staticClass: "mdc-floating-label",
                            class:
                              owner.identityType != ""
                                ? "mdc-floating-label--float-above"
                                : ""
                          },
                          [_vm._v(_vm._s(_vm.$t("signup.identityType")))]
                        ),
                        _c("div", { staticClass: "mdc-notched-outline" }, [
                          _c("svg", [
                            _c("path", {
                              staticClass: "mdc-notched-outline__path"
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "mdc-notched-outline__idle" })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        key: "identityNumber" + i,
                        staticClass:
                          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: owner.identityNumber,
                              expression: "owner.identityNumber"
                            }
                          ],
                          staticClass: "mdc-text-field__input",
                          attrs: {
                            disabled: owner.identityType == "",
                            maxlength: 10,
                            type: "text",
                            autocomplete: "off",
                            autocorrect: "off",
                            spellcheck: "false"
                          },
                          domProps: { value: owner.identityNumber },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  owner,
                                  "identityNumber",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                _vm.formatHKID(i)
                              }
                            ]
                          },
                          nativeOn: {
                            keypress: function($event) {
                              _vm.prevent($event, i)
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "mdc-floating-label",
                            class:
                              owner.identityNumber != ""
                                ? "mdc-floating-label--float-above"
                                : "",
                            attrs: { for: "identityNumber" }
                          },
                          [_vm._v(_vm._s(_vm.$t("signup.identityNumber")))]
                        ),
                        _c("div", { staticClass: "mdc-notched-outline" }, [
                          _c("svg", [
                            _c("path", {
                              staticClass: "mdc-notched-outline__path"
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "mdc-notched-outline__idle" })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        key: "dob" + i,
                        staticClass:
                          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched hidden-input-on-empty"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: owner.dob,
                              expression: "owner.dob"
                            }
                          ],
                          staticClass: "mdc-text-field__input",
                          class: [
                            { "mdc-text-field__input--empty": !owner.dob }
                          ],
                          attrs: {
                            type: "date",
                            id: "dob",
                            min: "1905-01-01",
                            max: "2005-01-01",
                            autocomplete: "off",
                            autocorrect: "off",
                            spellcheck: "false"
                          },
                          domProps: { value: owner.dob },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(owner, "dob", $event.target.value)
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "mdc-floating-label",
                            class:
                              owner.dob != ""
                                ? "mdc-floating-label--float-above"
                                : "",
                            attrs: { for: "dob" }
                          },
                          [_vm._v(_vm._s(_vm.$t("signup.dob")))]
                        ),
                        _c("div", { staticClass: "mdc-notched-outline" }, [
                          _c("svg", [
                            _c("path", {
                              staticClass: "mdc-notched-outline__path"
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "mdc-notched-outline__idle" })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        key: "country_code" + i,
                        staticClass:
                          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: owner.countryCode,
                                expression: "owner.countryCode"
                              }
                            ],
                            staticClass: "mdc-text-field__input",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  owner,
                                  "countryCode",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: { disabled: "" },
                                domProps: { value: null }
                              },
                              [_vm._v(_vm._s(_vm.$t("signup.countryCode")))]
                            ),
                            _vm._l(_vm.countryCodeList, function(code) {
                              return _c(
                                "option",
                                { key: code.en, domProps: { value: code } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$i18n.locale == "zh_HK"
                                        ? code.zh
                                        : _vm.$i18n.locale == "zh_CN"
                                          ? code.cn
                                          : code.en
                                    ) +
                                      " " +
                                      _vm._s("(" + code.phone_code + ")")
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        ),
                        _c("label", { staticClass: "mdc-floating-label" }, [
                          _vm._v(_vm._s(_vm.$t("signup.countryCode")))
                        ]),
                        _c("div", { staticClass: "mdc-notched-outline" }, [
                          _c("svg", [
                            _c("path", {
                              staticClass: "mdc-notched-outline__path"
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "mdc-notched-outline__idle" })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        key: "phone" + i,
                        staticClass:
                          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: owner.phone,
                              expression: "owner.phone"
                            }
                          ],
                          staticClass: "mdc-text-field__input",
                          attrs: {
                            type: "text",
                            autocomplete: "off",
                            autocorrect: "off",
                            spellcheck: "false"
                          },
                          domProps: { value: owner.phone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(owner, "phone", $event.target.value)
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "mdc-floating-label ",
                            class:
                              owner.phone != ""
                                ? "mdc-floating-label--float-above"
                                : "",
                            attrs: { for: "phone" }
                          },
                          [_vm._v(_vm._s(_vm.$t("signup.ownerPhone")))]
                        ),
                        _c("div", { staticClass: "mdc-notched-outline" }, [
                          _c("svg", [
                            _c("path", {
                              staticClass: "mdc-notched-outline__path"
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "mdc-notched-outline__idle" })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        key: "address" + i,
                        staticClass:
                          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: owner.address,
                              expression: "owner.address"
                            }
                          ],
                          staticClass: "mdc-text-field__input",
                          attrs: {
                            type: "text",
                            autocomplete: "off",
                            autocorrect: "off",
                            spellcheck: "false"
                          },
                          domProps: { value: owner.address },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(owner, "address", $event.target.value)
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "mdc-floating-label ",
                            class:
                              owner.address != ""
                                ? "mdc-floating-label--float-above"
                                : "",
                            attrs: { for: "address" }
                          },
                          [_vm._v(_vm._s(_vm.$t("signup.address")))]
                        ),
                        _c("div", { staticClass: "mdc-notched-outline" }, [
                          _c("svg", [
                            _c("path", {
                              staticClass: "mdc-notched-outline__path"
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "mdc-notched-outline__idle" })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        key: "city" + i,
                        staticClass:
                          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: owner.city,
                              expression: "owner.city"
                            }
                          ],
                          staticClass: "mdc-text-field__input",
                          attrs: {
                            type: "text",
                            autocomplete: "off",
                            autocorrect: "off",
                            spellcheck: "false"
                          },
                          domProps: { value: owner.city },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(owner, "city", $event.target.value)
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "mdc-floating-label",
                            class:
                              owner.city != ""
                                ? "mdc-floating-label--float-above"
                                : "",
                            attrs: { for: "city" }
                          },
                          [_vm._v(_vm._s(_vm.$t("signup.city")))]
                        ),
                        _c("div", { staticClass: "mdc-notched-outline" }, [
                          _c("svg", [
                            _c("path", {
                              staticClass: "mdc-notched-outline__path"
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "mdc-notched-outline__idle" })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        key: "state" + i,
                        staticClass:
                          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-text-field mdc-text-field--outlined mdc-text-field--notched"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: owner.state,
                              expression: "owner.state"
                            }
                          ],
                          staticClass: "mdc-text-field__input",
                          attrs: {
                            type: "text",
                            autocomplete: "off",
                            autocorrect: "off",
                            spellcheck: "false"
                          },
                          domProps: { value: owner.state },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(owner, "state", $event.target.value)
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "mdc-floating-label ",
                            class:
                              owner.state != ""
                                ? "mdc-floating-label--float-above"
                                : "",
                            attrs: { for: "state" }
                          },
                          [_vm._v(_vm._s(_vm.$t("signup.state")))]
                        ),
                        _c("div", { staticClass: "mdc-notched-outline" }, [
                          _c("svg", [
                            _c("path", {
                              staticClass: "mdc-notched-outline__path"
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "mdc-notched-outline__idle" })
                      ]
                    )
                  ]
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
                  },
                  [
                    _c("div"),
                    _c(
                      "button",
                      {
                        staticClass: "mdc-button",
                        attrs: { type: "button", "aria-label": "新增擁有人" },
                        on: { click: _vm.addOwner }
                      },
                      [
                        _c("span", { staticClass: "mdc-button__label" }, [
                          _vm._v(_vm._s(_vm.$t("signup.addOwner")))
                        ])
                      ]
                    ),
                    _c("div")
                  ]
                ),
                _vm.errors.length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
                      },
                      [
                        _c("p", { staticClass: "error-prompt" }, [
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function(error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error))
                              ])
                            })
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "mdc-fab mdc-fab--extended",
                        attrs: {
                          type: "button",
                          "aria-label": _vm.$t("actions.back")
                        },
                        on: { click: _vm.goPrevious }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "mdc-fab__icon",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewbox: "0 0 24 24"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M15.3,17.3c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0L11.4,12l3.9-3.9c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-4.6,4.6c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0l4.6,4.6C14.3,17.7,14.9,17.7,15.3,17.3z"
                              }
                            })
                          ]
                        ),
                        _c("span", { staticClass: "mdc-fab__label" }, [
                          _vm._v(_vm._s(_vm.$t("actions.back")))
                        ])
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "mdc-fab mdc-fab--extended",
                        attrs: {
                          type: "submit",
                          "aria-label": _vm.$t("actions.next")
                        }
                      },
                      [
                        _c("span", { staticClass: "mdc-fab__label" }, [
                          _vm._v(_vm._s(_vm.$t("actions.next")))
                        ]),
                        _c(
                          "svg",
                          {
                            staticClass: "mdc-fab__icon",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewbox: "0 0 24 24"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]
                )
              ],
              2
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }