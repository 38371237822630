













































































































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import VueNumeric from 'vue-numeric';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import { MDCFormField } from '@material/form-field';
import { MDCCheckbox } from '@material/checkbox';
import { IMaskComponent } from 'vue-imask';
import { isPlatform } from '@/utils/platform';
import moment from '@/utils/moment_HK_en';
import bankOptions from '@/constants/BANKS';
import {
    placeHolderForCurrency as placeHolderForCurrency_,
    symbolDisplay as symbolDisplay_,
    currencyNameWithSymbol,
} from '@/utils/helpers/currencyHelper';
import { currency } from '../../i18n/en/currency';

const currencyList = bankOptions.currencyOptions;
// import {CurrencyInput} from 'vue-currency-input';

function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
const env = process.env.VUE_APP_ENV;
@Component({
    filters: {
        currencySymbolDisplay: currencyNameWithSymbol,
    },
    components: {
        VueNumeric,
        'imask-input': IMaskComponent,
        // 'currency-input': CurrencyInput
    },
})
export default class Amount extends Vue {
    // private currencyList = currencyList;

    @Prop() value!: any;

    @Prop({ default: false })
    isRecurrent!: boolean;

    @Prop(String) display!: string;

    @Watch('value_')
    onValueChanged(v: string) {
        this.$emit('input', v);
    }

    @Watch('isRecurrent__')
    onIsRecurrentChanged(newVal: string) {
        this.currency = 'hkd';
        this.$emit('update:isRecurrent', newVal);
    }

    @Watch('display')
    onDisplayChanged(display: string) {
        if (display === 'amount') {
            this.$store.commit('showNavbar', true);
            this.isRecurrent_ = !this.isRecurrent_;
            this.isRecurrent_ = !this.isRecurrent_;
        }
    }

    get isTestingMode() {
        return env !== 'production' ? true : false;
    }

    public currency = 'hkd';
    public amount = 0;

    public description = '';
    // private display_: string = this.display;
    private check = 0;
    private checkDuration = 0;
    private checkDescription = 0;
    private mdcTextFields!: any;
    private input_user_el: any = { value: '' };
    private amountShown = '';
    private isRecurrent__: boolean = this.isRecurrent || false;
    private recurrentDuration_: number | null = null;
    private recurrentAutoExtend: boolean = false;
    private mdcTabBar!: any;
    // private chargeLimit = this.$store.state.user.charge_limit || 100000;
    private sacid = this.$store.state.user.sacid;

    private checked = false;

    private paymentConfirm_open = false;

    public maskAmount = '';
    public maskTotalAmount = '';
    private earliestPossibleExpiryData: string = '';

    // private selectedCurrency = "hkd";

    get chargeLimit() {
        return this.$store.state.user.charge_limit_for_each_currency && this.currency
            ? this.$store.state.user.charge_limit_for_each_currency[this.currency]
            : this.$store.state.user.charge_limit
                ? this.$store.state.user.charge_limit
                : 100000;
    }

    get chargeLimitDisplay() {
        return this.chargeLimit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    get value_() {
        return {
            amount: this.amount,
            currency: this.currency,
            description: this.description,
            ...(this.isRecurrent__ && {
                recurrent: {
                    duration: this.recurrentDuration,
                    autoExtend: this.recurrentAutoExtend,
                },
            }),
        };
    }

    public placeHolderForCurrency(currency: string) {
        return placeHolderForCurrency_(currency);
    }

    get currentLang() {
        return document.documentElement.lang;
    }

    get langClass() {
        return {
            'lang--zh-HK': this.currentLang == 'zh-HK',
        };
    }

    get enableRecurrence() {
        return this.$store.state.user && this.$store.state.user.permissions_.payment.enable_recurrence;
    }

    get isRecurrent_() {
        return this.isRecurrent__;
    }

    set isRecurrent_(isRecurrent) {
        this.isRecurrent__ = isRecurrent;
    }

    get recurrentDuration() {
        return this.recurrentDuration_;
    }

    set recurrentDuration(x) {
        this.recurrentDuration_ = x;
    }

    get currencyList() {
        if (this.isRecurrent_) {
            return currencyList.filter(({ text, value: currency }) => {
                return this.$store.state.user.allowedCurrencies['recurrence'].includes(currency);
            });
        }
        return currencyList.filter(({ text, value: currency }) => {
            return this.$store.state.user.allowedCurrencies['direct'].includes(currency);
        });
    }

    public mounted() {
        this.$emit('input', this.value_);
        this.input_user_el = (this.$refs.paymentInputUser as any).$el;
        this.initMDCTextFields();
        this.$root.$on('resetPayment', () => {
            this.reset();
        });

        this.$root.$on('switchToCard', () => {
            this.amount = this.value.amount;
            this.description = this.value.description;
        });
    }

    private convertPlusToDot = (amount: any) => amount.replace('+', '.');

    private async onCompleteHandler() {
        this.amountShown = this.input_user_el.value.length > 0 ? `${this.symbolDisplay(this.currency)}${this.input_user_el.value}` : '';
        this.check = this.amount > this.chargeLimit ? 1 : 0;
    }

    private async onCompleteHandler_Duration() {
        this.checkDuration = this.recurrentDuration_ ? 0 : 1;
    }

    private async onCompleteHandler_Description() {
        this.checkDescription = this.description ? 0 : 1;
    }

    private async nextPage() {
        this.check = 0;
        this.checkDuration = 0;
        this.checkDescription = 0;

        if (this.amount < 10 || this.amount > this.chargeLimit) {
            this.check = 1;
        }

        if (this.isRecurrent__ && (!this.recurrentDuration_ || this.recurrentDuration_ <= 1 || this.recurrentDuration_ > 99)) {
            this.checkDuration = 1;
        }

        if (this.isRecurrent__ && !this.value.description) {
            this.checkDescription = 1;
        }

        if (this.check || this.checkDuration || this.checkDescription) {
            return;
        }
        if (this.isRecurrent__ && this.recurrentDuration_ != null) {
            this.maskAmount = numberWithCommas(this.amount);
            this.maskTotalAmount = numberWithCommas(this.amount * this.recurrentDuration_);
            this.earliestPossibleExpiryData = moment()
                .add(this.recurrentDuration_, 'M')
                .startOf('month')
                .format('MM/YYYY');
            this.paymentConfirm_open = true;
        } else {
            this.$emit('next');
        }
        // this.$store.commit('show', 'card');
        // this.$store.commit('showNavbar', false);
        // this.$store.commit('storeAmount', this.amount);
        // this.$store.commit('setDescription', this.description);
    }
    private payDialogConfirm_accept() {
        this.$emit('next');
    }

    public symbolDisplay(currency: string) {
        return symbolDisplay_(currency);
    }

    public reset(donotResetRecurrent?: boolean) {
        this.$emit('reset', donotResetRecurrent);
        this.$root.$emit('reset');
        this.amount = 0;
        this.description = '';

        this.input_user_el.value = '';
        this.mdcTextFields[1].value = '';
        this.recurrentAutoExtend = false;
        this.recurrentDuration = null;

        // this.$store.commit('storeToken', {
        //     id: '',
        //     holder_name: '',
        //     exp_year: '',
        //     exp_month: '',
        //     last4: '',
        // });

        donotResetRecurrent || (this.isRecurrent_ = false);
    }

    public autofill(isAutofill?: boolean) {
        this.amount = 1000;
        this.description = 'Testing';
        this.input_user_el.value = '';
        this.mdcTextFields[1].value = '';
    }

    // animation function
    private initMDCTextFields() {
        this.mdcTextFields = Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
        const mdcCheckBox = new MDCCheckbox(this.$el.querySelector('.mdc-checkbox'));
        const formField = new MDCFormField(this.$el.querySelector('.mdc-form-field'));
        formField.input = mdcCheckBox;
        // }
    }
}
