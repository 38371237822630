const bankOptions = {
    development: [
        {
            bank_code: '110',
            bank_name: 'Test Bank',
            chinese_name: '測試銀行',
        },
    ],

    staging: [
        {
            bank_code: '110',
            bank_name: 'Test Bank',
            chinese_name: '測試銀行',
        },
    ],

    production: [
        {
            bank_code: '250',
            bank_name: 'Citibank (Hong Kong)',
            chinese_name: '花旗銀行(香港)有限公司',
        },
        {
            bank_code: '038',
            bank_name: 'Tai Yau Bank',
            chinese_name: '大有銀行有限公司',
        },
        {
            bank_code: '128',
            bank_name: 'Fubon Bank (Hong Kong)',
            chinese_name: '富邦銀行(香港)有限公司',
        },
        {
            bank_code: '028',
            bank_name: 'Public Bank (Hong Kong)',
            chinese_name: '大眾銀行(香港)有限公司',
        },
        {
            bank_code: '015',
            bank_name: 'Bank of East Asia, The',
            chinese_name: '東亞銀行有限公司',
        },
        {
            bank_code: '012',
            bank_name: 'Bank of China (Hong Kong)',
            chinese_name: '中國銀行(香港)有限公司',
        },
        {
            bank_code: '018',
            bank_name: 'China CITIC Bank International',
            chinese_name: '中信銀行國際有限公司',
        },
        {
            bank_code: '024',
            bank_name: 'Hang Seng Bank',
            chinese_name: '恒生銀行有限公司',
        },
        {
            bank_code: '382',
            bank_name: 'Bank of Communications',
            chinese_name: '交通银行',
        },
        {
            bank_code: '009',
            bank_name: 'China Construction Bank (Asia)',
            chinese_name: '中國建設銀行(亞洲)股份有限公司',
        },
        {
            bank_code: '043',
            bank_name: 'Nanyang Commercial Bank',
            chinese_name: '南洋商業銀行有限公司',
        },
        {
            bank_code: '061',
            bank_name: 'Dah Sing Bank Limited',
            chinese_name: '大生銀行有限公司',
        },
        {
            bank_code: '003',
            bank_name: 'Standard Chartered Hong Kong',
            chinese_name: '渣打銀行(香港)有限公司',
        },
        {
            bank_code: '004',
            bank_name: 'Hongkong and Shanghai Banking Corporation',
            chinese_name: '香港上海滙豐銀行有限公司',
        },
        {
            bank_code: '072',
            bank_name: 'Industrial and Commercial Bank of China (Asia)',
            chinese_name: '中國工商銀行(亞洲)有限公司',
        },
        {
            bank_code: '041',
            bank_name: 'Chong Hing Bank',
            chinese_name: '創興銀行有限公司',
        },
        {
            bank_code: '040',
            bank_name: 'Dah Sing Bank',
            chinese_name: '大新銀行有限公司',
        },
        {
            bank_code: '035',
            bank_name: 'OCBC Wing Hang Bank',
            chinese_name: '華僑銀行有限公司',
        },
        {
            bank_code: '020',
            bank_name: 'Wing Lung Bank',
            chinese_name: '永隆銀行有限公司',
        },
        {
            bank_code: '039',
            bank_name: 'Chiyu Banking Corporation',
            chinese_name: '集友銀行有限公司',
        },
        {
            bank_code: '016',
            bank_name: 'DBS Bank (Hong Kong)',
            chinese_name: '星展銀行(香港)有限公司',
        },
        {
            bank_code: '025',
            bank_name: 'Shanghai Commercial Bank',
            chinese_name: '上海商業銀行有限公司',
        },
    ],
};

const countryOptions = [{ value: 'hk', text: 'Hong Kong (香港)' }];

const currencyOptions = [
    { value: 'hkd', text: 'currency.hkd' },
    { value: 'usd', text: 'currency.usd' },
    { value: 'jpy', text: 'currency.jpy' },
    { value: 'mop', text: 'currency.mop' },
    { value: 'gbp', text: 'currency.gbp' },
    { value: 'cny', text: 'currency.cny' },
    { value: 'sgd', text: 'currency.sgd' },
    { value: 'myr', text: 'currency.myr' },
    { value: 'thb', text: 'currency.thb' },
];

const stripeFxFee = 0.02;

export default {
    bankOptions,
    countryOptions,
    currencyOptions,
    stripeFxFee,
};
