export const dashboard = {
    transaction: 'Transaction',
    refund: 'Refund',
    volume: 'Volume',
    count: 'Count',
    daily: 'Daily',
    weekly: '7 days',
    monthly: 'Monthly',
    payout: {
        history: 'Payout History (Credit Card)',
        arrivalDate: 'Arrival Date',
        expectedArrivalDate: 'Expected Arrival Date',
        total: 'Total',
        net: 'Net',
        type: {
            title: 'Payout Type',
            charge: 'Charge',
            refund: 'Refund',
            adjustment: 'Adjustment',
        },
        payment: {
            title: 'Payment Type',
        },
    },
};
