export const errors = {
    label: '錯誤',
    GENERAL: {
        retry: '發生錯誤，請重試。',
        contact: '請聯絡JARVIX PAY。',
        editing: '請先儲存編緝中的資料。',
    },
    PAYMENT_DETAILS: {
        invalid_transaction: '交易金額是必須為$10-${0}。',
        missing_description: '交易詳情是必須填寫。',
        invalid_recurrent_duration: '月付期數是必須介乎2-99個月。',
        missing_message: '客戶訊息是必須填寫。',
    },
    REMOTE: {
        empty_customer: '客戶姓名和電話是必須填寫。',
        duplicated_customer: '客戶電話是不能重覆的。',
        missing_transaction: '請填寫收款資料。',
        max_customers: '每次遙距請求最多添加10位客戶。',
        min_customers: '每次遙距請求最少添加1位客戶。',
    },
    CAMPAIGN: {
        empty_contact: '店鋪名稱和聯絡電話是必須填寫。',
        empty_product: '名稱、價錢和每次限購數量是必須填寫。',
        invalid_price: '價錢不能低於0元正。',
        missing_original_price: '請輸入原價。',
        invalid_discount: '實價必須低於原價。',
        invalid_quantity_per_order: '每次限購數量是不能多於總數量。',
        exceed_photo_limit: '最多只可以5張圖片。',
        exceed_product_limit: '最多只可以添加20個產品項目。',
        exceed_link_limit: '最多只可以添加25條連結。',
        less_than_minimum: '請輸入至少一個{0}。',
        missing_input: '請輸入{0}。',
        mc_option: '自訂問題選項已被開啓，請輸入問題和選項。',
    },
    ERROR_MSG: {
        title: 'Error',
        Invalid_Credentials: '帳戶或密碼不正確。 [Code: c00]',
        Network_Error: '發生網絡錯誤，請檢查您的互聯網連接。 [Code: n00]',
        The_requested_charge_is_invalid: '找不到這交易的詳細紀錄。 [Code: d01]',
        Email_is_already_taken: '電子郵件已被註冊。',
        The_verification_code_field_is_invalid: '驗證碼無效。',
        The_password_is_invalid: '請輸入正確的舊密碼。',
        The_new_password_is_repeated: '新密碼不能與舊密碼相同。',
        Duplicate_Entry_Error: '該名稱已被使用。請輸入其他名稱。',
        Payment_Error_b01: '交易異常，請聯絡Jarvix Pay以取得協助 (3618 7750/6922 5068)。 [Code: b01]',
        Payment_Error_r01: '交易異常，請聯絡Jarvix Pay以取得協助 (3618 7750/6922 5068)。 [Code: r01]',
        // upload documents
        "Front: We don't currently support that file type. Try uploading a file with one of the following mimetypes: image/jpeg, image/png":
            '文件格式錯誤，我們只支持jpeg, png的格式，請重新上載香港身份證副本(前面)',
        "Back: We don't currently support that file type. Try uploading a file with one of the following mimetypes: image/jpeg, image/png":
            '文件格式錯誤，我們只支持jpeg, png的格式，請重新上載香港身份證副本(背面)',
    },
    LAP_PAY_ERROR_MSG: {
        exceed_daily_trx_limit: '消費者戶口超過了每日的交易限額。',
        exceed_monthly_trx_limit: '消費者戶口超過了每月的交易限額。',
        exceed_daily_and_monthly_trx_limit: '消費者戶口超過了每日和每月的交易限額。',
        refund_rejected_after_100_days: '由於交易時間超過了100天，款項未能退款。',
        qr_code_expired: '二維碼已逾時。',
        insufficient_balance: '消費者戶口的結餘不足。',
        unsupported_card_type: '不支持消費者的付款卡類型。',
        unsupported_payment_type: '不支持消費者的付款類型。',
        'user_paying, please_input_password': '消費者正在付款，並需要消費者輸入支付密碼。',
        unable_to_pay_at_overseas_merchants: '由於政策原因，非身份證實名用戶暫時無法在境外商戶支付。',
        user_paying: '消費者正在付款。',
        internal_server_error: '伺服器錯誤, 請聯絡JARVIX PAY。 [Code: w00]',
        payment_gateway_error: '伺服器錯誤, 請聯絡JARVIX PAY。 [Code: w01]',
    },
    ERROR_TYPE: {
        unknown_error: '抱歉！ 發生未知錯誤，請稍後重試。 [Code: u00]',
        invalid_param_error: '伺服器錯誤, 請稍後重試。 [Code: i00]',
        database_error: '伺服器錯誤, 請稍後重試。 [Code: d00]',
        database_content_not_found: '伺服器錯誤, 請稍後重試。 [Code: d01]',
        passport_error: '用戶驗證錯誤, 請稍後重試。  [Code p00]',
        unexpected_error: '伺服器錯誤, 請稍後重試。 [Code: u01]',
        permission_error: '你的限權不足，請聯絡你的主管或JARVIX PAY。 [Code: p01]',
        duplicated_campaign_error: '營銷活動名稱曾經使用，請使用新的營銷活動名稱。',
        oversold_error: '搶購的優惠項目已售罄。',
    },
    STRIPE_CODE: {
        customer_canceled_authentication: '閣下必須通過身份驗證方可完成此交易。',
        expired_card: '閣下的信用卡已過期，請使用另一張。',
        incomplete_card: '請輸入信用卡資料。',
        incomplete_cvc: '閣下提供的信用卡安全碼並不完整。',
        incomplete_expiry: '閣下提供的信用卡到期日並不完整。',
        incomplete_iban: '閣下提供的IBAN並不完整。',
        incomplete_number: '閣下提供的信用卡號碼並不完整。',
        incomplete_zip: '閣下提供的郵遞區號並不完整。',
        incorrect_cvc: '閣下提供的信用卡安全碼並不完整。',
        incorrect_number: '閣下提供的信用卡號碼錯誤。',
        invalid_expiry_month: '閣下提供的信用卡過期日無效。',
        invalid_expiry_month_past: '閣下提供的信用卡過期日已過去。',
        invalid_expiry_year: '閣下提供的信用卡過期日無效。',
        invalid_expiry_year_past: '閣下提供的信用卡過期日已過去。',
        invalid_iban: '閣下提供的IBAN無效。',
        invalid_iban_country_code: '閣下提供的IBAN或國家/ 地區代碼並無效。',
        invalid_iban_start: '閣下提供的 IBAN 應以兩個字母的國家代碼作開首。',
        invalid_number: '閣下提供的信用卡並無效。',
        processing_error: '處理信用卡的資料時出錯，請稍後再試。',
        process_error_intransient: '處理信用卡的資料時出錯。',
        token_already_used: 'Token曾經被使用，請回到上一步重新再試。',
        unexpected: '一個意料之外的問題發生了。',
        not_accepted: '抱歉！閣下提供的信用卡不被接受，請聯絡我們。',
        account_invalid: '伺服器錯誤, 請聯絡JARVIX PAY。 [Code: s01]', // stripeId
        resource_missing: '伺服器錯誤, 請聯絡JARVIX PAY。 [Code: s02]',
    },
    STRIPE_DECLINE_CODE: {
        authentication_required: '閣下提供的信用卡不被接受，請聯絡所屬銀行。',
        approve_with_id: '閣下提供的信用卡不被接受，請重試。如果問題再次發生，請聯絡所屬銀行。',
        call_issuer: '閣下提供的信用卡不被接受，請聯絡所屬銀行。',
        card_not_supported: '閣下提供的信用卡並不支援。',
        card_velocity_exceeded: '閣下的信用卡因為重複嘗試過於頻繁而被拒絕。',
        currency_not_supported: '閣下提供的信用卡並不支援這種貨幣的兌換。',
        do_not_honor: '閣下提供的信用卡不被接受，可能是信用卡沒有足夠的資金。如果問題再次發生，請聯絡所屬銀行了解詳情。',
        do_not_try_again: '閣下提供的信用卡不被接受，請不要重試，並請聯絡所屬銀行了解詳情。',
        duplicate_transaction: '請檢查這筆交易是否重複。',
        expired_card: '閣下提供的信用卡已經到期，請使用其他信用卡。',
        fraudulent: '顧客提供的信用卡有可疑，請記下交易紀錄，並要求顧客使用其他信用卡。',
        fake_merchant_test_card: '此驗證觸發信用卡已被拒絕。此信用卡必须用於在測試模式中創建的賬戶。',
        generic_decline: '閣下提供的信用卡並無效，可能是信用卡沒有足夠的資金。如果問題再次發生，請聯絡所屬銀行了解詳情。',
        incorrect_cvc: '閣下提供的信用卡安全碼並不完整。',
        incorrect_number: '閣下提供的信用卡號碼並不完整。',
        invalid_expiry_month: '閣下提供的信用卡號碼與郵遞區號並不匹配。',
        invalid_expiry_month_past: '閣下提供的信用卡安全碼無效。',
        incorrect_pin: '用戶個人識別號碼錯誤。',
        insufficient_funds: '閣下提供的信用卡沒有足夠的資金。',
        invalid_pin: '用戶個人識別號碼無效。',
        invalid_account: '戶口無效。',
        invalid_amount: '金額無效。',
        issuer_not_available: '閣下提供的信用卡不被接受，請重試。如果問題再次發生，請聯絡所屬銀行。',
        lost_card: '閣下提供的信用卡是一張遺失的卡，所以不被允許使用。',
        live_mode_test_card: '您的信用卡遭到拒絕。您的請求處於實時模式，但使用了已知的測試卡。',
        merchant_blacklist: '閣下提供的信用卡並無效，可能是信用卡沒有足夠的資金。如果問題再次發生，請聯絡所屬銀行了解詳情。',
        new_account_information_available: '閣下提供的信用卡不被接受，請聯絡所屬銀行。',
        no_action_taken: '閣下提供的信用卡並無效，可能是信用卡沒有足夠的資金。如果問題再次發生，請聯絡所屬銀行了解詳情。',
        not_permitted: '閣下的交易不獲銀行允許，請聯絡所屬銀行了解詳情。',
        pickup_card: '閣下提供的信用卡是一張報失的卡，所以不被允許使用。',
        stolen_card: '閣下提供的信用卡是一張報失的卡，所以不被允許使用。',
        processing_error: '交易過程出現錯誤，請稍後再試。',
        restricted_card: '閣下提供的信用卡可能是一張報失的卡，所以不被允許使用。',
        revocation_of_all_authorizations: '閣下提供的信用卡不被接受，請聯絡所屬銀行。',
        revocation_of_authorization: '閣下提供的信用卡不被接受，請聯絡所屬銀行。',
        security_violation: '閣下提供的信用卡不被接受，請聯絡所屬銀行。',
        service_not_allowed: '閣下提供的信用卡不被接受，請聯絡所屬銀行。',
        stop_payment_order: '閣下提供的信用卡不被接受，請聯絡所屬銀行。',
        pin_try_exceeded: '超出輸入用戶個人識別號碼的次數上限。',
        test_mode_live_card: '您的信用卡遭到拒絕。您的請求處於測試模式，但使用了非測試卡。',
        transaction_not_allowed: '閣下的信用卡並不支持此種購貨。',
        your_card_was_declined: '抱歉！閣下提供的信用卡不被接受，請嘗試其他信用卡。',
        not_accepted: '抱歉！閣下提供的信用卡不被接受，請聯絡我們',
    },
    USER_INPUT_ERRORS: {
        please_select_at_least_one_item: '請選擇至少一件商品',
        please_enter_a_customer_name: '請輸入顧客名稱',
        please_enter_the_correct_mobile_phone_number: '請輸入正確的聯絡手提電話',
        please_enter_the_customer_age: '請輸入顧客年齡',
        please_enter_the_customer_address: '請輸入顧客地址',
        please_select_a_branch: '請選擇分店',
        please_enter_customer_gender: '請輸入顧客性別',
        please_enter_customer_email: '請輸入顧客電郵',
        please_enter_a_reservation_date: '請輸入預訂日期',
        the_system_does_not_allow_selection_of_past_day: '系統不允許選擇過往的日子',
        please_enter_a_customer_inquiry: '請輸入客戶諮詢內容。如果沒有，請填寫N/A',
        please_answer_all_mc_questions: '請回答所有選擇題',
    },
};
