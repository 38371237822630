var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-page app-page-payout app-page--active" },
    [
      _c("div", { staticClass: "app-page-container" }, [
        _c(
          "div",
          {
            staticClass:
              "app-page-activity mdc-layout-grid activity-settings-printer-scanner app-page-activity--active bottom-nav--hidden"
          },
          [
            _c("div", { staticClass: "big-card" }, [
              _c(
                "a",
                {
                  staticClass: "close-icon",
                  on: {
                    click: function($event) {
                      _vm.$router.go(-1)
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "close-icon__icon",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                        }
                      })
                    ]
                  )
                ]
              ),
              _c("div", { staticClass: "big-card__background" }),
              _c("div", { staticClass: "big-card__inner" }, [
                _c("div", { staticClass: "big-card__section" }, [
                  _vm.payout.amount
                    ? _c(
                        "div",
                        {
                          staticClass: "mdc-typography--headline4",
                          staticStyle: { "font-weight": "500" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm._f("currency")(_vm.payout.amount)) +
                              "\n                        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "flex-group flex-group__space-between" },
                    [
                      _vm.payout.status === "paid"
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "mdc-typography--body2",
                                staticStyle: { opacity: ".7" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("dashboard.payout.arrivalDate")
                                  ) + ": "
                                )
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "mdc-typography--caption",
                                staticStyle: { opacity: ".7" }
                              },
                              [_vm._v(_vm._s(_vm.arrivalFormated))]
                            )
                          ])
                        : _vm.payout.status === "in_transit"
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "mdc-typography--body2",
                                  staticStyle: { opacity: ".7" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "dashboard.payout.expectedArrivalDate"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "mdc-typography--caption",
                                  staticStyle: { opacity: ".7" }
                                },
                                [_vm._v(_vm._s(_vm.arrivalFormated))]
                              )
                            ])
                          : _vm._e(),
                      _c("PayoutStatus", {
                        attrs: { status: _vm.payout.status }
                      })
                    ],
                    1
                  )
                ]),
                _vm.items.length != 0
                  ? _c(
                      "div",
                      { staticClass: "big-card__section" },
                      [
                        _c(
                          "div",
                          { staticClass: "mdc-typography--headline6" },
                          [_vm._v(_vm._s(_vm.$t("general.history.title")))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "table table--hide-extra mdc-typography--body1",
                            attrs: {
                              onclick:
                                "this.classList.toggle('table--hide-extra')"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "table__cell table__head" },
                              [_vm._v(_vm._s(_vm.$t("dashboard.payout.total")))]
                            ),
                            _c(
                              "div",
                              { staticClass: "table__cell table__head" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("dashboard.payout.type.title"))
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "table__cell table__head" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("dashboard.payout.payment.title")
                                  )
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "table__cell table__head table__cell--end"
                              },
                              [_vm._v(_vm._s(_vm.$t("dashboard.payout.net")))]
                            ),
                            _vm._l(_vm.items, function(item) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key:
                                      item.id + item.itype + item.created + 1,
                                    staticClass: "table__cell"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm._f("currency")(item.amount)
                                        ) +
                                        "\n                                "
                                    )
                                  ]
                                ),
                                item.type === "charge"
                                  ? _c(
                                      "div",
                                      {
                                        key:
                                          item.id +
                                          item.itype +
                                          item.created +
                                          2,
                                        staticClass: "table__cell"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "dashboard.payout.type.charge"
                                              )
                                            ) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  : item.type === "refund"
                                    ? _c(
                                        "div",
                                        {
                                          key:
                                            item.id +
                                            item.itype +
                                            item.created +
                                            2,
                                          staticClass: "table__cell"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "dashboard.payout.type.refund"
                                                )
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    : item.type === "adjustment"
                                      ? _c(
                                          "div",
                                          {
                                            key:
                                              item.id +
                                              item.itype +
                                              item.created +
                                              2,
                                            staticClass: "table__cell"
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "dashboard.payout.type.adjustment"
                                                  )
                                                ) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            key:
                                              item.id +
                                              item.itype +
                                              item.created +
                                              2,
                                            staticClass: "table__cell"
                                          },
                                          [_vm._v("其他")]
                                        ),
                                item.category === "direct"
                                  ? _c(
                                      "div",
                                      {
                                        key:
                                          item.id +
                                          item.itype +
                                          item.created +
                                          3,
                                        staticClass: "table__cell"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(_vm.$t("general.direct")) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  : item.category === "recurrent"
                                    ? _c(
                                        "div",
                                        {
                                          key:
                                            item.id +
                                            item.itype +
                                            item.created +
                                            3,
                                          staticClass: "table__cell"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.$t("general.recurrent")
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    : item.category === "remote"
                                      ? _c(
                                          "div",
                                          {
                                            key:
                                              item.id +
                                              item.itype +
                                              item.created +
                                              3,
                                            staticClass: "table__cell"
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t("general.remote")
                                                ) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      : item.category === "campaign"
                                        ? _c(
                                            "div",
                                            {
                                              key:
                                                item.id +
                                                item.itype +
                                                item.created +
                                                3,
                                              staticClass: "table__cell"
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.$t("general.campaign")
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : item.category === "online"
                                          ? _c(
                                              "div",
                                              {
                                                key:
                                                  item.id +
                                                  item.itype +
                                                  item.created +
                                                  3,
                                                staticClass: "table__cell"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.$t("general.online")
                                                    ) +
                                                    "\n                                "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                key:
                                                  item.id +
                                                  item.itype +
                                                  item.created +
                                                  3,
                                                staticClass: "table__cell"
                                              },
                                              [_vm._v("N/A")]
                                            ),
                                _c(
                                  "div",
                                  {
                                    key:
                                      item.id + item.itype + item.created + 4,
                                    staticClass: "table__cell table__cell--end"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm._f("currency")(item.net)) +
                                        "\n                                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    key:
                                      item.id + item.itype + item.created + 5,
                                    staticClass:
                                      "table__cell table__cell--extra mdc-typography--caption"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.createdFormated(item.created)
                                        ) +
                                        "\n                                "
                                    )
                                  ]
                                ),
                                _c("hr", {
                                  key: item.id + item.itype + item.created,
                                  staticClass: "table__divider"
                                })
                              ]
                            })
                          ],
                          2
                        ),
                        _c(
                          "mdc-button",
                          {
                            attrs: { dense: "", disabled: !_vm.moreButton },
                            on: { click: _vm.moreItems }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.moreButton
                                  ? _vm.$t("actions.loadMore")
                                  : _vm.$t("actions.noMore")
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "big-card__section" },
                      [
                        _c(
                          "div",
                          { staticClass: "mdc-typography--headline6" },
                          [_vm._v(_vm._s(_vm.$t("general.history.title")))]
                        ),
                        _c(
                          "mdc-button",
                          { attrs: { dense: "", disabled: !_vm.moreButton } },
                          [_vm._v(_vm._s(_vm.$t("actions.noMore")))]
                        )
                      ],
                      1
                    )
              ])
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }