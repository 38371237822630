




















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IMaskComponent } from 'vue-imask';

@Component({
    components: {
        'imask-input': IMaskComponent,
    }
})
export default class RemoteCampaignLinkItem extends Vue {
    private mask = /^[a-zA-Z0-9- ]*$/;
    private prepare = (s: string) => s.replace(/ /g, '-').toLowerCase()

    @Prop()
    index!: number;

    @Prop()
    campaignName!: string;

    @Prop()
    name!: string;

    get name_() {
        return this.name;
    }

    set name_(v: any) {
        this.$emit('update:name', v);
    }

    private deleteLink() {
        this.$emit('delete');
    }
}
