var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-page app-page--active" }, [
    _c("div", { staticClass: "app-page-container" }, [
      _c(
        "div",
        {
          staticClass:
            "mdc-layout-grid app-page-activity app-page-activity--active"
        },
        [
          _c("div", { attrs: { id: "lending" } }, [
            _c(
              "a",
              {
                staticClass: "close-icon",
                on: {
                  click: function($event) {
                    _vm.$router.go(-1)
                  }
                }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "close-icon__icon",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 24 24"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                      }
                    })
                  ]
                )
              ]
            ),
            _c("iframe", {
              attrs: {
                id: "bdIframe",
                width: "100%",
                height: "800",
                src: "https://jarvixcapital.com/zh-hk",
                frameborder: "0"
              }
            })
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }