var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "recurrents-list-item",
      on: {
        click: function($event) {
          _vm.$emit("click")
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass:
              "recurrents-list-item__amount mdc-typography--headline5"
          },
          [
            _vm._v(
              _vm._s(_vm._f("currencySymbolDisplay")(_vm.remote.currency)) +
                _vm._s(_vm.remote.amount)
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "tags__group recurrents-list-item__status" },
          [_c("RemoteStatusTag", { attrs: { status: _vm.remote.status } })],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "row-left-section" }, [
          _c("div", { staticClass: "mdc-typography--body2" }, [
            _vm._v(
              _vm._s(_vm.$t("customer.name")) +
                ": " +
                _vm._s(_vm.remote.customer_name)
            )
          ]),
          _c("div", { staticClass: "mdc-typography--body2" }, [
            _vm._v(
              _vm._s(_vm.$t("customer.phone")) +
                ": " +
                _vm._s(
                  "" +
                    _vm.remote.customer_country_code +
                    _vm.remote.customer_phone
                )
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "row-right-section" },
          [
            _vm.remote.status !== "succeeded"
              ? _c(
                  "mdc-button",
                  {
                    staticClass: "button",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.send($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("actions.remind")))]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "recurrents-list-item__desc mdc-typography--caption",
            staticStyle: { opacity: ".54" }
          },
          [_vm._v(_vm._s(_vm.remote.description || "—"))]
        ),
        _c(
          "div",
          {
            staticClass:
              "recurrents-list-item__datetime mdc-typography--caption"
          },
          [_vm._v(_vm._s(_vm.moment(_vm.remote.created_at).format("LLL")))]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }