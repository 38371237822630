



































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import scrollIntoView from 'scroll-into-view-if-needed';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import * as Exif from 'exif-js';
import AUTH_MSG from '../constants/AUTH';
import { compressImageFile } from '@/utils/compressImageFile';
import { isPlatform } from '@/utils/platform';

const scrollIntoViewSmoothly = 'scrollBehavior' in document.documentElement.style ? scrollIntoView : smoothScrollIntoView;

@Component({})
export default class ReceiptCopy extends Vue {
    @Prop() chargeId!: string;
    @Prop() index!: number;
    @Prop() addButtonShown!: boolean;
    @Prop() existing!: boolean;
    @Prop({ default: false })
    editing!: boolean;

    get _editing() {
        return this.editing;
    }

    set _editing(v) {
        this.$emit('update:editing', v);
    }

    get isMobile() {
        return isPlatform(window, 'android') || isPlatform(window, 'ios');
    }

    private prev = {
        url: '',
        thumbnail: '',
        file: '',
        uploaded: false,
    };

    private curr = {
        url: '',
        thumbnail: '',
        file: '',
        uploaded: false,
    };

    private isPreviewHover = false;
    private loaded = false;

    protected mounted() {
        (this.$refs.img as HTMLElement).onload = () => {
            this.loaded = true;
        };

        if (this.existing) {
            return this.getReceiptLink();
        }

        return;
    }

    protected checkOrientation = async (file: any) => {
        return new Promise(function(resolve, reject) {
            Exif.getData(file, function(this: any) {
                const orientation = Exif.getTag(this, 'Orientation');
                resolve(orientation);
            });
        });
    };

    protected async previewFile(event: any) {
        const tmp = event.target.files[0];

        if (!tmp) {
            return;
        }

        if (!tmp.type.includes('image')) {
            return this.$root.$emit('error', {
                message: AUTH_MSG.DOCUMENT_ERROR,
            });
        }

        if (!this.prev.uploaded) {
            this.prev = Object.assign({}, this.curr);
        }

        const orientation = await this.checkOrientation(tmp);
        const quality = 0.95;

        await compressImageFile(tmp, quality, orientation)
            .then((res: any) => {
                this.curr.thumbnail = res.thumbnail;
                this.curr.file = res.compressed;
                this.curr.url = URL.createObjectURL(res.thumbnail);
                this.curr.uploaded = false;
                this._editing = true;
                this.isPreviewHover = false;
                (this.$refs as any).img.addEventListener('load', () => {
                    scrollIntoViewSmoothly((this.$refs as any).img, {
                        behavior: 'smooth',
                        scrollMode: 'if-needed',
                    } as any);
                });
            })
            .catch((err: any) => {
                // console.log(err);
                return this.$root.$emit('error', {
                    message: '發生錯誤，請重試。',
                });
            });
    }

    protected cancelFile() {
        (this.$refs.preview as any).value = '';
        this._editing = false;
        if (this.prev.uploaded) {
            this.curr = Object.assign({}, this.prev);
        } else {
            this.curr = {
                url: '',
                thumbnail: '',
                file: '',
                uploaded: false,
            };
        }
    }

    protected async uploadFile() {
        return this.$store
            .dispatch('uploadReceipt', {
                chargeId: this.chargeId,
                thumbnail: this.curr.thumbnail,
                original: this.curr.file,
                index: this.index,
            })
            .then((response: any) => {
                this.curr.uploaded = true;
                this._editing = false;
                this.$emit('addReceiptCopy');
            })
            .catch((error: any) => this.$root.$emit('error', error));
    }

    protected async getReceiptLink() {
        return this.$store
            .dispatch('getSignedS3UrlForView', {
                chargeId: this.chargeId,
                index: this.index,
            })
            .then((signedUrl: any) => {
                this.curr.url = signedUrl;
                this.curr.uploaded = true;
                this._editing = false;
            })
            .catch((error: any) => this.$root.$emit('error', error));
    }
}
