export const errors = {
    label: '错误',
    GENERAL: {
        retry: '发生错误，请重试。',
        contact: '请联络JARVIX PAY。',
        editing: '请先储存编缉中的资料。',
    },
    PAYMENT_DETAILS: {
        invalid_transaction: '交易金额是必须为$10-${0}。',
        missing_description: '交易详情是必须填写。',
        invalid_recurrent_duration: '月付期数是必须介乎2-99个月。',
        missing_message: '客户讯息是必须填写。',
    },
    REMOTE: {
        empty_customer: '客户姓名和电话是必须填写。',
        duplicated_customer: '客户电话是不能重覆的。',
        missing_transaction: '请填写收款资料。',
        max_customers: '每次遥距请求最多添加10位客户。',
        min_customers: '每次遥距请求最少添加1位客户。',
    },
    CAMPAIGN: {
        empty_contact: '店铺名称和联络电话是必须填写。',
        empty_product: '名称 、价钱和每次限购数量是必须填写。',
        invalid_price: '价钱不能低于0元正。',
        missing_original_price: '请输入原价。',
        invalid_discount: '实价必须低于原价。',
        invalid_quantity_per_order: '每次限购数量是不能多于总数量。',
        exceed_photo_limit: '最多只可以5张图片。',
        exceed_product_limit: '最多只可以添加20个产品项目。',
        exceed_link_limit: '最多只可以添加25条连结。',
        less_than_minimum: '请输入至少一个{0}。',
        missing_input: '请输入{0}。',
        mc_option: '自订问题选项已被开启，请输入问题和选项。',
    },
    ERROR_MSG: {
        title: '错误',
        Invalid_Credentials: '帐户或密码不正确。 [Code: c00]',
        Network_Error: '发生网络错误，请检查您的互联网连接。 [Code: n00]',
        The_requested_charge_is_invalid: '找不到这交易的详细纪录。 [Code: d01]',
        Email_is_already_taken: '电子邮件已被注册。',
        The_verification_code_field_is_invalid: '验证码无效。',
        The_password_is_invalid: '请输入正确的旧密码。',
        The_new_password_is_repeated: '新密码不能与旧密码相同。',
        Duplicate_Entry_Error: '该名称已被使用。请输入其他名称。',
        Payment_Error_b01: '交易异常，请联络Jarvix Pay以取得协助 (3618 7750/6922 5068)。 [Code: b01]',
        Payment_Error_r01: '交易异常，请联络Jarvix Pay以取得协助 (3618 7750/6922 5068)。 [Code: r01]',
        "Front:_We_don't_currently_support_that_file_type_Try_uploading_a_file_with_one_of_the_following_mimetypes:_image/jpeg,_image/png":
            '文件格式错误，我们只支持jpeg, png的格式，请重新上载香港身份证副本(前面)',
        "Back:_We_don't_currently_support_that_file_type_Try_uploading_a_file_with_one_of_the_following_mimetypes:_image/jpeg,_image/png":
            '文件格式错误，我们只支持jpeg, png的格式，请重新上载香港身份证副本(背面)',
    },
    LAP_PAY_ERROR_MSG: {
        exceed_daily_trx_limit: '消费者户口超过了每日的交易限额。',
        exceed_monthly_trx_limit: '消费者户口超过了每月的交易限额。',
        exceed_daily_and_monthly_trx_limit: '消费者户口超过了每日和每月的交易限额。',
        refund_rejected_after_100_days: '由于交易时间超过了100天，款项未能退款。',
        qr_code_expired: '二维码已逾时。',
        insufficient_balance: '消费者户口的结余不足。',
        unsupported_card_type: '不支持消费者的付款卡类型。',
        unsupported_payment_type: '不支持消费者的付款类型。',
        'user_paying, please_input_password': '消费者正在付款，并需要消费者输入支付密码。',
        unable_to_pay_at_overseas_merchants: '由于政策原因，非身份证实名用户暂时无法在境外商户支付。',
        user_paying: '消费者正在付款。',
        internal_server_error: '伺服器错误, 请联络JARVIX PAY。 [Code: w00]',
        payment_gateway_error: '伺服器错误, 请联络JARVIX PAY。 [Code: w01]',
    },
    ERROR_TYPE: {
        unknown_error: '抱歉！发生未知错误，请稍后重试。 [Code: u00]',
        invalid_param_error: '伺服器错误, 请稍后重试。 [Code: i00]',
        database_error: '伺服器错误, 请稍后重试。 [Code: d00]',
        database_content_not_found: '伺服器错误, 请稍后重试。 [Code: d01]',
        passport_error: '用户验证错误, 请稍后重试。 [Code p00]',
        unexpected_error: '伺服器错误, 请稍后重试。 [Code: u01]',
        permission_error: '你的限权不足，请联络你的主管或JARVIX PAY。 [Code: p01]',
        duplicated_campaign_error: '营销活动名称曾经使用，请使用新的营销活动名称。',
        oversold_error: '抢购的优惠项目已售罄。',
    },
    STRIPE_CODE: {
        customer_canceled_authentication: '阁下必须通过身份验证方可完成此交易。',
        expired_card: '阁下的信用卡已过期，请使用另一张。',
        incomplete_card: '请输入信用卡资料。',
        incomplete_cvc: '阁下提供的信用卡安全码并不完整。',
        incomplete_expiry: '阁下提供的信用卡到期日并不完整。',
        incomplete_iban: '阁下提供的IBAN并不完整。',
        incomplete_number: '阁下提供的信用卡号码并不完整。',
        incomplete_zip: '阁下提供的邮递区号并不完整。',
        incorrect_cvc: '阁下提供的信用卡安全码并不完整。',
        incorrect_number: '阁下提供的信用卡号码错误。',
        invalid_expiry_month: '阁下提供的信用卡过期日无效。',
        invalid_expiry_month_past: '阁下提供的信用卡过期日已过去。',
        invalid_expiry_year: '阁下提供的信用卡过期日无效。',
        invalid_expiry_year_past: '阁下提供的信用卡过期日已过去。',
        invalid_iban: '阁下提供的IBAN无效。',
        invalid_iban_country_code: '阁下提供的IBAN或国家/ 地区代码并无效。',
        invalid_iban_start: '阁下提供的 IBAN 应以两个字母的国家代码作开首。',
        invalid_number: '阁下提供的信用卡并无效。',
        processing_error: '处理信用卡的资料时出错，请稍后再试。',
        process_error_intransient: '处理信用卡的资料时出错。',
        token_already_used: 'Token曾经被使用，请回到上一步重新再试。',
        unexpected: '一个意料之外的问题发生了。',
        account_invalid: '伺服器错误, 请联络JARVIX PAY。 [Code: s01]',
        resource_missing: '伺服器错误, 请联络JARVIX PAY。 [Code: s02]',
        not_accepted: '抱歉！阁下提供的信用卡不被接受，请联络我们。',
    },
    STRIPE_DECLINE_CODE: {
        authentication_required: '阁下提供的信用卡不被接受，请联络所属银行。',
        approve_with_id: '阁下提供的信用卡不被接受，请重试。如果问题再次发生，请联络所属银行。',
        call_issuer: '阁下提供的信用卡不被接受，请联络所属银行。',
        card_not_supported: '阁下提供的信用卡并不支援。',
        card_velocity_exceeded: '阁下的信用卡因为重复尝试过于频繁而被拒绝。',
        currency_not_supported: '阁下提供的信用卡并不支援这种货币的兑换。',
        do_not_honor: '阁下提供的信用卡不被接受，可能是信用卡没有足够的资金。如果问题再次发生，请联络所属银行了解详情。',
        do_not_try_again: '阁下提供的信用卡不被接受，请不要重试，并请联络所属银行了解详情。',
        duplicate_transaction: '请检查这笔交易是否重复。',
        expired_card: '阁下提供的信用卡已经到期，请使用其他信用卡。',
        fraudulent: '顾客提供的信用卡有可疑，请记下交易纪录，并要求顾客使用其他信用卡。',
        fake_merchant_test_card: '此验证触发信用卡已被拒绝。此信用卡必须用于在测试模式中创建的账户。',
        generic_decline: '阁下提供的信用卡并无效，可能是信用卡没有足够的资金。如果问题再次发生，请联络所属银行了解详情。',
        incorrect_cvc: '阁下提供的信用卡安全码并不完整。',
        incorrect_number: '阁下提供的信用卡号码并不完整。',
        invalid_expiry_month: '阁下提供的信用卡号码与邮递区号并不匹配。',
        invalid_expiry_month_past: '阁下提供的信用卡安全码无效。',
        incorrect_pin: '用户个人识别号码错误。',
        insufficient_funds: '阁下提供的信用卡没有足够的资金。',
        invalid_pin: '用户个人识别号码无效。',
        invalid_account: '户口无效。',
        invalid_amount: '金额无效。',
        issuer_not_available: '阁下提供的信用卡不被接受，请重试。如果问题再次发生，请联络所属银行。',
        lost_card: '阁下提供的信用卡是一张遗失的卡，所以不被允许使用。',
        live_mode_test_card: '您的信用卡遭到拒绝。您的请求处于实时模式，但使用了已知的测试卡。',
        merchant_blacklist: '阁下提供的信用卡并无效，可能是信用卡没有足够的资金。如果问题再次发生，请联络所属银行了解详情。',
        new_account_information_available: '阁下提供的信用卡不被接受，请联络所属银行。',
        no_action_taken: '阁下提供的信用卡并无效，可能是信用卡没有足够的资金。如果问题再次发生，请联络所属银行了解详情。',
        not_permitted: '阁下的交易不获银行允许，请联络所属银行了解详情。',
        pickup_card: '阁下提供的信用卡是一张报失的卡，所以不被允许使用。',
        stolen_card: '阁下提供的信用卡是一张报失的卡，所以不被允许使用。',
        processing_error: '交易过程出现错误，请稍后再试。',
        restricted_card: '阁下提供的信用卡可能是一张报失的卡，所以不被允许使用。',
        revocation_of_all_authorizations: '阁下提供的信用卡不被接受，请联络所属银行。',
        revocation_of_authorization: '阁下提供的信用卡不被接受，请联络所属银行。',
        security_violation: '阁下提供的信用卡不被接受，请联络所属银行。',
        service_not_allowed: '阁下提供的信用卡不被接受，请联络所属银行。',
        stop_payment_order: '阁下提供的信用卡不被接受，请联络所属银行。',
        pin_try_exceeded: '超出输入用户个人识别号码的次数上限。',
        test_mode_live_card: '您的信用卡遭到拒绝。您的请求处于测试模式，但使用了非测试卡。',
        transaction_not_allowed: '阁下的信用卡并不支持此种购货。',
        your_card_was_declined: '抱歉！阁下提供的信用卡不被接受，请尝试其他信用卡。',
        not_accepted: '抱歉！阁下提供的信用卡不被接受，请联络我们。',
    },
    USER_INPUT_ERRORS: {
        please_select_at_least_one_item: '请选择至少一件商品',
        please_enter_a_customer_name: '请输入顾客名称',
        please_enter_the_correct_mobile_phone_number: '请输入正确的联络手提电话',
        please_enter_the_customer_age: '请输入顾客年龄',
        please_enter_the_customer_address: '请输入顾客地址',
        please_select_a_branch: '请选择分店',
        please_enter_customer_gender: '请输入顾客性别',
        please_enter_customer_email: '请输入顾客电邮',
        please_enter_a_reservation_date: '请输入预订日期',
        the_system_does_not_allow_selection_of_past_day: '系统不允许选择过往的日子',
        please_enter_a_customer_inquiry: '请输入客户咨询内容。如果没有，请填写N/A',
        please_answer_all_mc_questions: '请回答所有选择题',
    },
};
