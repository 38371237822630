var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-printer-scanner" },
    [
      _c(
        "div",
        {
          staticClass:
            "app-page-activity activity-settings-printer-scanner app-page-activity--active mdc-top-app-bar--hidden bottom-nav--hidden"
        },
        [
          _c(
            "div",
            { staticClass: "activity-settings-printer-scanner__inner" },
            [
              _c("div", { staticClass: "video_container" }, [
                _c("video", {
                  ref: "video",
                  staticStyle: { display: "none" },
                  attrs: { muted: "" },
                  domProps: { muted: true }
                }),
                _vm.fileUpload ? _c("img", { ref: "imgUploaded" }) : _vm._e()
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "fixed",
                    bottom: "50%",
                    left: "50%",
                    transform: "translate(-50%, 50%)"
                  }
                },
                [
                  _vm.fileUpload
                    ? _c(
                        "mdc-button",
                        {
                          ref: "uploadButton",
                          staticStyle: {
                            transition: "transform 0.2s",
                            "will-change": "transform"
                          },
                          attrs: { raised: "" },
                          on: { click: _vm.upload }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "mdc-button__icon",
                              staticStyle: { transform: "scale(0.833333)" },
                              attrs: {
                                "data-v-0f13c838": "",
                                "aria-hidden": "true",
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M20 4h-3.17l-1.24-1.35A1.99 1.99 0 0 0 14.12 2H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"
                                }
                              })
                            ]
                          ),
                          _c("span", { staticClass: "mdc-button__text" }, [
                            _vm._v("拍攝QR code")
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("input", {
                ref: "qrupload",
                staticStyle: { display: "none" },
                attrs: { type: "file", accept: "image/*", capture: "" }
              }),
              _c(
                "a",
                {
                  staticClass: "close-icon",
                  on: {
                    click: function($event) {
                      _vm.$router.go(-1)
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "close-icon__icon",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ]
      ),
      _c(
        "mdc-dialog",
        {
          attrs: { title: "連接成功", accept: "確認" },
          on: {
            accept: _vm.successDialog__accept,
            cancel: _vm.successDialog__accept
          },
          model: {
            value: _vm.successDialog__open,
            callback: function($$v) {
              _vm.successDialog__open = $$v
            },
            expression: "successDialog__open"
          }
        },
        [_c("div", [_vm._v("成功與打印機連接。")])]
      ),
      _c(
        "mdc-dialog",
        {
          attrs: { title: "掃描失敗", accept: "確認" },
          model: {
            value: _vm.failscanDialog__open,
            callback: function($$v) {
              _vm.failscanDialog__open = $$v
            },
            expression: "failscanDialog__open"
          }
        },
        [_c("div", [_vm._v("找不到QR code，請重新拍攝。")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }