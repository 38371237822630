
























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState, mapActions, mapGetters } from 'vuex';
import { moment_HK as moment, axios } from '@/store';
import { rnd } from '@/utils';
import PayoutCard from '@/components/dashboard/payouts/PayoutCard.vue';
import Spinner1 from '@/components/spinners/Spinner1.vue';
import smoothReflow from 'vue-smooth-reflow';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';

// function groupBy_(arr: Array<any>, key: string) {
//     return arr.reduce(function(a, v) {
//         (a[v[key]] = a[v[key]] || []).push(v);
//         return a;
//     }, {});
// }

type GET_DASHBOARD_SUMMARY = ({
    paymentMethod,
    type,
    date,
    accountList,
}: {
    paymentMethod: 'all' | 'card' | 'wechat';
    accountList?: string[];
    type: 'daily' | 'weekly' | 'monthly';
    date?: string;
}) => Promise<any>;

function groupBy(arr: Array<any>, key: any) {
    return arr.reduce(function (rv, x) {
        var v = key instanceof Function ? key(x) : x[key];
        (rv[v] = rv[v] || []).push(x);
        return rv;
    }, {});
}

const backgroundColors = ['#E91E63', '#9C27B0', '#673AB7'];

const status = [
    {
        metric: 'succeeded_amount', // volume
        type: 'daily',
        label: 'dashboard.transaction',
        postfix1: 'dashboard.volume',
        postfix2: 'dashboard.daily',
        backgroundColor: backgroundColors[0],
    },
    {
        metric: 'succeeded_count', // transactions
        type: 'daily',
        label: 'dashboard.transaction',
        postfix1: 'dashboard.count',
        postfix2: 'dashboard.daily',
        backgroundColor: backgroundColors[0],
    },
    {
        metric: 'refund_amount', // refunds
        type: 'daily',
        label: 'dashboard.refund',
        postfix1: 'dashboard.volume',
        postfix2: 'dashboard.daily',
        backgroundColor: backgroundColors[0],
    },
    {
        metric: 'refund_count', // refunds
        type: 'daily',
        label: 'dashboard.refund',
        postfix1: 'dashboard.count',
        postfix2: 'dashboard.daily',
        backgroundColor: backgroundColors[0],
    },
    {
        metric: 'succeeded_amount',
        type: 'weekly',
        label: 'dashboard.transaction',
        postfix1: 'dashboard.volume',
        postfix2: 'dashboard.weekly',
        backgroundColor: backgroundColors[1],
    },
    {
        metric: 'succeeded_count',
        type: 'weekly',
        label: 'dashboard.transaction',
        postfix1: 'dashboard.count',
        postfix2: 'dashboard.weekly',
        backgroundColor: backgroundColors[1],
    },
    {
        metric: 'refund_amount', // refunds
        type: 'weekly',
        label: 'dashboard.refund',
        postfix1: 'dashboard.volume',
        postfix2: 'dashboard.weekly',
        backgroundColor: backgroundColors[1],
    },
    {
        metric: 'refund_count',
        type: 'weekly',
        label: 'dashboard.refund',
        postfix1: 'dashboard.count',
        postfix2: 'dashboard.weekly',
        backgroundColor: backgroundColors[1],
    },
    {
        metric: 'succeeded_amount',
        type: 'monthly',
        label: 'dashboard.transaction',
        postfix1: 'dashboard.volume',
        // postfix2: 'dashboard.monthly',
        postfix2: '',
        backgroundColor: backgroundColors[2],
    },
    {
        metric: 'succeeded_count',
        type: 'monthly',
        label: 'dashboard.transaction',
        postfix1: 'dashboard.count',
        // postfix2: 'dashboard.monthly',
        postfix2: '',
        backgroundColor: backgroundColors[2],
    },
    {
        metric: 'refund_amount', // refunds
        type: 'monthly',
        label: 'dashboard.refund',
        postfix1: 'dashboard.volume',
        // postfix2: 'dashboard.monthly',
        postfix2: '',
        backgroundColor: backgroundColors[2],
    },
    {
        metric: 'refund_count',
        type: 'monthly',
        label: 'dashboard.refund',
        postfix1: 'dashboard.count',
        // postfix2: 'dashboard.monthly',
        postfix2: '',
        backgroundColor: backgroundColors[2],
    },
];

const summaryDefault = {
    succeeded_count: 0,
    succeeded_amount: 0,
    refund_count: 0,
    refund_amount: 0,
    failed_count: 0,
    failed_amount: 0,
    pending_count: 0,
    pending_amount: 0,
};

let smoothReflows: {
    el: Element;
}[] = [];

@Component({
    components: {
        PayoutCard,
        Spinner1,
    },
    computed: {
        ...mapGetters(['isMaster', 'permissions']),
    },
    methods: {
        ...mapActions(['getDashboardSummary', 'retreiveStores']),
    },
    mixins: [smoothReflow],
})
export default class Dashboard extends Vue {
    // private status =
    private paymentMethod = 'all';
    get wechatEnabled() {
        return this.$store.state.user.wechat_enabled;
    }

    @Watch('paymentMethod')
    onDashboardChanged(paymentMethod: string) {
        if (paymentMethod === 'all') {
            this.changeDashboardData('all');
        } else if (paymentMethod === 'card') {
            this.changeDashboardData('card');
        } else if (paymentMethod === 'wechat') {
            this.changeDashboardData('wechat');
        }
    }

    private isMaster!: boolean;
    private getDashboardSummary!: GET_DASHBOARD_SUMMARY;
    private retreiveStores!: (showLoading?: boolean) => Promise<any>;

    private status: {
        [uid: string]: {
            metric: string;
            type: string;
            label: any;
            postfix1: any;
            postfix2: any;
            value: {
                sum: number;
                shops: {
                    [id: string]: number;
                };
            };
            showMore: boolean;
        };
    } = {};
    private accountsName: {
        [id: string]: string;
    } = {};

    get accountsNameLoaded() {
        return !!Object.keys(this.accountsName).length;
    }
    // private backgroundColors = ['#E91E63', '#E91E63', '#E91E63', '#9C27B0', '#9C27B0', '#9C27B0', '#673AB7', '#673AB7', '#673AB7'];
    // private backgroundColors = ['#913CED', '#FB00B9', '#FF4182', '#FF8857', '#FFC54B'];

    public created() {
        this.initStats();
        this.isMaster && this.retreiveStores(false).then((ss) => ss.forEach((s: any) => this.$set(this.accountsName, s.id, s.store_name)));
    }

    public mounted() {
        this.initMDCTextFields();
        // this.updateLabel();
        // console.log(this.status);
        // for (let key in this.status) {
        //     this.status[key].label = `${this.$t(this.status[key].label)} ${this.$t(this.status[key].postfix1)} ${this.$t(
        //         this.status[key].postfix2
        //     )}`;
        //     // console.log(this.status[key].label);
        // }
    }

    public beforeDestroy() {
        this.destroySmoothReflow();
    }

    private toggleCardDetails(key: string) {
        for (let [k, stat] of Object.entries(this.status)) {
            stat.showMore = k != key ? false : key in this.status && this.status[key].value.sum ? !stat.showMore : false;
        }
    }

    private async initStats() {
        await this.changeDashboardData('all');
    }

    private updateLabel() {
        const dateObj_ = new Date();
        const monthName_ = dateObj_.toLocaleString(this.$i18n.locale.replace('_', '-'), {
            month: 'short',
        });
        for (let key in this.status) {
            if (this.status[key].type === 'monthly') {
                this.status[key].postfix2 = monthName_;
                this.status[key].label = `${this.$t(this.status[key].label)} ${this.$t(this.status[key].postfix1)} (
                    ${this.status[key].postfix2}
                )`;
            } else {
                this.status[key].label = `${this.$t(this.status[key].label)} ${this.$t(this.status[key].postfix1)} (${this.$t(
                    this.status[key].postfix2
                )})`;
            }
        }
    }

    private async changeDashboardData(paymentMethod: 'all' | 'card' | 'wechat') {
        this.status = {};

        status.forEach((s) => {
            this.$set(this.status, rnd(), {
                value: {
                    sum: null,
                    shops: {},
                },
                showMore: false,
                ...JSON.parse(JSON.stringify(s)),
            });
        });
        this.updateLabel();
        if (paymentMethod === 'card') {
            await this.getUpdatedDashboardByMethod('card');
        } else if (paymentMethod === 'wechat') {
            await this.getUpdatedDashboardByMethod('wechat');
        } else {
            await this.getUpdatedDashboardByMethod('all');
        }

        this.initSmoothReflow();
    }

    get payoutPermission() {
        return this.$store.state.user.permissions_ && this.$store.state.user.permissions_.functional.payout;
    }

    private getUpdatedDashboardByMethod(paymentMethod: 'all' | 'card' | 'wechat') {
        return Promise.all(
            Object.entries(groupBy(Object.entries(this.status), ([, v]: any) => v.type))
                .map(([type, status]: any) => {
                    let metricStat = status.map((_: any) => _[1]).reduce((a: any, stat: any) => ((a[stat.metric] = stat), a), {});

                    return this.getDashboardSummary({
                        paymentMethod,
                        type,
                        // date: now,
                    }).then((shopMetrics) => {
                        shopMetrics = shopMetrics.reduce((a: any, v: any) => {
                            a[v['account_id']] = v;
                            delete (a[v['account_id']] as any).account_id;
                            return a;
                        }, {});

                        Object.entries(
                            // sum
                            Object.values(shopMetrics).reduce(
                                (a: any, v: any) => {
                                    Object.keys(a).forEach((k) => {
                                        a[k] += parseFloat(v[k]);
                                    });
                                    return a;
                                },
                                { ...summaryDefault }
                            )
                        ).forEach(([metric, metricSum]: any) => {
                            let stat = metricStat[metric];
                            stat && (stat.value.sum = metricSum);
                        });

                        if (this.isMaster) {
                            for (let [shopId, metrics] of Object.entries(shopMetrics)) {
                                for (let [metric, metricValue] of Object.entries(metrics)) {
                                    let stat = metricStat[metric];
                                    stat && this.$set(stat.value.shops, shopId, metricValue);
                                }
                            }
                        }

                        return shopMetrics;
                    });
                })
                .map((p: any) => p.catch((e: any) => Object.assign(new Error(), e)))
        );
    }

    private initSmoothReflow() {
        let t = 'all .3s cubic-bezier(.4, 0, .2, 1)';
        smoothReflows = [
            ...Array.from(this.$el.querySelectorAll('.dashboard-card')).map((el) => ({
                el,
                ...{
                    property: ['height', 'width', 'transform'],
                    transition: t,
                },
            })),
            ...Array.from(
                // this.$el.querySelectorAll('.dashboard-card__content--sum-amount, .dashboard-card--ondark .dashboard-card__title')
                this.$el.querySelectorAll('.dashboard-card__content--sum-amount')
            ).map((el) => ({
                el,
                ...{
                    property: ['transform'],
                    transition: t,
                },
            })),
        ];
        (this.$smoothReflow as any)(smoothReflows);
    }

    private destroySmoothReflow() {
        (this.$unsmoothReflow as any)(smoothReflows);
    }

    public formatCurrency(n: number) {
        return (
            '$' +
            Math.round(n)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        );
    }

    private initMDCTextFields() {
        Array.from(this.$el.querySelectorAll('.mdc-text-field')).map((el: any) => new MDCTextField(el));
        Array.from(this.$el.querySelectorAll('.mdc-notched-outline')).map((el: any) => new MDCNotchedOutline(el));
    }
}
