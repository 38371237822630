var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "activity-online-payment-order" }, [
    _c(
      "div",
      { staticClass: "mdc-layout-grid__inner" },
      [
        _c(
          "div",
          {
            staticClass:
              "campaign-order-summary-item mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet"
          },
          [
            _c("div", { staticClass: "row flex align-items-center" }, [
              _c(
                "div",
                {
                  staticClass:
                    "campaign-order-summary-item__amount mdc-typography--headline5"
                },
                [_vm._v(_vm._s(_vm.$t("general.summary")))]
              ),
              _c(
                "a",
                {
                  staticClass: "close-icon ml-auto",
                  on: {
                    click: function($event) {
                      _vm.$router.go(-1)
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "close-icon__icon",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "mdc-typography--body2",
                  staticStyle: { width: "100%" }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("campaign.orderSummary.totalTransaction")) +
                      ": " +
                      _vm._s(_vm._f("currency")(_vm.summary.total_amount)) +
                      "\n                "
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mdc-typography--body2",
                  staticStyle: { width: "100%" }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("campaign.orderSummary.totalOrder")) +
                      ": " +
                      _vm._s(_vm.summary.total_orders) +
                      " (" +
                      _vm._s(_vm.$t("campaign.orderSummary.paid")) +
                      ":\n                    " +
                      _vm._s(_vm.summary.total_paid_orders) +
                      ")\n                "
                  )
                ]
              ),
              _vm.summary.latest_trx_amount
                ? _c(
                    "div",
                    {
                      staticClass: "mdc-typography--body2",
                      staticStyle: { width: "100%" }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t("campaign.orderSummary.latestTransaction")
                          ) +
                          ": " +
                          _vm._s(
                            _vm._f("currency")(_vm.summary.latest_trx_amount)
                          ) +
                          "\n                "
                      )
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "mdc-typography--body2",
                      staticStyle: { width: "100%" }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t("campaign.orderSummary.latestTransaction")
                          ) +
                          ": " +
                          _vm._s("N/A") +
                          "\n                "
                      )
                    ]
                  ),
              _c("div", { staticClass: "mdc-typography--body2 col-12" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("campaign.orderSummary.latestDate")) +
                    ": " +
                    _vm._s(_vm.summary.latest_trx_timestamp || "N/A") +
                    "\n                "
                )
              ]),
              _c(
                "div",
                {
                  staticStyle: { "margin-top": "auto", "margin-left": "auto" }
                },
                [
                  _c("vue-excel-xlsx", {
                    staticStyle: { display: "none" },
                    attrs: {
                      data: _vm.detailedInfo.item,
                      columns: _vm.detailedInfo.header,
                      sheetname: "sheet 1",
                      filename: _vm.detailedInfo.filename,
                      id: "excelOutput"
                    }
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "mdc-button",
                      staticStyle: { "justrify-content": "right" },
                      attrs: {
                        onclick:
                          "document.getElementById('excelOutput').click();"
                      }
                    },
                    [
                      _c("span", { staticClass: "mdc-button__label" }, [
                        _c("span", { staticClass: "mdc-button__label" }, [
                          _vm._v(_vm._s(_vm.$t("campaign.details")))
                        ]),
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "-3 -4 24 24",
                              fill: "#5656f1",
                              width: "15px",
                              height: "15px"
                            }
                          },
                          [
                            _c("path", {
                              attrs: { d: "M0 0h24v24H0z", fill: "none" }
                            }),
                            _c("path", {
                              attrs: {
                                d: "M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"
                              }
                            })
                          ]
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ]
        ),
        _vm.items.length
          ? _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav",
                staticStyle: { "justify-content": "center" }
              },
              [
                _c("div", { staticClass: "customer-title" }, [
                  _vm._v(_vm._s(_vm.$t("customer.title")))
                ])
              ]
            )
          : _vm._e(),
        _vm._l(_vm.items, function(item) {
          return _c("CampaignOrderSummaryItem", {
            key: item.id,
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet",
            attrs: { order: item, campaignId: _vm.campaignId }
          })
        }),
        _c(
          "div",
          {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet steps-nav",
            staticStyle: { "justify-content": "center" }
          },
          [
            _c(
              "button",
              {
                staticClass: "mdc-button",
                attrs: { disabled: !_vm.moreButton },
                on: { click: _vm.moreItems }
              },
              [
                _vm.items.length
                  ? _c("span", { staticClass: "mdc-fab__label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.moreButton
                            ? _vm.$t("actions.loadMore") + "..."
                            : "" + _vm.$t("actions.noMore")
                        )
                      )
                    ])
                  : _vm._e()
              ]
            )
          ]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }