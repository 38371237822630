













































import { Component, Prop, Vue } from 'vue-property-decorator';
import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);

@Component({})
export default class Signature extends Vue {
    private dialog: boolean = false;

    public mounted() {}
    public clean() {
        this.$emit('clean');
        const signaturePad: any = this.$refs.signaturePad;
        signaturePad.clearSignature(); // no error
    }

    public save() {
        const signaturePad: any = this.$refs.signaturePad;
        const { isEmpty, data } = signaturePad.saveSignature();
        if (!isEmpty) {
            this.$emit('save', data);
            this.dialog = false;
        }
    }
}
